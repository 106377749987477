import type { RouteConfig } from 'vue-router';

export enum Route {
  ROOT = 'availabilityAggregationsIndex',
}

const routes: RouteConfig[] = [
  {
    component: () => import('./AvailabilityAggregations'),
    name: Route.ROOT,
    path: '/availabilities',
  },
];

export default routes;
