import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import Collisions from 'components/conflicts/collisions/Collisions';
import MaximumConsecutiveWorkingDaysShiftConflict from 'components/conflicts/MaximumConsecutiveWorkingDaysShiftConflict';
import MaximumWorkingHoursShiftConflict from 'components/conflicts/MaximumWorkingHoursShiftConflict';
import OutsideWorkPeriodShiftConflict from 'components/conflicts/OutsideWorkPeriodShiftConflict';
import RestrictedShiftPresetsConflict from 'components/conflicts/RestrictedShiftPresetsConflict';
import LocationsPositionChangedShiftConflict from 'components/conflicts/LocationsPositionChangedShiftConflict';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import MaximumEarningShiftConflict from './MaximumEarningShiftConflict';
import MinimumBreakShiftConflict from './MinimumBreakShiftConflict';
import MinimumRestShiftConflict from './MinimumRestShiftConflict';
import InsideUnavailableTimeShiftConflict from './InsideUnavailableTimeShiftConflict';
import OutsideAvailableTimeShiftConflict from './OutsideAvailableTimeShiftConflict';

import styles from './conflicts.css';

type AbsencesCollisionShiftConflict = Extract<
  GQLShiftConflictsFragmentFragment,
  { __typename: 'AbsencesCollisionShiftConflict' }
>;

type ShiftsCollisionShiftConflict = Extract<
  GQLShiftConflictsFragmentFragment,
  { __typename: 'ShiftsCollisionShiftConflict' }
>;

export interface Props {
  conflicts: GQLShiftConflictsFragmentFragment[];
  isSelf?: boolean;
}

@Component
export default class Conflicts extends TsxComponent<Props> {
  protected get collidingAbsences() {
    return this.conflicts
      .filter(
        (conflict): conflict is AbsencesCollisionShiftConflict =>
          conflict.__typename === 'AbsencesCollisionShiftConflict',
      )
      .flatMap((conflict) => conflict.absences);
  }

  protected get collidingShifts() {
    return this.conflicts
      .filter(
        (conflict): conflict is ShiftsCollisionShiftConflict =>
          conflict.__typename === 'ShiftsCollisionShiftConflict',
      )
      .flatMap((conflict) => conflict.shifts);
  }

  @Prop({ default: () => [] })
  public conflicts: Props['conflicts'];

  @Prop()
  public isSelf?: Props['isSelf'];

  public render() {
    return (
      <div class={styles.conflicts}>
        <Collisions
          absences={this.collidingAbsences}
          class={styles.conflictsItem}
          shifts={this.collidingShifts}
        />
        {this.conflicts.map((conflict, index) => {
          if (conflict.__typename === 'MaximumEarningShiftConflict') {
            return (
              <MaximumEarningShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                isSelf={this.isSelf}
                key={conflict.__typename + index}
              />
            );
          }

          if (
            conflict.__typename === 'MaximumConsecutiveWorkingDaysShiftConflict'
          ) {
            return (
              <MaximumConsecutiveWorkingDaysShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'MinimumRestShiftConflict') {
            return (
              <MinimumRestShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'OutsideWorkPeriodShiftConflict') {
            return (
              <OutsideWorkPeriodShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'MaximumWorkingHoursShiftConflict') {
            return (
              <MaximumWorkingHoursShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                isSelf={this.isSelf}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'MinimumBreakShiftConflict') {
            return (
              <MinimumBreakShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'RestrictedShiftPresetsConflict') {
            return (
              <RestrictedShiftPresetsConflict
                class={styles.conflictsItem}
                conflict={conflict}
                isSelf={this.isSelf}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'LocationsPositionChangedShiftConflict') {
            return (
              <LocationsPositionChangedShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'InsideUnavailableTimeShiftConflict') {
            return (
              <InsideUnavailableTimeShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (conflict.__typename === 'OutsideAvailableTimeShiftConflict') {
            return (
              <OutsideAvailableTimeShiftConflict
                class={styles.conflictsItem}
                conflict={conflict}
                key={conflict.__typename + index}
              />
            );
          }

          if (
            conflict.__typename === 'AbsencesCollisionShiftConflict' ||
            conflict.__typename === 'ShiftsCollisionShiftConflict'
          ) {
            // return nothing because they're handled together as a whole in <Collisions> above
            return null;
          }

          return this.$t('conflicts.unknown');
        })}
      </div>
    );
  }
}
