import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import Action from './Action';
import Mutation from './Mutation';
import RootStoreState from '../RootStoreState';
import { DraggableSourcePayload, DropTargetPayload } from './types';

export const dragAndDropNS = namespace('dragAndDrop');

export type SetItemAction = (payload: DraggableSourcePayload) => void;
export type SetHoveredItemAction = (payload: DropTargetPayload) => void;

export interface StoreState {
  payload: DraggableSourcePayload | null;
  hoveredItem: DropTargetPayload | null;
}
const getDragAndDropStore = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    payload: null,
    hoveredItem: null,
  },
  actions: {
    [Action.SET_ITEM]({ commit }, payload: Parameters<SetItemAction>[0]) {
      commit(Mutation.SET_PAYLOAD, payload);
    },
    [Action.CLEAR_ITEM]({ commit }) {
      commit(Mutation.SET_PAYLOAD, null);
    },
    [Action.SET_HOVERED_ITEM](
      { commit },
      payload: Parameters<SetItemAction>[0],
    ) {
      commit(Mutation.SET_HOVERED_PAYLOAD, payload);
    },
    [Action.CLEAR_HOVERED_ITEM]({ commit }) {
      commit(Mutation.SET_HOVERED_PAYLOAD, null);
    },
  },
  mutations: {
    [Mutation.SET_PAYLOAD](state, payload: DraggableSourcePayload) {
      state.payload = payload;
    },
    [Mutation.SET_HOVERED_PAYLOAD](state, payload: DropTargetPayload) {
      state.hoveredItem = payload;
    },
  },
});

export default getDragAndDropStore;
