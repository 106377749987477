import { TOOLTIP_PORTAL_NAME } from 'layouts/TooltipPortalTarget';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './base-tooltip.css';

const SHOW_DELAY = 250;

export enum TooltipType {
  TOOLTIP = 'tooltip',
  POPOVER = 'popover',
}
export interface BaseTooltipProps {
  type?: TooltipType;
  text?: string | JSX.Element | Vue;
  isHidden?: boolean;
}

@Component
export default class BaseTooltip extends TsxComponent<BaseTooltipProps> {
  public $refs: {
    tooltipped: HTMLDivElement;
  };

  protected isPortalDisabled = true;

  protected isShown = false;

  protected isBeyondViewportTop = false;

  protected isBeyondViewportLeft = false;

  protected isBeyondViewportRight = false;

  protected style: Partial<Pick<CSSStyleDeclaration, 'left' | 'top'>> = {};

  protected timeoutId = Number.NaN;

  @Prop({ default: TooltipType.TOOLTIP })
  public type: NonNullable<BaseTooltipProps['type']>;

  @Prop()
  public text: BaseTooltipProps['text'];

  @Prop({ default: false })
  public isHidden: BaseTooltipProps['isHidden'];

  protected cancelTimeoutIfPresent() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = Number.NaN;
    }
  }

  protected onMouseEnter() {
    this.cancelTimeoutIfPresent();

    this.timeoutId = window.setTimeout(() => {
      this.isPortalDisabled = false;
      this.isShown = true;
      this.timeoutId = Number.NaN;
    }, SHOW_DELAY);
  }

  protected onMouseLeave() {
    this.cancelTimeoutIfPresent();

    this.isShown = false;

    this.isBeyondViewportTop = false;
    this.isBeyondViewportLeft = false;
    this.isBeyondViewportRight = false;

    // FAQ: need to reset, otherwise the flip detection below in onEnter will not
    // work the next time it is called
    this.style = {};
  }

  protected onEnter(tooltip: HTMLSpanElement, done: Function) {
    // rect of the tooltip element
    const rectTooltip = tooltip.getBoundingClientRect();
    // rect of element tooltip belongs to
    const rectTooltipped = this.$refs.tooltipped.getBoundingClientRect();

    /* left is center of tooltipped element(transform is applied in css) */
    const left = rectTooltipped.left + rectTooltipped.width / 2;

    this.isBeyondViewportTop = rectTooltipped.top - rectTooltip.height < 0;

    this.isBeyondViewportLeft = left - rectTooltip.width / 2 < 0;

    this.isBeyondViewportRight =
      left + rectTooltip.width / 2 > window.innerWidth;

    const top = this.isBeyondViewportTop
      ? rectTooltipped.top + rectTooltipped.height + rectTooltip.height
      : rectTooltipped.top;

    /*
    if tooltip goes out of bounds of the viewport - we put it not in the center
    but on one of the sides
    */
    const style = {
      left: `${
        rectTooltipped.left +
        rectTooltipped.width * 0.5 -
        rectTooltip.width * 0.5
      }px`,
      top: `${top}px`,
    };

    if (this.isBeyondViewportLeft) {
      style.left = `${rectTooltipped.left + rectTooltipped.width * 0.1}px`;
    }

    if (this.isBeyondViewportRight) {
      style.left = `${
        rectTooltipped.right - rectTooltipped.width * 0.1 - rectTooltip.width
      }px`;
    }

    this.style = style;

    done();
  }

  protected onAfterLeave() {
    this.isPortalDisabled = true;
  }

  public render() {
    return (
      <div
        class={styles.baseTooltip}
        onMouseenter={this.onMouseEnter}
        onMouseleave={this.onMouseLeave}
        ref="tooltipped"
      >
        {this.$slots.default}

        <portal to={TOOLTIP_PORTAL_NAME} disabled={this.isPortalDisabled}>
          <transition
            appear={true}
            enter-class={styles.transitionSlideEnter}
            enter-to-class={styles.transitionSlideEnterTo}
            leave-class={styles.transitionSlideLeave}
            leave-to-class={styles.transitionSlideLeaveTo}
            onEnter={this.onEnter}
            onAfterLeave={this.onAfterLeave}
          >
            {this.isShown && !this.isHidden && (
              <span
                class={{
                  [styles.baseTooltipText]: true,
                  [styles.baseTooltipTextPopover]:
                    this.type === TooltipType.POPOVER,
                  [styles.baseTooltipTextBottom]: this.isBeyondViewportTop,
                  [styles.baseTooltipTextRight]: this.isBeyondViewportLeft,
                  [styles.baseTooltipTextLeft]: this.isBeyondViewportRight,
                }}
                style={this.style}
              >
                {this.text}
              </span>
            )}
          </transition>
        </portal>
      </div>
    );
  }
}
