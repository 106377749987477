import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { store as getAbsencesStore } from 'components/calendar-common/absences/Store';
import {
  absencesByEmployments,
  getByDatesUnsorted,
} from 'components/calendar-common/absences/util';
import { GQLAbsenceState } from 'codegen/gql-types';

export const calendarAbsencesAbsencesNS = namespace(
  'calendarAbsences/absences',
);

export const store = (): Module<{}, RootStoreState> => {
  const baseStore = getAbsencesStore();
  return {
    ...baseStore,
    getters: {
      ...baseStore.getters,
      filteredAbsences(state, getters, rootState, rootGetters) {
        return getters.absences.filter((it) => {
          const isShown =
            it.state === GQLAbsenceState.ACCEPTED ||
            it.state === GQLAbsenceState.NEW;

          const isOfFilteredEmployment = rootGetters[
            'calendarAbsences/employments/filteredEmployments'
          ].find((emp) => emp.id === it.employment.id);

          return isShown && isOfFilteredEmployment;
        });
      },

      absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
        return getByDatesUnsorted(
          rootGetters['calendarAbsences/common/dateKeys'],
          getters.filteredAbsences,
        );
      },

      absencesByEmployments(state, getters, rootState, rootGetters) {
        return absencesByEmployments(
          rootGetters['calendarAbsences/employments/filteredEmployments'],
          getters.absencesByDatesUnsorted,
        );
      },
    },
  };
};
