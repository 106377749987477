import 'normalize.css';
import './component-hooks';
import portalVue from 'portal-vue';
import Vue from 'vue';
import Application from './Application';

Vue.use(portalVue);

const VUE_ROOT_ELEMENT_SELECTOR = 'root';

window.addEventListener('load', () => {
  const app = new Application();
  const el = document.querySelector(VUE_ROOT_ELEMENT_SELECTOR);
  if (!el) {
    throw new Error('Root element not found');
  }
  app.mount(el);
});
