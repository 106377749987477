import { GQLPaygradeLevel } from 'codegen/gql-types';
import { Pay } from 'components/dialog-shift/paygrades/Section';
import RootStoreState from 'store/RootStoreState';
import Vue from 'vue';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Action from './Action';
import Mutation from './Mutation';
import { mergeSourceWithTargetTree } from './utils';

export const sectionPayNS = namespace('evaluationsForm/sectionPay');

export const sectionPayEmploymentNS = namespace(
  'evaluationsForm/sectionPayEmployment',
);

export interface StoreState {
  createIdCounter: number;
  isDirty: boolean;
  tree: Pay[];
}

const getSectionPayStore = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    createIdCounter: Number.MIN_SAFE_INTEGER,
    isDirty: false,
    tree: [],
  },
  mutations: {
    [Mutation.SET_TREE]: (state, tree: Pay[]) => {
      state.tree = tree;
    },
    [Mutation.SET_IS_DIRTY]: (state, isDirty: boolean) => {
      state.isDirty = isDirty;
    },
    [Mutation.REPLACE_PARENT_NODE]: (
      state,
      { index, node }: { index: number; node: Pay },
    ) => {
      Vue.set(state.tree, index, node);
    },
    [Mutation.INSERT_PARENT_NODE]: (state, node: Pay) => {
      state.tree = [...state.tree, node];
    },
    [Mutation.SET_CREATE_ID_COUNTER]: (state, number: number) => {
      state.createIdCounter = number;
    },
  },
  getters: {
    activePayments(state) {
      return state.tree
        .filter((it) => !it.isDeleted)
        .map((pay) => ({
          ...pay,
          children: pay.children.filter((it) => !it.isDeleted),
        }));
    },
  },
  actions: {
    [Action.SET_TREE]: ({ commit }, tree: Pay[]) => {
      commit(Mutation.SET_TREE, tree);
      commit(Mutation.SET_IS_DIRTY, false);
    },
    [Action.ADD_PAY]: ({ commit, state }, payload?: Pay) => {
      const pay: Pay = {
        id: state.createIdCounter,
        children: [],
        isCreated: true,
        level: payload?.id ? GQLPaygradeLevel.PAYGRADE : GQLPaygradeLevel.SHIFT,
        parentId: payload?.id,
        total: 0,
        typeId: NaN,
        value: 0,
      };

      if (payload?.id) {
        const parentIndex = state.tree.findIndex((o) => o.id === payload.id);

        const updated = { ...state.tree[parentIndex] };
        updated.children = [...updated.children, pay];

        commit(Mutation.REPLACE_PARENT_NODE, {
          index: parentIndex,
          node: updated,
        });
      } else {
        commit(Mutation.INSERT_PARENT_NODE, pay);
      }

      commit(Mutation.SET_CREATE_ID_COUNTER, state.createIdCounter + 1);
    },
    [Action.UPDATE_PAY]: ({ commit, state }, payload: Pay) => {
      const parentIndex = state.tree.findIndex(
        (o) => o.id === (payload.parentId || payload.id),
      );

      if (parentIndex < 0) {
        return;
      }

      const updated = { ...state.tree[parentIndex] };

      if (!payload.parentId) {
        updated.value = +(payload.value || 0);
        updated.typeId = +payload.typeId;
        updated.isUpdated = !updated.isCreated;
      } else {
        updated.children = mergeSourceWithTargetTree(
          [
            {
              ...payload,
              value: +(payload.value || 0),
              typeId: +payload.typeId,
              isUpdated: !payload.isCreated,
            },
          ],
          updated.children,
        );
      }

      commit(Mutation.REPLACE_PARENT_NODE, {
        index: parentIndex,
        node: updated,
      });
    },
    [Action.UPDATE_TOTAL]: ({ commit, state }, payload: Pay) => {
      const parentIndex = state.tree.findIndex((o) => o.id === payload.id);

      if (parentIndex < 0) {
        return;
      }

      const updated = { ...state.tree[parentIndex] };
      updated.total = +(payload.total || 0);
      updated.children = mergeSourceWithTargetTree(
        payload.children,
        updated.children,
      );

      commit(Mutation.REPLACE_PARENT_NODE, {
        index: parentIndex,
        node: updated,
      });
    },
    [Action.REMOVE_PAY]: ({ commit, state }, payload: Pay) => {
      const parentIndex = state.tree.findIndex(
        (o) => o.id === (payload.parentId || payload.id),
      );

      if (parentIndex < 0) {
        return;
      }

      const updated = { ...state.tree[parentIndex] };

      if (!payload.parentId) {
        updated.isDeleted = true;
      } else {
        updated.children = mergeSourceWithTargetTree(
          [
            {
              ...payload,
              isDeleted: true,
            },
          ],
          updated.children,
        );
      }

      commit(Mutation.REPLACE_PARENT_NODE, {
        index: parentIndex,
        node: updated,
      });
    },
  },
});

export default getSectionPayStore;
