import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as translations from '../i18n/index.js';

export const I18_NEXT_LOCAL_STORAGE_KEY = 'i18nextLng';

const resources = Object.entries(translations).reduce(
  (prev, [lang, translation]) => {
    prev[lang] = { translation };
    return prev;
  },
  {},
);

const bootstrapI18n = () => {
  i18next.use(LanguageDetector).init({
    // TODO: Currently, there seems to be no way to retrieve the user's language via API.
    // So, we just use the device's language or the one stored in localStorage if present.
    detection: {
      checkWhitelist: true,
      // FAQ: So that en will match en-* and en-* will match en
      checkForSimilarInWhitelist: true,
      lookupLocalStorage: I18_NEXT_LOCAL_STORAGE_KEY,
      // FAQ: querystring so that we can quickly override for testing
      order: ['querystring', 'localStorage', 'navigator'],
    },
    interpolation: {
      prefix: '__',
      suffix: '__',
    },
    fallbackLng: 'en',
    resources,
    whitelist: Object.keys(translations),
  });

  return i18next;
};

export default bootstrapI18n;
