import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Button from 'components/form/button/Button';
import { IconPosition } from 'components/form/button/types';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { Location } from 'vue-router';
import Card from '../Card';
import styles from './card-with-link.css';

export enum Slot {
  ICON = 'icon',
}

export interface Props {
  to: string | Location;
}

@Component
class CardWithLink extends TSXComponent<Props> {
  @Prop()
  public to: Props['to'];

  public render() {
    return (
      <router-link class={styles.cardWithLinkLink} to={this.to}>
        <Card class={styles.cardWithLink}>
          {this.$slots[Slot.ICON] && (
            <div class={styles.cardWithLinkIcon}>{this.$slots[Slot.ICON]}</div>
          )}

          <div class={styles.cardWithLinkContent}>{this.$slots.default}</div>

          {/* This button is purely a visual element. It does not have any
          functionality. */}
          <Button
            aria-hidden
            tabindex={-1}
            color={ButtonColor.SECONDARY}
            icon={IconName.ARROW_NEXT}
            iconPosition={IconPosition.ALONE}
            kind={ButtonKind.GHOST}
            size={Size.XXSMALL}
          />
        </Card>
      </router-link>
    );
  }
}

export default CardWithLink;
