/* eslint-disable import/prefer-default-export */
import Absence from 'components/calendar-common/absences/Absence';
import { TimeframeKind } from 'components/calendar-common/Enums';
import type { SelectedTimeframe } from 'components/datepicker/types';
import { areIntervalsOverlapping, isWithinInterval, max, min } from 'date-fns';
import { toDate } from 'date-fns-tz';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import { Shiftplan } from 'src/store/shiftplans/Store';
import { ShiftplanScopedTimeframes } from 'src/store/ui-settings/Store';
import { endOf, startOf, Unit } from 'src/utils/date-related';

export const TimeframeKindToUnit: Record<
  TimeframeKind.DAY | TimeframeKind.WEEK | TimeframeKind.MONTH,
  Unit
> = {
  [TimeframeKind.DAY]: Unit.DAY,
  [TimeframeKind.WEEK]: Unit.WEEK,
  [TimeframeKind.MONTH]: Unit.MONTH,
};

export const getTimeframeFromDates = (
  startsAt: Date,
  endsAt: Date,
  timeframeKind: TimeframeKind,
  timeZone: string,
): SelectedTimeframe => {
  let resultTimeframe: SelectedTimeframe;

  switch (timeframeKind) {
    case TimeframeKind.FREE:
      resultTimeframe = {
        startsAt: startOf(startsAt, Unit.DAY, timeZone, { weekStartsOn: 1 }),
        endsAt: endOf(endsAt, Unit.DAY, timeZone, { weekStartsOn: 1 }),
      };
      break;

    case TimeframeKind.DAY:
      resultTimeframe = {
        startsAt,
        endsAt: endOf(startsAt, Unit.DAY, timeZone, { weekStartsOn: 1 }),
      };
      break;

    case TimeframeKind.WEEK:
      resultTimeframe = {
        startsAt: startOf(startsAt, Unit.WEEK, timeZone, { weekStartsOn: 1 }),
        endsAt: endOf(startsAt, Unit.WEEK, timeZone, { weekStartsOn: 1 }),
      };
      break;

    case TimeframeKind.MONTH:
      resultTimeframe = {
        startsAt: startOf(startsAt, Unit.MONTH, timeZone),
        endsAt: endOf(startsAt, Unit.MONTH, timeZone),
      };
      break;

    default:
      resultTimeframe = {
        startsAt,
        endsAt: startsAt,
      };
  }

  return resultTimeframe;
};

export const updateTimeframeOnTimeframeChange = (
  timeframe: SelectedTimeframe,
  timeframes: ShiftplanScopedTimeframes,
  timeframeKind: TimeframeKind,
) => {
  if (timeframeKind === TimeframeKind.FREE) {
    return {
      ...timeframes,
      [TimeframeKind.FREE]: getTimeframeFromDates(
        timeframe.startsAt,
        timeframe.endsAt,
        timeframeKind,
        VueTimeZoneProvider.getTimeZone(),
      ),
    };
  }

  return Object.values(TimeframeKind).reduce((acc, kind) => {
    if (kind === timeframeKind) {
      acc[kind as string] = timeframe;
      return acc;
    }

    if (kind === TimeframeKind.FREE) {
      acc[kind as string] = timeframes[kind];
      return acc;
    }

    const checkOverlap = areIntervalsOverlapping(
      { start: timeframe.startsAt, end: timeframe.endsAt },
      { start: timeframes[kind].startsAt, end: timeframes[kind].endsAt },
    );

    if (checkOverlap) {
      acc[kind as string] = timeframes[kind];
      return acc;
    }

    acc[kind as string] = getTimeframeFromDates(
      timeframe.startsAt,
      timeframe.endsAt,
      kind,
      VueTimeZoneProvider.getTimeZone(),
    );

    return acc;
  }, {} as ShiftplanScopedTimeframes);
};

export const getShiftplanAwareTimeframe = (
  timeframe: SelectedTimeframe,
  shiftplan: Shiftplan | undefined,
  timeZone: string,
) => {
  if (!shiftplan) {
    return timeframe;
  }

  const { startsAt, endsAt } = shiftplan;

  return {
    startsAt: max([
      new Date(timeframe.startsAt),
      startOf(toDate(startsAt, { timeZone }), Unit.DAY, timeZone),
    ]),
    endsAt: min([
      new Date(timeframe.endsAt),
      endOf(toDate(endsAt, { timeZone }), Unit.DAY, timeZone),
    ]),
  };
};

export const isEmployeeAbsenceWithinTimeFrame = (
  timeframe: SelectedTimeframe,
  absences: Record<string, Absence[]>,
) => {
  const { startsAt, endsAt } = timeframe;

  return Object.values(absences)
    .reduce((previousVal, currentVal) => {
      previousVal.push(...currentVal);
      return previousVal;
    }, [])
    .some((absence) =>
      isWithinInterval(new Date(absence.date), {
        start: startsAt,
        end: endsAt,
      }),
    );
};
