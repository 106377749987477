import { ActionTree, Module } from 'vuex';

export enum Action {
  SET_SELECTION = 'setSelection',
}

export enum Mutation {
  SET_SELECTION = 'setSelection',
}

export type Selection = number[];

export type SetSelectionFunction = (selection: Selection) => void;

export interface StoreState {
  selection: Selection;
}

export function createFilterStore<S, R>(
  store: Module<S, R>,
): Module<S & StoreState, R> {
  const { state: otherState, namespaced } = store || {};

  const newState = {
    ...(otherState instanceof Function ? otherState() : (otherState as S)),
    selection: [],
  };

  const newActions = {
    [Action.SET_SELECTION]({ commit }, payload: Selection) {
      commit(Mutation.SET_SELECTION, payload);
    },
  } as ActionTree<StoreState, R>;

  return {
    namespaced,
    state: newState,
    mutations: {
      ...store?.mutations,
      [Mutation.SET_SELECTION](state: StoreState, payload: Selection) {
        state.selection = payload;
      },
    },
    getters: {
      ...store?.getters,
    },
    actions: {
      ...store?.actions,
      ...newActions,
    },
    modules: store?.modules,
  };
}
