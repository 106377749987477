import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import styles from './avatar.css';
import { Icon } from '../icons/Icon';

export interface Props {
  size: Size;
}

@Component
export default class AvatarDefault extends TsxComponent<Props> {
  @Prop({ default: Size.MEDIUM })
  public size?: Props['size'];

  public render() {
    return (
      <div
        class={{
          [styles.avatar]: true,
          [styles.avatarXSmall]: this.size === Size.XSMALL,
          [styles.avatarSmall]: this.size === Size.SMALL,
          [styles.avatarMedium]: this.size === Size.MEDIUM,
          [styles.avatarLarge]: this.size === Size.LARGE,
          [styles.avatarXLarge]: this.size === Size.XLARGE,
          [styles.avatar2XLarge]: this.size === Size.XXLARGE,
        }}
      >
        <Icon name={IconName.PERSON} class={styles.avatarIcon} />
      </div>
    );
  }
}
