import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS } from 'components/auth/store/Store';
import { mainViewNS, StoreState as MainViewStoreState } from './store/Store';
import Header from './header/Header';
import Navigation from './navigation/Navigation';
import Chat from './chat/Chat';
import styles from './main-view.css';

@Component
export default class MainView extends TsxComponent<{}> {
  @authNS.Getter
  protected isAuthorized: boolean;

  @mainViewNS.Getter
  protected isChatAllowed: boolean;

  @mainViewNS.State
  protected isChatOpen: MainViewStoreState['isChatOpen'];

  public render() {
    return (
      <div class={styles.mainView}>
        <Header class={styles.mainViewHeader} />
        <Navigation class={styles.mainViewNavigation} />
        <div class={styles.mainViewView}>
          <main
            class={[
              styles.mainViewMain,
              this.isChatOpen && styles.mainViewMainChatOpen,
            ]}
          >
            <router-view />
          </main>
          {this.isChatAllowed && <Chat />}
        </div>
      </div>
    );
  }
}
