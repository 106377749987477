import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import getTableStore, {
  StoreState as TableStoreState,
} from 'components/table/store/Store';
import type {
  GQLEmploymentsTableDataQuery,
  GQLEmploymentsTableDataQueryVariables,
  GQLShiftRotationGroup,
  GQLSidebarDataQuery,
  GQLSidebarDataQueryVariables,
} from 'codegen/gql-types';
import { RESTING_DAY } from 'components/rotation-wizard/store/types';
import { SortDirection } from 'components/table/types';
import type {
  RotationGroup,
  RotationGroupInput,
} from 'components/rotation-wizard/store/types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import { sortBySortPosition } from 'src/utils/sort';
import EmploymentsTableDataGql from '../queries/EmploymentsTableData.gql';
import Action from './Action';
import Mutation from './Mutation';
import SidebarData from '../queries/SidebarData.gql';

export const employmentAssignmentNS = namespace(
  'rotationWizard/employmentAssignment',
);
export interface EmploymentsTableEmployment {
  id: number;
  firstName: string;
  lastName: string;
  userImage: string | null;
  qualificationIds: number[];
}

export enum Sort {
  LAST_NAME = 'last_name',
}
export interface EmploymentsTableData {
  id: number;
  staffNumber: string | null;
  employment: EmploymentsTableEmployment;
  lastName: string;
  shiftRotationGroup:
    | (Pick<GQLShiftRotationGroup, 'id' | 'name'> & {
        employmentsShiftRotationGroupId: number;
      })
    | null;
}

export interface Filters {
  search: string;
  shiftRotationGroupIds: number[];
  unassignedOnly: boolean;
  notInShiftRotationGroupIds: number[];
}

interface OwnState {
  rotationGroups: RotationGroup[];
  unassignedEmploymentsLength: number;
}

export type StoreState = TableStoreState<EmploymentsTableData, Sort, Filters> &
  OwnState;

const getStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => {
  const ownStore: Module<StoreState, RootStoreState> = {
    namespaced: true,
    getters: {
      employments(state): EmploymentsTableEmployment[] {
        return state.data.map((it) => it.employment);
      },
    },
    state: {
      rotationGroups: [],
      unassignedEmploymentsLength: 0,
    } as unknown as StoreState,
    actions: {
      async [Action.FETCH_SIDEBAR_DATA]({ commit, rootState, rootGetters }) {
        if (
          !(
            rootState.auth.currentCompanyId &&
            rootState.rotationWizard.rotationId
          )
        ) {
          logger.instance.info({
            message: {
              message: 'currentCompanyId or rotationId is not set',
              tags: [[SentryTag.ACTION, Action.FETCH_SIDEBAR_DATA]],
            },
          });
          return;
        }

        const notInShiftRotationGroupIds =
          rootState.rotationWizard.rotationGroups
            .filter((o): o is Required<RotationGroupInput> => Boolean(o.id))
            .map((o) => o.id);

        const result = await graphqlClient.query<
          GQLSidebarDataQuery,
          GQLSidebarDataQueryVariables
        >({
          query: SidebarData,
          variables: {
            companyId: rootState.auth.currentCompanyId,
            shiftRotationId: rootState.rotationWizard.rotationId,
            notInShiftRotationGroupIds,
          },
        });

        const {
          data: {
            shiftRotationGroups: { items: shiftRotationGroups },
            employments: {
              pagination: { count: unassignedEmploymentsLength },
            },
          },
        } = result;
        const transformedRotationGroups: RotationGroup[] = shiftRotationGroups
          .map((it) => ({
            id: it.id,
            name: it.name,
            employmentsLength: it.employmentsShiftRotationGroups.length,
            days: it.shiftPresetIds.map((id) =>
              id === 0
                ? RESTING_DAY
                : rootGetters['shiftPresets/items'].find(
                    (preset) => preset.id === id,
                  ),
            ),
            sortPosition: it.sortPosition,
          }))
          .sort(sortBySortPosition);
        commit(Mutation.SET_ROTATION_GROUPS, transformedRotationGroups);
        commit(
          Mutation.SET_UNASSIGNED_EMPLOYMENTS_LENGTH,
          unassignedEmploymentsLength,
        );
      },
    },
    mutations: {
      [Mutation.SET_ROTATION_GROUPS](
        state,
        shiftRotationGroups: RotationGroup[],
      ) {
        state.rotationGroups = shiftRotationGroups;
      },
      [Mutation.SET_UNASSIGNED_EMPLOYMENTS_LENGTH](state, length: number) {
        state.unassignedEmploymentsLength = length;
      },
    },
  };
  return getTableStore<
    EmploymentsTableData,
    Sort,
    Filters,
    GQLEmploymentsTableDataQuery,
    GQLEmploymentsTableDataQueryVariables,
    OwnState
  >(graphqlClient, {
    query: EmploymentsTableDataGql,
    getVariables: (rootState) => ({
      // both values should be defined on this step
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: rootState.auth.currentCompanyId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      shiftRotationId: rootState.rotationWizard.rotationId!,
    }),
    transformResponse: (response) => {
      const {
        employments: {
          items: employments,
          pagination: { count },
        },
      } = response.data;
      const getShiftRotationGroup = (
        employment,
      ): EmploymentsTableData['shiftRotationGroup'] => {
        if (!employment.employmentsShiftRotationGroups.length) {
          return null;
        }

        const [employmentsShiftRotationGroup] =
          employment.employmentsShiftRotationGroups;
        return {
          employmentsShiftRotationGroupId: employmentsShiftRotationGroup.id,
          ...employmentsShiftRotationGroup.shiftRotationGroup,
        };
      };
      return {
        data: employments.map((emp) => ({
          id: emp.id,
          employment: {
            id: emp.id,
            firstName: emp.firstName,
            lastName: emp.lastName,
            userImage:
              emp.pictureData === null ? null : emp.pictureData.pictureSmall,
            qualificationIds: [],
            pictureData: emp.pictureData,
          },
          staffNumber: emp.staffNumber,
          shiftRotationGroup: getShiftRotationGroup(emp),
          lastName: emp.lastName,
        })),
        count,
      };
    },
    initialState: {
      filters: {
        shiftRotationGroupIds: [],
        notInShiftRotationGroupIds: [],
      },
      sort: {
        direction: SortDirection.ASC,
        key: Sort.LAST_NAME,
      },
    },
    store: ownStore,
  });
};

export default getStore;
