import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { CalendarNamespace, Mode } from '../Enums';
import Actions from './Actions';
import Mutations from './Mutations';

export const calendarFiltersNS = namespace('calendar/filters');
export const calendarAbsencesFiltersNS = namespace('calendarAbsences/filters');

export type setFiltersAction = (items: FiltersMap[]) => void;

enum FiltersList {
  showNewAbsences,
  showAcceptedAbsences,
  showOpenShifts,
  showOnlyMineShifts,
  showOverassignedShifts,
  showUnderassignedShifts,
  showFullShifts,
  showReplaceRequests,
  showLeaveRequests,
  showJoinRequests,
  showDayNotes,
  showSpecialDates,
  showShiftsWithoutTags,
  showShiftsWithoutConflicts,
  showAllShifts,
  showShiftsWithoutPreset,
  showSummary,
  showShiftRotation,
  showUnscheduledEmployments,
}

export type FiltersDictionary = {
  [K in keyof typeof FiltersList]: boolean;
};

export enum FiltersMap {
  POSITION_NOTES = 'position_notes',
  NOTES = 'notes',
  NEW_ABSENCES = 'absences_new',
  ACCEPTED_ABSENCES = 'absences_accepted',
  MY_SHIFTS = 'mine',
  FILTER_OPEN_SHIFTS = 'not_null',
  STAFF_REQUEST = 'staff',
  LEAVE_REQUEST = 'change',
  REPLACE_REQUEST = 'replace',
  UNDERASSIGNED_SHIFTS = 'open',
  FULL_SHIFTS = 'assigned',
  OVERASSIGNED_SHIFTS = 'overassigned',
  DAY_NOTES = 'day_notes',
  SPECIAL_DATES = 'special_dates',
  SHIFTS_WITHOUT_PRESET = 'shifts_without_preset',
  SHIFTS_WITHOUT_TAGS = 'shifts_without_tags',
  SHIFTS_WITHOUT_CONFLICTS = 'shifts_without_conflicts',
  ALL_SHIFTS = 'all_shifts',
  SUMMARY = 'summary',
  SHIFT_ROTATION = 'shift_rotation',
  UNSCHEDULED_EMPLOYMENTS = 'unscheduled_employments',
}

// filters only available to employee
const employeeFilters: (keyof typeof FiltersList)[] = [
  'showAllShifts',
  'showShiftsWithoutConflicts',
  'showOnlyMineShifts',
];

// filters only available to stakeholders
const stakeholderFilters: (keyof typeof FiltersList)[] = [
  'showOverassignedShifts',
  'showFullShifts',
  'showReplaceRequests',
  'showLeaveRequests',
  'showJoinRequests',
  'showUnderassignedShifts',
  'showShiftRotation',
  'showSummary',
];

export type FiltersInput = FiltersMap[];
const getFiltersFromArray = (array: FiltersInput = []): FiltersDictionary => ({
  showNewAbsences: array.includes(FiltersMap.NEW_ABSENCES),
  showAcceptedAbsences: array.includes(FiltersMap.ACCEPTED_ABSENCES),
  showOpenShifts: !array.includes(FiltersMap.FILTER_OPEN_SHIFTS),
  showOnlyMineShifts: array.includes(FiltersMap.MY_SHIFTS),
  showOverassignedShifts: array.includes(FiltersMap.OVERASSIGNED_SHIFTS),
  showUnderassignedShifts: array.includes(FiltersMap.UNDERASSIGNED_SHIFTS),
  showFullShifts: array.includes(FiltersMap.FULL_SHIFTS),
  showLeaveRequests: array.includes(FiltersMap.LEAVE_REQUEST),
  showReplaceRequests: array.includes(FiltersMap.REPLACE_REQUEST),
  showJoinRequests: array.includes(FiltersMap.STAFF_REQUEST),
  showDayNotes: array.includes(FiltersMap.DAY_NOTES),
  showSpecialDates: array.includes(FiltersMap.SPECIAL_DATES),
  showShiftsWithoutTags: array.includes(FiltersMap.SHIFTS_WITHOUT_TAGS),
  showShiftsWithoutConflicts: array.includes(
    FiltersMap.SHIFTS_WITHOUT_CONFLICTS,
  ),
  showUnscheduledEmployments: array.includes(
    FiltersMap.UNSCHEDULED_EMPLOYMENTS,
  ),
  showAllShifts: array.includes(FiltersMap.ALL_SHIFTS),
  showShiftsWithoutPreset: array.includes(FiltersMap.SHIFTS_WITHOUT_PRESET),
  showSummary: array.includes(FiltersMap.SUMMARY),
  showShiftRotation: array.includes(FiltersMap.SHIFT_ROTATION),
});

export interface StoreState {
  selectedLocationsPositionIds: number[];
  selectedShiftRotationGroupIds: number[] | null;
  baseFilters: FiltersDictionary;
}

export const store = (
  parentNamespace: CalendarNamespace,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    selectedLocationsPositionIds: [],
    selectedShiftRotationGroupIds: null,
    baseFilters: getFiltersFromArray([]),
  },
  getters: {
    // if we need to modify filters somehow, for example depending on
    // the calendar mode - it should be done here
    filters(state, rootState, getters, rootGetters) {
      const mode: Mode = rootGetters[`${parentNamespace}/common/mode`];
      const isStakeholder = mode === Mode.STAKEHOLDER;

      if (isStakeholder) {
        return employeeFilters.reduce(
          (acc, name) => {
            acc[name] = false;
            return acc;
          },
          { ...state.baseFilters },
        );
      }

      return stakeholderFilters.reduce(
        (acc, name) => {
          acc[name] = false;
          return acc;
        },
        { ...state.baseFilters },
      );
    },
    // return null for locationPosition filter for employees
    locationsPositionIds(state, rootState, getters, rootGetters) {
      const mode: Mode = rootGetters[`${parentNamespace}/common/mode`];
      return mode === Mode.EMPLOYEE ? [] : state.selectedLocationsPositionIds;
    },
    // return null for shiftRotationGroups filter for employees
    shiftRotationGroupIds(state, rootState, getters, rootGetters) {
      const mode: Mode = rootGetters[`${parentNamespace}/common/mode`];
      return mode === Mode.EMPLOYEE
        ? null
        : state.selectedShiftRotationGroupIds;
    },
  },
  mutations: {
    [Mutations.SET_FILTERS](state, items) {
      state.baseFilters = getFiltersFromArray(items);
    },
    [Mutations.SET_SHOW_ONLY_MINE_SHIFTS](state, value) {
      state.baseFilters = { ...state.baseFilters, showOnlyMineShifts: value };
    },
    [Mutations.SET_SHOW_OPEN_SHIFTS](state, value) {
      state.baseFilters = { ...state.baseFilters, showOpenShifts: value };
    },
    [Mutations.SET_LOCATIONS_POSITION_IDS](state, items) {
      state.selectedLocationsPositionIds = items;
    },
    [Mutations.SET_SHIFT_ROTATION_GROUP_IDS](state, items) {
      state.selectedShiftRotationGroupIds = items;
    },
  },
  actions: {
    [Actions.SET_FILTERS]({ commit }, items) {
      commit(Mutations.SET_FILTERS, items);
    },
    [Actions.SET_SHOW_ONLY_MINE_SHIFTS]({ commit }, value) {
      commit(Mutations.SET_SHOW_ONLY_MINE_SHIFTS, value);
    },
    [Actions.TOGGLE_SHOW_OPEN_SHIFTS]({ commit, state }) {
      commit(Mutations.SET_SHOW_OPEN_SHIFTS, !state.baseFilters.showOpenShifts);
    },
    [Actions.SET_LOCATIONS_POSITION_IDS]({ commit }, items) {
      commit(Mutations.SET_LOCATIONS_POSITION_IDS, items);
    },
    [Actions.SET_SHIFT_ROTATION_GROUP_IDS]({ commit }, items) {
      commit(Mutations.SET_SHIFT_ROTATION_GROUP_IDS, items);
    },
  },
});
