import { StoreItem } from 'components/calendar-common/common/store/Helpers';
import Vue from 'vue';
import Vuex from 'vuex';
import { store as absences } from 'components/calendar-absences/absences/Store';
import {
  store as common,
  StoreState as CommonStoreState,
} from 'components/calendar-absences/common/store/Store';
import { store as employments } from 'components/calendar-common/employments/Store';
import {
  store as filters,
  StoreState as FiltersStoreState,
} from 'components/calendar-common/filters/Store';
import {
  store as highlights,
  StoreState as HighlightsStoreState,
} from 'components/calendar-common/highlights/Store';
import {
  store as notes,
  StoreState as NotesStoreState,
} from 'components/calendar-common/notes/Store';
import {
  store as pagination,
  StoreState as PaginationStoreState,
} from 'components/calendar-common/common/pagination-mixin/Store';
import { CalendarNamespace } from 'components/calendar-common/Enums';

Vue.use(Vuex);

export type EventsArray = StoreItem[];

export interface EventsData {
  specialDates: EventsArray;
  dayNotes: EventsArray;
}

export interface CalendarAbsencesModuleState {
  highlights: HighlightsStoreState;
  filters: FiltersStoreState;
  common: CommonStoreState;
  notes: NotesStoreState;
  pagination: PaginationStoreState;
}

const appStore = () => ({
  namespaced: true,
  modules: {
    highlights: highlights(CalendarNamespace.ABSENCES),
    absences: absences(),
    filters: filters(CalendarNamespace.ABSENCES),
    employments: employments(CalendarNamespace.ABSENCES),
    common: common(),
    notes: notes(CalendarNamespace.ABSENCES),
    pagination: pagination(),
  },
});

export default appStore;
