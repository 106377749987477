import { VueConstructor } from 'vue';
import GaTracker, { EventParams } from './GaTracker';

declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue
  interface Vue {
    $trackEvent: (
      params: EventParams,
      customDimensions?: Record<string, string | undefined>,
    ) => void;
    $trackPageView: (path?: string) => void;
  }
}

class VueGaTracker {
  private static gaTracker: GaTracker;

  public static setTracker(gaTracker: GaTracker) {
    VueGaTracker.gaTracker = gaTracker;
  }

  public static install(Vue: VueConstructor) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$trackEvent = (
      params: EventParams,
      customDimensions?: Record<string, string | undefined>,
    ) => {
      this.gaTracker.trackEvent(params, customDimensions);
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$trackPageView = (path: string) => {
      this.gaTracker.trackPageView(path);
    };
  }
}

export default VueGaTracker;
