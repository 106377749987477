import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { employmentEvaluationsNS } from 'store/employment-evaluations/Store';
import EmploymentEvaluationsAction from 'store/employment-evaluations/Action';
import type {
  CreateEmploymentEvaluationFunction,
  DeleteEmploymentEvaluationFunction,
  AcceptEmploymentEvaluationFunction,
} from 'store/employment-evaluations/Store';
import { Action } from 'store/normalized-store';
import { Tag } from 'store/tags/Store';
import { isStartBeforeEnd } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Pay } from 'components/dialog-shift/paygrades/Section';
import DialogShiftConflicts, {
  DialogManagerShiftsConflict,
} from 'components/dialog-shift/DialogShiftConflicts';
import DialogManager from 'components/dialog/DialogManager';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { snackbarNS } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { ButtonColor } from 'components/form/base-button/types';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { sectionPayEmploymentNS } from 'components/section-pay/store/Store';
import Form, { FormState, Slot } from './Form';
import SectionBreaksContainerEmployment from '../section-breaks/SectionBreaksContainerEmployment';
import { sectionBreaksEmploymentNS } from '../section-breaks/store/Store';
import type { Break, IsValidFunction } from '../section-breaks/store/Store';
import SectionPayContainerEmployment from '../section-pay/SectionPayContainerEmployment';
import { Evaluation, EvaluationContext } from '../types';
import styles from './form.css';

export interface Props {
  evaluation: Evaluation;
  isBringShiftsEnabled?: boolean;
  isDisabled?: boolean;
  isManageable?: boolean;
  isEmploymentEvaluationPresent?: boolean;
  staffShiftsTags: Tag[];
  isSectionPayShown: boolean;
}

@Component
export default class FormContainerEmployment extends TsxComponent<
  Props,
  {
    onInput: <T extends keyof FormState>(
      payload: EventPayload<{ field: T; value: FormState[T] }>,
    ) => void;
    onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  }
> {
  protected formState: FormState = {} as FormState;

  protected isSubmitting = false;

  protected conflictsDialogManager: DialogManagerShiftsConflict;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @employmentEvaluationsNS.Action(EmploymentEvaluationsAction.CREATE_EVALUATION)
  protected createEmploymentEvaluation: CreateEmploymentEvaluationFunction;

  @employmentEvaluationsNS.Action(Action.DELETE)
  protected deleteEmploymentEvaluation: DeleteEmploymentEvaluationFunction;

  @employmentEvaluationsNS.Action(
    EmploymentEvaluationsAction.ACCEPT_EMPLOYMENT_EVALUATION,
  )
  protected acceptEmploymentEvaluation: AcceptEmploymentEvaluationFunction;

  @sectionPayEmploymentNS.Getter('activePayments')
  protected evaluationPayments: Pay[];

  @sectionBreaksEmploymentNS.Getter('isValid')
  protected isBreaksValid: IsValidFunction;

  @sectionBreaksEmploymentNS.State('breaks')
  protected breaks: Break[];

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public evaluation: Props['evaluation'];

  @Prop({ default: false })
  public isBringShiftsEnabled: NonNullable<Props['isBringShiftsEnabled']>;

  @Prop({ default: false })
  public isDisabled: NonNullable<Props['isDisabled']>;

  @Prop({ default: false })
  public isEmploymentEvaluationPresent: NonNullable<
    Props['isEmploymentEvaluationPresent']
  >;

  @Prop()
  public staffShiftsTags: Props['staffShiftsTags'];

  @Prop()
  public isSectionPayShown: Props['isSectionPayShown'];

  @Prop()
  public isManageable: Props['isManageable'];

  @Watch('evaluation', { immediate: true })
  protected onEvaluationChange() {
    this.formState = {
      startsAt: new Date(this.evaluation.startsAt),
      endsAt: new Date(this.evaluation.endsAt),
      note: this.evaluation.note || '',
      unpaidBreak: this.evaluation.untimedBreak ?? this.evaluation.break,
      tags: this.evaluation.staffShiftsTagsIds.map((id) => id.toString()),
    };
  }

  protected onInput<T extends keyof FormState>({
    payload: { field, value },
  }: EventPayload<{ field: T; value: FormState[T] }>) {
    this.formState[field] = value;
  }

  protected async tryCreateEmploymentEvaluation(ignoreConflicts = false) {
    const { tags, unpaidBreak, ...evaluation } = this.formState;

    return executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShiftId: this.evaluation.staffShiftId,
        employmentEvaluation: deepTransformDates({
          ...evaluation,
          break: null,
          untimedBreak: unpaidBreak,
          evaluationBreaks: this.breaks.map(({ startsAt, endsAt }) => ({
            startsAt,
            endsAt,
          })),
          evaluationTagIds: tags.map((it) => Number.parseInt(it, 10)),
          // values are not optional at this step otherwise form is not valid and can't be submitted
          payments: this.evaluationPayments.map(
            ({ children, value, typeId }) => ({
              paygradeTypeId: typeId!,
              value: value!,
              childPayments: children.map(
                ({ typeId: childTypeId, value: childValue }) => ({
                  paygradeTypeId: childTypeId!,
                  value: childValue!,
                  childPayments: [],
                }),
              ),
            }),
          ),
          ignoreConflicts,
        }),
      },
      this.createEmploymentEvaluation,
      '',
    );
  }

  protected async onSubmit({ event }: EventPayload<void>) {
    event.preventDefault();

    try {
      this.isSubmitting = true;

      let result = await this.tryCreateEmploymentEvaluation();

      if (result.state === StoreActionState.CONFLICT) {
        this.conflictsDialogManager.setStateKey('conflicts', result.conflicts);
        await this.conflictsDialogManager.prompt();

        result = await this.tryCreateEmploymentEvaluation(true);
      }

      if (result.state === StoreActionState.SUCCESS) {
        this.showSnackbar({
          kind: AlertKind.SUCCESS,
          timeout: 5000,
          message: this.$t('shifts.evaluations.snackChangeProposed'),
        });
      }

      this.$emit('submit', createEventPayload<void>(event, undefined));
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        this.$logInfo(e);
      }
    } finally {
      this.isSubmitting = false;
    }
  }

  protected tryAcceptEvaluation(ignoreConflicts = false) {
    return executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShiftId: this.evaluation.staffShiftId,
        ignoreConflicts,
      },
      this.acceptEmploymentEvaluation,
      '',
    );
  }

  protected async onEvaluationAccept(event: SyntheticEvent<HTMLButtonElement>) {
    this.isSubmitting = false;
    try {
      this.isSubmitting = true;

      let result = await this.tryAcceptEvaluation(false);

      if (result.state === StoreActionState.CONFLICT) {
        this.conflictsDialogManager.setStateKey('conflicts', result.conflicts);
        await this.conflictsDialogManager.prompt();

        result = await this.tryAcceptEvaluation(true);
      }

      if (result.state === StoreActionState.SUCCESS) {
        this.showSnackbar({
          kind: AlertKind.SUCCESS,
          timeout: 5000,
          message: this.$t('shifts.evaluations.snackUpdated'),
        });
      }

      this.$emit('submit', createEventPayload<void>(event, undefined));
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        this.$logInfo(e);
      }
    } finally {
      this.isSubmitting = false;
    }
  }

  protected async onEvaluationDecline(
    event: SyntheticEvent<HTMLButtonElement>,
  ) {
    this.isSubmitting = false;

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShiftId: this.evaluation.staffShiftId,
      },
      this.deleteEmploymentEvaluation,
      '',
    );

    if (result.state === StoreActionState.SUCCESS) {
      this.showSnackbar({
        kind: AlertKind.SUCCESS,
        timeout: 5000,
        message: this.$t('shifts.evaluations.snackUpdated'),
      });
    }

    this.$emit('submit', createEventPayload<void>(event, undefined));
    this.isSubmitting = false;
  }

  protected get isValid() {
    return (
      this.formState.unpaidBreak !== undefined &&
      !Number.isNaN(this.formState.unpaidBreak) &&
      isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt, true) &&
      this.isBreaksValid(
        this.formState.startsAt,
        this.formState.endsAt,
        this.$timeZone.value,
      )
    );
  }

  public created() {
    this.conflictsDialogManager = new DialogManager({
      isOpen: false,
      conflicts: [],
    });
  }

  public beforeDestroy() {
    this.conflictsDialogManager.destroy();
  }

  public render() {
    return (
      <Form
        context={EvaluationContext.EMPLOYMENT}
        evaluation={this.evaluation}
        formState={this.formState}
        isBringShiftsEnabled={this.isBringShiftsEnabled}
        isDisabled={this.isDisabled}
        isSubmitting={this.isSubmitting}
        isValid={this.isValid}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        tags={this.staffShiftsTags}
      >
        <SectionBreaksContainerEmployment
          evaluationBreaks={this.evaluation.breaks}
          evaluationEndsAt={this.formState.endsAt}
          evaluationStartsAt={this.formState.startsAt}
          isDisabled={this.isDisabled || this.isSubmitting}
          isEmploymentEvaluationPresent={this.isEmploymentEvaluationPresent}
          onUnpaidBreakChange={({ event, payload: { value } }) =>
            this.onInput({
              event,
              payload: { field: 'unpaidBreak', value },
            })
          }
          slot={Slot.SECTION_BREAKS}
          unpaidBreak={this.formState.unpaidBreak}
        />

        {this.isSectionPayShown && (
          <SectionPayContainerEmployment
            breaks={this.breaks}
            evaluation={this.evaluation}
            isEmploymentEvaluationPresent={this.isEmploymentEvaluationPresent}
            formState={this.formState}
            isSubmitting={this.isSubmitting}
            isDisabled={
              this.isDisabled ||
              this.isSubmitting ||
              !this.currentCompany?.editShiftPayments
            }
            slot={Slot.SECTION_PAY}
          />
        )}

        <FormSection
          class={styles.formSectionActions}
          context={this.$t('shifts.evaluations.labelEmployee')}
          heading={this.$t('shifts.evaluations.headingActions')}
          slot={Slot.ACTION_BUTTONS}
        >
          {!this.isManageable ? (
            <Button
              disabled={this.isSubmitting || !this.isValid}
              size={Size.MEDIUM}
              type="submit"
              class={styles.formCenteredButton}
            >
              {this.$t('shifts.evaluations.buttonEvaluate')}
            </Button>
          ) : (
            [
              <Button
                disabled={this.isSubmitting || !this.isValid}
                size={Size.MEDIUM}
                type="button"
                class={styles.formActionButton}
                color={ButtonColor.ERROR}
                onClick={this.onEvaluationDecline}
              >
                {this.$t('shifts.evaluations.buttonDecline')}
              </Button>,
              <Button
                disabled={this.isSubmitting || !this.isValid}
                size={Size.MEDIUM}
                type="button"
                class={styles.formActionButton}
                color={ButtonColor.SUCCESS}
                onClick={this.onEvaluationAccept}
              >
                {this.$t('shifts.evaluations.buttonApprove')}
              </Button>,
            ]
          )}
        </FormSection>

        <DialogShiftConflicts
          slot={Slot.PROMPTS}
          conflicts={this.conflictsDialogManager.state.conflicts}
          isOpen={this.conflictsDialogManager.state.isOpen}
          onCloseClick={() => this.conflictsDialogManager.onAbort()}
          onConfirm={() =>
            this.conflictsDialogManager.onAnswer({ ignoreAll: false })
          }
        />
      </Form>
    );
  }
}
