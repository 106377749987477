import Employment, {
  EmploymentConstructorProps,
} from 'components/calendar-common/employments/Employment';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { sortByLastName } from 'src/utils/sort';
import {
  getWorkingMinutesLimitForDate,
  isWorkingOnThatDayOfTheWeek,
} from 'src/utils/hourly-account';
import { FREE_DAY_PRESET_ID } from 'src/store/shift-presets/Store';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import { SET_EMPLOYMENT_SEARCH_QUERY } from './Actions';
import Mutations from './Mutations';
import { CalendarNamespace } from '../Enums';
import { FiltersDictionary } from '../filters/Store';
import {
  isOutsideEmploymentPeriod,
  hasSuitableShift,
  locationPositionFilter,
  myShiftsFilter,
  shiftRotationGroupFilter,
} from './utils';
import Shift from '../shifts/Shift';

export const calendarEmploymentsNS = namespace('calendar/employments');
export const calendarEmploymentsPositionsMonthViewNS = namespace(
  'calendar/employments/positionsMonthView',
);
export const calendarAbsencesEmploymentsNS = namespace(
  'calendarAbsences/employments',
);

export interface StoreState {
  searchQuery: string;
}

export type EmploymentWithAssignmentInfo = {
  employment: Employment;
  isUnassignedAndNoSuitableShifts: boolean;
};

export type PresetEmployments = {
  employments: EmploymentWithAssignmentInfo[];
  color: string;
  name: string;
};

export type RotationEmployments = Record<number, PresetEmployments>;

export const EMPLOYMENT_INFO_CACHE_IDENTIFIER_PREFIX = 'employment-info-';

const getAssignedEmployeesForTheDay = (shiftsForTheCurrentDay: Shift[]) => {
  return [
    ...new Set(shiftsForTheCurrentDay.flatMap((shift) => shift.employmentIds)),
  ];
};

export const store = (
  parentNamespace: CalendarNamespace.CALENDAR | CalendarNamespace.ABSENCES,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    searchQuery: '',
  },
  mutations: {
    [Mutations.SET_SEARCH_QUERY](state, searchQuery) {
      state.searchQuery = searchQuery;
    },
  },
  actions: {
    [SET_EMPLOYMENT_SEARCH_QUERY]({ commit }, value = '') {
      commit(Mutations.SET_SEARCH_QUERY, value);
    },
  },
  getters: {
    employments(state, getters, rootState, rootGetters) {
      const shiftsEmployments: Record<string, number[]> =
        rootGetters[`${parentNamespace}/shifts/shiftsEmployments`] || {};
      const employments = rootGetters['employments/items']
        .map(
          (employment): EmploymentConstructorProps => ({
            ...employment,
            presentLocationsPositionIds: Array.from(
              shiftsEmployments[employment.id] || [],
            ),
          }),
        )
        .sort(sortByLastName);
      return employments.map((it) => new Employment(it));
    },
    filteredEmployments(state, getters, rootState, rootGetters) {
      if (
        !rootState.auth.currentEmploymentId ||
        !rootState.auth.currentCompany
      ) {
        return [];
      }
      const searchQuery = state.searchQuery?.toLocaleLowerCase() || '';

      let locationsPositionIds =
        rootGetters[`${parentNamespace}/filters/locationsPositionIds`];

      if (locationsPositionIds.length === 0) {
        const availableLocationPositions =
          rootGetters[
            `${CalendarNamespace.CALENDAR}/positions/availablePositions`
          ];
        locationsPositionIds = availableLocationPositions.map(
          (locationPosition) => locationPosition.id,
        );
      }

      const shiftRotationGroupIds =
        rootGetters[`${parentNamespace}/filters/shiftRotationGroupIds`];

      const { shiftRotationEnabled: isShiftRotationsEnabled } =
        rootState.auth.currentCompany;

      const { showOnlyMineShifts } =
        rootGetters[`${parentNamespace}/filters/filters`];

      const searchQueryFilterFn = ({ staffNumber, firstName, lastName }) =>
        searchQuery.length === 0 ||
        staffNumber?.toLocaleLowerCase().includes(searchQuery) ||
        `${firstName} ${lastName}`.toLocaleLowerCase().includes(searchQuery);
      const myShiftsFilterFn = myShiftsFilter(
        showOnlyMineShifts,
        rootState.auth.currentEmploymentId,
      );
      /** filter employments with all positions in ignored positions
       * we need to check presentLocationsPositions here as well
       * so historical data is displayed correctly.
       */
      const locationPositionFilterFn =
        locationPositionFilter(locationsPositionIds);
      const shiftRotationGroupFilterFn = shiftRotationGroupFilter(
        shiftRotationGroupIds,
        isShiftRotationsEnabled || false,
        rootGetters[`${parentNamespace}/shifts/filteredShifts`],
      );

      const filteredEmployments = getters.employments.filter(
        (employment) =>
          searchQueryFilterFn(employment) &&
          myShiftsFilterFn(employment) &&
          locationPositionFilterFn(employment) &&
          shiftRotationGroupFilterFn(employment),
      );

      return Object.freeze(filteredEmployments);
    },
    filteredUnscheduledEmployments(
      state,
      getters,
      rootState,
      rootGetters,
    ): Employment[] {
      if (!rootState.auth.currentCompany) {
        return [];
      }
      const locationsPositionIds =
        rootGetters[`${parentNamespace}/filters/locationsPositionIds`];
      const shiftRotationGroupIds =
        rootGetters[`${parentNamespace}/filters/shiftRotationGroupIds`];

      const { shiftRotationEnabled: isShiftRotationsEnabled } =
        rootState.auth.currentCompany;

      const locationPositionFilterFn =
        locationPositionFilter(locationsPositionIds);
      const shiftRotationGroupFilterFn = shiftRotationGroupFilter(
        shiftRotationGroupIds,
        isShiftRotationsEnabled || false,
        rootGetters[`${parentNamespace}/shifts/filteredShifts`],
      );

      const filteredEmployments = getters.employments.filter(
        (employment) =>
          !employment.isDeleted() &&
          locationPositionFilterFn(employment) &&
          shiftRotationGroupFilterFn(employment),
      );

      return filteredEmployments;
    },
    employmentsByPositions(state, getters, rootState, rootGetters) {
      return Object.freeze(
        rootGetters[`${parentNamespace}/positions/filteredPositions`].reduce(
          (acc, it) => {
            acc[it.locationsPositionId] = getters.filteredEmployments.filter(
              (employment) =>
                employment.presentLocationsPositionIds.includes(
                  it.locationsPositionId,
                ),
            );
            return acc;
          },
          {},
        ),
      );
    },
    unscheduledEmploymentsWithoutRotation(
      state,
      getters,
      rootState,
      rootGetters,
    ): Record<string, Employment[]> {
      const sortedEmployments = {};
      // this function is applicable only for hourly-monthly employees, because data is fetched only for those customers
      const getScheduledMinutesInTheCurrentMonth = (
        dateKey: string,
        employment: Employment,
      ): number => {
        const scheduledMinutes = rootGetters[
          'employmentInfo/getByCacheIdentifier'
        ](
          `${EMPLOYMENT_INFO_CACHE_IDENTIFIER_PREFIX}${new Date(
            dateKey,
          ).getMonth()}`,
        ).find(
          (employmentInfo) => employmentInfo.employment.id === employment.id,
        )?.scheduledMinutes;

        return scheduledMinutes || 0;
      };

      rootGetters[`${parentNamespace}/common/dates`].forEach(({ dateKey }) => {
        const shiftsForTheCurrentDay: Shift[] =
          rootGetters[`${parentNamespace}/shifts/shiftsByDates`][dateKey];

        const assignedEmployeesForTheDay = getAssignedEmployeesForTheDay(
          shiftsForTheCurrentDay,
        );

        const isOverassignmentAllowed =
          rootState.auth.currentCompany?.isOverassignmentAllowed || false;

        const employmentsGroupedByType = getters.filteredUnscheduledEmployments
          .filter(
            (employment) =>
              employment.shiftRotationGroupIds.length === 0 &&
              // no approved absence on that day
              !rootGetters[
                `${parentNamespace}/absences/filteredAcceptedAbsencesByDates`
              ][dateKey]
                .map((it) => it.employment.id)
                .includes(employment.id) &&
              !isOutsideEmploymentPeriod(
                dateKey,
                employment,
                VueTimeZoneProvider.getTimeZone(),
              ),
          )
          .reduce(
            (
              acc: {
                hourlyMonth: Employment[];
                nonHourly: Employment[];
                hourlyWeek: Employment[];
              },
              employment,
            ) => {
              if (
                !employment.employmentWorkHour ||
                // it's a workaround. employment.hourEnabled should be used for the check, but it's always returning false.
                //  weeklyMinutes also can't be used. It's always null.
                // TODO: replace with a proper check once the issue https://shyftplan.atlassian.net/browse/SP-4894 is fixed.
                (!employment.employmentWorkHour.isMonthly &&
                  !employment.employmentWorkHour.sunMinutes &&
                  !employment.employmentWorkHour.monMinutes &&
                  !employment.employmentWorkHour.tueMinutes &&
                  !employment.employmentWorkHour.wedMinutes &&
                  !employment.employmentWorkHour.thuMinutes &&
                  !employment.employmentWorkHour.friMinutes &&
                  !employment.employmentWorkHour.satMinutes)
              ) {
                acc.nonHourly.push(employment);
              } else if (employment.employmentWorkHour.isMonthly) {
                acc.hourlyMonth.push(employment);
              } else {
                acc.hourlyWeek.push(employment);
              }
              return acc;
            },
            { hourlyMonth: [], hourlyWeek: [], nonHourly: [] },
          );

        // return only employees that are not assigned to any shift today
        const filteredNonHourly = employmentsGroupedByType.nonHourly
          .filter(
            (employment) => !assignedEmployeesForTheDay.includes(employment.id),
          )
          .map((employment) => ({
            isUnassignedAndNoSuitableShifts: !hasSuitableShift(
              shiftsForTheCurrentDay,
              isOverassignmentAllowed,
              employment,
              null,
            ),
            employment,
          }));

        const filteredHourlyWeek = employmentsGroupedByType.hourlyWeek
          .filter((employment) => {
            const assignedShifts = shiftsForTheCurrentDay.filter((shift) =>
              shift.employmentIds.includes(employment.id),
            );
            const scheduledTimeForTheDay = assignedShifts
              .map((shift) => shift.getDurationMinutes())
              .reduce((acc, duration) => acc + duration, 0);
            const leftTimeForTheDay =
              getWorkingMinutesLimitForDate(dateKey, employment) -
              scheduledTimeForTheDay;
            return (
              getWorkingMinutesLimitForDate(dateKey, employment) > 0 &&
              // Working hours limit is not exceeded today
              leftTimeForTheDay > 0
              // Is there at least one shift with unassigned position which fits both criteria
            );
          })
          .map((employment) => {
            const assignedShifts = shiftsForTheCurrentDay.filter((shift) =>
              shift.employmentIds.includes(employment.id),
            );
            const scheduledTimeForTheDay = assignedShifts
              .map((shift) => shift.getDurationMinutes())
              .reduce((acc, duration) => acc + duration, 0);
            const leftTimeForTheDay =
              getWorkingMinutesLimitForDate(dateKey, employment) -
              scheduledTimeForTheDay;
            return {
              // if employee is already assigned, count it as isUnassignedAndNoSuitableShifts=false
              // if not assigned, calculate if there are suitable shifts
              isUnassignedAndNoSuitableShifts:
                assignedShifts.length === 0
                  ? !hasSuitableShift(
                      shiftsForTheCurrentDay,
                      isOverassignmentAllowed,
                      employment,
                      leftTimeForTheDay,
                    )
                  : false,
              employment,
            };
          });

        const filteredHoursMonthly = employmentsGroupedByType.hourlyMonth
          .filter((employment) => {
            const leftMinutesThisMonth = employment.employmentWorkHour!
              .monthlyMinutes
              ? employment.employmentWorkHour!.monthlyMinutes -
                getScheduledMinutesInTheCurrentMonth(dateKey, employment)
              : 0;

            return (
              // working on that day
              isWorkingOnThatDayOfTheWeek(dateKey, employment) &&
              // Working hours limit is not exceeded this month
              leftMinutesThisMonth > 0
            );
          })
          .map((employment) => {
            const leftMinutesThisMonth = employment.employmentWorkHour!
              .monthlyMinutes
              ? employment.employmentWorkHour!.monthlyMinutes -
                getScheduledMinutesInTheCurrentMonth(dateKey, employment)
              : 0;

            const assignedShifts = shiftsForTheCurrentDay.filter((shift) =>
              shift.employmentIds.includes(employment.id),
            );
            return {
              employment,
              isUnassignedAndNoSuitableShifts:
                assignedShifts.length === 0
                  ? !hasSuitableShift(
                      shiftsForTheCurrentDay,
                      isOverassignmentAllowed,
                      employment,
                      leftMinutesThisMonth,
                    )
                  : false,
            };
          });

        sortedEmployments[dateKey] = [
          ...filteredNonHourly,
          ...filteredHoursMonthly,
          ...filteredHourlyWeek,
        ];
      });

      return sortedEmployments;
    },
    unscheduledEmploymentsWithRotation(
      state,
      getters,
      rootState,
      rootGetters,
    ): Record<string, RotationEmployments> {
      const sortedEmployments = {};
      const employmentPresetIds = {};
      const dates = rootGetters[`${parentNamespace}/common/dates`];

      const employmentPresetIdByDateKey = (
        employment: Employment,
        dataKey: string,
      ) => {
        if (employmentPresetIds[employment.id] === undefined) {
          employmentPresetIds[employment.id] =
            employment.getShiftPresetIdsForTimeframe(dates);
        }
        return employmentPresetIds[employment.id][dataKey];
      };

      const isOverassignmentAllowed =
        rootState.auth.currentCompany?.isOverassignmentAllowed || false;

      dates.forEach(({ dateKey }) => {
        const shiftsForTheCurrentDay: Shift[] =
          rootGetters[`${parentNamespace}/shifts/shiftsByDates`][dateKey];

        sortedEmployments[dateKey] = getters.filteredUnscheduledEmployments
          .filter(
            (employment) =>
              // rotation group employee
              employment.shiftRotationGroupIds.length !== 0 &&
              // no approved absence on that day
              !rootGetters[
                `${parentNamespace}/absences/filteredAcceptedAbsencesByDates`
              ][dateKey]
                .map((it) => it.employment.id)
                .includes(employment.id) &&
              // return only employees that are not assigned to any shift today
              !getAssignedEmployeesForTheDay(shiftsForTheCurrentDay).includes(
                employment.id,
              ) &&
              !isOutsideEmploymentPeriod(
                dateKey,
                employment,
                VueTimeZoneProvider.getTimeZone(),
              ),
          )
          .reduce((acc: RotationEmployments, employment) => {
            const presetId = employmentPresetIdByDateKey(employment, dateKey);
            if (presetId === FREE_DAY_PRESET_ID) {
              return acc;
            }

            if (acc[presetId] === undefined) {
              const preset =
                rootGetters['shiftPresets/getByIdWithFallback'](presetId);

              // Corner case. When configuration of the rotation group is not finished.
              // We are not going to show such employees in the unscheduled section
              if (preset === undefined) {
                return acc;
              }
              acc[presetId] = {
                employments: [],
                color: preset.color,
                name: preset.name,
              };
            }
            acc[presetId].employments.push({
              employment,
              isUnassignedAndNoSuitableShifts: !hasSuitableShift(
                shiftsForTheCurrentDay,
                isOverassignmentAllowed,
                employment,
                null,
              ),
            });

            return acc;
          }, {});
      });

      return sortedEmployments;
    },
  },
  modules: {
    positionsMonthView: {
      namespaced: true,
      getters: {
        filteredEmployments(state, getters, rootState, rootGetters) {
          if (
            !rootState.auth.currentEmploymentId ||
            !rootState.auth.currentCompany
          ) {
            return [];
          }

          const locationsPositionIds =
            rootGetters[`${parentNamespace}/filters/locationsPositionIds`];
          const shiftRotationGroupIds =
            rootGetters[`${parentNamespace}/filters/shiftRotationGroupIds`];
          const filters: FiltersDictionary =
            rootGetters[`${parentNamespace}/filters/filters`];
          const { shiftRotationEnabled: isShiftRotationsEnabled } =
            rootState.auth.currentCompany;

          const myShiftsFilterFn = myShiftsFilter(
            filters.showOnlyMineShifts,
            rootState.auth.currentEmploymentId,
          );
          /** filter employments with all positions in ignored positions
           * we need to check presentLocationsPositions here as well
           * so historical data is displayed correctly.
           */
          const locationPositionFilterFn =
            locationPositionFilter(locationsPositionIds);
          const shiftRotationGroupFilterFn = shiftRotationGroupFilter(
            shiftRotationGroupIds,
            isShiftRotationsEnabled || false,
            rootGetters[`${parentNamespace}/shifts/filteredShifts`],
            true,
          );

          return Object.freeze(
            rootGetters[`${parentNamespace}/employments/employments`].filter(
              (employment) =>
                myShiftsFilterFn(employment) &&
                locationPositionFilterFn(employment) &&
                shiftRotationGroupFilterFn(employment),
            ),
          );
        },
        employmentsByPositions(state, getters, rootState, rootGetters) {
          return Object.freeze(
            rootGetters[
              `${parentNamespace}/positions/filteredPositions`
            ].reduce((acc, it) => {
              acc[it.locationsPositionId] = getters.filteredEmployments.filter(
                (employment) =>
                  employment.presentLocationsPositionIds.includes(
                    it.locationsPositionId,
                  ),
              );
              return acc;
            }, {}),
          );
        },
      },
    },
  },
});
