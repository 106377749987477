import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { CalendarNamespace } from '../Enums';
import Actions from './Actions';
import Mutations from './Mutations';

export type StoreState = {
  isActive: boolean;
  selectedIds: number[];
  selectionType: SelectionKind | null;
};

export enum SelectionKind {
  STAFF_SHIFT = 'staffShift',
  SHIFT = 'shift',
}

export type SetSelectedIdsToggleFunction = (payload: {
  id: number;
  selectionType: SelectionKind;
}) => void;

export type SetSelectedIdsFunction = (payload: {
  selectedIds: number[];
  selectionType: SelectionKind;
}) => void;

export const calendarSelectionModeNS = namespace(
  `${CalendarNamespace.CALENDAR}/selectionMode`,
);

export const store = (
  calendarNamespace: CalendarNamespace,
): Module<StoreState, RootStoreState> => {
  const initialState: StoreState = {
    isActive: false,
    selectedIds: [],
    selectionType: null,
  };

  return {
    namespaced: true,
    state: initialState,
    mutations: {
      [Mutations.SET_ACTIVE](state, isActive: boolean) {
        state.isActive = isActive;
      },
      [Mutations.SET_TYPE](state, selectionType: SelectionKind) {
        if (state.selectedIds.length === 0) {
          state.selectionType = null;
        } else {
          state.selectionType = selectionType;
        }
      },
      [Mutations.SELECT_ID](state, shiftId: number) {
        state.selectedIds = [...state.selectedIds, shiftId];
      },
      [Mutations.DESELECT_ID](state, shiftId: number) {
        state.selectedIds = state.selectedIds.filter((id) => id !== shiftId);
      },
      [Mutations.SET_SELECTED_IDS](state, shiftIds: number[]) {
        state.selectedIds = shiftIds;
      },
    },
    actions: {
      [Actions.TOGGLE_SELECTION_MODE]({ commit, state }) {
        if (state.isActive) {
          commit(Mutations.SET_SELECTED_IDS, []);
        }
        commit(Mutations.SET_ACTIVE, !state.isActive);
        commit(Mutations.SET_TYPE, null);
      },
      [Actions.TOGGLE_SELECTION_ID]({ commit, state }, { id, selectionType }) {
        if (state.selectedIds.includes(id)) {
          commit(Mutations.DESELECT_ID, id);
        } else {
          commit(Mutations.SELECT_ID, id);
        }
        commit(Mutations.SET_TYPE, selectionType);
      },
      [Actions.CLEAR_SELECTED_IDS]({ commit }) {
        commit(Mutations.SET_SELECTED_IDS, []);
        commit(Mutations.SET_TYPE, null);
      },
      [Actions.SET_SELECTED_IDS]({ commit }, { selectedIds, selectionType }) {
        commit(Mutations.SET_SELECTED_IDS, selectedIds);
        commit(Mutations.SET_TYPE, selectionType);
      },
    },
  };
};
