import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import {
  getDateInTimeZone,
  getDateTimeFormatter,
  getHourMinuteDurationString,
  MSECS_IN_MINUTE,
} from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './conflicts.css';

@Component
export default class MinimumRestShiftConflict extends TsxComponent<{
  conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MinimumRestShiftConflict' }
  >;
}> {
  protected get formatDate() {
    return getDateTimeFormatter(this.$i18n.i18next.language);
  }

  @Prop()
  public conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MinimumRestShiftConflict' }
  >;

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.minimumRest.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {this.$t('conflicts.minimumRest.text', {
            minimumRest: getHourMinuteDurationString(
              this.conflict.minimumRestMinutes * MSECS_IN_MINUTE,
            ),
            conflictingShift: this.formatDate(
              getDateInTimeZone(
                new Date(this.conflict.lastShiftEndsAt),
                this.$timeZone.value,
              ),
            ),
          })}
        </p>
      </section>
    );
  }
}
