import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { ShiftTooltip } from '../Store';

interface Props {
  tooltipPayload: ShiftTooltip;
}

@Component
export class TooltipShiftContent extends TsxComponent<Props, {}> {
  @Prop()
  protected tooltipPayload: Props['tooltipPayload'];

  public render() {
    return (
      <div>
        <div data-test-id="shift-month__tooltip-content">
          {this.tooltipPayload?.text}
          {this.tooltipPayload?.composedNote && (
            <div>{`${this.$t('calendar.shiftNote')}: ${
              this.tooltipPayload?.composedNote
            }`}</div>
          )}
        </div>
        <span>{this.tooltipPayload?.label}</span>
      </div>
    );
  }
}

export default TooltipShiftContent;
