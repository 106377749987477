import { GQLAbsenceState } from 'codegen/gql-types';
import Employment from '../employments/Employment';
import { Position } from '../positions/Position';
import Absence from './Absence';

// checks for absences filtering
export const FILTER_CHECKS = [
  {
    key: 'showNewAbsences',
    check: (item) => item.state === GQLAbsenceState.NEW,
  },
  {
    key: 'showAcceptedAbsences',
    check: (item) => item.state === GQLAbsenceState.ACCEPTED,
  },
];

export const getByDatesUnsorted = (dateKeys, items) =>
  dateKeys.reduce((acc, date) => {
    acc[date] = items.filter((absence) => absence.date === date);
    return acc;
  }, {});

export const getByDates = (unsortedItems) => {
  const absences = { ...unsortedItems };
  Object.keys(absences).forEach((date) => {
    absences[date] = {
      new: absences[date].filter((it) => it.state === GQLAbsenceState.NEW),
      accepted: absences[date].filter(
        (it) => it.state === GQLAbsenceState.ACCEPTED,
      ),
    };
  });
  return absences;
};

export const absencesByEmployments = (employments, absencesByDates) =>
  employments.reduce((acc, emp) => {
    const absences = { ...absencesByDates };
    Object.keys(absences).forEach((date) => {
      absences[date] = absences[date].filter(
        (abs) => abs.employment.id === emp.id,
      );
    });
    acc[emp.id] = absences;
    return acc;
  }, {});

export const absencesByPositions = (
  positions: Position[],
  employments: Employment[],
  absencesByDates: Record<string, Absence[]>,
) =>
  positions.reduce((acc, pos) => {
    const absences: Record<string, Absence[]> = { ...absencesByDates };
    Object.keys(absences).forEach((date) => {
      absences[date] = absences[date].filter((abs) => {
        const employment = employments.find(
          (it) => it.id === abs.employment.id,
        );
        if (!employment) {
          return abs.employment.activeLocationsPositionIds.includes(
            pos.locationsPositionId,
          );
        }
        return employment.presentLocationsPositionIds.includes(
          pos.locationsPositionId,
        );
      });
    });
    acc[pos.locationsPositionId] = absences;
    return acc;
  }, {});
