import RootStoreState from 'store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLShiftPresetsQuery,
  GQLShiftPresetFragmentFragment,
  GQLShiftPresetsQueryVariables,
  GQLShiftPreset,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { sortByTime } from 'utils/date-related';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import ShiftPresetsGql from './queries/ShiftPresets.gql';

export const shiftPresetsNS = namespace('shiftPresets');

export type StoreState = ById<GQLShiftPreset>;

export type ShiftPreset = GQLShiftPresetFragmentFragment;

export const FREE_DAY_PRESET_ID = 0;

export const FREE_DAY_PRESET: Pick<ShiftPreset, 'id' | 'name' | 'shortName'> = {
  id: FREE_DAY_PRESET_ID,
  name: 'free',
  shortName: 'free',
};

export type FetchAllShiftPresetsFunction = (
  payload: Omit<GQLShiftPresetsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      sortedByStartsAt: (_, getters) =>
        [...getters.items].sort((x, y) =>
          sortByTime(new Date(x.startsAt), new Date(y.startsAt)),
        ),
      getByIdWithFallback: (_, getters) => (id: number) => {
        if (id === FREE_DAY_PRESET_ID) {
          return FREE_DAY_PRESET;
        }
        return getters.getById(id);
      },
    },
  };

  const fetchAll: ActionProvider<
    GQLShiftPresetsQuery,
    GQLShiftPresetsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllShiftPresetsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: ShiftPresetsGql,
      resultKey: 'shiftPresets',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createNormalizedStore<ShiftPreset, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getStore;
