import SnackbarAction from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import { TFunction } from 'i18next';
import camelCase from 'lodash/camelCase';
import { SentryTag } from 'services/logger/SentryTransport';

export type PayloadParameter<T extends (...args: any) => any> =
  Parameters<T>[0];

export type OmitCompanyId<T extends { companyId: any }> = Omit<T, 'companyId'>;

interface LegacyApiErrorBody {
  error: 'invalid';
  invalid: Record<
    string,
    {
      error: string;
      value?: any;
    }[]
  >;
}

interface ApiErrorBody {
  message: string;
  errors: {
    error_key: string;
    message: string;
    data: Record<string, any>;
  }[];
}

export interface GQLResponseExtension {
  body: LegacyApiErrorBody | ApiErrorBody;
  status: number;
  statusText: string;
  url: string;
}

enum GenericErrorsKey {
  SHIFTPLAN_IS_LOCKED = 'shiftplanIsLocked',
}

export enum StoreActionState {
  CONFLICT,
  ERROR,
  NOT_FOUND,
  PENDING,
  SUCCESS,
}

export type StoreActionResult =
  | {
      state: StoreActionState.SUCCESS;
      entityId?: number;
      meta?: any;
    }
  | {
      state: StoreActionState.NOT_FOUND;
    }
  | {
      state: StoreActionState.ERROR;
      error?: GQLResponseExtension | string;
    }
  | {
      state: StoreActionState.CONFLICT;
      canManage?: boolean;
      conflicts?: any;
    };

export type GetById<T> = (id?: number | string | null) => T | undefined;

export type GetMultipleById<T> = (id?: number | null, ...other) => T[];

export type GetByCacheIdentifier<T> = (cacheIdentifier: number | string) => T[];

const isLegacyErrorBody = (
  body: LegacyApiErrorBody | ApiErrorBody,
): body is LegacyApiErrorBody => 'error' in body && body.error in body;

const isErrorBody = (
  body: LegacyApiErrorBody | ApiErrorBody,
): body is ApiErrorBody =>
  'errors' in body && Array.isArray(body.errors) && 'message' in body;

const buildLegacyErrorResponse = (body: LegacyApiErrorBody) => {
  const { error: errorKey } = body;
  const [firstErrorKey, [firstErrorVal] = []] =
    Object.entries(body[errorKey])[0] || [];
  if (!firstErrorVal) {
    return undefined;
  }
  const errorKeyString = [
    camelCase(firstErrorKey),
    camelCase(firstErrorVal.error),
  ]
    .filter(Boolean)
    .join('.');

  return { key: errorKeyString, val: { ...firstErrorVal } };
};

const buildErrorResponse = (body: ApiErrorBody) => {
  const { errors } = body;
  const { error_key: firstErrorKey, data } = errors[0];
  const [value] = Object.values(data) || [];
  return { key: camelCase(firstErrorKey), val: { value } };
};

export const getFirstErrorFromResponse = (
  response: GQLResponseExtension | undefined,
) => {
  if (!response) {
    return undefined;
  }

  if ('body' in response && isLegacyErrorBody(response.body)) {
    return buildLegacyErrorResponse(response.body);
  }
  if ('body' in response && isErrorBody(response.body)) {
    return buildErrorResponse(response.body);
  }
  return undefined;
};

export const getFirstErrorMessageFromResponse = (
  response: GQLResponseExtension | undefined,
  t: TFunction,
  pathPrefix?: string,
) => {
  const errorResponse = getFirstErrorFromResponse(response);
  if (!errorResponse) {
    return undefined;
  }

  const { key, val } = errorResponse;
  const keyString = [pathPrefix || '', key].filter(Boolean).join('.');
  const translation = t(keyString, { ...val });
  return translation !== keyString ? translation : undefined;
};

export function getTranslationPathError<T extends Vue>(
  response: StoreActionResult,
  vm: T,
  translationPath: string,
  genericError = 'general.error.unknown',
) {
  if (response.state === StoreActionState.ERROR) {
    vm.$logInfo({
      tags: [[SentryTag.COMPONENT, vm.$options.name || 'unknown component']],
      message: JSON.stringify(response),
    });

    const error = getFirstErrorFromResponse(
      typeof response.error !== 'string' ? response.error : undefined,
    );

    let message;

    if (
      error &&
      Object.values(GenericErrorsKey).includes(error.key as GenericErrorsKey)
    ) {
      message = vm.$t(['general.error', error.key].join('.'), { ...error.val });
    } else {
      message = error
        ? vm.$t([translationPath, error.key].join('.'), { ...error.val })
        : vm.$t(genericError);
    }
    return message;
  }
  return '';
}

export function showSnackbarWithFailureMessage<T extends Vue>(
  response: StoreActionResult,
  vm: T,
  translationPath: string,
  genericError = 'general.error.unknown',
) {
  if (response.state === StoreActionState.ERROR) {
    const message = getTranslationPathError(
      response,
      vm,
      translationPath,
      genericError,
    );
    vm.$store.dispatch(`snackbar/${SnackbarAction.SHOW}`, {
      kind: AlertKind.ERROR,
      message,
      timeout: 5000,
    });
  }
}

/**
 * @param vm A Vue instance, usually `this` when used in a component
 * @param entity Entity to use as the parameter for storeAction
 * @param storeAction A function reference to the store action to use
 * @param translationPath Path to the error sub-structure in the translations
 * @returns {StoreActionResult} Untouched response of the store action
 */
export async function executeStoreActionWithFailureSnackbar<
  T,
  TContext extends Vue,
>(
  vm: TContext,
  entity: T,
  storeAction: (entity: T) => Promise<StoreActionResult>,
  translationPath: string,
  genericError = 'general.error.unknown',
) {
  const response = await storeAction(entity);

  showSnackbarWithFailureMessage<TContext>(
    response,
    vm,
    translationPath,
    genericError,
  );

  return response;
}
