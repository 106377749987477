import { namespace } from 'vuex-class';
import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import getTableStore from 'components/table/store/Store';
import type { StoreState as TableStoreState } from 'components/table/store/Store';
import { SortDirection } from 'components/table/types';
import {
  GQLAvailabilityAggregationsQuery,
  GQLAvailabilityAggregationsQueryVariables,
} from 'codegen/gql-types';
import AvailabilityAggregationsGql from '../queries/AvailabilityAggregations.gql';
import { AvailabilityAggregation } from '../types';

export const availabilityAggregationsNS = namespace('availabilityAggregations');

export interface StoreState {
  availabilityAggregationsById: Record<number, AvailabilityAggregation>;
}

export enum Sort {
  EMPLOYEE_LAST_NAME = 'employee_last_name',
  CONTRACT_HOURS = 'contract_hours',
  AVAILABLE_HOURS = 'available_hours',
}

export interface Filters {
  locationIds: number[];
  positionIds: number[];
  employmentIds: number[];
  startsAt: string;
  endsAt: string;
}

export interface ModuleState extends StoreState {
  table: TableStoreState<AvailabilityAggregation, {}>;
}

const getAvailabiltyAggregationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
) =>
  getTableStore<
    AvailabilityAggregation,
    Sort,
    Filters,
    GQLAvailabilityAggregationsQuery,
    GQLAvailabilityAggregationsQueryVariables,
    {}
  >(graphqlClient, {
    query: AvailabilityAggregationsGql,
    getVariables: (rootState) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: rootState.auth.currentCompanyId!,
    }),
    transformResponse: (response) => {
      const {
        availabilityAggregations: {
          items: data,
          pagination: { count },
        },
      } = response.data;

      return {
        data,
        count,
      };
    },
    initialState: {
      filters: {
        employmentIds: [],
        locationIds: [],
        positionIds: [],
      },
      perPage: 10,
      sort: {
        direction: SortDirection.ASC,
        key: Sort.EMPLOYEE_LAST_NAME,
      },
    },
  });

export default getAvailabiltyAggregationsStore;
