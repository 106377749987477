import Spinner from 'components/spinner/Spinner';
import { getUrlWithApiPrefix } from 'src/utils/url';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './dashboard-redirect.css';

@Component
export default class DashboardRedirect extends TsxComponent<{}> {
  public mounted() {
    if (
      typeof window !== 'undefined' &&
      !window.location.search.includes('debug=true')
    ) {
      window.location.replace(
        getUrlWithApiPrefix('/companies/0/app/dashboard'),
      );
    }
  }

  public render() {
    return (
      <div class={styles.dashboardRedirect}>
        <Spinner class={styles.dashboardRedirectSpinner} />
        <p>{this.$t('dashboardRedirectMessage')}</p>
      </div>
    );
  }
}
