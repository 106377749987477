enum Mutation {
  SET_AUTHENTICATION_TOKEN = 'setAuthenticationToken',
  SET_EMAIL = 'setEmail',
  SET_CURRENT_COMPANY = 'setCurrentCompany',
  SET_CURRENT_COMPANY_ID = 'setCurrentCompanyId',
  SET_CURRENT_EMPLOYMENT = 'setCurrentEmployment',
  SET_CURRENT_EMPLOYMENT_ID = 'setCurrentEmploymentId',
  SET_RIGHTS = 'setRights',
  SET_EMPLOYMENTS = 'setEmployments',
  SET_COMPANIES = 'setCompanies',
}

export default Mutation;
