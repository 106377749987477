enum Action {
  ASSIGN_EMPLOYMENT = 'assignEmployment',
  CREATE_INDIVIDUAL_ROTATION = 'createIndividualRotation',
  CREATE_ROTATION = 'createRotation',
  CREATE_ROTATION_GROUP = 'createRotationGroup',
  CREATE_ROTATION_GROUPS = 'createRotationGroups',
  DELETE_ROTATION_GROUP = 'deleteRotationGroup',
  FETCH_ROTATION = 'fetchRotation',
  GENERATE_ROTATION_GROUPS = 'generateRotationGroups',
  MOVE_ROTATION_GROUP = 'moveRotationGroup',
  ON_INPUT = 'onInput',
  RESET = 'reset',
  SET_ROTATION_GROUPS = 'setRotationGroups',
  SET_ROTATION_ID = 'setRotationId',
  UNASSIGN_EMPLOYMENT = 'unassignEmployment',
  UPDATE_ROTATION = 'updateRotation',
  UPDATE_ROTATION_DAY = 'updateRotationDay',
  UPDATE_ROTATION_GROUP = 'updateRotationGroup',
  UPDATE_ROTATION_GROUP_NAME = 'updateRotationGroupName',
  UPDATE_ROTATION_GROUPS = 'updateRotationGroups',
}

export default Action;
