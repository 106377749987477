import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './spinner.css';

@Component
class Spinner extends TsxComponent<{}> {
  public render() {
    return (
      <div class={styles.spinner}>
        <div class={styles.spinnerIcon}></div>
      </div>
    );
  }
}

export default Spinner;
