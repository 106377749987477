import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLApplyRotationMutation,
  GQLApplyRotationMutationVariables,
  GQLCreateShiftplanMutation,
  GQLCreateShiftplanMutationVariables,
  GQLCreateShiftplanFromShiftRotationMutation,
  GQLCreateShiftplanFromShiftRotationMutationVariables,
  GQLDeleteShiftplanMutation,
  GQLDeleteShiftplanMutationVariables,
  GQLFetchShiftplanQuery,
  GQLFetchShiftplanQueryVariables,
  GQLUpdateShiftplanMutation,
  GQLUpdateShiftplanMutationVariables,
  GQLShiftplanUpdateInput,
  GQLShiftplanFragmentFragment,
  GQLShiftplansQueryVariables,
  GQLShiftplansQuery,
  GQLAssignEmploymentsToOpenShiftsMutationVariables,
  GQLAssignEmploymentsToOpenShiftsMutation,
  GQLPublishShiftplanMutation,
  GQLPublishShiftplanMutationVariables,
  GQLCopyShiftplanMutationVariables,
  GQLCopyShiftplanMutation,
  GQLImportShiftplanMutationVariables,
  GQLImportShiftplanMutation,
} from 'codegen/gql-types';
import {
  HasAnyCurrentLocationRightFunction,
  HasAnyRightFunction,
} from 'components/auth/store/Store';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  handleUnexpectedResult,
  isSuccessResult,
} from 'store/normalized-store';
import {
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import ShiftplanAction from './Action';
import ApplyRotationGql from './queries/ApplyRotation.gql';
import CreateShiftplanGql from './queries/CreateShiftplan.gql';
import CreateShiftplanFromShiftRotationGql from './queries/CreateShiftplanFromShiftRotation.gql';
import DeleteShiftplanGql from './queries/DeleteShiftplan.gql';
import AssignEmploymentsToOpenShiftsGql from './queries/AssignEmploymentsToOpenShifts.gql';
import FetchShiftplanGql from './queries/FetchShiftplan.gql';
import ShiftplansGql from './queries/Shiftplans.gql';
import UpdateShiftplanGql from './queries/UpdateShiftplan.gql';
import PubishShiftPlanGql from './queries/PublishShiftplan.gql';
import CopyShiftplan from './queries/CopyShiftplan.gql';
import ImportShiftplan from './queries/ImportShiftplan.gql';

export const shiftplansNS = namespace('shiftplans');

export type StoreState = ById<Shiftplan>;

export type Shiftplan = GQLShiftplanFragmentFragment;

export type DeleteShiftplanFunction = (
  payload: Omit<GQLDeleteShiftplanMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type AssignEmploymentsToOpenShiftsFunction = (
  payload: Omit<GQLAssignEmploymentsToOpenShiftsMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAllShiftplansFunction = (
  payload: Omit<GQLShiftplansQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchShiftplanFunction = (
  payload: Omit<GQLFetchShiftplanQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateShiftplanFunction = (payload: {
  id: number;
  shiftplan: Omit<
    GQLShiftplanUpdateInput,
    'companyId' | 'endsAt' | 'startsAt'
  > & {
    endsAt: Date;
    startsAt: Date;
  };
}) => Promise<StoreActionResult>;

export type ApplyRotationFunction = (
  payload: Omit<GQLApplyRotationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateShiftplanFromShiftRotationFunction = (
  payload: Omit<
    GQLCreateShiftplanFromShiftRotationMutationVariables,
    'companyId'
  >,
) => Promise<StoreActionResult>;

export type CreateShiftplanFunction = (
  payload: Omit<GQLCreateShiftplanMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CopyShiftplanFunction = (
  payload: Omit<GQLCopyShiftplanMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type PublishShiftplanFunction = (
  payload: Omit<GQLPublishShiftplanMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type ImportShiftplanFunction = (
  payload: Omit<GQLImportShiftplanMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;
type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getShiftplansStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByLocationId: (state: StoreState) => (locationId: number) =>
        // FAQ: implicitly uses sorting by ID which is also what the API returns
        Object.values(state.byId).filter(
          (item) => item.locationId === locationId,
        ),
      getOrderedShiftplanByLocationId:
        (state: StoreState, getters) => (locationId: number) =>
          // FAQ: implicitly uses sorting by ID which is also what the API returns
          getters.ordered.filter((item) => item.locationId === locationId),
      canCreateShiftplan: (
        state,
        getters,
        rootState: RootStoreState,
        rootGetters,
      ) => {
        const isSuperAdmin = rootGetters['auth/isSuperAdmin'];

        if (isSuperAdmin) {
          return true;
        }

        const hasAnyRight: HasAnyRightFunction =
          rootGetters['auth/hasAnyRight'];
        const hasAnyCurrentLocationRight: HasAnyCurrentLocationRightFunction =
          rootGetters['auth/hasAnyCurrentLocationRight'];
        const { currentEmployment } = rootState.auth;

        return (
          !!currentEmployment?.isStakeholder &&
          (hasAnyRight('payments_manage_all', 'shifts_manage_all') ||
            hasAnyCurrentLocationRight(
              'payment_manage_right',
              'shift_manage_right',
            ))
        );
      },
    },
    actions: {
      [ShiftplanAction.APPLY_ROTATION]: async (
        { rootState },
        payload: PayloadParameter<ApplyRotationFunction>,
      ): ReturnType<ApplyRotationFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.mutate<
            GQLApplyRotationMutation,
            GQLApplyRotationMutationVariables
          >({
            mutation: ApplyRotationGql,
            variables: {
              ...payload,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          if (!isSuccessResult(result, 'applyShiftplanRotation')) {
            return handleUnexpectedResult(
              ShiftplanAction.APPLY_ROTATION as any,
              logger,
            );
          }

          return {
            state: result.data?.applyShiftplanRotation?.jobId
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
            entityId: result.data?.applyShiftplanRotation?.id,
            meta: { jobId: result.data?.applyShiftplanRotation?.jobId },
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
      [ShiftplanAction.CREATE_SHIFTPLAN_FROM_SHIFT_ROTATION]: async (
        { rootState },
        payload: PayloadParameter<CreateShiftplanFromShiftRotationFunction>,
      ): ReturnType<CreateShiftplanFromShiftRotationFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.mutate<
            GQLCreateShiftplanFromShiftRotationMutation,
            GQLCreateShiftplanFromShiftRotationMutationVariables
          >({
            mutation: CreateShiftplanFromShiftRotationGql,
            variables: deepTransformDates({
              ...payload,
              companyId: rootState.auth.currentCompanyId,
              shiftRotationId: payload.shiftRotationId,
              input: payload.input,
            }),
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          if (!isSuccessResult(result, 'createShiftplanFromShiftRotation')) {
            return handleUnexpectedResult(
              ShiftplanAction.CREATE_SHIFTPLAN_FROM_SHIFT_ROTATION as any,
              logger,
            );
          }

          return {
            state: result.data?.createShiftplanFromShiftRotation?.jobId
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
            entityId: result.data?.createShiftplanFromShiftRotation?.id,
            meta: {
              jobId: result.data?.createShiftplanFromShiftRotation?.jobId,
            },
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
      [ShiftplanAction.ASSIGN_EMPLOYMENTS_TO_OPEN_SHIFTS]: async (
        { rootState },
        payload: PayloadParameter<AssignEmploymentsToOpenShiftsFunction>,
      ): ReturnType<AssignEmploymentsToOpenShiftsFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.mutate<
            GQLAssignEmploymentsToOpenShiftsMutation,
            GQLAssignEmploymentsToOpenShiftsMutationVariables
          >({
            mutation: AssignEmploymentsToOpenShiftsGql,
            variables: {
              ...payload,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].message,
            };
          }

          if (!isSuccessResult(result, 'assignEmploymentsToOpenShifts')) {
            return handleUnexpectedResult(
              ShiftplanAction.ASSIGN_EMPLOYMENTS_TO_OPEN_SHIFTS as any,
              logger,
            );
          }

          return {
            state: result.data?.assignEmploymentsToOpenShifts?.jobIdentifier
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
            entityId:
              result.data?.assignEmploymentsToOpenShifts?.result.shiftplanId,
            meta: {
              jobId: result.data?.assignEmploymentsToOpenShifts?.id,
              jobIdentifier:
                result.data?.assignEmploymentsToOpenShifts?.jobIdentifier,
            },
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
      [ShiftplanAction.PUBLISH]: async (
        { rootState },
        payload: PayloadParameter<PublishShiftplanFunction>,
      ): ReturnType<PublishShiftplanFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.mutate<
            GQLPublishShiftplanMutation,
            GQLPublishShiftplanMutationVariables
          >({
            mutation: PubishShiftPlanGql,
            variables: {
              ...payload,
              id: payload.id,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].message,
            };
          }

          if (!isSuccessResult(result, 'publishShiftplan')) {
            return handleUnexpectedResult(
              ShiftplanAction.PUBLISH as any,
              logger,
            );
          }

          return result.data?.publishShiftplan?.success
            ? { state: StoreActionState.SUCCESS }
            : { state: StoreActionState.ERROR };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
      [ShiftplanAction.COPY_SHIFTPLAN]: async (
        { rootState },
        payload: PayloadParameter<CopyShiftplanFunction>,
      ): ReturnType<CopyShiftplanFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.mutate<
            GQLCopyShiftplanMutation,
            GQLCopyShiftplanMutationVariables
          >({
            mutation: CopyShiftplan,
            variables: {
              copyShiftplanParams: payload.copyShiftplanParams,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].message,
            };
          }

          if (!isSuccessResult(result, 'copyShiftplan')) {
            return handleUnexpectedResult(
              ShiftplanAction.COPY_SHIFTPLAN as any,
              logger,
            );
          }

          return {
            state: result.data?.copyShiftplan?.jobIdentifier
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
            entityId: result.data?.copyShiftplan?.result.shiftplanId,
            meta: {
              jobId: result.data?.copyShiftplan?.id,
              jobIdentifier: result.data?.copyShiftplan?.jobIdentifier,
            },
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
      [ShiftplanAction.IMPORT_SHIFTPLAN]: async (
        { rootState },
        payload: PayloadParameter<ImportShiftplanFunction>,
      ): ReturnType<ImportShiftplanFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            throw new TypeError('currentCompanyId not provided');
          }

          const result = await graphqlClient.mutate<
            GQLImportShiftplanMutation,
            GQLImportShiftplanMutationVariables
          >({
            mutation: ImportShiftplan,
            variables: {
              importShiftplanParams: payload.importShiftplanParams,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].message,
            };
          }

          if (!isSuccessResult(result, 'importShiftplan')) {
            return handleUnexpectedResult(
              ShiftplanAction.IMPORT_SHIFTPLAN as any,
              logger,
            );
          }

          return {
            state: result.data?.importShiftplan?.jobIdentifier
              ? StoreActionState.SUCCESS
              : StoreActionState.ERROR,
            entityId: result.data?.importShiftplan?.result.shiftplanId,
            meta: {
              jobId: result.data?.importShiftplan?.id,
              jobIdentifier: result.data?.importShiftplan?.jobIdentifier,
            },
          };
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
    },
  };

  const remove: ActionProvider<
    GQLDeleteShiftplanMutation,
    GQLDeleteShiftplanMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteShiftplanFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteShiftplanGql,
      resultKey: 'deleteShiftplan',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetch: ActionProvider<
    GQLFetchShiftplanQuery,
    GQLFetchShiftplanQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchShiftplanFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: FetchShiftplanGql,
      resultKey: 'shiftplans',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetchAll: ActionProvider<
    GQLShiftplansQuery,
    GQLShiftplansQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllShiftplansFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: ShiftplansGql,
      resultKey: 'shiftplans',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const update: ActionProvider<
    GQLUpdateShiftplanMutation,
    GQLUpdateShiftplanMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateShiftplanFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: UpdateShiftplanGql,
      resultKey: 'updateShiftplan',
      variables: deepTransformDates({
        id: payload.id,
        shiftplan: {
          ...payload.shiftplan,
        },
        companyId: rootState.auth.currentCompanyId,
      }),
    };
  };

  const create: ActionProvider<
    GQLCreateShiftplanMutation,
    GQLCreateShiftplanMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateShiftplanFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateShiftplanGql,
      resultKey: 'createShiftplan',
      variables: deepTransformDates({
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      }),
    };
  };

  return createNormalizedStore<Shiftplan, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.FETCH]: fetch,
      [Action.FETCH_ALL]: fetchAll,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftplansStore;
