import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { formatNumberWithPrecision } from 'src/utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './conflicts.css';

@Component
export default class MaximumEarningShiftConflict extends TsxComponent<{
  conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MaximumEarningShiftConflict' }
  >;
  isSelf?: boolean;
}> {
  @Prop()
  public conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MaximumEarningShiftConflict' }
  >;

  @Prop()
  public isSelf?: boolean;

  protected get translationKey() {
    return this.isSelf
      ? 'conflicts.maximumEarning.textSelf'
      : 'conflicts.maximumEarning.textStakeholder';
  }

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.maximumEarning.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {this.$t(this.translationKey, {
            currency: this.conflict.currency,
            employmentMaxPayment: formatNumberWithPrecision(
              this.$i18n.i18next.language,
              this.conflict.employmentMaxPayment,
              2,
              2,
            ),
            totalPayment: formatNumberWithPrecision(
              this.$i18n.i18next.language,
              this.conflict.totalPayment,
              2,
              2,
            ),
          })}
        </p>
      </section>
    );
  }
}
