import { VueConstructor } from 'vue';
import { ViewKind } from 'src/components/calendar-common/Enums';
import Datadog, { CustomTiming, CustomView } from './Datadog';

declare module 'vue/types/vue' {
  interface Vue {
    $addTiming: (timingName: CustomTiming) => void;
    $startView: (viewName: ViewKind | CustomView) => void;
  }
}

class VueDatadog {
  private static datadogRum: Datadog;

  public static setDatadog(datadogRum: Datadog) {
    VueDatadog.datadogRum = datadogRum;
  }

  public static install(Vue: VueConstructor) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$addTiming = (params: string) => {
      if (this.datadogRum.isReady) {
        this.datadogRum.instance.addTiming(params);
      }
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$startView = (params: string) => {
      if (this.datadogRum.isReady) {
        this.datadogRum.instance.startView(params);
      }
    };
  }
}

export default VueDatadog;
