import { ApolloLink } from 'apollo-link';
import { DATE_API_FORMAT } from 'utils/date-related';
import { format } from 'date-fns';

type PersistNull<TOriginalType, TReplacementType> = TOriginalType extends null
  ? TReplacementType | null
  : TReplacementType;

type Primitive = string | number | boolean | null | undefined;

type ReplaceDateWithString<T> = T extends Date
  ? string
  : T extends (infer P)[]
  ? ReplaceDateWithString<P>[]
  : T extends Primitive
  ? T
  : { [P in keyof T]: ReplaceDateWithString<T[P]> };

export function deepTransformDates<T>(edge: T): ReplaceDateWithString<T>;
export function deepTransformDates(edge: any): any {
  if (edge instanceof Date) {
    return format(edge, DATE_API_FORMAT);
  }

  if (Array.isArray(edge)) {
    return edge.map((it) => deepTransformDates(it));
  }

  if (edge instanceof Object && edge !== null) {
    return Object.entries(edge).reduce((acc, [key, value]) => {
      acc[key] = deepTransformDates(value);
      return acc;
    }, {});
  }

  return edge;
}

export type GraphqlVariablesWrapper<
  TOriginalType extends object,
  TKeys extends keyof TOriginalType,
  TReplacementType = any,
> = {
  [key in keyof TOriginalType]: key extends TKeys
    ? PersistNull<TOriginalType[key], TReplacementType>
    : TOriginalType[key];
};

export type DatesWrapper<
  TOriginalType extends object,
  TKeys extends keyof TOriginalType,
> = GraphqlVariablesWrapper<TOriginalType, TKeys, Date>;

class DatesTransformLink extends ApolloLink {
  public constructor() {
    super((operation, forward) => {
      // eslint-disable-next-line no-param-reassign
      operation.variables = deepTransformDates(operation.variables);
      if (forward) {
        return forward(operation);
      }
      return null;
    });
  }
}

export default DatesTransformLink;
