export const sortByLastName = <T extends { lastName: string }>(x: T, y: T) =>
  x.lastName.localeCompare(y.lastName);

export const sortByName = <T extends { name: string }>(x: T, y: T) =>
  x.name.localeCompare(y.name);

export const sortByStartsAt =
  (reverse = false) =>
  <T extends { startsAt: string | Date }>(x: T, y: T) => {
    const result =
      new Date(x.startsAt).valueOf() - new Date(y.startsAt).valueOf();
    return reverse ? -result : result;
  };

export const sortBySortOrder =
  (reverse?: boolean) =>
  (x: { sort?: number | null }, y: { sort?: number | null }) => {
    if (typeof x.sort !== 'number' || typeof y.sort !== 'number') {
      return 0;
    }

    return reverse ? y.sort - x.sort : x.sort - y.sort;
  };

export const sortBySortPosition = (
  x: { sortPosition: number | null },
  y: { sortPosition: number | null },
) => {
  if (x.sortPosition === null || y.sortPosition === null) {
    return 0;
  }

  return x.sortPosition - y.sortPosition;
};
