import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { getRandomString } from 'src/utils/random';
import { EventPayload } from 'src/utils/events';
import { Option } from 'components/select-panel/SelectPanel';
import styles from './input-combobox.css';
import InputPillSelectBase from '../input-pill-select/InputPillSelectBase';

export interface Props<T extends string | number> {
  isDisabled?: boolean;
  error?: string;
  id?: string;
  isValid?: boolean;
  label?: string;
  name?: string;
  options: Option<T>[];
  value?: T;
  placeholder?: string;
  isError?: boolean;
  hintText?: string;
  isRequired?: boolean;
}

interface Events<T extends string | number> {
  onChange: EventPayload<T, HTMLInputElement, UIEvent>;
}

@Component
class InputCombobox<T extends string | number> extends TSXComponent<
  Props<T>,
  Events<T>
> {
  public $refs: {
    container: HTMLDivElement;
    input: Vue;
    list: Vue;
  };

  private searchString = '';

  @Prop({ default: () => `input-combobox-${getRandomString()}` })
  protected id: NonNullable<Props<T>['id']>;

  @Prop()
  protected label: Props<T>['label'];

  @Prop({ default: false })
  protected isDisabled: NonNullable<Props<T>['isDisabled']>;

  @Prop()
  public options: Props<T>['options'];

  @Prop()
  public name: Props<T>['name'];

  @Prop()
  public isValid: Props<T>['isValid'];

  @Prop()
  public error: Props<T>['error'];

  @Prop()
  public value: Props<T>['value'];

  @Prop()
  public placeholder: Props<T>['placeholder'];

  @Prop()
  public isError: Props<T>['isError'];

  @Prop()
  public hintText: Props<T>['hintText'];

  @Prop({ default: false })
  public isRequired: Props<T>['isRequired'];

  private get isInvalid(): boolean {
    return (
      this.isError ||
      (this.$attrs.required !== undefined &&
        (this.value === undefined || this.value.toString().length === 0))
    );
  }

  private get optionsWithTrimmedLowerCaseLabels(): (Option<T> & {
    trimmedLowerCaseLabel: string;
  })[] {
    return this.options.map((option) => ({
      ...option,
      trimmedLowerCaseLabel: option.label.trim().toLowerCase(),
    }));
  }

  private onSelect(
    eventPayload: EventPayload<
      T[],
      HTMLInputElement | HTMLButtonElement,
      MouseEvent | InputEvent
    >,
  ) {
    this.$emit('change', {
      event: eventPayload.event,
      payload: eventPayload.payload[0],
    });
  }

  private get selectedOptions() {
    const selectedOptions = this.options.filter(
      ({ value }) => this.value === value,
    );

    return selectedOptions;
  }

  private get filteredOptions() {
    const trimmedLowerCaseSearchString = this.searchString.trim().toLowerCase();

    if (trimmedLowerCaseSearchString.length === 0) {
      return this.options;
    }

    return this.optionsWithTrimmedLowerCaseLabels.filter(
      ({ trimmedLowerCaseLabel }) =>
        trimmedLowerCaseLabel.includes(trimmedLowerCaseSearchString),
    );
  }

  public render() {
    return (
      <div class={styles.inputCombobox} ref="container">
        <div
          // the required props are there, just not in this element, but the way proposed by w3c
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-owns={`${this.id}-listbox`}
          aria-haspopup="listbox"
          id={`${this.id}-combobox`}
          class={styles.inputComboboxInputWrappper}
        >
          <InputPillSelectBase
            label={this.label}
            isMultiselect={false}
            class={styles.inputComboboxInput}
            placeholder={this.placeholder}
            name={this.name}
            value={this.value ? [this.value] : []}
            onChange={(e) => this.onSelect(e)}
            onSearchChange={(search) => {
              this.searchString = search;
            }}
            filteredOptions={this.filteredOptions}
            selectedOptions={this.selectedOptions}
            isError={!!this.error}
            required={!!this.isRequired}
            isDisabled={this.isDisabled}
          />
          {this.error && (
            <div class={styles.inputComboboxError}>{this.error}</div>
          )}
        </div>
        <span
          class={{
            [styles.inputComboboxHintText]: true,
            [styles.inputComboboxHintTextError]: this.isInvalid,
            [styles.inputComboboxHintTextSuccess]: this.isValid,
          }}
        >
          {this.hintText}
        </span>
      </div>
    );
  }
}

export default InputCombobox;
