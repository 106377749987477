import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import { mainViewNS, StoreState as MainViewStoreState } from '../store/Store';
import MainViewAction from '../store/Action';
import styles from './chat-button.css';
import { Size } from '../../types';

@Component
export default class ChatButton extends TsxComponent<{}> {
  @mainViewNS.Action(MainViewAction.OPEN_CHAT)
  protected openChat: () => void;

  @mainViewNS.Action(MainViewAction.CLOSE_CHAT)
  protected closeChat: () => void;

  @mainViewNS.State
  protected isChatOpen: MainViewStoreState['isChatOpen'];

  @mainViewNS.State
  protected hasNewChatMessage: MainViewStoreState['hasNewChatMessage'];

  public render() {
    return (
      <button
        onClick={() => (this.isChatOpen ? this.closeChat() : this.openChat())}
        type="button"
        class={{
          [styles.chatButton]: true,
          [styles.chatButtonWithNotification]:
            this.hasNewChatMessage && !this.isChatOpen,
        }}
      >
        <Icon
          size={Size.LARGE}
          name={this.isChatOpen ? IconName.CLEAR : IconName.CHAT}
        />
      </button>
    );
  }
}
