import RootStoreState from 'store/RootStoreState';
import type {
  HasAnyLocationRightFunction,
  HasAnyLocationsPositionRightFunction,
  HasAnyRightFunction,
} from 'components/auth/store/Store';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEvaluationMutation,
  GQLCreateEvaluationMutationVariables,
  GQLUpdateEvaluationMutation,
  GQLUpdateEvaluationMutationVariables,
  GQLEvaluationFragmentFragment,
  GQLEvaluationQuery,
  GQLEvaluationQueryVariables,
  GQLEvaluationsQuery,
  GQLEvaluationsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  handleUnexpectedResult,
  Mutation,
} from 'store/normalized-store';
import {
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import CreateEvaluationGql from './queries/CreateEvaluation.gql';
import UpdateEvaluationGql from './queries/UpdateEvaluation.gql';
import EvaluationsGql from './queries/Evaluations.gql';
import EvaluationGql from './queries/Evaluation.gql';
import EvaluationActions from './Actions';

export const evaluationsNS = namespace('evaluations');

export type StoreState = ById<GQLEvaluation>;

export type GQLEvaluation = Omit<GQLEvaluationFragmentFragment, 'id'> & {
  id: number;
};

export type CreateEvaluationFunction = (
  payload: Omit<GQLCreateEvaluationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateEvaluationFunction = (
  payload: GQLUpdateEvaluationMutationVariables,
) => Promise<StoreActionResult>;

export type FetchAllEvaluationsFunction = (
  payload: Omit<GQLEvaluationsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchEvaluationFunction = (
  payload: Omit<GQLEvaluationQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const transformEvaluation = (
  evaluation: GQLEvaluationFragmentFragment,
): GQLEvaluation => ({
  ...evaluation,
  id: evaluation.id === null ? -1 : evaluation.id,
});

const getEvaluationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByStaffShiftId: (state: StoreState) => (staffShiftId: number) =>
        Object.values<GQLEvaluation>(state.byId).find(
          (item) => item.staffShiftId === staffShiftId,
        ),
      hasEvaluationPayShowRight:
        (state, getters, rootState, rootGetters) =>
        (evaluation: GQLEvaluation) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const hasAnyRight: HasAnyRightFunction =
            rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction =
            rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction =
            rootGetters['auth/hasAnyLocationsPositionRight'];

          return (
            isSuperAdmin ||
            hasAnyRight('payments_show_all') ||
            hasAnyLocationRight(
              evaluation.location?.id,
              'payment_show_right',
            ) ||
            hasAnyLocationsPositionRight(
              evaluation.locationsPosition.id,
              'payment_show_right',
            )
          );
        },
      hasEvaluationPayManageRight:
        (state, getters, rootState, rootGetters) =>
        (evaluation: GQLEvaluation) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const hasAnyRight: HasAnyRightFunction =
            rootGetters['auth/hasAnyRight'];
          const hasAnyLocationRight: HasAnyLocationRightFunction =
            rootGetters['auth/hasAnyLocationRight'];
          const hasAnyLocationsPositionRight: HasAnyLocationsPositionRightFunction =
            rootGetters['auth/hasAnyLocationsPositionRight'];

          return (
            isSuperAdmin ||
            hasAnyRight('payments_manage_all') ||
            hasAnyLocationRight(
              evaluation.location?.id,
              'payment_manage_right',
            ) ||
            hasAnyLocationsPositionRight(
              evaluation.locationsPosition.id,
              'payment_manage_right',
            )
          );
        },
    },
    actions: {
      async [EvaluationActions.UPDATE_STATE](
        { commit, state },
        payload: PayloadParameter<UpdateEvaluationFunction>,
      ): ReturnType<UpdateEvaluationFunction> {
        try {
          const variables: GQLUpdateEvaluationMutationVariables = {
            ...payload,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient.mutate<
            GQLUpdateEvaluationMutation,
            GQLUpdateEvaluationMutationVariables
          >({
            mutation: UpdateEvaluationGql,
            variables,
          });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          const { updateEvaluation } = result.data || {};

          if (!updateEvaluation) {
            return handleUnexpectedResult(
              EvaluationActions.UPDATE_STATE as any,
              logger,
            );
          }

          if (updateEvaluation && updateEvaluation.success) {
            /*
             * Need to remove existing evaluations for staffShiftId
             * otherwise getter will return stale data
             */
            Object.values<GQLEvaluation>(state.byId)
              .filter((item) => item.staffShiftId === payload.staffShiftId)
              .forEach(({ id }) => commit(Mutation.REMOVE_ITEM, id));

            return { state: StoreActionState.SUCCESS };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
    },
  };

  const create: ActionProvider<
    GQLCreateEvaluationMutation,
    GQLCreateEvaluationMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateEvaluationGql,
      resultKey: 'createEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetchAll: ActionProvider<
    GQLEvaluationsQuery,
    GQLEvaluationsQueryVariables,
    GQLEvaluation
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllEvaluationsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: EvaluationsGql,
      resultKey: 'evaluations',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: transformEvaluation,
    };
  };

  const fetch: ActionProvider<
    GQLEvaluationQuery,
    GQLEvaluationQueryVariables,
    GQLEvaluation
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: EvaluationGql,
      resultKey: 'evaluations',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: transformEvaluation,
    };
  };

  return createNormalizedStore<GQLEvaluation, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.FETCH_ALL]: fetchAll,
      [Action.FETCH]: fetch,
    },
    graphqlClient,
    logger,
  });
};

export default getEvaluationsStore;
