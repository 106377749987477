import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import BaseTooltip from './BaseTooltip';

export interface TooltipProps {
  text: string | JSX.Element | Vue;
  isHidden?: boolean;
}
@Component
export default class Tooltip extends TsxComponent<TooltipProps> {
  @Prop()
  public text: TooltipProps['text'];

  @Prop({ default: false })
  public isHidden: TooltipProps['isHidden'];

  public render() {
    return (
      <BaseTooltip isHidden={this.isHidden} text={this.text}>
        {this.$slots.default}
      </BaseTooltip>
    );
  }
}
