import RootStoreState from 'store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLLocationShiftplanPublishSettingQueryVariables,
  GQLLocationShiftplanPublishSettingQuery,
  GQLUpdateLocationShiftplanPublishSettingMutationVariables,
  GQLUpdateLocationShiftplanPublishSettingMutation,
  GQLLocationShiftplanPublishSettings,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import FetchLocationShiftplanPublishSettingGql from './queries/FetchLocationShiftplanPublishSetting.gql';
import UpdateLocationShiftplanPublishSettingGql from './queries/UpdateLocationShiftplanPublishSetting.gql';

export const shiftplanPublishNS = namespace('shiftplanPublish');

export type ShiftPublishSetting = Omit<
  GQLLocationShiftplanPublishSettings,
  'locationId'
> & { id: number };
export type StoreState = ById<ShiftPublishSetting>;

export type FetchShiftPublishSettingsFunction = (
  payload: Omit<
    GQLLocationShiftplanPublishSettingQueryVariables,
    'companyId' | 'locationId'
  >,
) => Promise<StoreActionResult>;

export type UpdateShiftPublishSettingsFunction = (
  payload: Omit<
    GQLUpdateLocationShiftplanPublishSettingMutationVariables,
    'companyId' | 'locationId'
  >,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const transformPublishSettings = (
  publishSettings: GQLLocationShiftplanPublishSettings,
): ShiftPublishSetting => {
  const {
    companyId,
    message,
    sendAssignedShifts,
    sendMessage,
    sendOpenShifts,
    locationId,
  } = publishSettings;
  return {
    id: locationId,
    companyId,
    message,
    sendAssignedShifts,
    sendMessage,
    sendOpenShifts,
  };
};

const getStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByLocationId: (state: StoreState) => (locationId: number) =>
        state.byId[locationId],
    },
  };

  const fetch: ActionProvider<
    GQLLocationShiftplanPublishSettingQuery,
    GQLLocationShiftplanPublishSettingQueryVariables
  > = ({ rootState }: StoreActionContext) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    if (!rootState.auth.currentLocationId) {
      throw new TypeError('currentLocationId not provided');
    }

    return {
      query: FetchLocationShiftplanPublishSettingGql,
      resultKey: 'locationShiftplanPublishSetting',
      variables: {
        locationId: rootState.auth.currentLocationId,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: transformPublishSettings,
    };
  };

  const update: ActionProvider<
    GQLUpdateLocationShiftplanPublishSettingMutation,
    GQLUpdateLocationShiftplanPublishSettingMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateShiftPublishSettingsFunction>,
  ) => {
    if (!rootState.auth.currentLocationId) {
      throw new TypeError('currentLocationId not provided');
    }

    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: UpdateLocationShiftplanPublishSettingGql,
      resultKey: 'updateLocationShiftplanPublishSetting',
      variables: {
        locationId: rootState.auth.currentLocationId,
        companyId: rootState.auth.currentCompanyId,
        ...payload,
      },
      transform: transformPublishSettings,
    };
  };

  return createNormalizedStore<ShiftPublishSetting, StoreState, RootStoreState>(
    {
      store,
      provide: {
        [Action.FETCH]: fetch,
        [Action.UPDATE]: update,
      },
      graphqlClient,
      logger,
    },
  );
};

export default getStore;
