import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreatePositionMutation,
  GQLCreatePositionMutationVariables,
  GQLPositionCreateInput,
  GQLPositionFragmentFragment,
  GQLPositionsQuery,
  GQLPositionsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult, PayloadParameter } from 'utils/store';
import CreatePositionGql from './queries/CreatePosition.gql';
import PositionGql from './queries/Positions.gql';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from '../normalized-store';

export const positionsNS = namespace('positions');

export type StoreState = ById<Position>;

export type Position = GQLPositionFragmentFragment;

export type FetchAllPositionsFunction = (
  payload: Omit<GQLPositionsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;
export type CreatePositionFunction = (payload: {
  position: Omit<GQLPositionCreateInput, 'companyId'>;
}) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getPositionsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const create: ActionProvider<
    GQLCreatePositionMutation,
    GQLCreatePositionMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreatePositionFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreatePositionGql,
      resultKey: 'createPosition',
      variables: {
        position: {
          ...payload.position,
          companyId: rootState.auth.currentCompanyId,
        },
      },
    };
  };

  const fetchAll: ActionProvider<
    GQLPositionsQuery,
    GQLPositionsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllPositionsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: PositionGql,
      resultKey: 'positions',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createNormalizedStore<Position, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getPositionsStore;
