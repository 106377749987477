import { VueConstructor } from 'vue';
import ApplicationLogger from './ApplicationLogger';
import { SentryErrorPayload, SentryMessagePayload } from './SentryTransport';

declare module 'vue/types/vue' {
  interface Vue {
    $logError: (params: SentryErrorPayload) => void;
    $logInfo: (params: SentryMessagePayload) => void;
  }
}

class VueLogger {
  private static logger: ApplicationLogger;

  public static setLogger(logger: ApplicationLogger) {
    VueLogger.logger = logger;
  }

  public static install(Vue: VueConstructor) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$logError = (params: SentryErrorPayload) => {
      this.logger.instance.error(params);
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$logInfo = (params: SentryMessagePayload) => {
      this.logger.instance.info(params);
    };
  }
}

export default VueLogger;
