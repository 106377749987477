import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import { Key } from 'utils/keyboard';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { createEventPayload, EventPayload } from 'utils/events';
import SelectPanel, { Option } from 'components/select-panel/SelectPanel';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import styles from './input-select.css';

export interface Props<T extends string | number> {
  id?: string;
  label?: string;
  name?: string;
  options: Option<T>[];
  placeholder?: string;
  value?: string | number;
  hintText?: string;
  isValid?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
}

export interface Events<T extends string | number> {
  onChange: EventPayload<T, HTMLInputElement, KeyboardEvent | MouseEvent>;
}

@Component({
  inheritAttrs: false,
})
export default class InputSelect<
  T extends string | number,
> extends TsxComponent<
  Partial<Pick<HTMLInputElement, 'required'>> & Props<T>,
  Events<T>
> {
  public suffixIcon: IconName | undefined;

  public isExpanded = false;

  public $refs: {
    selectRef: HTMLElement;
    buttonRef: HTMLButtonElement;
    iconRef: HTMLElement;
  };

  @Prop({ default: () => `select-${getRandomString()}` })
  protected id: Props<T>['id'];

  @Prop()
  public label?: Props<T>['label'];

  @Prop()
  public name?: Props<T>['name'];

  @Prop()
  public options: Props<T>['options'];

  @Prop({ default: '' })
  public placeholder: NonNullable<Props<T>['placeholder']>;

  @Prop()
  public isValid?: Props<T>['isValid'];

  @Prop()
  public isError?: Props<T>['isError'];

  @Prop()
  public isDisabled?: Props<T>['isDisabled'];

  @Prop()
  public hintText?: Props<T>['hintText'];

  @Prop()
  public value?: Props<T>['value'];

  private get isInvalid(): boolean {
    return (
      this.isError ||
      (this.$attrs.required !== undefined && this.value === undefined)
    );
  }

  private get textValue(): string | JSX.Element {
    if (this.value) {
      const found = this.options.find(
        (option: Option<T>) => option.value === this.value,
      );
      if (found) {
        return found.label;
      }
    }
    return this.placeholder;
  }

  private get hiddenInputValue(): string {
    if (this.value !== undefined) {
      if (typeof this.value === 'number') {
        return Number.isNaN(this.value) ? '' : this.value.toString();
      }
      return this.value;
    }

    return '';
  }

  public onChange(
    event: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>,
    value: string | number | undefined,
  ) {
    this.isExpanded = false;
    return this.$emit('change', createEventPayload(event, value));
  }

  public selectOption(
    payload: EventPayload<
      string | number,
      HTMLElement,
      KeyboardEvent | MouseEvent
    >,
  ) {
    this.onChange(payload.event, payload.payload);
    this.isExpanded = false;
    this.$refs.buttonRef.focus();
  }

  private toggleExpand() {
    if (!this.isDisabled) {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.$refs.buttonRef.focus();
      }
    }
  }

  private outsideClicked(
    e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>,
  ) {
    e.stopPropagation();
    this.isExpanded = false;
  }

  private async onSelectKeyup(e: KeyboardEvent) {
    if (this.isExpanded) {
      return;
    }

    if (
      e.key === Key.ENTER ||
      e.key === Key.SPACE ||
      e.key === Key.ARROW_DOWN ||
      e.key === Key.ARROW_UP
    ) {
      this.isExpanded = true;
    }
  }

  public render() {
    return (
      <div
        ref="selectRef"
        class={{
          [styles.inputSelect]: true,
          [styles.inputSelectDisabled]: this.isDisabled,
        }}
      >
        {this.label && (
          <label class={styles.inputSelectLabel} for={this.id}>
            {this.label}
          </label>
        )}

        {this.isExpanded && (
          <OutsideClickHandler
            insideRef={() => this.$refs.selectRef}
            onOutsideClick={this.outsideClicked}
          />
        )}

        <div class={styles.inputSelectWrapper}>
          <button
            class={{
              [styles.inputSelectSelectButton]: true,
              [styles.inputSelectSelectButtonError]: this.isInvalid,
              [styles.inputSelectSelectButtonSuccess]: this.isValid === true,
              [styles.inputSelectSelectButtonExpanded]:
                this.isExpanded && !this.isInvalid && this.isValid !== true,
            }}
            disabled={this.isDisabled}
            aria-controls={`${this.id}-select`}
            aria-expanded={this.isExpanded}
            aria-haspopup="true"
            id={this.id}
            key="select-wrapper"
            onClick={this.toggleExpand}
            onKeyup={this.onSelectKeyup}
            type="button"
            ref="buttonRef"
          >
            <input
              type="text"
              required={this.$attrs.required}
              name={this.name}
              value={this.hiddenInputValue}
              class={styles.inputSelectValidationInput}
              tabindex="-1"
            />

            <div class={styles.inputSelectValue}>{this.textValue}</div>

            <Icon
              aria-hidden="true"
              name={IconName.CHEVRON_DOWN}
              size={Size.SMALL}
              class={{
                [styles.inputSelectChevron]: true,
                [styles.inputSelectChevronMenuHidden]: this.isExpanded,
              }}
            />

            {(this.isInvalid || this.isValid) && (
              <Icon
                aria-hidden="true"
                name={
                  this.isValid ? IconName.CHECK_CIRCLE : IconName.REPORT_PROBLEM
                }
                size={Size.SMALL}
                ref="iconRef"
                class={{
                  [styles.inputSelectSuffixIcon]: true,
                  [styles.inputSelectSuffixIconError]: this.isInvalid,
                  [styles.inputSelectSuffixIconSuccess]: this.isValid,
                }}
              />
            )}
          </button>

          {!this.isDisabled && this.isExpanded && (
            <SelectPanel
              id={`${this.id}-listbox`}
              value={this.value}
              isPanelFocused={true}
              options={this.options}
              onSelect={this.selectOption}
            />
          )}
        </div>

        {this.hintText && (
          <span
            class={{
              [styles.inputSelectHintText]: true,
              [styles.inputSelectHintTextError]: this.isInvalid,
              [styles.inputSelectHintTextSuccess]: this.isValid,
            }}
          >
            {this.hintText}
          </span>
        )}
      </div>
    );
  }
}
