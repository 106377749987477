import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEvaluationPaymentMutation,
  GQLCreateEvaluationPaymentMutationVariables,
  GQLUpdateEvaluationPaymentMutation,
  GQLUpdateEvaluationPaymentMutationVariables,
  GQLDeleteEvaluationPaymentMutation,
  GQLDeleteEvaluationPaymentMutationVariables,
  GQLPaymentFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateEvaluationPaymentGql from './queries/CreateEvaluationPayment.gql';
import DeleteEvaluationPaymentGql from './queries/DeleteEvaluationPayment.gql';
import UpdateEvaluationPaymentGql from './queries/UpdateEvaluationPayment.gql';

export const evaluationPaymentsNS = namespace('evaluationPayments');

export type StoreState = ById<Payment>;

export type Payment = GQLPaymentFragmentFragment;

export type CreateEvaluationPaymentFunction = (
  payload: Omit<GQLCreateEvaluationPaymentMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteEvaluationPaymentFunction = (
  payload: Omit<GQLDeleteEvaluationPaymentMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateEvaluationPaymentFunction = (
  payload: Omit<GQLUpdateEvaluationPaymentMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEvaluationPaymentsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByStaffShiftId: (state: StoreState) => (staffShiftId: number) =>
        Object.values<Payment>(state.byId).filter(
          (item) => item.staffShiftId === staffShiftId,
        ),
    },
  };

  const create: ActionProvider<
    GQLCreateEvaluationPaymentMutation,
    GQLCreateEvaluationPaymentMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEvaluationPaymentFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateEvaluationPaymentGql,
      resultKey: 'createEvaluationPayment',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const remove: ActionProvider<
    GQLDeleteEvaluationPaymentMutation,
    GQLDeleteEvaluationPaymentMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteEvaluationPaymentFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteEvaluationPaymentGql,
      resultKey: 'deleteEvaluationPayment',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const update: ActionProvider<
    GQLUpdateEvaluationPaymentMutation,
    GQLUpdateEvaluationPaymentMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateEvaluationPaymentFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: UpdateEvaluationPaymentGql,
      resultKey: 'updateEvaluationPayment',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  return createNormalizedStore<Payment, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getEvaluationPaymentsStore;
