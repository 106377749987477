import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Size } from 'components/types';
import styles from './avatar.css';

export const AVATAR_SIZES = [
  Size.XSMALL,
  Size.SMALL,
  Size.MEDIUM,
  Size.LARGE,
  Size.XLARGE,
  Size.XXLARGE,
] as const;

export type AvatarSize = typeof AVATAR_SIZES[number];

export interface Props {
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
  size?: AvatarSize;
}

const AVATAR_SIZE_STYLE_MAP: Record<AvatarSize, string> = {
  [Size.XSMALL]: styles.avatarXSmall,
  [Size.SMALL]: styles.avatarSmall,
  [Size.MEDIUM]: styles.avatarMedium,
  [Size.LARGE]: styles.avatarLarge,
  [Size.XLARGE]: styles.avatarXLarge,
  [Size.XXLARGE]: styles.avatar2XLarge,
};

@Component
export default class Avatar extends TsxComponent<Props> {
  @Prop()
  public firstName: Props['firstName'];

  @Prop()
  public lastName: Props['lastName'];

  @Prop()
  public imageUrl: Props['imageUrl'];

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<Props['size']>;

  private get initials() {
    return [
      this.firstName.trim().toUpperCase()[0],
      this.lastName.trim().toUpperCase()[0],
    ].join('');
  }

  public render() {
    return (
      <div
        class={{
          [styles.avatar]: true,
          [AVATAR_SIZE_STYLE_MAP[this.size]]: true,
          [styles.avatarInitials]: !this.imageUrl,
        }}
      >
        {this.imageUrl && (
          <img
            src={this.imageUrl}
            alt=""
            class={styles.avatarImage}
            draggable={false}
          />
        )}

        {!this.imageUrl && this.initials && (
          <span class={styles.avatarInitials}>{this.initials}</span>
        )}
      </div>
    );
  }
}
