import { DateKey } from 'components/calendar-common/common/DateItem';
import Employment from 'components/calendar-common/employments/Employment';
import { FiltersDictionary } from 'components/calendar-common/filters/Store';
import { Position } from 'components/calendar-common/positions/Position';
import moment from 'moment';
import Shift from '../Shift';

export const HAS_SHIFTS_KEY = 'hasShifts';

export const checks = [
  { key: 'showJoinRequests', check: (item: Shift) => item.hasJoinRequests() },
  { key: 'showLeaveRequests', check: (item: Shift) => item.hasLeaveRequests() },
  {
    key: 'showReplaceRequests',
    check: (item: Shift) => item.hasReplaceRequests(),
  },
  { key: 'showUnderassignedShifts', check: (item: Shift) => item.isOpen },
  { key: 'showFullShifts', check: (item: Shift) => item.isFull },
  {
    key: 'showOverassignedShifts',
    check: (item: Shift) => item.isOverassigned,
  },
];

export const hasNoFilters = (filters: FiltersDictionary) =>
  checks.map((it) => it.key).every((it) => !filters[it]);

export const filterByBaseFilters =
  (baseFilters: FiltersDictionary) => (shift: Shift) => {
    const hasApplyingChecks = checks.some(({ key, check }) =>
      baseFilters[key] ? check(shift) : false,
    );

    return hasNoFilters(baseFilters) || hasApplyingChecks;
  };

export const sortShifts = (shift1: Shift, shift2: Shift) => {
  const shift1StartsAt = moment(shift1.startsAt);
  const shift2StartsAt = moment(shift2.startsAt);
  if (shift1StartsAt.isAfter(shift2StartsAt, 'minute')) {
    return 1;
  }
  if (shift1StartsAt.isBefore(shift2StartsAt, 'minute')) {
    return -1;
  }
  // shifts without shift presets go to the back
  if (shift1.shiftPreset && !shift2.shiftPreset) {
    return -1;
  }

  if (!shift1.shiftPreset && shift2.shiftPreset) {
    return 1;
  }

  // compare shift presets name if both shifts have shift preset
  if (shift1.shiftPreset && shift2.shiftPreset) {
    if (shift1.shiftPreset.id !== shift2.shiftPreset.id) {
      return shift1.shiftPreset.name.localeCompare(shift2.shiftPreset.name);
    }
  }

  return shift1.id - shift2.id;
};

export const filterShowOnlyMyShifts =
  (showOnlyMineShifts: boolean, showShiftsWithoutConflicts: boolean) =>
  (shift: Shift) =>
    !showOnlyMineShifts || showShiftsWithoutConflicts || shift.isMyShift;

export const filterByLocationsPositions =
  (
    locationsPositionIds: number[] | null,
    isLocationsPositionsFilterDisabled: boolean,
  ) =>
  (shift: Shift) => {
    const isShiftInFilteredLocationPosition = locationsPositionIds?.includes(
      shift.position.locationsPositionId,
    );

    return (
      isLocationsPositionsFilterDisabled || isShiftInFilteredLocationPosition
    );
  };

export const filterByTags =
  (
    tagIds: number[] | null,
    isTagsFilterDisabled: boolean,
    showShiftsWithoutTags: boolean,
  ) =>
  (shift: Shift) => {
    if (isTagsFilterDisabled) {
      return true;
    }

    const isShiftInFilteredTag = tagIds?.some((id) =>
      shift.tagIds.includes(id),
    );

    if (showShiftsWithoutTags) {
      return !shift.tagIds?.length || !!isShiftInFilteredTag;
    }

    return (
      !!isShiftInFilteredTag || !Array.isArray(tagIds) || tagIds.length === 0
    );
  };

export const filterByShiftPresets =
  (shiftPresetIds: number[] | null, isShiftPresetsFilterDisabled: boolean) =>
  (shift: Shift) => {
    const isShiftInFilteredShiftPreset =
      shift.shiftPreset && shiftPresetIds?.includes(shift.shiftPreset?.id);

    const isWithoutShiftPresetIncluded =
      shiftPresetIds?.includes(-1) && !shift.shiftPreset;

    return (
      isShiftPresetsFilterDisabled ||
      !!isShiftInFilteredShiftPreset ||
      !!isWithoutShiftPresetIncluded
    );
  };

export const filterByShiftRotationGroups =
  (
    shiftRotationGroupIds: number[] | null,
    isShiftRotationGroupsFilterDisabled: boolean,
  ) =>
  (shift: Shift) => {
    const isShiftInFilteredShiftRotationGroups = shiftRotationGroupIds?.some(
      (rotationGroupId) =>
        shift.shiftRotationGroupIds.includes(rotationGroupId),
    );

    return (
      isShiftRotationGroupsFilterDisabled ||
      !!isShiftInFilteredShiftRotationGroups
    );
  };

export const getByDates = (dateKeys, items) =>
  dateKeys.reduce((acc, date) => {
    acc[date] = items.filter((shift) => shift.getDate() === date);
    return acc;
  }, {});

export const shiftsByEmployments = (
  employments: Employment[],
  shiftsByDates: Record<DateKey, Shift[]>,
) =>
  employments.reduce((acc, it) => {
    const employmentShifts = Object.keys(shiftsByDates).reduce(
      (accInner, dateKey) => {
        accInner[dateKey] = shiftsByDates[dateKey].filter(
          (shift) =>
            shift.staffShifts.find((staffShift) => staffShift.id === it.id) !==
            undefined,
        );

        // add a flag if user has any shift
        if (accInner[dateKey].length > 0) {
          accInner[HAS_SHIFTS_KEY] = true;
        }

        return accInner;
      },
      {},
    );

    acc[it.id] = { [HAS_SHIFTS_KEY]: false, ...employmentShifts };
    return acc;
  }, {});

export const shiftsByPositions = (
  positions: Position[],
  shiftsByDates: Record<DateKey, Shift[]>,
) =>
  positions.reduce((acc, it) => {
    const positionShifts = Object.keys(shiftsByDates).reduce(
      (accInner, dateKey: string) => {
        accInner[dateKey] = shiftsByDates[dateKey].filter(
          ({ position }: Shift) =>
            position.locationsPositionId === it.locationsPositionId,
        );

        return accInner;
      },
      {},
    );

    acc[it.locationsPositionId] = positionShifts;
    return acc;
  }, {});
