import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLOrderDirection,
  GQLShiftEmploymentSort,
  GQLEmploymentsInfoQuery,
  GQLEmploymentsInfoQueryVariables,
} from 'codegen/gql-types';
import type { GQLEmploymentInfo } from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  WithCachePayloadParameter,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import EmploymentsInfo from './queries/EmploymentsInfo.gql';

export const employmentFilterNS = namespace('employmentInfo');

export type StoreState = ById<EmploymentInfo>;

export const getDateRangeId = (startDate, endDate) => {
  return `${new Date(startDate).toISOString()}-${new Date(
    endDate,
  ).toISOString()}`;
};

export type FetchAllEmploymentsInfoFunction = (
  payload: WithCachePayloadParameter<
    Omit<GQLEmploymentsInfoQueryVariables, 'companyId'>
  >,
) => Promise<StoreActionResult>;

export type FetchEmploymentInfoFunction = (
  payload: Pick<
    GQLEmploymentsInfoQueryVariables,
    'employmentIds' | 'startDate' | 'endDate'
  >,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

export type EmploymentInfo = Omit<GQLEmploymentInfo, 'id'> & { id: string };

const sorting = {
  criterion: GQLShiftEmploymentSort.NAME,
  order: GQLOrderDirection.ASC,
};

const getShiftEmploymentsFilterStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
  };

  const fetchAll: ActionProvider<
    GQLEmploymentsInfoQuery,
    GQLEmploymentsInfoQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllEmploymentsInfoFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }
    const id = getDateRangeId(payload.startDate, payload.endDate);
    return {
      query: EmploymentsInfo,
      resultKey: 'employmentsInfo',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: (employmentsInfo: GQLEmploymentInfo): EmploymentInfo => ({
        ...employmentsInfo,
        id: `${employmentsInfo.id}-${id}`,
      }),
    };
  };

  const fetch: ActionProvider<
    GQLEmploymentsInfoQuery,
    GQLEmploymentsInfoQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchEmploymentInfoFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const completePayload = {
      searchString: '',
      sortDirection: sorting.order,
      sortName: sorting.criterion,
      page: 1,
      perPage: 1,
      shiftRotationGroupIds: null, // fetch all shiftRotationGroup belonging to Shift
      locationsPositionIds: null, // fetch all LocationsPositions
      locationIds: null,
      startDate: payload.startDate,
      endDate: payload.endDate,
      employmentIds: payload.employmentIds,
    };

    const id = getDateRangeId(payload.startDate, payload.endDate);
    return {
      query: EmploymentsInfo,
      resultKey: 'employmentsInfo',
      variables: {
        ...completePayload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: (employmentsInfo: GQLEmploymentInfo): EmploymentInfo => ({
        ...employmentsInfo,
        id: `${employmentsInfo.id}-${id}`,
      }),
    };
  };

  return createNormalizedStore<EmploymentInfo, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH]: fetch,
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftEmploymentsFilterStore;
