import { namespace } from 'vuex-class';
import { SET_SELECTED_TAG_IDS } from './Actions';
import Mutations from './Mutations';

export const tagsNS = namespace('calendar/tags');

export interface StoreState {
  selectedTagIds: number[];
}
export const store = (selectedTagIds: number[] = []) => ({
  namespaced: true,
  state: {
    selectedTagIds,
  },
  mutations: {
    [Mutations.SET_SELECTED_TAG_IDS](state, tagIds) {
      state.selectedTagIds = tagIds;
    },
  },
  actions: {
    [SET_SELECTED_TAG_IDS]({ commit }, itemIds) {
      commit(Mutations.SET_SELECTED_TAG_IDS, itemIds);
    },
  },
});
