import moment from 'moment';
import { Module } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import { Mode, SlotDisplayStyle } from 'components/calendar-common/Enums';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  isSameDay,
  isWithinInterval,
} from 'date-fns';
import { namespace } from 'vuex-class';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import DateItem from 'components/calendar-common/common/DateItem';
import { RESET } from 'components/calendar-common/common/pagination-mixin/Actions';
import type { Shiftplan } from 'components/calendar-common/types';
import {
  endOf,
  getCurrentDateInTimeZone,
  getDateInTimeZone,
  startOf,
  Unit,
} from 'utils/date-related';
import { toDate } from 'date-fns-tz';
import {
  SET_FREE_RANGE_INTERVAL,
  SET_SHIFTPLAN,
  SET_SLOT_DISPLAY_STYLE,
  SET_TIMEFRAME,
} from './Actions';
import Mutations from './Mutations';

export interface DateInterval {
  start?: string;
  end?: string;
}

export interface StoreState {
  shiftplan: Shiftplan | null;
  freeRangeInterval: SelectedTimeframe | null;
  defaultInterval: number;
  timeframe: SelectedTimeframe;
  slotDisplayStyle: SlotDisplayStyle;
}

export const calendarCommonNS = namespace('calendar/common');

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    shiftplan: null,
    freeRangeInterval: null,
    defaultInterval: 10,
    timeframe: {
      startsAt: new Date(),
      endsAt: addDays(new Date(), 7),
    },
    slotDisplayStyle: SlotDisplayStyle.DEFAULT,
  },
  mutations: {
    [Mutations.SET_SHIFTPLAN](state, value) {
      state.shiftplan = value;
    },
    [Mutations.SET_TIMEFRAME](state, timeframe: SelectedTimeframe) {
      state.timeframe = timeframe;
    },
    [Mutations.SET_SLOT_DISPLAY_STYLE](
      state,
      slotDisplayStyle: SlotDisplayStyle,
    ) {
      state.slotDisplayStyle = slotDisplayStyle;
    },
    [Mutations.SET_FREE_RANGE_INTERVAL](
      state,
      payload: {
        freeRangeInterval: SelectedTimeframe;
      },
    ) {
      state.freeRangeInterval = payload.freeRangeInterval;
      state.defaultInterval = differenceInDays(
        payload.freeRangeInterval.startsAt,
        payload.freeRangeInterval.endsAt,
      );
    },
  },
  actions: {
    [SET_SHIFTPLAN]({ commit, dispatch }, shiftplan: Shiftplan) {
      commit(Mutations.SET_SHIFTPLAN, shiftplan);
      dispatch(`calendar/pagination/${RESET}`, undefined, { root: true });
    },
    [SET_TIMEFRAME]({ commit, dispatch }, timeframe: SelectedTimeframe) {
      commit(Mutations.SET_TIMEFRAME, timeframe);
      dispatch(`calendar/pagination/${RESET}`, undefined, { root: true });
    },
    [SET_FREE_RANGE_INTERVAL](
      { commit, dispatch },
      interval: SelectedTimeframe,
    ) {
      commit(Mutations.SET_FREE_RANGE_INTERVAL, interval);
      dispatch(`calendar/pagination/${RESET}`, undefined, { root: true });
    },
    [SET_SLOT_DISPLAY_STYLE]({ commit }, slotDisplayStyle: SlotDisplayStyle) {
      commit(Mutations.SET_SLOT_DISPLAY_STYLE, slotDisplayStyle);
    },
  },
  getters: {
    // enforce interval to always use start of day/end of day
    currentInterval(state) {
      return {
        start: startOf(
          state.timeframe.startsAt,
          Unit.DAY,
          VueTimeZoneProvider.getTimeZone(),
        ),
        end: endOf(
          state.timeframe.endsAt,
          Unit.DAY,
          VueTimeZoneProvider.getTimeZone(),
        ),
      };
    },
    dates(state, getters, rootState, rootGetters) {
      const timeZone = VueTimeZoneProvider.getTimeZone();

      const isWithinShiftplan = (
        date: Date,
        shiftplanStart: Date,
        shiftplanEnd: Date,
      ) => {
        return isWithinInterval(date, {
          start: shiftplanStart,
          end: shiftplanEnd,
        });
      };
      const isSpecialDate = (date) =>
        rootGetters['calendar/notes/isSpecialDate'](moment(date));

      const { start, end }: { start: Date; end: Date } =
        getters.currentInterval;

      const shiftedInterval = {
        start: getDateInTimeZone(start, timeZone),
        end: getDateInTimeZone(end, timeZone),
      };

      const { shiftplan } = state;
      const shiftplanStart = shiftplan
        ? getDateInTimeZone(
            startOf(
              toDate(shiftplan.startsAt, {
                timeZone,
              }),
              Unit.DAY,
              timeZone,
            ),
            timeZone,
          )
        : start;
      const shiftplanEnd = shiftplan
        ? getDateInTimeZone(
            endOf(
              toDate(shiftplan.endsAt, {
                timeZone,
              }),
              Unit.DAY,
              timeZone,
            ),
            timeZone,
          )
        : end;

      // these are shifted dates already
      const dates = eachDayOfInterval(shiftedInterval);
      return Object.freeze(
        dates.map(
          (date) =>
            new DateItem(
              moment(date),
              isWithinShiftplan(date, shiftplanStart, shiftplanEnd),
              isSameDay(date, getCurrentDateInTimeZone(timeZone)),
              isSpecialDate(date),
            ),
        ),
      );
    },
    dateKeys(state, getters) {
      return Object.freeze(getters.dates.map((date) => date.dateKey));
    },

    /**
     * @deprecated use right checks directly instead
     */
    mode(state, getters, rootState, rootGetters) {
      return rootGetters['shiftSchedule/rights/hasAnyRightInCurrentLocation']
        ? Mode.STAKEHOLDER
        : Mode.EMPLOYEE;
    },
  },
});
