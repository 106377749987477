import { TFunction } from 'i18next';
import { RouteConfig, Route as RouteType } from 'vue-router';
import DialogShiftplanNotificationsRoutes from 'components/dialog-shiftplan-notifications/routes';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import DialogShiftRoutes from '../dialog-shift/routes';
import CalendarDialogAbsenceGroup from './calendar-dialog-absence-group/CalendarDialogAbsenceGroup';

export enum Route {
  ROOT = 'calendarIndex',
  ABSENCE = 'calendarAbsence',
  ABSENCE_GROUP = 'calendarAbsenceGroup',
  ABSENCES_ABSENCE = 'calendarAbsencesAbsence',
  ABSENCES_CREATE_ABSENCE = 'calendarAbsencesAbsenceCreate',
  APPLY_ROTATION = 'applyRotation',
  ASSIGNMENT_GROUP = 'calendarAssignmentGroup',
  ROOT_CREATE_SHIFTPLAN = 'calendarRootCreateShiftplan',
  CREATE_SHIFTPLAN = 'calendarCreateShiftplan',
  EDIT_SHIFTPLAN = 'calendarEditShiftplan',
  DAY_NOTE = 'calendarDayNote',
  EXPORT_CALENDAR = 'exportCalendar',
  MESSAGE_EMPLOYEE = 'calendarMessageEmployee',
  PRINT_SHIFTPLAN = 'calendarPrintShiftplan',
  ROOT_ABSENCES = 'calendarAbsencesIndex',
  SHIFTPLAN = 'calendarShiftplan',
  TAG = 'calendarTag',
  PUBLISH = 'calendarPublishShiftplan',
  IMPORT_SHIFT_SCHEDULE = 'importShiftSchedule',
}

export const ROOT_ROUTE: RouteConfig = {
  component: () => import('./ShiftScheduleContainer'),
  name: Route.ROOT,
  path: '/calendar',
  meta: {
    title: (t: TFunction) => t('shiftSchedule.pageTitle'),
  },
  children: [
    {
      component: () =>
        import('./dialog-create-shiftplan/DialogAddShiftplanContainer'),
      name: Route.ROOT_CREATE_SHIFTPLAN,
      path: 'new',
    },
  ],
};

const routes: RouteConfig[] = [
  ROOT_ROUTE,
  {
    component: () => import('./ShiftScheduleContainer'),
    name: Route.SHIFTPLAN,
    path: '/calendar/shiftplans/:shiftplanId(\\d+)',
    meta: {
      title: (t: TFunction) => t('shiftSchedule.pageTitle'),
    },
    props: (route: RouteType) => ({
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
    children: [
      {
        component: () =>
          import('components/absences/dialog-absence/DialogAbsenceContainer'),
        name: Route.ABSENCE,
        path: 'absences/:id(\\d+)',
        beforeEnter(to, from, next) {
          const hasNoHistory = from.fullPath === '/';

          // Don't apply goBackOnCancel if there is no page to go back to
          if (hasNoHistory && to.query.goBackOnCancel) {
            // eslint-disable-next-line no-param-reassign
            to.query.goBackOnCancel = 'false';
          }

          next();
        },
        props: (route: RouteType) => ({
          absenceId: Number.parseInt(route.params.id, 10),
          namespace: CalendarNamespace.CALENDAR,
          goBackOnCancel: route.query.goBackOnCancel === 'true',
        }),
      },
      {
        component: CalendarDialogAbsenceGroup,
        name: Route.ABSENCE_GROUP,
        path: 'absenceGroup',
        props: (route: RouteType) => {
          const {
            dateKey,
            absenceReasonId,
            absenceState,
            localizedAbsenceReason,
          } = route.query;

          return {
            dateKey,
            absenceReasonId,
            absenceState,
            localizedAbsenceReason,
          };
        },
      },
      {
        component: () =>
          import(
            'components/dialog-shiftplan-publish/DialogShiftplanPublishContainer'
          ),
        name: Route.PUBLISH,
        path: 'publish',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      {
        component: () =>
          import('components/dialog-day-note/DialogDayNoteContainer'),
        name: Route.DAY_NOTE,
        path: 'notes/:id(\\d+)?',
        props: (route: RouteType) => ({
          dayNoteId: Number.parseInt(route.params.id, 10),
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      {
        component: () => import('./dialog-apply-rotation/DialogApplyRotation'),
        name: Route.APPLY_ROTATION,
        path: 'apply-rotation',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      {
        component: () =>
          import('./dialog-calendar-export/DialogCalendarExport'),
        name: Route.EXPORT_CALENDAR,
        path: 'export',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      {
        component: () =>
          import(
            'components/dialog-assignment-group/DialogAssignmentGroupContainer'
          ),
        name: Route.ASSIGNMENT_GROUP,
        path: 'assignment-groups/:assignmentGroupId(\\d+)?',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
          assignmentGroupId:
            route.params.assignmentGroupId &&
            Number.parseInt(route.params.assignmentGroupId, 10),
        }),
      },
      {
        component: () =>
          import('./dialog-create-shiftplan/DialogAddShiftplanContainer'),
        name: Route.CREATE_SHIFTPLAN,
        path: 'new',
      },
      {
        component: () => import('../dialog-shiftplan/DialogShiftplanContainer'),
        name: Route.EDIT_SHIFTPLAN,
        path: 'edit',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      {
        component: () => import('components/dialog-tag/DialogTagContainer'),
        name: Route.TAG,
        path: 'tags/:tagId(\\d+)?',
        props: (route: RouteType) => ({
          tagId: route.params.tagId && Number.parseInt(route.params.tagId, 10),
        }),
      },
      {
        component: () =>
          import(
            'components/dialog-shiftplan-import/DialogShiftplanImportContainer'
          ),
        name: Route.IMPORT_SHIFT_SCHEDULE,
        path: 'import',
        props: (route: RouteType) => ({
          shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
        }),
      },
      ...DialogShiftRoutes,
      ...DialogShiftplanNotificationsRoutes,
    ],
  },
  {
    component: () => import('./AbsencePlanningContainer'),
    name: Route.ROOT_ABSENCES,
    path: '/calendar-absences',
    meta: {
      title: (t: TFunction) => t('shiftSchedule.absencesPlanning.pageTitle'),
    },
    children: [
      {
        component: () =>
          import('components/absences/dialog-absence/DialogAbsenceContainer'),
        name: Route.ABSENCES_ABSENCE,
        path: 'absences/:id(\\d+)',
        props: (route: RouteType) => ({
          namespace: CalendarNamespace.ABSENCES,
          absenceId: Number.parseInt(route.params.id, 10),
        }),
      },
      {
        component: () =>
          import('components/absences/dialog-absence/DialogAbsenceContainer'),
        name: Route.ABSENCES_CREATE_ABSENCE,
        path: 'absences/new',
        props: (route: RouteType) => ({
          namespace: CalendarNamespace.ABSENCES,
          initialState: route.params.initialState,
          cacheIdentifier: route.query.cacheIdentifier,
        }),
      },
    ],
  },
  {
    component: () => import('./PrintShiftScheduleContainer'),
    name: Route.PRINT_SHIFTPLAN,
    path: '/calendar/:shiftplanId(\\d+)/print',
    meta: {
      title: (t: TFunction) => t('shiftSchedule.calendarPrint.pageTitle'),
    },
    props: (route: RouteType) => ({
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
  },
];

export default routes;
