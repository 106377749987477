import EventPart from 'components/calendar-common/common/time-grid-events/EventPart';
import Shift from 'components/calendar-common/shifts/Shift';
import { AGENDA_GRID_STEP } from './Store';

class ShiftPart extends EventPart {
  public timeGridStep = AGENDA_GRID_STEP;

  public event: Shift;
}

export default ShiftPart;
