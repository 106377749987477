import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import {
  mainViewNS,
  StoreState as MainViewStoreState,
} from 'components/main-view/store/Store';
import MainViewAction from '../store/Action';
import styles from './chat.css';
import 'components/sp-icon/sp-icon.css';
import '@shyftplan/node-chat/dist/node-chat.css';

@Component
export default class Chat extends TsxComponent<{}> {
  public $refs: {
    chatRef: HTMLDivElement;
  };

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentCompanyId: AuthStoreState['currentCompanyId'];

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @authNS.State
  protected email: AuthStoreState['email'];

  @authNS.State
  protected token: AuthStoreState['token'];

  @mainViewNS.Action(MainViewAction.SET_HAS_NEW_CHAT_MESSAGE)
  protected setHasNewChatMessage: (state: boolean) => void;

  @mainViewNS.State
  protected isChatOpen: MainViewStoreState['isChatOpen'];

  protected async initChat() {
    // chat used some global I18n structure, so emulate it here
    const { default: NodeChat } = await import('@shyftplan/node-chat');
    (window as any).I18n = {
      t: this.$t.bind(this),
    };

    const capabilities = {
      employmentChats:
        !!this.currentCompany?.chatSettings?.employmentChats?.enabled,
      locationChats: !!this.currentCompany?.chatSettings?.locationChatsEnabled,
      positionChats:
        !!this.currentCompany?.chatSettings?.locationsPositionChatsEnabled,
    };

    const nodeChat = new NodeChat(
      this.$refs.chatRef,
      {
        employmentToken: this.token,
        employmentEmail: this.email,
        employmentId: this.currentEmploymentId,
        companyId: this.currentCompanyId,
        capabilities,
        activeChatId: null,
      },
      process.env.API_ENDPOINT,
      {
        channel: process.env.CHAT_PREFIX,
        endpoint: process.env.CHAT_ENDPOINT,
      },
    );

    nodeChat.subscribe(this.setHasNewChatMessage);
    nodeChat.loadChatData();
  }

  public async mounted() {
    await this.initChat();
  }

  public render() {
    return (
      <div class={[styles.chat, this.isChatOpen && styles.chatOpen]}>
        <div ref="chatRef" />
      </div>
    );
  }
}
