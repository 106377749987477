import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';

import styles from './input-text-area.css';

export interface Props {
  id?: string;
  label?: string;
  name?: string;
  value?: string | number | readonly string[];
  error?: string;
  hintText?: string;
  isValid?: boolean;
}

@Component({
  inheritAttrs: false,
})
export default class InputTextArea extends TsxComponent<
  Partial<HTMLTextAreaElement> & Props,
  EventsOn<HTMLTextAreaElement>
> {
  public $refs: {
    inputRef: HTMLTextAreaElement;
  };

  @Prop({ default: () => `textarea-${getRandomString()}` })
  protected id: Props['id'];

  @Prop()
  public label?: Props['label'];

  @Prop()
  public name?: Props['name'];

  @Prop()
  public value?: Props['value'];

  @Prop()
  public error?: Props['error'];

  @Prop()
  public isValid?: Props['isValid'];

  @Prop()
  public hintText?: Props['hintText'];

  public render() {
    return (
      <div class={styles.inputTextArea}>
        {this.label && (
          <label
            class={{
              [styles.inputTextAreaLabel]: true,
              [styles.inputTextAreaLabelDisabled]: !!this.$attrs.disabled,
            }}
            for={this.id}
          >
            {this.label}
          </label>
        )}
        <textarea
          {...{
            attrs: this.$attrs,
            on: { ...this.$listeners },
          }}
          class={{
            [styles.inputTextAreaInput]: true,
            [styles.inputTextAreaInputError]: this.isValid === false,
            [styles.inputTextAreaInputSuccess]: this.isValid === true,
          }}
          id={this.id}
          name={this.name}
          ref="inputRef"
          value={this.value}
        />
        {this.isValid !== false && this.hintText && (
          <span
            class={{
              [styles.inputTextAreaHintText]: true,
              [styles.inputTextAreaHintTextSuccess]: this.isValid === true,
            }}
          >
            ({this.hintText})
          </span>
        )}
        {this.isValid === false && this.error && (
          <span
            class={{
              [styles.inputTextAreaHintText]: true,
              [styles.inputTextAreaHintTextError]: true,
            }}
          >
            ({this.error})
          </span>
        )}
      </div>
    );
  }
}
