enum Action {
  REFETCH = 'refetch',
  SET_FILTER = 'setFilter',
  SET_PAGE = 'setPage',
  SET_SELECTION = 'setSelection',
  SET_SORT = 'setSort',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

export default Action;
