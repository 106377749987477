import RootStoreState from 'store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { isStartBeforeEnd } from 'utils/date-related';
import { getItemsOverlappingInterval } from 'utils/intervals';
import Action from './Action';
import Mutation from './Mutation';

export const sectionBreaksNS = namespace('evaluationsForm/sectionBreaks');

export const sectionBreaksEmploymentNS = namespace(
  'evaluationsForm/sectionBreaksEmployment',
);

export type IsValidFunction = (
  evaluationStartsAt: Date,
  evaluationEndsAt: Date,
  timeZone: string,
) => boolean;

export interface Break {
  id: number;
  endsAt: Date;
  startsAt: Date;
}

export interface StoreState {
  createIdCounter: number;
  breaks: Break[];
  initial: Break[];
}

const getSectionBreaksStore = (
  isStakeholder?: boolean,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    createIdCounter: Number.MIN_SAFE_INTEGER,
    breaks: [],
    initial: [],
  },
  mutations: {
    [Mutation.SET_BREAKS]: (state, breaks: Break[]) => {
      state.breaks = breaks;
    },
    [Mutation.SET_CREATE_ID_COUNTER]: (state, number: number) => {
      state.createIdCounter = number;
    },
    [Mutation.SET_INITIAL]: (state, initial: Break[]) => {
      state.initial = initial;
    },
  },
  getters: {
    isValid:
      (state) =>
      (evaluationStartsAt: Date, evaluationEndsAt: Date, timeZone: string) => {
        const filtered = state.breaks.filter(
          (item) =>
            isStartBeforeEnd(item.startsAt, item.endsAt) &&
            isStartBeforeEnd(evaluationStartsAt, item.startsAt, true) &&
            isStartBeforeEnd(item.endsAt, evaluationEndsAt, true),
        );

        if (state.breaks.length !== filtered.length) {
          return false;
        }

        return filtered.every((item, index) => {
          const otherBreaks = [...filtered];
          otherBreaks.splice(index, 1);

          return (
            getItemsOverlappingInterval(
              otherBreaks,
              item.startsAt,
              item.endsAt,
              timeZone,
            ).length === 0
          );
        });
      },
  },
  actions: {
    [Action.SET_BREAKS]({ commit }, breaks: Break[]) {
      commit(Mutation.SET_BREAKS, breaks);
      commit(Mutation.SET_INITIAL, breaks);
    },
    [Action.ADD_BREAK]({ commit, state }, newBreak: Omit<Break, 'id'>) {
      commit(Mutation.SET_BREAKS, [
        ...state.breaks,
        {
          ...newBreak,
          id: state.createIdCounter,
        },
      ]);
      commit(Mutation.SET_CREATE_ID_COUNTER, state.createIdCounter + 1);
    },
    [Action.DELETE_BREAK]({ commit, state }, breakId: number) {
      commit(
        Mutation.SET_BREAKS,
        state.breaks.filter((it) => it.id !== breakId),
      );
    },
    [Action.UPDATE_BREAK]({ commit, state }, updatedBreak: Break) {
      commit(
        Mutation.SET_BREAKS,
        state.breaks.map((it) => {
          if (it.id === updatedBreak.id) {
            return updatedBreak;
          }

          return it;
        }),
      );
    },
  },
});

export default getSectionBreaksStore;
