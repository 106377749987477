import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './conflicts.css';

@Component
export default class MaximumConsecutiveWorkingDaysShiftConflict extends TsxComponent<{
  conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MaximumConsecutiveWorkingDaysShiftConflict' }
  >;
  isSelf?: boolean;
}> {
  @Prop()
  public conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MaximumConsecutiveWorkingDaysShiftConflict' }
  >;

  @Prop()
  public isSelf?: boolean;

  protected get translationKey() {
    return this.isSelf
      ? 'conflicts.maximumConsecutiveDays.textSelf'
      : 'conflicts.maximumConsecutiveDays.textStakeholder';
  }

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.maximumConsecutiveDays.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {this.$t(this.translationKey, {
            days: this.conflict.maxDays,
            name: this.conflict.employmentName,
          })}
        </p>
      </section>
    );
  }
}
