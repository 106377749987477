import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLAbsenceReasonsQuery,
  GQLAbsenceReasonsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult } from 'utils/store';
import AbsenceReasonsGql from './queries/AbsenceReasons.gql';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from '../normalized-store';

export const absenceReasonsNS = namespace('absenceReasons');

export type AbsenceReason =
  GQLAbsenceReasonsQuery['absenceReasons']['items'][0];

export type StoreState = ById<AbsenceReason>;

export type FetchAllFunction = () => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getAbsenceReasonsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const fetchAll: ActionProvider<
    GQLAbsenceReasonsQuery,
    GQLAbsenceReasonsQueryVariables
  > = ({ rootState }: StoreActionContext) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: AbsenceReasonsGql,
      resultKey: 'absenceReasons',
      variables: {
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  return createNormalizedStore<AbsenceReason, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getAbsenceReasonsStore;
