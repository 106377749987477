import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import LinkWithCompanyPrefix from 'components/link-with-company-prefix/LinkWithCompanyPrefix';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import { Size } from 'components/types';
import Badge, { BadgePreset } from 'components/badge/Badge';
import Icon from 'components/icons/Icon';
import { IconName } from 'components/icons/types';
import styles from './navigation-button.css';

export type Props = {
  isButton?: boolean;
  icon: string;
  tooltip?: string;
  iconText?: string;
} & ({ href: string } | { routeName: string });

@Component
export default class NavigationButton extends TsxComponent<Props, EventsOn> {
  @Prop()
  public href?: string;

  @Prop()
  public routeName?: string;

  @Prop()
  public icon: IconName;

  @Prop({ default: false })
  public isButton?: boolean;

  @Prop()
  public tooltip?: string;

  @Prop()
  public badgeCount?: number;

  @Prop()
  public iconText?: Props['iconText'];

  public render() {
    const iconTextElement = this.iconText && (
      <span class={styles.navigationButtonIconText}>{this.iconText}</span>
    );

    const buttonLabelContent = (
      <div class={styles.navigationButtonContainer}>
        {iconTextElement}
        <Icon name={this.icon} size={Size.LARGE} />
        <span class={styles.navigationButtonLabel}>{this.$slots.default}</span>
      </div>
    );

    if (this.isButton) {
      return (
        <button
          {...{
            on: { ...this.$listeners },
          }}
          class={styles.navigationButton}
          type="button"
        >
          {buttonLabelContent}
        </button>
      );
    }

    const buttonContent = [
      <Icon
        name={this.icon}
        class={styles.navigationButtonIcon}
        size={Size.LARGE}
      />,

      <span class={styles.navigationButtonLabel}>{this.$slots.default}</span>,

      this.tooltip && (
        <span class={styles.navigationButtonTooltip} aria-hidden="true">
          {this.tooltip}
        </span>
      ),

      this.$slots.overlay,
    ].filter((element) => !!element);

    return this.href ? (
      <LinkWithCompanyPrefix
        activeClass={styles.navigationButtonActive}
        class={styles.navigationButton}
        href={this.href}
      >
        {buttonLabelContent}
        {this.badgeCount && (
          <Badge
            class={styles.navigationButtonBadge}
            size={Size.XSMALL}
            preset={BadgePreset.PRIMARY}
          >
            {this.badgeCount}
          </Badge>
        )}
        {this.tooltip && (
          <span class={styles.navigationButtonTooltip} aria-hidden="true">
            {this.tooltip}
          </span>
        )}
        {this.$slots.overlay}
      </LinkWithCompanyPrefix>
    ) : (
      <router-link
        activeClass={styles.navigationButtonActive}
        class={styles.navigationButton}
        to={{
          name: this.routeName,
        }}
      >
        {iconTextElement}
        {buttonContent}
      </router-link>
    );
  }
}
