import { GQLEvaluation } from 'store/evaluations/Store';

export interface Evaluation extends GQLEvaluation {
  note: string;
  totalPayment: number;
}

export enum EvaluationContext {
  COMPANY,
  EMPLOYMENT,
}
