import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import { evaluationsNS } from 'store/evaluations/Store';
import EvaluationsActions from 'store/evaluations/Actions';
import type {
  CreateEvaluationFunction,
  UpdateEvaluationFunction,
  GQLEvaluation,
} from 'store/evaluations/Store';
import { Action } from 'store/normalized-store';
import { PaygradeType, paygradeTypesNS } from 'store/paygrade-types/Store';
import { staffShiftsTagsNS } from 'store/staff-shifts-tags/Store';
import type {
  CreateStaffShiftsTagFunction,
  DeleteStaffShiftsTagFunction,
} from 'store/staff-shifts-tags/Store';
import { Tag } from 'store/tags/Store';
import { isStartBeforeEnd } from 'utils/date-related';
import { createEventPayload, EventPayload } from 'utils/events';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { Pay } from 'components/dialog-shift/paygrades/Section';
import DialogShiftConflicts, {
  DialogManagerShiftsConflict,
} from 'components/dialog-shift/DialogShiftConflicts';
import DialogManager from 'components/dialog/DialogManager';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { snackbarNS } from 'components/snackbar/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { AlertKind } from 'components/alert/Alert';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import FormSection from 'components/dialog-shift/form-section/FormSection';
import { GQLEvaluationState } from 'codegen/gql-types';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { sectionPayNS } from 'components/section-pay/store/Store';
import Form, { FormState, Slot } from './Form';
import SectionBreaksAction from '../section-breaks/store/Action';
import { sectionBreaksNS } from '../section-breaks/store/Store';
import type { Break, IsValidFunction } from '../section-breaks/store/Store';
import { EvaluationContext } from '../types';
import type { Evaluation } from '../types';
import SectionBreaksContainerCompany from '../section-breaks/SectionBreaksContainerCompany';
import SectionPayContainerCompany from '../section-pay/SectionPayContainerCompany';
import styles from './form.css';

@Component
export default class FormContainerCompany extends TsxComponent<
  {
    evaluation: Evaluation;
    isBringShiftsEnabled?: boolean;
    isDisabled?: boolean;
    staffShiftsTags: Tag[];
  },
  {
    onInput: <T extends keyof FormState>(
      payload: EventPayload<{ field: T; value: FormState[T] }>,
    ) => void;
    onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  }
> {
  protected formState: FormState = {} as FormState;

  protected isSubmitting = false;

  protected conflictsDialogManager: DialogManagerShiftsConflict;

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @evaluationsNS.Action(Action.CREATE)
  protected createEvaluation: CreateEvaluationFunction;

  @evaluationsNS.Action(EvaluationsActions.UPDATE_STATE)
  protected updateEvaluation: UpdateEvaluationFunction;

  @evaluationsNS.Getter
  protected hasEvaluationPayShowRight: (evaluation: GQLEvaluation) => boolean;

  @evaluationsNS.Getter
  protected hasEvaluationPayManageRight: (evaluation: GQLEvaluation) => boolean;

  @paygradeTypesNS.Getter('items')
  protected paygradeTypes: PaygradeType[];

  @sectionBreaksNS.Action(SectionBreaksAction.SET_BREAKS)
  protected setBreaks: (breaks: Break[]) => void;

  @sectionBreaksNS.Getter('isValid')
  protected isBreaksValid: IsValidFunction;

  @sectionBreaksNS.State('breaks')
  protected breaks: Break[];

  @sectionPayNS.Getter('activePayments')
  protected evaluationPayments: Pay[];

  @staffShiftsTagsNS.Action(Action.CREATE)
  protected createStaffShiftsTag: CreateStaffShiftsTagFunction;

  @staffShiftsTagsNS.Action(Action.DELETE)
  protected deleteStaffShiftsTag: DeleteStaffShiftsTagFunction;

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @Prop()
  public evaluation: Evaluation;

  @Prop()
  public isBringShiftsEnabled?: boolean;

  @Prop()
  public isDisabled?: boolean;

  @Prop()
  public staffShiftsTags: Tag[];

  @Watch('evaluation', { immediate: true })
  protected onEvaluationChange() {
    this.formState = {
      startsAt: new Date(this.evaluation.startsAt),
      endsAt: new Date(this.evaluation.endsAt),
      note: this.evaluation.note,
      unpaidBreak: this.evaluation.untimedBreak ?? this.evaluation.break,
      tags: this.evaluation.staffShiftsTagsIds.map((id) => id.toString()),
    };
  }

  protected onInput<T extends keyof FormState>({
    payload: { field, value },
  }: EventPayload<{ field: T; value: FormState[T] }>) {
    this.formState[field] = value;
  }

  protected async tryCreateEvaluation(ignoreConflicts = false) {
    const { tags, unpaidBreak, ...evaluation } = this.formState;

    return executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShiftId: this.evaluation.staffShiftId,
        evaluation: deepTransformDates({
          ignoreConflicts,
          ...evaluation,
          break: null,
          untimedBreak: unpaidBreak,
          evaluationBreaks: this.breaks.map(({ startsAt, endsAt }) => ({
            startsAt,
            endsAt,
          })),
          evaluationTagIds: tags.map((it) => Number.parseInt(it, 10)),
          // values are not optional at this step otherwise form is not valid and can't be submitted
          payments: this.evaluationPayments.map(
            ({ children, value, typeId }) => ({
              paygradeTypeId: typeId!,
              value: value!,
              childPayments: children.map(
                ({ typeId: childTypeId, value: childValue }) => ({
                  paygradeTypeId: childTypeId!,
                  value: childValue!,
                  childPayments: [],
                }),
              ),
            }),
          ),
        }),
      },
      this.createEvaluation,
      '',
    );
  }

  protected async tryUpdateEvaluation(
    event: SyntheticEvent<HTMLButtonElement>,
  ) {
    this.isSubmitting = true;

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      {
        staffShiftId: this.evaluation.staffShiftId,
        didShow: this.evaluation.state === GQLEvaluationState.NO_SHOW,
      },
      this.updateEvaluation,
      '',
    );

    if (result.state === StoreActionState.SUCCESS) {
      this.showSnackbar({
        kind: AlertKind.SUCCESS,
        timeout: 5000,
        message: this.$t('shifts.evaluations.snackUpdatedByManager'),
      });
      this.$emit('submit', createEventPayload<void>(event, undefined));
    }

    this.isSubmitting = false;
  }

  protected async onSubmit({ event }: EventPayload<void>) {
    event.preventDefault();

    try {
      this.isSubmitting = true;

      let result = await this.tryCreateEvaluation();

      if (result.state === StoreActionState.CONFLICT) {
        this.conflictsDialogManager.setStateKey('conflicts', result.conflicts);
        await this.conflictsDialogManager.prompt();

        result = await this.tryCreateEvaluation(true);
      }

      if (result.state === StoreActionState.SUCCESS) {
        this.showSnackbar({
          kind: AlertKind.SUCCESS,
          timeout: 5000,
          message: this.$t('shifts.evaluations.snackUpdatedByManager'),
        });
      }

      this.$emit('submit', createEventPayload<void>(event, undefined));
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        this.$logInfo(e);
      }
    } finally {
      this.isSubmitting = false;
    }
  }

  protected get isSelfEvaluation() {
    return this.evaluation.employment.id === this.currentEmploymentId;
  }

  protected get isSectionPayShown() {
    return (
      !!this.paygradeTypes.length &&
      (this.hasEvaluationPayManageRight(this.evaluation) ||
        ((this.hasEvaluationPayShowRight(this.evaluation) ||
          this.isSelfEvaluation) &&
          !!this.evaluation.payments.length))
    );
  }

  protected get isValid() {
    return (
      this.formState.unpaidBreak !== undefined &&
      !Number.isNaN(this.formState.unpaidBreak) &&
      isStartBeforeEnd(this.formState.startsAt, this.formState.endsAt, true) &&
      this.isBreaksValid(
        this.formState.startsAt,
        this.formState.endsAt,
        this.$timeZone.value,
      )
    );
  }

  public created() {
    this.conflictsDialogManager = new DialogManager({
      isOpen: false,
      conflicts: [],
    });
  }

  public beforeDestroy() {
    this.conflictsDialogManager.destroy();
  }

  public render() {
    return (
      <Form
        context={EvaluationContext.COMPANY}
        evaluation={this.evaluation}
        formState={this.formState}
        isBringShiftsEnabled={this.isBringShiftsEnabled}
        isDisabled={this.isDisabled}
        isSubmitting={this.isSubmitting}
        isValid={this.isValid}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
        tags={this.staffShiftsTags}
      >
        <SectionBreaksContainerCompany
          evaluationBreaks={this.evaluation.breaks}
          evaluationEndsAt={this.formState.endsAt}
          evaluationStartsAt={this.formState.startsAt}
          isDisabled={this.isDisabled || this.isSubmitting}
          onUnpaidBreakChange={({ event, payload: { value } }) =>
            this.onInput({
              event,
              payload: { field: 'unpaidBreak', value },
            })
          }
          slot={Slot.SECTION_BREAKS}
          unpaidBreak={this.formState.unpaidBreak}
        />

        {this.isSectionPayShown && (
          <SectionPayContainerCompany
            breaks={this.breaks}
            evaluation={this.evaluation}
            formState={this.formState}
            isDisabled={
              this.isDisabled ||
              this.isSubmitting ||
              !this.hasEvaluationPayManageRight(this.evaluation)
            }
            slot={Slot.SECTION_PAY}
          />
        )}

        {!this.isDisabled && (
          <FormSection
            class={styles.formSectionActions}
            slot={Slot.ACTION_BUTTONS}
            context={this.$t('shifts.evaluations.labelCompany')}
            heading={this.$t('shifts.evaluations.headingActions')}
          >
            {/* FIXME: Button to be added later
             <Button
              disabled={this.isSubmitting || !this.isValid}
              size={Size.MEDIUM}
              type="submit"
              class={styles.formActionButton}
              color={ButtonColor.ERROR}
            >
              {this.$t('general.buttonDelete')}
            </Button> */}
            <Button
              disabled={this.isSubmitting || !this.isValid}
              size={Size.MEDIUM}
              type="button"
              class={styles.formActionButton}
              kind={ButtonKind.STROKE}
              onClick={this.tryUpdateEvaluation}
            >
              {this.evaluation.state === GQLEvaluationState.NO_SHOW
                ? this.$t('shifts.evaluations.buttonShow')
                : this.$t('shifts.evaluations.buttonNoShow')}
            </Button>
            <Button
              disabled={
                this.isSubmitting ||
                !this.isValid ||
                this.evaluation.state === GQLEvaluationState.NO_SHOW
              }
              size={Size.MEDIUM}
              type="submit"
              class={styles.formActionButton}
              color={ButtonColor.SUCCESS}
            >
              {this.$t('shifts.evaluations.buttonEvaluate')}
            </Button>
          </FormSection>
        )}

        <DialogShiftConflicts
          slot={Slot.PROMPTS}
          conflicts={this.conflictsDialogManager.state.conflicts}
          isOpen={this.conflictsDialogManager.state.isOpen}
          onCloseClick={() => this.conflictsDialogManager.onAbort()}
          onConfirm={() =>
            this.conflictsDialogManager.onAnswer({ ignoreAll: false })
          }
        />
      </Form>
    );
  }
}
