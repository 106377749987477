/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import { Component as TsxComponent } from 'vue-tsx-support';
import { Component, Prop } from 'vue-property-decorator';
import { getRandomString } from 'utils/random';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import BaseDialog, {
  Slot as BaseDialogSlot,
} from 'components/dialog/base-dialog/BaseDialog';
import { Size } from 'components/types';
import styles from './prompt.css';

export interface Props {
  id?: string;
  isOpen?: boolean;
  title?: string;
  primaryButtonText?: string;
  isDestructive?: boolean;
  isCloseButtonDisabled?: boolean;
  isDialogNotClosable?: boolean;
  isConfirmButtonDisabled?: boolean;
}

export enum Slot {
  OTHER_ACTIONS = 'other_actions',
  ACTION_BUTTONS = 'action_buttons',
}

interface Events {
  onCloseClick: SyntheticEvent<
    HTMLElement | HTMLButtonElement,
    KeyboardEvent | MouseEvent | UIEvent
  >;
  onConfirmClick: SyntheticEvent<
    HTMLElement | HTMLButtonElement,
    KeyboardEvent | MouseEvent | UIEvent
  >;
}

@Component
export default class Prompt extends TsxComponent<Props, Events> {
  public $refs: {
    prompt: HTMLDivElement;
  };

  @Prop({ default: () => `prompt-${getRandomString()}` })
  public id: Props['id'];

  @Prop()
  public title?: Props['title'];

  @Prop()
  public primaryButtonText?: Props['primaryButtonText'];

  @Prop({ default: false })
  public isOpen: NonNullable<Props['isOpen']>;

  @Prop({ default: false })
  public isCloseButtonDisabled: NonNullable<Props['isCloseButtonDisabled']>;

  @Prop({ default: false })
  public isDialogNotClosable: NonNullable<Props['isDialogNotClosable']>;

  @Prop({ default: false })
  public isConfirmButtonDisabled?: NonNullable<
    Props['isConfirmButtonDisabled']
  >;

  @Prop({ default: false })
  public isDestructive?: NonNullable<Props['isDestructive']>;

  private get confirmButtonTextValue() {
    if (this.primaryButtonText) {
      return this.primaryButtonText;
    }
    if (this.isDestructive) {
      return this.$t('modal.buttonDelete');
    }
    return this.$t('modal.buttonContinue');
  }

  private get defaultButtons() {
    return (
      <div class={styles.promptFooterMainActionButtons}>
        <Button
          type="button"
          color={ButtonColor.SECONDARY}
          kind={ButtonKind.STROKE}
          onClick={this.onCloseClick}
          disabled={this.isCloseButtonDisabled}
        >
          {this.$t('modal.buttonCancel')}
        </Button>
        <Button
          type="button"
          color={this.isDestructive ? ButtonColor.ERROR : ButtonColor.PRIMARY}
          kind={ButtonKind.FILL}
          onClick={this.onConfirmClick}
          disabled={this.isConfirmButtonDisabled}
        >
          {this.confirmButtonTextValue}
        </Button>
      </div>
    );
  }

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  protected onConfirmClick(e: MouseEvent) {
    this.$emit('confirmClick', e);
  }

  public render() {
    return (
      <BaseDialog
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        isClosingPrevented={this.isCloseButtonDisabled}
        isDialogNotClosable={this.isDialogNotClosable}
        title={this.title}
        dialogSpacingSize={Size.MEDIUM}
      >
        <div class={styles.promptContentWrapper}>
          <div class={styles.promptContent}>{this.$slots.default}</div>
        </div>
        <footer class={styles.promptFooter} slot={BaseDialogSlot.FOOTER}>
          {this.$slots[Slot.ACTION_BUTTONS]
            ? this.$slots[Slot.ACTION_BUTTONS]
            : this.defaultButtons}
          {this.$slots[Slot.OTHER_ACTIONS]}
        </footer>
      </BaseDialog>
    );
  }
}
