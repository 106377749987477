enum Action {
  INIT_CALENDAR = 'initCalendar',
  // prop setters
  SET_APM_TIMEFRAME = 'setAPMTimeframe',
  SET_CALENDAR_TIMEFRAME = 'setCalendarTimeframe',
  SET_CALENDAR_TIMEFRAMES = 'setCalendarTimeframes',
  SET_VIEW_KIND = 'setViewKind',
  SET_PRINT_VIEW_KIND = 'setPrintViewKind',
  SET_PRINT_TIMEFRAME = 'setPrintTimeframe',
  SET_PRINT_TIMEFRAMES = 'setPrintTimeframes',
  SET_SLOT_DISPLAY_STYLE = 'setSlotDisplayStyle',
  SET_JOB = 'setJob',
  REMOVE_JOB = 'removeJob',
}

export default Action;
