export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Paginatable',
        possibleTypes: [
          {
            name: 'AbsenceEntitlementsCollection',
          },
          {
            name: 'AbsenceReasonCollection',
          },
          {
            name: 'AbsenceCollection',
          },
          {
            name: 'EmploymentCollection',
          },
          {
            name: 'AssignmentGroupCollection',
          },
          {
            name: 'AvailabilityCollection',
          },
          {
            name: 'AvailabilityAggregationCollection',
          },
          {
            name: 'AvailabilityExceptionCollection',
          },
          {
            name: 'BackgroundJobCollection',
          },
          {
            name: 'ChatEmploymentCollection',
          },
          {
            name: 'ChatMessageCollection',
          },
          {
            name: 'ChatCollection',
          },
          {
            name: 'CompanyCollection',
          },
          {
            name: 'CustomFieldCollection',
          },
          {
            name: 'DayNoteCollection',
          },
          {
            name: 'PaymentCollection',
          },
          {
            name: 'EmploymentsInfoCollection',
          },
          {
            name: 'EmploymentsPositionCollection',
          },
          {
            name: 'EmploymentsRotationGroupsCollection',
          },
          {
            name: 'EvaluationBreakCollection',
          },
          {
            name: 'EvaluationCollection',
          },
          {
            name: 'IndividualShiftRotationCollection',
          },
          {
            name: 'LanguageCollection',
          },
          {
            name: 'LiveStaffShiftCollection',
          },
          {
            name: 'LocationCollection',
          },
          {
            name: 'LocationsPositionCollection',
          },
          {
            name: 'NewsfeedCollection',
          },
          {
            name: 'NotificationConfigurationCollection',
          },
          {
            name: 'PaygradeTypeCollection',
          },
          {
            name: 'PayslipCollection',
          },
          {
            name: 'PositionCollection',
          },
          {
            name: 'QualificationCollection',
          },
          {
            name: 'RightCollection',
          },
          {
            name: 'ShiftAssignmentGroupCollection',
          },
          {
            name: 'ShiftAvailableEmploymentCollection',
          },
          {
            name: 'ShiftBreakCollection',
          },
          {
            name: 'TagCollection',
          },
          {
            name: 'ShiftPaygradeCollection',
          },
          {
            name: 'ShiftplanCollection',
          },
          {
            name: 'ShiftPresetCollection',
          },
          {
            name: 'ShiftRotationGroupCollection',
          },
          {
            name: 'ShiftRotationCollection',
          },
          {
            name: 'ShiftCollection',
          },
          {
            name: 'ShiftsTagCollection',
          },
          {
            name: 'SpecialDateCollection',
          },
          {
            name: 'StaffShiftCollection',
          },
          {
            name: 'StaffShiftsTagCollection',
          },
          {
            name: 'UserCollection',
          },
          {
            name: 'EmploymentsSurveyCollection',
          },
          {
            name: 'EmploymentEvaluationCollection',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Identifiable',
        possibleTypes: [
          {
            name: 'AbsenceReason',
          },
          {
            name: 'Company',
          },
          {
            name: 'Language',
          },
          {
            name: 'Employment',
          },
          {
            name: 'Absence',
          },
          {
            name: 'EmploymentsRotationGroup',
          },
          {
            name: 'ShiftRotationGroup',
          },
          {
            name: 'ShiftPreset',
          },
          {
            name: 'Tag',
          },
          {
            name: 'Location',
          },
          {
            name: 'Position',
          },
          {
            name: 'ShiftRotation',
          },
          {
            name: 'EmploymentWorkHour',
          },
          {
            name: 'LocationsPosition',
          },
          {
            name: 'EmploymentPaygrade',
          },
          {
            name: 'PaygradeType',
          },
          {
            name: 'PunchBreak',
          },
          {
            name: 'PunchTiming',
          },
          {
            name: 'StaffShift',
          },
          {
            name: 'Shift',
          },
          {
            name: 'AssignmentGroup',
          },
          {
            name: 'Shiftplan',
          },
          {
            name: 'EvaluationBreak',
          },
          {
            name: 'Payment',
          },
          {
            name: 'StaffShiftsTag',
          },
          {
            name: 'ShiftPaygrade',
          },
          {
            name: 'Request',
          },
          {
            name: 'ShiftAssignmentGroup',
          },
          {
            name: 'ShiftBreak',
          },
          {
            name: 'Qualification',
          },
          {
            name: 'User',
          },
          {
            name: 'Newsfeed',
          },
          {
            name: 'Availability',
          },
          {
            name: 'AvailabilityException',
          },
          {
            name: 'ChatEmployment',
          },
          {
            name: 'ChatMessage',
          },
          {
            name: 'ChatMessageEmployment',
          },
          {
            name: 'ChatLocation',
          },
          {
            name: 'ChatLocationsPosition',
          },
          {
            name: 'CustomField',
          },
          {
            name: 'DayNote',
          },
          {
            name: 'EmploymentEvaluation',
          },
          {
            name: 'EmploymentInfo',
          },
          {
            name: 'EmploymentsPosition',
          },
          {
            name: 'IndividualShiftRotation',
          },
          {
            name: 'EmploymentsSurvey',
          },
          {
            name: 'EmploymentsUiSetting',
          },
          {
            name: 'LiveStaffShift',
          },
          {
            name: 'Payslip',
          },
          {
            name: 'PunchingLocationsPosition',
          },
          {
            name: 'ShiftAvailableEmployment',
          },
          {
            name: 'ShiftsTag',
          },
          {
            name: 'SpecialDate',
          },
          {
            name: 'EmploymentEvaluationTag',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Timestampable',
        possibleTypes: [
          {
            name: 'AbsenceReason',
          },
          {
            name: 'Company',
          },
          {
            name: 'Employment',
          },
          {
            name: 'Absence',
          },
          {
            name: 'ShiftRotationGroup',
          },
          {
            name: 'ShiftPreset',
          },
          {
            name: 'Location',
          },
          {
            name: 'Position',
          },
          {
            name: 'ShiftRotation',
          },
          {
            name: 'EmploymentWorkHour',
          },
          {
            name: 'LocationsPosition',
          },
          {
            name: 'PaygradeType',
          },
          {
            name: 'PunchTiming',
          },
          {
            name: 'StaffShift',
          },
          {
            name: 'Shift',
          },
          {
            name: 'AssignmentGroup',
          },
          {
            name: 'Shiftplan',
          },
          {
            name: 'Evaluation',
          },
          {
            name: 'EvaluationBreak',
          },
          {
            name: 'StaffShiftsTag',
          },
          {
            name: 'Request',
          },
          {
            name: 'ShiftAssignmentGroup',
          },
          {
            name: 'Qualification',
          },
          {
            name: 'User',
          },
          {
            name: 'Newsfeed',
          },
          {
            name: 'Availability',
          },
          {
            name: 'AvailabilityException',
          },
          {
            name: 'BackgroundJob',
          },
          {
            name: 'IndividualShiftRotation',
          },
          {
            name: 'PunchingLocationsPosition',
          },
          {
            name: 'ShiftsTag',
          },
          {
            name: 'SpecialDate',
          },
          {
            name: 'EmploymentEvaluationTag',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Timeframeable',
        possibleTypes: [
          {
            name: 'Absence',
          },
          {
            name: 'Shift',
          },
          {
            name: 'Shiftplan',
          },
          {
            name: 'Availability',
          },
          {
            name: 'AvailabilityException',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ChatUser',
        possibleTypes: [
          {
            name: 'ChatLocation',
          },
          {
            name: 'ChatLocationsPosition',
          },
          {
            name: 'ChatMessageEmployment',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'RotationGroupUnion',
        possibleTypes: [
          {
            name: 'EmploymentsRotationGroup',
          },
          {
            name: 'IndividualShiftRotation',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PunchStateOrError',
        possibleTypes: [
          {
            name: 'PunchError',
          },
          {
            name: 'PunchState',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ShiftConflict',
        possibleTypes: [
          {
            name: 'AbsencesCollisionShiftConflict',
          },
          {
            name: 'AlreadyAssignedShiftConflict',
          },
          {
            name: 'InsideUnavailableTimeShiftConflict',
          },
          {
            name: 'LocationsPositionChangedShiftConflict',
          },
          {
            name: 'MaximumConsecutiveWorkingDaysShiftConflict',
          },
          {
            name: 'MaximumEarningShiftConflict',
          },
          {
            name: 'MaximumWorkingHoursShiftConflict',
          },
          {
            name: 'MinimumBreakShiftConflict',
          },
          {
            name: 'MinimumRestShiftConflict',
          },
          {
            name: 'OutsideAvailableTimeShiftConflict',
          },
          {
            name: 'OutsideWorkPeriodShiftConflict',
          },
          {
            name: 'RestrictedShiftPresetsConflict',
          },
          {
            name: 'ShiftsCollisionShiftConflict',
          },
          {
            name: 'UnknownShiftConflict',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'EvaluationCreateResult',
        possibleTypes: [
          {
            name: 'Evaluation',
          },
          {
            name: 'EvaluationConflictsResponse',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AcceptReplaceRequestResult',
        possibleTypes: [
          {
            name: 'PendingReplaceRequest',
          },
          {
            name: 'RequestState',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AbsenceApproveResult',
        possibleTypes: [
          {
            name: 'ConflictsResponse',
          },
          {
            name: 'CreateResponse',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Conflict',
        possibleTypes: [
          {
            name: 'CarryOverDeadlineErrorConflict',
          },
          {
            name: 'CarryOverMultipleYearsConflict',
          },
          {
            name: 'CollisionsConflict',
          },
          {
            name: 'MaxDaysPerYearConflict',
          },
          {
            name: 'OutsideEmploymentPeriodConflict',
          },
          {
            name: 'UnknownConflict',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConfirmReplaceRequestResult',
        possibleTypes: [
          {
            name: 'PendingReplaceRequest',
          },
          {
            name: 'RequestState',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ShiftCreateResult',
        possibleTypes: [
          {
            name: 'Shift',
          },
          {
            name: 'ShiftConflictsResponse',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AbsenceCreateResult',
        possibleTypes: [
          {
            name: 'Absence',
          },
          {
            name: 'ConflictsResponse',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AvailabilityCreateResult',
        possibleTypes: [
          {
            name: 'Availability',
          },
          {
            name: 'AvailabilityConflictResponse',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'EmployeeEvaluationCreateResult',
        possibleTypes: [
          {
            name: 'EmploymentEvaluation',
          },
          {
            name: 'EvaluationConflictsResponse',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CreatePunchingShiftConflict',
        possibleTypes: [
          {
            name: 'ErrorRestPeriodBelowMinimum',
          },
          {
            name: 'ErrorStaffOverpaid',
          },
          {
            name: 'WarningRestPeriodBelowMinimum',
          },
          {
            name: 'WarningStaffOverpaid',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'CreatePunchingShiftConflictWithStringValue',
        possibleTypes: [
          {
            name: 'ErrorRestPeriodBelowMinimum',
          },
          {
            name: 'ErrorStaffOverpaid',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'CreatePunchingShiftConflictWithIntValue',
        possibleTypes: [
          {
            name: 'WarningRestPeriodBelowMinimum',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'StaffShiftCreateResult',
        possibleTypes: [
          {
            name: 'ShiftConflictsResponse',
          },
          {
            name: 'StaffShift',
          },
        ],
      },
    ],
  },
};
export default result;
