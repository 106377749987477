import {
  GQLCreateShiftplanMessageMutation,
  GQLCreateShiftplanMessageMutationVariables,
  GQLCreateShiftplanNotificationMutation,
  GQLCreateShiftplanNotificationMutationVariables,
  GQLMutationCreateShiftplanMessageArgs,
  GQLMutationCreateShiftplanNotificationArgs,
  GQLShiftplanNotificationsQuery,
  GQLShiftplanNotificationsQueryVariables,
} from 'codegen/gql-types';
import { ActionContext, Module } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import RootStoreState from 'store/RootStoreState';
import {
  QueryProvider,
  WatchStoreState,
  createWatchStore,
  SubscribeAction,
  RefetchAction,
} from 'store/watch-store';
import {
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import CreateShiftplanMessage from './queries/CreateShiftplanMessage.gql';
import CreateShiftplanNotification from './queries/CreateShiftplanNotification.gql';
import ShiftplanNotifications from './queries/ShiftplanNotifications.gql';
import Action from './Action';
import { handleUnexpectedResult, isSuccessResult } from '../normalized-store';

export const shiftplanNotificationsNS = namespace('shiftplanNotifications');

export const shiftplanNotificationsWatchNS = namespace(
  'shiftplanNotifications/watch',
);

export type StoreState = {
  watch: WatchStoreState<ShiftplanNotification>;
};

export type ShiftplanNotification = GQLShiftplanNotificationsQuery;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

export type CreateShiftplanMessageFunction = (
  payload: Omit<GQLMutationCreateShiftplanMessageArgs, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateShiftplanNotificationFunction = (
  payload: Omit<GQLMutationCreateShiftplanNotificationArgs, 'companyId'>,
) => Promise<StoreActionResult>;

export type SubscribeToShiftplanNotificationsFunction = SubscribeAction<
  Omit<GQLShiftplanNotificationsQueryVariables, 'companyId'>
>;

export type RefetchShiftplanNotificationsFunction = RefetchAction<
  Omit<GQLShiftplanNotificationsQueryVariables, 'companyId'>
>;

const getShiftplanNotificationsWatchStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const query: QueryProvider<
    GQLShiftplanNotificationsQuery,
    GQLShiftplanNotificationsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: GQLShiftplanNotificationsQueryVariables,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: ShiftplanNotifications,
      resultKey: 'shiftplanNotifications',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createWatchStore<ShiftplanNotification, StoreState, RootStoreState>({
    store,
    graphqlClient,
    logger,
    pollInterval: 10000,
    query,
  });
};

const getShiftplanNotificationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
): Module<{}, RootStoreState> => ({
  namespaced: true,
  actions: {
    [Action.CREATE_SHIFTPLAN_MESSAGE]: async (
      { rootState },
      { id, input }: PayloadParameter<CreateShiftplanMessageFunction>,
    ) => {
      if (!rootState.auth.currentCompanyId) {
        return {
          state: StoreActionState.ERROR,
        };
      }

      const result = await graphqlClient.mutate<
        GQLCreateShiftplanMessageMutation,
        GQLCreateShiftplanMessageMutationVariables
      >({
        mutation: CreateShiftplanMessage,
        variables: {
          companyId: rootState.auth.currentCompanyId,
          id,
          input,
        },
      });

      if (!isSuccessResult(result, 'createShiftplanMessage')) {
        return handleUnexpectedResult(
          Action.CREATE_SHIFTPLAN_MESSAGE as any,
          logger,
        );
      }

      return {
        state: StoreActionState.SUCCESS,
      };
    },
    [Action.CREATE_SHIFTPLAN_NOTIFICATION]: async (
      { rootState },
      { id, input }: PayloadParameter<CreateShiftplanNotificationFunction>,
    ) => {
      if (!rootState.auth.currentCompanyId) {
        return {
          state: StoreActionState.ERROR,
        };
      }

      const result = await graphqlClient.mutate<
        GQLCreateShiftplanNotificationMutation,
        GQLCreateShiftplanNotificationMutationVariables
      >({
        mutation: CreateShiftplanNotification,
        variables: {
          companyId: rootState.auth.currentCompanyId,
          id,
          input,
        },
      });

      if (!isSuccessResult(result, 'createShiftplanNotification')) {
        return handleUnexpectedResult(
          Action.CREATE_SHIFTPLAN_NOTIFICATION as any,
          logger,
        );
      }

      return {
        state: StoreActionState.SUCCESS,
      };
    },
  },
  modules: {
    watch: getShiftplanNotificationsWatchStore(graphqlClient, logger),
  },
});

export default getShiftplanNotificationsStore;
