import {
  GQLAutoAcceptRequestSetting,
  GQLShiftCreateInput,
  GQLShiftRotationFragmentFragment,
  GQLShiftUpdateInput,
  GQLMinimumBreakSetting,
} from 'codegen/gql-types';
import {
  addHours,
  differenceInCalendarDays,
  formatISO,
  isAfter,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { toDate } from 'date-fns-tz';
import { getOverlappingTimeFrames } from 'src/utils/intervals';
import type { Shiftplan } from 'store/shiftplans/Store';
import type { Shift } from 'store/shifts/Store';
import {
  endOf,
  getDateInTimeZone,
  getDurationSum,
  isStartBeforeEnd,
  MAX_DATE,
  MIN_DATE,
  parseDateString,
  startOf,
  Unit,
  MSECS_IN_MINUTE,
} from 'utils/date-related';
import { CUSTOM_PRESET_ID } from './section-general/SectionGeneral';
import { CompleteBreakInput, FormState } from './types';

export const getInitialFormState = (): FormState => ({
  shiftplanId: Number.NaN,
  startsAt: new Date(),
  isConnectedShift: false,
  endsAt: new Date(),
  workers: 1,
  shiftPresetId: null,
  locationsPositionId: null,
  shiftRotationGroupIds: [],
  shiftBreaks: [],
  autoAccept: GQLAutoAcceptRequestSetting.DISABLED,
  connectedGroupId: Number.NaN,
  shiftEvaluationTagIds: [],
  shiftQualifications: [],
  tagIds: [],
  unpaidBreak: 0,
  publicNote: '',
  repeatDates: [],
  managerNote: '',
  isStandBy: false,
  canEvaluate: true,
  ignoreConflicts: false,
  updateConnected: false,
  assignmentGroupIds: [],
});

export const getFormStateByShift = (shift: Shift, shiftplanId: number) => ({
  autoAccept: shift.autoAccept,
  unpaidBreak: shift.untimedBreakTime ?? shift.breakTime,
  connectedGroupId: shift.connectedGroupId,
  shiftEvaluationTagIds: shift.shiftEvaluationTags?.map(({ id }) => id) || [],
  locationsPositionId: shift.locationsPosition.id,
  publicNote: shift.note || '',
  shiftBreaks: shift.shiftBreaks.filter(
    (shiftBreak): shiftBreak is CompleteBreakInput =>
      typeof shiftBreak.endsAt === 'string',
  ),
  shiftplanId,
  shiftPresetId: shift.shiftPreset?.id || CUSTOM_PRESET_ID,
  shiftRotationGroupIds: shift.shiftRotationGroups?.map(({ id }) => id) || [],
  startsAt: new Date(shift.startsAt),
  endsAt: new Date(shift.endsAt),
  tagIds: shift.tags?.map(({ id }) => id) || [],
  workers: shift.workers,
  isStandBy: shift.untimed,
  canEvaluate: shift.canEvaluate,
  managerNote: shift.managerNote || '',
  ignoreConflicts: false,
  updateConnected: false,
  isConnectedShift: shift.connectedGroupId !== null,
  repeatDates: [],
  shiftQualifications:
    shift.shiftQualifications?.map(({ qualificationId, count }) => ({
      qualificationId,
      count,
    })) || [],
  assignmentGroupIds: shift.assignmentGroupIds,
});

export const getUntimedBreaksFromMinimumBreakSettings = (
  minimumBreakRules?: GQLMinimumBreakSetting[],
  startsAt?: Date,
  endsAt?: Date,
): number => {
  if (!(startsAt && endsAt && !!minimumBreakRules)) {
    return 0;
  }

  const sortedRules = minimumBreakRules.sort(
    (a, b) => b.totalMinutes - a.totalMinutes,
  );
  const durationOfShift =
    getDurationSum([{ startsAt, endsAt }]) / MSECS_IN_MINUTE;
  const rules = sortedRules.find(
    (rule) => rule.totalMinutes <= durationOfShift,
  );
  return rules?.totalBreakMinutes || 0;
};

export const getNewFormState = (payload: {
  shiftplan: Pick<Shiftplan, 'startsAt' | 'endsAt' | 'id'>;
  timeZone: string;
  startsAt?: Date;
  endsAt?: Date;
  locationsPositionId?: number;
  unpaidBreak: number;
  autoAccept: GQLAutoAcceptRequestSetting;
}) => {
  const shiftplanStartsAt = startOf(
    toDate(payload.shiftplan.startsAt, { timeZone: payload.timeZone }),
    Unit.DAY,
    payload.timeZone,
  );
  const shiftplanEndsAt = endOf(
    toDate(payload.shiftplan.endsAt, { timeZone: payload.timeZone }),
    Unit.DAY,
    payload.timeZone,
  );

  // set startsAt
  let startsAt = addHours(shiftplanStartsAt, 9);
  const shiftplanInterval = { start: shiftplanStartsAt, end: shiftplanEndsAt };
  if (
    payload.startsAt &&
    isWithinInterval(payload.startsAt, shiftplanInterval)
  ) {
    startsAt = payload.startsAt;
  }

  // set endsAt
  let endsAt = addHours(startsAt, 8);
  if (
    payload.endsAt &&
    isWithinInterval(payload.endsAt, shiftplanInterval) &&
    isAfter(payload.endsAt, startsAt)
  ) {
    endsAt = payload.endsAt;
  }

  const { unpaidBreak, ...initialFormState } = getInitialFormState();
  return {
    ...initialFormState,
    startsAt,
    endsAt,
    unpaidBreak: payload.unpaidBreak >= 0 ? payload.unpaidBreak : unpaidBreak,
    shiftplanId: payload.shiftplan.id,
    locationsPositionId: payload.locationsPositionId || null,
    autoAccept: payload.autoAccept,
  };
};

export const transformFormStateToCreateInput = (
  formState: Omit<FormState, 'locationsPositionId'> & {
    locationsPositionId: number;
  },
): Omit<GQLShiftCreateInput, 'companyId'> => ({
  startsAt: formatISO(formState.startsAt),
  endsAt: formatISO(formState.endsAt),
  workers: formState.workers,
  untimedBreakTime: formState.unpaidBreak,
  note: formState.publicNote,
  canEvaluate: formState.canEvaluate,
  autoAccept: formState.autoAccept,
  shiftplanId: formState.shiftplanId,
  locationsPositionId: formState.locationsPositionId,
  shiftPresetId:
    formState.shiftPresetId && formState.shiftPresetId !== CUSTOM_PRESET_ID
      ? formState.shiftPresetId
      : null,
  untimed: !!formState.isStandBy,
  tagIds: formState.tagIds,
  evaluationTagIds: formState.shiftEvaluationTagIds,
  shiftBreaks: formState.shiftBreaks.map((o) => ({
    startsAt: o.startsAt,
    endsAt: o.endsAt,
  })),
  shiftQualifications: formState.shiftQualifications || [],
  ignoreConflicts: formState.ignoreConflicts,
  assignmentGroupIds: formState.assignmentGroupIds || [],
  shiftRotationGroupIds: formState.shiftRotationGroupIds || [],
  managerNote: formState?.managerNote || '',
});

export const transformFormStateToUpdateInput = (
  formState: Omit<FormState, 'locationsPositionId'> & {
    locationsPositionId: number;
  },
): Omit<GQLShiftUpdateInput, 'companyId'> => ({
  startsAt: formatISO(formState.startsAt),
  endsAt: formatISO(formState.endsAt),
  workers: formState.workers,
  untimedBreakTime: formState.unpaidBreak,
  note: formState.publicNote,
  canEvaluate: formState.canEvaluate,
  autoAccept: formState.autoAccept,
  locationsPositionId: formState.locationsPositionId,
  shiftPresetId:
    formState.shiftPresetId && formState.shiftPresetId !== CUSTOM_PRESET_ID
      ? formState.shiftPresetId
      : null,
  untimed: !!formState.isStandBy,
  managerNote: formState?.managerNote || '',
  tagIds: formState.tagIds,
  evaluationTagIds: formState.shiftEvaluationTagIds,
  shiftBreaks: formState.shiftBreaks.map((o) => ({
    startsAt: o.startsAt,
    endsAt: o.endsAt,
  })),
  shiftQualifications: formState.shiftQualifications || [],
  shiftRotationGroupIds: formState.shiftRotationGroupIds || [],
  ignoreConflicts: formState.ignoreConflicts,
  updateConnected: formState.updateConnected,
  assignmentGroupIds: formState.assignmentGroupIds,
});

export const isBreakInsideShiftTimeFrame = ({
  breakToValidate: { startsAt, endsAt },
  shiftStartsAt,
  shiftEndsAt,
}: {
  breakToValidate: { startsAt: Date; endsAt: Date; id: number };
  shiftStartsAt: Date;
  shiftEndsAt: Date;
}) =>
  isStartBeforeEnd(shiftStartsAt, startsAt, true) &&
  isStartBeforeEnd(endsAt, shiftEndsAt, true);

export const validateBreak = ({
  allBreaks,
  breakToValidate,
  shiftEndsAt,
  shiftStartsAt,
  timeZone,
}: {
  allBreaks: { startsAt: Date; endsAt: Date; id: number }[];
  breakToValidate: { startsAt: Date; endsAt: Date; id: number };
  shiftEndsAt: Date;
  shiftStartsAt: Date;
  timeZone: string;
}) =>
  isStartBeforeEnd(breakToValidate.startsAt, breakToValidate.endsAt) &&
  isBreakInsideShiftTimeFrame({
    breakToValidate,
    shiftEndsAt,
    shiftStartsAt,
  }) &&
  getOverlappingTimeFrames({
    intervalToValidate: breakToValidate,
    intervals: allBreaks,
    timeZone,
  }).length === 0;

export const shiftPresetOfRotationGroupForCurrentDate = (
  startsAt: Date,
  shiftRotations: GQLShiftRotationFragmentFragment[],
  timeZone: string,
): {
  shiftRotationGroupId: number;
  shiftRotationGroupName: string;
  shiftPresetId: number;
  shiftRotationName: string;
  shiftRotationId: number;
  anchorDateString: string;
  rotationInterval: number;
  rotationStartsAt: string | null;
  rotationEndsAt: string | null;
}[] => {
  const shiftStartsAt = getDateInTimeZone(startsAt, timeZone);

  if (!shiftRotations) {
    return [];
  }

  const shiftRotationsWithinTimeframe = shiftRotations.filter(
    ({ startsAt: rotationStartsAt, endsAt: rotationEndsAt }) => {
      const start = rotationStartsAt
        ? parseDateString(rotationStartsAt, timeZone)
        : MIN_DATE;
      const end = rotationEndsAt
        ? parseDateString(rotationEndsAt, timeZone)
        : MAX_DATE;

      return isWithinInterval(shiftStartsAt, { start, end });
    },
  );

  const data = shiftRotationsWithinTimeframe.flatMap((shiftRotation) => {
    const {
      anchorDate: anchorDateString,
      rotationInterval,
      shiftRotationGroups,
      name,
      id,
      startsAt: rotationStartsAt,
      endsAt: rotationEndsAt,
    } = shiftRotation;

    // eslint-disable-next-line no-restricted-syntax
    const anchorDate = startOfDay(parseDateString(anchorDateString, timeZone));

    const rotationPadding =
      differenceInCalendarDays(shiftStartsAt, anchorDate) % rotationInterval;
    const patternIndex =
      (rotationPadding < 0
        ? // make sure that index is positive
          rotationInterval + rotationPadding
        : rotationPadding) % rotationInterval;

    return shiftRotationGroups.map((shiftRotationGroup) => {
      // [1, 2, 1, 3, 5]
      const {
        shiftPresetIds,
        id: shiftRotationGroupId,
        name: shiftRotationGroupName,
      } = shiftRotationGroup;

      return {
        anchorDateString,
        rotationInterval,
        shiftPresetId: shiftPresetIds[patternIndex],
        shiftRotationGroupId,
        shiftRotationName: name,
        shiftRotationGroupName,
        shiftRotationId: id,
        rotationStartsAt,
        rotationEndsAt,
      };
    });
  });
  return data;
};

export const filteredShiftRotationGroupByShiftPreset = (
  startsAt: Date,
  shiftRotations: GQLShiftRotationFragmentFragment[],
  formShiftPresetId: number,
  timeZone: string,
): {
  anchorDateString: string;
  rotationStartsAt: string | null;
  rotationEndsAt: string | null;
  rotationInterval: number;
  shiftRotationGroupId: number;
  shiftRotationGroupName: string;
  shiftPresetId: number;
  shiftRotationName: string;
  shiftRotationId: number;
}[] =>
  shiftPresetOfRotationGroupForCurrentDate(
    startsAt,
    shiftRotations,
    timeZone,
  ).filter(({ shiftPresetId }) => shiftPresetId === formShiftPresetId);
