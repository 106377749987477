import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';

export enum CustomTiming {
  SHIFTPLAN_LOADED = 'shiftplan_loaded',
  CALENDAR_VIEW_LOADED = 'calendar_view_loaded',
  LIST_VIEW_LOADED = 'list_view_loaded',
  POSITIONS_VIEW_LOADED = 'position_view_loaded',
  EMPLOYEE_VIEW_LOADED = 'employee_view_loaded',
  UNSCHEDULED_VIEW_LOADED = 'unscheduled_view_loaded',
}

export enum CustomView {
  SHIFTPLAN_MAIN_VIEW = 'shiftplan_main_view',
  SHIFTPLAN_RELOAD = 'shiftplan_reload',
  UNSCHEDULED_VIEW = 'unscheduled_view',
}

class Datadog {
  public instance;

  public isReady = false;

  // eslint-disable-next-line no-useless-constructor
  public constructor(private logger: ApplicationLogger) {}

  public async init(companyId?: number) {
    const { datadogRum } = await import('@datadog/browser-rum');
    this.instance = datadogRum;
    try {
      if (
        !process.env.DATADOG_APPLICATION_ID ||
        !process.env.DATADOG_CLIENT_TOKEN ||
        !process.env.DATADOG_ALLOWED_TRACING_ORIGIN
      ) {
        throw new Error('environment variables are not set');
      }

      const apiOriginRegexp = new RegExp(
        process.env.DATADOG_ALLOWED_TRACING_ORIGIN,
      );

      // When logging is restricted to certain company ids,
      // we need to make sure its not loaded for other companies
      if (process.env.DATADOG_ENABLED_COMPANY_IDS !== undefined) {
        const enabledCompanyIds =
          process.env.DATADOG_ENABLED_COMPANY_IDS.split(',');
        if (!companyId || !enabledCompanyIds.includes(companyId.toString())) {
          return;
        }
      }

      datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: process.env.DATADOG_SITE,
        env: process.env.DATADOG_ENVIRONMENT,
        service: process.env.DATADOG_SERVICE,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        version: process.env.RELEASE,
        allowedTracingOrigins: [apiOriginRegexp],
      });
      datadogRum.startSessionReplayRecording();
      this.isReady = true;
    } catch (e) {
      this.logger.instance.error({
        e,
        tags: [SentryTag.INIT, 'datadog'],
      });
    }
  }
}

export default Datadog;
