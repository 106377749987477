import { FiltersMap } from 'components/calendar-common/filters/Store';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Action from './Action';
import Mutation from './Mutation';

export const quickFilterNS = namespace('shiftSchedule/quickFilter');

export interface StoreState {
  filters: FiltersMap[];
}

export const getStore = (): Module<StoreState, RootStoreState> => ({
  state: {
    filters: [],
  },
  namespaced: true,
  mutations: {
    [Mutation.SET_FILTERS](state, filters: FiltersMap[]) {
      state.filters = filters;
    },
  },
  actions: {
    [Action.SET_FILTERS]({ commit }, filters: FiltersMap[]) {
      commit(Mutation.SET_FILTERS, filters);
    },
  },
});

export default getStore;
