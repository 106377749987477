import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import { createEventPayload, EventPayload } from 'utils/events';
import { getRandomString } from 'utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import DetailsTab from './details/Details';
import styles from './dialog-assignment-group.css';

// required to make browser form validation work
export const FORM_ID = `dialog-${getRandomString()}`;

export interface FormState {
  name: string;
}

@Component
export default class DialogAssignmentGroup extends TsxComponent<
  {
    formState: FormState;
    hasError?: boolean;
    isLoading?: boolean;
    isOpen?: boolean;
    isSubmitting: boolean;
    isDeleting: boolean;
    isUpdate: boolean;
  },
  {
    onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
    onDeleteClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
    onInput: <T extends keyof FormState>(
      payload: EventPayload<{ field: T; value: FormState[T] }>,
    ) => void;
    onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  }
> {
  @Prop()
  public formState: FormState;

  @Prop()
  public hasError?: boolean;

  @Prop()
  public isLoading?: boolean;

  @Prop()
  public isOpen?: boolean;

  @Prop()
  public isSubmitting: boolean;

  @Prop()
  public isDeleting: boolean;

  @Prop()
  public isUpdate: boolean;

  protected get isNameValid() {
    return !!this.formState.name && this.formState.name.trim().length > 0;
  }

  protected get isFormStateValid() {
    return !this.isSubmitting && this.isNameValid;
  }

  protected onDeleteClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit(
        'deleteClick',
        createEventPayload<void, HTMLElement, UIEvent>(e, undefined),
      );
    }
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    this.$emit(
      'closeClick',
      createEventPayload<void, HTMLElement, UIEvent>(e, undefined),
    );
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        error={
          this.hasError && this.$t('assignmentGroups.modal.error.notFound')
        }
        isClosingPrevented={this.isSubmitting}
        isLoading={this.isLoading}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        title={
          this.isUpdate
            ? this.$t('assignmentGroups.modal.titleUpdate')
            : this.$t('assignmentGroups.modal.titleCreate')
        }
      >
        <div class={styles.dialogAssignmentGroupInner}>
          <DetailsTab
            formState={this.formState}
            hasError={this.hasError}
            isSubmitting={this.isSubmitting}
            isUpdate={this.isUpdate}
            isLoading={this.isLoading}
            onCloseClick={(e) => this.$emit('closeClick', e)}
            onInput={(e) => this.$emit('input', e)}
            onSubmit={(e) => this.$emit('submit', e)}
          />
        </div>
        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('general.buttonCancel')}
        </Button>
        {!this.hasError && this.isUpdate && !this.isLoading && (
          <Button
            color={ButtonColor.ERROR}
            disabled={this.isDeleting || this.isSubmitting}
            size={Size.SMALL}
            slot={Slot.BUTTONS_LEFT}
            type="button"
            kind={ButtonKind.GHOST}
            onClick={this.onDeleteClick}
          >
            {this.$t('assignmentGroups.modal.buttonDelete')}
          </Button>
        )}
        {!this.hasError && (
          <Button
            disabled={
              this.isSubmitting || !this.isFormStateValid || this.isLoading
            }
            form={FORM_ID}
            size={Size.SMALL}
            slot={Slot.BUTTONS_RIGHT}
            type="submit"
          >
            {this.isUpdate
              ? this.$t('assignmentGroups.modal.buttonUpdate')
              : this.$t('assignmentGroups.modal.buttonCreate')}
          </Button>
        )}
      </DialogWithSpinnerAndError>
    );
  }
}
