import { GQLAbsenceState } from 'codegen/gql-types';
import Employment from 'components/calendar-common/employments/Employment';
import moment from 'moment';
import { Module } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import Absence from './Absence';
import { CalendarCacheKey } from '../Enums';

// tslint:disable-next-line:variable-name
export const AbsenceIcons = {
  [GQLAbsenceState.NEW]: 'absence',
  [GQLAbsenceState.ACCEPTED]: 'absences-state-approved',
};

export const store = (): Module<{}, RootStoreState> => ({
  namespaced: true,
  getters: {
    absences(state, getters, rootState, rootGetters) {
      // unroll absence into a separate event for each absence day
      return Object.freeze(
        rootGetters['absences/getByCacheIdentifier'](
          CalendarCacheKey.ABSENCE_CACHE_KEY,
        ).reduce((acc, absence) => {
          const momentStart = moment(absence.startsAt);
          const momentEnd = moment(absence.endsAt);
          while (momentStart.isSameOrBefore(momentEnd, 'day')) {
            acc.push(
              new Absence({
                ...absence,
                reason: absence.absenceReason,
                date: momentStart.toString(),
                employment: new Employment(absence.employment),
              }),
            );
            momentStart.add(1, 'day');
          }
          return acc;
        }, [] as Absence[]),
      );
    },
  },
});
