import { getUrlWithApiPrefix } from 'utils/url';
import { i18n } from 'i18next';
import VueRouter, { NavigationGuard, Route } from 'vue-router';
import GaTracker from 'services/ga-tracker/GaTracker';
import routes, { publicRouteNames } from './routes';
import { ApplicationStore } from './store/Store';

class ApplicationRouter {
  private router: VueRouter;

  private store: ApplicationStore;

  private gaTracker: GaTracker;

  private i18n: i18n;

  public constructor(
    store: ApplicationStore,
    i18nInstance: i18n,
    gaTracker: GaTracker,
  ) {
    this.router = new VueRouter({
      routes: routes(),
      mode: 'history',
    });

    this.store = store;

    this.i18n = i18nInstance;

    this.gaTracker = gaTracker;

    this.router.beforeEach(this.checkAuth.bind(this));
    this.router.afterEach(this.trackPageView.bind(this));
    this.router.afterEach(this.trySetDocumentTitle.bind(this));
  }

  public getInstance() {
    return this.router;
  }

  private trackPageView(to: Route) {
    this.gaTracker.trackPageView(to.path);
  }

  private checkAuth(
    to: Parameters<NavigationGuard>[0],
    from: Parameters<NavigationGuard>[1],
    next: Parameters<NavigationGuard>[2],
  ) {
    const isAuthorized = this.store.getters['auth/isAuthorized'];

    if (isAuthorized === true || publicRouteNames.includes(to.name)) {
      next();
    } else {
      window.location.href = getUrlWithApiPrefix('/login');
      next(false);
    }
  }

  private trySetDocumentTitle(to: Parameters<NavigationGuard>[0]) {
    const match = to.matched.find((route) => !!route.meta.title);

    if (typeof match?.meta?.title === 'function') {
      document.title = match.meta.title(this.i18n.t.bind(this.i18n));
    } else if (typeof match?.meta?.title === 'string') {
      document.title = match.meta.title;
    }
  }
}

export default ApplicationRouter;
