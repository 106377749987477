import { Store } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import ApplicationLogger from 'services/logger/ApplicationLogger';

// add ga to window interface
declare global {
  interface Window {
    ga?: Function;
  }
}

export interface EventParams {
  category: string;
  action: string;
  label?: string;
  value?: string;
}
class GaTracker {
  private apiUrl: string;

  private store: Store<RootStoreState>;

  private logger: ApplicationLogger;

  public constructor(
    apiUrl: string,
    store: Store<RootStoreState>,
    logger: ApplicationLogger,
  ) {
    this.apiUrl = apiUrl;
    this.store = store;
    this.logger = logger;
  }

  // used to track page views
  public trackPageView(path: string) {
    const url = this.getUrl(path);
    this.sendPageView(url);
  }

  // used to track API calls
  public trackAction({ url, method }: { url: string; method: string }) {
    let path = this.getUrl(url.replace(this.apiUrl, ''));
    path += `[${method}]`;
    this.sendPageView(path);
  }

  public trackEvent(
    params: EventParams,
    customDimensions?: Record<string, string | undefined>,
  ) {
    this.sendEvent(params, customDimensions);
  }

  private getEmployment() {
    return this.store.state.auth.currentEmployment;
  }

  private getCompany() {
    return this.store.state.auth.currentCompany;
  }

  private getDimensions() {
    const { name: companyName = undefined, id: companyId = undefined } =
      this.getCompany() || {};
    const { id: employmentId = undefined } = this.getEmployment() || {};
    return {
      dimension1: '',
      dimension2: '',
      dimension3: companyName,
      dimension4: employmentId,
      dimension5: companyId,
      dimension6: '',
      dimension7: '',
      dimension8: '',
    };
  }

  private sendPageView(url) {
    if (window.ga) {
      // match dimensions from web tracker
      try {
        window.ga('global.send', 'pageview', {
          page: this.getUrl(url),
          ...this.getDimensions(),
        });
      } catch (error) {
        this.logger.instance.error({ error });
      }
    }
  }

  private sendEvent(
    eventParams: EventParams,
    customDimensions?: Record<string, string | undefined>,
  ) {
    if (window.ga) {
      // match dimensions from web tracker
      try {
        window.ga('global.send', {
          hitType: 'event',
          eventAction: eventParams.action,
          eventCategory: eventParams.category,
          eventLabel: eventParams.label,
          eventValue: eventParams.value,
          ...this.getDimensions(),
          ...customDimensions,
        });
      } catch (error) {
        this.logger.instance.error({ error });
      }
    }
  }

  private getUrl(url?: string) {
    if (!url || url.length === 0) {
      return '/';
    }
    const paths = url.split('/');
    return paths
      .map((path, index, array) =>
        path.length === 0 || Number.isNaN(+path)
          ? path
          : `{${array[index - 1] || 'key'}_id}`,
      )
      .join('/');
  }
}

export default GaTracker;
