import {
  DATE_TIME_STRING_FORMAT,
  splitIntervalIntoDays,
} from 'components/calendar-common/helpers/intervals/Intervals.js';
import { getDateKey } from 'components/calendar-common/common/DateItem';
import {
  DEFAULT_TIME_GRID_CELL_HEIGHT,
  DEFAULT_TIME_GRID_STEP,
} from 'components/calendar-common/grid/time-grid/TimeGrid';
import moment from 'moment';
import { Dispatch, Module } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import MouseMoveTracker from '../mouse-move-tracker/MouseMoveTracker';
import * as Actions from './Actions';
import EventPart from './EventPart';
import Mutations from './Mutations';
import { SplitableEvent } from './SplitableEvent';

export enum EventEditTypes {
  NEW_EVENT = 'newEvent',
  RESIZE_EVENT = 'resizeEvent',
  DRAG_EVENT = 'dragEvent',
}

export enum DropActionTypes {
  DEFAULT = 'default',
  DELETE = 'delete',
}

export type onDropCallback = (
  event: SplitableEvent,
  eventEditType: EventEditTypes,
  dropAction: DropActionTypes,
  dispatch: Dispatch,
) => void | Promise<any>;

type UtilData = {
  eventHandler: EventListener;
  mouseMoveTracker: MouseMoveTracker;
} | null;

export interface StoreState<T extends SplitableEvent> {
  hoveredEventId: number | null;
  editedEvent: T | null;
  eventEditType: EventEditTypes | null;
  utilData: UtilData;
}

interface GridProps {
  step: number;
  height: number;
}

export function createStore<T extends SplitableEvent>(
  eventsGetterPath: string,
  datesGetterPath: string,
  EventPartConstructor: typeof EventPart,
  onDrop: onDropCallback,
  gridProps: GridProps = {
    step: DEFAULT_TIME_GRID_STEP,
    height: DEFAULT_TIME_GRID_CELL_HEIGHT,
  },
): Module<StoreState<T>, RootStoreState> {
  const splitEventIntoParts = (
    event: SplitableEvent,
    isEditedEventCopy = false,
  ): EventPart[] => {
    const eventIntervals = splitIntervalIntoDays(
      event.getStartDateTime(),
      event.getEndDateTime(),
    );
    const eventParts = eventIntervals.map(
      ({ start, end }) =>
        new EventPartConstructor(
          event,
          event.id,
          start,
          end,
          0,
          isEditedEventCopy,
        ),
    );

    return event.isFixed ? eventParts.slice(0, 1) : eventParts;
  };

  return {
    namespaced: true,
    state: {
      hoveredEventId: null,
      editedEvent: null,
      eventEditType: null,
      utilData: null,
    },
    mutations: {
      [Mutations.SET_HOVERED_EVENT_ID](state, id = null) {
        state.hoveredEventId = id;
      },
      [Mutations.SET_EDITED_EVENT](state, event = null) {
        state.editedEvent = event;
      },
      [Mutations.SET_EVENT_EDIT_TYPE](state, eventEditType = null) {
        state.eventEditType = eventEditType;
      },
      [Mutations.SET_UTIL_DATA](state, utilData: UtilData = null) {
        if (utilData === null) {
          if (state.utilData !== null) {
            document.removeEventListener(
              'mouseup',
              state.utilData.eventHandler,
            );
            state.utilData.mouseMoveTracker.destroy();
          }
        } else {
          document.addEventListener('mouseup', utilData.eventHandler);
        }
        state.utilData = utilData;
      },
    },
    getters: {
      existingEventParts(state, getters, rootState, rootGetters) {
        const datesDictionary: Record<string, EventPart[]> = rootGetters[
          datesGetterPath
        ].reduce((acc, date) => {
          acc[date.dateKey] = [];
          return acc;
        }, {});
        return rootGetters[eventsGetterPath].reduce((acc, event) => {
          splitEventIntoParts(event).forEach((eventPart: EventPart) => {
            const startDateKey = getDateKey(eventPart.start);
            if (Object.hasOwnProperty.call(acc, startDateKey)) {
              acc[startDateKey].push(eventPart);
            }
          });
          return acc;
        }, datesDictionary);
      },
      existingEventPartsOnTimeGrid(state, getters, rootState, rootGetters) {
        return rootGetters[datesGetterPath].reduce((eventParts, date) => {
          const dateEvents = [
            ...(getters.existingEventParts[date.dateKey] || []),
          ];
          // sort events by start time
          dateEvents.sort((a, b) => {
            const ma = a.getGridStartDate();
            const mb = b.getGridStartDate();
            if (ma.isAfter(mb, 'minute')) {
              return 1;
            }
            if (ma.isBefore(mb, 'minute')) {
              return -1;
            }
            // longest shift comes first
            return b.duration() - a.duration();
          });
          // calculate events indent
          const reducedEvents = dateEvents.reduce(
            (acc, shift, index, collection) => {
              const item = shift;
              // first item
              if (index === 0) {
                item.indent = 0;
                acc.currentIndent = 1;
              } else {
                let currentIndex = index - 1;
                let prevElement = collection[currentIndex];
                let indent = Math.max(acc.maxIndent, acc.currentIndent);
                /* check if current even overlaps with any of already processed events
                   we go through all events and check if events overlap
                   if we find event with some indent that is overlapping
                   - we update possibleIndents array
                  after all events are checked we pick smallest remaining
                  indent from empty indents array
                  */
                // create array of [0,..., indent]
                let possibleIndents = Array.from(
                  new Array(Math.max(indent, 0)),
                  (value, indentIndex) => indentIndex,
                );
                while (
                  prevElement !== undefined &&
                  possibleIndents.length !== 0
                ) {
                  const prevElementIndent = prevElement.indent;
                  if (item.start < prevElement.end) {
                    possibleIndents = possibleIndents.filter(
                      (it) => it !== prevElementIndent,
                    );
                  }

                  currentIndex -= 1;
                  prevElement = collection[currentIndex];
                }
                indent =
                  possibleIndents.length === 0 ? indent : possibleIndents[0];
                item.indent = Math.max(indent, 0);
                indent += 1;
                acc.currentIndent = Math.max(indent, 1);
              }
              acc.items.push(item);
              acc.maxIndent = Math.max(acc.maxIndent, acc.currentIndent);
              return acc;
            },
            {
              currentIndent: 0,
              maxIndent: 0,
              items: [],
            },
          );
          // calculate indent coefficient from current element indent and max row indent

          // eslint-disable-next-line no-param-reassign
          eventParts[date.dateKey] = reducedEvents.items.map((it) => {
            // indent coefficient will be used to set element margin from left side.
            // in a form of indentCoeff * cell_width
            // we also want to have free space equal to 2/max_indent * cell_width
            // eslint-disable-next-line no-param-reassign
            it.indentCoeff =
              it.indent / Math.max(reducedEvents.maxIndent, 1) / 2;
            return it;
          });
          return eventParts;
        }, {});
      },
      eventParts(state, getters) {
        if (state.editedEvent === null) {
          return getters.existingEventPartsOnTimeGrid;
        }

        // add new parts to existing object
        return splitEventIntoParts(state.editedEvent, true).reduce(
          (acc, eventPart: EventPart) => {
            const gridStartDate = eventPart.getGridStartDate();
            if (acc[getDateKey(gridStartDate)]) {
              // we need concat here so original arrays are not mutated
              acc[getDateKey(gridStartDate)] = [
                ...acc[getDateKey(gridStartDate)],
                eventPart,
              ];
            }
            return acc;
          },
          { ...getters.existingEventPartsOnTimeGrid },
        );
      },
      isEditingEvent(state) {
        return state.editedEvent !== null;
      },
    },
    actions: {
      [Actions.SET_HOVERED_EVENT]({ commit }, id) {
        commit(Mutations.SET_HOVERED_EVENT_ID, id);
      },
      [Actions.REMOVE_HOVERED_EVENT]({ commit, state }, id) {
        if (state.hoveredEventId === id) {
          commit(Mutations.SET_HOVERED_EVENT_ID, null);
        }
      },
      [Actions.START_EVENT_EDIT](
        { commit, dispatch, state },
        { event, editType },
      ) {
        const eventHandler: EventListener = () => {
          dispatch(Actions.STOP_EVENT_EDIT);
        };
        const mouseMoveTracker = new MouseMoveTracker(
          gridProps.height,
          (steps) => {
            if (state.editedEvent === null) {
              return;
            }
            const addedValue = steps * gridProps.step;
            const start = state.editedEvent.getStartDateTime();
            const end = state.editedEvent.getEndDateTime();
            const updatedParams: Partial<SplitableEvent> = {
              endsAt: moment(end)
                .clone()
                .add(addedValue, 'minutes')
                .format(DATE_TIME_STRING_FORMAT),
            };
            if (state.eventEditType === EventEditTypes.DRAG_EVENT) {
              updatedParams.startsAt = moment(start)
                .clone()
                .add(addedValue, 'minutes')
                .format(DATE_TIME_STRING_FORMAT);
            }

            dispatch(Actions.UPDATE_EDITED_EVENT, updatedParams);
          },
        );

        // make copy of original object
        commit(Mutations.SET_EDITED_EVENT, new event.constructor(event));
        commit(Mutations.SET_EVENT_EDIT_TYPE, editType);
        commit(Mutations.SET_UTIL_DATA, { eventHandler, mouseMoveTracker });
      },
      [Actions.UPDATE_EDITED_EVENT](
        { commit, state },
        updatedParams: Partial<SplitableEvent> = {},
      ) {
        const { editedEvent } = state;

        if (editedEvent === null) {
          return;
        }

        const needUpdate = Object.entries(updatedParams).some(
          ([key, value]) => editedEvent[key] !== value,
        );
        if (needUpdate) {
          commit(
            Mutations.SET_EDITED_EVENT,
            Object.assign(state.editedEvent || {}, updatedParams),
          );
        }
      },
      [Actions.STOP_EVENT_EDIT](
        { commit, state, dispatch },
        dropAction: DropActionTypes = DropActionTypes.DEFAULT,
      ) {
        const clearDndData = () => {
          // clear edited shift
          commit(Mutations.SET_EDITED_EVENT);
          commit(Mutations.SET_EVENT_EDIT_TYPE);
          commit(Mutations.SET_UTIL_DATA);
        };
        const { editedEvent, eventEditType } = state;
        // call callback
        if (editedEvent !== null && eventEditType !== null) {
          const promise = onDrop(
            editedEvent,
            eventEditType,
            dropAction,
            dispatch,
          );
          if (promise instanceof Promise) {
            promise.then(() => clearDndData());
          } else {
            clearDndData();
          }
        }
      },
    },
  };
}
