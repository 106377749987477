import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import {
  MSECS_IN_MINUTE,
  getISOWeekDurationString,
  getHourMinuteDurationString,
} from 'utils/date-related';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './conflicts.css';

@Component
export default class MaximumWorkingHoursShiftConflict extends TsxComponent<{
  conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MaximumWorkingHoursShiftConflict' }
  >;
  isSelf?: boolean;
}> {
  @Prop()
  public conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'MaximumWorkingHoursShiftConflict' }
  >;

  @Prop()
  public isSelf?: boolean;

  protected get isWeekly() {
    return this.conflict.weeklyMinutes !== null;
  }

  protected get msecsAllowed() {
    if (this.conflict.weeklyMinutes !== null) {
      return this.conflict.weeklyMinutes * MSECS_IN_MINUTE;
    }

    if (this.conflict.monthlyMinutes !== null) {
      return this.conflict.monthlyMinutes * MSECS_IN_MINUTE;
    }

    return 0;
  }

  protected get msecsRequested() {
    if (this.conflict.total !== null) {
      return this.conflict.total * MSECS_IN_MINUTE;
    }

    return 0;
  }

  protected get formattedAllowed() {
    return getHourMinuteDurationString(this.msecsAllowed);
  }

  protected get formattedRequested() {
    return getHourMinuteDurationString(this.msecsRequested);
  }

  protected get translationKey() {
    if (this.isSelf) {
      return this.isWeekly
        ? 'conflicts.maximumWorkingHours.textSelf.weekly'
        : 'conflicts.maximumWorkingHours.textSelf.monthly';
    }

    return this.isWeekly
      ? 'conflicts.maximumWorkingHours.textStakeholder.weekly'
      : 'conflicts.maximumWorkingHours.textStakeholder.monthly';
  }

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.maximumWorkingHours.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {this.$t(this.translationKey, {
            name: this.conflict.name,
            hour: this.formattedAllowed,
            total: this.formattedRequested,
            week:
              !this.conflict.week || !this.conflict.year
                ? ''
                : getISOWeekDurationString(
                    this.$i18n.i18next.language,
                    this.conflict.week,
                    this.conflict.year,
                  ),
            month:
              !this.conflict.year || !this.conflict.month
                ? ''
                : // FAQ: toLocaleDateString can be used here as we construct a date based on
                  // year and month irrelevant of time zone
                  new Date(
                    this.conflict.year,
                    this.conflict.month - 1,
                    // eslint-disable-next-line no-restricted-syntax
                  ).toLocaleDateString(this.$i18n.i18next.language, {
                    month: 'long',
                    year: 'numeric',
                  }),
          })}
        </p>
      </section>
    );
  }
}
