export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Json: any;
};

export type GQLQuery = {
  _empty: Maybe<Scalars['String']>;
  absenceDuration: Maybe<GQLAbsenceDuration>;
  absenceEntitlements: GQLAbsenceEntitlementsCollection;
  absenceReasons: GQLAbsenceReasonCollection;
  absenceReasonsStats: GQLAbsenceReasonsStatsResponse;
  absences: GQLAbsenceCollection;
  absencesEmployments: GQLEmploymentCollection;
  assignmentGroups: GQLAssignmentGroupCollection;
  availabilities: GQLAvailabilityCollection;
  /** Gets statistics per employee about their availabilities */
  availabilityAggregations: GQLAvailabilityAggregationCollection;
  availabilityExceptions: GQLAvailabilityExceptionCollection;
  backgroundJobs: GQLBackgroundJobCollection;
  calendarAggregations: Array<GQLCalendarAggregation>;
  chatEmployments: GQLChatEmploymentCollection;
  chatMessages: GQLChatMessageCollection;
  chats: GQLChatCollection;
  companies: GQLCompanyCollection;
  customFields: GQLCustomFieldCollection;
  /** Get the DayNotes for a specific shiftplanId */
  dayNotes: GQLDayNoteCollection;
  employmentEvaluation: Maybe<GQLEmploymentEvaluation>;
  employmentEvaluationPayments: GQLPaymentCollection;
  employmentLiveInfo: Maybe<GQLPunchTimingLiveInfo>;
  /** Get the Paygrades for a specific employmentId */
  employmentPaygrades: Maybe<GQLEmploymentPaygradeCollection>;
  employments: GQLEmploymentCollection;
  employmentsAvailabilities: Array<GQLAvailability>;
  employmentsInfo: GQLEmploymentsInfoCollection;
  employmentsPositions: GQLEmploymentsPositionCollection;
  employmentsRotationGroups: GQLEmploymentsRotationGroupsCollection;
  employmentsSurvey: Maybe<GQLEmploymentsSurvey>;
  /** Get the UiSetting for a specific employmentId */
  employmentsUiSetting: GQLEmploymentsUiSetting;
  evaluation: GQLEvaluation;
  evaluationBreaks: GQLEvaluationBreakCollection;
  evaluationPayments: GQLPaymentCollection;
  evaluations: GQLEvaluationCollection;
  individualShiftRotations: GQLIndividualShiftRotationCollection;
  /** Get the configured languages for a given Company */
  languages: GQLLanguageCollection;
  liveStaffShifts: GQLLiveStaffShiftCollection;
  locations: GQLLocationCollection;
  /** Get location Shiftplan publish settings */
  locationShiftplanPublishSetting: GQLLocationShiftplanPublishSettings;
  locationsPositions: GQLLocationsPositionCollection;
  /** List of employments of current user */
  myEmployments: Array<GQLEmployment>;
  /** Get the Rights of the User in the Company with the given ID */
  myRights: Maybe<Array<Maybe<GQLRight>>>;
  newsfeeds: GQLNewsfeedCollection;
  notificationConfigurations: GQLNotificationConfigurationCollection;
  paygradeTypes: Maybe<GQLPaygradeTypeCollection>;
  paymentsCalculation: GQLPaymentsCalculation;
  payslips: GQLPayslipCollection;
  positions: GQLPositionCollection;
  punchLocationsPositions: Maybe<GQLPunchLocationsPositions>;
  punchState: Maybe<GQLPunchStateOrError>;
  qualifications: GQLQualificationCollection;
  requests: GQLRequestCollection;
  /** Get the Rights of the Company with the given ID */
  rights: GQLRightCollection;
  shiftAssignmentGroups: GQLShiftAssignmentGroupCollection;
  shiftAvailableEmployments: GQLShiftAvailableEmploymentCollection;
  shiftBreaks: GQLShiftBreakCollection;
  shiftEvaluationTags: GQLTagCollection;
  /** Get the Paygrades for a specific shiftId */
  shiftPaygrades: GQLShiftPaygradeCollection;
  /** Get NotificationCount for an existing shiftplan */
  shiftplanNotifications: GQLShiftplanNotifications;
  shiftplans: GQLShiftplanCollection;
  shiftPresets: GQLShiftPresetCollection;
  shiftRotationGroups: GQLShiftRotationGroupCollection;
  shiftRotations: GQLShiftRotationCollection;
  shifts: GQLShiftCollection;
  shiftsTags: GQLShiftsTagCollection;
  specialDates: GQLSpecialDateCollection;
  staffShifts: GQLStaffShiftCollection;
  staffShiftsTags: GQLStaffShiftsTagCollection;
  tags: GQLTagCollection;
  translations: Maybe<Array<Maybe<GQLTranslation>>>;
  users: GQLUserCollection;
};

export type GQLQueryAbsenceDurationArgs = {
  absenceDays: InputMaybe<Scalars['Float']>;
  absenceReasonId: Scalars['Int'];
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
  endsAt: Scalars['Date'];
  startsAt: Scalars['Date'];
};

export type GQLQueryAbsenceEntitlementsArgs = {
  absenceReasonIds: InputMaybe<Array<Scalars['Int']>>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  withDeleted: InputMaybe<Scalars['Boolean']>;
  year: InputMaybe<Scalars['Int']>;
};

export type GQLQueryAbsenceReasonsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryAbsenceReasonsStatsArgs = {
  attachment: InputMaybe<Scalars['Boolean']>;
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
  endsAt: InputMaybe<Scalars['Date']>;
  locationIds: InputMaybe<Array<Scalars['Int']>>;
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLQueryAbsencesArgs = {
  absenceReasonId: InputMaybe<Scalars['Int']>;
  absenceReasonIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  attachment: InputMaybe<Scalars['Boolean']>;
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  employmentStatuses: InputMaybe<Array<GQLEmploymentStatus>>;
  endsAt: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationId: InputMaybe<Scalars['Int']>;
  locationIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  orderDir: InputMaybe<GQLOrderDirection>;
  orderKey: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  paid: InputMaybe<Scalars['Boolean']>;
  perPage?: InputMaybe<Scalars['Int']>;
  startsAt: InputMaybe<Scalars['Date']>;
  state: InputMaybe<GQLAbsenceState>;
  states: InputMaybe<Array<InputMaybe<GQLAbsenceState>>>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryAbsencesEmploymentsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  orderDir: InputMaybe<GQLOrderDirection>;
  orderKey: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GQLQueryAssignmentGroupsArgs = {
  companyId: Scalars['Int'];
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftplanIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GQLQueryAvailabilitiesArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  endsAt: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  startsAt: Scalars['Date'];
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryAvailabilityAggregationsArgs = {
  companyId: Scalars['Int'];
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  endsAt: Scalars['Date'];
  locationIds: InputMaybe<Array<Scalars['Int']>>;
  orderDir?: InputMaybe<GQLOrderDirection>;
  orderKey?: InputMaybe<GQLAvailabilityAggregationOrderKey>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  positionIds: InputMaybe<Array<Scalars['Int']>>;
  startsAt: Scalars['Date'];
};

export type GQLQueryAvailabilityExceptionsArgs = {
  availabilityId: InputMaybe<Scalars['Int']>;
  availabilityIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryBackgroundJobsArgs = {
  companyId: Scalars['Int'];
  createdAfter: InputMaybe<Scalars['Date']>;
  ids: InputMaybe<Array<Scalars['String']>>;
  updatedAfter: InputMaybe<Scalars['Date']>;
};

export type GQLQueryCalendarAggregationsArgs = {
  companyId: Scalars['Int'];
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['Date'];
  locationIds: InputMaybe<Array<Scalars['Int']>>;
  locationsPositionIds: InputMaybe<Array<Scalars['Int']>>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  startDate: Scalars['Date'];
};

export type GQLQueryChatEmploymentsArgs = {
  companyId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryChatMessagesArgs = {
  companyId: Scalars['Int'];
  lastMessageId: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  recipientId: Scalars['Int'];
  recipientType: GQLChatType;
};

export type GQLQueryChatsArgs = {
  companyId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryCompaniesArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryCustomFieldsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryDayNotesArgs = {
  companyId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftplanId: Scalars['Int'];
};

export type GQLQueryEmploymentEvaluationArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEmploymentEvaluationPaymentsArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEmploymentLiveInfoArgs = {
  id: Scalars['Int'];
};

export type GQLQueryEmploymentPaygradesArgs = {
  employmentId: Scalars['Int'];
};

export type GQLQueryEmploymentsArgs = {
  accessLevel: InputMaybe<GQLEmploymentAccessLevel>;
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  includeLiveInfo?: InputMaybe<Scalars['Boolean']>;
  invitationState: InputMaybe<GQLEmploymentInvitationState>;
  invitationStates: InputMaybe<Array<InputMaybe<GQLEmploymentInvitationState>>>;
  isEmployee: InputMaybe<Scalars['Boolean']>;
  isOwner: InputMaybe<Scalars['Boolean']>;
  isStakeholder: InputMaybe<Scalars['Boolean']>;
  locationId: InputMaybe<Scalars['Int']>;
  locationIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationsPositionId: InputMaybe<Scalars['Int']>;
  locationsPositionIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notInShiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  orderDir?: InputMaybe<GQLOrderDirection>;
  orderKey?: InputMaybe<GQLEmploymentSort>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  shiftplanId: InputMaybe<Scalars['Int']>;
  shiftplanIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  shiftRotationGroupId: InputMaybe<Scalars['Int']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
  withDeleted: InputMaybe<Scalars['Boolean']>;
};

export type GQLQueryEmploymentsAvailabilitiesArgs = {
  availabilityIds: InputMaybe<Array<Scalars['Int']>>;
  companyId: InputMaybe<Scalars['Int']>;
  employmentId: Scalars['Int'];
  endsAt: InputMaybe<Scalars['Date']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  startsAt: Scalars['Date'];
};

export type GQLQueryEmploymentsInfoArgs = {
  companyId: Scalars['Int'];
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['Date'];
  locationIds: InputMaybe<Array<Scalars['Int']>>;
  locationsPositionIds: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchString: InputMaybe<Scalars['String']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  sortDirection?: InputMaybe<GQLOrderDirection>;
  sortName?: InputMaybe<GQLShiftEmploymentSort>;
  startDate: Scalars['Date'];
};

export type GQLQueryEmploymentsPositionsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationsPositionId: InputMaybe<Scalars['Int']>;
  locationsPositionIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  responseFormat?: InputMaybe<GQLResponseFormat>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryEmploymentsRotationGroupsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  rotationType?: InputMaybe<Array<GQLRotationType>>;
  shiftRotationGroupId: InputMaybe<Scalars['Int']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  shiftRotationId: InputMaybe<Scalars['Int']>;
};

export type GQLQueryEmploymentsSurveyArgs = {
  id: Scalars['Int'];
};

export type GQLQueryEmploymentsUiSettingArgs = {
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
};

export type GQLQueryEvaluationArgs = {
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEvaluationBreaksArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  endsAt: InputMaybe<Scalars['Date']>;
  evaluationIds: InputMaybe<Array<Scalars['Int']>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftId: InputMaybe<Scalars['Int']>;
  shiftIds: InputMaybe<Array<Scalars['Int']>>;
  staffShiftId: InputMaybe<Scalars['Int']>;
  staffShiftIds: InputMaybe<Array<Scalars['Int']>>;
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLQueryEvaluationPaymentsArgs = {
  staffShiftId: Scalars['Int'];
};

export type GQLQueryEvaluationsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  endsAt: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<Scalars['Int']>>;
  includePayments: InputMaybe<Scalars['Boolean']>;
  locationId: InputMaybe<Scalars['Int']>;
  locationIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationsPositionId: InputMaybe<Scalars['Int']>;
  locationsPositionIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  positionId: InputMaybe<Scalars['Int']>;
  positionIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  shiftIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsAt: InputMaybe<Scalars['Date']>;
  state: InputMaybe<GQLEvaluationState>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryIndividualShiftRotationsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<Scalars['Int']>>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryLanguagesArgs = {
  companyId: Scalars['Int'];
};

export type GQLQueryLiveStaffShiftsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  endsAt: InputMaybe<Scalars['Date']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLQueryLocationsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryLocationShiftplanPublishSettingArgs = {
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
};

export type GQLQueryLocationsPositionsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationId: InputMaybe<Scalars['Int']>;
  locationIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  positionId: InputMaybe<Scalars['Int']>;
  positionIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryMyEmploymentsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
};

export type GQLQueryMyRightsArgs = {
  companyId: Scalars['Int'];
};

export type GQLQueryNewsfeedsArgs = {
  companyId: Scalars['Int'];
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryNotificationConfigurationsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryPaygradeTypesArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryPaymentsCalculationArgs = {
  companyId: Scalars['Int'];
  input: GQLPaymentsCalculationInput;
  staffShiftId: Scalars['Int'];
};

export type GQLQueryPayslipsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryPositionsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryPunchLocationsPositionsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  shiftplanId: Scalars['Int'];
  userToken: InputMaybe<Scalars['Int']>;
};

export type GQLQueryPunchStateArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  userToken: InputMaybe<Scalars['Int']>;
};

export type GQLQueryQualificationsArgs = {
  companyId: Scalars['Int'];
  ids: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryRequestsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftId: InputMaybe<Scalars['Int']>;
  shiftIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  shiftplanId: InputMaybe<Scalars['Int']>;
  shiftplanIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: InputMaybe<GQLRequestType>;
  types: InputMaybe<Array<InputMaybe<GQLRequestType>>>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryRightsArgs = {
  companyId: Scalars['Int'];
};

export type GQLQueryShiftAssignmentGroupsArgs = {
  assignmentGroupId: InputMaybe<Scalars['Int']>;
  assignmentGroupIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  companyId: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GQLQueryShiftAvailableEmploymentsArgs = {
  companyId: Scalars['Int'];
  filters: InputMaybe<GQLShiftEmploymentFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchString: InputMaybe<Scalars['String']>;
  shiftId: Scalars['Int'];
  sortDirection?: InputMaybe<GQLOrderDirection>;
  sortName?: InputMaybe<GQLShiftEmploymentSort>;
};

export type GQLQueryShiftBreaksArgs = {
  companyId: Scalars['Int'];
  endsAt: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftId: InputMaybe<Scalars['Int']>;
  shiftIds: InputMaybe<Array<Scalars['Int']>>;
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLQueryShiftEvaluationTagsArgs = {
  companyId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftId: InputMaybe<Scalars['Int']>;
};

export type GQLQueryShiftPaygradesArgs = {
  shiftId: Scalars['Int'];
};

export type GQLQueryShiftplanNotificationsArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLQueryShiftplansArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationId: InputMaybe<Scalars['Int']>;
  locationIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  orderDir: InputMaybe<GQLOrderDirection>;
  orderKey: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryShiftPresetsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryShiftRotationGroupsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<Scalars['Int']>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<Scalars['Int']>>;
  shiftRotationId: InputMaybe<Scalars['Int']>;
  shiftRotationIds: InputMaybe<Array<Scalars['Int']>>;
};

export type GQLQueryShiftRotationsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<Scalars['Int']>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftplanId: InputMaybe<Scalars['Int']>;
  shiftplanIds: InputMaybe<Array<Scalars['Int']>>;
};

export type GQLQueryShiftsArgs = {
  assignmentGroupId: InputMaybe<Scalars['Int']>;
  assignmentGroupIds: InputMaybe<Array<Scalars['Int']>>;
  companyId: InputMaybe<Scalars['Int']>;
  connectedGroupId: InputMaybe<Scalars['Int']>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<Scalars['Int']>>;
  endsAt: InputMaybe<Scalars['Date']>;
  extendedInfo?: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationsPositionId: InputMaybe<Scalars['Int']>;
  locationsPositionIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  onlyOpen?: InputMaybe<Scalars['Boolean']>;
  orderDir?: InputMaybe<GQLOrderDirection>;
  orderKey?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftplanId: InputMaybe<Scalars['Int']>;
  shiftplanIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  shiftPresetIds: InputMaybe<Array<Scalars['Int']>>;
  shiftRotationGroupId: InputMaybe<Scalars['Int']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  startsAt: InputMaybe<Scalars['Date']>;
  tagId: InputMaybe<Scalars['Int']>;
  tagIds: InputMaybe<Array<Scalars['Int']>>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
  withoutConflicts?: InputMaybe<Scalars['Boolean']>;
  withoutShiftPresets?: InputMaybe<Scalars['Boolean']>;
  withoutTags?: InputMaybe<Scalars['Boolean']>;
};

export type GQLQueryShiftsTagsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftId: InputMaybe<Scalars['Int']>;
  shiftIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tagId: InputMaybe<Scalars['Int']>;
  tagIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GQLQuerySpecialDatesArgs = {
  companyId: Scalars['Int'];
  endsAt: InputMaybe<Scalars['Date']>;
  locationIds: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLQueryStaffShiftsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  companyIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAfter: InputMaybe<Scalars['Date']>;
  createdBefore: InputMaybe<Scalars['Date']>;
  employmentId: InputMaybe<Scalars['Int']>;
  employmentIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  endsAt: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  onlyOpen?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shiftId: InputMaybe<Scalars['Int']>;
  shiftIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  shiftplanId: InputMaybe<Scalars['Int']>;
  shiftplanIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsAt: InputMaybe<Scalars['Date']>;
  state: InputMaybe<GQLStaffShiftState>;
  updatedAfter: InputMaybe<Scalars['Date']>;
  updatedBefore: InputMaybe<Scalars['Date']>;
};

export type GQLQueryStaffShiftsTagsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  staffShiftIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tagIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GQLQueryTagsArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  context: InputMaybe<Array<GQLTagContext>>;
  id: InputMaybe<Scalars['Int']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  locationId: InputMaybe<Scalars['Int']>;
  locationIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryTranslationsArgs = {
  languageId: Scalars['Int'];
};

export type GQLQueryUsersArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type GQLAbsenceDuration = {
  absenceDays: Scalars['Float'];
  absenceHours: Scalars['Float'];
  absenceHoursPerWorkingDay: Scalars['Float'];
};

export type GQLAbsenceEntitlementsCollection = GQLPaginatable & {
  items: Array<GQLAbsenceEntitlement>;
  pagination: GQLPaginationInfo;
};

export type GQLPaginatable = {
  pagination: GQLPaginationInfo;
};

export type GQLPaginationInfo = {
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

/**
 * In order to read max. number of absence days, approved days, remaining days per absence type, employee and timeframe.
 * Also in order to update max. number of absence days per absence type, employee and timeframe.
 */
export type GQLAbsenceEntitlement = {
  /** Id of reason for the Absence */
  absenceReasonId: Scalars['Int'];
  /** Allocated Days of absences per absence reason type */
  allocatedDays: Scalars['Float'];
  /** Approved Days of absences per absence reason type */
  approvedDays: Scalars['Float'];
  /** Id of the Employment associated with the Absence */
  employmentId: Scalars['Int'];
  /** Remaining Days of absences per absence reason type */
  remainingDays: Scalars['Float'];
  /** Absence Entitlements for Year */
  year: Maybe<Scalars['Int']>;
};

export type GQLAbsenceReasonCollection = GQLPaginatable & {
  items: Array<GQLAbsenceReason>;
  pagination: GQLPaginationInfo;
};

/**
 * In order do distinguish between different kinds of Absences every Absence has an AbsenceReason.
 * Note that vacation as an AbsenceReason will automatically count off vacation days that have been
 * documented in an employee's payroll information.
 */
export type GQLAbsenceReason = GQLIdentifiable &
  GQLTimestampable & {
    /** The deadline by which the carried over vacation days can be taken in format DD.MM */
    carryOverDaysDeadline: Maybe<Scalars['String']>;
    /** Can vacation days be carried over to the next year? */
    carryOverDaysEnabled: Scalars['Boolean'];
    /** ID of the associated Company */
    company: Maybe<GQLCompany>;
    /** Date when the Absence was created */
    createdAt: Scalars['Date'];
    /** Allowed amount of days per calendar year for AbsenceReason */
    days: Scalars['Int'];
    /** Date when the Absence was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Does the AbsenceReason have a localized name? */
    hasLocalization: Scalars['Boolean'];
    /** Unique identifier of the AbsenceReason */
    id: Scalars['Int'];
    /** Can users add attachments? */
    isAbsenceAttachmentsAllowed: Scalars['Boolean'];
    /** A human-readable representation of the AbsenceReason (e.g. vacation, sickness, overtime, other) */
    name: Scalars['String'];
    /** The abbreviation of the absence reason name */
    shortName: Maybe<Scalars['String']>;
    /** Date when the Absence was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLIdentifiable = {
  id: Scalars['Int'];
};

export type GQLTimestampable = {
  createdAt: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};

/**
 * Every User is associated with at least one Company. It contains important settings about the use
 * of the shyftplan software.
 */
export type GQLCompany = GQLIdentifiable &
  GQLTimestampable & {
    /** Show assignment groups? */
    assignmentGroupEnabled: Maybe<Scalars['Boolean']>;
    /** Should requests be automatically accepted as long as there are open slots in the Shift and there are no conflicts? */
    autoAcceptShiftRequestEnabled: Maybe<Scalars['Boolean']>;
    /** Auto accept request type (warning / enforced / disabled) */
    autoAcceptShiftRequestType: GQLAutoAcceptRequestSetting;
    /** Are shift swaps automatically accepted? */
    autoAcceptSwapRequests: Maybe<Scalars['Boolean']>;
    /** End punch time automatically? */
    autoPunchout: Maybe<Scalars['Boolean']>;
    /** Billing method used by the Company (direct, corrected or not set) */
    billingMethod: Maybe<Scalars['String']>;
    /** Is creating absences by employees allowed? */
    canEmployeesCreateAbsences: Maybe<Scalars['Boolean']>;
    /** Is automation to fill shiftplans enabled? */
    canFillShiftplans: Maybe<Scalars['Boolean']>;
    /** Is locking of shiftplans enabled? */
    canLockShiftplans: Maybe<Scalars['Boolean']>;
    /** Can managers manage the availabilities of their employees */
    canManageAvailabilities: Maybe<Scalars['Boolean']>;
    /** Can manager evaluate their own shifts? */
    canManagersEvaluateOwnShifts: Maybe<Scalars['Boolean']>;
    /** Are bring shifts enabled? */
    canUseBringShifts: Maybe<Scalars['Boolean']>;
    /** Are qualifications enabled? */
    canUseQualifications: Maybe<Scalars['Boolean']>;
    /** Are shift presets enabled? */
    canUseShiftPresets: Maybe<Scalars['Boolean']>;
    /** Chat-related settings */
    chatSettings: Maybe<GQLChatSettings>;
    /** Date when the Company was created */
    createdAt: Scalars['Date'];
    /** Currency used by the Company */
    currency: Maybe<Scalars['String']>;
    /** The Default Languages set by the Company */
    defaultLanguage: GQLLanguage;
    /** Default language used by the Company */
    defaultLanguageId: Maybe<Scalars['Int']>;
    /** Date when the Company was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Users can submit a different shift payment */
    editShiftPayments: Maybe<Scalars['Boolean']>;
    /** Can an employee create a Shift by starting the punch time? */
    employeeShift: Maybe<Scalars['Boolean']>;
    /** The Employments associated with the Company */
    employments: Maybe<Array<GQLEmployment>>;
    /** Unique identifier of the Company */
    id: Scalars['Int'];
    /** Name of the Company image file */
    imageName: Maybe<Scalars['String']>;
    imageUid: Maybe<Scalars['String']>;
    /** Email used for sending invoices to the Company */
    invoiceEmail: Maybe<Scalars['String']>;
    /** Is attaching files to absences allowed? */
    isAbsenceAttachmentsAllowed: Maybe<Scalars['Boolean']>;
    /** Is editing absences enabled */
    isAbsenceEditEnabled: Maybe<Scalars['Boolean']>;
    /** Enable billing reminder */
    isBillingRemindersEnabled: Maybe<Scalars['Boolean']>;
    /** Is bulk changing enabled? */
    isBulkChangesEnabled: Maybe<Scalars['Boolean']>;
    /** Enable employees to post messages to the dashboard */
    isDashboardMessagesAllowed: Maybe<Scalars['Boolean']>;
    /** Is billing method deactivated? */
    isDeactivated: Maybe<Scalars['Boolean']>;
    /** Allow employees to see their own hour account */
    isEmployeeAllowedToSeeOwnHourAccount: Maybe<Scalars['Boolean']>;
    /** Show monetary value on evaluation */
    isMoneyShownOnEvaluation: Maybe<Scalars['Boolean']>;
    /** Is overassignment allowed? */
    isOverassignmentAllowed: Maybe<Scalars['Boolean']>;
    /** Is paying via PayPal enabled? */
    isPaypalEnabled: Maybe<Scalars['Boolean']>;
    /** Are payslips enabled? */
    isPayslipsEnabled: Maybe<Scalars['Boolean']>;
    /** Are tags enabled? */
    isTagsAllowed: Scalars['Boolean'];
    /** Has the VAT number been validated? */
    isVatIdValidated: Maybe<Scalars['Boolean']>;
    /** Is the withdrawal of absences by employees allowed? */
    isWithdrawAbsencesAllowed: Scalars['Boolean'];
    /** The Locations associated with the Company */
    locations: Maybe<Array<GQLLocation>>;
    /** Minimum-break settings */
    minimumBreakSettings: GQLMinimumBreakSettings;
    /** Name of the Company */
    name: Scalars['String'];
    /** The Newsfeeds associated with the Company */
    newsfeeds: Maybe<Array<GQLNewsfeed>>;
    /** At which step of the company onboarding tutorial is this Company? */
    onboardingStep: Maybe<Scalars['Int']>;
    /** Information about the picture of the Company */
    pictureData: Maybe<GQLPictureData>;
    /** The Positions associated with the Company */
    positions: Maybe<Array<GQLPosition>>;
    /** Estimated number of employees stated during registration */
    propableEmployees: Maybe<Scalars['Int']>;
    /** Punch out automatically after how many hours? */
    punchoutHour: Maybe<Scalars['Int']>;
    /** Round for employee’s benefit? */
    roundFair: Maybe<Scalars['Boolean']>;
    /** Round punch time to which granularity (0, 5, 15, 30)? */
    roundTo: Maybe<Scalars['Float']>;
    /** If punching in before the beginning of the Shift is stored as a punch-in at the Shift start */
    roundToShift: Maybe<Scalars['Boolean']>;
    /** ID of the Company's Salesforce account */
    salesforceId: Maybe<Scalars['Int']>;
    /** Are shift rotations enabled? */
    shiftRotationEnabled: Maybe<Scalars['Boolean']>;
    /** Is shift swap enabled? */
    shiftSwapEnabled: Maybe<Scalars['Boolean']>;
    /** Show punchtime token on user modal? */
    showUserToken: Maybe<Scalars['Boolean']>;
    /** Has the Employee off from work if only one of their Locations has a special day */
    specialDateCountedOff: Maybe<Scalars['Boolean']>;
    /** Date when the survey was completed */
    surveyDoneAt: Maybe<Scalars['Date']>;
    swapRequestMinHour: Maybe<Scalars['Int']>;
    /** Preferred time zone */
    timeZone: Maybe<Scalars['String']>;
    /** Date when the Company was last updated */
    updatedAt: Scalars['Date'];
    /** VAT number of this Company */
    vatId: Maybe<Scalars['String']>;
    /** Allow employees to see other employees in their Positions */
    viewPersonal: Maybe<Scalars['Boolean']>;
    /** The shortest duration until the absence allowed to withdraw an absence */
    withdrawAbsencesDuration: Scalars['Int'];
  };

export enum GQLAutoAcceptRequestSetting {
  DISABLED = 'disabled',
  ENFORCED = 'enforced',
  WARNING = 'warning',
}

export type GQLChatSettings = {
  employmentChats: Maybe<GQLEmploymentChats>;
  isChatAllowed: Maybe<Scalars['Boolean']>;
  locationChatsEnabled: Maybe<Scalars['Boolean']>;
  locationsPositionChatsEnabled: Maybe<Scalars['Boolean']>;
};

export type GQLEmploymentChats = {
  employeeCanChatTo: Maybe<GQLEmployeeCanChatTo>;
  enabled: Maybe<Scalars['Boolean']>;
  stakeholderCanChatTo: Maybe<GQLStakeholderCanChatTo>;
};

export type GQLEmployeeCanChatTo = {
  anyone: Maybe<Scalars['Boolean']>;
  sameLocation: Maybe<Scalars['Boolean']>;
  sameLocationsPosition: Maybe<Scalars['Boolean']>;
};

export type GQLStakeholderCanChatTo = {
  anyone: Maybe<Scalars['Boolean']>;
  employeesInLocation: Maybe<Scalars['Boolean']>;
  employeesInLocationsPosition: Maybe<Scalars['Boolean']>;
  stakeholdersInLocation: Maybe<Scalars['Boolean']>;
  stakeholdersInLocationsPosition: Maybe<Scalars['Boolean']>;
};

/**
 * Every company can set their default language, as well as users individually.
 * Optionally it is possible to add custom languages and create translations.
 */
export type GQLLanguage = GQLIdentifiable & {
  companyId: Maybe<Scalars['Int']>;
  /** Fallback locale when translation for the chosen language is not set */
  fallback: Maybe<Scalars['String']>;
  /** Unique identifier of the Language */
  id: Scalars['Int'];
  /** Name of the related locale */
  locale: Maybe<Scalars['String']>;
  /** Localized name of the language */
  name: Scalars['String'];
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmployment = GQLIdentifiable &
  GQLTimestampable & {
    /** The Absences associated with the Employment */
    absences: Maybe<Array<Maybe<GQLAbsence>>>;
    /** ID of the associated payroll type */
    billingTypeId: Maybe<Scalars['Int']>;
    /** The Company associated with the Employment */
    company: Maybe<GQLCompany>;
    /** ID of the Company associated with the Employment */
    companyId: Maybe<Scalars['Int']>;
    /** Date when the Employment was created */
    createdAt: Scalars['Date'];
    /** Date when the Employment was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Email address of the employee in the Employment */
    email: Maybe<Scalars['String']>;
    /** The EmploymentsRotationGroups associated with the Employment */
    employmentsShiftRotationGroups: Array<GQLEmploymentsRotationGroup>;
    /** Information related to the working hours of the employee in the Employment */
    employmentWorkHour: Maybe<GQLEmploymentWorkHour>;
    /** Leaving date of the employee in the Employment */
    exitDate: Maybe<Scalars['Date']>;
    /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
    exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
    /** First name of the employee in the Employment */
    firstName: Scalars['String'];
    /** Is the employee's hour account enabled in the Employment? */
    hourEnabled: Maybe<Scalars['Boolean']>;
    /** Unique identifier of the Employment */
    id: Scalars['Int'];
    /** State of the invitation to use shyftplan sent to the employee in the Employment */
    invitationState: GQLEmploymentInvitationState;
    /** Is the employee in the role of employee in the Employment? */
    isEmployee: Maybe<Scalars['Boolean']>;
    /** Is the employee paid monthly in the Employment? */
    isPaidMonthly: Maybe<Scalars['Boolean']>;
    /** Is the employee a stakeholder in the Employment? */
    isStakeholder: Maybe<Scalars['Boolean']>;
    /** The Language associated with the Employment */
    language: Maybe<GQLLanguage>;
    /** Last name of the employee in the Employment */
    lastName: Scalars['String'];
    /** IDs of LocationsPositions associated with the Employment */
    locationsPositionIds: Maybe<Array<Scalars['Int']>>;
    /** The LocationsPositions associated with the Employment */
    locationsPositions: Maybe<Array<Maybe<GQLLocationsPosition>>>;
    /** Maximum earnings per month */
    maximumMoney: Maybe<Scalars['Float']>;
    /** Maximum earnings per month enabled? */
    maximumMoneyEnabled: Maybe<Scalars['Boolean']>;
    /** Maximum number of vacation days of the employee in the Employment */
    maxVacationDays: Maybe<Scalars['Float']>;
    /** Monthly pay of the employee in the Employment */
    monthlyPay: Maybe<Scalars['Float']>;
    /** Comments */
    note: Maybe<Scalars['String']>;
    /** Information related to the Paygrades of the employee in the Employment */
    paygrades: Maybe<GQLEmploymentPaygradeCollection>;
    /** Phone number of the employee in the Employment */
    phoneNumber: Maybe<Scalars['String']>;
    /** Information related to the picture of the employee in the Employment */
    pictureData: Maybe<GQLPictureData>;
    /** Information related to the current punch timing of the employee in the Employment */
    punchTimingLiveInfo: Maybe<GQLPunchTimingLiveInfo>;
    /** IDs of Qualifications associated with the Employment */
    qualificationIds: Maybe<Array<Scalars['Int']>>;
    /** Qualifications associated with the Employment */
    qualifications: Array<GQLQualification>;
    /** Ids of ShiftRotationGroups associated with the Employment */
    shiftRotationGroupIds: Array<Scalars['Int']>;
    /** Staff number of the employee in the Employment */
    staffNumber: Maybe<Scalars['String']>;
    /** Date when the Employment was last updated */
    updatedAt: Scalars['Date'];
    /** The User associated with the Employment */
    user: Maybe<GQLUser>;
    /** User id associated with Employment */
    userId: Scalars['Int'];
    /** Punchtime token of the employee in the Employment */
    userToken: Maybe<Scalars['Int']>;
    /** Number of vacation hours per working day */
    vacationHours: Maybe<Scalars['Float']>;
  };

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentEmploymentsShiftRotationGroupsArgs = {
  shiftRotationId: InputMaybe<Scalars['Int']>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentLocationsPositionIdsArgs = {
  locationId: InputMaybe<Scalars['Int']>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentLocationsPositionsArgs = {
  locationId: InputMaybe<Scalars['Int']>;
};

/**
 * Every User in a Company has an Employment. It contains the name of the employee and whether they
 * are employee or stakeholder in this company. Optionally work hours, pay information and contact
 * information can be provided.
 */
export type GQLEmploymentShiftRotationGroupIdsArgs = {
  rotationId: InputMaybe<Scalars['Int']>;
};

/**
 * Users are able to inform their employer about their absences or stakeholders create them for
 * them. It contains the absence reason, time of absence and whether it is paid or not. Optionally
 * a note for further explanation can be added. The employer can then approve or decline the absence.
 */
export type GQLAbsence = GQLIdentifiable &
  GQLTimeframeable &
  GQLTimestampable & {
    /** Reason for the Absence */
    absenceReason: GQLAbsenceReason;
    /** Can the Absence be managed by the stakeholder? */
    canManage: Scalars['Boolean'];
    /** Date when the Absence was created */
    createdAt: Scalars['Date'];
    /** Duration of the Absence in working days */
    days: Scalars['Float'];
    /** Date when the Absence was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** The Employment associated with the Absence */
    employment: GQLEmployment;
    /** Date when the Absence will end or ended */
    endsAt: Scalars['Date'];
    /** Location of attachment */
    file: Maybe<Scalars['String']>;
    /** Name of the attachment */
    fileName: Maybe<Scalars['String']>;
    /** Unique identification key for attachment */
    fileUid: Maybe<Scalars['String']>;
    /** Date when the attachment was uploaded */
    fileUploadedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the Absence */
    id: Scalars['Int'];
    /** An Absence may vary between full day(s) or set by time of day */
    isFullDay: Scalars['Boolean'];
    /** Note added by the employee or employer */
    notes: Maybe<Scalars['String']>;
    /** Is or was this a paid or unpaid Absence? */
    paid: Scalars['Boolean'];
    /** Optional message for a refused absence */
    refuseMessage: Maybe<Scalars['String']>;
    /** Date when the Absence will start or started */
    startsAt: Scalars['Date'];
    /** State of the Absence */
    state: GQLAbsenceState;
    /** The last date the state of the absence was updated */
    stateUpdatedAt: Maybe<Scalars['Date']>;
    /** Date when the Absence was last updated */
    updatedAt: Scalars['Date'];
    /** Amount of vacations minutes for the Absence */
    vacationMinutes: Scalars['Int'];
  };

export type GQLTimeframeable = {
  endsAt: Scalars['Date'];
  startsAt: Scalars['Date'];
};

/** An Absence can have a specific state */
export enum GQLAbsenceState {
  /** Absence has been granted */
  ACCEPTED = 'accepted',
  /** Absence has been created but no decision has been made yet */
  NEW = 'new',
  /** Absence has been rejected */
  REFUSED = 'refused',
  /** Absence has been withdrawn by the employee */
  WITHDRAWN = 'withdrawn',
}

/**
 * EmploymentsRotationGroup is the join table of Employment and ShiftRotationGroup.
 * This defines what rotation groups employee has at specific period of time.
 */
export type GQLEmploymentsRotationGroup = GQLIdentifiable & {
  /** Date when the EmploymentsRotationGroup was created */
  createdAt: Scalars['Date'];
  /** The Employment associated with the EmploymentsRotationGroup */
  employment: GQLEmployment;
  /** ID of Employment associated with the EmploymentsRotationGroup */
  employmentId: Scalars['Int'];
  /** Date when the employment's assignment to the ShiftRotationGroup will end or ended */
  endsAt: Maybe<Scalars['Date']>;
  /** Unique identifier of the EmploymentsRotationGroup */
  id: Scalars['Int'];
  /** Connected ShiftRotationGroup */
  shiftRotationGroup: GQLShiftRotationGroup;
  /** ID of ShiftRotationGroup associated with the EmploymentsRotationGroup */
  shiftRotationGroupId: Scalars['Int'];
  /** Date when the employment's assignment to the ShiftRotationGroup will start or started */
  startsAt: Maybe<Scalars['Date']>;
  /** Date when the EmploymentsRotationGroup was last updated */
  updatedAt: Scalars['Date'];
};

export type GQLShiftRotationGroup = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the ShiftRotationGroup was created */
    createdAt: Scalars['Date'];
    /** Date when the ShiftRotationGroup was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Employment IDs associated with ShiftRotationGroup */
    employmentIds: Array<Scalars['Int']>;
    /** EmploymentsShiftRotationGroups associated with ShiftRotationGroup */
    employmentsShiftRotationGroups: Array<GQLEmploymentsRotationGroup>;
    /** Unique identifier of the ShiftRotationGroup */
    id: Scalars['Int'];
    /** Name of the ShiftRotationGroup */
    name: Scalars['String'];
    /** An array of ShiftPreset IDs associated with the ShiftRotationGroup */
    shiftPresetIds: Array<Scalars['Int']>;
    /** An array of ShiftPreset associated with the ShiftRotationGroup */
    shiftPresets: Array<GQLShiftPreset>;
    /** ShiftRotation associated with ShiftRotationGroup */
    shiftRotation: GQLShiftRotation;
    /** Used for ordering ShiftRotationGroups of the same ShiftRotation */
    sortPosition: Maybe<Scalars['Int']>;
    /** Date when the ShiftRotationGroup was last updated */
    updatedAt: Scalars['Date'];
  };

/** A ShiftPreset */
export type GQLShiftPreset = GQLIdentifiable &
  GQLTimestampable & {
    /** Break time for a Shift with this ShiftPreset */
    breakTime: Scalars['Int'];
    /** Color of ShiftPreset */
    color: Scalars['String'];
    /** Company associated with ShiftPreset */
    company: GQLCompany;
    /** Date when the ShiftPreset was created */
    createdAt: Scalars['Date'];
    /** Date when the ShiftPreset was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Date with the end time for a Shift with this ShiftPreset */
    endsAt: Scalars['Date'];
    /** End time string for a Shift with this ShiftPreset */
    endsAtTime: Scalars['String'];
    /** Unique identifier of the ShiftPreset */
    id: Scalars['Int'];
    /** Name of ShiftPreset */
    name: Scalars['String'];
    /** Ids of the ShiftEvaluationTags for a Shift with this ShiftPreset */
    shiftEvaluationTagIds: Array<Scalars['Int']>;
    /** ShiftEvaluationTags for a Shift with this ShiftPreset */
    shiftEvaluationTags: Array<GQLTag>;
    /** Short name of shiftPreset */
    shortName: Maybe<Scalars['String']>;
    /** Date with the start time for a Shift with this ShiftPreset */
    startsAt: Scalars['Date'];
    /** Start time string for a Shift with this ShiftPreset */
    startsAtTime: Scalars['String'];
    /** Ids of the Tags for a Shift with this ShiftPreset */
    tagIds: Array<Scalars['Int']>;
    /** Tags for a Shift with this ShiftPreset */
    tags: Array<GQLTag>;
    /** Date when the ShiftPreset was last updated */
    updatedAt: Scalars['Date'];
  };

/** A Tag */
export type GQLTag = GQLIdentifiable & {
  /** Color of the Tag (hex) */
  color: Scalars['String'];
  /** Kind of the Tag */
  context: GQLTagContext;
  /** Unique identifier of the Tag */
  id: Scalars['Int'];
  /** Location associated with the Tag (null for staff_shift_tag) */
  location: Maybe<GQLLocation>;
  /** Name of the Tag */
  name: Scalars['String'];
  /** Text color of the Tag(hex) */
  textColor: Maybe<Scalars['String']>;
};

export enum GQLTagContext {
  SHIFT_TAG = 'shift_tag',
  STAFF_SHIFT_TAG = 'staff_shift_tag',
}

/**
 * Every Company and Position has at least one Location. The join table LocationsPosition shows
 * which Position is associated with which Location.
 */
export type GQLLocation = GQLIdentifiable &
  GQLTimestampable & {
    actionType: Maybe<Scalars['String']>;
    /** Company associated with the Location */
    company: Maybe<GQLCompany>;
    /** ID of the Company associated with the Location */
    companyId: Maybe<Scalars['Int']>;
    /** Date when the Location was created */
    createdAt: Scalars['Date'];
    /** Date when the Location was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the Location */
    id: Scalars['Int'];
    /** Name of the Location */
    name: Scalars['String'];
    /** The Positions associated with the Location */
    positions: Maybe<Array<Maybe<GQLPosition>>>;
    /** Sorting position of the Location */
    sort: Maybe<Scalars['Int']>;
    /** Date when the Location was last updated */
    updatedAt: Scalars['Date'];
  };

/**
 * Every Company can have multiple Positions. Positions can be associated with several
 * Locations of a Company.
 */
export type GQLPosition = GQLIdentifiable &
  GQLTimestampable & {
    actionType: Maybe<Scalars['String']>;
    /** Background color for the Position */
    color: Scalars['String'];
    /** Company associated with the Position */
    company: Maybe<GQLCompany>;
    /** ID of the Company associated with the Position */
    companyId: Maybe<Scalars['Int']>;
    /** Date when the Position was created */
    createdAt: Scalars['Date'];
    /** Date when the Position was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Description of the Position */
    description: Maybe<Scalars['String']>;
    /** Unique identifier of the Position */
    id: Scalars['Int'];
    /** Name of the Position */
    name: Scalars['String'];
    /** Position comment (shown on Shift) */
    note: Maybe<Scalars['String']>;
    /** Sorting position of the Position */
    sort: Maybe<Scalars['Int']>;
    /** Text color for the Position */
    textColor: Scalars['String'];
    /** Date when the Position was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLShiftRotation = GQLIdentifiable &
  GQLTimestampable & {
    /** Anchor date of the ShiftRotation */
    anchorDate: Scalars['Date'];
    /** Company associated with ShiftRotation */
    company: GQLCompany;
    /** ID of the associated Company */
    companyId: Scalars['Int'];
    /** Date when the ShiftRotation was created */
    createdAt: Scalars['Date'];
    /** Date when the ShiftRotation was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** When does the the ShiftRotation itself end */
    endsAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the ShiftRotation */
    id: Scalars['Int'];
    /** Name of the ShiftRotation */
    name: Scalars['String'];
    /** After how many days does the ShiftRotation repeat? */
    rotationInterval: Scalars['Int'];
    shiftRotationGroups: Array<GQLShiftRotationGroup>;
    /** When does the the ShiftRotation itself start */
    startsAt: Maybe<Scalars['Date']>;
    /** Date when the ShiftRotation was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLEmploymentWorkHour = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the EmploymentWorkHour was created */
    createdAt: Scalars['Date'];
    /** Date when the EmploymentWorkHour was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** The Employment associated with the EmploymentWorkHour */
    employment: Maybe<GQLEmployment>;
    friMinutes: Maybe<Scalars['Int']>;
    /** Type of hour account */
    hourType: Maybe<GQLHourType>;
    /** Unique identifier of the Employment */
    id: Scalars['Int'];
    isMonthly: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Fridays in the Employment? */
    isWorkingOnFri: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Mondays in the Employment? */
    isWorkingOnMon: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Saturdays in the Employment? */
    isWorkingOnSat: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Sundays in the Employment? */
    isWorkingOnSun: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Thursdays in the Employment? */
    isWorkingOnThu: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Tuesdays in the Employment? */
    isWorkingOnTue: Maybe<Scalars['Boolean']>;
    /** Is the employee working on Wednesdays in the Employment? */
    isWorkingOnWed: Maybe<Scalars['Boolean']>;
    monMinutes: Maybe<Scalars['Int']>;
    monthlyMinutes: Maybe<Scalars['Int']>;
    satMinutes: Maybe<Scalars['Int']>;
    sunMinutes: Maybe<Scalars['Int']>;
    thuMinutes: Maybe<Scalars['Int']>;
    tueMinutes: Maybe<Scalars['Int']>;
    /** Date when the EmploymentWorkHour was last updated */
    updatedAt: Scalars['Date'];
    vacationMinutes: Maybe<Scalars['Int']>;
    wedMinutes: Maybe<Scalars['Int']>;
    weeklyMinutes: Maybe<Scalars['Int']>;
  };

export enum GQLHourType {
  MONTHLY = 'monthly',
  NONE = 'none',
  WEEKLY = 'weekly',
}

export enum GQLEmploymentInvitationState {
  ACCEPTED = 'accepted',
  NEW = 'new',
  REFUSED = 'refused',
}

/**
 * LocationsPosition is the join table of Location and Position.
 * It connects a certain position to a certain location.
 */
export type GQLLocationsPosition = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the LocationsPosition was created */
    createdAt: Scalars['Date'];
    /** Date when the LocationsPosition was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the LocationsPosition */
    id: Scalars['Int'];
    /** The Location associated with the LocationsPosition */
    location: Maybe<GQLLocation>;
    /** The Position associated with the LocationsPosition */
    position: Maybe<GQLPosition>;
    /** Sorting position of the LocationsPosition */
    sort: Maybe<Scalars['Int']>;
    /** Date when the LocationsPosition was last updated */
    updatedAt: Scalars['Date'];
  };

/**
 * A collection of Paygrades for a given Employment grouped into Paygrades in general,
 * monthly Paygrades, and Paygrades for existing EmploymentsPositions.
 */
export type GQLEmploymentPaygradeCollection = {
  /** Paygrades for existing EmploymentsPositions */
  employmentsPositionsPaygrades: Maybe<Array<Maybe<GQLEmploymentPaygrade>>>;
  /** Only monthly Paygrades */
  monthlyPaygrades: Maybe<Array<Maybe<GQLEmploymentPaygrade>>>;
  /** All Paygrades */
  paygrades: Maybe<Array<Maybe<GQLEmploymentPaygrade>>>;
};

export type GQLEmploymentPaygrade = GQLIdentifiable & {
  /** Unique identifier of the Paygrade */
  id: Scalars['Int'];
  paygradeForId: Scalars['Int'];
  /** Type of Paygrade */
  paygradeForType: GQLPaygradeLevel;
  /** Child Paygrades */
  paygrades: Maybe<Array<GQLEmploymentPaygrade>>;
  /** PaygradeType associated with the Paygrade */
  paygradeType: GQLPaygradeType;
  /** Value of PaygradeType */
  value: Scalars['Float'];
};

export enum GQLPaygradeLevel {
  COMPANY = 'Company',
  EMPLOYMENT = 'Employment',
  MONTHLYPAYGRADE = 'MonthlyPaygrade',
  PAYGRADE = 'Paygrade',
  POSITION = 'Position',
  SHIFT = 'Shift',
}

/**
 * Paygrade types represent different salary and compensation models a Company can have for an
 * employee's payment. They are specified on Company level, Position level or employee level.
 * Paygrades set on Position level will override paygrades on Company level, paygrades set on
 * employee level will override paygrades on Company and Position level. Only same paygrades
 * and same bonus paygrades will be overwritten, therefore all paygrades set on Company level that
 * don't exist on employee level are not being overwritten and will still be applied to the
 * employee's payments.
 * Name and paygrade type can only be associated with one unique paygrade, therefore also keep in mind
 * that it is not possible to set more than one paygrade for an employee with same paygrade type and same position.
 */
export type GQLPaygradeType = GQLIdentifiable &
  GQLTimestampable & {
    /** The Company associated with the PaygradeType */
    company: GQLCompany;
    /** Date when the PaygradeType was created */
    createdAt: Scalars['Date'];
    /** Date when the PaygradeType was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Date when the PaygradeType ended */
    endsAt: Maybe<Scalars['Date']>;
    /** Can the PaygradeType be applied on Fridays? */
    friday: Maybe<Scalars['Boolean']>;
    /** Unique identifier of the PaygradeType */
    id: Scalars['Int'];
    /** Information about Paygrade validation in minutes */
    minMinutes: Scalars['Int'];
    /** Can the PaygradeType be applied on Mondays? */
    monday: Maybe<Scalars['Boolean']>;
    /** Name of the PaygradeType */
    name: Scalars['String'];
    paychexLa: Maybe<Scalars['String']>;
    /** Type of payment (fixed/bonus fixed/bonus percentage/hourly) */
    payType: Scalars['String'];
    /** Can the PaygradeType be applied on Saturdays? */
    saturday: Maybe<Scalars['Boolean']>;
    selection: Maybe<Scalars['String']>;
    /** Can the PaygradeType be applied on special days (public holidays etc.)? */
    specialDay: Maybe<Scalars['Boolean']>;
    /** Date when the PaygradeType started */
    startsAt: Maybe<Scalars['Date']>;
    /** Can the PaygradeType be applied on Sundays? */
    sunday: Maybe<Scalars['Boolean']>;
    /** Can the PaygradeType be applied on Thursdays? */
    thursday: Maybe<Scalars['Boolean']>;
    /** Can the PaygradeType be applied on Tuesdays? */
    tuesday: Maybe<Scalars['Boolean']>;
    /** Date when the PaygradeType was last updated */
    updatedAt: Scalars['Date'];
    /** Can the PaygradeType be applied on Wednesdays? */
    wednesday: Maybe<Scalars['Boolean']>;
  };

export type GQLPictureData = {
  backgroundColor: Maybe<Scalars['String']>;
  pictureLarge: Maybe<Scalars['String']>;
  pictureMedium: Maybe<Scalars['String']>;
  pictureSmall: Maybe<Scalars['String']>;
};

export type GQLPunchTimingLiveInfo = {
  punchBreak: Maybe<GQLPunchBreak>;
  punchTiming: Maybe<GQLPunchTiming>;
  staffShift: Maybe<GQLStaffShift>;
  state: Maybe<Scalars['String']>;
};

export type GQLPunchBreak = GQLIdentifiable & {
  /** Date when the PunchBreak was created */
  createdAt: Scalars['Date'];
  /** Date when the PunchBreak was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Start of the PunchBreak */
  endTime: Maybe<Scalars['Date']>;
  /** Unique identifier of the PunchBreak */
  id: Scalars['Int'];
  /** ID of the associated PunchTiming */
  punchTimingId: Maybe<Scalars['Int']>;
  /** Start of the PunchBreak */
  startTime: Maybe<Scalars['Date']>;
  /** Date when the PunchBreak was last updated */
  updatedAt: Maybe<Scalars['Date']>;
};

/** PunchTiming defines punched start and end time of a Shift, as well as punched breaks. */
export type GQLPunchTiming = GQLIdentifiable &
  GQLTimestampable & {
    /** Additional break time in minutes */
    additionalBreakTime: Maybe<Scalars['Float']>;
    /** Break time in minutes */
    breakTime: Maybe<Scalars['Float']>;
    /** Date when the PunchTiming was created */
    createdAt: Scalars['Date'];
    /** Date when the PunchTiming was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** End of the PunchTiming */
    endTime: Maybe<Scalars['Date']>;
    /** Unique identifier of the PunchTiming */
    id: Scalars['Int'];
    /** Start of the overtime */
    overtimeStart: Maybe<Scalars['Date']>;
    /** StaffShift associated with the PunchTiming */
    staffShift: Maybe<GQLStaffShift>;
    /** Start of the PunchTiming */
    startTime: Maybe<Scalars['Date']>;
    /** Date when the PunchTiming was last updated */
    updatedAt: Scalars['Date'];
  };

/** A StaffShift defines the assignment of an Employment to a Shift. */
export type GQLStaffShift = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the StaffShift was accepted? */
    acceptedAt: Maybe<Scalars['Date']>;
    /** Date when the StaffShift was created */
    createdAt: Scalars['Date'];
    /** Date when the StaffShift was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Employment associated with the StaffShift */
    employment: Maybe<GQLEmployment>;
    /** Unique identifier of the StaffShift */
    id: Scalars['Int'];
    /** Shift associated with the StaffShift */
    shift: Maybe<GQLShift>;
    shiftAssignmentGroupId: Maybe<Scalars['Int']>;
    /** StaffShiftsTags assigned to the StaffShift */
    staffShiftsTags: Array<GQLStaffShiftsTag>;
    /** State of the StaffShift */
    state: GQLStaffShiftState;
    /** Accumulated working time (without breaks) */
    totalMinutes: Maybe<Scalars['Int']>;
    /** Accumulated Payments */
    totalPayments: Maybe<Scalars['Float']>;
    /** Date when the StaffShift was last updated */
    updatedAt: Scalars['Date'];
  };

/**
 * A Shift defines a working slot for a defined amount of Employments at a Position on a Shiftplan.
 * Please note: The assigned position is set by the LocationsPosition where the Location needs to
 * match the the Shiftplan it is linked to.
 */
export type GQLShift = GQLIdentifiable &
  GQLTimeframeable &
  GQLTimestampable & {
    /** The ids of the AssignmentGroups the shift is associated with */
    assignmentGroupIds: Array<Scalars['Int']>;
    /** AssignmentGroups associated with the Shift */
    assignmentGroups: Array<GQLAssignmentGroup>;
    /** The setting for whether requests will be automatically accepted and how conflicts will be handled */
    autoAccept: GQLAutoAcceptRequestSetting;
    /** Duration of breaks in minutes */
    breakTime: Scalars['Float'];
    /** Can employees create Evaluations? */
    canEvaluate: Scalars['Boolean'];
    /** Can the Shift be managed by the user? */
    canManage: Maybe<Scalars['Boolean']>;
    connectedGroupId: Maybe<Scalars['Int']>;
    /** Date when the Shift was created */
    createdAt: Scalars['Date'];
    /** Date when the Shift was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Date when the Shift ends */
    endsAt: Scalars['Date'];
    /** Evaluations associated with the Shift */
    evaluations: Maybe<Array<Maybe<GQLEvaluation>>>;
    /** Unique identifier of the Shift */
    id: Scalars['Int'];
    /** LocationsPosition associated with the Shift */
    locationsPosition: GQLLocationsPosition;
    /** Manager note for the Shift (can only be seen by stakeholders) */
    managerNote: Maybe<Scalars['String']>;
    /** Note for the Shift */
    note: Maybe<Scalars['String']>;
    paygrades: Maybe<Array<GQLShiftPaygrade>>;
    /** Requests associated with the Shift */
    requests: Array<GQLRequest>;
    /** AssignmentGroups associated with the Shift */
    shiftAssignmentGroups: Array<GQLShiftAssignmentGroup>;
    /** The additional breaks the shift is associated with */
    shiftBreaks: Array<GQLShiftBreak>;
    /** EvaluationTags associated with the Shift */
    shiftEvaluationTags: Array<GQLTag>;
    /** Shiftplan associated with the Shift */
    shiftplan: GQLShiftplan;
    /** Id of the Shiftplan associated with the Shift */
    shiftplanId: Scalars['Int'];
    /** ShiftPreset associated with the Shift */
    shiftPreset: Maybe<GQLShiftPreset>;
    /** The ids and count of ShiftQualification the shift is associated with */
    shiftQualifications: Array<GQLShiftQualification>;
    /** The ids of the ShiftRotationGroups the shift is associated with */
    shiftRotationGroupIds: Array<Scalars['Int']>;
    /** ShiftRotationGroups the shift is associated with */
    shiftRotationGroups: Array<GQLShiftRotationGroup>;
    /** StaffShifts associated with the Shift */
    staffShifts: Maybe<Array<GQLStaffShift>>;
    /** Number of StaffShifts associated with this Shift. Provided only when extendedInfo is true */
    staffShiftsCount: Maybe<Scalars['Int']>;
    /** Number of pending Evaluations for this shift. Provided only when extendedInfo is true */
    staffShiftsNeedsEvaluationCount: Maybe<Scalars['Int']>;
    /** Date when the Shift starts */
    startsAt: Scalars['Date'];
    /** Tags associated with the Shift */
    tags: Array<GQLTag>;
    /** Should the Shift be accounted? */
    untimed: Maybe<Scalars['Boolean']>;
    /** Duration of unpaid breaks in minutes */
    untimedBreakTime: Scalars['Float'];
    /** Date when the Shift was last updated */
    updatedAt: Scalars['Date'];
    /** Amount of employees assignable to the Shift */
    workers: Scalars['Int'];
  };

/**
 * A Shift defines a working slot for a defined amount of Employments at a Position on a Shiftplan.
 * Please note: The assigned position is set by the LocationsPosition where the Location needs to
 * match the the Shiftplan it is linked to.
 */
export type GQLShiftAssignmentGroupsArgs = {
  companyId: Scalars['Int'];
};

/**
 * A Shift defines a working slot for a defined amount of Employments at a Position on a Shiftplan.
 * Please note: The assigned position is set by the LocationsPosition where the Location needs to
 * match the the Shiftplan it is linked to.
 */
export type GQLShiftShiftEvaluationTagsArgs = {
  companyId: Scalars['Int'];
};

/** A AssignmentGroup */
export type GQLAssignmentGroup = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the AssignmentGroup was created */
    createdAt: Scalars['Date'];
    /** Date when the ShiftAssignmentGroup was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Number of employees who are assigned to all shifts in the AssignmentGroup */
    employmentCount: Maybe<Scalars['Int']>;
    /** Unique identifier of the AssignmentGroup */
    id: Scalars['Int'];
    /** Additional metadata related to the AssignmentGroup */
    metadata: Maybe<Scalars['Json']>;
    /** Name of the AssignmentGroup */
    name: Scalars['String'];
    /** Number of shifts in the AssignmentGroup */
    shiftCount: Maybe<Scalars['Int']>;
    /** Shiftplan associated with the AssignmentGroup */
    shiftplan: GQLShiftplan;
    /** Date when the AssignmentGroup was last updated */
    updatedAt: Scalars['Date'];
  };

/**
 * A Shiftplan contains a collection of Shifts within a defined period of time and is linked
 * to a Location.
 */
export type GQLShiftplan = GQLIdentifiable &
  GQLTimeframeable &
  GQLTimestampable & {
    copying: Scalars['Boolean'];
    /** Date when the Shiftplan was created */
    createdAt: Scalars['Date'];
    /** Date when the Shiftplan was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    deleting: Scalars['Boolean'];
    /** Date when the Shiftplan will end or ended (inclusive) */
    endsAt: Scalars['Date'];
    /** Unique identifier of the Shiftplan */
    id: Scalars['Int'];
    /** Location associated with the Shiftplan */
    location: Maybe<GQLLocation>;
    /** ID of the Location associated with the Shiftplan */
    locationId: Maybe<Scalars['Int']>;
    /** Is Shiftplan locked? */
    locked: Maybe<Scalars['Boolean']>;
    /** Name of the Shiftplan */
    name: Scalars['String'];
    /** Date when the Shiftplan will start or started (inclusive) */
    startsAt: Scalars['Date'];
    state: GQLShiftplanState;
    /** Date when the Shiftplan was last updated */
    updatedAt: Scalars['Date'];
  };

export enum GQLShiftplanState {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

/**
 * When a Shift has been performed by an employee an Evaluation is automatically provided. This can be
 * manually adjusted by stakeholders or a proposal of changes can be made by the employee (if enabled).
 * Please note: payments can be included in the evaluation response. This decreases the response time
 * and requires owner rights for the API user.
 */
export type GQLEvaluation = GQLTimestampable & {
  /** Evaluation note by stakeholder */
  adminEvaluationNote: Maybe<Scalars['String']>;
  /** Evaluated total break duration (untimed plus additional) in minutes */
  break: Scalars['Float'];
  /** The EvaluationBreaks associated with the Evaluaton */
  breaks: Array<GQLEvaluationBreak>;
  /** Date when the Evaluation was created */
  createdAt: Scalars['Date'];
  /** Date when the Evaluation was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Evaluated duration of the Shift */
  duration: Scalars['Float'];
  /** Evaluation note by employee */
  employeeEvaluationNote: Maybe<Scalars['String']>;
  /** The Employment associated with the Evaluation */
  employment: GQLEmployment;
  /** Evaluated date when the Shift has ended */
  endsAt: Scalars['Date'];
  /** Unique identifier of the Evaluation. Null if shift was not evaluated */
  id: Maybe<Scalars['Int']>;
  /** The Location associated with the Evaluation */
  location: Maybe<GQLLocation>;
  /** The LocationsPosition associated with the Evaluation */
  locationsPosition: GQLLocationsPosition;
  /** The Payments associated with the Evaluation */
  payments: Array<GQLPayment>;
  /** Evaluation has pending employment response */
  pendingEmployeeEvaluation: Scalars['Boolean'];
  /** The Position associated with the Evaluation */
  position: Maybe<GQLPosition>;
  /** The Shift associated with the Evaluation */
  shift: Maybe<GQLShift>;
  /** Note regarding the Shift */
  shiftNote: Maybe<Scalars['String']>;
  /** The Shiftplan associated with the Evaluation */
  shiftplan: GQLShiftplan;
  /** Id of staffShift evaluation belongs to */
  staffShiftId: Scalars['Int'];
  /** The StaffShiftsTag associated with the Evaluation */
  staffShiftsTags: Array<GQLStaffShiftsTag>;
  /** The ids of the StaffShiftsTag the shift is associated with */
  staffShiftsTagsIds: Array<Scalars['Int']>;
  /** Evaluated date when the Shift was started */
  startsAt: Scalars['Date'];
  /** State of the Evaluation */
  state: GQLEvaluationState;
  totalPayment: Maybe<Scalars['Float']>;
  /** Untimed/unpaid break duration in minutes */
  untimedBreak: Maybe<Scalars['Float']>;
  /** Date when the Evaluation was last updated */
  updatedAt: Scalars['Date'];
};

export type GQLEvaluationBreak = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the EvaluationBreak was created */
    createdAt: Scalars['Date'];
    /** Date when the EvaluationBreak was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Date when EvaluationBreak ended */
    endsAt: Maybe<Scalars['Date']>;
    /** ID of Evaluation the EvaluationBreak is associated with */
    evaluationId: Scalars['Int'];
    /** Unique identifier of the Evaluation */
    id: Scalars['Int'];
    /** Date when EvaluationBreak started */
    startsAt: Maybe<Scalars['Date']>;
    /** Date when the EvaluationBreak was last updated */
    updatedAt: Scalars['Date'];
  };

/**
 * Payments define the total amount of money an employee earns from a shift.
 * Note that to be able to receive Payment information about an employee's performed shifts
 * the staff shift id is required.
 */
export type GQLPayment = GQLIdentifiable & {
  /** Children of the Payment */
  childPayments: Maybe<Array<GQLPayment>>;
  displayName: Maybe<Scalars['String']>;
  /** Unique identifier of the Payment */
  id: Scalars['Int'];
  /** On which level has the Payment been created (Company, Position, employee)? */
  inheritedFrom: Maybe<GQLPaygradeLevel>;
  /** Is the Payment editable */
  isEdited: Maybe<Scalars['Boolean']>;
  /** PaygradeType associated with the Payment */
  paygradeType: Maybe<GQLPaygradeType>;
  /** ID of the staffShift this Payment belongs to */
  staffShiftId: Scalars['Int'];
  /** Length of Shift in minutes */
  sumWithShift: Maybe<Scalars['Float']>;
  /** Type of Payment */
  type: Maybe<Scalars['String']>;
  /** Value of PaygradeType */
  value: Scalars['Float'];
};

/** A StaffShiftsTag */
export type GQLStaffShiftsTag = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the StaffShiftsTag was created */
    createdAt: Scalars['Date'];
    /** Date when the StaffShiftsTag was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the StaffShiftsTag */
    id: Scalars['Int'];
    /** StaffShift associated with the StaffShiftsTag */
    staffShift: GQLStaffShift;
    /** ID of the StaffShift associated with the StaffShiftsTag */
    staffShiftId: Scalars['Int'];
    /** Tag associated with the StaffShiftsTag */
    tag: GQLTag;
    /** ID of the Tag associated with the StaffShiftsTag */
    tagId: Scalars['Int'];
    /** Date when the StaffShiftsTag was last updated */
    updatedAt: Scalars['Date'];
  };

export enum GQLEvaluationState {
  AUTO_PUNCHTIMED = 'auto_punchtimed',
  DONE_EVALUATION = 'done_evaluation',
  NEEDS_EVALUATION = 'needs_evaluation',
  NO_EVALUATION = 'no_evaluation',
  NO_SHOW = 'no_show',
  PUNCHTIMED = 'punchtimed',
}

export type GQLShiftPaygrade = GQLIdentifiable & {
  childPaygrades: Maybe<Array<GQLShiftPaygrade>>;
  /** Unique identifier of the ShiftPaygrade */
  id: Scalars['Int'];
  /** ID of the entity for which the ShiftPaygrade has been defined */
  paygradeForId: Scalars['Int'];
  /** Level for which the ShiftPaygrade has been defined (company, shift, or other paygrade, i.e. bonus) */
  paygradeForType: GQLPaygradeLevel;
  /** PaygradeType associated with the ShiftPaygrade */
  paygradeType: GQLPaygradeType;
  shiftId: Scalars['Int'];
  /** Value of ShiftPaygrade */
  value: Scalars['Float'];
};

/**
 * A Request defines an employee's wish to make a
 * change to a Shift. If an employee is not currently
 * assigned to a Shift they can request an application.
 * If an employee is currently assigned to a Shift they
 * can request a change.
 */
export type GQLRequest = GQLIdentifiable &
  GQLTimestampable & {
    /** The employment ids of employees that accepted this request */
    acceptedBy: Maybe<Array<Maybe<Scalars['Int']>>>;
    /** Date when the request was processed by automation */
    automationCheckedAt: Maybe<Scalars['Date']>;
    /** Date when the Request was created */
    createdAt: Scalars['Date'];
    /** Date when the Request was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Employment associated with the Request */
    employment: Maybe<GQLEmployment>;
    /** Unique identifier of the Request */
    id: Scalars['Int'];
    /** Shift associated with the Request */
    shift: Maybe<GQLShift>;
    /** The state of the swap request (if it is one) */
    state: Maybe<GQLReplaceRequestState>;
    /** Type of Request */
    type: Maybe<GQLRequestType>;
    /** Date when the Request was last updated */
    updatedAt: Scalars['Date'];
  };

export enum GQLReplaceRequestState {
  ACCEPTED = 'accepted',
  AUTO_CONFIRMED = 'auto_confirmed',
  CONFIRMED = 'confirmed',
  NEW = 'new',
}

export enum GQLRequestType {
  /** Request made by an employee already assigned to a Shift */
  CHANGEREQUEST = 'ChangeRequest',
  /** Request made by an employee not assigned to any Shift */
  STAFFREQUEST = 'StaffRequest',
  /** Request of an employee offering his/her shift to other employees */
  SWAPREQUEST = 'SwapRequest',
}

/** A ShiftAssignmentGroup */
export type GQLShiftAssignmentGroup = GQLIdentifiable &
  GQLTimestampable & {
    /** AssignmentGroup associated with the ShiftAssignmentGroup */
    assignmentGroup: GQLAssignmentGroup;
    /** Id of AssignmentGroup */
    assignmentGroupId: Scalars['Int'];
    /** Date when the ShiftAssignmentGroup was created */
    createdAt: Scalars['Date'];
    /** Date when the ShiftAssignmentGroup was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the ShiftAssignmentGroup */
    id: Scalars['Int'];
    /** Shift associated with the ShiftAssignmentGroup */
    shift: GQLShift;
    /** Id of Shift */
    shiftId: Scalars['Int'];
    /** Date when the ShiftAssignmentGroup was last updated */
    updatedAt: Scalars['Date'];
  };

/** Every Shift can have multiple shift breaks. */
export type GQLShiftBreak = GQLIdentifiable & {
  /** Date when the ShiftBreak ended */
  endsAt: Maybe<Scalars['Date']>;
  /** Unique identifier of the ShiftBreak */
  id: Scalars['Int'];
  /** Shift to which this break relates */
  shift: GQLShift;
  /** ID of the shift to which this break relates */
  shiftId: Scalars['Int'];
  /** Date when the ShiftBreak started */
  startsAt: Scalars['Date'];
};

export type GQLShiftQualification = {
  /** Required workers for qualification */
  count: Scalars['Int'];
  /** Qualification associated to the Shift */
  qualification: GQLQualification;
  /** ID of the associated qualification */
  qualificationId: Scalars['Int'];
};

/**
 * Qualifications are expertise assigned to employment. An employee
 * can have multiple qualifications. Availability of Qualification feature
 * for a company depends on the package and also it needs to be enabled
 * in company profile.
 */
export type GQLQualification = GQLIdentifiable &
  GQLTimestampable & {
    /** Company associated with Qualification */
    company: GQLCompany;
    /** ID of company associated with Qualification */
    companyId: Scalars['Int'];
    /** Date when the Qualfication was created */
    createdAt: Scalars['Date'];
    /** Date when the Qualfication was last deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the Qualification */
    id: Scalars['Int'];
    /** Additional metadata related to the Qualification */
    metadata: Maybe<Scalars['Json']>;
    /** Name of the Qualification */
    name: Scalars['String'];
    /** Date when the Qualfication was last updated */
    updatedAt: Scalars['Date'];
  };

export enum GQLStaffShiftState {
  AUTO_PUNCHTIMED = 'auto_punchtimed',
  DONE_EVALUATION = 'done_evaluation',
  NEEDS_EVALUATION = 'needs_evaluation',
  NO_EVALUATION = 'no_evaluation',
  NO_SHOW = 'no_show',
  PUNCHTIMED = 'punchtimed',
}

/**
 * A User can be an employee, a stakeholder and/or the Company owner. As soon as someone is invited
 * to shyftplan a new User will be created. Users can be associated with several Companies.
 */
export type GQLUser = GQLIdentifiable &
  GQLTimestampable & {
    billingTypeId: Maybe<Scalars['Int']>;
    /** Date when the User was created */
    createdAt: Scalars['Date'];
    /** Company associated with the User */
    currentCompany: Maybe<GQLCompany>;
    /** ID of the Company associated with the User */
    currentCompanyId: Maybe<Scalars['Int']>;
    /** Location associated with the User */
    currentLocation: Maybe<GQLLocation>;
    /** ID of the Location associated with the User */
    currentLocationId: Maybe<Scalars['Int']>;
    /** Date when the User was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Email address of the User */
    email: Maybe<Scalars['String']>;
    /** Leaving date of the User */
    exitDate: Maybe<Scalars['Date']>;
    /** Unique identifier of the User */
    id: Scalars['Int'];
    /** Date when the invitation was accepted */
    invitationAcceptedAt: Maybe<Scalars['Date']>;
    /** Date when the invitation was created */
    invitationCreatedAt: Maybe<Scalars['Date']>;
    /** Date when the invitation was sent */
    invitationSentAt: Maybe<Scalars['Date']>;
    /** Date when the invitation was accepted */
    invitedById: Maybe<Scalars['Int']>;
    /** Type of User that sent the invitation (stakeholder/owner) */
    invitedByType: Maybe<Scalars['String']>;
    /** Is the User's account inactive? */
    isInactive: Maybe<Scalars['Boolean']>;
    /** Locale (i.e. language code) of the User */
    locale: Maybe<Scalars['String']>;
    /** Phone number of the User */
    phoneNumber: Maybe<Scalars['String']>;
    /** Date when the User was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLMinimumBreakSettings = {
  /** Force type of company minimum break setting (disabled, warning or enforced) */
  forceType: GQLAutoAcceptRequestSetting;
  /** Minimum break rules */
  rules: Array<GQLMinimumBreakSetting>;
};

export type GQLMinimumBreakSetting = {
  /** Minimum break duration */
  totalBreakMinutes: Scalars['Int'];
  /** Minimum duration of shift for rule to be applied */
  totalMinutes: Scalars['Int'];
};

/**
 * The newsfeed contains notifications about created Absences, Evaluations, PunchTimes, Requests and
 * Shiftplans. It is also possible to post messages via newsfeed.
 */
export type GQLNewsfeed = GQLIdentifiable &
  GQLTimestampable & {
    /** The Company associated with the Newsfeed */
    company: Maybe<GQLCompany>;
    context: Maybe<Scalars['Json']>;
    contextId: Maybe<Scalars['String']>;
    contextType: Maybe<Scalars['String']>;
    /** Date when the Newsfeed was created */
    createdAt: Scalars['Date'];
    /** Date when the Newsfeed was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the Newsfeed */
    id: Scalars['Int'];
    /** Newsfeed item type */
    key: Maybe<Scalars['String']>;
    /** Optional message posted to the Newsfeed */
    message: Maybe<Scalars['String']>;
    /** Additional metadata related to the Newsfeed item */
    metadata: Maybe<Scalars['Json']>;
    objekt: Maybe<Scalars['Json']>;
    objektId: Maybe<Scalars['String']>;
    objektType: Maybe<Scalars['String']>;
    subjekt: Maybe<Scalars['Json']>;
    subjektId: Maybe<Scalars['String']>;
    subjektType: Maybe<Scalars['String']>;
    /** Date when the Newsfeed was last updated */
    updatedAt: Scalars['Date'];
    /** The User associated with the Newsfeed */
    user: Maybe<Scalars['Json']>;
  };

export type GQLAbsenceReasonsStatsResponse = {
  absenceReasonsStats: Array<GQLAbsenceReasonStats>;
  totalDays: Scalars['Int'];
};

export type GQLAbsenceReasonStats = {
  absenceReason: GQLAbsenceReason;
  /** Information about carried over days, if it is enabled for the given AbsenceReason */
  carryOverInfo: GQLCarryOverInfo;
  /** Unique identifier of the AbsenceReason */
  id: Scalars['Int'];
  /** The amount of days of the AbsenceReason the given employment is allowed to take in the given timespan */
  limit: Scalars['Float'];
  /** A human-readable representation of the AbsenceReason (e.g. vacation, sickness, overtime, other) */
  name: Scalars['String'];
  /** The amount of taken days of this AbsenceReason for the given employment in the given timespan, including the taken carryover days */
  used: Scalars['Float'];
};

export type GQLCarryOverInfo = {
  /** How much days were carried over to the given timespan */
  limit: Maybe<Scalars['Float']>;
  /** The amount of days of the carried over days already have been taken */
  used: Maybe<Scalars['Float']>;
};

/** Status of the Employment (only in the context of Absence) */
export enum GQLEmploymentStatus {
  /** Employment is active */
  ACTIVE = 'active',
  /** Employment has been deleted */
  DESTROYED = 'destroyed',
  /** Employment is disabled */
  INACTIVE = 'inactive',
  /** Employment is not associated with an email */
  NOEMAIL = 'noemail',
}

export enum GQLOrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type GQLAbsenceCollection = GQLPaginatable & {
  items: Array<GQLAbsence>;
  pagination: GQLPaginationInfo;
};

export type GQLEmploymentCollection = GQLPaginatable & {
  items: Array<GQLEmployment>;
  pagination: GQLPaginationInfo;
};

export type GQLAssignmentGroupCollection = GQLPaginatable & {
  items: Array<GQLAssignmentGroup>;
  pagination: GQLPaginationInfo;
};

export type GQLAvailabilityCollection = GQLPaginatable & {
  items: Array<GQLAvailability>;
  pagination: GQLPaginationInfo;
};

/**
 * Every employee can inform stakeholders about their availabilities. The employee is able to state
 * whether they are available or unavailable, either all day or within a certain time frame and if
 * these availabilities should be repeated weekly or appear only once.
 * When fetching availabilities for a certain time frame of repeated availabilities you need to search
 * for an exact date and check status ”repeat”: true in order to receive the information. In this case
 * searched and listed date can differ, because only the first created availability of a series of
 * repeated availability will be listed.
 */
export type GQLAvailability = GQLIdentifiable &
  GQLTimeframeable &
  GQLTimestampable & {
    /** Is this Availability indicating that the Employee is available or unavailable? */
    available: Scalars['Boolean'];
    /** Date when the Availability was created */
    createdAt: Scalars['Date'];
    /** Date when the Availability was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** The Employment associated with the Availability */
    employment: GQLEmployment;
    /** Date when the Availability will end or ended */
    endsAt: Scalars['Date'];
    /** endsAt in DD.MM.YYYY HH:mm format */
    endsAtFormatted: Maybe<Scalars['String']>;
    /** Original end date with which availability was created */
    endsAtOriginal: Scalars['Date'];
    /** Identifier of the Availability (is the same for repeating availabilities) */
    id: Scalars['Int'];
    /** Is this a full day-availability? */
    isFullDay: Scalars['Boolean'];
    /** Is the Availability repeated weekly? */
    repeat: Scalars['Boolean'];
    /** Date when the Availability will start or started */
    startsAt: Scalars['Date'];
    /** startsAt in DD.MM.YYYY HH:mm format */
    startsAtFormatted: Maybe<Scalars['String']>;
    /** Original start date with which availability was created */
    startsAtOriginal: Scalars['Date'];
    /** Unique identifier of the Availability */
    uniqueId: Scalars['String'];
    /** Date when the Availability was last updated */
    updatedAt: Scalars['Date'];
  };

export enum GQLAvailabilityAggregationOrderKey {
  AVAILABLE_HOURS = 'available_hours',
  CONTRACT_HOURS = 'contract_hours',
  EMPLOYEE_LAST_NAME = 'employee_last_name',
}

export type GQLAvailabilityAggregationCollection = GQLPaginatable & {
  items: Array<GQLAvailabilityAggregation>;
  pagination: GQLPaginationInfo;
};

/** Statistics about availabilities for one employee */
export type GQLAvailabilityAggregation = {
  /** The amount of hours that the employee entered as available */
  availableHours: Scalars['Int'];
  /** Whether the requesting user is allowed to edit the availabilities of the associated employee */
  canManage: Scalars['Boolean'];
  /** The amount of hours that the employee is contracted to work. Null, employee only has monthly hours */
  contractHours: Maybe<Scalars['Int']>;
  /** The Employment associated with the AvailabilityAggregation */
  employment: GQLEmployment;
  /** The id of the Employment associated with the AvailabilityAggregation */
  employmentId: Scalars['Int'];
};

export type GQLAvailabilityExceptionCollection = GQLPaginatable & {
  items: Array<GQLAvailabilityException>;
  pagination: GQLPaginationInfo;
};

export type GQLAvailabilityException = GQLIdentifiable &
  GQLTimeframeable &
  GQLTimestampable & {
    /** The Availability associated with the AvailabilityException */
    availability: Maybe<GQLAvailability>;
    /** Date when the AvailabilityException was created */
    createdAt: Scalars['Date'];
    /** Date when the AvailabilityException was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Date when the AvailabilityException will end or ended */
    endsAt: Scalars['Date'];
    /** Unique identifier of the AvailabilityException */
    id: Scalars['Int'];
    /** Date when the AvailabilityException will start or started */
    startsAt: Scalars['Date'];
    /** Date when the AvailabilityException was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLBackgroundJobCollection = GQLPaginatable & {
  items: Array<GQLBackgroundJob>;
  pagination: GQLPaginationInfo;
};

/**
 * In order do distinguish between different kinds of Absences every Absence has an AbsenceReason.
 * Note that vacation as an AbsenceReason will automatically count off vacation days that have been
 * documented in an employee's payroll information.
 */
export type GQLBackgroundJob = GQLTimestampable & {
  /** Date when the BackgroundJob was created */
  createdAt: Scalars['Date'];
  /** Date when the BackgroundJob was deleted (not used, always null) */
  deletedAt: Maybe<Scalars['Date']>;
  /** errors of the BackgroundJob */
  errors: Maybe<Array<Scalars['Json']>>;
  /** Date when the execution of the BackgroundJob finished */
  finishedAt: Maybe<Scalars['Date']>;
  /** Unique identifier of the BackgroundJob */
  id: Scalars['String'];
  /** Unique identifier of the underlying job */
  jobIdentifier: Scalars['String'];
  /** Type of the BackgroundJob */
  klassName: Scalars['String'];
  /** result of the BackgroundJob */
  result: Maybe<Scalars['Json']>;
  /** Date when the execution of the BackgroundJob was started */
  startedAt: Maybe<Scalars['Date']>;
  /** State of the BackgroundJob */
  state: GQLBackgroundJobState;
  /** Date when the BackgroundJob was last updated */
  updatedAt: Scalars['Date'];
};

export enum GQLBackgroundJobState {
  FAILED = 'failed',
  FINISHED = 'finished',
  PENDING = 'pending',
  RUNNING = 'running',
}

export type GQLCalendarAggregation = {
  absent: Scalars['Float'];
  date: Scalars['Date'];
  demand: Scalars['Int'];
  working: Scalars['Int'];
};

export type GQLChatEmploymentCollection = GQLPaginatable & {
  items: Array<GQLChatEmployment>;
  pagination: GQLPaginationInfo;
};

export type GQLChatEmployment = GQLIdentifiable & {
  firstName: Scalars['String'];
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  lastName: Scalars['String'];
  pictureData: GQLPictureData;
};

export enum GQLChatType {
  EMPLOYMENT = 'Employment',
  LOCATION = 'Location',
  LOCATIONSPOSITION = 'LocationsPosition',
}

export type GQLChatMessageCollection = GQLPaginatable & {
  items: Array<GQLChatMessage>;
  pagination: GQLPaginationInfo;
};

export type GQLChatMessage = GQLIdentifiable & {
  chatId: Scalars['Int'];
  createdAt: Scalars['Date'];
  employmentId: Scalars['Int'];
  /** Unique identifier of the ChatMessage */
  id: Scalars['Int'];
  message: Scalars['String'];
  sender: Maybe<GQLChatMessageEmployment>;
};

export type GQLChatMessageEmployment = GQLIdentifiable & {
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  userImage: Scalars['String'];
  userName: Scalars['String'];
};

export type GQLChatCollection = GQLPaginatable & {
  items: Array<GQLChat>;
  pagination: GQLPaginationInfo;
};

export type GQLChat = {
  hasNewChatMessages: Scalars['Boolean'];
  /** Unique identifier of the Chat (can be integer or string like Location1234) */
  id: Scalars['String'];
  lastChatMessage: Maybe<GQLChatMessage>;
  member: Maybe<GQLChatUser>;
  type: GQLChatType;
};

export type GQLChatUser =
  | GQLChatLocation
  | GQLChatLocationsPosition
  | GQLChatMessageEmployment;

export type GQLChatLocation = GQLIdentifiable & {
  /** Unique identifier of the Location */
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GQLChatLocationsPosition = GQLIdentifiable & {
  /** Unique identifier of the LocationsPosition */
  id: Scalars['Int'];
  locationName: Scalars['String'];
  positionColor: Scalars['String'];
  positionName: Scalars['String'];
};

export type GQLCompanyCollection = GQLPaginatable & {
  items: Array<GQLCompany>;
  pagination: GQLPaginationInfo;
};

export type GQLCustomFieldCollection = GQLPaginatable & {
  items: Array<GQLCustomField>;
  pagination: GQLPaginationInfo;
};

/**
 * A CustomField describes an additional information about a User. They will be added to the user's
 * employment information. Only stakeholders can add custom fields.
 */
export type GQLCustomField = GQLIdentifiable & {
  /** The Company associated with the CustomField */
  company: Maybe<GQLCompany>;
  /** Date when the CustomField was created */
  createdAt: Scalars['Date'];
  /** Data type used for the CustomField */
  customFieldType: Scalars['String'];
  /** Unique identifier of the CustomField */
  id: Scalars['Int'];
  /** Name of the CustomField */
  name: Scalars['String'];
  /** Sort position of the CustomField */
  sort: Scalars['Int'];
  /** Date when the CustomField was last updated */
  updatedAt: Scalars['Date'];
};

export type GQLDayNoteCollection = GQLPaginatable & {
  items: Array<GQLDayNote>;
  pagination: GQLPaginationInfo;
};

export type GQLDayNote = GQLIdentifiable & {
  /** Optional description for the DayNote */
  description: Maybe<Scalars['String']>;
  /** End date for the DayNote */
  endsAt: Scalars['Date'];
  /** Unique identifier of the DayNote */
  id: Scalars['Int'];
  /** Shiftplan associated with the DayNote */
  shiftplan: GQLShiftplan;
  /** Start date of the DayNote */
  startsAt: Scalars['Date'];
  /** Title of the DayNote */
  title: Scalars['String'];
};

/**
 * When a Shift has been performed by an employee an EmploymentEvaluation is automatically provided. This can be
 * manually adjusted by stakeholders or a proposal of changes can be made by the employee (if enabled).
 * Please note: payments can be included in the employment evaluation response. This decreases the response time
 * and requires owner rights for the API user.
 */
export type GQLEmploymentEvaluation = GQLIdentifiable & {
  /** Evaluated total break duration (untimed plus additional) in minutes */
  break: Scalars['Float'];
  /** The EvaluationBreaks associated with the Evaluaton */
  breaks: Array<GQLEvaluationBreak>;
  /** Date when the Evaluation was created */
  createdAt: Scalars['Date'];
  /** Date when the Evaluation was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Evaluated date when the Shift has ended */
  endsAt: Scalars['Date'];
  /** Unique identifier of the Evaluation */
  id: Scalars['Int'];
  /** The Payments associated with the Evaluation */
  payments: Array<GQLPayment>;
  /** Note regarding the Shift */
  shiftNote: Maybe<Scalars['String']>;
  /** ID of StaffShift associated with Evaluation */
  staffShiftId: Scalars['Int'];
  /** The StaffShiftsTag associated with the Evaluation */
  staffShiftsTags: Array<GQLStaffShiftsTag>;
  /** The ids of the StaffShiftsTag the shift is associated with */
  staffShiftsTagsIds: Array<Scalars['Int']>;
  /** Evaluated date when the Shift was started */
  startsAt: Scalars['Date'];
  /** Untimed/unpaid break duration in minutes */
  untimedBreak: Maybe<Scalars['Float']>;
  /** Date when the Evaluation was last updated */
  updatedAt: Scalars['Date'];
};

export type GQLPaymentCollection = GQLPaginatable & {
  items: Array<GQLPayment>;
  pagination: GQLPaginationInfo;
};

/**
 * Filter for employment to filter out based on access level
 * all - return employments based on other filters
 * manage_availabilities - return employments which can be scheduled by current employees
 */
export enum GQLEmploymentAccessLevel {
  ALL = 'all',
  MANAGE_AVAILABILITIES = 'manage_availabilities',
}

export enum GQLEmploymentSort {
  LAST_NAME = 'last_name',
  STATE = 'state',
}

export enum GQLShiftEmploymentSort {
  BRINGSHIFTS = 'bringShifts',
  EARNEDAMOUNT = 'earnedAmount',
  HOURACCOUNTHOURS = 'hourAccountHours',
  MAXIMUMMONEY = 'maximumMoney',
  NAME = 'name',
  REMAININGMINUTES = 'remainingMinutes',
  REMAININGMONEY = 'remainingMoney',
  WORKEDMINUTES = 'workedMinutes',
}

export type GQLEmploymentsInfoCollection = GQLPaginatable & {
  items: Array<GQLEmploymentInfo>;
  pagination: GQLPaginationInfo;
};

/**
 * A EmploymentInfo endpoint returns
 * information about employments and their hour account information, for a specific shiftplan
 */
export type GQLEmploymentInfo = GQLIdentifiable & {
  /** Number of annual bring shift hours */
  bringShiftHours: Maybe<Scalars['Float']>;
  /** Number of bring shift hours worked */
  bringShiftHoursUsed: Maybe<Scalars['Float']>;
  /** Number of contracted minutes */
  contractMinutes: Maybe<Scalars['Float']>;
  /** Employment associated  */
  employment: GQLEmployment;
  /** Hour account balance */
  hourAccountHours: Maybe<Scalars['Float']>;
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  /** Maximum earnings per month */
  maximumMoney: Maybe<Scalars['Float']>;
  /** Number of scheduled minutes */
  scheduledMinutes: Maybe<Scalars['Float']>;
  /** Expected payment for the scheduled shifts */
  scheduledMoney: Maybe<Scalars['Float']>;
  /** Number of actually worked minutes */
  workedMinutes: Maybe<Scalars['Float']>;
};

export enum GQLResponseFormat {
  NESTED = 'nested',
  PLAIN = 'plain',
}

export type GQLEmploymentsPositionCollection = GQLPaginatable & {
  items: Array<GQLEmploymentsPosition>;
  pagination: GQLPaginationInfo;
};

/**
 * EmploymentsPosition is the join table of Employment and LocationsPosition.
 * This defines on which positions at what locations an employee is able to work.
 */
export type GQLEmploymentsPosition = GQLIdentifiable & {
  /** Date when the EmploymentsPosition was created */
  createdAt: Scalars['Date'];
  /** The Employment associated with the EmploymentsPosition */
  employment: Maybe<GQLEmployment>;
  /** Unique identifier of the EmploymentsPosition */
  id: Scalars['Int'];
  /** The LocationsPosition associated with the EmploymentsPosition */
  locationsPosition: Maybe<GQLLocationsPosition>;
  /** Date when the EmploymentsPosition was last updated */
  updatedAt: Scalars['Date'];
};

/** Type of shift rotation */
export enum GQLRotationType {
  /** Individual Shift Rotation */
  INDIVIDUAL = 'individual',
  /** Regular Shift Rotation */
  REGULAR = 'regular',
}

export type GQLEmploymentsRotationGroupsCollection = GQLPaginatable & {
  items: Array<GQLRotationGroupUnion>;
  pagination: GQLPaginationInfo;
};

export type GQLRotationGroupUnion =
  | GQLEmploymentsRotationGroup
  | GQLIndividualShiftRotation;

export type GQLIndividualShiftRotation = GQLIdentifiable &
  GQLTimestampable & {
    /** Anchor date of the IndividualShiftRotation */
    anchorDate: Scalars['Date'];
    /** Company associated with IndividualShiftRotation */
    company: GQLCompany;
    /** ID of the associated Company */
    companyId: Scalars['Int'];
    /** Date when the IndividualShiftRotation was created */
    createdAt: Scalars['Date'];
    /** Date when the IndividualShiftRotation was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Employment associated with IndividualShiftRotation */
    employment: GQLEmployment;
    /** ID of associated Employment */
    employmentId: Scalars['Int'];
    /** When does the the IndividualShiftRotation itself end */
    endsAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the IndividualShiftRotation */
    id: Scalars['Int'];
    /** After how many days does the IndividualShiftRotation repeat? */
    rotationInterval: Scalars['Int'];
    /** An array of ShiftPreset IDs associated with the ShiftRotationGroup */
    shiftPresetIds: Array<Scalars['Int']>;
    /** An array of ShiftPreset associated with the ShiftRotationGroup */
    shiftPresets: Array<GQLShiftPreset>;
    /** When does the the IndividualShiftRotation itself start */
    startsAt: Maybe<Scalars['Date']>;
    /** Date when the IndividualShiftRotation was last updated */
    updatedAt: Scalars['Date'];
  };

/**
 * Every Employment has an EmploymentSurvey. It contains all specified employee information.
 * To enable an employment survey the employment's billing type (payroll type) needs to be set
 * to billing. After the payroll type is set the employee is able to fill out the staff questionnaire
 * which will be added to the employment survey.
 */
export type GQLEmploymentsSurvey = GQLIdentifiable & {
  allStepCompleted: Maybe<Scalars['Boolean']>;
  /** Name of the bank account holder */
  bankAccountHolder: Maybe<Scalars['String']>;
  /** IBAN */
  bankAccountNumber: Maybe<Scalars['String']>;
  /** BIC */
  bankIdentifierCode: Maybe<Scalars['String']>;
  /** Name of the bank */
  bankName: Maybe<Scalars['String']>;
  /** Birth date of the employee */
  birthDate: Maybe<Scalars['Date']>;
  /** Birth place of the employee */
  birthPlace: Maybe<Scalars['String']>;
  /** Bank that issued the capital-forming benefits */
  capitalFormingBenefitsBankName: Maybe<Scalars['String']>;
  /** Contract number of capital-forming benefits contract */
  capitalFormingBenefitsContractNumber: Maybe<Scalars['String']>;
  /** Commencement of the capital-forming benefits contract */
  capitalFormingBenefitsContractStart: Maybe<Scalars['Date']>;
  /** Employer's contribution towards the capital-forming benefits contract */
  capitalFormingBenefitsEmployersContribution: Maybe<Scalars['Float']>;
  /** Amount per month going into the capital-forming benefits contract */
  capitalFormingBenefitsTotal: Maybe<Scalars['Float']>;
  /** Amount of allowance the employee is entitled to in case they have children */
  childrenAllowance: Maybe<Scalars['Float']>;
  /** Number of children */
  childrenCount: Maybe<Scalars['Int']>;
  /** Whether a proof has been provided that the employee has children */
  childrenProof: Maybe<Scalars['String']>;
  /** Name of the city the employee is living in (only when otherAddress = true) */
  city: Maybe<Scalars['String']>;
  /** Additional comments */
  comments: Maybe<Scalars['String']>;
  /** Name of the country the employee is living in (only when otherAddress = true) */
  country: Maybe<Scalars['String']>;
  /** Date when the EmploymentsSurvey was created */
  createdAt: Scalars['Date'];
  /** Date when the employee joined */
  dateOfEntry: Maybe<Scalars['Date']>;
  /** Relevant for the deduction of church tax from your gross pay. */
  denomination: Maybe<Scalars['String']>;
  /** Status of employment. As of now only German taxation options */
  employmentRelationship: Maybe<Scalars['String']>;
  /** Main or secondary employment? */
  employmentType: Maybe<Scalars['String']>;
  /** Leaving date of the employee (in case of fixed-term contract) */
  exitDate: Maybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: Maybe<Scalars['Boolean']>;
  /** Marital status */
  familyStatus: Maybe<Scalars['String']>;
  /** First name of the employee */
  firstName: Maybe<Scalars['String']>;
  /** Whether the employee has one or more other mini jobs */
  furtherMinijobs: Maybe<Scalars['Boolean']>;
  /** Gender of the employee */
  gender: Maybe<Scalars['String']>;
  /** Whether the employee has capital-forming benefits */
  hasCapitalFormingBenefits: Maybe<Scalars['Boolean']>;
  /** Whether the employee has children */
  hasChildren: Maybe<Scalars['Boolean']>;
  /** Identification number of the health insurance provider */
  healthInsuranceIdentificationNumber: Maybe<Scalars['String']>;
  /** Name of the health insurance provider */
  healthInsuranceName: Maybe<Scalars['String']>;
  /** Highest level of education */
  highestLevelOfEducation: Maybe<Scalars['String']>;
  /** Highest school degree */
  highestSchoolDegree: Maybe<Scalars['String']>;
  /** Unique identifier of the EmploymentsPosition */
  id: Scalars['Int'];
  /** Description of main work activity */
  jobDescription: Maybe<Scalars['String']>;
  /** Last name of the employee */
  lastName: Maybe<Scalars['String']>;
  /** Middle name of the employee */
  middleName: Maybe<Scalars['String']>;
  /** Salary if the employment is a mini job */
  minijobSalary: Maybe<Scalars['Float']>;
  /** Nationality of the employee */
  nationality: Maybe<Scalars['String']>;
  /** Whether the employee has worked in Germany before or not */
  neverWorkedInGermany: Maybe<Scalars['Boolean']>;
  /** Note */
  note: Maybe<Scalars['String']>;
  /** true if an address other than the default adress of the User is used */
  otherAddress: Maybe<Scalars['Boolean']>;
  ownerId: Scalars['Int'];
  ownerType: Scalars['String'];
  /** Type of exemption from pension insurance if the employment is a mini job */
  pensionInsuranceExemption: Maybe<Scalars['String']>;
  /** Phone number */
  phoneNumber: Maybe<Scalars['String']>;
  /** Social security number */
  socialSecurityNumber: Maybe<Scalars['String']>;
  /** Staff number */
  staffNumber: Maybe<Scalars['String']>;
  /** Name of the country's state the employee is living in (only when otherAddress = true) */
  state: Maybe<Scalars['String']>;
  /** Name of the street the employee is living in (only when otherAddress = true) */
  street: Maybe<Scalars['String']>;
  /** House number of the street the employee is living in (only when otherAddress = true) */
  streetNumber: Maybe<Scalars['String']>;
  /** Name of the district etc. the employee is living in (only when otherAddress = true) */
  subcity: Maybe<Scalars['String']>;
  /** Tax bracket */
  taxBracket: Maybe<Scalars['String']>;
  /** Only needed if tax bracket is 'IV with factor' */
  taxBracketFactor: Maybe<Scalars['String']>;
  /** Tax identification number (also called personal identification number, IdNr. or tax ID) */
  taxIdentificationNumber: Maybe<Scalars['String']>;
  /** Date when the EmploymentsSurvey was last updated */
  updatedAt: Maybe<Scalars['Date']>;
  visibility: Maybe<Scalars['String']>;
  /** Working hours per week */
  weeklyHours: Maybe<Scalars['Float']>;
  /** Zip code of the city the employee is living in (only when otherAddress = true) */
  zip: Maybe<Scalars['String']>;
};

/**
 * Every Employment has an EmploymentUiSetting. It is specific to Calendar.
 * It contains information on:
 * 1. Filters selected
 * 2. Timeframe selected for each shift schedule
 * 3. View that the user last used
 *
 * The information contained in there can specific to:
 * 1. Location
 * 2. Shiftplan
 */
export type GQLEmploymentsUiSetting = GQLIdentifiable & {
  /** Date when the EmploymentsUiSetting was created */
  createdAt: Scalars['Date'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Unique identifier of the EmploymentsUiSetting */
  id: Scalars['Int'];
  /** Data of saved setting */
  settings: Maybe<Scalars['Json']>;
  /** Date when the EmploymentsUiSetting was last updated */
  updatedAt: Maybe<Scalars['Date']>;
  /** Version of saved setting */
  version: Maybe<Scalars['String']>;
};

export type GQLEvaluationBreakCollection = GQLPaginatable & {
  items: Array<GQLEvaluationBreak>;
  pagination: GQLPaginationInfo;
};

export type GQLEvaluationCollection = GQLPaginatable & {
  items: Array<GQLEvaluation>;
  pagination: GQLPaginationInfo;
};

export type GQLIndividualShiftRotationCollection = GQLPaginatable & {
  items: Array<GQLIndividualShiftRotation>;
  pagination: GQLPaginationInfo;
};

export type GQLLanguageCollection = GQLPaginatable & {
  items: Array<GQLLanguage>;
  pagination: GQLPaginationInfo;
};

export type GQLLiveStaffShiftCollection = GQLPaginatable & {
  items: Array<GQLLiveStaffShift>;
  pagination: GQLPaginationInfo;
};

/**
 * A live staff shift contains all information about a performed Shift. Note that only company owners
 * or stakeholders with view permissions can access LiveStaffShift information.
 */
export type GQLLiveStaffShift = GQLIdentifiable & {
  /** The Company associated with the LiveStaffShift */
  company: Maybe<GQLCompany>;
  /** The Employment associated with the LiveStaffShift */
  employment: Maybe<GQLEmployment>;
  /** Unique identifier of the LiveStaffShift */
  id: Scalars['Int'];
  /** State of the LiveStaffShift */
  liveStatus: Maybe<Scalars['String']>;
  /** The Location associated with the LiveStaffShift */
  location: Maybe<GQLLocation>;
  /** The LocationsPosition associated with the LiveStaffShift */
  locationsPosition: Maybe<GQLLocationsPosition>;
  /** The Position associated with the LiveStaffShift */
  position: Maybe<GQLPosition>;
  /** ID of the PunchBreak associated with the LiveStaffShift */
  punchBreakId: Maybe<Scalars['Int']>;
  /** ID of the PunchTiming associated with the LiveStaffShift */
  punchTimingId: Maybe<Scalars['Int']>;
  /** The Shift associated with the LiveStaffShift */
  shift: Maybe<GQLShift>;
  /** The StaffShift associated with the LiveStaffShift */
  staffShift: Maybe<GQLStaffShift>;
  /** The User associated with the LiveStaffShift */
  user: Maybe<GQLUser>;
};

export type GQLLocationCollection = GQLPaginatable & {
  items: Array<GQLLocation>;
  pagination: GQLPaginationInfo;
};

export type GQLLocationShiftplanPublishSettings = {
  /** Company ID */
  companyId: Scalars['Int'];
  /** Location ID */
  locationId: Scalars['Int'];
  /** Additional text message included in email notification to employees. Requires sendMessage to be true for message to be included */
  message: Scalars['String'];
  /** Send information about shifts assigned to the employee in email notification. */
  sendAssignedShifts: Scalars['Boolean'];
  /** Send additional text message to employees. Provided in message field. */
  sendMessage: Scalars['Boolean'];
  /** Send information about open shifts in email notification. */
  sendOpenShifts: Scalars['Boolean'];
};

export type GQLLocationsPositionCollection = GQLPaginatable & {
  items: Array<GQLLocationsPosition>;
  pagination: GQLPaginationInfo;
};

/**
 * Rights define the way a user is able to use shyftplan. Rights can be assigned to an
 * individual User. Only owners in any case and stakeholders with the same rights they
 * want to assign to a user can do so. Choosing the accurate rights for a stakeholder
 * is depending on Company-internal agreements about their operational area.
 */
export type GQLRight = {
  /** Company associated with the Right */
  company: Maybe<GQLCompany>;
  /** ID of the associated context */
  contextId: Maybe<Scalars['Int']>;
  /** Context of the Right (Location, Position, PunchTime etc.) */
  contextType: Maybe<Scalars['String']>;
  /** Date when the Right was created */
  createdAt: Maybe<Scalars['Date']>;
  /** Date when the Right was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** Unique identifier of the Right */
  id: Maybe<Scalars['Int']>;
  /** Can this Right be set? */
  isPossibleToSet: Maybe<Scalars['Boolean']>;
  /** Name of the Right */
  name: Maybe<Scalars['String']>;
  /** Date when the Right was last updated */
  updatedAt: Maybe<Scalars['Date']>;
};

export type GQLNewsfeedCollection = GQLPaginatable & {
  items: Array<GQLNewsfeed>;
  pagination: GQLPaginationInfo;
};

export type GQLNotificationConfigurationCollection = GQLPaginatable & {
  items: Array<GQLNotificationConfiguration>;
  pagination: GQLPaginationInfo;
};

/**
 * NotificationConfiguration defines whether a User gets a notification via mobile or email
 * automatically when a notification appears on the Newsfeed.
 */
export type GQLNotificationConfiguration = {
  /** Should Absence notifications be sent via email? */
  absencesMail: Maybe<Scalars['Boolean']>;
  /** Should Absence notifications be sent to the User's mobile device? */
  absencesMobile: Maybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent via email? */
  applicationRequestRefusedMail: Maybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent to the User's mobile device? */
  applicationRequestRefusedMobile: Maybe<Scalars['Boolean']>;
  /** Should auto punchout notifications be sent to the User's mobile device? */
  autoPunchoutMobile: Maybe<Scalars['Boolean']>;
  /** Date when the NotificationConfiguration was created */
  createdAt: Maybe<Scalars['Date']>;
  /** Date when the NotificationConfiguration was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** ID of the associated Employment */
  employmentId: Maybe<Scalars['Int']>;
  /** Should Evaluation notifications be sent via email? */
  evaluationsMail: Maybe<Scalars['Boolean']>;
  /** Should Evaluation notifications be sent to the User's mobile device? */
  evaluationsMobile: Maybe<Scalars['Boolean']>;
  /** Unique identifier of the NotificationConfiguration */
  id: Maybe<Scalars['Int']>;
  /** Should messages be sent to the User's mobile device? */
  messagesMobile: Maybe<Scalars['Boolean']>;
  /** Should Request notifications be sent to the User's mobile device? */
  requestsMobile: Maybe<Scalars['Boolean']>;
  /** Should Shiftplan notifications be sent to the User's mobile device? */
  shiftplansMobile: Maybe<Scalars['Boolean']>;
  /** Should staff messages be sent to the User's mobile device? */
  staffMessagesMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications via email? */
  stakeholderAbsencesMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications on their mobile devices? */
  stakeholderAbsencesMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto punchout notifications on their mobile devices? */
  stakeholderAutoPunchoutMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications via email? */
  stakeholderAutoRequestChangeAcceptMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications on their mobile devices? */
  stakeholderAutoRequestChangeAcceptMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Evaluation suggestions on their mobile devices? */
  stakeholderEvaluationsMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications via email? */
  stakeholderRequestsMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications on their mobile devices? */
  stakeholderRequestsMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications via email? */
  stakeholderShiftApplicationMail: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications on their mobile devices? */
  stakeholderShiftApplicationMobile: Maybe<Scalars['Boolean']>;
  /** Should stakeholders receive notifications on their mobile devices when an employee has withdrawn their absence? */
  stakeholderWithdrawAbsencesMobile: Maybe<Scalars['Boolean']>;
  /** Date when the NotificationConfiguration was last updated */
  updatedAt: Maybe<Scalars['Date']>;
};

export type GQLPaygradeTypeCollection = GQLPaginatable & {
  items: Array<GQLPaygradeType>;
  pagination: GQLPaginationInfo;
};

export type GQLPaymentsCalculationInput = {
  /** Evaluated total breaks duration in minutes */
  breaks: Scalars['Int'];
  /** Date when the evaluated Shift has ended */
  endsAt: Scalars['Date'];
  /** Start and end date of individual breaks of the evaluated Shift */
  evaluationBreaks: InputMaybe<Array<GQLTimeframeableInput>>;
  /** Payments to be used for the calculation */
  payments: Array<GQLPaymentsCalculationPaymentInput>;
  /** Date when the evaluated Shift has started */
  startsAt: Scalars['Date'];
};

export type GQLTimeframeableInput = {
  endsAt: Scalars['Date'];
  startsAt: Scalars['Date'];
};

export type GQLPaymentsCalculationPaymentInput = {
  /** Children of the payment (e.g. bonuses) */
  childPayments: InputMaybe<Array<GQLPaymentsCalculationPaymentInput>>;
  /** ID of the PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of the payment */
  value: Scalars['Float'];
};

export type GQLPaymentsCalculation = {
  /** Calculated values for each provided payment */
  payments: Maybe<Array<GQLPaymentsCalculationPayment>>;
  /** Sum of calculated payment values for the evaluated Shift */
  sum: Scalars['Float'];
};

export type GQLPaymentsCalculationPayment = {
  /** Calculated children of the payment (e.g. bonuses) */
  childPayments: Maybe<Array<GQLPaymentsCalculationPayment>>;
  /** ID of the PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Calculated value for the payment */
  totalValue: Scalars['Float'];
};

export type GQLPayslipCollection = GQLPaginatable & {
  items: Array<GQLPayslip>;
  pagination: GQLPaginationInfo;
};

/** Payslips are payment documents attached to user. */
export type GQLPayslip = GQLIdentifiable & {
  /** Company associated with Payslip */
  company: GQLCompany;
  /** Upload date */
  date: Scalars['Date'];
  /** Employment id associated with Payslip */
  employmentId: Scalars['Int'];
  /** Uploaded file url */
  file: Scalars['String'];
  /** Unique identifier of the Payslip */
  id: Scalars['Int'];
  /** Uploaded file name */
  name: Scalars['String'];
};

export type GQLPositionCollection = GQLPaginatable & {
  items: Array<GQLPosition>;
  pagination: GQLPaginationInfo;
};

export type GQLPunchLocationsPositions = {
  locationsPositions: Array<Maybe<GQLPunchingLocationsPosition>>;
  success: Scalars['Boolean'];
};

export type GQLPunchingLocationsPosition = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the PunchBreak was created */
    createdAt: Scalars['Date'];
    /** Date when the PunchBreak was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    id: Scalars['Int'];
    location: Maybe<GQLLocation>;
    position: Maybe<GQLPosition>;
    /** Sorting position of the LocationsPosition */
    sort: Maybe<Scalars['Int']>;
    /** Date when the PunchBreak was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLPunchStateOrError = GQLPunchError | GQLPunchState;

export type GQLPunchError = {
  /** A key indicating the reason why the action has failed */
  reason: Maybe<Scalars['String']>;
  /** Was the punchtime session started successfully? */
  success: Maybe<Scalars['Boolean']>;
};

export type GQLPunchState = {
  companyRoundFair: Maybe<Scalars['Boolean']>;
  companyRoundTo: Maybe<Scalars['Float']>;
  incompletePunchBreak: Maybe<GQLPunchBreak>;
  /** Location associated with the PunchState */
  location: Maybe<GQLLocation>;
  /** Position associated with the PunchState */
  position: Maybe<GQLPosition>;
  punchBreaks: Maybe<Array<Maybe<GQLPunchBreak>>>;
  punchTimingUncomplete: Maybe<GQLPunchTiming>;
  /** Shift associated with the PunchState */
  shift: Maybe<GQLShift>;
  /** Shiftplans associated with the PunchState */
  shiftplans: Maybe<Array<Maybe<GQLShiftplan>>>;
  /** StaffShift associated with the PunchState */
  staffShift: Maybe<GQLStaffShift>;
  startTime: Maybe<Scalars['Date']>;
  /** Was the punchtime session started successfully? */
  success: Maybe<Scalars['Boolean']>;
  userImage: Maybe<Scalars['String']>;
  userPresentation: Maybe<Scalars['String']>;
  userToken: Maybe<Scalars['Int']>;
};

export type GQLQualificationCollection = GQLPaginatable & {
  items: Array<GQLQualification>;
  pagination: GQLPaginationInfo;
};

export type GQLRequestCollection = {
  items: Array<GQLRequest>;
  pagination: GQLPaginationInfo;
};

export type GQLRightCollection = GQLPaginatable & {
  items: Array<GQLRight>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftAssignmentGroupCollection = GQLPaginatable & {
  items: Array<GQLShiftAssignmentGroup>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftEmploymentFilter = {
  /** Include only employees with matching availability status */
  availabilityFilters: InputMaybe<GQLShiftEmploymentAvailabilityFilter>;
  /** Include only employees with join requests for this shift */
  withJoinRequests: InputMaybe<Scalars['Boolean']>;
  /** Include only employees without any conflicting absence */
  withoutAbsenceConflicts: InputMaybe<Scalars['Boolean']>;
  /** Include only employees without any other conflicting shift */
  withoutShiftConflicts: InputMaybe<Scalars['Boolean']>;
  /** Include only employees with passed qualification ID */
  withTheseQualificationIds: InputMaybe<Array<Scalars['Int']>>;
};

export type GQLShiftEmploymentAvailabilityFilter = {
  /** Include only employees who are fully available */
  available: InputMaybe<Scalars['Boolean']>;
  /** Include only employees who have no information about availabilities */
  neutral: InputMaybe<Scalars['Boolean']>;
  /** Include only employees who are partially available */
  partialAvailable: InputMaybe<Scalars['Boolean']>;
  /** Include only employees who are partially available and partially unavailable */
  partialBoth: InputMaybe<Scalars['Boolean']>;
  /** Include only employees who are partially unavailable */
  partialUnavailable: InputMaybe<Scalars['Boolean']>;
  /** Include only employees who are fully unavailable */
  unavailable: InputMaybe<Scalars['Boolean']>;
};

export type GQLShiftAvailableEmploymentCollection = GQLPaginatable & {
  items: Array<GQLShiftAvailableEmployment>;
  pagination: GQLPaginationInfo;
};

/** A ShiftAvailableEmployment */
export type GQLShiftAvailableEmployment = GQLIdentifiable & {
  /** Information about the employee's availabilities and unavailabilities during the course of the shift */
  availabilityInfo: GQLAvailabilityInfo;
  /** Number of annual bring shift hours */
  bringShiftHours: Maybe<Scalars['Float']>;
  /** Number of bring shift hours worked */
  bringShiftHoursUsed: Maybe<Scalars['Float']>;
  conflicts: Maybe<Array<GQLShiftConflict>>;
  /** Number of contracted minutes */
  contractMinutes: Maybe<Scalars['Float']>;
  employment: GQLEmployment;
  /** Is there a conflict with an existing absence or shift assignment? */
  hasConflicts: Scalars['Boolean'];
  /** Hour account balance */
  hourAccountHours: Maybe<Scalars['Float']>;
  /** Unique identifier of the Employment */
  id: Scalars['Int'];
  /** Is there a join request already pending? */
  joinRequest: Maybe<GQLRequest>;
  /** Maximum earnings per month */
  maximumMoney: Maybe<Scalars['Float']>;
  /** Number of scheduled minutes */
  scheduledMinutes: Maybe<Scalars['Float']>;
  /** Expected payment for the scheduled shifts */
  scheduledMoney: Maybe<Scalars['Float']>;
  /** Unique identifier of the Shift */
  shiftId: Scalars['Int'];
  /** Number of actually worked minutes */
  workedMinutes: Maybe<Scalars['Float']>;
};

export type GQLAvailabilityInfo = {
  /** Availabilities during the course of the shift */
  available: Maybe<Array<GQLShiftEmploymentAvailability>>;
  /** Is the employee either available or unavailable for the entire shift? */
  complete: Scalars['Boolean'];
  /** Is the employee available for the entire shift? */
  fullAvailable: Scalars['Boolean'];
  /** Is the employee available for the entire shift? */
  fullUnavailable: Scalars['Boolean'];
  /** Is the employee only partially available for the shift? */
  partialAvailable: Scalars['Boolean'];
  /** Is the employee only partially unavailable for the shift? */
  partialUnavailable: Scalars['Boolean'];
  /** Unavailabilities during the course of the shift */
  unavailable: Maybe<Array<GQLShiftEmploymentAvailability>>;
};

export type GQLShiftEmploymentAvailability = {
  /** Date when the availability or unavailability will end or ended */
  endsAt: Scalars['Date'];
  /** Date when the availability or unavailability will start or started */
  startsAt: Scalars['Date'];
};

export type GQLShiftConflict =
  | GQLAbsencesCollisionShiftConflict
  | GQLAlreadyAssignedShiftConflict
  | GQLInsideUnavailableTimeShiftConflict
  | GQLLocationsPositionChangedShiftConflict
  | GQLMaximumConsecutiveWorkingDaysShiftConflict
  | GQLMaximumEarningShiftConflict
  | GQLMaximumWorkingHoursShiftConflict
  | GQLMinimumBreakShiftConflict
  | GQLMinimumRestShiftConflict
  | GQLOutsideAvailableTimeShiftConflict
  | GQLOutsideWorkPeriodShiftConflict
  | GQLRestrictedShiftPresetsConflict
  | GQLShiftsCollisionShiftConflict
  | GQLUnknownShiftConflict;

export type GQLAbsencesCollisionShiftConflict = {
  /** Absences that cause the collision */
  absences: Maybe<Array<Maybe<GQLAbsence>>>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
};

export type GQLAlreadyAssignedShiftConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
};

export type GQLInsideUnavailableTimeShiftConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
};

export type GQLLocationsPositionChangedShiftConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
};

export type GQLMaximumConsecutiveWorkingDaysShiftConflict = {
  /** Name of employment */
  employmentName: Maybe<Scalars['String']>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Allowed maximum set in company settings */
  maxDays: Scalars['Int'];
  /** Message from conflict */
  message: Scalars['String'];
};

export type GQLMaximumEarningShiftConflict = {
  /** Currency used by the Company */
  currency: Scalars['String'];
  /** Maximum allowed amount */
  employmentMaxPayment: Scalars['Float'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
  /** Total amount to be paid when shift assigned */
  totalPayment: Scalars['Float'];
};

export type GQLMaximumWorkingHoursShiftConflict = {
  /** Total overwork in minutes for the Employee */
  difference: Scalars['Float'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
  /** Month for which the exceedance would occur */
  month: Maybe<Scalars['Int']>;
  /** Assigned monthly value to Employee */
  monthlyMinutes: Maybe<Scalars['Int']>;
  /** Full name of the Employee concerned */
  name: Maybe<Scalars['String']>;
  /** Total minutes with the shift */
  total: Maybe<Scalars['Float']>;
  /** Week for which the exceedance would occur */
  week: Maybe<Scalars['Int']>;
  /** Assigned weekly value to Employee */
  weeklyMinutes: Maybe<Scalars['Int']>;
  /** Year for which the exceedance would occur */
  year: Maybe<Scalars['Int']>;
};

export type GQLMinimumBreakShiftConflict = {
  /** Duration in minutes */
  duration: Maybe<Scalars['Int']>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
  /** Minimum break time in minutes */
  minBreakTime: Scalars['Int'];
  /** Shift duration in minutes */
  shiftDuration: Maybe<Scalars['Int']>;
};

export type GQLMinimumRestShiftConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Previous shift end date */
  lastShiftEndsAt: Scalars['Date'];
  /** Message from conflict */
  message: Scalars['String'];
  /** Minimum rest time in minutes */
  minimumRestMinutes: Scalars['Int'];
  /** New shift start date */
  newShiftStartsAt: Scalars['Date'];
  /** Actual rest time in minutes */
  restMinutes: Scalars['Int'];
};

export type GQLOutsideAvailableTimeShiftConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
};

export type GQLOutsideWorkPeriodShiftConflict = {
  /** End of work period */
  endsAt: Maybe<Scalars['Date']>;
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
  /** Start of work period */
  startsAt: Maybe<Scalars['Date']>;
};

export type GQLRestrictedShiftPresetsConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
  restrictedShiftPresets: Array<Scalars['String']>;
};

export type GQLShiftsCollisionShiftConflict = {
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from conflict */
  message: Scalars['String'];
  /** Shifts that cause the collision */
  shifts: Maybe<Array<Maybe<GQLShift>>>;
};

export type GQLUnknownShiftConflict = {
  /** Conflict key */
  conflictKey: Scalars['String'];
  /** Original conflict data */
  data: Scalars['Json'];
  /** Can conflicts be ignored? */
  ignorable: Scalars['Boolean'];
  /** Message from original conflict */
  message: Scalars['String'];
};

export type GQLShiftBreakCollection = GQLPaginatable & {
  items: Array<GQLShiftBreak>;
  pagination: GQLPaginationInfo;
};

export type GQLTagCollection = GQLPaginatable & {
  items: Array<GQLTag>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftPaygradeCollection = GQLPaginatable & {
  items: Array<GQLShiftPaygrade>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftplanNotifications = {
  count: Scalars['Int'];
};

export type GQLShiftplanCollection = GQLPaginatable & {
  items: Array<GQLShiftplan>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftPresetCollection = GQLPaginatable & {
  items: Array<GQLShiftPreset>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftRotationGroupCollection = GQLPaginatable & {
  items: Array<GQLShiftRotationGroup>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftRotationCollection = GQLPaginatable & {
  items: Array<GQLShiftRotation>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftCollection = GQLPaginatable & {
  items: Array<GQLShift>;
  pagination: GQLPaginationInfo;
};

export type GQLShiftsTagCollection = GQLPaginatable & {
  items: Array<GQLShiftsTag>;
  pagination: GQLPaginationInfo;
};

/** A ShiftsTag */
export type GQLShiftsTag = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the ShiftsTag was created */
    createdAt: Scalars['Date'];
    /** Date when the ShiftsTag was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the ShiftTag */
    id: Scalars['Int'];
    /** Shift associated with the ShiftsTag */
    shift: GQLShift;
    /** Tag associated with the ShiftsTag */
    tag: GQLTag;
    /** Date when the ShiftsTag was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLSpecialDateCollection = GQLPaginatable & {
  items: Array<GQLSpecialDate>;
  pagination: GQLPaginationInfo;
};

/** A SpecialDate */
export type GQLSpecialDate = GQLIdentifiable &
  GQLTimestampable & {
    /** Does this SpecialDate apply to every location of the company? */
    allLocations: Scalars['Boolean'];
    /** Company associated with SpecialDate */
    company: GQLCompany;
    /** Date when the SpecialDate was created */
    createdAt: Scalars['Date'];
    /** Date when the SpecialDate was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Time when that SpecialDate ends or null if fullDay is true */
    endTime: Maybe<Scalars['String']>;
    /** Is this a full-day SpecialDate? */
    fullDay: Scalars['Boolean'];
    /** Unique identifier of the SpecialDate */
    id: Scalars['Int'];
    /** Is this SpecialDate counted towards the employee’s working hours? */
    isIncludedInHourAccount: Scalars['Boolean'];
    /** Array of Location to which the SpecialDate is applies (empty if allLocations is true) */
    locations: Array<GQLLocation>;
    /** Name of SpecialDate */
    name: Scalars['String'];
    /** Start time for a Shift with this ShiftPreset */
    specialDate: Scalars['Date'];
    /** Time when that SpecialDate starts or null if fullDay is true */
    startTime: Maybe<Scalars['String']>;
    /** Date when the SpecialDate was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLStaffShiftCollection = GQLPaginatable & {
  items: Array<GQLStaffShift>;
  pagination: GQLPaginationInfo;
};

export type GQLStaffShiftsTagCollection = GQLPaginatable & {
  items: Array<GQLStaffShiftsTag>;
  pagination: GQLPaginationInfo;
};

/**
 * Translations demonstrate key-value pairs that can be added to a custom Language. In order to use
 * translations a custom Language must be created.
 * While the key for the translation is a given value, the translation is language-specific. This way
 * it is possible to generate a new dictionary for the shyftplan software or customize terms and
 * definitions according to the company's internal structure.
 */
export type GQLTranslation = {
  /** Key of the translated string */
  key: Scalars['String'];
  /** ID of the associated Language */
  languageId: Scalars['Int'];
  /** Translation of the string */
  value: Scalars['String'];
};

export type GQLUserCollection = GQLPaginatable & {
  items: Array<GQLUser>;
  pagination: GQLPaginationInfo;
};

export type GQLMutation = {
  _empty: Maybe<Scalars['String']>;
  /** Accept EmploymentEvaluation */
  acceptEmploymentEvaluation: GQLEvaluationCreateResult;
  /** Accept a ReplaceRequest */
  acceptReplaceRequest: Maybe<GQLAcceptReplaceRequestResult>;
  /** Add an Employment to a Position */
  addEmploymentToPosition: Maybe<Scalars['Json']>;
  /** Add Tag to Shift */
  addTagToShift: GQLShiftTag;
  /** Apply a ShiftRotation to an existing Shiftplan */
  applyShiftplanRotation: GQLBackgroundJobResponse;
  /** Approve an Absence */
  approveAbsence: Maybe<GQLAbsenceApproveResult>;
  /**
   * Assign an employment to shifts with ShiftRotationGroup
   * @deprecated Use assignEmploymentToShiftRotationGroup
   */
  assignEmployeeToShiftRotationGroup: GQLBackgroundJob;
  /** Start automation for existing Shiftplan to assign employees to open shifts */
  assignEmploymentsToOpenShifts: GQLBackgroundJob;
  /** Assign an Employment to the Shifts of an AssignmentGroup */
  assignEmploymentToAssignmentGroup: GQLAssignEmploymentCreateResponse;
  /** Assign an employment to shifts with ShiftRotationGroup */
  assignEmploymentToShiftRotationGroup: GQLBackgroundJob;
  /** Assign PaymentManageRight for Location to Employment */
  assignLocationPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentShowRight for Location to Employment */
  assignLocationPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftManageRight for Location to Employment */
  assignLocationShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftShowRight for Location to Employment */
  assignLocationShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentManageRight for LocationsPosition to Employment */
  assignLocationsPositionPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Assign PaymentShowRight for LocationsPosition to Employment */
  assignLocationsPositionPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftManageRight for LocationsPosition to Employment */
  assignLocationsPositionShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Assign ShiftShowRight for LocationsPosition to Employment */
  assignLocationsPositionShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Assign Right to Employment */
  assignRight: Maybe<Scalars['Boolean']>;
  /** Confirm a ReplaceRequest */
  confirmReplaceRequest: Maybe<GQLConfirmReplaceRequestResult>;
  /** Connect a collection of Shifts */
  connectShifts: GQLSuccessResponse;
  /** Copy Shift to another date in the Shiftplan */
  copyShift: GQLShiftCreateResult;
  /** Copy an existing Shiftplan */
  copyShiftplan: GQLBackgroundJob;
  /** Create a new Absence */
  createAbsence: Maybe<GQLAbsenceCreateResult>;
  /** Create a new AssignmentGroup */
  createAssignmentGroup: GQLAssignmentGroup;
  /** Create a new Availability */
  createAvailability: Maybe<GQLAvailabilityCreateResult>;
  /** Create a new AvailabilityException */
  createAvailabilityException: Maybe<GQLAvailabilityException>;
  /** Create a new CustomField */
  createCustomField: Maybe<GQLCustomField>;
  /** Create a new DayNote */
  createDayNote: GQLDayNote;
  /** Create a new EmploymentEvaluation */
  createEmploymentEvaluation: GQLEmployeeEvaluationCreateResult;
  /** Create a new EvaluationBreak */
  createEmploymentEvaluationBreak: GQLEvaluationBreak;
  /** Create a new Payment for an EmploymentEvaluation */
  createEmploymentEvaluationPayment: GQLPayment;
  /** Create a new EmploymentEvaluationTag */
  createEmploymentEvaluationTag: GQLEmploymentEvaluationTag;
  /** Create a new Paygrade */
  createEmploymentPaygrade: Maybe<GQLEmploymentPaygrade>;
  /** Create a new EmploymentsPosition */
  createEmploymentsPosition: Maybe<GQLEmploymentsPosition>;
  /** Create a new EmploymentsRotationGroup */
  createEmploymentsRotationGroup: GQLEmploymentsRotationGroup;
  /** Create an Evaluation */
  createEvaluation: GQLEvaluationCreateResult;
  /** Create a new EvaluationBreak */
  createEvaluationBreak: GQLEvaluationBreak;
  /** Create a new Payment for an Evaluation */
  createEvaluationPayment: GQLPayment;
  /** Create a new IndividualShiftRotation */
  createIndividualShiftRotation: GQLIndividualShiftRotation;
  /** Create a new JoinRequest */
  createJoinRequest: Maybe<GQLRequestState>;
  /** Create a new LeaveRequest */
  createLeaveRequest: Maybe<GQLCreateResponse>;
  /** Create a new Location */
  createLocation: Maybe<GQLLocation>;
  /** Create a new LocationsPosition */
  createLocationsPosition: Maybe<GQLLocationsPosition>;
  /** Create a new Newsfeed */
  createNewsfeed: GQLCreateResponse;
  /** Create a new Position */
  createPosition: Maybe<GQLPosition>;
  /** Create a new Shift by punching in */
  createPunchingShift: Maybe<GQLCreatePunchingShiftResult>;
  /** Create a new ReplaceRequest */
  createReplaceRequest: Maybe<GQLRequest>;
  /** Create a new Shift */
  createShift: GQLShiftCreateResult;
  /** Create a new ShiftAssignmentGroup (i.e. add a Shift to an AssignmentGroup) */
  createShiftAssignmentGroup: GQLShiftAssignmentGroup;
  /** Adds a break to a shift */
  createShiftBreak: GQLShiftBreak;
  /** Create a new EvaluationTag */
  createShiftEvaluationTag: GQLShiftEvaluationTag;
  /** Create a new ShiftPaygrade */
  createShiftPaygrade: GQLShiftPaygrade;
  /** Create a new Shiftplan */
  createShiftplan: GQLShiftplan;
  /** Create a new Shiftplan based on an existing ShiftRotation */
  createShiftplanFromShiftRotation: GQLBackgroundJobResponse;
  /** Send message to employees from an existing Shiftplan */
  createShiftplanMessage: GQLSuccessResponse;
  /** Send notification to employees from an existing Shiftplan */
  createShiftplanNotification: GQLSuccessResponse;
  /** Create a new ShiftRotation */
  createShiftRotation: GQLShiftRotation;
  /** Create a new ShiftRotationGroup */
  createShiftRotationGroup: GQLShiftRotationGroup;
  /** Create a new SpecialDate */
  createSpecialDate: GQLSpecialDate;
  /** Create a new StaffShift */
  createStaffShift: GQLStaffShiftCreateResult;
  /** Create a new StaffShiftsTag */
  createStaffShiftsTag: GQLStaffShiftsTag;
  /** Create a new Tag */
  createTag: GQLTag;
  /** Create a new Translation */
  createTranslation: Maybe<GQLTranslation>;
  /** Create a new User */
  createUser: Maybe<GQLUser>;
  /** Decline an Absence */
  declineAbsence: GQLSuccessResponse;
  /** Remove an existing Absence */
  deleteAbsence: Maybe<GQLSuccessResponse>;
  /** Remove an existing Availability */
  deleteAvailability: Maybe<Scalars['Boolean']>;
  /** Remove an existing AvailabilityException */
  deleteAvailabilityException: Maybe<Scalars['Boolean']>;
  /** Remove an existing CustomField */
  deleteCustomField: Maybe<Scalars['Boolean']>;
  /** Delete EmploymentEvaluation */
  deleteEmploymentEvaluation: GQLSuccessResponse;
  /** Delete an existing EvaluationBreak */
  deleteEmploymentEvaluationBreak: GQLEvaluationBreak;
  /** Remove an existing Payment of an EmploymentEvaluation */
  deleteEmploymentEvaluationPayment: GQLSuccessResponse;
  /** Remove an existing EmploymentEvaluationTag */
  deleteEmploymentEvaluationTag: GQLSuccessResponse;
  /** Remove an existing EmploymentsPosition */
  deleteEmploymentsPosition: Maybe<Scalars['Boolean']>;
  /** Remove an existing EmploymentsRotationGroup */
  deleteEmploymentsRotationGroup: GQLSuccessResponse;
  /** Delete an existing EvaluationBreak */
  deleteEvaluationBreak: GQLSuccessResponse;
  /** Remove an existing Payment of an Evaluation */
  deleteEvaluationPayment: GQLSuccessResponse;
  /** Remove an existing IndividualShiftRotation */
  deleteIndividualShiftRotation: GQLSuccessResponse;
  /** Remove an existing Location (soft delete) */
  deleteLocation: Maybe<Scalars['Boolean']>;
  /** Remove an existing LocationsPosition */
  deleteLocationsPosition: Maybe<Scalars['Boolean']>;
  /** Remove an existing Position (soft delete) */
  deletePosition: Maybe<Scalars['Boolean']>;
  /** Remove an existing Request */
  deleteRequest: Maybe<GQLSuccessResponse>;
  /** Remove an existing Shift */
  deleteShift: GQLSuccessResponse;
  /** Remove an existing ShiftAssignmentGroup (i.e. remove a Shift from an AssignmentGroup) */
  deleteShiftAssignmentGroup: GQLSuccessResponse;
  /** Remove an existing EvaluationTag */
  deleteShiftEvaluationTag: GQLSuccessResponse;
  /** Remove an existing Shiftplan */
  deleteShiftplan: GQLSuccessResponse;
  /** Remove an existing ShiftRotation */
  deleteShiftRotation: GQLSuccessResponse;
  /** Remove an existing ShiftRotationGroup */
  deleteShiftRotationGroup: GQLSuccessResponse;
  /** Remove an existing StaffShift */
  deleteStaffShift: GQLSuccessResponse;
  /** Remove an existing StaffShiftsTag */
  deleteStaffShiftsTag: GQLSuccessResponse;
  /** Remove an existing Tag */
  deleteTag: GQLSuccessResponse;
  /** Remove an existing Translation */
  deleteTranslation: Maybe<Scalars['Boolean']>;
  /** Permanently remove an existing Employment */
  destroyEmployment: Maybe<Scalars['Json']>;
  /** Permanently remove an existing Location */
  destroyLocation: Maybe<Scalars['Boolean']>;
  /** Permanently remove an existing Position */
  destroyPosition: Maybe<Scalars['Boolean']>;
  /** Disconnect a connected Shift */
  disconnectShift: GQLSuccessResponse;
  /** Record the ending of a break */
  endBreak: Maybe<GQLPunchBreak>;
  /** Import shifts from Shiftplan to another existing Shiftplan */
  importShiftplan: GQLBackgroundJob;
  /** Perform the login using the provided email and password */
  login: Maybe<GQLLogin>;
  moveStaffShift: GQLStaffShiftCreateResult;
  /** Publish an existing Shiftplan */
  publishShiftplan: GQLSuccessResponse;
  /** Record punching in */
  punchIn: Maybe<Scalars['Boolean']>;
  /** Record punching out */
  punchOut: Maybe<GQLPunchOutResult>;
  /** Remove an existing AssignmentGroup */
  removeAssignmentGroup: GQLSuccessResponse;
  /** Remove an existing DayNote */
  removeDayNote: GQLSuccessResponse;
  /** Remove an existing Employment (soft delete) */
  removeEmployment: Maybe<Scalars['Json']>;
  /** Remove an Employment from a Position */
  removeEmploymentFromPosition: Maybe<Scalars['Json']>;
  /** Remove an existing Paygrade */
  removeEmploymentPaygrade: Maybe<Scalars['Json']>;
  /** Remove PaymentManageRight for Location from Employment */
  removeLocationPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentShowRight for Location from Employment */
  removeLocationPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftManageRight for Location from Employment */
  removeLocationShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftShowRight for Location from Employment */
  removeLocationShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentManageRight for LocationsPosition from Employment */
  removeLocationsPositionPaymentManageRight: Maybe<Scalars['Boolean']>;
  /** Remove PaymentShowRight for LocationsPosition from Employment */
  removeLocationsPositionPaymentShowRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftManageRight for LocationsPosition from Employment */
  removeLocationsPositionShiftManageRight: Maybe<Scalars['Boolean']>;
  /** Remove ShiftShowRight for LocationsPosition from Employment */
  removeLocationsPositionShiftShowRight: Maybe<Scalars['Boolean']>;
  /** Remove Right from Employment */
  removeRight: Maybe<Scalars['Boolean']>;
  /** Remove an existing ShiftPaygrade */
  removeShiftPaygrade: GQLSuccessResponse;
  /** Remove an existing SpecialDate */
  removeSpecialDate: GQLSuccessResponse;
  /** Remove Tag from Shift */
  removeTagFromShift: GQLShiftTag;
  /** Replace employee in staff shift */
  replaceStaffShift: GQLStaffShiftCreateResult;
  /** Restore a soft-deleted Employment */
  restoreEmployment: Maybe<GQLEmployment>;
  /** Send a chat message */
  sendChatMessage: Maybe<GQLChatMessage>;
  /** Record the starting of a break */
  startBreak: Maybe<GQLPunchBreak>;
  /** Create a new PunchTiming session */
  startPunchTimeSession: Maybe<GQLPunchtimeSession>;
  /** Unassign an Employment from the Shifts of an AssignmentGroup */
  unassignEmploymentFromAssignmentGroup: GQLAssignEmploymentCreateResponse;
  /** Unassign an employment from shifts with ShiftRotationGroup */
  unassignEmploymentFromShiftRotationGroup: GQLBackgroundJob;
  /** Update an existing Absence */
  updateAbsence: Maybe<GQLAbsenceCreateResult>;
  /** Update absence entitlements for the employee */
  updateAbsenceEntitlement: GQLAbsenceEntitlement;
  /** Update an existing AssignmentGroup */
  updateAssignmentGroup: GQLAssignmentGroup;
  /** Update an existing Availability */
  updateAvailability: Maybe<GQLAvailabilityCreateResult>;
  /** Update an existing CustomField */
  updateCustomField: Maybe<GQLCustomField>;
  /** Update an existing DayNote */
  updateDayNote: GQLDayNote;
  /** Update an existing EvaluationBreak */
  updateEmploymentEvaluationBreak: GQLEvaluationBreak;
  /** Update an existing Payment of an EmploymentEvaluation */
  updateEmploymentEvaluationPayment: GQLPayment;
  /** Update an existing Paygrade */
  updateEmploymentPaygrade: Maybe<GQLEmploymentPaygrade>;
  /** Update a new EmploymentsRotationGroup */
  updateEmploymentsRotationGroup: GQLEmploymentsRotationGroup;
  /** Update an existing EmploymentsSurvey */
  updateEmploymentsSurvey: Maybe<GQLEmploymentsSurveyCollection>;
  /** Update an existing EmploymentsUiSetting */
  updateEmploymentsUiSetting: GQLEmploymentsUiSetting;
  /** Update an existing Evaluation */
  updateEvaluation: GQLSuccessResponse;
  /** Update an existing EvaluationBreak */
  updateEvaluationBreak: GQLEvaluationBreak;
  /** Update an existing Payment of an Evaluation */
  updateEvaluationPayment: GQLPayment;
  /** Update an existing IndividualShiftRotation */
  updateIndividualShiftRotation: GQLIndividualShiftRotation;
  /** Update an existing Location */
  updateLocation: Maybe<GQLLocation>;
  /** Update location Shiftplan publish settings */
  updateLocationShiftplanPublishSetting: GQLLocationShiftplanPublishSettings;
  /** Update an existing NotificationConfiguration */
  updateNotificationConfiguration: Maybe<Scalars['Boolean']>;
  /** Update an existing Position */
  updatePosition: Maybe<GQLPosition>;
  /** Update an existing Shift */
  updateShift: GQLShiftCreateResult;
  /** Update an existing ShiftPaygrade */
  updateShiftPaygrade: GQLShiftPaygrade;
  /** Update paygrades for Shift */
  updateShiftPaygrades: Array<GQLShiftPaygrade>;
  /** Update an existing Shiftplan */
  updateShiftplan: GQLShiftplan;
  /** Update an existing ShiftRotation */
  updateShiftRotation: GQLShiftRotation;
  /** Update an existing ShiftRotationGroup */
  updateShiftRotationGroup: GQLShiftRotationGroup;
  /** Update an existing SpecialDate */
  updateSpecialDate: GQLSpecialDate;
  /** Update an existing Tag */
  updateTag: GQLTag;
  /** Update an existing Translation */
  updateTranslation: Maybe<GQLTranslation>;
  /** Update an existing User */
  updateUser: Maybe<GQLUser>;
  /** Withdrawal of an absence request by an employee */
  withdrawAbsence: GQLSuccessResponse;
  /** Accept a ReplaceRequest */
  withdrawAcceptedReplaceRequest: Maybe<GQLPendingReplaceRequest>;
};

export type GQLMutationAcceptEmploymentEvaluationArgs = {
  companyId: Scalars['Int'];
  ignoreConflicts: Scalars['Boolean'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationAcceptReplaceRequestArgs = {
  request: GQLAcceptReplaceRequestInput;
};

export type GQLMutationAddEmploymentToPositionArgs = {
  employmentId: Scalars['Int'];
  locationId: Scalars['Int'];
  positionId: Scalars['Int'];
};

export type GQLMutationAddTagToShiftArgs = {
  id: InputMaybe<Scalars['Int']>;
  params: GQLShiftTagInput;
};

export type GQLMutationApplyShiftplanRotationArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLApplyShiftplanRotationInput;
};

export type GQLMutationApproveAbsenceArgs = {
  id: Scalars['Int'];
  params: InputMaybe<GQLAbsenceApproveInput>;
};

export type GQLMutationAssignEmployeeToShiftRotationGroupArgs = {
  companyId: Scalars['Int'];
  shiftRotationGroupAssignInput: GQLShiftRotationGroupAssignInput;
};

export type GQLMutationAssignEmploymentsToOpenShiftsArgs = {
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
};

export type GQLMutationAssignEmploymentToAssignmentGroupArgs = {
  assignEmploymentParams: GQLAssignmentGroupAssignEmploymentInput;
  assignmentGroupId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type GQLMutationAssignEmploymentToShiftRotationGroupArgs = {
  companyId: Scalars['Int'];
  shiftRotationGroupAssignInput: GQLShiftRotationGroupAssignInput;
};

export type GQLMutationAssignLocationPaymentManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationPaymentShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationShiftManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationShiftShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionPaymentManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionPaymentShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionShiftManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignLocationsPositionShiftShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationAssignRightArgs = {
  right: InputMaybe<GQLRightChangeInput>;
};

export type GQLMutationConfirmReplaceRequestArgs = {
  request: GQLConfirmReplaceRequestInput;
};

export type GQLMutationConnectShiftsArgs = {
  shiftIds: Array<Scalars['Int']>;
  shiftplanId: Scalars['Int'];
};

export type GQLMutationCopyShiftArgs = {
  companyId: Scalars['Int'];
  copyParams: GQLShiftCopyInput;
};

export type GQLMutationCopyShiftplanArgs = {
  companyId: Scalars['Int'];
  copyShiftplanParams: GQLShiftplanCopyInput;
};

export type GQLMutationCreateAbsenceArgs = {
  absence: GQLAbsenceInput;
  forceCollision?: InputMaybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: InputMaybe<Scalars['Boolean']>;
};

export type GQLMutationCreateAssignmentGroupArgs = {
  assignmentGroup: GQLAssignmentGroupCreateInput;
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
};

export type GQLMutationCreateAvailabilityArgs = {
  availability: GQLAvailabilityCreateInput;
};

export type GQLMutationCreateAvailabilityExceptionArgs = {
  availabilityException: InputMaybe<GQLAvailabilityExceptionInput>;
};

export type GQLMutationCreateCustomFieldArgs = {
  customField: GQLCustomFieldCreateInput;
};

export type GQLMutationCreateDayNoteArgs = {
  companyId: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
  shiftplanId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentEvaluationArgs = {
  companyId: Scalars['Int'];
  employmentEvaluation: GQLEvaluationInput;
  staffShiftId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
  staffShiftId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentEvaluationPaymentArgs = {
  companyId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
  staffShiftId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentEvaluationTagArgs = {
  companyId: Scalars['Int'];
  evaluationId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationCreateEmploymentPaygradeArgs = {
  employmentId: Scalars['Int'];
  paygrade: GQLEmploymentPaygradeCreateInput;
};

export type GQLMutationCreateEmploymentsPositionArgs = {
  employmentsPosition: GQLEmploymentsPositionInput;
};

export type GQLMutationCreateEmploymentsRotationGroupArgs = {
  employmentsRotationGroup: GQLEmploymentsRotationGroupCreateInput;
};

export type GQLMutationCreateEvaluationArgs = {
  companyId: Scalars['Int'];
  evaluation: GQLEvaluationInput;
  staffShiftId: Scalars['Int'];
};

export type GQLMutationCreateEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
  staffShiftId: Scalars['Int'];
};

export type GQLMutationCreateEvaluationPaymentArgs = {
  payment: GQLPaymentCreateInput;
  staffShiftId: Scalars['Int'];
};

export type GQLMutationCreateIndividualShiftRotationArgs = {
  individualShiftRotation: GQLIndividualShiftRotationCreateInput;
};

export type GQLMutationCreateJoinRequestArgs = {
  request: GQLOverridableRequestInput;
};

export type GQLMutationCreateLeaveRequestArgs = {
  request: GQLRequestInput;
};

export type GQLMutationCreateLocationArgs = {
  location: GQLLocationCreateInput;
};

export type GQLMutationCreateLocationsPositionArgs = {
  locationsPosition: InputMaybe<GQLLocationsPositionCreateInput>;
};

export type GQLMutationCreateNewsfeedArgs = {
  newsfeed: InputMaybe<GQLNewsfeedInput>;
};

export type GQLMutationCreatePositionArgs = {
  position: GQLPositionCreateInput;
};

export type GQLMutationCreatePunchingShiftArgs = {
  punchingShift: InputMaybe<GQLCreatePunchingShiftInput>;
};

export type GQLMutationCreateReplaceRequestArgs = {
  request: GQLRequestInput;
};

export type GQLMutationCreateShiftArgs = {
  shift: GQLShiftCreateInput;
};

export type GQLMutationCreateShiftAssignmentGroupArgs = {
  assignmentGroupId: Scalars['Int'];
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationCreateShiftBreakArgs = {
  shiftBreak: GQLShiftBreakCreateInput;
  shiftId: Scalars['Int'];
};

export type GQLMutationCreateShiftEvaluationTagArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationCreateShiftPaygradeArgs = {
  companyId: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
  shiftId: Scalars['Int'];
};

export type GQLMutationCreateShiftplanArgs = {
  shiftplan: GQLShiftplanCreateInput;
};

export type GQLMutationCreateShiftplanFromShiftRotationArgs = {
  companyId: Scalars['Int'];
  input: GQLShiftRotationShiftplanCreateInput;
  shiftRotationId: Scalars['Int'];
};

export type GQLMutationCreateShiftplanMessageArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateMessageInput;
};

export type GQLMutationCreateShiftplanNotificationArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateNotificationInput;
};

export type GQLMutationCreateShiftRotationArgs = {
  shiftRotation: GQLShiftRotationCreateInput;
};

export type GQLMutationCreateShiftRotationGroupArgs = {
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
};

export type GQLMutationCreateSpecialDateArgs = {
  companyId: Scalars['Int'];
  specialDate: GQLSpecialDateCreateInput;
};

export type GQLMutationCreateStaffShiftArgs = {
  companyId: Scalars['Int'];
  staffShift: GQLStaffShiftCreateInput;
};

export type GQLMutationCreateStaffShiftsTagArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationCreateTagArgs = {
  tag: GQLTagCreateInput;
};

export type GQLMutationCreateTranslationArgs = {
  translation: InputMaybe<GQLTranslationCreateInput>;
};

export type GQLMutationCreateUserArgs = {
  user: GQLUserCreateInput;
};

export type GQLMutationDeclineAbsenceArgs = {
  id: Scalars['Int'];
  params: InputMaybe<GQLAbsenceDeclineInput>;
};

export type GQLMutationDeleteAbsenceArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteAvailabilityArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteAvailabilityExceptionArgs = {
  id: InputMaybe<Scalars['Int']>;
};

export type GQLMutationDeleteCustomFieldArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentEvaluationArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentEvaluationPaymentArgs = {
  companyId: Scalars['Int'];
  parentPaymentId: InputMaybe<Scalars['Int']>;
  paymentId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentEvaluationTagArgs = {
  companyId: Scalars['Int'];
  evaluationId: Scalars['Int'];
  evaluationTagId: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentsPositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteEmploymentsRotationGroupArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationDeleteEvaluationPaymentArgs = {
  parentPaymentId: InputMaybe<Scalars['Int']>;
  paymentId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationDeleteIndividualShiftRotationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteLocationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteLocationsPositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeletePositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteRequestArgs = {
  id: Scalars['Int'];
  request: GQLRequestInput;
};

export type GQLMutationDeleteShiftArgs = {
  companyId: Scalars['Int'];
  deleteParams: GQLShiftDeleteInput;
  id: Scalars['Int'];
};

export type GQLMutationDeleteShiftAssignmentGroupArgs = {
  assignmentGroupId: Scalars['Int'];
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationDeleteShiftEvaluationTagArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationDeleteShiftplanArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationDeleteShiftRotationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteShiftRotationGroupArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDeleteStaffShiftArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  unassignFromConnected?: Scalars['Boolean'];
};

export type GQLMutationDeleteStaffShiftsTagArgs = {
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type GQLMutationDeleteTagArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
};

export type GQLMutationDeleteTranslationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDestroyEmploymentArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationDestroyLocationArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDestroyPositionArgs = {
  id: Scalars['Int'];
};

export type GQLMutationDisconnectShiftArgs = {
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationEndBreakArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  endTime: InputMaybe<Scalars['Date']>;
  punchBreakId: Scalars['Int'];
  punchTimingId: Scalars['Int'];
};

export type GQLMutationImportShiftplanArgs = {
  companyId: Scalars['Int'];
  importShiftplanParams: GQLShiftplanImportInput;
};

export type GQLMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type GQLMutationMoveStaffShiftArgs = {
  staffShift: GQLStaffShiftMoveInput;
};

export type GQLMutationPublishShiftplanArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanPublishInput;
};

export type GQLMutationPunchInArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  staffShiftId: Scalars['Int'];
  startTime: InputMaybe<Scalars['Date']>;
  userToken: Scalars['Int'];
};

export type GQLMutationPunchOutArgs = {
  additionalBreakTime: InputMaybe<Scalars['Float']>;
  companyId: InputMaybe<Scalars['Int']>;
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  endTime: InputMaybe<Scalars['Date']>;
  ignoreWarning: InputMaybe<Scalars['Boolean']>;
  punchTimingId: Scalars['Int'];
};

export type GQLMutationRemoveAssignmentGroupArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationRemoveDayNoteArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  shiftplanId: Scalars['Int'];
};

export type GQLMutationRemoveEmploymentArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationRemoveEmploymentFromPositionArgs = {
  employmentId: Scalars['Int'];
  keepFutureStaffShifts: InputMaybe<Scalars['Boolean']>;
  locationId: Scalars['Int'];
  positionId: Scalars['Int'];
};

export type GQLMutationRemoveEmploymentPaygradeArgs = {
  deletePaygradeConfig: InputMaybe<GQLEmploymentPaygradeDeleteInput>;
  employmentId: Scalars['Int'];
  paygradeId: Scalars['Int'];
};

export type GQLMutationRemoveLocationPaymentManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationPaymentShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationShiftManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationShiftShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionPaymentManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionPaymentShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionShiftManageRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveLocationsPositionShiftShowRightArgs = {
  right: InputMaybe<GQLRightSpecificChangeInput>;
};

export type GQLMutationRemoveRightArgs = {
  right: InputMaybe<GQLRightChangeInput>;
};

export type GQLMutationRemoveShiftPaygradeArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  shiftId: Scalars['Int'];
};

export type GQLMutationRemoveSpecialDateArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationRemoveTagFromShiftArgs = {
  id: InputMaybe<Scalars['Int']>;
  params: GQLShiftTagInput;
};

export type GQLMutationReplaceStaffShiftArgs = {
  staffShift: GQLStaffShiftReplaceInput;
};

export type GQLMutationRestoreEmploymentArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationSendChatMessageArgs = {
  chat: GQLChatMessageInput;
  companyId: Scalars['Int'];
};

export type GQLMutationStartBreakArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  punchTimingId: Scalars['Int'];
  startTime: InputMaybe<Scalars['Date']>;
};

export type GQLMutationStartPunchTimeSessionArgs = {
  companyId: InputMaybe<Scalars['Int']>;
};

export type GQLMutationUnassignEmploymentFromAssignmentGroupArgs = {
  assignmentGroupId: Scalars['Int'];
  companyId: Scalars['Int'];
  unassignEmploymentParams: GQLAssignmentGroupAssignEmploymentInput;
};

export type GQLMutationUnassignEmploymentFromShiftRotationGroupArgs = {
  companyId: Scalars['Int'];
  shiftRotationGroupUnassignInput: GQLShiftRotationGroupUnassignInput;
};

export type GQLMutationUpdateAbsenceArgs = {
  absence: GQLAbsenceInput;
  forceCollision?: InputMaybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type GQLMutationUpdateAbsenceEntitlementArgs = {
  absenceReasonId: Scalars['Int'];
  allocatedDays: Scalars['Float'];
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
  year: Scalars['Int'];
};

export type GQLMutationUpdateAssignmentGroupArgs = {
  assignmentGroup: GQLAssignmentGroupUpdateInput;
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationUpdateAvailabilityArgs = {
  availability: GQLAvailabilityUpdateInput;
  id: Scalars['Int'];
};

export type GQLMutationUpdateCustomFieldArgs = {
  customField: GQLCustomFieldUpdateInput;
  id: Scalars['Int'];
};

export type GQLMutationUpdateDayNoteArgs = {
  companyId: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
  id: Scalars['Int'];
  shiftplanId: Scalars['Int'];
};

export type GQLMutationUpdateEmploymentEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
  evaluationBreakId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationUpdateEmploymentEvaluationPaymentArgs = {
  companyId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
  paymentId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationUpdateEmploymentPaygradeArgs = {
  employmentId: Scalars['Int'];
  paygrade: InputMaybe<GQLEmploymentPaygradeUpdateInput>;
  paygradeId: Scalars['Int'];
};

export type GQLMutationUpdateEmploymentsRotationGroupArgs = {
  employmentsRotationGroup: GQLEmploymentsRotationGroupUpdateInput;
  id: Scalars['Int'];
  strategyToUnassignFromShifts: InputMaybe<GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy>;
};

export type GQLMutationUpdateEmploymentsSurveyArgs = {
  employmentsSurvey: GQLEmploymentsSurveyInput;
  id: Scalars['Int'];
};

export type GQLMutationUpdateEmploymentsUiSettingArgs = {
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
  settings: Scalars['String'];
  version: Scalars['String'];
};

export type GQLMutationUpdateEvaluationArgs = {
  didShow: Scalars['Boolean'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationUpdateEvaluationBreakArgs = {
  companyId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
  evaluationBreakId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationUpdateEvaluationPaymentArgs = {
  payment: GQLPaymentUpdateInput;
  paymentId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
};

export type GQLMutationUpdateIndividualShiftRotationArgs = {
  id: Scalars['Int'];
  individualShiftRotation: GQLIndividualShiftRotationCreateInput;
};

export type GQLMutationUpdateLocationArgs = {
  id: Scalars['Int'];
  location: GQLLocationUpdateInput;
};

export type GQLMutationUpdateLocationShiftplanPublishSettingArgs = {
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  publishSettingsParams: GQLPublishSettingsParams;
};

export type GQLMutationUpdateNotificationConfigurationArgs = {
  companyId: InputMaybe<Scalars['Int']>;
  employmentId: Scalars['Int'];
  notificationConfiguration: GQLNotificationConfigurationUpdateInput;
};

export type GQLMutationUpdatePositionArgs = {
  id: Scalars['Int'];
  position: GQLPositionUpdateInput;
};

export type GQLMutationUpdateShiftArgs = {
  id: Scalars['Int'];
  shift: GQLShiftUpdateInput;
};

export type GQLMutationUpdateShiftPaygradeArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
  shiftId: Scalars['Int'];
};

export type GQLMutationUpdateShiftPaygradesArgs = {
  companyId: Scalars['Int'];
  paygradeParams: GQLShiftPaygradesUpdateInput;
  shiftId: Scalars['Int'];
};

export type GQLMutationUpdateShiftplanArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  shiftplan: GQLShiftplanUpdateInput;
};

export type GQLMutationUpdateShiftRotationArgs = {
  id: Scalars['Int'];
  shiftRotation: GQLShiftRotationCreateInput;
};

export type GQLMutationUpdateShiftRotationGroupArgs = {
  id: Scalars['Int'];
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
};

export type GQLMutationUpdateSpecialDateArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  specialDate: GQLSpecialDateCreateInput;
};

export type GQLMutationUpdateTagArgs = {
  id: Scalars['Int'];
  tag: GQLTagUpdateInput;
};

export type GQLMutationUpdateTranslationArgs = {
  id: Scalars['Int'];
  translation: InputMaybe<GQLTranslationUpdateInput>;
};

export type GQLMutationUpdateUserArgs = {
  id: Scalars['Int'];
  user: GQLUserUpdateInput;
};

export type GQLMutationWithdrawAbsenceArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type GQLMutationWithdrawAcceptedReplaceRequestArgs = {
  request: GQLWithdrawAcceptedReplaceRequestInput;
};

export type GQLEvaluationCreateResult =
  | GQLEvaluation
  | GQLEvaluationConflictsResponse;

export type GQLEvaluationConflictsResponse = {
  conflicts: Array<GQLShiftConflict>;
  success: Scalars['Boolean'];
};

export type GQLAcceptReplaceRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Replace Request Request id */
  id: Scalars['Int'];
  /** Accept replace requests even when warnings are present */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
};

export type GQLAcceptReplaceRequestResult =
  | GQLPendingReplaceRequest
  | GQLRequestState;

export type GQLPendingReplaceRequest = {
  /** The employment ids of employees that accepted this request */
  acceptedBy: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The moment the change request was applied */
  createdAt: Maybe<Scalars['Date']>;
  /** The moment the change request was deleted */
  deletedAt: Maybe<Scalars['Date']>;
  /** The employment that offered their shift */
  employment: Maybe<GQLEmployment>;
  /** The id of the request. Is null when a replace request was auto accepted */
  id: Maybe<Scalars['Int']>;
  /** The referring request */
  request: Maybe<GQLRequest>;
  /** The staff shift that was created by applying the replace request */
  staffShift: Maybe<GQLStaffShift>;
  /** The state of the change request */
  state: Maybe<GQLReplaceRequestState>;
  /** The moment the change request was last changed */
  updatedAt: Maybe<Scalars['Date']>;
};

export type GQLRequestState = {
  /** Request conflicts */
  conflicts: Maybe<Array<Maybe<GQLShiftConflict>>>;
  /** Type of non-overridable collision */
  errorKey: Maybe<Scalars['String']>;
  /** Was request successful? */
  success: Scalars['Boolean'];
  /** Type of overridable collision */
  warning: Maybe<Scalars['String']>;
};

export type GQLShiftTagInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** ID of location */
  locationId: Scalars['Int'];
  /** ID of shift */
  shiftId: Scalars['Int'];
};

/** Type describing 1 to 1 relation between tag and shift */
export type GQLShiftTag = {
  id: Maybe<Scalars['Int']>;
  shift: Maybe<GQLShift>;
  tag: Maybe<GQLTag>;
};

export type GQLApplyShiftplanRotationInput = {
  /** ID of the ShiftRotation which should be assigned to the Shiftplan */
  shiftRotationId: Scalars['Int'];
  /** Array of number of employees which should be assigned to each Position of the ShiftRotation */
  workersPerPositionsList: Array<InputMaybe<GQLWorkersPerPosition>>;
};

export type GQLWorkersPerPosition = {
  /** ID of the Position */
  id: Scalars['Int'];
  /** Number of employees for the Position */
  workers: Scalars['Int'];
};

export type GQLBackgroundJobResponse = {
  id: Scalars['Int'];
  jobId: Scalars['String'];
};

export type GQLAbsenceApproveInput = {
  /** The company in which the absence should be approved */
  companyId: Scalars['Int'];
  /** Create Absence even when conflicts exist? */
  forceCollision: InputMaybe<Scalars['Boolean']>;
  /** Remove staff from conflicting Shifts? */
  forceCollisionAndRemove: InputMaybe<Scalars['Boolean']>;
};

export type GQLAbsenceApproveResult = GQLConflictsResponse | GQLCreateResponse;

export type GQLConflictsResponse = {
  canManage: Scalars['Boolean'];
  conflicts: Array<GQLConflict>;
  success: Scalars['Boolean'];
};

export type GQLConflict =
  | GQLCarryOverDeadlineErrorConflict
  | GQLCarryOverMultipleYearsConflict
  | GQLCollisionsConflict
  | GQLMaxDaysPerYearConflict
  | GQLOutsideEmploymentPeriodConflict
  | GQLUnknownConflict;

export type GQLCarryOverDeadlineErrorConflict = {
  deadline: Scalars['Date'];
  ignorable: Scalars['Boolean'];
};

export type GQLCarryOverMultipleYearsConflict = {
  ignorable: Scalars['Boolean'];
};

export type GQLCollisionsConflict = {
  absences: Maybe<Array<GQLAbsence>>;
  ignorable: Scalars['Boolean'];
  shifts: Maybe<Array<GQLShift>>;
};

export type GQLMaxDaysPerYearConflict = {
  after: Scalars['Float'];
  before: Scalars['Float'];
  ignorable: Scalars['Boolean'];
  limit: Scalars['Float'];
};

export type GQLOutsideEmploymentPeriodConflict = {
  exitDate: Maybe<Scalars['Date']>;
  ignorable: Scalars['Boolean'];
};

export type GQLUnknownConflict = {
  data: Maybe<Scalars['Json']>;
  ignorable: Scalars['Boolean'];
};

export type GQLCreateResponse = {
  success: Scalars['Boolean'];
};

export type GQLShiftRotationGroupAssignInput = {
  /** ID of employment to assign */
  employmentId: Scalars['Int'];
  /** Date to select the Shifts to */
  endDate: Scalars['Date'];
  /** Location Position ID */
  locationsPositionId: Scalars['Int'];
  /** ID of Shiftplan to assign */
  shiftplanId: Scalars['Int'];
  /** ID of the ShiftRotationGroup to assign */
  shiftRotationGroupId: Scalars['Int'];
  /** Date to select the Shifts from */
  startDate: Scalars['Date'];
};

export type GQLAssignmentGroupAssignEmploymentInput = {
  /** ID of the Employment */
  employmentId: Scalars['Int'];
  /** End date of the shifts to select till */
  endDate: Scalars['Date'];
  /** Flag to ignore conflicts */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** Start date of the shifts to select from */
  startDate: Scalars['Date'];
};

export type GQLAssignEmploymentCreateResponse = {
  shiftIds: Array<Maybe<Scalars['Int']>>;
  staffShiftIds: Array<Maybe<Scalars['Int']>>;
};

export type GQLRightSpecificChangeInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
};

export type GQLRightChangeInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Unique identifier of the Right */
  id: Scalars['Int'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
};

export type GQLConfirmReplaceRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of Employment that accepted the shift offer */
  employmentId: Scalars['Int'];
  /** Replace Request Request id */
  id: Scalars['Int'];
  /** Accept replace requests even when warnings are present */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
};

export type GQLConfirmReplaceRequestResult =
  | GQLPendingReplaceRequest
  | GQLRequestState;

export type GQLSuccessResponse = {
  error: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GQLShiftCopyInput = {
  /** Whether the new Shift should be connected to the original */
  connect: InputMaybe<Scalars['Boolean']>;
  /** Date to which the Shift should be copied (start and end times are the same as for the original Shift) */
  date: Scalars['Date'];
  /** Whether to ignore conflicts */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of Shift to be copied */
  sourceShiftId: Scalars['Int'];
};

export type GQLShiftCreateResult = GQLShift | GQLShiftConflictsResponse;

export type GQLShiftConflictsResponse = {
  conflicts: Array<GQLShiftConflict>;
  success: Scalars['Boolean'];
};

export type GQLShiftplanCopyInput = {
  /** Copy employment assignments */
  copyEmployees: InputMaybe<Scalars['Boolean']>;
  /** Copy full shiftplan */
  copyFullShiftplan: InputMaybe<Scalars['Boolean']>;
  /** Copy timeframe end at */
  copyTimeframeEndsAt: InputMaybe<Scalars['Date']>;
  /** Copy timeframe start at */
  copyTimeframeStartsAt: InputMaybe<Scalars['Date']>;
  /** Date when the Shiftplan will end (inclusive) */
  endsAt: Scalars['Date'];
  /** Name of the Shiftplan */
  name: Scalars['String'];
  /** Ids Positions */
  positionIds: InputMaybe<Array<Scalars['Int']>>;
  /** ID of the Shiftplan to copy */
  sourceShiftplanId: Scalars['Int'];
  /** Date when the Shiftplan will start (inclusive) */
  startsAt: Scalars['Date'];
  /** Only copies assignments that wouldn’t trigger a warning */
  withoutConflicts: InputMaybe<Scalars['Boolean']>;
};

export type GQLAbsenceInput = {
  /** ID of the AbsenceReason */
  absenceReasonId: Scalars['Int'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Number of days from Absence */
  days: Scalars['Float'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Date when the Absence will end */
  endsAt: Scalars['Date'];
  /** Is the Absence for the full day(s)? */
  isFullDay: Scalars['Boolean'];
  /** Note of Absence */
  notes: InputMaybe<Scalars['String']>;
  /** Is this a paid or unpaid Absence? */
  paid: Scalars['Boolean'];
  /** Date when the Absence will start */
  startsAt: Scalars['Date'];
  /** Initial state of the Absence */
  state: GQLAbsenceState;
};

export type GQLAbsenceCreateResult = GQLAbsence | GQLConflictsResponse;

export type GQLAssignmentGroupCreateInput = {
  /** Additional metadata related to the AssignmentGroup */
  metadata: InputMaybe<Scalars['Json']>;
  /** Name of the AssignmentGroup */
  name: Scalars['String'];
};

export type GQLAvailabilityCreateInput = {
  /** Is this Availability indicating that the Employee is available or unavailable? */
  available: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  endsAt: Scalars['Date'];
  /** Override conflicting availabilities */
  override: InputMaybe<Scalars['Boolean']>;
  /** Is the Availability repeated weekly? */
  repeat: InputMaybe<Scalars['Boolean']>;
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  startsAt: Scalars['Date'];
};

export type GQLAvailabilityCreateResult =
  | GQLAvailability
  | GQLAvailabilityConflictResponse;

export type GQLAvailabilityConflictResponse = {
  conflicts: Maybe<Array<Maybe<GQLAvailabilityConflicts>>>;
  errorKey: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

export type GQLAvailabilityConflicts = {
  availabilities: Maybe<Array<Maybe<GQLAvailability>>>;
};

export type GQLAvailabilityExceptionInput = {
  /** ID of the associated Availability */
  availabilityId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /**
   * End of the selected Availability out of a repeated Availability which should be counted as an
   * exception (must fit the exact Availability start)
   */
  endsAt: Scalars['Date'];
  /**
   * Start of the selected Availability out of a repeated Availability which should be counted as an
   * exception (must fit the exact Availability start)
   */
  startsAt: Scalars['Date'];
};

export type GQLCustomFieldCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the CustomField */
  name: Scalars['String'];
};

export type GQLDayNoteCreateInput = {
  /** Optional description for the DayNote */
  description: InputMaybe<Scalars['String']>;
  /** End date of the DayNote */
  endsAt: Scalars['Date'];
  /** Start date of the DayNote */
  startsAt: Scalars['Date'];
  /** Title of the DayNote */
  title: Scalars['String'];
};

export type GQLEvaluationInput = {
  /** Total Duration of breaks in minutes */
  break: InputMaybe<Scalars['Float']>;
  /** Date when the Evaluation ends */
  endsAt: Scalars['Date'];
  /** Start and end times of EvaluationBreaks */
  evaluationBreaks: InputMaybe<Array<GQLEvaluationBreakInput>>;
  /** StaffShiftTags assigned to the Evaluation */
  evaluationTagIds: InputMaybe<Array<Scalars['Int']>>;
  /** Whether to ignore conflicts */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** Note for the Evaluation */
  note: InputMaybe<Scalars['String']>;
  /** Payments related to the Evaluation */
  payments: InputMaybe<Array<GQLEvaluationPaymentInput>>;
  /** Date when the Evaluation starts */
  startsAt: Scalars['Date'];
  /** Duration of unpaid breaks in minutes */
  untimedBreak: InputMaybe<Scalars['Float']>;
};

export type GQLEvaluationBreakInput = {
  /** Date when EvaluationBreak ended */
  endsAt: InputMaybe<Scalars['Date']>;
  /** Date when EvaluationBreak started */
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLEvaluationPaymentInput = {
  /** Children of the Payment */
  childPayments: InputMaybe<Array<GQLEvaluationPaymentInput>>;
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of the Payment */
  value: InputMaybe<Scalars['Float']>;
};

export type GQLEmployeeEvaluationCreateResult =
  | GQLEmploymentEvaluation
  | GQLEvaluationConflictsResponse;

export type GQLPaymentCreateInput = {
  /** ID of the parent Payment (or null if no parent) */
  parentPaymentId: InputMaybe<Scalars['Int']>;
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of the Payment */
  value: InputMaybe<Scalars['Float']>;
};

export type GQLEmploymentEvaluationTag = GQLIdentifiable &
  GQLTimestampable & {
    /** Date when the EvaluationTag was created */
    createdAt: Scalars['Date'];
    /** Date when the EvaluationTag was deleted */
    deletedAt: Maybe<Scalars['Date']>;
    /** Unique identifier of the EvaluationTag */
    id: Scalars['Int'];
    /** Tag associated with the EvaluationTag */
    tag: GQLTag;
    /** ID of the Tag associated with the EvaluationTag */
    tagId: Scalars['Int'];
    /** Date when the EvaluationTag was last updated */
    updatedAt: Scalars['Date'];
  };

export type GQLEmploymentPaygradeCreateInput = {
  /** ID of the Paygrade */
  paygradeId: Scalars['Int'];
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Update Paygrade on all Shifts */
  updateAll: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts until */
  updateEndDate: InputMaybe<Scalars['Date']>;
  /** Force the Paygrade update */
  updateForce: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrades on Shifts */
  updatePaygrades: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts from */
  updateStartDate: InputMaybe<Scalars['Date']>;
  /** Value of Paygrade */
  value: Scalars['Float'];
};

export type GQLEmploymentsPositionInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** ID of the associated LocationsPosition */
  locationsPositionId: Scalars['Int'];
};

export type GQLEmploymentsRotationGroupCreateInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** End date for association */
  endsAt: InputMaybe<Scalars['Date']>;
  /** ID of the associated ShiftRotationGroup */
  shiftRotationGroupId: Scalars['Int'];
  /** Start date for association */
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLIndividualShiftRotationCreateInput = {
  /** Anchor date of the rotation */
  anchorDate: Scalars['Date'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** When does the the rotation itself end */
  endsAt: InputMaybe<Scalars['Date']>;
  /** After how many days does the rotation repeat? */
  rotationInterval: Scalars['Int'];
  /** An array of shift preset IDs associated with the ShiftRotationGroup */
  shiftPresetIds: Array<Scalars['Int']>;
  /** When does the the rotation itself start */
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLOverridableRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Ignore warnings */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
};

export type GQLRequestInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Ignore warnings */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
};

export type GQLLocationCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the Location */
  name: Scalars['String'];
  /** Sorting position of the Location */
  sort: InputMaybe<Scalars['Int']>;
};

export type GQLLocationsPositionCreateInput = {
  /** ID of the associated Location */
  locationId: Scalars['Int'];
  /** ID of the associated Position */
  positionId: Scalars['Int'];
  /** Sorting position of the LocationsPosition */
  sort: InputMaybe<Scalars['Int']>;
};

export type GQLNewsfeedInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Message posted to the Newsfeed */
  message: InputMaybe<Scalars['String']>;
};

export type GQLPositionCreateInput = {
  /** Background color for the Position */
  color: InputMaybe<Scalars['String']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Description of the Position */
  description: InputMaybe<Scalars['String']>;
  /** Name of the Position */
  name: Scalars['String'];
  /** Position comment (shown on Shift) */
  note: InputMaybe<Scalars['String']>;
  /** Sorting position of the Position */
  sort: InputMaybe<Scalars['Int']>;
};

export type GQLCreatePunchingShiftInput = {
  /** Company ID for which to create the shift */
  companyId: Scalars['Int'];
  /** Encrypted Company ID */
  encryptedCompanyId: InputMaybe<Scalars['String']>;
  /** Information required to create the shift */
  shift: GQLPunchingShiftInput;
  /** User token for punch clock */
  userToken: InputMaybe<Scalars['Int']>;
};

export type GQLPunchingShiftInput = {
  /** Break time in minutes */
  breakTime: InputMaybe<Scalars['Float']>;
  /** Time when the Shift is planned to end */
  endsAtTime: Scalars['String'];
  /** Ignore any warning regarding break rules if possible */
  enforceBreakWarning: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated LocationsPosition */
  locationsPositionId: Scalars['Int'];
  /** Ignore any warning regarding payment restrictions if possible */
  overridePayment: InputMaybe<Scalars['Boolean']>;
  /** Ignore any warning regarding rest periods if possible */
  overrideRestRules: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Shiftplan */
  shiftplanId: Scalars['Int'];
  /** Date when the Shift was started */
  startsAt: Scalars['Date'];
};

export type GQLCreatePunchingShiftResult = {
  /** Conflicts which prevented shift creation */
  conflicts: Array<Maybe<GQLCreatePunchingShiftConflict>>;
  /** Indicator whether shift creation was successful */
  success: Scalars['Boolean'];
};

export type GQLCreatePunchingShiftConflict =
  | GQLErrorRestPeriodBelowMinimum
  | GQLErrorStaffOverpaid
  | GQLWarningRestPeriodBelowMinimum
  | GQLWarningStaffOverpaid;

export type GQLErrorRestPeriodBelowMinimum =
  GQLCreatePunchingShiftConflictWithStringValue & {
    /** A value with details about the error which prevented shift creation */
    value: Scalars['String'];
  };

export type GQLCreatePunchingShiftConflictWithStringValue = {
  value: Scalars['String'];
};

export type GQLErrorStaffOverpaid =
  GQLCreatePunchingShiftConflictWithStringValue & {
    /** A value with details about the error which prevented shift creation */
    value: Scalars['String'];
  };

export type GQLWarningRestPeriodBelowMinimum =
  GQLCreatePunchingShiftConflictWithIntValue & {
    /** A value with details about the error which prevented shift creation */
    value: Scalars['Int'];
  };

export type GQLCreatePunchingShiftConflictWithIntValue = {
  value: Scalars['Int'];
};

export type GQLWarningStaffOverpaid = {
  /** Currency used by the Company */
  currency: Scalars['String'];
  /** Maximum allowed payment */
  employmentMaxPayment: Scalars['Float'];
  /** Total payment if shift was started */
  totalPayment: Scalars['Float'];
};

export type GQLShiftCreateInput = {
  /** Ids of the associated AssignmentGroups */
  assignmentGroupIds: InputMaybe<Array<Scalars['Int']>>;
  /** The setting for whether requests will be automatically accepted and how conflicts will be handled */
  autoAccept: InputMaybe<GQLAutoAcceptRequestSetting>;
  /** Can employees create Evaluations? */
  canEvaluate: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Date when the Shift ends */
  endsAt: Scalars['Date'];
  /** Ids of the associated EvaluationTags */
  evaluationTagIds: InputMaybe<Array<Scalars['Int']>>;
  /** Whether to ignore conflicts */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated LocationsPosition */
  locationsPositionId: Scalars['Int'];
  /** Manager note for the Shift */
  managerNote: InputMaybe<Scalars['String']>;
  /** Note for the Shift */
  note: InputMaybe<Scalars['String']>;
  /** Start and end times of ShiftBreaks */
  shiftBreaks: InputMaybe<Array<GQLShiftBreakTimeframe>>;
  /** ID of the associated Shiftplan */
  shiftplanId: Scalars['Int'];
  /** ID of the shift preset used for setting the time and unpaid break of the shift */
  shiftPresetId: InputMaybe<Scalars['Int']>;
  /** Id and count for shift qualification */
  shiftQualifications: InputMaybe<Array<GQLShiftQualificationInput>>;
  /** Ids of the associated ShiftRotationGroups */
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  /** Date when the Shift starts */
  startsAt: Scalars['Date'];
  /** Ids of the associated Tags */
  tagIds: InputMaybe<Array<Scalars['Int']>>;
  /** Defines whether this shift will be a stand by shift */
  untimed: InputMaybe<Scalars['Boolean']>;
  /** Duration of unpaid breaks in minutes */
  untimedBreakTime: InputMaybe<Scalars['Float']>;
  /** Amount of employees assignable to the Shift */
  workers: Scalars['Int'];
};

export type GQLShiftBreakTimeframe = {
  /** Date when the ShiftBreak ended */
  endsAt: Scalars['Date'];
  /** Date when the ShiftBreak started */
  startsAt: Scalars['Date'];
};

export type GQLShiftQualificationInput = {
  /** Required workers for qualification */
  count: Scalars['Int'];
  /** ID of the associated qualification */
  qualificationId: Scalars['Int'];
};

export type GQLShiftBreakCreateInput = {
  /** The id of the company in which you're acting */
  companyId: Scalars['Int'];
  /** The end of the break */
  endsAt: Scalars['Date'];
  /** The start of the break */
  startsAt: Scalars['Date'];
};

export type GQLShiftEvaluationTag = {
  tag: GQLTag;
  tagId: Scalars['Int'];
};

export type GQLShiftPaygradeCreateInput = {
  /** ID of this paygrade's parent */
  parentPaygradeId: InputMaybe<Scalars['Int']>;
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of Paygrade */
  value: Scalars['Float'];
};

export type GQLShiftplanCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Date when the Shiftplan will end (inclusive) */
  endsAt: Scalars['Date'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
  /** Name of the Shiftplan */
  name: Scalars['String'];
  /** Date when the Shiftplan will start (inclusive) */
  startsAt: Scalars['Date'];
};

export type GQLShiftRotationShiftplanCreateInput = {
  /** Date when the Shiftplan will end (inclusive) */
  endsAt: Scalars['Date'];
  /** ID of the associated Location */
  locationId: Scalars['Int'];
  /** Shiftplan metadata as JSON */
  metadata: InputMaybe<Scalars['String']>;
  /** Name of the Shiftplan */
  name: Scalars['String'];
  /** Date when the Shiftplan will start (inclusive) */
  startsAt: Scalars['Date'];
  /** Array of number of employees which should be assigned to each Position of the ShiftRotation */
  workersPerPositionsList: Array<GQLWorkersPerPosition>;
};

export type GQLShiftplanCreateMessageInput = {
  /** Message content */
  message: Scalars['String'];
  /** Only send to employees assigned to a shift that is part of the Shiftplan */
  onlyAssigned: InputMaybe<Scalars['Boolean']>;
  /** Only send to employees that have this position */
  positionIds: InputMaybe<Array<Scalars['Int']>>;
};

export type GQLShiftplanCreateNotificationInput = {
  message: InputMaybe<Scalars['String']>;
  notificationType: GQLShiftplanNotificationType;
};

export enum GQLShiftplanNotificationType {
  OPEN = 'open',
  UPDATES = 'updates',
}

export type GQLShiftRotationCreateInput = {
  /** Anchor date of the rotation */
  anchorDate: Scalars['Date'];
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** When does the the rotation itself end */
  endsAt: InputMaybe<Scalars['Date']>;
  /** Name of the rotation */
  name: Scalars['String'];
  /** After how many days does the rotation repeat? */
  rotationInterval: Scalars['Int'];
  /** When does the the rotation itself start */
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLShiftRotationGroupCreateInput = {
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Name of the ShiftRotationGroup */
  name: Scalars['String'];
  /** An array of shift preset IDs associated with the ShiftRotationGroup */
  shiftPresetIds: Array<Scalars['Int']>;
  /** ID of the associated ShiftRotation */
  shiftRotationId: Scalars['Int'];
  /** Used for ordering ShiftRotationGroups of the same ShiftRotation */
  sortPosition: InputMaybe<Scalars['Int']>;
};

export type GQLSpecialDateCreateInput = {
  /** Does this SpecialDate apply to every location of the company? */
  allLocations: InputMaybe<Scalars['Boolean']>;
  /** Time when that SpecialDate ends or null if fullDay is true */
  endTime: InputMaybe<Scalars['String']>;
  /** Is this a full-day SpecialDate? */
  fullDay: InputMaybe<Scalars['Boolean']>;
  /** Is this SpecialDate counted towards the employee’s working hours? */
  isIncludedInHourAccount: InputMaybe<Scalars['Boolean']>;
  /** Array of Location to which the SpecialDate is applied (empty if allLocations is true) */
  locationIds: Array<Scalars['Int']>;
  /** Name of the SpecialDate */
  name: Scalars['String'];
  /** Date for SpecialDate */
  specialDate: Scalars['Date'];
  /** Time when that SpecialDate starts or null if fullDay is true */
  startTime: InputMaybe<Scalars['String']>;
};

export type GQLStaffShiftCreateInput = {
  /** Whether to create StaffShifts for the Shifts connected to this Shift */
  assignToConnected: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** List of conflicts to ignore */
  ignoreConflictKinds: InputMaybe<Array<GQLShiftConflictKind>>;
  /** Whether to ignore conflicts */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Shift */
  shiftId: Scalars['Int'];
};

export enum GQLShiftConflictKind {
  ABSENCE_COLLISIONS = 'absence_collisions',
  MAXIMUM_CONSECUTIVE_WORKING_DAYS = 'maximum_consecutive_working_days',
  MAXIMUM_EARNING = 'maximum_earning',
  MAXIMUM_WORKING_HOURS = 'maximum_working_hours',
  MINIMUM_BREAK = 'minimum_break',
  MINIMUM_REST = 'minimum_rest',
  NOT_AVAILABLE = 'not_available',
  OUTSIDE_EMPLOYMENT_PERIOD = 'outside_employment_period',
  RESTRICTED_SHIFT_PRESETS = 'restricted_shift_presets',
  SHIFTS_COLLISIONS = 'shifts_collisions',
  UNAVAILABLE = 'unavailable',
}

export type GQLStaffShiftCreateResult =
  | GQLShiftConflictsResponse
  | GQLStaffShift;

export type GQLTagCreateInput = {
  /** Color of tag (hex) */
  color: InputMaybe<Scalars['String']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Location associated with the Tag */
  locationId: Scalars['Int'];
  /** Name of tag */
  name: Scalars['String'];
};

export type GQLTranslationCreateInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Key of the string to be translated */
  key: Scalars['String'];
  /** ID of the associated Language */
  languageId: Scalars['Int'];
  /** Translation of the string */
  value: Scalars['String'];
};

export type GQLUserCreateInput = {
  /** ID of the billing type */
  billingTypeId: InputMaybe<Scalars['Int']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Email address of the User */
  email: InputMaybe<Scalars['String']>;
  /** Leaving date of the User */
  exitDate: InputMaybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: InputMaybe<Scalars['Boolean']>;
  /** First name of the User */
  firstName: Scalars['String'];
  /** Is the User an employee? */
  isEmployee: InputMaybe<Scalars['Boolean']>;
  /** Is the User's account inactive? */
  isInactive: InputMaybe<Scalars['Boolean']>;
  /** Is the User paid monthly? */
  isPaidMonthly: InputMaybe<Scalars['Boolean']>;
  /** Is the User a stakeholder? */
  isStakeholder: InputMaybe<Scalars['Boolean']>;
  /** Last name of the User */
  lastName: Scalars['String'];
  /** Locale (i.e. language code) of the User */
  locale: InputMaybe<Scalars['String']>;
  /** Maximum earnings per month */
  maximumMoney: InputMaybe<Scalars['Float']>;
  /** Maximum earnings per month enabled? */
  maximumMoneyEnabled: InputMaybe<Scalars['Boolean']>;
  /** Maximum number of vacation days of the User */
  maxVacationDays: InputMaybe<Scalars['Float']>;
  /** Note */
  note: InputMaybe<Scalars['String']>;
  /** Password of the User */
  password: InputMaybe<Scalars['String']>;
  /** Phone number of the User */
  phoneNumber: InputMaybe<Scalars['String']>;
  /** Staff number of the User */
  staffNumber: InputMaybe<Scalars['String']>;
  /** A unique token for the User */
  userToken: InputMaybe<Scalars['String']>;
};

export type GQLAbsenceDeclineInput = {
  /** The company in which the absence should be declined */
  companyId: Scalars['Int'];
  /** Optional message for a refused absence */
  refuseMessage: InputMaybe<Scalars['String']>;
};

export type GQLShiftDeleteInput = {
  /** Whether all connected Shift should be deleted */
  deleteConnected: InputMaybe<Scalars['Boolean']>;
};

export type GQLShiftplanImportInput = {
  /** Import employment assignments */
  copyEmployees: InputMaybe<Scalars['Boolean']>;
  /** Import full shiftplan */
  copyFullShiftplan: InputMaybe<Scalars['Boolean']>;
  /** Date of the end of imported period */
  endsAt: Scalars['Date'];
  /** Imported timeframe ends at */
  importTimeframeEndsAt: InputMaybe<Scalars['Date']>;
  /** Imported timeframe starts at */
  importTimeframeStartsAt: InputMaybe<Scalars['Date']>;
  /** Ids of Positions to be imported */
  positionIds: InputMaybe<Array<Scalars['Int']>>;
  /** ID of the shiftplan to import data from */
  sourceShiftplanId: Scalars['Int'];
  /** Date of the start of imported period(first day of imported timeframe will be on this day) */
  startsAt: Scalars['Date'];
  /** ID of the Shiftplan to be imported to */
  targetShiftplanId: Scalars['Int'];
  /** Only imports assignments that wouldn’t trigger a warning */
  withoutConflicts: InputMaybe<Scalars['Boolean']>;
};

/**
 * Email and password are the credentials for the login. To get an authentication token for using
 * the API a successful login is required.
 */
export type GQLLogin = {
  /** An automatically generated token upon login */
  authenticationToken: Scalars['String'];
  /** The Companies associated with the Login */
  companies: Maybe<Array<Maybe<GQLCompany>>>;
  /** The Employment currently associated with the Login */
  currentEmploymentId: Maybe<Scalars['Int']>;
  /** The Employments associated with the Login */
  employments: Maybe<Array<Maybe<GQLEmployment>>>;
  /** The Locations associated with the Login */
  locations: Maybe<Array<Maybe<GQLLocation>>>;
  /** The Positions associated with the Login */
  positions: Maybe<Array<Maybe<GQLPosition>>>;
  /** The Rights associated with the Login */
  rights: Maybe<Array<Maybe<GQLRight>>>;
  /** The User associated with the Login */
  users: Maybe<GQLUser>;
};

export type GQLStaffShiftMoveInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** ID of the associated Employment */
  employmentId: Scalars['Int'];
  /** Whether to ignore conflicts caused by assignment */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the Shift to move to */
  shiftId: Scalars['Int'];
  /** ID of the Shift to move from */
  sourceShiftId: Scalars['Int'];
};

export type GQLShiftplanPublishInput = {
  /** Additional text message included in email notification to employees. Requires sendMessage to be true for message to be included */
  message: InputMaybe<Scalars['String']>;
  /** Notify employees by email */
  publishWithEmail: InputMaybe<Scalars['Boolean']>;
  /** Send information about shifts assigned to the employee in email notification. Requires publishWithEmail to be true for notification to be sent */
  sendAssignedShifts: InputMaybe<Scalars['Boolean']>;
  /** Send additional text message to employees. Provided in message field. Requires publishWithEmail to be true for notification to be sent */
  sendMessage: InputMaybe<Scalars['Boolean']>;
  /** Send information about open shifts in email notification. Requires publishWithEmail to be true for notification to be sent */
  sendOpenShifts: InputMaybe<Scalars['Boolean']>;
};

export type GQLPunchOutResult = {
  /** Type of non-overridable error that prevents from punching out */
  errorKey: Maybe<Scalars['String']>;
  /** Indicator whether punching out was successful */
  success: Scalars['Boolean'];
  /** A value that serves as a detail to the error which prevented from punching out */
  value: Maybe<Scalars['Int']>;
  /** Type of overridable warning that prevents from punching out */
  warning: Maybe<Scalars['String']>;
};

export type GQLEmploymentPaygradeDeleteInput = {
  /** Update Paygrade on all Shifts */
  updateAll: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts until */
  updateEndDate: InputMaybe<Scalars['Date']>;
  /** Force the Paygrade update */
  updateForce: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrades on Shifts */
  updatePaygrades: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts from */
  updateStartDate: InputMaybe<Scalars['Date']>;
};

export type GQLStaffShiftReplaceInput = {
  /** ID of the Employment being assigned to staffShift */
  employmentId: Scalars['Int'];
  /** Whether to ignore conflicts caused by assignment */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the Shift */
  shiftId: Scalars['Int'];
  /** ID of the Employment that is being removed from staffShift */
  sourceEmploymentId: Scalars['Int'];
};

export type GQLChatMessageInput = {
  message: Scalars['String'];
  recipientId: Scalars['String'];
  recipientType: GQLChatType;
};

export type GQLPunchtimeSession = {
  /** Name of the Company */
  companyName: Maybe<Scalars['String']>;
  /** Encrypted Company ID */
  encryptedCompanyId: Scalars['String'];
  /** Was the punchtime session started successfully? */
  success: Scalars['Boolean'];
};

export type GQLShiftRotationGroupUnassignInput = {
  /** ID of employment to unassign */
  employmentId: Scalars['Int'];
  /** Date to select the Shifts to */
  endDate: Scalars['Date'];
  /** Location Position ID */
  locationsPositionId: Scalars['Int'];
  /** ID of Shiftplan to unassign */
  shiftplanId: Scalars['Int'];
  /** ID of the ShiftRotationGroup to unassign */
  shiftRotationGroupId: Scalars['Int'];
  /** Date to select the Shifts from */
  startDate: Scalars['Date'];
};

export type GQLAssignmentGroupUpdateInput = {
  /** Additional metadata related to the AssignmentGroup */
  metadata: InputMaybe<Scalars['Json']>;
  /** Name of the AssignmentGroup */
  name: InputMaybe<Scalars['String']>;
};

export type GQLAvailabilityUpdateInput = {
  /** Is this Availability indicating that the Employee is available or unavailable? */
  available: InputMaybe<Scalars['Boolean']>;
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  endsAt: Scalars['Date'];
  /** Override conflicting availabilities */
  override: InputMaybe<Scalars['Boolean']>;
  /** Date when the Availability will start (rounded to steps of 15 minutes) */
  startsAt: Scalars['Date'];
  /** Update all records of a repeating Availability */
  updateAll: Scalars['Boolean'];
};

export type GQLCustomFieldUpdateInput = {
  /** Name of the CustomField */
  name: Scalars['String'];
};

export type GQLPaymentUpdateInput = {
  /** ID of the parent Payment (or null if no parent) */
  parentPaymentId: InputMaybe<Scalars['Int']>;
  /** Value of PaygradeType */
  value: InputMaybe<Scalars['Float']>;
};

export type GQLEmploymentPaygradeUpdateInput = {
  /** ID of the Paygrade */
  paygradeId: Scalars['Int'];
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Update Paygrade on all Shifts */
  updateAll: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts until */
  updateEndDate: InputMaybe<Scalars['Date']>;
  /** Force the Paygrade update */
  updateForce: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrades on Shifts */
  updatePaygrades: InputMaybe<Scalars['Boolean']>;
  /** Update Paygrade on Shifts from */
  updateStartDate: InputMaybe<Scalars['Date']>;
  /** Value of PaygradeType */
  value: Scalars['Float'];
};

export type GQLEmploymentsRotationGroupUpdateInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** End date for association */
  endsAt: InputMaybe<Scalars['Date']>;
  /** Start date for association */
  startsAt: InputMaybe<Scalars['Date']>;
};

export enum GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy {
  ALL = 'all',
  ROTATION_GROUP = 'rotation_group',
}

export type GQLEmploymentsSurveyInput = {
  /** Name of the bank account holder */
  bankAccountHolder: InputMaybe<Scalars['String']>;
  /** IBAN */
  bankAccountNumber: InputMaybe<Scalars['String']>;
  /** BIC */
  bankIdentifierCode: InputMaybe<Scalars['String']>;
  /** Name of the bank */
  bankName: InputMaybe<Scalars['String']>;
  /** Birth date of the employee */
  birthDate: InputMaybe<Scalars['Date']>;
  /** Birth place of the employee */
  birthPlace: InputMaybe<Scalars['String']>;
  /** Bank that issued the capital-forming benefits */
  capitalFormingBenefitsBankName: InputMaybe<Scalars['String']>;
  /** Contract number of capital-forming benefits contract */
  capitalFormingBenefitsContractNumber: InputMaybe<Scalars['String']>;
  /** Commencement of the capital-forming benefits contract */
  capitalFormingBenefitsContractStart: InputMaybe<Scalars['Date']>;
  /** Employer's contribution towards the capital-forming benefits contract */
  capitalFormingBenefitsEmployersContribution: InputMaybe<Scalars['Float']>;
  /** Amount per month going into the capital-forming benefits contract */
  capitalFormingBenefitsTotal: InputMaybe<Scalars['Float']>;
  /** Amount of allowance the employee is entitled to in case they have children */
  childrenAllowance: InputMaybe<Scalars['Float']>;
  /** Number of children */
  childrenCount: InputMaybe<Scalars['Int']>;
  /** Whether a proof has been provided that the employee has children */
  childrenProof: InputMaybe<Scalars['String']>;
  /** Name of the city the employee is living in (only when otherAddress = true) */
  city: InputMaybe<Scalars['String']>;
  /** Additional comments */
  comments: InputMaybe<Scalars['String']>;
  /** Name of the country the employee is living in (only when otherAddress = true) */
  country: InputMaybe<Scalars['String']>;
  /** Date when the employee joined */
  dateOfEntry: InputMaybe<Scalars['Date']>;
  /** Relevant for the deduction of church tax from your gross pay. */
  denomination: InputMaybe<Scalars['String']>;
  /** Status of employment. As of now only German taxation options */
  employmentRelationship: InputMaybe<Scalars['String']>;
  /** Main or secondary employment? */
  employmentType: InputMaybe<Scalars['String']>;
  /** Leaving date of the employee (in case of fixed-term contract) */
  exitDate: InputMaybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: InputMaybe<Scalars['Boolean']>;
  /** Marital status */
  familyStatus: InputMaybe<Scalars['String']>;
  /** First name of the employee */
  firstName: InputMaybe<Scalars['String']>;
  /** Whether the employee has one or more other mini jobs */
  furtherMinijobs: InputMaybe<Scalars['Boolean']>;
  /** Gender of the employee */
  gender: InputMaybe<Scalars['String']>;
  /** Whether the employee has capital-forming benefits */
  hasCapitalFormingBenefits: InputMaybe<Scalars['Boolean']>;
  /** Whether the employee has children */
  hasChildren: InputMaybe<Scalars['Boolean']>;
  /** Identification number of the health insurance provider */
  healthInsuranceIdentificationNumber: InputMaybe<Scalars['String']>;
  /** Name of the health insurance provider */
  healthInsuranceName: InputMaybe<Scalars['String']>;
  /** Highest level of education */
  highestLevelOfEducation: InputMaybe<Scalars['String']>;
  /** Highest school degree */
  highestSchoolDegree: InputMaybe<Scalars['String']>;
  /** Description of main work activity */
  jobDescription: InputMaybe<Scalars['String']>;
  /** Last name of the employee */
  lastName: InputMaybe<Scalars['String']>;
  /** Middle name of the employee */
  middleName: InputMaybe<Scalars['String']>;
  /** Salary if the employment is a mini job */
  minijobSalary: InputMaybe<Scalars['Float']>;
  /** Nationality of the employee */
  nationality: InputMaybe<Scalars['String']>;
  /** Whether the employee has worked in Germany before or not */
  neverWorkedInGermany: InputMaybe<Scalars['Boolean']>;
  /** Note */
  note: InputMaybe<Scalars['String']>;
  /** Set to true if an address other than the default adress of the User should be used */
  otherAddress: InputMaybe<Scalars['Boolean']>;
  /** Type of exemption from pension insurance if the employment is a mini job */
  pensionInsuranceExemption: InputMaybe<Scalars['String']>;
  /** Phone number */
  phoneNumber: InputMaybe<Scalars['String']>;
  /** Social security number */
  socialSecurityNumber: InputMaybe<Scalars['String']>;
  /** Staff number */
  staffNumber: InputMaybe<Scalars['String']>;
  /** Name of the country's state the employee is living in (only when otherAddress = true) */
  state: InputMaybe<Scalars['String']>;
  /** Name of the street the employee is living in (only when otherAddress = true) */
  street: InputMaybe<Scalars['String']>;
  /** House number of the street the employee is living in (only when otherAddress = true) */
  streetNumber: InputMaybe<Scalars['String']>;
  /** Name of the district etc. the employee is living in (only when otherAddress = true) */
  subcity: InputMaybe<Scalars['String']>;
  /** Tax bracket */
  taxBracket: InputMaybe<Scalars['String']>;
  /** Only needed if tax bracket is 'IV with factor' */
  taxBracketFactor: InputMaybe<Scalars['String']>;
  /** Tax identification number (also called personal identification number, IdNr. or tax ID) */
  taxIdentificationNumber: InputMaybe<Scalars['String']>;
  visibility: InputMaybe<Scalars['String']>;
  /** Working hours per week */
  weeklyHours: InputMaybe<Scalars['Float']>;
  /** Zip code of the city the employee is living in (only when otherAddress = true) */
  zip: InputMaybe<Scalars['String']>;
};

export type GQLEmploymentsSurveyCollection = GQLPaginatable & {
  items: Array<GQLEmploymentsSurvey>;
  pagination: GQLPaginationInfo;
};

export type GQLLocationUpdateInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Name of the Location */
  name: InputMaybe<Scalars['String']>;
  /** Sorting position of the Location */
  sort: InputMaybe<Scalars['Int']>;
};

export type GQLPublishSettingsParams = {
  /** Additional text message included in email notification to employees. Requires sendMessage to be true for message to be included */
  message: InputMaybe<Scalars['String']>;
  /** Send information about shifts assigned to the employee in email notification. */
  sendAssignedShifts: InputMaybe<Scalars['Boolean']>;
  /** Send additional text message to employees. Provided in message field. */
  sendMessage: InputMaybe<Scalars['Boolean']>;
  /** Send information about open shifts in email notification. */
  sendOpenShifts: InputMaybe<Scalars['Boolean']>;
};

export type GQLNotificationConfigurationUpdateInput = {
  /** Should Absence notifications be sent via email? */
  absencesMail: InputMaybe<Scalars['Boolean']>;
  /** Should Absence notifications be sent to the User's mobile device? */
  absencesMobile: InputMaybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent via email? */
  applicationRequestRefusedMail: InputMaybe<Scalars['Boolean']>;
  /** Should Request refusal notifications be sent to the User's mobile device? */
  applicationRequestRefusedMobile: InputMaybe<Scalars['Boolean']>;
  /** Should auto punchout notifications be sent to the User's mobile device? */
  autoPunchoutMobile: InputMaybe<Scalars['Boolean']>;
  /** Should Evaluation notifications be sent via email? */
  evaluationsMail: InputMaybe<Scalars['Boolean']>;
  /** Should Evaluation notifications be sent to the User's mobile device? */
  evaluationsMobile: InputMaybe<Scalars['Boolean']>;
  /** Should messages be sent to the User's mobile device? */
  messagesMobile: InputMaybe<Scalars['Boolean']>;
  /** Should Request notifications be sent to the User's mobile device? */
  requestsMobile: InputMaybe<Scalars['Boolean']>;
  /** Should Shiftplan notifications be sent to the User's mobile device? */
  shiftplansMobile: InputMaybe<Scalars['Boolean']>;
  /** Should staff messages be sent to the User's mobile device? */
  staffMessagesMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications via email? */
  stakeholderAbsencesMail: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive Absence notifications on their mobile devices? */
  stakeholderAbsencesMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto punchout notifications on their mobile devices? */
  stakeholderAutoPunchoutMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications via email? */
  stakeholderAutoRequestChangeAcceptMail: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive auto change request notifications on their mobile devices? */
  stakeholderAutoRequestChangeAcceptMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive Evaluation suggestions on their mobile devices? */
  stakeholderEvaluationsMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications via email? */
  stakeholderRequestsMail: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive Request notifications on their mobile devices? */
  stakeholderRequestsMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications via email? */
  stakeholderShiftApplicationMail: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive shift application notifications on their mobile devices? */
  stakeholderShiftApplicationMobile: InputMaybe<Scalars['Boolean']>;
  /** Should stakeholders receive notifications on their mobile devices when an employee has withdrawn their absence? */
  stakeholderWithdrawAbsencesMobile: InputMaybe<Scalars['Boolean']>;
};

export type GQLPositionUpdateInput = {
  /** Background color for the Position */
  color: InputMaybe<Scalars['String']>;
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Description of the Position */
  description: InputMaybe<Scalars['String']>;
  /** Name of the Position */
  name: InputMaybe<Scalars['String']>;
  /** Position comment (shown on Shift) */
  note: InputMaybe<Scalars['String']>;
  /** Sorting position of the Position */
  sort: InputMaybe<Scalars['Int']>;
};

export type GQLShiftUpdateInput = {
  /** Ids of the associated AssignmentGroups */
  assignmentGroupIds: InputMaybe<Array<Scalars['Int']>>;
  /** The setting for whether requests will be automatically accepted and how conflicts will be handled */
  autoAccept: InputMaybe<GQLAutoAcceptRequestSetting>;
  /** Can employees create Evaluations? */
  canEvaluate: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Date when the Shift ends */
  endsAt: Scalars['Date'];
  /** Ids of the associated EvaluationTags */
  evaluationTagIds: InputMaybe<Array<Scalars['Int']>>;
  /** Ignore conflicts? */
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  /** ID of the associated LocationsPosition. Updating locations_position_id of a shift will unassign all employees from the shift and their evaluations will be deleted */
  locationsPositionId: Scalars['Int'];
  /** Manager note for the Shift */
  managerNote: InputMaybe<Scalars['String']>;
  /** Note for the Shift */
  note: InputMaybe<Scalars['String']>;
  /** Start and end times of ShiftBreaks */
  shiftBreaks: InputMaybe<Array<GQLShiftBreakTimeframe>>;
  /** ID of the shift preset used for setting the time and unpaid break of the shift */
  shiftPresetId: InputMaybe<Scalars['Int']>;
  /** Id and count for shift qualification */
  shiftQualifications: InputMaybe<Array<GQLShiftQualificationInput>>;
  /** Ids of the associated ShiftRotationGroups */
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']>>;
  /** Date when the Shift starts */
  startsAt: Scalars['Date'];
  /** Ids of the associated Tags */
  tagIds: InputMaybe<Array<Scalars['Int']>>;
  /** Defines whether this shift will be a stand by shift */
  untimed: InputMaybe<Scalars['Boolean']>;
  /** Duration of unpaid breaks in minutes */
  untimedBreakTime: InputMaybe<Scalars['Float']>;
  /** Whether all connected Shift should be updated */
  updateConnected: InputMaybe<Scalars['Boolean']>;
  /** Amount of employees assignable to the Shift */
  workers: Scalars['Int'];
};

export type GQLShiftPaygradesUpdateInput = {
  /** Paygrades related to the shift */
  paygrades: InputMaybe<Array<GQLShiftPaygradesInput>>;
  /** Whether all connected Shift should be updated */
  updateConnected: InputMaybe<Scalars['Boolean']>;
};

export type GQLShiftPaygradesInput = {
  /** Children of the Paygrade */
  childPaygrades: InputMaybe<Array<GQLShiftPaygradesInput>>;
  /** ID of the associated PaygradeType */
  paygradeTypeId: Scalars['Int'];
  /** Value of the Payment */
  value: Scalars['Float'];
};

export type GQLShiftplanUpdateInput = {
  /** Date when the Shiftplan will end or ended (inclusive) */
  endsAt: InputMaybe<Scalars['Date']>;
  /** Should shiftplan be locked? */
  locked: InputMaybe<Scalars['Boolean']>;
  /** Name of the Shiftplan */
  name: InputMaybe<Scalars['String']>;
  /** Date when the Shiftplan will start or started (inclusive) */
  startsAt: InputMaybe<Scalars['Date']>;
};

export type GQLTagUpdateInput = {
  /** Color of tag (hex) */
  color: InputMaybe<Scalars['String']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Location associated with the Tag */
  locationId: Scalars['Int'];
  /** Name of tag */
  name: InputMaybe<Scalars['String']>;
};

export type GQLTranslationUpdateInput = {
  /** ID of the associated Company */
  companyId: InputMaybe<Scalars['Int']>;
  /** Key of the string to be translated */
  key: InputMaybe<Scalars['String']>;
  /** ID of the associated Language */
  languageId: InputMaybe<Scalars['Int']>;
  /** Translation of the string */
  value: InputMaybe<Scalars['String']>;
};

export type GQLUserUpdateInput = {
  /** ID of the billing type */
  billingTypeId: InputMaybe<Scalars['Int']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** Email address of the User */
  email: InputMaybe<Scalars['String']>;
  /** Leaving date of the User */
  exitDate: InputMaybe<Scalars['Date']>;
  /** Should the monthly payment in the leaving month be paid partially (i.e. pro rata)? */
  exitMonthPayedPartially: InputMaybe<Scalars['Boolean']>;
  /** First name of the User */
  firstName: InputMaybe<Scalars['String']>;
  /** Is the User's account inactive? */
  isInactive: InputMaybe<Scalars['Boolean']>;
  /** Is the User paid monthly? */
  isPaidMonthly: InputMaybe<Scalars['Boolean']>;
  /** Last name of the User */
  lastName: InputMaybe<Scalars['String']>;
  /** Locale (i.e. language code) of the User */
  locale: InputMaybe<Scalars['String']>;
  /** Maximum earnings per month */
  maximumMoney: InputMaybe<Scalars['Float']>;
  /** Maximum earnings per month enabled? */
  maximumMoneyEnabled: InputMaybe<Scalars['Boolean']>;
  /** Maximum number of vacation days of the User */
  maxVacationDays: InputMaybe<Scalars['Float']>;
  /** Note */
  note: InputMaybe<Scalars['String']>;
  /** Phone number of the User */
  phoneNumber: InputMaybe<Scalars['String']>;
  /** Staff number of the User */
  staffNumber: InputMaybe<Scalars['String']>;
  /** A unique token for the User */
  userToken: InputMaybe<Scalars['String']>;
};

export type GQLWithdrawAcceptedReplaceRequestInput = {
  /** ID of the employment that accepted the offer. If this is empty, currently logged in user is assumed to authorize the action. */
  acceptedBy: InputMaybe<Scalars['Int']>;
  /** ID of the associated Company */
  companyId: Scalars['Int'];
  /** ID of the shift offer */
  id: Scalars['Int'];
};

export type GQLCollisionRulesInput = {
  forceCollision: Scalars['Boolean'];
  forceCollisionAndRemove: Scalars['Boolean'];
};

export type GQLEmploymentEvaluationCollection = GQLPaginatable & {
  items: Array<GQLEmploymentEvaluation>;
  pagination: GQLPaginationInfo;
};

export type GQLAbsenceConflictsFragmentFragment = {
  success: boolean;
  canManage: boolean;
  conflicts: Array<
    | {
        __typename: 'CarryOverDeadlineErrorConflict';
        ignorable: boolean;
        deadline: string;
      }
    | { __typename: 'CarryOverMultipleYearsConflict'; ignorable: boolean }
    | {
        __typename: 'CollisionsConflict';
        ignorable: boolean;
        shifts: Array<{
          id: number;
          startsAt: string;
          endsAt: string;
          locationsPosition: {
            id: number;
            location: { id: number; name: string } | null;
            position: {
              id: number;
              color: string;
              name: string;
              note: string | null;
            } | null;
          };
          shiftplan: { name: string };
        }> | null;
        absences: Array<{
          id: number;
          startsAt: string;
          endsAt: string;
          isFullDay: boolean;
          absenceReason: { id: number; name: string; hasLocalization: boolean };
        }> | null;
      }
    | {
        __typename: 'MaxDaysPerYearConflict';
        before: number;
        after: number;
        limit: number;
        ignorable: boolean;
      }
    | { __typename: 'OutsideEmploymentPeriodConflict'; ignorable: boolean }
    | { __typename: 'UnknownConflict'; data: any | null; ignorable: boolean }
  >;
};

export type GQLAbsenceDurationQueryVariables = Exact<{
  companyId: Scalars['Int'];
  days: InputMaybe<Scalars['Float']>;
  employmentId: Scalars['Int'];
  endsAt: Scalars['Date'];
  reasonId: Scalars['Int'];
  startsAt: Scalars['Date'];
}>;

export type GQLAbsenceDurationQuery = {
  absenceDuration: {
    absenceHoursPerWorkingDay: number;
    absenceDays: number;
    absenceHours: number;
  } | null;
};

export type GQLAbsenceFragmentFragment = {
  id: number;
  startsAt: string;
  endsAt: string;
  state: GQLAbsenceState;
  stateUpdatedAt: string | null;
  days: number;
  isFullDay: boolean;
  file: string | null;
  fileName: string | null;
  fileUploadedAt: string | null;
  notes: string | null;
  paid: boolean;
  vacationMinutes: number;
  canManage: boolean;
  absenceReason: {
    id: number;
    name: string;
    hasLocalization: boolean;
    shortName: string | null;
  };
  employment: {
    id: number;
    firstName: string;
    lastName: string;
    isEmployee: boolean | null;
    userId: number;
    deletedAt: string | null;
    staffNumber: string | null;
    locationsPositionIds: Array<number> | null;
    qualificationIds: Array<number> | null;
    exitDate: string | null;
    pictureData: { pictureSmall: string | null } | null;
    employmentWorkHour: {
      hourType: GQLHourType | null;
      isMonthly: boolean | null;
      weeklyMinutes: number | null;
      monthlyMinutes: number | null;
      monMinutes: number | null;
      tueMinutes: number | null;
      wedMinutes: number | null;
      thuMinutes: number | null;
      friMinutes: number | null;
      satMinutes: number | null;
      sunMinutes: number | null;
      isWorkingOnFri: boolean | null;
      isWorkingOnMon: boolean | null;
      isWorkingOnSat: boolean | null;
      isWorkingOnSun: boolean | null;
      isWorkingOnThu: boolean | null;
      isWorkingOnTue: boolean | null;
      isWorkingOnWed: boolean | null;
    } | null;
  };
};

export type GQLAbsencesQueryVariables = Exact<{
  absenceReasonIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  companyId: Scalars['Int'];
  orderDir: InputMaybe<GQLOrderDirection>;
  orderKey: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  paid: InputMaybe<Scalars['Boolean']>;
  perPage: Scalars['Int'];
  states: InputMaybe<Array<GQLAbsenceState> | GQLAbsenceState>;
  startsAt: InputMaybe<Scalars['Date']>;
  endsAt: InputMaybe<Scalars['Date']>;
  employmentIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employmentStatuses: InputMaybe<
    Array<GQLEmploymentStatus> | GQLEmploymentStatus
  >;
  locationIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  withAttachment: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLAbsencesQuery = {
  absences: {
    items: Array<{
      id: number;
      startsAt: string;
      endsAt: string;
      state: GQLAbsenceState;
      stateUpdatedAt: string | null;
      days: number;
      isFullDay: boolean;
      file: string | null;
      fileName: string | null;
      fileUploadedAt: string | null;
      notes: string | null;
      paid: boolean;
      vacationMinutes: number;
      canManage: boolean;
      absenceReason: {
        id: number;
        name: string;
        hasLocalization: boolean;
        shortName: string | null;
      };
      employment: {
        id: number;
        firstName: string;
        lastName: string;
        isEmployee: boolean | null;
        userId: number;
        deletedAt: string | null;
        staffNumber: string | null;
        locationsPositionIds: Array<number> | null;
        qualificationIds: Array<number> | null;
        exitDate: string | null;
        pictureData: { pictureSmall: string | null } | null;
        employmentWorkHour: {
          hourType: GQLHourType | null;
          isMonthly: boolean | null;
          weeklyMinutes: number | null;
          monthlyMinutes: number | null;
          monMinutes: number | null;
          tueMinutes: number | null;
          wedMinutes: number | null;
          thuMinutes: number | null;
          friMinutes: number | null;
          satMinutes: number | null;
          sunMinutes: number | null;
          isWorkingOnFri: boolean | null;
          isWorkingOnMon: boolean | null;
          isWorkingOnSat: boolean | null;
          isWorkingOnSun: boolean | null;
          isWorkingOnThu: boolean | null;
          isWorkingOnTue: boolean | null;
          isWorkingOnWed: boolean | null;
        } | null;
      };
    }>;
    pagination: { count: number };
  };
};

export type GQLApproveAbsenceMutationVariables = Exact<{
  absenceId: Scalars['Int'];
  companyId: Scalars['Int'];
  forceCollision?: InputMaybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLApproveAbsenceMutation = {
  approveAbsence:
    | {
        success: boolean;
        canManage: boolean;
        conflicts: Array<
          | {
              __typename: 'CarryOverDeadlineErrorConflict';
              ignorable: boolean;
              deadline: string;
            }
          | { __typename: 'CarryOverMultipleYearsConflict'; ignorable: boolean }
          | {
              __typename: 'CollisionsConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              }> | null;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              }> | null;
            }
          | {
              __typename: 'MaxDaysPerYearConflict';
              before: number;
              after: number;
              limit: number;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideEmploymentPeriodConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'UnknownConflict';
              data: any | null;
              ignorable: boolean;
            }
        >;
      }
    | { success: boolean }
    | null;
};

export type GQLCalendarAggregationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  employmentIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationsPositionIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationId: Scalars['Int'];
}>;

export type GQLCalendarAggregationsQuery = {
  calendarAggregations: Array<{
    date: string;
    working: number;
    demand: number;
    absent: number;
  }>;
};

export type GQLCreateAbsenceMutationVariables = Exact<{
  absence: GQLAbsenceInput;
  forceCollision?: InputMaybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLCreateAbsenceMutation = {
  createAbsence:
    | {
        id: number;
        startsAt: string;
        endsAt: string;
        state: GQLAbsenceState;
        stateUpdatedAt: string | null;
        days: number;
        isFullDay: boolean;
        file: string | null;
        fileName: string | null;
        fileUploadedAt: string | null;
        notes: string | null;
        paid: boolean;
        vacationMinutes: number;
        canManage: boolean;
        absenceReason: {
          id: number;
          name: string;
          hasLocalization: boolean;
          shortName: string | null;
        };
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          isEmployee: boolean | null;
          userId: number;
          deletedAt: string | null;
          staffNumber: string | null;
          locationsPositionIds: Array<number> | null;
          qualificationIds: Array<number> | null;
          exitDate: string | null;
          pictureData: { pictureSmall: string | null } | null;
          employmentWorkHour: {
            hourType: GQLHourType | null;
            isMonthly: boolean | null;
            weeklyMinutes: number | null;
            monthlyMinutes: number | null;
            monMinutes: number | null;
            tueMinutes: number | null;
            wedMinutes: number | null;
            thuMinutes: number | null;
            friMinutes: number | null;
            satMinutes: number | null;
            sunMinutes: number | null;
            isWorkingOnFri: boolean | null;
            isWorkingOnMon: boolean | null;
            isWorkingOnSat: boolean | null;
            isWorkingOnSun: boolean | null;
            isWorkingOnThu: boolean | null;
            isWorkingOnTue: boolean | null;
            isWorkingOnWed: boolean | null;
          } | null;
        };
      }
    | {
        success: boolean;
        canManage: boolean;
        conflicts: Array<
          | {
              __typename: 'CarryOverDeadlineErrorConflict';
              ignorable: boolean;
              deadline: string;
            }
          | { __typename: 'CarryOverMultipleYearsConflict'; ignorable: boolean }
          | {
              __typename: 'CollisionsConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              }> | null;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              }> | null;
            }
          | {
              __typename: 'MaxDaysPerYearConflict';
              before: number;
              after: number;
              limit: number;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideEmploymentPeriodConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'UnknownConflict';
              data: any | null;
              ignorable: boolean;
            }
        >;
      }
    | null;
};

export type GQLDeclineAbsenceMutationVariables = Exact<{
  absenceId: Scalars['Int'];
  companyId: Scalars['Int'];
  refuseMessage: InputMaybe<Scalars['String']>;
}>;

export type GQLDeclineAbsenceMutation = {
  declineAbsence: { success: boolean };
};

export type GQLDeleteAbsenceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GQLDeleteAbsenceMutation = {
  deleteAbsence: { success: boolean; error: string | null } | null;
};

export type GQLFetchAbsenceQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchAbsenceQuery = {
  absences: {
    items: Array<{
      id: number;
      startsAt: string;
      endsAt: string;
      state: GQLAbsenceState;
      stateUpdatedAt: string | null;
      days: number;
      isFullDay: boolean;
      file: string | null;
      fileName: string | null;
      fileUploadedAt: string | null;
      notes: string | null;
      paid: boolean;
      vacationMinutes: number;
      canManage: boolean;
      absenceReason: {
        id: number;
        name: string;
        hasLocalization: boolean;
        shortName: string | null;
      };
      employment: {
        id: number;
        firstName: string;
        lastName: string;
        isEmployee: boolean | null;
        userId: number;
        deletedAt: string | null;
        staffNumber: string | null;
        locationsPositionIds: Array<number> | null;
        qualificationIds: Array<number> | null;
        exitDate: string | null;
        pictureData: { pictureSmall: string | null } | null;
        employmentWorkHour: {
          hourType: GQLHourType | null;
          isMonthly: boolean | null;
          weeklyMinutes: number | null;
          monthlyMinutes: number | null;
          monMinutes: number | null;
          tueMinutes: number | null;
          wedMinutes: number | null;
          thuMinutes: number | null;
          friMinutes: number | null;
          satMinutes: number | null;
          sunMinutes: number | null;
          isWorkingOnFri: boolean | null;
          isWorkingOnMon: boolean | null;
          isWorkingOnSat: boolean | null;
          isWorkingOnSun: boolean | null;
          isWorkingOnThu: boolean | null;
          isWorkingOnTue: boolean | null;
          isWorkingOnWed: boolean | null;
        } | null;
      };
    }>;
  };
};

export type GQLFetchAllAbsencesQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
}>;

export type GQLFetchAllAbsencesQuery = {
  absences: {
    items: Array<{
      id: number;
      startsAt: string;
      endsAt: string;
      state: GQLAbsenceState;
      stateUpdatedAt: string | null;
      days: number;
      isFullDay: boolean;
      file: string | null;
      fileName: string | null;
      fileUploadedAt: string | null;
      notes: string | null;
      paid: boolean;
      vacationMinutes: number;
      canManage: boolean;
      absenceReason: {
        id: number;
        name: string;
        hasLocalization: boolean;
        shortName: string | null;
      };
      employment: {
        id: number;
        firstName: string;
        lastName: string;
        isEmployee: boolean | null;
        userId: number;
        deletedAt: string | null;
        staffNumber: string | null;
        locationsPositionIds: Array<number> | null;
        qualificationIds: Array<number> | null;
        exitDate: string | null;
        pictureData: { pictureSmall: string | null } | null;
        employmentWorkHour: {
          hourType: GQLHourType | null;
          isMonthly: boolean | null;
          weeklyMinutes: number | null;
          monthlyMinutes: number | null;
          monMinutes: number | null;
          tueMinutes: number | null;
          wedMinutes: number | null;
          thuMinutes: number | null;
          friMinutes: number | null;
          satMinutes: number | null;
          sunMinutes: number | null;
          isWorkingOnFri: boolean | null;
          isWorkingOnMon: boolean | null;
          isWorkingOnSat: boolean | null;
          isWorkingOnSun: boolean | null;
          isWorkingOnThu: boolean | null;
          isWorkingOnTue: boolean | null;
          isWorkingOnWed: boolean | null;
        } | null;
      };
    }>;
  };
};

export type GQLUpdateAbsenceMutationVariables = Exact<{
  id: Scalars['Int'];
  absence: GQLAbsenceInput;
  forceCollision?: InputMaybe<Scalars['Boolean']>;
  forceCollisionAndRemove?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLUpdateAbsenceMutation = {
  updateAbsence:
    | {
        id: number;
        startsAt: string;
        endsAt: string;
        state: GQLAbsenceState;
        stateUpdatedAt: string | null;
        days: number;
        isFullDay: boolean;
        file: string | null;
        fileName: string | null;
        fileUploadedAt: string | null;
        notes: string | null;
        paid: boolean;
        vacationMinutes: number;
        canManage: boolean;
        absenceReason: {
          id: number;
          name: string;
          hasLocalization: boolean;
          shortName: string | null;
        };
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          isEmployee: boolean | null;
          userId: number;
          deletedAt: string | null;
          staffNumber: string | null;
          locationsPositionIds: Array<number> | null;
          qualificationIds: Array<number> | null;
          exitDate: string | null;
          pictureData: { pictureSmall: string | null } | null;
          employmentWorkHour: {
            hourType: GQLHourType | null;
            isMonthly: boolean | null;
            weeklyMinutes: number | null;
            monthlyMinutes: number | null;
            monMinutes: number | null;
            tueMinutes: number | null;
            wedMinutes: number | null;
            thuMinutes: number | null;
            friMinutes: number | null;
            satMinutes: number | null;
            sunMinutes: number | null;
            isWorkingOnFri: boolean | null;
            isWorkingOnMon: boolean | null;
            isWorkingOnSat: boolean | null;
            isWorkingOnSun: boolean | null;
            isWorkingOnThu: boolean | null;
            isWorkingOnTue: boolean | null;
            isWorkingOnWed: boolean | null;
          } | null;
        };
      }
    | {
        success: boolean;
        canManage: boolean;
        conflicts: Array<
          | {
              __typename: 'CarryOverDeadlineErrorConflict';
              ignorable: boolean;
              deadline: string;
            }
          | { __typename: 'CarryOverMultipleYearsConflict'; ignorable: boolean }
          | {
              __typename: 'CollisionsConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              }> | null;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              }> | null;
            }
          | {
              __typename: 'MaxDaysPerYearConflict';
              before: number;
              after: number;
              limit: number;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideEmploymentPeriodConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'UnknownConflict';
              data: any | null;
              ignorable: boolean;
            }
        >;
      }
    | null;
};

export type GQLGetCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GQLGetCompaniesQuery = {
  companies: {
    items: Array<{
      id: number;
      autoAcceptShiftRequestEnabled: boolean | null;
      autoAcceptSwapRequests: boolean | null;
      autoAcceptShiftRequestType: GQLAutoAcceptRequestSetting;
      canEmployeesCreateAbsences: boolean | null;
      canUseBringShifts: boolean | null;
      canUseShiftPresets: boolean | null;
      canFillShiftplans: boolean | null;
      canLockShiftplans: boolean | null;
      canUseQualifications: boolean | null;
      canManageAvailabilities: boolean | null;
      currency: string | null;
      editShiftPayments: boolean | null;
      isAbsenceAttachmentsAllowed: boolean | null;
      isAbsenceEditEnabled: boolean | null;
      isDashboardMessagesAllowed: boolean | null;
      isEmployeeAllowedToSeeOwnHourAccount: boolean | null;
      isOverassignmentAllowed: boolean | null;
      isPayslipsEnabled: boolean | null;
      shiftRotationEnabled: boolean | null;
      assignmentGroupEnabled: boolean | null;
      isTagsAllowed: boolean;
      isBulkChangesEnabled: boolean | null;
      canManagersEvaluateOwnShifts: boolean | null;
      name: string;
      shiftSwapEnabled: boolean | null;
      swapRequestMinHour: number | null;
      isWithdrawAbsencesAllowed: boolean;
      timeZone: string | null;
      withdrawAbsencesDuration: number;
      viewPersonal: boolean | null;
      chatSettings: {
        isChatAllowed: boolean | null;
        locationChatsEnabled: boolean | null;
        locationsPositionChatsEnabled: boolean | null;
        employmentChats: {
          enabled: boolean | null;
          employeeCanChatTo: {
            anyone: boolean | null;
            sameLocation: boolean | null;
            sameLocationsPosition: boolean | null;
          } | null;
          stakeholderCanChatTo: {
            anyone: boolean | null;
            employeesInLocation: boolean | null;
            employeesInLocationsPosition: boolean | null;
            stakeholdersInLocation: boolean | null;
            stakeholdersInLocationsPosition: boolean | null;
          } | null;
        } | null;
      } | null;
      locations: Array<{
        id: number;
        name: string;
        sort: number | null;
      }> | null;
      pictureData: {
        pictureLarge: string | null;
        pictureSmall: string | null;
        pictureMedium: string | null;
      } | null;
      minimumBreakSettings: {
        forceType: GQLAutoAcceptRequestSetting;
        rules: Array<{ totalMinutes: number; totalBreakMinutes: number }>;
      };
    }>;
  };
};

export type GQLGetCurrentEmploymentQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLGetCurrentEmploymentQuery = {
  myEmployments: Array<{
    id: number;
    userId: number;
    firstName: string;
    lastName: string;
    email: string | null;
    phoneNumber: string | null;
    staffNumber: string | null;
    userToken: number | null;
    exitDate: string | null;
    billingTypeId: number | null;
    maxVacationDays: number | null;
    monthlyPay: number | null;
    maximumMoney: number | null;
    maximumMoneyEnabled: boolean | null;
    note: string | null;
    isEmployee: boolean | null;
    hourEnabled: boolean | null;
    isPaidMonthly: boolean | null;
    isStakeholder: boolean | null;
    exitMonthPayedPartially: boolean | null;
    vacationHours: number | null;
    pictureData: { pictureSmall: string | null } | null;
    language: { locale: string | null } | null;
    locationsPositions: Array<{
      location: { id: number; name: string } | null;
    } | null> | null;
  }>;
};

export type GQLGetMyRightsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLGetMyRightsQuery = {
  myRights: Array<{
    id: number | null;
    contextType: string | null;
    contextId: number | null;
    name: string | null;
  } | null> | null;
};

export type GQLAvailabilityAggregationFragmentFragment = {
  employmentId: number;
  canManage: boolean;
  availableHours: number;
  contractHours: number | null;
  employment: {
    id: number;
    firstName: string;
    lastName: string;
    staffNumber: string | null;
    pictureData: { pictureSmall: string | null } | null;
  };
};

export type GQLAvailabilityAggregationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
  perPage: Scalars['Int'];
  page: Scalars['Int'];
  orderKey: InputMaybe<GQLAvailabilityAggregationOrderKey>;
  orderDir: InputMaybe<GQLOrderDirection>;
  employmentIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  positionIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLAvailabilityAggregationsQuery = {
  availabilityAggregations: {
    items: Array<{
      employmentId: number;
      canManage: boolean;
      availableHours: number;
      contractHours: number | null;
      employment: {
        id: number;
        firstName: string;
        lastName: string;
        staffNumber: string | null;
        pictureData: { pictureSmall: string | null } | null;
      };
    }>;
    pagination: { count: number };
  };
};

export type GQLAbsenceFieldsFragment = {
  id: number;
  startsAt: string;
  endsAt: string;
  state: GQLAbsenceState;
  stateUpdatedAt: string | null;
  canManage: boolean;
  notes: string | null;
  absenceReason: {
    id: number;
    name: string;
    hasLocalization: boolean;
    shortName: string | null;
  };
  employment: {
    id: number;
    firstName: string;
    lastName: string;
    isEmployee: boolean | null;
    userId: number;
    deletedAt: string | null;
    staffNumber: string | null;
    locationsPositionIds: Array<number> | null;
    qualificationIds: Array<number> | null;
    exitDate: string | null;
    pictureData: { pictureSmall: string | null } | null;
    employmentWorkHour: {
      hourType: GQLHourType | null;
      isMonthly: boolean | null;
      weeklyMinutes: number | null;
      monthlyMinutes: number | null;
      monMinutes: number | null;
      tueMinutes: number | null;
      wedMinutes: number | null;
      thuMinutes: number | null;
      friMinutes: number | null;
      satMinutes: number | null;
      sunMinutes: number | null;
      isWorkingOnFri: boolean | null;
      isWorkingOnMon: boolean | null;
      isWorkingOnSat: boolean | null;
      isWorkingOnSun: boolean | null;
      isWorkingOnThu: boolean | null;
      isWorkingOnTue: boolean | null;
      isWorkingOnWed: boolean | null;
    } | null;
  };
};

export type GQLLocationsPositionFieldsFragment = {
  id: number;
  sort: number | null;
  position: {
    id: number;
    name: string;
    color: string;
    note: string | null;
  } | null;
  location: { id: number } | null;
};

export type GQLCalendarAddTagToShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  params: GQLShiftTagInput;
}>;

export type GQLCalendarAddTagToShiftMutation = {
  addTagToShift: { id: number | null };
};

export type GQLCalendarCreateStaffShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShift: GQLStaffShiftCreateInput;
}>;

export type GQLCalendarCreateStaffShiftMutation = {
  createStaffShift:
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      }
    | { id: number };
};

export type GQLCalendarDeleteStaffShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLCalendarDeleteStaffShiftMutation = {
  deleteStaffShift: { success: boolean };
};

export type GQLCalendarRemoveTagFromShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  params: GQLShiftTagInput;
}>;

export type GQLCalendarRemoveTagFromShiftMutation = {
  removeTagFromShift: { id: number | null };
};

export type GQLCalendarUpdateShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  shift: GQLShiftUpdateInput;
}>;

export type GQLCalendarUpdateShiftMutation = {
  updateShift:
    | { id: number }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLEmploymentFragmentFragment = {
  id: number;
  firstName: string;
  lastName: string;
  isEmployee: boolean | null;
  userId: number;
  deletedAt: string | null;
  staffNumber: string | null;
  locationsPositionIds: Array<number> | null;
  qualificationIds: Array<number> | null;
  exitDate: string | null;
  pictureData: { pictureSmall: string | null } | null;
  employmentWorkHour: {
    hourType: GQLHourType | null;
    isMonthly: boolean | null;
    weeklyMinutes: number | null;
    monthlyMinutes: number | null;
    monMinutes: number | null;
    tueMinutes: number | null;
    wedMinutes: number | null;
    thuMinutes: number | null;
    friMinutes: number | null;
    satMinutes: number | null;
    sunMinutes: number | null;
    isWorkingOnFri: boolean | null;
    isWorkingOnMon: boolean | null;
    isWorkingOnSat: boolean | null;
    isWorkingOnSun: boolean | null;
    isWorkingOnThu: boolean | null;
    isWorkingOnTue: boolean | null;
    isWorkingOnWed: boolean | null;
  } | null;
};

export type GQLEmploymentsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId?: InputMaybe<Scalars['Int']>;
  employmentId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  locationsPositionIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  shouldFetchShiftRotationGroup?: InputMaybe<Scalars['Boolean']>;
  isEmployee?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLEmploymentsQuery = {
  employments: {
    items: Array<{
      id: number;
      firstName: string;
      lastName: string;
      isEmployee: boolean | null;
      userId: number;
      deletedAt: string | null;
      staffNumber: string | null;
      locationsPositionIds: Array<number> | null;
      qualificationIds: Array<number> | null;
      exitDate: string | null;
      employmentsShiftRotationGroups?: Array<{
        id: number;
        shiftRotationGroupId: number;
        startsAt: string | null;
        endsAt: string | null;
        shiftRotationGroup: {
          id: number;
          shiftPresetIds: Array<number>;
          shiftRotation: { anchorDate: string; rotationInterval: number };
        };
      }>;
      pictureData: { pictureSmall: string | null } | null;
      employmentWorkHour: {
        hourType: GQLHourType | null;
        isMonthly: boolean | null;
        weeklyMinutes: number | null;
        monthlyMinutes: number | null;
        monMinutes: number | null;
        tueMinutes: number | null;
        wedMinutes: number | null;
        thuMinutes: number | null;
        friMinutes: number | null;
        satMinutes: number | null;
        sunMinutes: number | null;
        isWorkingOnFri: boolean | null;
        isWorkingOnMon: boolean | null;
        isWorkingOnSat: boolean | null;
        isWorkingOnSun: boolean | null;
        isWorkingOnThu: boolean | null;
        isWorkingOnTue: boolean | null;
        isWorkingOnWed: boolean | null;
      } | null;
    }>;
    pagination: { count: number };
  };
};

export type GQLEmploymentsRotationGroupsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  employmentIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type GQLEmploymentsRotationGroupsQuery = {
  employmentsRotationGroups: {
    items: Array<
      | {
          __typename: 'EmploymentsRotationGroup';
          endsAt: string | null;
          id: number;
          startsAt: string | null;
          shiftRotationGroup: {
            id: number;
            name: string;
            shiftPresetIds: Array<number>;
            shiftPresets: Array<{
              color: string;
              id: number;
              name: string;
              shortName: string | null;
              startsAt: string;
            }>;
            shiftRotation: {
              anchorDate: string;
              id: number;
              name: string;
              rotationInterval: number;
            };
          };
        }
      | {
          __typename: 'IndividualShiftRotation';
          anchorDate: string;
          endsAt: string | null;
          id: number;
          rotationInterval: number;
          shiftPresetIds: Array<number>;
          startsAt: string | null;
          shiftPresets: Array<{
            color: string;
            id: number;
            name: string;
            shortName: string | null;
            startsAt: string;
          }>;
        }
    >;
  };
};

export type GQLShiftRotationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLShiftRotationsQuery = {
  shiftRotations: {
    items: Array<{
      id: number;
      name: string;
      shiftRotationGroups: Array<{ id: number; name: string }>;
    }>;
  };
};

export type GQLEmploymentsTableDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
  notInShiftRotationGroupIds: InputMaybe<
    Array<Scalars['Int']> | Scalars['Int']
  >;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  search: InputMaybe<Scalars['String']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  shiftRotationId: Scalars['Int'];
  orderKey: InputMaybe<GQLEmploymentSort>;
  orderDir: InputMaybe<GQLOrderDirection>;
}>;

export type GQLEmploymentsTableDataQuery = {
  employments: {
    items: Array<{
      id: number;
      staffNumber: string | null;
      firstName: string;
      lastName: string;
      pictureData: { pictureSmall: string | null } | null;
      employmentsShiftRotationGroups: Array<{
        id: number;
        shiftRotationGroup: { id: number; name: string };
      }>;
    }>;
    pagination: { count: number };
  };
};

export type GQLSidebarDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftRotationId: Scalars['Int'];
  notInShiftRotationGroupIds: InputMaybe<
    Array<Scalars['Int']> | Scalars['Int']
  >;
}>;

export type GQLSidebarDataQuery = {
  shiftRotationGroups: {
    items: Array<{
      id: number;
      name: string;
      shiftPresetIds: Array<number>;
      sortPosition: number | null;
      employmentsShiftRotationGroups: Array<{ id: number }>;
    }>;
  };
  employments: { pagination: { count: number } };
};

export type GQLCreateEmploymentsRotationGroupMutationVariables = Exact<{
  employmentsRotationGroup: GQLEmploymentsRotationGroupCreateInput;
}>;

export type GQLCreateEmploymentsRotationGroupMutation = {
  createEmploymentsRotationGroup: { id: number };
};

export type GQLCreateIndividualShiftRotationMutationVariables = Exact<{
  individualShiftRotation: GQLIndividualShiftRotationCreateInput;
}>;

export type GQLCreateIndividualShiftRotationMutation = {
  createIndividualShiftRotation: { id: number };
};

export type GQLCreateShiftRotationMutationVariables = Exact<{
  shiftRotation: GQLShiftRotationCreateInput;
}>;

export type GQLCreateShiftRotationMutation = {
  createShiftRotation: { id: number };
};

export type GQLCreateShiftRotationGroupMutationVariables = Exact<{
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
}>;

export type GQLCreateShiftRotationGroupMutation = {
  createShiftRotationGroup: { id: number };
};

export type GQLDeleteEmploymentsRotationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GQLDeleteEmploymentsRotationGroupMutation = {
  deleteEmploymentsRotationGroup: { success: boolean };
};

export type GQLFetchShiftRotationQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchShiftRotationQuery = {
  shiftRotations: {
    items: Array<{
      id: number;
      rotationInterval: number;
      startsAt: string | null;
      endsAt: string | null;
      anchorDate: string;
      name: string;
      shiftRotationGroups: Array<{
        id: number;
        name: string;
        shiftPresetIds: Array<number>;
        shiftPresets: Array<{
          id: number;
          color: string;
          name: string;
          shortName: string | null;
          startsAt: string;
        }>;
      }>;
    }>;
  };
};

export type GQLUpdateEmploymentsRotationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  employmentsRotationGroup: GQLEmploymentsRotationGroupUpdateInput;
  unassignmentStrategy: InputMaybe<GQLEmploymentsRotationGroupsUnassignFromShiftsStrategy>;
}>;

export type GQLUpdateEmploymentsRotationGroupMutation = {
  updateEmploymentsRotationGroup: { id: number };
};

export type GQLUpdateShiftRotationMutationVariables = Exact<{
  shiftRotation: GQLShiftRotationCreateInput;
  id: Scalars['Int'];
}>;

export type GQLUpdateShiftRotationMutation = {
  updateShiftRotation: { id: number };
};

export type GQLUpdateShiftRotationGroupMutationVariables = Exact<{
  shiftRotationGroup: GQLShiftRotationGroupCreateInput;
  id: Scalars['Int'];
}>;

export type GQLUpdateShiftRotationGroupMutation = {
  updateShiftRotationGroup: { id: number };
};

export type GQLEmploymentsFilterQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  search: InputMaybe<Scalars['String']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLEmploymentsFilterQuery = {
  employments: {
    items: Array<{
      id: number;
      firstName: string;
      lastName: string;
      staffNumber: string | null;
      deletedAt: string | null;
      pictureData: { pictureSmall: string | null } | null;
      locationsPositions: Array<{
        id: number;
        position: {
          id: number;
          name: string;
          color: string;
          deletedAt: string | null;
        } | null;
      } | null> | null;
      employmentWorkHour: {
        monthlyMinutes: number | null;
        weeklyMinutes: number | null;
      } | null;
      employmentsShiftRotationGroups: Array<{
        id: number;
        shiftRotationGroupId: number;
        startsAt: string | null;
        endsAt: string | null;
      }>;
    }>;
    pagination: { count: number };
  };
};

export type GQLAbsenceCollisionFragment = {
  id: number;
  startsAt: string;
  endsAt: string;
  isFullDay: boolean;
  absenceReason: { id: number; name: string; hasLocalization: boolean };
};

export type GQLShiftCollisionFragment = {
  id: number;
  startsAt: string;
  endsAt: string;
  locationsPosition: {
    id: number;
    location: { id: number; name: string } | null;
    position: {
      id: number;
      color: string;
      name: string;
      note: string | null;
    } | null;
  };
  shiftplan: { name: string };
};

export type GQLAbsenceReasonsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLAbsenceReasonsQuery = {
  absenceReasons: {
    items: Array<{
      deletedAt: string | null;
      hasLocalization: boolean;
      id: number;
      isAbsenceAttachmentsAllowed: boolean;
      name: string;
      carryOverDaysEnabled: boolean;
      carryOverDaysDeadline: string | null;
      shortName: string | null;
    }>;
  };
};

export type GQLAssignEmploymentToAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  assignEmploymentParams: GQLAssignmentGroupAssignEmploymentInput;
}>;

export type GQLAssignEmploymentToAssignmentGroupMutation = {
  assignEmploymentToAssignmentGroup: {
    shiftIds: Array<number | null>;
    staffShiftIds: Array<number | null>;
  };
};

export type GQLAssignmentGroupFragmentFragment = {
  id: number;
  createdAt: string;
  deletedAt: string | null;
  employmentCount: number | null;
  metadata: any | null;
  name: string;
  shiftCount: number | null;
  updatedAt: string;
  shiftplan: { id: number };
};

export type GQLAssignmentGroupsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLAssignmentGroupsQuery = {
  assignmentGroups: {
    items: Array<{
      id: number;
      createdAt: string;
      deletedAt: string | null;
      employmentCount: number | null;
      metadata: any | null;
      name: string;
      shiftCount: number | null;
      updatedAt: string;
      shiftplan: { id: number };
    }>;
  };
};

export type GQLCreateAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  assignmentGroup: GQLAssignmentGroupCreateInput;
}>;

export type GQLCreateAssignmentGroupMutation = {
  createAssignmentGroup: {
    id: number;
    createdAt: string;
    deletedAt: string | null;
    employmentCount: number | null;
    metadata: any | null;
    name: string;
    shiftCount: number | null;
    updatedAt: string;
    shiftplan: { id: number };
  };
};

export type GQLFetchAssignmentGroupQueryVariables = Exact<{
  companyId: Scalars['Int'];
  ids: InputMaybe<
    Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>
  >;
  shiftplanIds: InputMaybe<
    Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>
  >;
}>;

export type GQLFetchAssignmentGroupQuery = {
  assignmentGroups: {
    items: Array<{
      id: number;
      createdAt: string;
      deletedAt: string | null;
      employmentCount: number | null;
      metadata: any | null;
      name: string;
      shiftCount: number | null;
      updatedAt: string;
      shiftplan: { id: number };
    }>;
  };
};

export type GQLRemoveAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLRemoveAssignmentGroupMutation = {
  removeAssignmentGroup: { success: boolean; error: string | null };
};

export type GQLUnassignEmploymentFromAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  unassignEmploymentParams: GQLAssignmentGroupAssignEmploymentInput;
}>;

export type GQLUnassignEmploymentFromAssignmentGroupMutation = {
  unassignEmploymentFromAssignmentGroup: {
    shiftIds: Array<number | null>;
    staffShiftIds: Array<number | null>;
  };
};

export type GQLUpdateAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  assignmentGroup: GQLAssignmentGroupUpdateInput;
}>;

export type GQLUpdateAssignmentGroupMutation = {
  updateAssignmentGroup: {
    id: number;
    createdAt: string;
    deletedAt: string | null;
    employmentCount: number | null;
    metadata: any | null;
    name: string;
    shiftCount: number | null;
    updatedAt: string;
    shiftplan: { id: number };
  };
};

export type GQLBackgroundJobFragmentFragment = {
  id: string;
  jobIdentifier: string;
  state: GQLBackgroundJobState;
  startedAt: string | null;
  createdAt: string;
};

export type GQLBackgroundJobsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  ids: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  createdAfter: InputMaybe<Scalars['Date']>;
}>;

export type GQLBackgroundJobsQuery = {
  backgroundJobs: {
    items: Array<{
      id: string;
      jobIdentifier: string;
      state: GQLBackgroundJobState;
      startedAt: string | null;
      createdAt: string;
    }>;
  };
};

export type GQLCreateDayNoteMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
}>;

export type GQLCreateDayNoteMutation = {
  createDayNote: {
    id: number;
    description: string | null;
    endsAt: string;
    startsAt: string;
    title: string;
    shiftplan: { id: number };
  };
};

export type GQLDayNoteFragmentFragment = {
  id: number;
  description: string | null;
  endsAt: string;
  startsAt: string;
  title: string;
  shiftplan: { id: number };
};

export type GQLDayNotesQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLDayNotesQuery = {
  dayNotes: {
    items: Array<{
      id: number;
      description: string | null;
      endsAt: string;
      startsAt: string;
      title: string;
      shiftplan: { id: number };
    }>;
  };
};

export type GQLRemoveDayNoteMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLRemoveDayNoteMutation = {
  removeDayNote: { success: boolean; error: string | null };
};

export type GQLUpdateDayNoteMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
  id: Scalars['Int'];
  dayNote: GQLDayNoteCreateInput;
}>;

export type GQLUpdateDayNoteMutation = {
  updateDayNote: {
    id: number;
    description: string | null;
    endsAt: string;
    startsAt: string;
    title: string;
    shiftplan: { id: number };
  };
};

export type GQLCreateEmploymentEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLCreateEmploymentEvaluationBreakMutation = {
  createEmploymentEvaluationBreak: {
    id: number;
    endsAt: string | null;
    startsAt: string | null;
  };
};

export type GQLDeleteEmploymentEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
}>;

export type GQLDeleteEmploymentEvaluationBreakMutation = {
  deleteEmploymentEvaluationBreak: {
    id: number;
    endsAt: string | null;
    startsAt: string | null;
  };
};

export type GQLUpdateEmploymentEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLUpdateEmploymentEvaluationBreakMutation = {
  updateEmploymentEvaluationBreak: {
    id: number;
    endsAt: string | null;
    startsAt: string | null;
  };
};

export type GQLCreateEmploymentEvaluationPaymentMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
}>;

export type GQLCreateEmploymentEvaluationPaymentMutation = {
  createEmploymentEvaluationPayment: {
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    staffShiftId: number;
    type: string | null;
    value: number;
    childPayments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      type: string | null;
      value: number;
      paygradeType: { id: number; name: string; payType: string } | null;
    }> | null;
    paygradeType: { id: number; name: string; payType: string } | null;
  };
};

export type GQLDeleteEmploymentEvaluationPaymentMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  parentPaymentId: InputMaybe<Scalars['Int']>;
}>;

export type GQLDeleteEmploymentEvaluationPaymentMutation = {
  deleteEmploymentEvaluationPayment: { success: boolean; error: string | null };
};

export type GQLUpdateEmploymentEvaluationPaymentMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
}>;

export type GQLUpdateEmploymentEvaluationPaymentMutation = {
  updateEmploymentEvaluationPayment: {
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    staffShiftId: number;
    type: string | null;
    value: number;
    childPayments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      type: string | null;
      value: number;
      paygradeType: { id: number; name: string; payType: string } | null;
    }> | null;
    paygradeType: { id: number; name: string; payType: string } | null;
  };
};

export type GQLAcceptEmploymentEvaluationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  ignoreConflicts: Scalars['Boolean'];
}>;

export type GQLAcceptEmploymentEvaluationMutation = {
  acceptEmploymentEvaluation:
    | {
        id: number | null;
        break: number;
        untimedBreak: number | null;
        endsAt: string;
        startsAt: string;
        totalPayment: number | null;
        staffShiftId: number;
        state: GQLEvaluationState;
        staffShiftsTagsIds: Array<number>;
        note: string | null;
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          staffNumber: string | null;
          pictureData: { pictureSmall: string | null } | null;
        };
        location: { id: number } | null;
        locationsPosition: { id: number };
        payments: Array<{
          displayName: string | null;
          id: number;
          inheritedFrom: GQLPaygradeLevel | null;
          sumWithShift: number | null;
          staffShiftId: number;
          type: string | null;
          value: number;
          childPayments: Array<{
            displayName: string | null;
            id: number;
            inheritedFrom: GQLPaygradeLevel | null;
            sumWithShift: number | null;
            type: string | null;
            value: number;
            paygradeType: { id: number; name: string; payType: string } | null;
          }> | null;
          paygradeType: { id: number; name: string; payType: string } | null;
        }>;
        breaks: Array<{
          id: number;
          endsAt: string | null;
          startsAt: string | null;
        }>;
        shift: { id: number } | null;
      }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLCreateEmploymentEvaluationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  employmentEvaluation: GQLEvaluationInput;
}>;

export type GQLCreateEmploymentEvaluationMutation = {
  createEmploymentEvaluation:
    | {
        id: number;
        break: number;
        endsAt: string;
        staffShiftsTagsIds: Array<number>;
        startsAt: string;
        staffShiftId: number;
        untimedBreak: number | null;
        note: string | null;
        breaks: Array<{
          id: number;
          endsAt: string | null;
          startsAt: string | null;
        }>;
        payments: Array<{
          displayName: string | null;
          id: number;
          inheritedFrom: GQLPaygradeLevel | null;
          sumWithShift: number | null;
          staffShiftId: number;
          type: string | null;
          value: number;
          childPayments: Array<{
            displayName: string | null;
            id: number;
            inheritedFrom: GQLPaygradeLevel | null;
            sumWithShift: number | null;
            type: string | null;
            value: number;
            paygradeType: { id: number; name: string; payType: string } | null;
          }> | null;
          paygradeType: { id: number; name: string; payType: string } | null;
        }>;
      }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLDeleteEmploymentEvaluationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
}>;

export type GQLDeleteEmploymentEvaluationMutation = {
  deleteEmploymentEvaluation: { success: boolean; error: string | null };
};

export type GQLEmploymentEvaluationQueryVariables = Exact<{
  staffShiftId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLEmploymentEvaluationQuery = {
  employmentEvaluation: {
    id: number;
    break: number;
    endsAt: string;
    staffShiftsTagsIds: Array<number>;
    startsAt: string;
    staffShiftId: number;
    untimedBreak: number | null;
    note: string | null;
    breaks: Array<{
      id: number;
      endsAt: string | null;
      startsAt: string | null;
    }>;
    payments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      staffShiftId: number;
      type: string | null;
      value: number;
      childPayments: Array<{
        displayName: string | null;
        id: number;
        inheritedFrom: GQLPaygradeLevel | null;
        sumWithShift: number | null;
        type: string | null;
        value: number;
        paygradeType: { id: number; name: string; payType: string } | null;
      }> | null;
      paygradeType: { id: number; name: string; payType: string } | null;
    }>;
  } | null;
};

export type GQLEmploymentEvaluationFragmentFragment = {
  id: number;
  break: number;
  endsAt: string;
  staffShiftsTagsIds: Array<number>;
  startsAt: string;
  staffShiftId: number;
  untimedBreak: number | null;
  note: string | null;
  breaks: Array<{ id: number; endsAt: string | null; startsAt: string | null }>;
  payments: Array<{
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    staffShiftId: number;
    type: string | null;
    value: number;
    childPayments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      type: string | null;
      value: number;
      paygradeType: { id: number; name: string; payType: string } | null;
    }> | null;
    paygradeType: { id: number; name: string; payType: string } | null;
  }>;
};

export type GQLCreateEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLCreateEvaluationBreakMutation = {
  createEvaluationBreak: {
    id: number;
    endsAt: string | null;
    startsAt: string | null;
  };
};

export type GQLDeleteEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
}>;

export type GQLDeleteEvaluationBreakMutation = {
  deleteEvaluationBreak: { success: boolean; error: string | null };
};

export type GQLEvaluationBreakFragmentFragment = {
  id: number;
  endsAt: string | null;
  startsAt: string | null;
};

export type GQLUpdateEvaluationBreakMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluationBreakId: Scalars['Int'];
  evaluationBreak: GQLEvaluationBreakInput;
}>;

export type GQLUpdateEvaluationBreakMutation = {
  updateEvaluationBreak: {
    id: number;
    endsAt: string | null;
    startsAt: string | null;
  };
};

export type GQLCreateEvaluationPaymentMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  payment: GQLPaymentCreateInput;
}>;

export type GQLCreateEvaluationPaymentMutation = {
  createEvaluationPayment: {
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    staffShiftId: number;
    type: string | null;
    value: number;
    childPayments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      type: string | null;
      value: number;
      paygradeType: { id: number; name: string; payType: string } | null;
    }> | null;
    paygradeType: { id: number; name: string; payType: string } | null;
  };
};

export type GQLDeleteEvaluationPaymentMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  parentPaymentId: InputMaybe<Scalars['Int']>;
}>;

export type GQLDeleteEvaluationPaymentMutation = {
  deleteEvaluationPayment: { success: boolean; error: string | null };
};

export type GQLPaymentFragmentFragment = {
  displayName: string | null;
  id: number;
  inheritedFrom: GQLPaygradeLevel | null;
  sumWithShift: number | null;
  staffShiftId: number;
  type: string | null;
  value: number;
  childPayments: Array<{
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    type: string | null;
    value: number;
    paygradeType: { id: number; name: string; payType: string } | null;
  }> | null;
  paygradeType: { id: number; name: string; payType: string } | null;
};

export type GQLUpdateEvaluationPaymentMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  paymentId: Scalars['Int'];
  payment: GQLPaymentUpdateInput;
}>;

export type GQLUpdateEvaluationPaymentMutation = {
  updateEvaluationPayment: {
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    staffShiftId: number;
    type: string | null;
    value: number;
    childPayments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      type: string | null;
      value: number;
      paygradeType: { id: number; name: string; payType: string } | null;
    }> | null;
    paygradeType: { id: number; name: string; payType: string } | null;
  };
};

export type GQLCreateEvaluationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  evaluation: GQLEvaluationInput;
}>;

export type GQLCreateEvaluationMutation = {
  createEvaluation:
    | {
        id: number | null;
        break: number;
        untimedBreak: number | null;
        endsAt: string;
        startsAt: string;
        totalPayment: number | null;
        staffShiftId: number;
        state: GQLEvaluationState;
        staffShiftsTagsIds: Array<number>;
        note: string | null;
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          staffNumber: string | null;
          pictureData: { pictureSmall: string | null } | null;
        };
        location: { id: number } | null;
        locationsPosition: { id: number };
        payments: Array<{
          displayName: string | null;
          id: number;
          inheritedFrom: GQLPaygradeLevel | null;
          sumWithShift: number | null;
          staffShiftId: number;
          type: string | null;
          value: number;
          childPayments: Array<{
            displayName: string | null;
            id: number;
            inheritedFrom: GQLPaygradeLevel | null;
            sumWithShift: number | null;
            type: string | null;
            value: number;
            paygradeType: { id: number; name: string; payType: string } | null;
          }> | null;
          paygradeType: { id: number; name: string; payType: string } | null;
        }>;
        breaks: Array<{
          id: number;
          endsAt: string | null;
          startsAt: string | null;
        }>;
        shift: { id: number } | null;
      }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLEvaluationQueryVariables = Exact<{
  staffShiftId: Scalars['Int'];
}>;

export type GQLEvaluationQuery = {
  evaluations: {
    items: Array<{
      id: number | null;
      break: number;
      untimedBreak: number | null;
      endsAt: string;
      startsAt: string;
      totalPayment: number | null;
      staffShiftId: number;
      state: GQLEvaluationState;
      staffShiftsTagsIds: Array<number>;
      note: string | null;
      employment: {
        id: number;
        firstName: string;
        lastName: string;
        staffNumber: string | null;
        pictureData: { pictureSmall: string | null } | null;
      };
      location: { id: number } | null;
      locationsPosition: { id: number };
      payments: Array<{
        displayName: string | null;
        id: number;
        inheritedFrom: GQLPaygradeLevel | null;
        sumWithShift: number | null;
        staffShiftId: number;
        type: string | null;
        value: number;
        childPayments: Array<{
          displayName: string | null;
          id: number;
          inheritedFrom: GQLPaygradeLevel | null;
          sumWithShift: number | null;
          type: string | null;
          value: number;
          paygradeType: { id: number; name: string; payType: string } | null;
        }> | null;
        paygradeType: { id: number; name: string; payType: string } | null;
      }>;
      breaks: Array<{
        id: number;
        endsAt: string | null;
        startsAt: string | null;
      }>;
      shift: { id: number } | null;
    }>;
  };
};

export type GQLEvaluationFragmentFragment = {
  id: number | null;
  break: number;
  untimedBreak: number | null;
  endsAt: string;
  startsAt: string;
  totalPayment: number | null;
  staffShiftId: number;
  state: GQLEvaluationState;
  staffShiftsTagsIds: Array<number>;
  note: string | null;
  employment: {
    id: number;
    firstName: string;
    lastName: string;
    staffNumber: string | null;
    pictureData: { pictureSmall: string | null } | null;
  };
  location: { id: number } | null;
  locationsPosition: { id: number };
  payments: Array<{
    displayName: string | null;
    id: number;
    inheritedFrom: GQLPaygradeLevel | null;
    sumWithShift: number | null;
    staffShiftId: number;
    type: string | null;
    value: number;
    childPayments: Array<{
      displayName: string | null;
      id: number;
      inheritedFrom: GQLPaygradeLevel | null;
      sumWithShift: number | null;
      type: string | null;
      value: number;
      paygradeType: { id: number; name: string; payType: string } | null;
    }> | null;
    paygradeType: { id: number; name: string; payType: string } | null;
  }>;
  breaks: Array<{ id: number; endsAt: string | null; startsAt: string | null }>;
  shift: { id: number } | null;
};

export type GQLEvaluationsQueryVariables = Exact<{
  shiftId: Scalars['Int'];
}>;

export type GQLEvaluationsQuery = {
  evaluations: {
    items: Array<{
      id: number | null;
      break: number;
      untimedBreak: number | null;
      endsAt: string;
      startsAt: string;
      totalPayment: number | null;
      staffShiftId: number;
      state: GQLEvaluationState;
      staffShiftsTagsIds: Array<number>;
      note: string | null;
      employment: {
        id: number;
        firstName: string;
        lastName: string;
        staffNumber: string | null;
        pictureData: { pictureSmall: string | null } | null;
      };
      location: { id: number } | null;
      locationsPosition: { id: number };
      payments: Array<{
        displayName: string | null;
        id: number;
        inheritedFrom: GQLPaygradeLevel | null;
        sumWithShift: number | null;
        staffShiftId: number;
        type: string | null;
        value: number;
        childPayments: Array<{
          displayName: string | null;
          id: number;
          inheritedFrom: GQLPaygradeLevel | null;
          sumWithShift: number | null;
          type: string | null;
          value: number;
          paygradeType: { id: number; name: string; payType: string } | null;
        }> | null;
        paygradeType: { id: number; name: string; payType: string } | null;
      }>;
      breaks: Array<{
        id: number;
        endsAt: string | null;
        startsAt: string | null;
      }>;
      shift: { id: number } | null;
    }>;
  };
};

export type GQLUpdateEvaluationMutationVariables = Exact<{
  staffShiftId: Scalars['Int'];
  didShow: Scalars['Boolean'];
}>;

export type GQLUpdateEvaluationMutation = {
  updateEvaluation: { success: boolean; error: string | null };
};

export type GQLCreateLocationsPositionMutationVariables = Exact<{
  locationsPosition: GQLLocationsPositionCreateInput;
}>;

export type GQLCreateLocationsPositionMutation = {
  createLocationsPosition: {
    id: number;
    sort: number | null;
    location: { id: number; name: string } | null;
    position: {
      id: number;
      color: string;
      name: string;
      note: string | null;
    } | null;
  } | null;
};

export type GQLLocationsPositionFragmentFragment = {
  id: number;
  sort: number | null;
  location: { id: number; name: string } | null;
  position: {
    id: number;
    color: string;
    name: string;
    note: string | null;
  } | null;
};

export type GQLLocationsPositionsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  positionIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLLocationsPositionsQuery = {
  locationsPositions: {
    items: Array<{
      id: number;
      sort: number | null;
      location: { id: number; name: string } | null;
      position: {
        id: number;
        color: string;
        name: string;
        note: string | null;
      } | null;
    }>;
  };
};

export type GQLLocationFragmentFragment = { id: number; name: string };

export type GQLLocationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLLocationsQuery = {
  locations: { items: Array<{ id: number; name: string }> };
};

export type GQLPaygradeTypeFragmentFragment = {
  id: number;
  name: string;
  payType: string;
};

export type GQLPaygradeTypesQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLPaygradeTypesQuery = {
  paygradeTypes: {
    items: Array<{ id: number; name: string; payType: string }>;
  } | null;
};

export type GQLPaymentsCalculationQueryVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  input: GQLPaymentsCalculationInput;
}>;

export type GQLPaymentsCalculationQuery = {
  paymentsCalculation: {
    sum: number;
    payments: Array<{
      paygradeTypeId: number;
      totalValue: number;
      childPayments: Array<{
        paygradeTypeId: number;
        totalValue: number;
      }> | null;
    }> | null;
  };
};

export type GQLPaymentsCalculationFragmentFragment = {
  sum: number;
  payments: Array<{
    paygradeTypeId: number;
    totalValue: number;
    childPayments: Array<{ paygradeTypeId: number; totalValue: number }> | null;
  }> | null;
};

export type GQLCreatePositionMutationVariables = Exact<{
  position: GQLPositionCreateInput;
}>;

export type GQLCreatePositionMutation = {
  createPosition: {
    color: string;
    description: string | null;
    id: number;
    name: string;
    note: string | null;
    sort: number | null;
  } | null;
};

export type GQLPositionFragmentFragment = {
  color: string;
  description: string | null;
  id: number;
  name: string;
  note: string | null;
  sort: number | null;
};

export type GQLPositionsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLPositionsQuery = {
  positions: {
    items: Array<{
      color: string;
      description: string | null;
      id: number;
      name: string;
      note: string | null;
      sort: number | null;
    }>;
  };
};

export type GQLQualificationFragmentFragment = { id: number; name: string };

export type GQLQualificationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLQualificationsQuery = {
  qualifications: { items: Array<{ id: number; name: string }> };
};

export type GQLCreateShiftAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLCreateShiftAssignmentGroupMutation = {
  createShiftAssignmentGroup: {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    assignmentGroupId: number;
    shiftId: number;
  };
};

export type GQLDeleteShiftAssignmentGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  assignmentGroupId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLDeleteShiftAssignmentGroupMutation = {
  deleteShiftAssignmentGroup: { success: boolean; error: string | null };
};

export type GQLShiftAssignmentGroupFragmentFragment = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  assignmentGroupId: number;
  shiftId: number;
};

export type GQLShiftAvailableEmploymentFragmentFragment = {
  id: number;
  contractMinutes: number | null;
  hasConflicts: boolean;
  hourAccountHours: number | null;
  maximumMoney: number | null;
  scheduledMinutes: number | null;
  scheduledMoney: number | null;
  shiftId: number;
  workedMinutes: number | null;
  bringShiftHours: number | null;
  bringShiftHoursUsed: number | null;
  availabilityInfo: {
    partialAvailable: boolean;
    partialUnavailable: boolean;
    complete: boolean;
    fullAvailable: boolean;
    fullUnavailable: boolean;
    available: Array<{ endsAt: string; startsAt: string }> | null;
    unavailable: Array<{ endsAt: string; startsAt: string }> | null;
  };
  conflicts: Array<
    | {
        __typename: 'AbsencesCollisionShiftConflict';
        ignorable: boolean;
        absences: Array<{
          id: number;
          startsAt: string;
          endsAt: string;
          isFullDay: boolean;
          absenceReason: { id: number; name: string; hasLocalization: boolean };
        } | null> | null;
      }
    | {
        __typename: 'AlreadyAssignedShiftConflict';
        message: string;
        ignorable: boolean;
      }
    | { __typename: 'InsideUnavailableTimeShiftConflict'; ignorable: boolean }
    | {
        __typename: 'LocationsPositionChangedShiftConflict';
        message: string;
        ignorable: boolean;
      }
    | {
        __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
        employmentName: string | null;
        maxDays: number;
        ignorable: boolean;
      }
    | {
        __typename: 'MaximumEarningShiftConflict';
        employmentMaxPayment: number;
        totalPayment: number;
        currency: string;
        ignorable: boolean;
      }
    | {
        __typename: 'MaximumWorkingHoursShiftConflict';
        total: number | null;
        monthlyMinutes: number | null;
        weeklyMinutes: number | null;
        month: number | null;
        week: number | null;
        year: number | null;
        name: string | null;
        difference: number;
        ignorable: boolean;
      }
    | {
        __typename: 'MinimumBreakShiftConflict';
        minBreakTime: number;
        shiftDuration: number | null;
        ignorable: boolean;
      }
    | {
        __typename: 'MinimumRestShiftConflict';
        restMinutes: number;
        minimumRestMinutes: number;
        lastShiftEndsAt: string;
        newShiftStartsAt: string;
        ignorable: boolean;
      }
    | { __typename: 'OutsideAvailableTimeShiftConflict'; ignorable: boolean }
    | {
        __typename: 'OutsideWorkPeriodShiftConflict';
        startsAt: string | null;
        endsAt: string | null;
        ignorable: boolean;
      }
    | {
        __typename: 'RestrictedShiftPresetsConflict';
        restrictedShiftPresets: Array<string>;
        ignorable: boolean;
      }
    | {
        __typename: 'ShiftsCollisionShiftConflict';
        ignorable: boolean;
        shifts: Array<{
          id: number;
          startsAt: string;
          endsAt: string;
          locationsPosition: {
            id: number;
            location: { id: number; name: string } | null;
            position: {
              id: number;
              color: string;
              name: string;
              note: string | null;
            } | null;
          };
          shiftplan: { name: string };
        } | null> | null;
      }
    | {
        __typename: 'UnknownShiftConflict';
        data: any;
        message: string;
        ignorable: boolean;
      }
  > | null;
  employment: {
    id: number;
    firstName: string;
    hourEnabled: boolean | null;
    lastName: string;
    maximumMoney: number | null;
    maximumMoneyEnabled: boolean | null;
    staffNumber: string | null;
    qualificationIds: Array<number> | null;
    employmentWorkHour: {
      isMonthly: boolean | null;
      monthlyMinutes: number | null;
      weeklyMinutes: number | null;
    } | null;
    pictureData: { pictureSmall: string | null } | null;
    employmentsShiftRotationGroups: Array<{
      id: number;
      shiftRotationGroupId: number;
      startsAt: string | null;
      endsAt: string | null;
    }>;
  };
  joinRequest: { id: number; createdAt: string } | null;
};

export type GQLShiftAvailableEmploymentsQueryVariables = Exact<{
  availability: InputMaybe<GQLShiftEmploymentAvailabilityFilter>;
  companyId: Scalars['Int'];
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  searchString: InputMaybe<Scalars['String']>;
  shiftId: Scalars['Int'];
  withJoinRequests: InputMaybe<Scalars['Boolean']>;
  withoutAbsenceConflicts: InputMaybe<Scalars['Boolean']>;
  withoutShiftConflicts: InputMaybe<Scalars['Boolean']>;
  sortCriterion: InputMaybe<GQLShiftEmploymentSort>;
  sortOrder: InputMaybe<GQLOrderDirection>;
  withTheseQualificationIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLShiftAvailableEmploymentsQuery = {
  shiftAvailableEmployments: {
    items: Array<{
      id: number;
      contractMinutes: number | null;
      hasConflicts: boolean;
      hourAccountHours: number | null;
      maximumMoney: number | null;
      scheduledMinutes: number | null;
      scheduledMoney: number | null;
      shiftId: number;
      workedMinutes: number | null;
      bringShiftHours: number | null;
      bringShiftHoursUsed: number | null;
      availabilityInfo: {
        partialAvailable: boolean;
        partialUnavailable: boolean;
        complete: boolean;
        fullAvailable: boolean;
        fullUnavailable: boolean;
        available: Array<{ endsAt: string; startsAt: string }> | null;
        unavailable: Array<{ endsAt: string; startsAt: string }> | null;
      };
      conflicts: Array<
        | {
            __typename: 'AbsencesCollisionShiftConflict';
            ignorable: boolean;
            absences: Array<{
              id: number;
              startsAt: string;
              endsAt: string;
              isFullDay: boolean;
              absenceReason: {
                id: number;
                name: string;
                hasLocalization: boolean;
              };
            } | null> | null;
          }
        | {
            __typename: 'AlreadyAssignedShiftConflict';
            message: string;
            ignorable: boolean;
          }
        | {
            __typename: 'InsideUnavailableTimeShiftConflict';
            ignorable: boolean;
          }
        | {
            __typename: 'LocationsPositionChangedShiftConflict';
            message: string;
            ignorable: boolean;
          }
        | {
            __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
            employmentName: string | null;
            maxDays: number;
            ignorable: boolean;
          }
        | {
            __typename: 'MaximumEarningShiftConflict';
            employmentMaxPayment: number;
            totalPayment: number;
            currency: string;
            ignorable: boolean;
          }
        | {
            __typename: 'MaximumWorkingHoursShiftConflict';
            total: number | null;
            monthlyMinutes: number | null;
            weeklyMinutes: number | null;
            month: number | null;
            week: number | null;
            year: number | null;
            name: string | null;
            difference: number;
            ignorable: boolean;
          }
        | {
            __typename: 'MinimumBreakShiftConflict';
            minBreakTime: number;
            shiftDuration: number | null;
            ignorable: boolean;
          }
        | {
            __typename: 'MinimumRestShiftConflict';
            restMinutes: number;
            minimumRestMinutes: number;
            lastShiftEndsAt: string;
            newShiftStartsAt: string;
            ignorable: boolean;
          }
        | {
            __typename: 'OutsideAvailableTimeShiftConflict';
            ignorable: boolean;
          }
        | {
            __typename: 'OutsideWorkPeriodShiftConflict';
            startsAt: string | null;
            endsAt: string | null;
            ignorable: boolean;
          }
        | {
            __typename: 'RestrictedShiftPresetsConflict';
            restrictedShiftPresets: Array<string>;
            ignorable: boolean;
          }
        | {
            __typename: 'ShiftsCollisionShiftConflict';
            ignorable: boolean;
            shifts: Array<{
              id: number;
              startsAt: string;
              endsAt: string;
              locationsPosition: {
                id: number;
                location: { id: number; name: string } | null;
                position: {
                  id: number;
                  color: string;
                  name: string;
                  note: string | null;
                } | null;
              };
              shiftplan: { name: string };
            } | null> | null;
          }
        | {
            __typename: 'UnknownShiftConflict';
            data: any;
            message: string;
            ignorable: boolean;
          }
      > | null;
      employment: {
        id: number;
        firstName: string;
        hourEnabled: boolean | null;
        lastName: string;
        maximumMoney: number | null;
        maximumMoneyEnabled: boolean | null;
        staffNumber: string | null;
        qualificationIds: Array<number> | null;
        employmentWorkHour: {
          isMonthly: boolean | null;
          monthlyMinutes: number | null;
          weeklyMinutes: number | null;
        } | null;
        pictureData: { pictureSmall: string | null } | null;
        employmentsShiftRotationGroups: Array<{
          id: number;
          shiftRotationGroupId: number;
          startsAt: string | null;
          endsAt: string | null;
        }>;
      };
      joinRequest: { id: number; createdAt: string } | null;
    }>;
    pagination: { count: number };
  };
};

export type GQLEmploymentsInfoQueryVariables = Exact<{
  companyId: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  searchString: InputMaybe<Scalars['String']>;
  locationIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  shiftRotationGroupIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationsPositionIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employmentIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  sortName: InputMaybe<GQLShiftEmploymentSort>;
  sortDirection: InputMaybe<GQLOrderDirection>;
}>;

export type GQLEmploymentsInfoQuery = {
  employmentsInfo: {
    items: Array<{
      id: number;
      contractMinutes: number | null;
      scheduledMinutes: number | null;
      workedMinutes: number | null;
      hourAccountHours: number | null;
      scheduledMoney: number | null;
      maximumMoney: number | null;
      bringShiftHours: number | null;
      bringShiftHoursUsed: number | null;
      employment: {
        id: number;
        locationsPositionIds: Array<number> | null;
        deletedAt: string | null;
        firstName: string;
        hourEnabled: boolean | null;
        lastName: string;
        maximumMoney: number | null;
        maximumMoneyEnabled: boolean | null;
        staffNumber: string | null;
        qualificationIds: Array<number> | null;
        employmentWorkHour: {
          isMonthly: boolean | null;
          monthlyMinutes: number | null;
          weeklyMinutes: number | null;
        } | null;
        pictureData: { pictureSmall: string | null } | null;
        employmentsShiftRotationGroups: Array<{
          id: number;
          shiftRotationGroupId: number;
          startsAt: string | null;
          endsAt: string | null;
        }>;
      };
    }>;
    pagination: { count: number };
  };
};

export type GQLCreateShiftEvaluationTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLCreateShiftEvaluationTagMutation = {
  createShiftEvaluationTag: {
    tag: {
      color: string;
      id: number;
      name: string;
      context: GQLTagContext;
      textColor: string | null;
      location: { id: number } | null;
    };
  };
};

export type GQLDeleteShiftEvaluationTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLDeleteShiftEvaluationTagMutation = {
  deleteShiftEvaluationTag: { success: boolean; error: string | null };
};

export type GQLShiftEvaluationTagFragmentFragment = {
  tag: {
    color: string;
    id: number;
    name: string;
    context: GQLTagContext;
    textColor: string | null;
    location: { id: number } | null;
  };
};

export type GQLShiftEvaluationTagsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLShiftEvaluationTagsQuery = {
  shiftEvaluationTags: {
    items: Array<{
      color: string;
      id: number;
      name: string;
      context: GQLTagContext;
      textColor: string | null;
      location: { id: number } | null;
    }>;
  };
};

export type GQLCreateShiftPaygradeMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
}>;

export type GQLCreateShiftPaygradeMutation = {
  createShiftPaygrade: {
    id: number;
    paygradeForType: GQLPaygradeLevel;
    paygradeForId: number;
    shiftId: number;
    value: number;
    paygradeType: { id: number; name: string; payType: string };
    childPaygrades: Array<{
      id: number;
      paygradeForType: GQLPaygradeLevel;
      paygradeForId: number;
      shiftId: number;
      value: number;
      paygradeType: { id: number; name: string; payType: string };
    }> | null;
  };
};

export type GQLDeleteShiftPaygradeMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLDeleteShiftPaygradeMutation = {
  removeShiftPaygrade: { success: boolean; error: string | null };
};

export type GQLShiftPaygradeFragmentFragment = {
  id: number;
  paygradeForType: GQLPaygradeLevel;
  paygradeForId: number;
  shiftId: number;
  value: number;
  paygradeType: { id: number; name: string; payType: string };
  childPaygrades: Array<{
    id: number;
    paygradeForType: GQLPaygradeLevel;
    paygradeForId: number;
    shiftId: number;
    value: number;
    paygradeType: { id: number; name: string; payType: string };
  }> | null;
};

export type GQLShiftPaygradesQueryVariables = Exact<{
  shiftId: Scalars['Int'];
}>;

export type GQLShiftPaygradesQuery = {
  shiftPaygrades: {
    items: Array<{
      id: number;
      paygradeForType: GQLPaygradeLevel;
      paygradeForId: number;
      shiftId: number;
      value: number;
      paygradeType: { id: number; name: string; payType: string };
      childPaygrades: Array<{
        id: number;
        paygradeForType: GQLPaygradeLevel;
        paygradeForId: number;
        shiftId: number;
        value: number;
        paygradeType: { id: number; name: string; payType: string };
      }> | null;
    }>;
  };
};

export type GQLUpdateShiftPaygradeMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  id: Scalars['Int'];
  paygrade: GQLShiftPaygradeCreateInput;
}>;

export type GQLUpdateShiftPaygradeMutation = {
  updateShiftPaygrade: {
    id: number;
    paygradeForType: GQLPaygradeLevel;
    paygradeForId: number;
    shiftId: number;
    value: number;
    paygradeType: { id: number; name: string; payType: string };
    childPaygrades: Array<{
      id: number;
      paygradeForType: GQLPaygradeLevel;
      paygradeForId: number;
      shiftId: number;
      value: number;
      paygradeType: { id: number; name: string; payType: string };
    }> | null;
  };
};

export type GQLUpdateShiftPaygradesMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
  paygradeParams: GQLShiftPaygradesUpdateInput;
}>;

export type GQLUpdateShiftPaygradesMutation = {
  updateShiftPaygrades: Array<{
    id: number;
    paygradeForType: GQLPaygradeLevel;
    paygradeForId: number;
    shiftId: number;
    value: number;
    paygradeType: { id: number; name: string; payType: string };
    childPaygrades: Array<{
      id: number;
      paygradeForType: GQLPaygradeLevel;
      paygradeForId: number;
      shiftId: number;
      value: number;
      paygradeType: { id: number; name: string; payType: string };
    }> | null;
  }>;
};

export type GQLShiftPresetFragmentFragment = {
  id: number;
  color: string;
  name: string;
  shortName: string | null;
  startsAt: string;
  startsAtTime: string;
  endsAt: string;
  endsAtTime: string;
  tagIds: Array<number>;
  shiftEvaluationTagIds: Array<number>;
  breakTime: number;
};

export type GQLShiftPresetsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GQLShiftPresetsQuery = {
  shiftPresets: {
    items: Array<{
      id: number;
      color: string;
      name: string;
      shortName: string | null;
      startsAt: string;
      startsAtTime: string;
      endsAt: string;
      endsAtTime: string;
      tagIds: Array<number>;
      shiftEvaluationTagIds: Array<number>;
      breakTime: number;
    }>;
  };
};

export type GQLShiftRotationFragmentFragment = {
  id: number;
  rotationInterval: number;
  startsAt: string | null;
  endsAt: string | null;
  anchorDate: string;
  name: string;
  shiftRotationGroups: Array<{
    id: number;
    name: string;
    sortPosition: number | null;
    shiftPresetIds: Array<number>;
  }>;
};

export type GQLShiftRotationGroupFragmentFragment = {
  id: number;
  name: string;
  sortPosition: number | null;
  shiftPresetIds: Array<number>;
};

export type GQLFetchAllShiftRotationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLFetchAllShiftRotationsQuery = {
  shiftRotations: {
    items: Array<{
      id: number;
      rotationInterval: number;
      startsAt: string | null;
      endsAt: string | null;
      anchorDate: string;
      name: string;
      shiftRotationGroups: Array<{
        id: number;
        name: string;
        sortPosition: number | null;
        shiftPresetIds: Array<number>;
      }>;
    }>;
  };
};

export type GQLCreateShiftplanNotificationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateNotificationInput;
}>;

export type GQLCreateShiftplanNotificationMutation = {
  createShiftplanNotification: { success: boolean; error: string | null };
};

export type GQLCreateShiftplanMessageMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanCreateMessageInput;
}>;

export type GQLCreateShiftplanMessageMutation = {
  createShiftplanMessage: { success: boolean; error: string | null };
};

export type GQLShiftplanNotificationsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLShiftplanNotificationsQuery = {
  shiftplanNotifications: { count: number };
};

export type GQLLocationShiftplanPublishSettingQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
}>;

export type GQLLocationShiftplanPublishSettingQuery = {
  locationShiftplanPublishSetting: {
    companyId: number;
    locationId: number;
    sendAssignedShifts: boolean;
    sendOpenShifts: boolean;
    sendMessage: boolean;
    message: string;
  };
};

export type GQLUpdateLocationShiftplanPublishSettingMutationVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
  publishSettingsParams: GQLPublishSettingsParams;
}>;

export type GQLUpdateLocationShiftplanPublishSettingMutation = {
  updateLocationShiftplanPublishSetting: {
    companyId: number;
    locationId: number;
    sendAssignedShifts: boolean;
    sendOpenShifts: boolean;
    sendMessage: boolean;
    message: string;
  };
};

export type GQLApplyRotationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLApplyShiftplanRotationInput;
}>;

export type GQLApplyRotationMutation = {
  applyShiftplanRotation: { id: number; jobId: string };
};

export type GQLAssignEmploymentsToOpenShiftsMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLAssignEmploymentsToOpenShiftsMutation = {
  assignEmploymentsToOpenShifts: {
    id: string;
    jobIdentifier: string;
    result: any | null;
    errors: Array<any> | null;
  };
};

export type GQLCopyShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  copyShiftplanParams: GQLShiftplanCopyInput;
}>;

export type GQLCopyShiftplanMutation = {
  copyShiftplan: {
    id: string;
    jobIdentifier: string;
    result: any | null;
    errors: Array<any> | null;
  };
};

export type GQLCreateShiftplanMutationVariables = Exact<{
  input: GQLShiftplanCreateInput;
}>;

export type GQLCreateShiftplanMutation = {
  createShiftplan: {
    endsAt: string;
    id: number;
    name: string;
    startsAt: string;
    state: GQLShiftplanState;
    locationId: number | null;
    locked: boolean | null;
  };
};

export type GQLCreateShiftplanFromShiftRotationMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftRotationId: Scalars['Int'];
  input: GQLShiftRotationShiftplanCreateInput;
}>;

export type GQLCreateShiftplanFromShiftRotationMutation = {
  createShiftplanFromShiftRotation: { id: number; jobId: string };
};

export type GQLDeleteShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLDeleteShiftplanMutation = {
  deleteShiftplan: { success: boolean; error: string | null };
};

export type GQLFetchShiftplanQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchShiftplanQuery = {
  shiftplans: {
    items: Array<{
      endsAt: string;
      id: number;
      name: string;
      startsAt: string;
      state: GQLShiftplanState;
      locationId: number | null;
      locked: boolean | null;
    }>;
  };
};

export type GQLImportShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  importShiftplanParams: GQLShiftplanImportInput;
}>;

export type GQLImportShiftplanMutation = {
  importShiftplan: {
    id: string;
    jobIdentifier: string;
    result: any | null;
    errors: Array<any> | null;
  };
};

export type GQLPublishShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  input: GQLShiftplanPublishInput;
}>;

export type GQLPublishShiftplanMutation = {
  publishShiftplan: { success: boolean; error: string | null };
};

export type GQLShiftplanFragmentFragment = {
  endsAt: string;
  id: number;
  name: string;
  startsAt: string;
  state: GQLShiftplanState;
  locationId: number | null;
  locked: boolean | null;
};

export type GQLShiftplansQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: InputMaybe<Scalars['Int']>;
}>;

export type GQLShiftplansQuery = {
  shiftplans: {
    items: Array<{
      endsAt: string;
      id: number;
      name: string;
      startsAt: string;
      state: GQLShiftplanState;
      locationId: number | null;
      locked: boolean | null;
    }>;
  };
};

export type GQLUpdateShiftplanMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  shiftplan: GQLShiftplanUpdateInput;
}>;

export type GQLUpdateShiftplanMutation = {
  updateShiftplan: {
    endsAt: string;
    id: number;
    name: string;
    startsAt: string;
    state: GQLShiftplanState;
    locationId: number | null;
    locked: boolean | null;
  };
};

export type GQLAcceptReplaceOfferMutationVariables = Exact<{
  companyId: Scalars['Int'];
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
  requestId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLAcceptReplaceOfferMutation = {
  acceptReplaceOffer:
    | { id: number | null; state: GQLReplaceRequestState | null }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
          | null
        > | null;
      }
    | null;
};

export type GQLAddTagToShiftMutationVariables = Exact<{
  tagId: Scalars['Int'];
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
}>;

export type GQLAddTagToShiftMutation = { addTagToShift: { id: number | null } };

export type GQLAssignEmployeeToShiftRotationGroupMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftRotationGroupAssignInput: GQLShiftRotationGroupAssignInput;
}>;

export type GQLAssignEmployeeToShiftRotationGroupMutation = {
  assignEmployeeToShiftRotationGroup: { jobId: string };
};

export type GQLConfirmReplaceRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  employmentId: Scalars['Int'];
  id: Scalars['Int'];
  ignoreConflicts: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLConfirmReplaceRequestMutation = {
  confirmReplaceRequest:
    | { id: number | null; state: GQLReplaceRequestState | null }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
          | null
        > | null;
      }
    | null;
};

export type GQLConnectShiftsMutationVariables = Exact<{
  shiftIds: Array<Scalars['Int']> | Scalars['Int'];
  shiftplanId: Scalars['Int'];
}>;

export type GQLConnectShiftsMutation = {
  connectShifts: { success: boolean; error: string | null };
};

export type GQLCopyShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  copyParams: GQLShiftCopyInput;
  isShiftEvaluationTagsIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLCopyShiftMutation = {
  copyShift:
    | {
        id: number;
        canEvaluate: boolean;
        canManage: boolean | null;
        endsAt: string;
        breakTime: number;
        untimedBreakTime: number;
        staffShiftsNeedsEvaluationCount: number | null;
        note: string | null;
        connectedGroupId: number | null;
        workers: number;
        shiftRotationGroupIds: Array<number>;
        autoAccept: GQLAutoAcceptRequestSetting;
        untimed: boolean | null;
        managerNote: string | null;
        staffShiftsCount: number | null;
        startsAt: string;
        assignmentGroupIds: Array<number>;
        tags: Array<{ id: number; name: string; color: string }>;
        shiftEvaluationTags?: Array<{
          id: number;
          name: string;
          color: string;
        }>;
        shiftRotationGroups: Array<{
          id: number;
          name: string;
          shiftRotation: { id: number; name: string };
        }>;
        shiftPreset: {
          id: number;
          name: string;
          color: string;
          shortName: string | null;
          startsAtTime: string;
          endsAtTime: string;
        } | null;
        locationsPosition: {
          id: number;
          sort: number | null;
          location: { id: number; name: string } | null;
          position: {
            id: number;
            color: string;
            name: string;
            note: string | null;
          } | null;
        };
        requests: Array<{
          id: number;
          acceptedBy: Array<number | null> | null;
          automationCheckedAt: string | null;
          createdAt: string;
          state: GQLReplaceRequestState | null;
          type: GQLRequestType | null;
          employment: {
            id: number;
            firstName: string;
            lastName: string;
            staffNumber: string | null;
            qualificationIds: Array<number> | null;
            pictureData: { pictureSmall: string | null } | null;
          } | null;
        }>;
        shiftBreaks: Array<{
          id: number;
          startsAt: string;
          endsAt: string | null;
        }>;
        staffShifts: Array<{
          id: number;
          shiftAssignmentGroupId: number | null;
          acceptedAt: string | null;
          state: GQLStaffShiftState;
          employment: {
            id: number;
            firstName: string;
            lastName: string;
            isEmployee: boolean | null;
            userId: number;
            deletedAt: string | null;
            staffNumber: string | null;
            locationsPositionIds: Array<number> | null;
            qualificationIds: Array<number> | null;
            exitDate: string | null;
            pictureData: { pictureSmall: string | null } | null;
            employmentWorkHour: {
              hourType: GQLHourType | null;
              isMonthly: boolean | null;
              weeklyMinutes: number | null;
              monthlyMinutes: number | null;
              monMinutes: number | null;
              tueMinutes: number | null;
              wedMinutes: number | null;
              thuMinutes: number | null;
              friMinutes: number | null;
              satMinutes: number | null;
              sunMinutes: number | null;
              isWorkingOnFri: boolean | null;
              isWorkingOnMon: boolean | null;
              isWorkingOnSat: boolean | null;
              isWorkingOnSun: boolean | null;
              isWorkingOnThu: boolean | null;
              isWorkingOnTue: boolean | null;
              isWorkingOnWed: boolean | null;
            } | null;
          } | null;
        }> | null;
        shiftplan: {
          endsAt: string;
          id: number;
          name: string;
          startsAt: string;
          state: GQLShiftplanState;
          locationId: number | null;
          locked: boolean | null;
        };
        shiftQualifications: Array<{
          count: number;
          qualificationId: number;
          qualification: { name: string; id: number };
        }>;
        shiftAssignmentGroups: Array<{
          id: number;
          shiftId: number;
          assignmentGroupId: number;
        }>;
      }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLCreateJoinRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  ignoreConflicts?: InputMaybe<Scalars['Boolean']>;
  shiftId: Scalars['Int'];
}>;

export type GQLCreateJoinRequestMutation = {
  createJoinRequest: {
    success: boolean;
    errorKey: string | null;
    warning: string | null;
    conflicts: Array<
      | {
          __typename: 'AbsencesCollisionShiftConflict';
          ignorable: boolean;
          absences: Array<{
            id: number;
            startsAt: string;
            endsAt: string;
            isFullDay: boolean;
            absenceReason: {
              id: number;
              name: string;
              hasLocalization: boolean;
            };
          } | null> | null;
        }
      | {
          __typename: 'AlreadyAssignedShiftConflict';
          message: string;
          ignorable: boolean;
        }
      | { __typename: 'InsideUnavailableTimeShiftConflict'; ignorable: boolean }
      | {
          __typename: 'LocationsPositionChangedShiftConflict';
          message: string;
          ignorable: boolean;
        }
      | {
          __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
          employmentName: string | null;
          maxDays: number;
          ignorable: boolean;
        }
      | {
          __typename: 'MaximumEarningShiftConflict';
          employmentMaxPayment: number;
          totalPayment: number;
          currency: string;
          ignorable: boolean;
        }
      | {
          __typename: 'MaximumWorkingHoursShiftConflict';
          total: number | null;
          monthlyMinutes: number | null;
          weeklyMinutes: number | null;
          month: number | null;
          week: number | null;
          year: number | null;
          name: string | null;
          difference: number;
          ignorable: boolean;
        }
      | {
          __typename: 'MinimumBreakShiftConflict';
          minBreakTime: number;
          shiftDuration: number | null;
          ignorable: boolean;
        }
      | {
          __typename: 'MinimumRestShiftConflict';
          restMinutes: number;
          minimumRestMinutes: number;
          lastShiftEndsAt: string;
          newShiftStartsAt: string;
          ignorable: boolean;
        }
      | { __typename: 'OutsideAvailableTimeShiftConflict'; ignorable: boolean }
      | {
          __typename: 'OutsideWorkPeriodShiftConflict';
          startsAt: string | null;
          endsAt: string | null;
          ignorable: boolean;
        }
      | {
          __typename: 'RestrictedShiftPresetsConflict';
          restrictedShiftPresets: Array<string>;
          ignorable: boolean;
        }
      | {
          __typename: 'ShiftsCollisionShiftConflict';
          ignorable: boolean;
          shifts: Array<{
            id: number;
            startsAt: string;
            endsAt: string;
            locationsPosition: {
              id: number;
              location: { id: number; name: string } | null;
              position: {
                id: number;
                color: string;
                name: string;
                note: string | null;
              } | null;
            };
            shiftplan: { name: string };
          } | null> | null;
        }
      | {
          __typename: 'UnknownShiftConflict';
          data: any;
          message: string;
          ignorable: boolean;
        }
      | null
    > | null;
  } | null;
};

export type GQLCreateLeaveRequestMutationVariables = Exact<{
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLCreateLeaveRequestMutation = {
  createLeaveRequest: { success: boolean } | null;
};

export type GQLCreateReplaceRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLCreateReplaceRequestMutation = {
  createReplaceRequest: { id: number } | null;
};

export type GQLCreateShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  isShiftEvaluationTagsIncluded?: InputMaybe<Scalars['Boolean']>;
  shift: GQLShiftCreateInput;
}>;

export type GQLCreateShiftMutation = {
  createShift:
    | {
        id: number;
        canEvaluate: boolean;
        canManage: boolean | null;
        endsAt: string;
        breakTime: number;
        untimedBreakTime: number;
        staffShiftsNeedsEvaluationCount: number | null;
        note: string | null;
        connectedGroupId: number | null;
        workers: number;
        shiftRotationGroupIds: Array<number>;
        autoAccept: GQLAutoAcceptRequestSetting;
        untimed: boolean | null;
        managerNote: string | null;
        staffShiftsCount: number | null;
        startsAt: string;
        assignmentGroupIds: Array<number>;
        tags: Array<{ id: number; name: string; color: string }>;
        shiftEvaluationTags?: Array<{
          id: number;
          name: string;
          color: string;
        }>;
        shiftRotationGroups: Array<{
          id: number;
          name: string;
          shiftRotation: { id: number; name: string };
        }>;
        shiftPreset: {
          id: number;
          name: string;
          color: string;
          shortName: string | null;
          startsAtTime: string;
          endsAtTime: string;
        } | null;
        locationsPosition: {
          id: number;
          sort: number | null;
          location: { id: number; name: string } | null;
          position: {
            id: number;
            color: string;
            name: string;
            note: string | null;
          } | null;
        };
        requests: Array<{
          id: number;
          acceptedBy: Array<number | null> | null;
          automationCheckedAt: string | null;
          createdAt: string;
          state: GQLReplaceRequestState | null;
          type: GQLRequestType | null;
          employment: {
            id: number;
            firstName: string;
            lastName: string;
            staffNumber: string | null;
            qualificationIds: Array<number> | null;
            pictureData: { pictureSmall: string | null } | null;
          } | null;
        }>;
        shiftBreaks: Array<{
          id: number;
          startsAt: string;
          endsAt: string | null;
        }>;
        staffShifts: Array<{
          id: number;
          shiftAssignmentGroupId: number | null;
          acceptedAt: string | null;
          state: GQLStaffShiftState;
          employment: {
            id: number;
            firstName: string;
            lastName: string;
            isEmployee: boolean | null;
            userId: number;
            deletedAt: string | null;
            staffNumber: string | null;
            locationsPositionIds: Array<number> | null;
            qualificationIds: Array<number> | null;
            exitDate: string | null;
            pictureData: { pictureSmall: string | null } | null;
            employmentWorkHour: {
              hourType: GQLHourType | null;
              isMonthly: boolean | null;
              weeklyMinutes: number | null;
              monthlyMinutes: number | null;
              monMinutes: number | null;
              tueMinutes: number | null;
              wedMinutes: number | null;
              thuMinutes: number | null;
              friMinutes: number | null;
              satMinutes: number | null;
              sunMinutes: number | null;
              isWorkingOnFri: boolean | null;
              isWorkingOnMon: boolean | null;
              isWorkingOnSat: boolean | null;
              isWorkingOnSun: boolean | null;
              isWorkingOnThu: boolean | null;
              isWorkingOnTue: boolean | null;
              isWorkingOnWed: boolean | null;
            } | null;
          } | null;
        }> | null;
        shiftplan: {
          endsAt: string;
          id: number;
          name: string;
          startsAt: string;
          state: GQLShiftplanState;
          locationId: number | null;
          locked: boolean | null;
        };
        shiftQualifications: Array<{
          count: number;
          qualificationId: number;
          qualification: { name: string; id: number };
        }>;
        shiftAssignmentGroups: Array<{
          id: number;
          shiftId: number;
          assignmentGroupId: number;
        }>;
      }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLCreateShiftBreakMutationVariables = Exact<{
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
  startsAt: Scalars['Date'];
  endsAt: Scalars['Date'];
}>;

export type GQLCreateShiftBreakMutation = {
  createShiftBreak: {
    id: number;
    startsAt: string;
    endsAt: string | null;
    shiftId: number;
  };
};

export type GQLDeleteRequestMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  shiftId: Scalars['Int'];
}>;

export type GQLDeleteRequestMutation = {
  deleteRequest: { success: boolean; error: string | null } | null;
};

export type GQLDeleteShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  deleteParams: GQLShiftDeleteInput;
}>;

export type GQLDeleteShiftMutation = {
  deleteShift: { success: boolean; error: string | null };
};

export type GQLDisconnectShiftMutationVariables = Exact<{
  shiftId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLDisconnectShiftMutation = {
  disconnectShift: { success: boolean; error: string | null };
};

export type GQLFetchAllShiftsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  perPage: InputMaybe<Scalars['Int']>;
  shiftplanId: InputMaybe<Scalars['Int']>;
  isShiftEvaluationTagsIncluded?: InputMaybe<Scalars['Boolean']>;
  startsAt: InputMaybe<Scalars['Date']>;
  endsAt: InputMaybe<Scalars['Date']>;
  connectedGroupId?: InputMaybe<Scalars['Int']>;
  assignmentGroupId?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLFetchAllShiftsQuery = {
  shifts: {
    items: Array<{
      id: number;
      canEvaluate: boolean;
      canManage: boolean | null;
      endsAt: string;
      breakTime: number;
      untimedBreakTime: number;
      staffShiftsNeedsEvaluationCount: number | null;
      note: string | null;
      connectedGroupId: number | null;
      workers: number;
      shiftRotationGroupIds: Array<number>;
      autoAccept: GQLAutoAcceptRequestSetting;
      untimed: boolean | null;
      managerNote: string | null;
      staffShiftsCount: number | null;
      startsAt: string;
      assignmentGroupIds: Array<number>;
      tags: Array<{ id: number; name: string; color: string }>;
      shiftEvaluationTags?: Array<{ id: number; name: string; color: string }>;
      shiftRotationGroups: Array<{
        id: number;
        name: string;
        shiftRotation: { id: number; name: string };
      }>;
      shiftPreset: {
        id: number;
        name: string;
        color: string;
        shortName: string | null;
        startsAtTime: string;
        endsAtTime: string;
      } | null;
      locationsPosition: {
        id: number;
        sort: number | null;
        location: { id: number; name: string } | null;
        position: {
          id: number;
          color: string;
          name: string;
          note: string | null;
        } | null;
      };
      requests: Array<{
        id: number;
        acceptedBy: Array<number | null> | null;
        automationCheckedAt: string | null;
        createdAt: string;
        state: GQLReplaceRequestState | null;
        type: GQLRequestType | null;
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          staffNumber: string | null;
          qualificationIds: Array<number> | null;
          pictureData: { pictureSmall: string | null } | null;
        } | null;
      }>;
      shiftBreaks: Array<{
        id: number;
        startsAt: string;
        endsAt: string | null;
      }>;
      staffShifts: Array<{
        id: number;
        shiftAssignmentGroupId: number | null;
        acceptedAt: string | null;
        state: GQLStaffShiftState;
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          isEmployee: boolean | null;
          userId: number;
          deletedAt: string | null;
          staffNumber: string | null;
          locationsPositionIds: Array<number> | null;
          qualificationIds: Array<number> | null;
          exitDate: string | null;
          pictureData: { pictureSmall: string | null } | null;
          employmentWorkHour: {
            hourType: GQLHourType | null;
            isMonthly: boolean | null;
            weeklyMinutes: number | null;
            monthlyMinutes: number | null;
            monMinutes: number | null;
            tueMinutes: number | null;
            wedMinutes: number | null;
            thuMinutes: number | null;
            friMinutes: number | null;
            satMinutes: number | null;
            sunMinutes: number | null;
            isWorkingOnFri: boolean | null;
            isWorkingOnMon: boolean | null;
            isWorkingOnSat: boolean | null;
            isWorkingOnSun: boolean | null;
            isWorkingOnThu: boolean | null;
            isWorkingOnTue: boolean | null;
            isWorkingOnWed: boolean | null;
          } | null;
        } | null;
      }> | null;
      shiftplan: {
        endsAt: string;
        id: number;
        name: string;
        startsAt: string;
        state: GQLShiftplanState;
        locationId: number | null;
        locked: boolean | null;
      };
      shiftQualifications: Array<{
        count: number;
        qualificationId: number;
        qualification: { name: string; id: number };
      }>;
      shiftAssignmentGroups: Array<{
        id: number;
        shiftId: number;
        assignmentGroupId: number;
      }>;
    }>;
  };
};

export type GQLFetchShiftQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  isShiftEvaluationTagsIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLFetchShiftQuery = {
  shifts: {
    items: Array<{
      id: number;
      canEvaluate: boolean;
      canManage: boolean | null;
      endsAt: string;
      breakTime: number;
      untimedBreakTime: number;
      staffShiftsNeedsEvaluationCount: number | null;
      note: string | null;
      connectedGroupId: number | null;
      workers: number;
      shiftRotationGroupIds: Array<number>;
      autoAccept: GQLAutoAcceptRequestSetting;
      untimed: boolean | null;
      managerNote: string | null;
      staffShiftsCount: number | null;
      startsAt: string;
      assignmentGroupIds: Array<number>;
      tags: Array<{ id: number; name: string; color: string }>;
      shiftEvaluationTags?: Array<{ id: number; name: string; color: string }>;
      shiftRotationGroups: Array<{
        id: number;
        name: string;
        shiftRotation: { id: number; name: string };
      }>;
      shiftPreset: {
        id: number;
        name: string;
        color: string;
        shortName: string | null;
        startsAtTime: string;
        endsAtTime: string;
      } | null;
      locationsPosition: {
        id: number;
        sort: number | null;
        location: { id: number; name: string } | null;
        position: {
          id: number;
          color: string;
          name: string;
          note: string | null;
        } | null;
      };
      requests: Array<{
        id: number;
        acceptedBy: Array<number | null> | null;
        automationCheckedAt: string | null;
        createdAt: string;
        state: GQLReplaceRequestState | null;
        type: GQLRequestType | null;
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          staffNumber: string | null;
          qualificationIds: Array<number> | null;
          pictureData: { pictureSmall: string | null } | null;
        } | null;
      }>;
      shiftBreaks: Array<{
        id: number;
        startsAt: string;
        endsAt: string | null;
      }>;
      staffShifts: Array<{
        id: number;
        shiftAssignmentGroupId: number | null;
        acceptedAt: string | null;
        state: GQLStaffShiftState;
        employment: {
          id: number;
          firstName: string;
          lastName: string;
          isEmployee: boolean | null;
          userId: number;
          deletedAt: string | null;
          staffNumber: string | null;
          locationsPositionIds: Array<number> | null;
          qualificationIds: Array<number> | null;
          exitDate: string | null;
          pictureData: { pictureSmall: string | null } | null;
          employmentWorkHour: {
            hourType: GQLHourType | null;
            isMonthly: boolean | null;
            weeklyMinutes: number | null;
            monthlyMinutes: number | null;
            monMinutes: number | null;
            tueMinutes: number | null;
            wedMinutes: number | null;
            thuMinutes: number | null;
            friMinutes: number | null;
            satMinutes: number | null;
            sunMinutes: number | null;
            isWorkingOnFri: boolean | null;
            isWorkingOnMon: boolean | null;
            isWorkingOnSat: boolean | null;
            isWorkingOnSun: boolean | null;
            isWorkingOnThu: boolean | null;
            isWorkingOnTue: boolean | null;
            isWorkingOnWed: boolean | null;
          } | null;
        } | null;
      }> | null;
      shiftplan: {
        endsAt: string;
        id: number;
        name: string;
        startsAt: string;
        state: GQLShiftplanState;
        locationId: number | null;
        locked: boolean | null;
      };
      shiftQualifications: Array<{
        count: number;
        qualificationId: number;
        qualification: { name: string; id: number };
      }>;
      shiftAssignmentGroups: Array<{
        id: number;
        shiftId: number;
        assignmentGroupId: number;
      }>;
    }>;
  };
};

type GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment = {
  __typename: 'AbsencesCollisionShiftConflict';
  ignorable: boolean;
  absences: Array<{
    id: number;
    startsAt: string;
    endsAt: string;
    isFullDay: boolean;
    absenceReason: { id: number; name: string; hasLocalization: boolean };
  } | null> | null;
};

type GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment = {
  __typename: 'AlreadyAssignedShiftConflict';
  message: string;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_InsideUnavailableTimeShiftConflict_Fragment = {
  __typename: 'InsideUnavailableTimeShiftConflict';
  ignorable: boolean;
};

type GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment =
  {
    __typename: 'LocationsPositionChangedShiftConflict';
    message: string;
    ignorable: boolean;
  };

type GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment =
  {
    __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
    employmentName: string | null;
    maxDays: number;
    ignorable: boolean;
  };

type GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment = {
  __typename: 'MaximumEarningShiftConflict';
  employmentMaxPayment: number;
  totalPayment: number;
  currency: string;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment = {
  __typename: 'MaximumWorkingHoursShiftConflict';
  total: number | null;
  monthlyMinutes: number | null;
  weeklyMinutes: number | null;
  month: number | null;
  week: number | null;
  year: number | null;
  name: string | null;
  difference: number;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment = {
  __typename: 'MinimumBreakShiftConflict';
  minBreakTime: number;
  shiftDuration: number | null;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment = {
  __typename: 'MinimumRestShiftConflict';
  restMinutes: number;
  minimumRestMinutes: number;
  lastShiftEndsAt: string;
  newShiftStartsAt: string;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_OutsideAvailableTimeShiftConflict_Fragment = {
  __typename: 'OutsideAvailableTimeShiftConflict';
  ignorable: boolean;
};

type GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment = {
  __typename: 'OutsideWorkPeriodShiftConflict';
  startsAt: string | null;
  endsAt: string | null;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment = {
  __typename: 'RestrictedShiftPresetsConflict';
  restrictedShiftPresets: Array<string>;
  ignorable: boolean;
};

type GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment = {
  __typename: 'ShiftsCollisionShiftConflict';
  ignorable: boolean;
  shifts: Array<{
    id: number;
    startsAt: string;
    endsAt: string;
    locationsPosition: {
      id: number;
      location: { id: number; name: string } | null;
      position: {
        id: number;
        color: string;
        name: string;
        note: string | null;
      } | null;
    };
    shiftplan: { name: string };
  } | null> | null;
};

type GQLShiftConflictsFragment_UnknownShiftConflict_Fragment = {
  __typename: 'UnknownShiftConflict';
  data: any;
  message: string;
  ignorable: boolean;
};

export type GQLShiftConflictsFragmentFragment =
  | GQLShiftConflictsFragment_AbsencesCollisionShiftConflict_Fragment
  | GQLShiftConflictsFragment_AlreadyAssignedShiftConflict_Fragment
  | GQLShiftConflictsFragment_InsideUnavailableTimeShiftConflict_Fragment
  | GQLShiftConflictsFragment_LocationsPositionChangedShiftConflict_Fragment
  | GQLShiftConflictsFragment_MaximumConsecutiveWorkingDaysShiftConflict_Fragment
  | GQLShiftConflictsFragment_MaximumEarningShiftConflict_Fragment
  | GQLShiftConflictsFragment_MaximumWorkingHoursShiftConflict_Fragment
  | GQLShiftConflictsFragment_MinimumBreakShiftConflict_Fragment
  | GQLShiftConflictsFragment_MinimumRestShiftConflict_Fragment
  | GQLShiftConflictsFragment_OutsideAvailableTimeShiftConflict_Fragment
  | GQLShiftConflictsFragment_OutsideWorkPeriodShiftConflict_Fragment
  | GQLShiftConflictsFragment_RestrictedShiftPresetsConflict_Fragment
  | GQLShiftConflictsFragment_ShiftsCollisionShiftConflict_Fragment
  | GQLShiftConflictsFragment_UnknownShiftConflict_Fragment;

export type GQLShiftFragmentFragment = {
  id: number;
  canEvaluate: boolean;
  canManage: boolean | null;
  endsAt: string;
  breakTime: number;
  untimedBreakTime: number;
  staffShiftsNeedsEvaluationCount: number | null;
  note: string | null;
  connectedGroupId: number | null;
  workers: number;
  shiftRotationGroupIds: Array<number>;
  autoAccept: GQLAutoAcceptRequestSetting;
  untimed: boolean | null;
  managerNote: string | null;
  staffShiftsCount: number | null;
  startsAt: string;
  assignmentGroupIds: Array<number>;
  tags: Array<{ id: number; name: string; color: string }>;
  shiftEvaluationTags?: Array<{ id: number; name: string; color: string }>;
  shiftRotationGroups: Array<{
    id: number;
    name: string;
    shiftRotation: { id: number; name: string };
  }>;
  shiftPreset: {
    id: number;
    name: string;
    color: string;
    shortName: string | null;
    startsAtTime: string;
    endsAtTime: string;
  } | null;
  locationsPosition: {
    id: number;
    sort: number | null;
    location: { id: number; name: string } | null;
    position: {
      id: number;
      color: string;
      name: string;
      note: string | null;
    } | null;
  };
  requests: Array<{
    id: number;
    acceptedBy: Array<number | null> | null;
    automationCheckedAt: string | null;
    createdAt: string;
    state: GQLReplaceRequestState | null;
    type: GQLRequestType | null;
    employment: {
      id: number;
      firstName: string;
      lastName: string;
      staffNumber: string | null;
      qualificationIds: Array<number> | null;
      pictureData: { pictureSmall: string | null } | null;
    } | null;
  }>;
  shiftBreaks: Array<{ id: number; startsAt: string; endsAt: string | null }>;
  staffShifts: Array<{
    id: number;
    shiftAssignmentGroupId: number | null;
    acceptedAt: string | null;
    state: GQLStaffShiftState;
    employment: {
      id: number;
      firstName: string;
      lastName: string;
      isEmployee: boolean | null;
      userId: number;
      deletedAt: string | null;
      staffNumber: string | null;
      locationsPositionIds: Array<number> | null;
      qualificationIds: Array<number> | null;
      exitDate: string | null;
      pictureData: { pictureSmall: string | null } | null;
      employmentWorkHour: {
        hourType: GQLHourType | null;
        isMonthly: boolean | null;
        weeklyMinutes: number | null;
        monthlyMinutes: number | null;
        monMinutes: number | null;
        tueMinutes: number | null;
        wedMinutes: number | null;
        thuMinutes: number | null;
        friMinutes: number | null;
        satMinutes: number | null;
        sunMinutes: number | null;
        isWorkingOnFri: boolean | null;
        isWorkingOnMon: boolean | null;
        isWorkingOnSat: boolean | null;
        isWorkingOnSun: boolean | null;
        isWorkingOnThu: boolean | null;
        isWorkingOnTue: boolean | null;
        isWorkingOnWed: boolean | null;
      } | null;
    } | null;
  }> | null;
  shiftplan: {
    endsAt: string;
    id: number;
    name: string;
    startsAt: string;
    state: GQLShiftplanState;
    locationId: number | null;
    locked: boolean | null;
  };
  shiftQualifications: Array<{
    count: number;
    qualificationId: number;
    qualification: { name: string; id: number };
  }>;
  shiftAssignmentGroups: Array<{
    id: number;
    shiftId: number;
    assignmentGroupId: number;
  }>;
};

export type GQLUnassignEmploymentFromShiftRotationGroupMutationVariables =
  Exact<{
    companyId: Scalars['Int'];
    shiftRotationGroupUnassignInput: GQLShiftRotationGroupUnassignInput;
  }>;

export type GQLUnassignEmploymentFromShiftRotationGroupMutation = {
  unassignEmploymentFromShiftRotationGroup: { jobId: string };
};

export type GQLUpdateShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  shift: GQLShiftUpdateInput;
  isShiftEvaluationTagsIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type GQLUpdateShiftMutation = {
  updateShift:
    | {
        id: number;
        canEvaluate: boolean;
        canManage: boolean | null;
        endsAt: string;
        breakTime: number;
        untimedBreakTime: number;
        staffShiftsNeedsEvaluationCount: number | null;
        note: string | null;
        connectedGroupId: number | null;
        workers: number;
        shiftRotationGroupIds: Array<number>;
        autoAccept: GQLAutoAcceptRequestSetting;
        untimed: boolean | null;
        managerNote: string | null;
        staffShiftsCount: number | null;
        startsAt: string;
        assignmentGroupIds: Array<number>;
        tags: Array<{ id: number; name: string; color: string }>;
        shiftEvaluationTags?: Array<{
          id: number;
          name: string;
          color: string;
        }>;
        shiftRotationGroups: Array<{
          id: number;
          name: string;
          shiftRotation: { id: number; name: string };
        }>;
        shiftPreset: {
          id: number;
          name: string;
          color: string;
          shortName: string | null;
          startsAtTime: string;
          endsAtTime: string;
        } | null;
        locationsPosition: {
          id: number;
          sort: number | null;
          location: { id: number; name: string } | null;
          position: {
            id: number;
            color: string;
            name: string;
            note: string | null;
          } | null;
        };
        requests: Array<{
          id: number;
          acceptedBy: Array<number | null> | null;
          automationCheckedAt: string | null;
          createdAt: string;
          state: GQLReplaceRequestState | null;
          type: GQLRequestType | null;
          employment: {
            id: number;
            firstName: string;
            lastName: string;
            staffNumber: string | null;
            qualificationIds: Array<number> | null;
            pictureData: { pictureSmall: string | null } | null;
          } | null;
        }>;
        shiftBreaks: Array<{
          id: number;
          startsAt: string;
          endsAt: string | null;
        }>;
        staffShifts: Array<{
          id: number;
          shiftAssignmentGroupId: number | null;
          acceptedAt: string | null;
          state: GQLStaffShiftState;
          employment: {
            id: number;
            firstName: string;
            lastName: string;
            isEmployee: boolean | null;
            userId: number;
            deletedAt: string | null;
            staffNumber: string | null;
            locationsPositionIds: Array<number> | null;
            qualificationIds: Array<number> | null;
            exitDate: string | null;
            pictureData: { pictureSmall: string | null } | null;
            employmentWorkHour: {
              hourType: GQLHourType | null;
              isMonthly: boolean | null;
              weeklyMinutes: number | null;
              monthlyMinutes: number | null;
              monMinutes: number | null;
              tueMinutes: number | null;
              wedMinutes: number | null;
              thuMinutes: number | null;
              friMinutes: number | null;
              satMinutes: number | null;
              sunMinutes: number | null;
              isWorkingOnFri: boolean | null;
              isWorkingOnMon: boolean | null;
              isWorkingOnSat: boolean | null;
              isWorkingOnSun: boolean | null;
              isWorkingOnThu: boolean | null;
              isWorkingOnTue: boolean | null;
              isWorkingOnWed: boolean | null;
            } | null;
          } | null;
        }> | null;
        shiftplan: {
          endsAt: string;
          id: number;
          name: string;
          startsAt: string;
          state: GQLShiftplanState;
          locationId: number | null;
          locked: boolean | null;
        };
        shiftQualifications: Array<{
          count: number;
          qualificationId: number;
          qualification: { name: string; id: number };
        }>;
        shiftAssignmentGroups: Array<{
          id: number;
          shiftId: number;
          assignmentGroupId: number;
        }>;
      }
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      };
};

export type GQLWithdrawAcceptedReplaceRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  acceptedBy: InputMaybe<Scalars['Int']>;
}>;

export type GQLWithdrawAcceptedReplaceRequestMutation = {
  withdrawAcceptedReplaceRequest: {
    id: number | null;
    state: GQLReplaceRequestState | null;
  } | null;
};

export type GQLSpecialDatesQueryVariables = Exact<{
  companyId: Scalars['Int'];
  locationId: Scalars['Int'];
}>;

export type GQLSpecialDatesQuery = {
  specialDates: {
    items: Array<{
      id: number;
      name: string;
      specialDate: string;
      fullDay: boolean;
      allLocations: boolean;
      startTime: string | null;
      endTime: string | null;
      isIncludedInHourAccount: boolean;
    }>;
  };
};

export type GQLCreateStaffShiftsTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLCreateStaffShiftsTagMutation = {
  createStaffShiftsTag: { id: number; staffShiftId: number; tagId: number };
};

export type GQLDeleteStaffShiftsTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShiftId: Scalars['Int'];
  tagId: Scalars['Int'];
}>;

export type GQLDeleteStaffShiftsTagMutation = {
  deleteStaffShiftsTag: { success: boolean; error: string | null };
};

export type GQLStaffShiftsTagFragmentFragment = {
  id: number;
  staffShiftId: number;
  tagId: number;
};

export type GQLCreateStaffShiftMutationVariables = Exact<{
  companyId: Scalars['Int'];
  staffShift: GQLStaffShiftCreateInput;
}>;

export type GQLCreateStaffShiftMutation = {
  createStaffShift:
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      }
    | { id: number };
};

export type GQLDeleteStaffShiftMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  unassignFromConnected: Scalars['Boolean'];
}>;

export type GQLDeleteStaffShiftMutation = {
  deleteStaffShift: { success: boolean; error: string | null };
};

export type GQLMoveStaffShiftMutationVariables = Exact<{
  staffShift: GQLStaffShiftMoveInput;
}>;

export type GQLMoveStaffShiftMutation = {
  moveStaffShift:
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      }
    | { id: number };
};

export type GQLReplaceStaffShiftMutationVariables = Exact<{
  staffShift: GQLStaffShiftReplaceInput;
}>;

export type GQLReplaceStaffShiftMutation = {
  replaceStaffShift:
    | {
        conflicts: Array<
          | {
              __typename: 'AbsencesCollisionShiftConflict';
              ignorable: boolean;
              absences: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                isFullDay: boolean;
                absenceReason: {
                  id: number;
                  name: string;
                  hasLocalization: boolean;
                };
              } | null> | null;
            }
          | {
              __typename: 'AlreadyAssignedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'InsideUnavailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'LocationsPositionChangedShiftConflict';
              message: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumConsecutiveWorkingDaysShiftConflict';
              employmentName: string | null;
              maxDays: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumEarningShiftConflict';
              employmentMaxPayment: number;
              totalPayment: number;
              currency: string;
              ignorable: boolean;
            }
          | {
              __typename: 'MaximumWorkingHoursShiftConflict';
              total: number | null;
              monthlyMinutes: number | null;
              weeklyMinutes: number | null;
              month: number | null;
              week: number | null;
              year: number | null;
              name: string | null;
              difference: number;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumBreakShiftConflict';
              minBreakTime: number;
              shiftDuration: number | null;
              ignorable: boolean;
            }
          | {
              __typename: 'MinimumRestShiftConflict';
              restMinutes: number;
              minimumRestMinutes: number;
              lastShiftEndsAt: string;
              newShiftStartsAt: string;
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideAvailableTimeShiftConflict';
              ignorable: boolean;
            }
          | {
              __typename: 'OutsideWorkPeriodShiftConflict';
              startsAt: string | null;
              endsAt: string | null;
              ignorable: boolean;
            }
          | {
              __typename: 'RestrictedShiftPresetsConflict';
              restrictedShiftPresets: Array<string>;
              ignorable: boolean;
            }
          | {
              __typename: 'ShiftsCollisionShiftConflict';
              ignorable: boolean;
              shifts: Array<{
                id: number;
                startsAt: string;
                endsAt: string;
                locationsPosition: {
                  id: number;
                  location: { id: number; name: string } | null;
                  position: {
                    id: number;
                    color: string;
                    name: string;
                    note: string | null;
                  } | null;
                };
                shiftplan: { name: string };
              } | null> | null;
            }
          | {
              __typename: 'UnknownShiftConflict';
              data: any;
              message: string;
              ignorable: boolean;
            }
        >;
      }
    | { id: number };
};

export type GQLStaffShiftFragmentFragment = { id: number };

export type GQLCreateTagMutationVariables = Exact<{
  tag: GQLTagCreateInput;
}>;

export type GQLCreateTagMutation = {
  createTag: {
    color: string;
    id: number;
    name: string;
    context: GQLTagContext;
    textColor: string | null;
    location: { id: number } | null;
  };
};

export type GQLDeleteTagMutationVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
}>;

export type GQLDeleteTagMutation = {
  deleteTag: { success: boolean; error: string | null };
};

export type GQLFetchTagQueryVariables = Exact<{
  companyId: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type GQLFetchTagQuery = {
  tags: {
    items: Array<{
      color: string;
      id: number;
      name: string;
      context: GQLTagContext;
      textColor: string | null;
      location: { id: number } | null;
    }>;
  };
};

export type GQLTagFragmentFragment = {
  color: string;
  id: number;
  name: string;
  context: GQLTagContext;
  textColor: string | null;
  location: { id: number } | null;
};

export type GQLTagsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  context: InputMaybe<Array<GQLTagContext> | GQLTagContext>;
  locationIds: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GQLTagsQuery = {
  tags: {
    items: Array<{
      color: string;
      id: number;
      name: string;
      context: GQLTagContext;
      textColor: string | null;
      location: { id: number } | null;
    }>;
  };
};

export type GQLUpdateTagMutationVariables = Exact<{
  id: Scalars['Int'];
  tag: GQLTagUpdateInput;
}>;

export type GQLUpdateTagMutation = {
  updateTag: {
    color: string;
    id: number;
    name: string;
    context: GQLTagContext;
    textColor: string | null;
    location: { id: number } | null;
  };
};

export type GQLGetUiSettingQueryVariables = Exact<{
  employmentId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GQLGetUiSettingQuery = {
  employmentsUiSetting: {
    id: number;
    createdAt: string;
    updatedAt: string | null;
    employmentId: number;
    settings: any | null;
    version: string | null;
  };
};

export type GQLUpdateEmploymentsUiSettingMutationVariables = Exact<{
  employmentId: Scalars['Int'];
  companyId: Scalars['Int'];
  version: Scalars['String'];
  settings: Scalars['String'];
}>;

export type GQLUpdateEmploymentsUiSettingMutation = {
  updateEmploymentsUiSetting: {
    id: number;
    createdAt: string;
    updatedAt: string | null;
    employmentId: number;
    settings: any | null;
    version: string | null;
  };
};
