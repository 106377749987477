import { toDate } from 'date-fns-tz';
import Shift from '../shifts/Shift';
import Employment from './Employment';

/* eslint-disable import/prefer-default-export */
export const locationPositionFilter =
  (locationsPositionIds: number[]) => (employment: Employment) =>
    locationsPositionIds.length === 0 ||
    employment.presentLocationsPositionIds.some((locationsPositionId) =>
      locationsPositionIds.includes(locationsPositionId),
    ) ||
    employment.activeLocationsPositionIds.some((locationsPositionId) =>
      locationsPositionIds.includes(locationsPositionId),
    );

export const myShiftsFilter =
  (isFilterActive: boolean, currentEmploymentId: number) =>
  (employment: Employment) =>
    isFilterActive ? employment.id === currentEmploymentId : true;

export const shiftRotationGroupFilter =
  (
    shiftRotationGroupIds: number[] | null,
    isShiftRotationsEnabled: boolean,
    shifts: Shift[],
    includeEmployeesWithRotationGroupShifts = false,
  ) =>
  (employment: Employment) => {
    if (shiftRotationGroupIds === null || !isShiftRotationsEnabled) {
      return true;
    }

    const isEmployeeAssignedToRotationGroup = () =>
      employment.shiftRotationGroupIds.some((id) =>
        shiftRotationGroupIds?.includes(id),
      );

    const hasEmployeeShiftsInRotationGroup = () =>
      shifts.some((shift: Shift) => {
        const isShiftOfCurrentEmployment = () =>
          shift.employmentIds.includes(employment.id);

        const isShiftInShiftRotationGroup = () =>
          shift.shiftRotationGroupIds.some((id) =>
            shiftRotationGroupIds?.includes(id),
          );

        return isShiftOfCurrentEmployment() && isShiftInShiftRotationGroup();
      });

    return (
      isEmployeeAssignedToRotationGroup() ||
      (includeEmployeesWithRotationGroupShifts &&
        hasEmployeeShiftsInRotationGroup())
    );
  };

// Checks for suitable shifts for unassigned employees. No shifts overlapping check needed
export const hasSuitableShift = (
  shiftsForTheCurrentDay: Shift[],
  isOverassignmentAllowed: boolean,
  employment: Employment,
  leftTimeInMinutes: number | null,
) => {
  return shiftsForTheCurrentDay.some((shift) => {
    if (
      (shift.isFull && !isOverassignmentAllowed) ||
      shift.employmentIds.includes(employment.id)
    ) {
      return false;
    }
    if (
      employment.activeLocationsPositionIds.includes(
        shift.position.locationsPositionId,
      )
    ) {
      if (
        !leftTimeInMinutes ||
        leftTimeInMinutes >= shift.getDurationMinutes()
      ) {
        return true;
      }
    }

    return false;
  });
};

export const isOutsideEmploymentPeriod = (
  dateKey: string,
  employment: Employment,
  timeZone: string,
) => {
  if (!employment.exitDate) {
    return false;
  }
  const exitDate = toDate(employment.exitDate, {
    timeZone,
  });

  const dateKeyDate = toDate(dateKey, {
    timeZone,
  });

  return dateKeyDate >= exitDate;
};
