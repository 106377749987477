import type { GQLEmploymentsQuery } from 'codegen/gql-types';
import moment, { Moment } from 'moment';
import { getPatternBasedOnRotationGroup } from 'src/utils/rotation-group';
import DateItem, { DateKey } from '../common/DateItem';

export interface PictureData {
  pictureSmall: string | null;
}

type EmploymentQueryItem = GQLEmploymentsQuery['employments']['items'][0];

export type EmploymentConstructorProps = EmploymentQueryItem & {
  presentLocationsPositionIds?: number[];
};

class Employment {
  public firstName: string;

  public lastName: string;

  public id: number;

  public userId: number;

  public pictureData: PictureData;

  public deletedAt: Moment | null;

  public hourEnabled: boolean | null;

  public employmentWorkHour: NonNullable<
    EmploymentQueryItem['employmentWorkHour']
  > | null;

  /**
   * LocationsPosition ids that are present in current view
   * some of these might be previous locations of employment
   * and should be used only for display purposes
   *
   * @type {number[]}
   * @memberof Employment
   */
  public presentLocationsPositionIds: number[];

  /**
   * ShiftRotationGroup ids that that the employment is assigned to
   * @type {number[]}
   * @memberof Employment
   */
  public shiftRotationGroupIds: number[];

  /**
   * LocationsPosition ids that employee has currently access too
   *
   * @type {number[]}
   * @memberof Employment
   */
  public activeLocationsPositionIds: number[];

  public name: string;

  public image: string | null;

  /**
   * ShiftRotationGroup ids that that the employment is assigned to
   * @type {number[]}
   * @memberof Employment
   */
  public employmentsShiftRotationGroups: NonNullable<
    EmploymentQueryItem['employmentsShiftRotationGroups']
  >;

  public exitDate: string | null;

  public constructor({
    firstName,
    lastName,
    deletedAt,
    userId,
    id,
    pictureData,
    presentLocationsPositionIds = [],
    locationsPositionIds,
    employmentsShiftRotationGroups = [],
    employmentWorkHour = null,
    exitDate = null,
  }: EmploymentConstructorProps) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.deletedAt = deletedAt !== null ? moment(deletedAt) : null;
    this.id = id;
    this.userId = userId;
    this.pictureData = pictureData || { pictureSmall: null };
    this.presentLocationsPositionIds = presentLocationsPositionIds;
    this.activeLocationsPositionIds = locationsPositionIds || [];
    this.image = this.pictureData.pictureSmall;
    this.name = `${firstName} ${lastName}`;
    this.employmentsShiftRotationGroups = employmentsShiftRotationGroups;
    this.userId = userId;
    this.exitDate = exitDate;

    this.shiftRotationGroupIds = employmentsShiftRotationGroups.map(
      (it) => it.shiftRotationGroup.id,
    );
    this.image = this.pictureData.pictureSmall;
    this.name = `${firstName} ${lastName}`;
    this.employmentWorkHour = employmentWorkHour;
  }

  public isDeleted() {
    return this.deletedAt !== null;
  }

  public getShiftPresetIdsForTimeframe(
    dateItems: DateItem[],
  ): Record<DateKey, number | null> {
    const rotationGroupData = this.employmentsShiftRotationGroups.map(
      (rotation) => {
        return {
          rotationGroupId: rotation.id,
          shiftPresetIds: rotation.shiftRotationGroup.shiftPresetIds,
          anchorDate: rotation.shiftRotationGroup.shiftRotation.anchorDate,
          rotationInterval:
            rotation.shiftRotationGroup.shiftRotation.rotationInterval,
          endsAt: rotation.endsAt,
          startsAt: rotation.startsAt,
        };
      },
    );

    return getPatternBasedOnRotationGroup(dateItems, rotationGroupData);
  }
}

export default Employment;
