import { isBreakInsideShiftTimeFrame } from 'components/dialog-shift/details/utils';
import { getOverlappingTimeFrames } from 'src/utils/intervals';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import InputText from 'components/form/input-text/InputText';
import { isStartBeforeEnd } from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { differenceInMinutes } from 'date-fns';
import AdditionalBreak from './additional-break/AdditionalBreak';
import styles from './breaks.css';

export interface Props {
  context?: string;
  isDisabled?: boolean;
  isMultiDay: boolean;
  isUnpaidBreakDisabled?: boolean;
  shiftEndsAt: Date;
  shiftStartsAt: Date;
  unpaidBreak: number;
  breaks: {
    startsAt: Date;
    endsAt: Date;
    id: number;
  }[];
}

interface Events {
  onAddBreakClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onBreakChange: (payload: OnBreakChangePayload) => void;
  onDeleteBreakClick: (payload: OnDeleteBreakClickPayload) => void;
  onUnpaidBreakChange: (payload: OnUnpaidBreakChangePayload) => void;
  onValidityChange: (payload: OnValidityChangePayload) => void;
}

export type OnDeleteBreakClickPayload = EventPayload<
  { id: number },
  HTMLElement,
  UIEvent
>;
export type OnBreakChangePayload = EventPayload<
  {
    id: number;
    value: Date;
    field: string;
  },
  HTMLElement,
  UIEvent
>;
export type OnValidityChangePayload = EventPayload<
  { isValid: boolean },
  HTMLElement,
  UIEvent
>;
export type OnUnpaidBreakChangePayload = EventPayload<
  { value: number },
  HTMLElement,
  UIEvent
>;

@Component
class Breaks extends TSXComponent<Props, Events> {
  public $refs: {
    unpaidBreakRef: Vue;
  };

  @Prop()
  public context: Props['context'];

  @Prop()
  public unpaidBreak: Props['unpaidBreak'];

  @Prop()
  public isMultiDay: Props['isMultiDay'];

  @Prop()
  public breaks: Props['breaks'];

  @Prop()
  public shiftStartsAt: Props['shiftStartsAt'];

  @Prop()
  public shiftEndsAt: Props['shiftEndsAt'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isUnpaidBreakDisabled: Props['isUnpaidBreakDisabled'];

  protected get maxMinutes() {
    return Math.abs(differenceInMinutes(this.shiftStartsAt, this.shiftEndsAt));
  }

  private getErrorMessageForBreak(breakToValidate: {
    startsAt: Date;
    endsAt: Date;
    id: number;
  }): string | undefined {
    if (!isStartBeforeEnd(breakToValidate.startsAt, breakToValidate.endsAt)) {
      return this.$t('shifts.breaks.error.startBeforeEnd');
    }

    if (
      !isBreakInsideShiftTimeFrame({
        breakToValidate,
        shiftStartsAt: this.shiftStartsAt,
        shiftEndsAt: this.shiftEndsAt,
      })
    ) {
      return this.$t('shifts.breaks.error.outsideOfShiftTimeframe');
    }

    const isBreakOverlappingWithOther =
      getOverlappingTimeFrames({
        intervalToValidate: breakToValidate,
        intervals: this.breaks,
        timeZone: this.$timeZone.value,
      }).length > 0;

    if (isBreakOverlappingWithOther) {
      return this.$t('shifts.breaks.error.overlappingBreaks');
    }

    return undefined;
  }

  protected onUnpaidBreakInput(e: SyntheticEvent<HTMLInputElement>) {
    const value = Number.parseInt(e.target.value, 10);

    // limit value to allowed maximum
    const updatedValue = Math.min(value, this.maxMinutes);

    this.$emit(
      'unpaidBreakChange',
      createEventPayload(e, { value: updatedValue }),
    );

    // reload value to reset input
    this.$refs.unpaidBreakRef.$forceUpdate();
  }

  public render() {
    return (
      <div class={styles.breaks}>
        <InputText
          ref="unpaidBreakRef"
          class={styles.breaksUnpaidBreakInput}
          disabled={this.isUnpaidBreakDisabled || this.isDisabled}
          label={this.$t('shifts.breaks.labelUnpaidBreak')}
          min="0"
          max={this.maxMinutes.toString()}
          name="unpaidBreak"
          onChange={this.onUnpaidBreakInput}
          required={true}
          type="number"
          value={this.unpaidBreak.toString()}
          error={
            this.unpaidBreak > this.maxMinutes
              ? this.$t('general.error.invalidValue')
              : null
          }
        />

        {this.breaks?.map((item) => {
          const errorMessage = this.getErrorMessageForBreak(item);

          return (
            <AdditionalBreak
              additionalBreak={item}
              isDisabled={this.isDisabled}
              isMultiDay={this.isMultiDay}
              shiftEndsAt={this.shiftEndsAt}
              shiftStartsAt={this.shiftStartsAt}
              onChange={(e) => this.$emit('breakChange', e)}
              onDeleteBreakClick={(e) => this.$emit('deleteBreakClick', e)}
              isValid={!errorMessage}
              errorMessage={errorMessage}
            />
          );
        })}

        {!this.isDisabled && (
          <Button
            icon={IconName.PLUS}
            class={styles.breaksAddButton}
            color={ButtonColor.PRIMARY}
            onClick={(e) =>
              this.$emit('addBreakClick', createEventPayload(e, undefined))
            }
            size={Size.SMALL}
            kind={ButtonKind.FILL}
            type="button"
          >
            {this.$t('shifts.breaks.buttonAdd')}
          </Button>
        )}
      </div>
    );
  }
}

export default Breaks;
