import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  tooltipNS,
  StoreState,
  TooltipType,
} from 'components/calendar-common/tooltip/Store';
import { TOOLTIP_PORTAL_NAME } from 'layouts/TooltipPortalTarget';
import styles from './calendar-tooltip.css';
import TooltipEmploymentContent from './tooltip-employment-content/TooltipEmploymentContent';
import TooltipShiftContent from './tooltip-shift-content/TooltipShiftContent';

@Component
export class CalendarTooltip extends TsxComponent<{}> {
  public $refs: {
    tooltipped: HTMLDivElement;
  };

  protected isPortalDisabled = true;

  protected isShown = false;

  protected isBeyondViewportTop = false;

  protected isBeyondViewportLeft = false;

  protected isBeyondViewportRight = false;

  protected style: Partial<CSSStyleDeclaration> = {};

  protected textAlignment = 'left';

  protected timeoutId = Number.NaN;

  @tooltipNS.State('payload')
  private tooltipPayload: StoreState['payload'];

  private onAfterLeave() {
    this.style = {};
  }

  private onEnter() {
    const tooltipTargetEl = this.tooltipPayload?.element;

    if (tooltipTargetEl) {
      // rect of the tooltip element
      const rectTooltip = tooltipTargetEl.getBoundingClientRect();
      // rect of element tooltip belongs to
      const rectTooltipped = this.$refs.tooltipped.getBoundingClientRect();

      /* left is center of tooltipped element(transform is applied in css) */
      const left = rectTooltip.left + rectTooltipped.width / 2;

      this.isBeyondViewportTop = rectTooltip.top - rectTooltipped.height < 0;

      this.isBeyondViewportLeft =
        rectTooltip.left - rectTooltipped.width / 2 < 0;

      this.isBeyondViewportRight =
        left + rectTooltip.width / 2 > window.innerWidth;

      const top = this.isBeyondViewportTop
        ? rectTooltip.top + rectTooltipped.height + rectTooltip.height
        : rectTooltip.top;

      /*
    if tooltip goes out of bounds of the viewport - we put it not in the center
    but on one of the sides
    */
      const style = {
        left: `${
          rectTooltip.left -
          rectTooltipped.width * 0.5 +
          rectTooltip.width * 0.5
        }px`,
        top: `${top}px`,
        textAlign: this.textAlignment,
      };

      if (this.isBeyondViewportLeft) {
        style.left = `${rectTooltip.left + rectTooltipped.width * 0.1}px`;
      }

      if (this.isBeyondViewportRight) {
        style.left = `${rectTooltip.right - rectTooltipped.width}px`;
      }
      this.style = style;
    }
  }

  public render() {
    return (
      <portal to={TOOLTIP_PORTAL_NAME}>
        <transition
          appear={true}
          enter-class={styles.transitionSlideEnter}
          enter-to-class={styles.transitionSlideEnterTo}
          leave-class={styles.transitionSlideLeave}
          leave-to-class={styles.transitionSlideLeaveTo}
          onEnter={this.onEnter}
          onAfterLeave={this.onAfterLeave}
        >
          <div
            ref="tooltipped"
            class={{
              [styles.calendarTooltipText]: true,
              [styles.calendarTooltipTextPopover]:
                this.tooltipPayload?.type === TooltipType.EMPLOYMENT,
              [styles.calendarTooltipTextBottom]: this.isBeyondViewportTop,
              [styles.calendarTooltipTextRight]: this.isBeyondViewportLeft,
              [styles.calendarTooltipTextLeft]: this.isBeyondViewportRight,
            }}
            style={this.style}
          >
            {this.tooltipPayload?.type === TooltipType.SHIFT && (
              <TooltipShiftContent tooltipPayload={this.tooltipPayload} />
            )}

            {this.tooltipPayload?.type === TooltipType.EMPLOYMENT && (
              <TooltipEmploymentContent tooltipPayload={this.tooltipPayload} />
            )}
          </div>
        </transition>
      </portal>
    );
  }
}

export default CalendarTooltip;
