import InputDateTime, {
  Kind,
} from 'components/form/input-date-time/InputDateTime';
import InputText from 'components/form/input-text/InputText';
import { differenceInMinutes } from 'date-fns';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import { IconPosition } from 'components/form/button/types';
import styles from './additional-break.css';

export interface AdditionalBreakItem {
  startsAt: Date;
  endsAt: Date;
  id: number;
}

export interface Props {
  additionalBreak: AdditionalBreakItem;
  shiftStartsAt: Date;
  shiftEndsAt: Date;
  isDisabled?: boolean;
  isMultiDay: boolean;
  isValid?: boolean;
  errorMessage?: string;
}

interface Events {
  onChange: (
    payload: EventPayload<
      { field: 'startsAt' | 'endsAt'; value: Date; id: number },
      Element
    >,
  ) => void;
  onDeleteBreakClick: (payload: EventPayload<{ id: number }, Element>) => void;
}

@Component
class AdditionalBreak extends TSXComponent<Props, Events> {
  private isDirty = false;

  @Prop()
  public additionalBreak: Props['additionalBreak'];

  @Prop()
  public shiftStartsAt: Props['shiftStartsAt'];

  @Prop()
  public shiftEndsAt: Props['shiftEndsAt'];

  @Prop()
  public isDisabled: Props['isDisabled'];

  @Prop()
  public isMultiDay: Props['isMultiDay'];

  @Prop()
  public isValid: Props['isValid'];

  @Prop()
  public errorMessage: Props['errorMessage'];

  protected get dateInputKind() {
    return this.isMultiDay ? Kind.DATETIME : Kind.TIME;
  }

  protected onInput(
    e: SyntheticEvent<Element>,
    breakId: number,
    field: 'startsAt' | 'endsAt',
    value: Date,
  ) {
    this.$emit(
      'change',
      createEventPayload(e, {
        id: breakId,
        value,
        field,
      }),
    );

    this.isDirty = true;
  }

  public render() {
    return (
      <div class={styles.additionalBreak}>
        <InputDateTime
          class={styles.additionalBreakInput}
          datepickerLabel={this.$t('general.form.labelStartsAtDate')}
          error={this.isDirty ? this.errorMessage : undefined}
          isDisabled={this.isDisabled}
          isValid={this.isValid}
          kind={this.dateInputKind}
          max={this.shiftEndsAt}
          min={this.shiftStartsAt}
          timepickerLabel={this.$t('general.form.labelStartsAtTime')}
          timeZone={this.$timeZone.value}
          value={this.additionalBreak.startsAt}
          onInput={({ event, payload }) => {
            this.onInput(
              event,
              this.additionalBreak.id,
              'startsAt',
              payload.value,
            );
          }}
        />

        <InputDateTime
          class={styles.additionalBreakInput}
          datepickerLabel={this.$t('general.form.labelEndsAtDate')}
          isDisabled={this.isDisabled}
          isValid={this.isValid}
          kind={this.dateInputKind}
          max={this.shiftEndsAt}
          min={this.shiftStartsAt}
          onInput={({ event, payload }) =>
            this.onInput(
              event,
              this.additionalBreak.id,
              'endsAt',
              payload.value,
            )
          }
          timepickerLabel={this.$t('general.form.labelEndsAtTime')}
          value={this.additionalBreak.endsAt}
          timeZone={this.$timeZone.value}
        />

        <InputText
          class={styles.additionalBreakInput}
          disabled={true}
          label={this.$t('shifts.breaks.labelBreakDuration')}
          type="number"
          value={
            this.additionalBreak.startsAt && this.additionalBreak.endsAt
              ? `${Math.max(
                  differenceInMinutes(
                    this.additionalBreak.endsAt,
                    this.additionalBreak.startsAt,
                  ),
                  0,
                )}`
              : '0'
          }
        />

        {!this.isDisabled && (
          <Button
            class={styles.additionalBreakButtonDelete}
            color={ButtonColor.ERROR}
            icon={IconName.CLEAR}
            size={Size.MEDIUM}
            title={this.$t('shifts.breaks.buttonDelete')}
            kind={ButtonKind.GHOST}
            iconPosition={IconPosition.ALONE}
            type="button"
            onClick={(e) =>
              this.$emit(
                'deleteBreakClick',
                createEventPayload(e, { id: this.additionalBreak.id }),
              )
            }
          />
        )}
      </div>
    );
  }
}

export default AdditionalBreak;
