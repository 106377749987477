import { areIntervalsOverlapping } from 'date-fns';
import {
  getDateInTimeZone,
  isStartBeforeEnd,
  MAX_DATE,
  MIN_DATE,
} from './date-related';

export interface Intervaled {
  startsAt: Date | string | null | undefined;
  endsAt: Date | string | null | undefined;
}

export function getItemsOverlappingInterval<T extends Intervaled>(
  items: T[],
  startsAt: Intervaled['startsAt'],
  endsAt: Intervaled['endsAt'],
  timeZone: string,
): T[] {
  if (!startsAt && !endsAt) {
    return [];
  }
  const startsAtDate = startsAt
    ? getDateInTimeZone(new Date(startsAt), timeZone)
    : MIN_DATE;
  const endsAtDate = endsAt
    ? getDateInTimeZone(new Date(endsAt), timeZone)
    : MAX_DATE;

  return items.filter((item) => {
    if (!item.startsAt && !item.endsAt) {
      return true;
    }

    const itemStartsAt = item.startsAt
      ? getDateInTimeZone(new Date(item.startsAt), timeZone)
      : MIN_DATE;
    const itemEndsAt = item.endsAt
      ? getDateInTimeZone(new Date(item.endsAt), timeZone)
      : MAX_DATE;

    return areIntervalsOverlapping(
      { start: startsAtDate, end: endsAtDate },
      { start: itemStartsAt, end: itemEndsAt },
    );
  });
}

export const getOverlappingTimeFrames = ({
  intervals,
  intervalToValidate: { startsAt, endsAt, id },
  timeZone,
}: {
  intervals: {
    startsAt: Date | undefined;
    endsAt: Date | undefined;
    id: number;
  }[];
  intervalToValidate: {
    startsAt: Date | undefined;
    endsAt: Date | undefined;
    id: number;
  };
  timeZone: string;
}) => {
  // grab only valid intervals with different id from currently validated interval
  const otherIntervals = intervals.filter((item) => {
    const startsAtDate = item.startsAt;
    const endsAtDate = item.endsAt;

    return item.id !== id && isStartBeforeEnd(startsAtDate, endsAtDate);
  });

  return getItemsOverlappingInterval(
    otherIntervals,
    startsAt,
    endsAt,
    timeZone,
  );
};
