export interface RestResponse<T> {
  body: T;
  isSuccessful: true;
  status: number;
}

export interface RestError {
  body: any;
  isSuccessful: false;
  status: number;
}

class RestClient {
  private token?: string;

  private email?: string;

  private endpoint: string;

  public constructor(endpoint: string, token?: string, email?: string) {
    this.token = token;
    this.email = email;
    this.endpoint = endpoint;
  }

  public setToken(token?: string) {
    this.token = token;
  }

  public setEmail(email?: string) {
    this.email = email;
  }

  public async postUrlEncoded<T extends Record<string, any>>(
    url,
    params: Record<string, string>,
  ): Promise<RestResponse<T> | RestError> {
    const paramsWithAuth = { ...params };

    if (this.email && this.token) {
      /* eslint-disable camelcase */
      paramsWithAuth.user_email = this.email;
      paramsWithAuth.authentication_token = this.token;
      /* eslint-enable camelcase */
    }

    const urlEncodedParams = new URLSearchParams(paramsWithAuth);

    return this.post(url, urlEncodedParams);
  }

  public async postFormData<T extends Record<string, any>>(
    url: string,
    params: Record<string, string | Blob>,
  ): Promise<RestResponse<T> | RestError> {
    const paramsWithAuth = {
      ...params,
      /* eslint-disable camelcase */
      user_email: this.email,
      authentication_token: this.token,
      /* eslint-enable camelcase */
    };
    const formData = Object.entries(paramsWithAuth).reduce(
      (prev, [name, val]) => {
        if (val !== undefined) {
          prev.set(name, val);
        }
        return prev;
      },
      new FormData(),
    );

    return this.post(url, formData);
  }

  public async post<T extends Record<string, any>>(
    url: string,
    payload: any,
    headers?: any,
  ): Promise<RestResponse<T> | RestError> {
    try {
      const response = await fetch(`${this.endpoint}${url}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        ...(payload && { body: payload }),
        headers,
      });

      const body = await response.json();

      return {
        isSuccessful: response.ok,
        status: response.status,
        body,
      };
    } catch {
      return {
        isSuccessful: false,
        status: -1,
        body: null,
      };
    }
  }

  public async get<T extends Record<string, any>>(
    url,
    params: Record<string, string>,
  ): Promise<RestResponse<T> | RestError> {
    try {
      const fetchUrl = new URL(`${this.endpoint}${url}`);
      if (this.email && this.token) {
        fetchUrl.searchParams.append('user_email', this.email);
        fetchUrl.searchParams.append('authentication_token', this.token);
      }
      Object.entries(params).forEach(([key, value]) => {
        fetchUrl.searchParams.append(key, value);
      });

      const response = await fetch(fetchUrl.toString(), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
      });

      const body = await response.json();

      return {
        isSuccessful: response.ok,
        status: response.status,
        body,
      };
    } catch {
      return {
        isSuccessful: false,
        status: -1,
        body: null,
      };
    }
  }
}

export default RestClient;
