import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEmploymentEvaluationMutation,
  GQLCreateEmploymentEvaluationMutationVariables,
  GQLDeleteEmploymentEvaluationMutation,
  GQLDeleteEmploymentEvaluationMutationVariables,
  GQLEmploymentEvaluationFragmentFragment,
  GQLEmploymentEvaluationQuery,
  GQLEmploymentEvaluationQueryVariables,
  GQLAcceptEmploymentEvaluationMutation,
  GQLAcceptEmploymentEvaluationMutationVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action as NormalizedStoreAction,
  Mutation as NormalizedStoreMutation,
  ActionProvider,
  ById,
  createNormalizedStore,
  handleUnexpectedResult,
} from 'store/normalized-store';
import {
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import EmploymentEvaluationGql from './queries/EmploymentEvaluation.gql';
import CreateEmploymentEvaluationGql from './queries/CreateEmploymentEvaluation.gql';
import Action from './Action';
import DeleteEmploymentEvaluationGql from './queries/DeleteEmploymentEvaluation.gql';
import AcceptEmploymentEvaluationGql from './queries/AcceptEmploymentEvaluation.gql';

export const employmentEvaluationsNS = namespace('employmentEvaluations');

export type GQLEmploymentEvaluation = GQLEmploymentEvaluationFragmentFragment;

export type StoreState = ById<GQLEmploymentEvaluation>;

export type FetchEmploymentEvaluationFunction = (
  payload: Omit<GQLEmploymentEvaluationQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type CreateEmploymentEvaluationFunction = (
  payload: Omit<GQLCreateEmploymentEvaluationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteEmploymentEvaluationFunction = (
  payload: Omit<GQLDeleteEmploymentEvaluationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type AcceptEmploymentEvaluationFunction = (
  payload: Omit<GQLAcceptEmploymentEvaluationMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEmploymentEvaluationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByStaffShiftId: (state: StoreState) => (staffShiftId: number) =>
        Object.values<GQLEmploymentEvaluation>(state.byId).find(
          (item) => item.staffShiftId === staffShiftId,
        ),
    },
    actions: {
      [Action.REMOVE_EVALUATIONS_BY_STAFF_SHIFT_ID](
        { state, commit },
        staffShiftId: number,
      ) {
        const evaluations = Object.values<GQLEmploymentEvaluation>(
          state.byId,
        ).filter((it) => it.staffShiftId === staffShiftId);

        evaluations.forEach(({ id }) => {
          commit(NormalizedStoreMutation.REMOVE_ITEM, id);
        });
      },
      async [Action.FETCH_EVALUATION](
        { dispatch },
        payload: PayloadParameter<FetchEmploymentEvaluationFunction>,
      ) {
        const result: StoreActionResult = await dispatch(
          NormalizedStoreAction.FETCH,
          payload,
        );

        // employment evaluation not available anymore
        // we need to clear stale objects from normalized store
        if (result.state === StoreActionState.NOT_FOUND) {
          dispatch(
            Action.REMOVE_EVALUATIONS_BY_STAFF_SHIFT_ID,
            payload.staffShiftId,
          );
        }

        return result;
      },
      async [Action.CREATE_EVALUATION](
        { dispatch },
        payload: PayloadParameter<CreateEmploymentEvaluationFunction>,
      ) {
        const result: StoreActionResult = await dispatch(
          NormalizedStoreAction.CREATE,
          payload,
        );

        // remove all stale employment evaluations after new one was created
        if (result.state === StoreActionState.SUCCESS) {
          dispatch(
            Action.REMOVE_EVALUATIONS_BY_STAFF_SHIFT_ID,
            payload.staffShiftId,
          );
        }

        return result;
      },
      async [Action.ACCEPT_EMPLOYMENT_EVALUATION](
        { rootState },
        payload: PayloadParameter<AcceptEmploymentEvaluationFunction>,
      ): ReturnType<AcceptEmploymentEvaluationFunction> {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const variables: GQLAcceptEmploymentEvaluationMutationVariables = {
            ...payload,
            companyId: rootState.auth.currentCompanyId,
          };

          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient.mutate<
            GQLAcceptEmploymentEvaluationMutation,
            GQLAcceptEmploymentEvaluationMutationVariables
          >({
            mutation: AcceptEmploymentEvaluationGql,
            variables,
          });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          const { acceptEmploymentEvaluation } = result.data || {};

          if (!acceptEmploymentEvaluation) {
            return handleUnexpectedResult(
              Action.ACCEPT_EMPLOYMENT_EVALUATION as any,
              logger,
            );
          }

          const hasId =
            'id' in acceptEmploymentEvaluation && acceptEmploymentEvaluation.id;

          if (hasId) {
            return { state: StoreActionState.SUCCESS };
          }

          if ('conflicts' in acceptEmploymentEvaluation) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: acceptEmploymentEvaluation.conflicts,
            };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
    },
  };

  const fetch: ActionProvider<
    GQLEmploymentEvaluationQuery,
    GQLEmploymentEvaluationQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchEmploymentEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: EmploymentEvaluationGql,
      resultKey: 'employmentEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      failSilently: true,
    };
  };

  const create: ActionProvider<
    GQLCreateEmploymentEvaluationMutation,
    GQLCreateEmploymentEvaluationMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEmploymentEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateEmploymentEvaluationGql,
      resultKey: 'createEmploymentEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const remove: ActionProvider<
    GQLDeleteEmploymentEvaluationMutation,
    GQLDeleteEmploymentEvaluationMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteEmploymentEvaluationFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteEmploymentEvaluationGql,
      resultKey: 'deleteEmploymentEvaluation',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createNormalizedStore<
    GQLEmploymentEvaluation,
    StoreState,
    RootStoreState
  >({
    store,
    provide: {
      [NormalizedStoreAction.FETCH]: fetch,
      [NormalizedStoreAction.CREATE]: create,
      [NormalizedStoreAction.DELETE]: remove,
    },
    graphqlClient,
    logger,
  });
};

export default getEmploymentEvaluationsStore;
