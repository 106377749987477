import {
  getHSLCSSString,
  getSecondaryColor,
  HSLColor,
} from 'components/calendar-common/helpers/colors/Colors';
import moment from 'moment';
import type { GQLShiftFragmentFragment } from 'codegen/gql-types';
import StaffShift from './StaffShift';

export enum ShiftRequestKind {
  LEAVE_REQUEST = 'ChangeRequest',
  JOIN_REQUEST = 'StaffRequest',
  REPLACE_REQUEST = 'SwapRequest',
}

const DEFAULT_PRESET_COLOR = '#545A6C';
const getTimeWithoutTimeZone = (dateString: string) => dateString.substr(0, 16);

interface ShiftStyle {
  color: string;
  borderLeftColor: string;
  backgroundColor: string;
  backgroundImage: string | null;
  backgroundSize: string;
  backgroundRepeat: string;
}

interface ShiftRequest {
  id: number;
  type: ShiftRequestKind;
}

export type ShiftParams = Omit<
  NonNullable<GQLShiftFragmentFragment>,
  'staffShifts' | 'shiftplan'
> & {
  isMyShift: boolean;
  staffShifts: StaffShift[];
  position: GQLShiftFragmentFragment['locationsPosition']['position'] & {
    locationsPositionId: number;
    locationId: number;
  };
  color: string;
  shiftplanId: number;
};

class Shift {
  // constructor props
  public id: number;

  public staffShifts: StaffShift[];

  public startsAt: string;

  public endsAt: string;

  public workers: number;

  /**
   * Total amount of occupied slots, can be greater then amount of staffShifts
   * as some staffShifts can be hidden from employees without rights
   *
   * @type {number}
   * @memberof Shift
   */
  public staffShiftsLength: number;

  /**
   * Amount of pending evaluations for the shift. Pending evaluations are
   * evaluations that wait for stakeholder approval
   *
   * @type {number}
   * @memberof Shift
   */
  public pendingEvaluationsLength: number;

  public untimed: boolean;

  public isManageable: boolean;

  public note: string | null;

  public managerNote: string | null;

  public shiftplanId: number;

  public tags: ShiftParams['tags'];

  public position: ShiftParams['position'];

  public untimedBreakTime: number;

  public isMyShift: boolean;

  public color: string;

  public requests: ShiftRequest[];

  // computed props
  public isEmpty: boolean;

  public isFull: boolean;

  public isOpen: boolean;

  public isOverassigned: boolean;

  public composedNote: string;

  public tagIds: number[];

  public employmentIds: number[];

  public assignmentGroupIds: number[];

  public shiftRotationGroupIds: number[];

  public joinRequests: ShiftRequest[];

  public leaveRequests: ShiftRequest[];

  public replaceRequests: ShiftRequest[];

  public shiftPreset: GQLShiftFragmentFragment['shiftPreset'];

  // optional props, that are not set in constructor
  public employmentId: number | null = null;

  public isFixed = false;

  public style: ShiftStyle;

  public connectedGroupId: number | null;

  public constructor(
    {
      id,
      staffShifts,
      startsAt,
      endsAt,
      workers,
      untimedBreakTime,
      untimed = false,
      note = null,
      managerNote = null,
      requests,
      staffShiftsNeedsEvaluationCount,
      canManage,
      color,
      staffShiftsCount = 0,
      shiftAssignmentGroups = [],
      shiftRotationGroupIds = [],
      shiftplanId,
      position,
      isMyShift,
      shiftPreset,
      tags,
      connectedGroupId,
    }: ShiftParams,
    isShiftPresetsEnabled = false,
  ) {
    this.id = id;
    this.staffShifts = staffShifts;
    this.tags = tags;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.workers = workers;
    this.untimedBreakTime = untimedBreakTime;
    this.untimed = untimed === null ? false : untimed;
    this.isManageable = canManage === null ? false : canManage;
    this.isMyShift = isMyShift;
    this.position = position;
    this.pendingEvaluationsLength = staffShiftsNeedsEvaluationCount || 0;
    this.color = color;
    this.shiftPreset = shiftPreset;
    this.note = note === null || note.length === 0 ? null : note;
    this.requests = requests
      .map((it): ShiftRequest | null =>
        it.type &&
        Object.values(ShiftRequestKind).includes(
          it.type as unknown as ShiftRequestKind,
        )
          ? {
              ...it,
              type: it.type as unknown as ShiftRequestKind,
            }
          : null,
      )
      .filter((it): it is ShiftRequest => it !== null && it.type !== null);
    this.managerNote =
      managerNote === null || managerNote.length === 0 ? null : managerNote;
    this.shiftplanId = shiftplanId;
    this.staffShiftsLength = staffShiftsCount === null ? 0 : staffShiftsCount;

    this.isEmpty = this.staffShiftsLength === 0;
    this.isFull = this.staffShiftsLength === this.workers;
    this.isOpen = this.staffShiftsLength < this.workers;
    this.isOverassigned = this.staffShiftsLength > this.workers;
    this.tagIds = this.tags.map((it) => it.id);
    this.employmentIds = this.staffShifts
      .map((it) => it.id)
      .filter((it) => it !== null) as number[];
    this.shiftRotationGroupIds = shiftRotationGroupIds || [];
    this.assignmentGroupIds = (shiftAssignmentGroups || []).map(
      (it) => it.assignmentGroupId,
    );
    this.composedNote = this.getComposedNote() || '';
    this.replaceRequests = this.getRequestsOfKind(
      this.requests,
      ShiftRequestKind.REPLACE_REQUEST,
    );
    this.joinRequests = this.getRequestsOfKind(
      this.requests,
      ShiftRequestKind.JOIN_REQUEST,
    );
    this.leaveRequests = this.getRequestsOfKind(
      this.requests,
      ShiftRequestKind.LEAVE_REQUEST,
    );
    this.style = this.getShiftStyle(isShiftPresetsEnabled);
    this.connectedGroupId = connectedGroupId;
  }

  public serialize() {
    return this;
  }

  public getGridStartDate() {
    const dateWithoutTimeZone = getTimeWithoutTimeZone(this.startsAt);
    const momentDate = moment(dateWithoutTimeZone);
    const minutes = momentDate.minutes();
    if (minutes >= 30) {
      return momentDate.startOf('hour').minutes(30);
    }
    return momentDate.startOf('hour');
  }

  public getStartDateTime() {
    const dateWithoutTimeZone = getTimeWithoutTimeZone(this.startsAt);
    return moment(dateWithoutTimeZone);
  }

  public getEndDateTime() {
    const dateWithoutTimeZone = getTimeWithoutTimeZone(this.endsAt);
    return moment(dateWithoutTimeZone);
  }

  public getDate() {
    return this.getStartDate();
  }

  public getStartDate() {
    return this.startsAt.substr(0, 10);
  }

  public getEndDate() {
    return this.endsAt.substr(0, 10);
  }

  public getStartTime() {
    return this.startsAt.substr(11, 5);
  }

  public getEndTime() {
    return this.endsAt.substr(11, 5);
  }

  public getDurationMinutes() {
    const diff = moment(this.endsAt).diff(moment(this.startsAt));
    return diff / 1000 / 60;
  }

  public isStandBy() {
    return this.untimed;
  }

  public isMultiDay() {
    return this.getStartDate() !== this.getEndDate();
  }

  public hasNote() {
    return !!(this.composedNote && this.composedNote.length > 0);
  }

  public hasJoinRequests() {
    return this.joinRequests.length > 0;
  }

  public hasLeaveRequests() {
    return this.leaveRequests.length > 0;
  }

  public hasReplaceRequests() {
    return this.replaceRequests.length > 0;
  }

  public hasPendingEvaluations() {
    return this.pendingEvaluationsLength > 0;
  }

  public duration() {
    return moment(this.endsAt).diff(moment(this.startsAt));
  }

  private getShiftStyle(isShiftPresetStyle: boolean): ShiftStyle {
    const backgroundColor = isShiftPresetStyle
      ? this.shiftPreset?.color || DEFAULT_PRESET_COLOR
      : this.color;
    const secondaryColor: HSLColor = getSecondaryColor(backgroundColor);

    const secondaryColorString = getHSLCSSString(secondaryColor);

    const secondaryColorOpaque = `hsla(${secondaryColorString}, ${
      // for light colors we want to lower opacity so they are more visible
      // lightness is 1-100, so we take middle point as threshold
      secondaryColor.lightness < 50 ? '0.35' : '0.50'
    })`;
    const textColor = `hsl(${secondaryColorString})`;
    const backgroundImage = this.isFull
      ? null
      : `linear-gradient(${
          this.isOpen ? '45deg' : '180deg'
        }, ${secondaryColorOpaque} 3.13%, transparent 3.13%, transparent 50%, ${secondaryColorOpaque} 50%, ${secondaryColorOpaque} 53.13%, transparent 53.13%, transparent 100%)`;

    return {
      backgroundImage,
      backgroundColor,
      color: textColor,
      borderLeftColor: textColor,
      // image size should be different for horizontal stripes to have same ratio
      backgroundSize: this.isOpen ? '18px 18px' : '22px 22px',
      backgroundRepeat: 'repeat',
    };
  }

  private getRequestsOfKind(requests: ShiftRequest[], kind: ShiftRequestKind) {
    return requests.filter((it) => it.type === kind);
  }

  private getComposedNote() {
    const allNotes = [this.note, this.managerNote, this.position.note];

    return allNotes.reduce((prev, current) => {
      if (!current) {
        return prev;
      }
      return `${prev}${current} \n`;
    }, '');
  }
}

export default Shift;
