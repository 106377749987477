import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { IconName } from 'components/icons/types';
import Icon from 'components/icons/Icon';
import { Size } from 'components/types';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { IconPosition } from 'components/form/button/types';
import { HTMLAttributes } from 'vue-tsx-support/types/dom';
import styles from './alert.css';

export enum AlertKind {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface Props {
  kind: AlertKind;
  title: string;
  message?: string;
  isDismissible?: boolean;
}

interface Events {
  onCloseClick: void;
}

const ICON_NAME_MAP: Record<AlertKind, IconName> = {
  [AlertKind.SUCCESS]: IconName.DONE,
  [AlertKind.INFO]: IconName.INFO,
  [AlertKind.ERROR]: IconName.CLEAR,
  [AlertKind.WARNING]: IconName.REPORT_PROBLEM,
};

const KIND_STYLE_MAP: Record<AlertKind, string> = {
  [AlertKind.SUCCESS]: styles.alertSuccess,
  [AlertKind.ERROR]: styles.alertError,
  [AlertKind.INFO]: styles.alertInfo,
  [AlertKind.WARNING]: styles.alertWarning,
};

const BUTTON_COLOR_MAP: Record<AlertKind, ButtonColor> = {
  [AlertKind.SUCCESS]: ButtonColor.SUCCESS,
  [AlertKind.INFO]: ButtonColor.INFO,
  [AlertKind.ERROR]: ButtonColor.ERROR,
  [AlertKind.WARNING]: ButtonColor.WARNING,
};

@Component
export class Alert extends TsxComponent<HTMLAttributes & Props, Events> {
  @Prop({ default: AlertKind.INFO })
  protected kind: Props['kind'];

  @Prop()
  protected title: Props['title'];

  @Prop()
  protected message: Props['message'];

  @Prop({ default: true })
  protected isDismissible: NonNullable<Props['isDismissible']>;

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  public render() {
    return (
      <div class={[styles.alert, KIND_STYLE_MAP[this.kind]]}>
        <div class={styles.alertGroup}>
          <Icon
            name={ICON_NAME_MAP[this.kind]}
            size={Size.MEDIUM}
            class={styles.alertIcon}
          />
          <div class={styles.alertContent}>
            <div class={styles.alertTitle}>{this.title}</div>
            {this.message && (
              <div class={styles.alertMessage}>{this.message}</div>
            )}

            {this.$slots.default}
          </div>
        </div>
        {this.isDismissible && (
          <Button
            type="button"
            aria-label={this.$t('alert.buttonClose')}
            color={BUTTON_COLOR_MAP[this.kind]}
            icon={IconName.CLEAR}
            kind={ButtonKind.GHOST}
            size={Size.XXSMALL}
            iconPosition={IconPosition.ALONE}
            onClick={this.onCloseClick}
          />
        )}
      </div>
    );
  }
}

export default Alert;
