import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateStaffShiftMutation,
  GQLCreateStaffShiftMutationVariables,
  GQLDeleteStaffShiftMutation,
  GQLDeleteStaffShiftMutationVariables,
  GQLMoveStaffShiftMutation,
  GQLMoveStaffShiftMutationVariables,
  GQLReplaceStaffShiftMutation,
  GQLReplaceStaffShiftMutationVariables,
  GQLStaffShiftFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  handleUnexpectedResult,
} from 'store/normalized-store';
import {
  OmitCompanyId,
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import CreateStaffShiftGql from './queries/CreateStaffShift.gql';
import DeleteStaffShiftGql from './queries/DeleteStaffShift.gql';
import StaffShiftAction from './Action';
import MoveStaffShift from './queries/MoveStaffShift.gql';
import ReplaceStaffShift from './queries/ReplaceStaffShift.gql';

export const staffShiftsNS = namespace('staffShifts');

export type StoreState = ById<StaffShift>;

export type StaffShift = GQLStaffShiftFragmentFragment;

export type MoveStaffShiftFunction = (
  payload: OmitCompanyId<GQLMoveStaffShiftMutationVariables['staffShift']>,
) => Promise<StoreActionResult>;

export type ReplaceStaffShiftFunction = (
  payload: GQLReplaceStaffShiftMutationVariables['staffShift'],
) => Promise<StoreActionResult>;

export type CreateStaffShiftFunction = (payload: {
  staffShift: OmitCompanyId<GQLCreateStaffShiftMutationVariables['staffShift']>;
}) => Promise<StoreActionResult>;

export type DeleteStaffShiftFunction = (
  payload: OmitCompanyId<GQLDeleteStaffShiftMutationVariables>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getStaffShiftsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    actions: {
      async [StaffShiftAction.MOVE](
        { rootState },
        {
          shiftId,
          employmentId,
          sourceShiftId,
          ignoreConflicts,
        }: PayloadParameter<MoveStaffShiftFunction>,
      ) {
        const { currentCompanyId } = rootState.auth;
        if (!currentCompanyId) {
          return {
            state: StoreActionState.ERROR,
          };
        }

        const variables: GQLMoveStaffShiftMutationVariables = {
          staffShift: {
            shiftId,
            employmentId,
            sourceShiftId,
            ignoreConflicts,
            companyId: currentCompanyId,
          },
        };
        try {
          const result = await graphqlClient.mutate<
            GQLMoveStaffShiftMutation,
            GQLMoveStaffShiftMutationVariables
          >({
            mutation: MoveStaffShift,
            variables,
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          const { moveStaffShift } = result.data || {};

          if (!moveStaffShift) {
            return handleUnexpectedResult(StaffShiftAction.MOVE as any, logger);
          }

          if ('conflicts' in moveStaffShift) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: moveStaffShift.conflicts,
            };
          }

          const hasId = 'id' in moveStaffShift && moveStaffShift.id;

          if (hasId) {
            return {
              state: StoreActionState.SUCCESS,
              entityId: moveStaffShift.id,
            };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
      async [StaffShiftAction.REPLACE](
        { rootState },
        {
          shiftId,
          employmentId,
          sourceEmploymentId,
          ignoreConflicts,
        }: PayloadParameter<ReplaceStaffShiftFunction>,
      ) {
        const { currentCompanyId } = rootState.auth;
        if (!currentCompanyId) {
          return {
            state: StoreActionState.ERROR,
          };
        }

        const variables: GQLReplaceStaffShiftMutationVariables = {
          staffShift: {
            shiftId,
            employmentId,
            sourceEmploymentId,
            ignoreConflicts,
          },
        };
        try {
          const result = await graphqlClient.mutate<
            GQLReplaceStaffShiftMutation,
            GQLReplaceStaffShiftMutationVariables
          >({
            mutation: ReplaceStaffShift,
            variables,
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          const { replaceStaffShift } = result.data || {};

          if (!replaceStaffShift) {
            return handleUnexpectedResult(
              StaffShiftAction.REPLACE as any,
              logger,
            );
          }

          if ('conflicts' in replaceStaffShift) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: replaceStaffShift.conflicts,
            };
          }

          const hasId = 'id' in replaceStaffShift && replaceStaffShift.id;

          if (hasId) {
            return {
              state: StoreActionState.SUCCESS,
              entityId: replaceStaffShift.id,
            };
          }
        } catch (e) {
          logger.instance.error(e);
        }

        return { state: StoreActionState.ERROR };
      },
    },
  };

  const create: ActionProvider<
    GQLCreateStaffShiftMutation,
    GQLCreateStaffShiftMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateStaffShiftFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = {
      companyId: rootState.auth.currentCompanyId,
      staffShift: {
        ...payload.staffShift,
        companyId: rootState.auth.currentCompanyId,
      },
    };

    return {
      resultKey: 'createStaffShift',
      query: CreateStaffShiftGql,
      variables,
      useBatching: true,
    };
  };

  const remove: ActionProvider<
    GQLDeleteStaffShiftMutation,
    GQLDeleteStaffShiftMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteStaffShiftFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables: GQLDeleteStaffShiftMutationVariables = {
      companyId: rootState.auth.currentCompanyId,
      id: payload.id,
      unassignFromConnected: payload.unassignFromConnected,
    };

    return {
      resultKey: 'deleteStaffShift',
      query: DeleteStaffShiftGql,
      variables,
      useBatching: true,
    };
  };

  return createNormalizedStore<StaffShift, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
    },
    graphqlClient,
    logger,
  });
};

export default getStaffShiftsStore;
