import DropdownLegacy from 'components/dropdown-legacy/DropdownLegacy';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { authNS } from 'components/auth/store/Store';
import type {
  HasAnyLocationRightFunction,
  HasAnyOfLocationsPositionsRightFunction,
  HasAnyRightFunction,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'utils/url';
import SpIcon from 'components/sp-icon/SpIcon';
import { ROOT_ROUTE } from 'components/shift-schedule/routes';
import type { FetchAllLocationsPositionsFunction } from 'src/store/locations-positions/Store';
import {
  LocationsPosition,
  locationsPositionsNS,
} from 'src/store/locations-positions/Store';
import { Action as StoreAction } from 'store/normalized-store';
import { executeStoreActionWithFailureSnackbar } from 'src/utils/store';
import type { GetMultipleById } from 'utils/store';
import { sortBySortOrder } from 'src/utils/sort';
import { filterFalsy } from 'src/utils/utils';
import styles from './location-dropdown.css';

@Component
export default class LocationDropdown extends TsxComponent<{}> {
  @authNS.Getter
  protected hasAnyRight: HasAnyRightFunction;

  @authNS.Getter
  protected hasAnyLocationRight: HasAnyLocationRightFunction;

  @authNS.Getter
  public hasAnyOfLocationsPositionsRight: HasAnyOfLocationsPositionsRightFunction;

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  // #region Locations Positions Store
  @locationsPositionsNS.Action(StoreAction.FETCH_ALL)
  protected fetchAllLocationsPositions: FetchAllLocationsPositionsFunction;

  @locationsPositionsNS.Getter('getByLocationAndPositionId')
  protected getLocationsPositionsByLocationAndPositionId: GetMultipleById<LocationsPosition>;
  // #endregion Locations Positions Store

  protected get allLocationsUserIsAssigned() {
    const locationsPositions = this.currentEmployment?.locationsPositions?.map(
      (locationPosition) => locationPosition?.location?.id,
    );

    return [...new Set(locationsPositions)];
  }

  protected get locations() {
    const locations = (this.currentCompany?.locations || [])
      .filter(filterFalsy)
      .sort(sortBySortOrder(false));

    if (this.isSuperAdmin) {
      return locations;
    }

    if (this.currentEmployment?.isStakeholder) {
      const hasGlobalRights = this.hasAnyRight(
        'shifts_show_all',
        'shifts_manage_all',
        'payments_show_all',
        'payments_manage_all',
      );

      if (hasGlobalRights) {
        return locations;
      }

      return locations.filter((location) => {
        if (this.allLocationsUserIsAssigned.includes(location.id)) {
          return true;
        }

        // Location related rights
        const rightsToCheck = [
          'shift_show_right',
          'shift_manage_right',
          'payment_show_right',
          'payment_manage_right',
        ];

        const hasLocationRights = this.hasAnyLocationRight(
          location.id,
          ...rightsToCheck,
        );
        if (hasLocationRights) {
          return true;
        }

        // check against all LocationPositions within Location
        // as stakeholder can be assigned with right for one
        // position with this location.
        const allLocationsPositionsInLocation =
          this.getLocationsPositionsByLocationAndPositionId(location.id).map(
            (lopo) => lopo.id,
          );
        return this.hasAnyOfLocationsPositionsRight(
          allLocationsPositionsInLocation,
          ...rightsToCheck,
        );
      });
    }

    if (this.currentEmployment?.isEmployee) {
      return locations.filter((location) =>
        this.allLocationsUserIsAssigned.includes(location.id),
      );
    }

    return [];
  }

  public async mounted() {
    const allLocations = this.currentCompany?.locations;

    await executeStoreActionWithFailureSnackbar(
      this,
      {
        locationIds: allLocations
          ? allLocations.map((location) => location.id)
          : [],
        positionIds: null,
      },
      this.fetchAllLocationsPositions,
      'shifts.dialog.error',
      'shifts.dialog.error.genericLoadLocationsPositions',
    );
  }

  public render() {
    if (
      !this.$route.path.startsWith(ROOT_ROUTE.path) ||
      this.locations.length === 0
    ) {
      return null;
    }

    if (this.locations.length === 1) {
      return (
        <span class={styles.locationDropdownSingleItem}>
          {this.locations[0].name}
        </span>
      );
    }

    return (
      <DropdownLegacy
        id="location-dropdown"
        items={this.locations.map((location) => ({
          label: location?.name || '',
          href: getUrlWithApiPrefix(
            getUrlWithCompanyPrefix(
              `/app/set_current_location?id=${location?.id}`,
              this.currentCompany,
            ),
          ),
        }))}
      >
        <SpIcon name="location" class={styles.locationDropdownIcon} />
        {
          this.currentCompany?.locations?.find(
            (o) => o?.id === this.currentLocationId,
          )?.name
        }
      </DropdownLegacy>
    );
  }
}
