import SecureLS from 'secure-ls';

export default class SecureLocalStorage implements Storage {
  protected secureLS: SecureLS;

  public constructor() {
    this.secureLS = new SecureLS({
      encodingType: 'AES',
      isCompression: true,
    });
  }

  public get length() {
    return this.secureLS.getAllKeys().length;
  }

  public clear() {
    return this.secureLS.clear();
  }

  public getItem(key: string) {
    return this.secureLS.get(key);
  }

  public key(index: number) {
    return this.secureLS.getAllKeys()[index];
  }

  public removeItem(key: string) {
    return this.secureLS.remove(key);
  }

  public setItem(key: string, value: string) {
    return this.secureLS.set(key, value);
  }
}
