import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import type { SelectedTimeframe } from 'components/datepicker/types';
import {
  CalendarNamespace,
  PrintTimeframeKind,
  PrintViewKind,
  TimeframeKind,
} from 'components/calendar-common/Enums';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import { endOf, getDateInTimeZone, startOf, Unit } from 'utils/date-related';
import type { Shiftplan } from 'components/calendar-common/types';
import moment from 'moment';
import { eachDayOfInterval } from 'date-fns';
import DateItem from 'components/calendar-common/common/DateItem';
import { toDate } from 'date-fns-tz';
import Action from './Action';
import Mutation from './Mutation';

export const calendarPrintCommonNS = namespace(
  `${CalendarNamespace.PRINT}/common`,
);

export enum NoteType {
  NOTE = 'note',
  POSITION_NOTE = 'position_note',
}

export type StoreState = {
  printViewKind: PrintViewKind;
  timeframeKind: TimeframeKind;
  timeframe: SelectedTimeframe;
  shiftplan: Shiftplan | null;
};

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    printViewKind: PrintViewKind.LIST,
    timeframe: {
      startsAt: new Date(),
      endsAt: new Date(),
    },
    timeframeKind: TimeframeKind.DAY,
    shiftplan: null,
  },
  mutations: {
    [Mutation.SET_SHIFTPLAN](state, value) {
      state.shiftplan = value;
    },
    [Mutation.SET_PRINT_VIEW_KIND](state, value: PrintViewKind) {
      state.printViewKind = value;
    },
    [Mutation.SET_TIMEFRAME](state, timeframe: SelectedTimeframe) {
      state.timeframe = timeframe;
    },
    [Mutation.SET_TIMEFRAME_KIND](state, timeframeKind: PrintTimeframeKind) {
      state.timeframeKind = timeframeKind;
    },
  },
  actions: {
    [Action.SET_PRINT_VIEW_KIND]({ commit }, printViewKind: PrintViewKind) {
      commit(Mutation.SET_PRINT_VIEW_KIND, printViewKind);
    },
    [Action.SET_TIMEFRAME]({ commit }, timeframe: SelectedTimeframe) {
      commit(Mutation.SET_TIMEFRAME, timeframe);
    },
    [Action.SET_TIMEFRAME_KIND]({ commit }, timeframeKind: PrintTimeframeKind) {
      commit(Mutation.SET_TIMEFRAME_KIND, timeframeKind);
    },
    [Action.SET_SHIFTPLAN]({ commit }, shiftplan: Shiftplan) {
      commit(Mutation.SET_SHIFTPLAN, shiftplan);
    },
  },
  getters: {
    // Calendar is using moment.js for now
    currentInterval(state) {
      return {
        start: startOf(
          state.timeframe.startsAt,
          Unit.DAY,
          VueTimeZoneProvider.getTimeZone(),
        ),
        end: endOf(
          state.timeframe.endsAt,
          Unit.DAY,
          VueTimeZoneProvider.getTimeZone(),
        ),
      };
    },
    dates(state, getters) {
      const timeZone = VueTimeZoneProvider.getTimeZone();

      const isWithinShiftplan = (
        date: Date,
        shiftplanStart: Date,
        shiftplanEnd: Date,
      ) =>
        date.valueOf() >= shiftplanStart.valueOf() &&
        date.valueOf() <= shiftplanEnd.valueOf();

      const { start, end }: { start: Date; end: Date } =
        getters.currentInterval;

      const shiftedInterval = {
        start: getDateInTimeZone(start, timeZone),
        end: getDateInTimeZone(end, timeZone),
      };

      const { shiftplan } = state;
      const shiftplanStart = shiftplan
        ? getDateInTimeZone(
            startOf(
              toDate(shiftplan.startsAt, { timeZone }),
              Unit.DAY,
              timeZone,
            ),
            timeZone,
          )
        : start;
      const shiftplanEnd = shiftplan
        ? getDateInTimeZone(
            endOf(toDate(shiftplan.endsAt, { timeZone }), Unit.DAY, timeZone),
            timeZone,
          )
        : end;

      const dates = eachDayOfInterval(shiftedInterval);
      return dates.map(
        (date) =>
          new DateItem(
            moment(date),
            isWithinShiftplan(date, shiftplanStart, shiftplanEnd),
            // we do not want to highlight current date on print view
            false,
            false,
          ),
      );
    },
    dateKeys(state, getters) {
      return getters.dates.map((date) => date.dateKey);
    },
  },
});
