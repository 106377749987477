import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { EventsOn, SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './form-dialog.css';

// types in dom.d.ts seem to be inaccurate
interface FormEventsOn<E> {
  onChange: SyntheticEvent<E, UIEvent>;
  onInput: SyntheticEvent<E, UIEvent>;
  onInvalid: SyntheticEvent<E, UIEvent>;
  onReset: SyntheticEvent<E, UIEvent>;
  onSubmit: SyntheticEvent<E, UIEvent>;
}

@Component({
  inheritAttrs: false,
})
export default class FormDialog extends TsxComponent<
  /* eslint-disable @typescript-eslint/indent */
  Partial<HTMLFormElement>,
  EventsOn<HTMLFormElement> & FormEventsOn<HTMLFormElement>
  /* eslint-enable @typescript-eslint/indent */
> {
  public render() {
    return (
      <form
        {...{
          attrs: { ...this.$attrs },
          on: { ...this.$listeners },
        }}
        class={styles.formDialog}
      >
        {this.$slots.default}
      </form>
    );
  }
}
