import DateItem, {
  DateKey,
} from 'src/components/calendar-common/common/DateItem';
import moment, { Moment } from 'moment';
import { GQLEmploymentsRotationGroup } from 'codegen/gql-types';
import isWithinInterval from 'date-fns/isWithinInterval';
import { MAX_DATE, MIN_DATE } from './date-related';

export const getPatternBasedOnRotationGroup = (
  dateItems: DateItem[],
  rotationGroup,
) => {
  const momentTimeframeStartsAt = dateItems[0].date;
  const momentTimeframeEndsAt = dateItems[dateItems.length - 1].date;
  const pattern: Record<DateKey, number | null> = Object.fromEntries(
    dateItems.map((it) => [it.dateKey, null]),
  );

  if (!rotationGroup) {
    return pattern;
  }

  rotationGroup.forEach((rotationGroupAssignment) => {
    const { endsAt, startsAt, shiftPresetIds, anchorDate, rotationInterval } =
      rotationGroupAssignment;
    if (
      !(
        (startsAt && momentTimeframeEndsAt.isBefore(startsAt)) ||
        (endsAt && momentTimeframeStartsAt.isAfter(endsAt))
      )
    ) {
      // set all dates to start or end of day
      const assignmentEndDate = moment(endsAt).endOf('day');
      const assignmentStartDate = moment(startsAt).startOf('day');
      const momentAnchorDate: Moment = moment(anchorDate).startOf('day');
      const rotationPadding =
        momentTimeframeStartsAt.diff(momentAnchorDate, 'days') %
        rotationInterval;

      dateItems.forEach((dateItem, index) => {
        if (
          // ignore dates outside current shiftplan
          !dateItem.isWithinShiftplan ||
          (assignmentEndDate &&
            dateItem.date.isAfter(assignmentEndDate, 'days')) ||
          (assignmentStartDate &&
            dateItem.date.isBefore(assignmentStartDate, 'days'))
        ) {
          return;
        }

        if (pattern[dateItem.dateKey] !== null) {
          console.warn(
            'there is an overlap?',
            index,
            pattern[index],
            rotationGroupAssignment,
          );
        }

        const idx =
          (index + rotationPadding < 0
            ? // make sure that index is positive
              rotationInterval + index + rotationPadding
            : index + rotationPadding) % rotationInterval;

        pattern[dateItem.dateKey] = shiftPresetIds[idx];
      });
    }
  });

  return pattern;
};

export const getCommonShiftRotationGroupId = (
  { shiftRotationGroupIds, shiftStartsAt },
  shiftRotationEnabled: boolean,
  employmentsShiftRotationGroups:
    | Array<
        Pick<
          GQLEmploymentsRotationGroup,
          'id' | 'endsAt' | 'startsAt' | 'shiftRotationGroupId'
        >
      >
    | undefined,
): number | null => {
  if (shiftRotationEnabled && shiftRotationGroupIds?.length) {
    const isShiftWithinEmploymentRotationGroupInterval = (
      o: Pick<
        GQLEmploymentsRotationGroup,
        'id' | 'endsAt' | 'startsAt' | 'shiftRotationGroupId'
      >,
    ) =>
      isWithinInterval(new Date(shiftStartsAt), {
        start: o.startsAt ? new Date(o.startsAt) : MIN_DATE,
        end: o.endsAt ? new Date(o.endsAt) : MAX_DATE,
      });

    const commonEmploymentsShiftRotationGroup = (
      employmentsShiftRotationGroups || []
    ).find(
      (o) =>
        shiftRotationGroupIds?.includes(o.shiftRotationGroupId) &&
        isShiftWithinEmploymentRotationGroupInterval(o),
    );

    return commonEmploymentsShiftRotationGroup?.shiftRotationGroupId || null;
  }

  return null;
};
