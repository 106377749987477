import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';

import styles from './section-heading.css';

interface Props {
  context?: string;
  summary?: string;
}

@Component
class SectionHeading extends TSXComponent<Props> {
  @Prop()
  public context: Props['context'];

  @Prop()
  public summary: Props['summary'];

  public render() {
    return (
      <header
        class={{
          [styles.sectionHeading]: true,
          [styles.sectionHeadingWithContext]: this.context,
        }}
      >
        {this.$slots.default}

        {this.context && (
          <span class={styles.sectionHeadingContext}>
            &nbsp;·&nbsp;
            {this.context}
          </span>
        )}

        {this.summary && (
          <span class={styles.sectionHeadingSummary}>{this.summary}</span>
        )}
      </header>
    );
  }
}

export default SectionHeading;
