import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Alert, { AlertKind } from 'components/alert/Alert';
import styles from './snackbar.css';

export interface Props {
  kind: AlertKind;
  title: string;
  isOpen?: boolean;
}

export interface Events {
  onClose: void;
}

@Component
class Snackbar extends TsxComponent<Props, Events> {
  @Prop()
  public kind: Props['kind'];

  @Prop()
  public title: Props['title'];

  @Prop({ default: false })
  public isOpen: NonNullable<Props['isOpen']>;

  @Emit('close')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onClose() {}

  public render() {
    return (
      <transition
        enter-class={styles.transitionSlideEnter}
        enter-to-class={styles.transitionSlideEnterTo}
        leave-class={styles.transitionSlideLeave}
        leave-to-class={styles.transitionSlideLeaveTo}
      >
        {this.isOpen && (
          <Alert
            class={styles.snackbar}
            kind={this.kind}
            role="status"
            aria-live="polite"
            onCloseClick={this.onClose}
            isDismissible={true}
            title={this.title}
          />
        )}
      </transition>
    );
  }
}

export default Snackbar;
