import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEmploymentEvaluationBreakMutation,
  GQLCreateEmploymentEvaluationBreakMutationVariables,
  GQLDeleteEmploymentEvaluationBreakMutation,
  GQLDeleteEmploymentEvaluationBreakMutationVariables,
  GQLUpdateEmploymentEvaluationBreakMutation,
  GQLUpdateEmploymentEvaluationBreakMutationVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateEmploymentEvaluationBreakGql from './queries/CreateEmploymentEvaluationBreak.gql';
import DeleteEmploymentEvaluationBreakGql from './queries/DeleteEmploymentEvaluationBreak.gql';
import UpdateEmploymentEvaluationBreakGql from './queries/UpdateEmploymentEvaluationBreak.gql';
import { EvaluationBreak } from '../evaluation-breaks/Store';

export const EmploymentEvaluationBreaksNS = namespace(
  'employmentEvaluationBreaks',
);

export type StoreState = ById<EvaluationBreak>;

export type CreateEmploymentEvaluationBreaksFunction = (
  payload: Omit<
    GQLCreateEmploymentEvaluationBreakMutationVariables,
    'companyId'
  >,
) => Promise<StoreActionResult>;

export type DeleteEmploymentEvaluationBreaksFunction = (
  payload: Omit<
    GQLDeleteEmploymentEvaluationBreakMutationVariables & { id: number },
    'companyId'
  >,
) => Promise<StoreActionResult>;

export type UpdateEmploymentEvaluationBreaksFunction = (
  payload: Omit<
    GQLUpdateEmploymentEvaluationBreakMutationVariables,
    'companyId'
  >,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEmploymentEvaluationBreaksStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
  };

  const create: ActionProvider<
    GQLCreateEmploymentEvaluationBreakMutation,
    GQLCreateEmploymentEvaluationBreakMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEmploymentEvaluationBreaksFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateEmploymentEvaluationBreakGql,
      resultKey: 'createEmploymentEvaluationBreak',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const remove: ActionProvider<
    GQLDeleteEmploymentEvaluationBreakMutation,
    GQLDeleteEmploymentEvaluationBreakMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteEmploymentEvaluationBreaksFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteEmploymentEvaluationBreakGql,
      resultKey: 'deleteEmploymentEvaluationBreak',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const update: ActionProvider<
    GQLUpdateEmploymentEvaluationBreakMutation,
    GQLUpdateEmploymentEvaluationBreakMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateEmploymentEvaluationBreaksFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: UpdateEmploymentEvaluationBreakGql,
      resultKey: 'updateEmploymentEvaluationBreak',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  return createNormalizedStore<EvaluationBreak, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getEmploymentEvaluationBreaksStore;
