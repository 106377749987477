import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Prompt from 'components/prompt/Prompt';

@Component
export default class DialogConfirm extends TsxComponent<
  {
    isOpen?: boolean;
    isSubmitting?: boolean;
    title: string;
  },
  {
    onCancel: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
    onConfirm: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  }
> {
  @Prop()
  public isOpen?: boolean;

  @Prop()
  public isSubmitting?: boolean;

  @Prop()
  public title: string;

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        isCloseButtonDisabled={this.isSubmitting}
        isConfirmButtonDisabled={this.isSubmitting}
        onCloseClick={(e) =>
          this.$emit('cancel', createEventPayload(e, undefined))
        }
        onConfirmClick={(e) =>
          this.$emit('confirm', createEventPayload(e, undefined))
        }
        title={this.title}
      >
        {this.$slots.default}
      </Prompt>
    );
  }
}
