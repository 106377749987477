export enum BrandColor {
  BLACK = 'Black',
  WHITE = 'White',
}

export enum BrandKind {
  ICON_AND_TEXT = 'IconAndText',
  ICON = 'Icon',
  TEXT = 'Text',
  ANIMATED_ICON = 'AnimatedIcon',
}
