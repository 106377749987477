import InputText from 'components/form/input-text/InputText';
import { formatISO, isValid } from 'date-fns';
import {
  getDateFromDateAndTimeString,
  getDateInTimeZone,
  isSameDayInTimeZone,
  LOCALE_TIMEZONE,
} from 'src/utils/date-related';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getRandomString } from 'src/utils/random';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './input-date-time.css';

export enum Kind {
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'dateTime',
}

export interface Props {
  kind: Kind;
  datepickerLabel?: string;
  timepickerLabel?: string;
  isDisabled?: boolean;
  isDatepickerDisabled?: boolean;
  isTimepickerDisabled?: boolean;
  name?: string;
  required?: boolean;
  value: Date | null;
  isValid?: boolean;
  max?: Date;
  min?: Date;
  timeZone?: string;
  error?: string;
  info?: string;
}

interface Events {
  onInput: (e: EventPayload<{ value: Date }>) => void;
}

@Component
export default class InputDateTime extends TsxComponent<Props, Events> {
  public $refs: {
    dateRef: Vue;
    timeRef: Vue;
  };

  protected dateString = '';

  protected timeString = '';

  protected internalDate: Date = new Date();

  @Prop({ default: Kind.DATETIME })
  public kind: Kind;

  @Prop()
  protected datepickerLabel: Props['datepickerLabel'];

  @Prop()
  protected timepickerLabel: Props['timepickerLabel'];

  @Prop({ default: () => `inp-${getRandomString()}` })
  protected name: Props['name'];

  @Prop()
  protected required: Props['required'];

  @Prop()
  public value: Props['value'];

  @Prop()
  public timeZone: Props['timeZone'];

  @Prop()
  public isValid: Props['isValid'];

  @Prop({ default: false })
  public isDatepickerDisabled: Props['isDatepickerDisabled'];

  @Prop({ default: false })
  public isTimepickerDisabled: Props['isTimepickerDisabled'];

  @Prop({ default: false })
  public isDisabled: Props['isDisabled'];

  @Prop()
  public max: Props['max'];

  @Prop()
  public min: Props['min'];

  @Prop()
  public error: Props['error'];

  @Prop()
  public info: Props['info'];

  private get timeZoneValue() {
    return this.timeZone || LOCALE_TIMEZONE;
  }

  private get maxDateString() {
    if (!this.max) {
      return undefined;
    }

    return formatISO(getDateInTimeZone(this.max, this.timeZoneValue)).substr(
      0,
      10,
    );
  }

  private get maxTimeString() {
    if (
      !this.max ||
      !this.maxDateString ||
      (this.dateString &&
        !isSameDayInTimeZone(
          new Date(this.dateString),
          new Date(this.maxDateString),
          this.timeZoneValue,
        ))
    ) {
      return undefined;
    }

    return formatISO(getDateInTimeZone(this.max, this.timeZoneValue)).substr(
      11,
      5,
    );
  }

  private get minDateString() {
    if (!this.min) {
      return undefined;
    }

    return formatISO(getDateInTimeZone(this.min, this.timeZoneValue)).substr(
      0,
      10,
    );
  }

  private get minTimeString() {
    if (
      !this.min ||
      !this.minDateString ||
      (this.dateString &&
        !isSameDayInTimeZone(
          new Date(this.dateString),
          new Date(this.minDateString),
          this.timeZoneValue,
        ))
    ) {
      return undefined;
    }

    return formatISO(getDateInTimeZone(this.min, this.timeZoneValue)).substr(
      11,
      5,
    );
  }

  protected onInput(e: SyntheticEvent<HTMLInputElement, Event>) {
    if (!e.target.value) {
      // date cleared
      this.$emit('input', createEventPayload(e, { value: '' }));
      return;
    }

    const isDateUpdate = e.target.name.endsWith('date');

    try {
      const value = isDateUpdate
        ? getDateFromDateAndTimeString(
            e.target.value.substr(0, 10),
            this.timeString,
            this.timeZoneValue,
          )
        : getDateFromDateAndTimeString(
            this.dateString,
            e.target.value,
            this.timeZoneValue,
          );

      /*
        Native inputs allow to input some interesting values
        that can not be parsed properly by date-fns
        so we are resetting input state to last valid state
        empty value is emitted when you delete part of date or time
      */
      if (e.target.value.length === 0 || !isValid(value)) {
        const oldValue = this.internalDate;
        // clear internal value so update triggers
        this.internalDate = new Date(0);
        this.onValueChange(oldValue);
        // force update inputs to match passed props
        this.$refs.dateRef.$forceUpdate();
        this.$refs.timeRef.$forceUpdate();
        return;
      }

      if (isDateUpdate) {
        this.dateString = e.target.value.substr(0, 10);
      } else {
        this.timeString = e.target.value;
      }

      this.internalDate = value;
      this.$emit('input', createEventPayload(e, { value }));
    } catch (ex) {
      // fail silently
    }
  }

  @Watch('kind')
  protected onIsFullDayChange(kind: Kind) {
    if (kind === Kind.DATE) {
      this.timeString = '00:00';
    }
  }

  @Watch('value', { immediate: true })
  public onValueChange(value: Date) {
    if (value && isValid(value)) {
      // FAQ: use formatISO to maintain offset when converting to ISO string
      // Date.toISOString would convert it to UTC
      try {
        const isoString = formatISO(
          getDateInTimeZone(value, this.timeZoneValue),
        );

        this.dateString = isoString.substr(0, 10);
        this.timeString = isoString.substr(11, 5);
        this.internalDate = value;
      } catch (er) {
        // fail silently
      }
    } else {
      this.dateString = '';
      this.timeString = '';
    }
  }

  public render() {
    return (
      <section class={styles.inputDateTime}>
        {this.kind !== Kind.TIME && (
          <InputText
            ref="dateRef"
            disabled={this.isDisabled || this.isDatepickerDisabled}
            id={`${this.name}_date-input`}
            label={this.datepickerLabel}
            name={`${this.name}_date`}
            onChange={this.onInput}
            // being required will hide all "clear input" buttons
            // https://stackoverflow.com/questions/49527186/how-to-remove-cross-on-date-and-time-html-inputs-in-firefox
            required={
              this.required || this.isDisabled || this.isDatepickerDisabled
            }
            size={10}
            type="date"
            value={this.dateString}
            isValid={this.isValid}
            min={this.minDateString}
            max={this.maxDateString}
            error={this.error}
            info={this.info}
          />
        )}

        {this.kind !== Kind.DATE && (
          <InputText
            ref="timeRef"
            disabled={this.isDisabled || this.isTimepickerDisabled}
            id={`${this.name}_time`}
            label={this.timepickerLabel}
            name={`${this.name}_time-input`}
            onChange={this.onInput}
            // being required will hide all "clear input" buttons
            // https://stackoverflow.com/questions/49527186/how-to-remove-cross-on-date-and-time-html-inputs-in-firefox
            required={
              this.required || this.isDisabled || this.isTimepickerDisabled
            }
            size={10}
            type="time"
            value={this.timeString}
            isValid={this.isValid}
            min={this.minTimeString}
            max={this.maxTimeString}
            error={this.kind === Kind.TIME ? this.error : undefined}
            info={this.info}
          />
        )}
      </section>
    );
  }
}
