import { isValid, parseISO } from 'date-fns';
import { RouteConfig, Route as RouteType } from 'vue-router';

export enum Route {
  ROOT = 'shiftDialog',
  DIALOG_NEW = 'shiftDialogNew',
  TAB_PAYGRADES = 'shiftDialogPaygrades',
  TAB_EMPLOYEES = 'shiftDialogEmployees',
  TAB_JOIN_SHIFT = 'shiftDialogJoinShift',
  TAB_LEAVE_SHIFT = 'shiftDialogLeaveShift',
  TAB_REPLACE_REQUESTS = 'shiftDialogReplaceRequests',
  TAB_EVALUATION = 'shiftDialogEvaluation',
}

export const propsInjectorDialogNew = (route: RouteType) => {
  const startsAt = Array.isArray(route.query.startsAt)
    ? parseISO(route.query.startsAt[0] || '')
    : parseISO(route.query.startsAt);

  const endsAt = Array.isArray(route.query.endsAt)
    ? parseISO(route.query.endsAt[0] || '')
    : parseISO(route.query.endsAt);

  const locationsPositionId =
    typeof route.query.locationsPositionId === 'string'
      ? parseInt(route.query.locationsPositionId, 10)
      : Number.NaN;

  const employmentId =
    typeof route.query.employmentId === 'string'
      ? parseInt(route.query.employmentId, 10)
      : Number.NaN;

  const { cacheIdentifier } = route.query;

  return {
    startsAt: isValid(startsAt) ? startsAt : undefined,
    endsAt: isValid(endsAt) ? endsAt : undefined,
    shiftplanId: parseInt(route.params.shiftplanId, 10),
    locationsPositionId: !Number.isNaN(locationsPositionId)
      ? locationsPositionId
      : undefined,
    employmentId: !Number.isNaN(employmentId) ? employmentId : undefined,
    cacheIdentifier,
  };
};

const routes: RouteConfig[] = [
  {
    component: () => import('./DialogShiftContainer'),
    path: 'shifts/:id(\\d+)',
    props: (route: RouteType) => ({
      shiftId: Number.parseInt(route.params.id, 10),
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
    children: [
      {
        name: Route.ROOT,
        component: () => import('./details/DetailsContainer'),
        path: '',
        meta: {
          hasTabsHidden: false,
          hasEditButton: true,
          hasDeleteButton: true,
        },
      },
      {
        component: () => import('./paygrades/Paygrades'),
        name: Route.TAB_PAYGRADES,
        path: 'paygrades',
        meta: {
          hasEditButton: true,
        },
      },
      {
        component: () => import('./employees/Employees'),
        name: Route.TAB_EMPLOYEES,
        path: 'employees',
      },
      {
        component: () => import('./join-shift/JoinShift'),
        name: Route.TAB_JOIN_SHIFT,
        path: 'join-shift',
      },
      {
        component: () => import('./leave-shift/LeaveShift'),
        name: Route.TAB_LEAVE_SHIFT,
        path: 'leave-shift',
      },
      {
        component: () => import('./replace-requests/ReplaceRequests'),
        name: Route.TAB_REPLACE_REQUESTS,
        path: 'replace-requests',
      },
      {
        component: () => import('./evaluations/Evaluations'),
        name: Route.TAB_EVALUATION,
        path: 'evaluation',
      },
    ],
  },
  {
    component: () => import('./DialogShiftContainer'),
    path: 'shifts/new',
    props: (route: RouteType) => ({
      shiftplanId: parseInt(route.params.shiftplanId, 10),
    }),
    children: [
      {
        name: Route.DIALOG_NEW,
        component: () => import('./details/DetailsContainer'),
        path: '',
        props: propsInjectorDialogNew,
        meta: {
          hasTabsHidden: true,
          hasSaveButton: true,
        },
      },
    ],
  },
];

export default routes;
