import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import { authNS, StoreState } from 'components/auth/store/Store';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'src/utils/url';

@Component
export default class LinkWithCompanyPrefix extends TsxComponent<{
  activeClass?: string;
  href?: string;
  route?: string;
}> {
  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @Prop()
  public activeClass?: string;

  @Prop()
  public href: string;

  @Prop()
  public route: string;

  protected get url() {
    if (this.href) {
      return getUrlWithApiPrefix(
        getUrlWithCompanyPrefix(this.href, this.currentCompany),
      );
    }

    return getUrlWithCompanyPrefix(
      this.$router.resolve({
        name: this.route,
      }).href,
      this.currentCompany,
    );
  }

  public render() {
    return this.href ? (
      <a href={this.url}>{this.$slots.default}</a>
    ) : (
      <router-link activeClass={this.activeClass} to={this.url}>
        {this.$slots.default}
      </router-link>
    );
  }
}
