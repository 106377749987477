import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import moment from 'moment';
import RootStoreState from 'src/store/RootStoreState';
import { FiltersDictionary } from 'components/calendar-common/filters/Store';
import { DateKey } from 'components/calendar-common/common/DateItem';
import Shift from '../Shift';
import StaffShift from '../StaffShift';
import {
  filterByBaseFilters,
  filterByLocationsPositions,
  filterByShiftPresets,
  filterByShiftRotationGroups,
  filterByTags,
  filterShowOnlyMyShifts,
  getByDates,
  shiftsByEmployments,
  shiftsByPositions,
} from './util';
import { CalendarCacheKey, CalendarNamespace } from '../../Enums';

export const shiftsNS = namespace('calendar/shifts');
export const shiftsEmployeeViewNS = namespace('calendar/shifts/employeeView');
export const shiftsPositionsMonthViewNS = namespace(
  'calendar/shifts/positionsMonthView',
);

export const store = (): Module<{}, RootStoreState> => ({
  namespaced: true,
  getters: {
    shiftsIds(state, getters) {
      return getters.shifts.map((it) => it.id);
    },
    shiftsEmployments(state, getters) {
      return getters.shifts.reduce(
        (
          acc: Record<number, Set<number>>,
          { position: { locationsPositionId }, staffShifts },
        ) => {
          (staffShifts || []).forEach((staffShift) => {
            if (staffShift) {
              if (acc[staffShift.id]) {
                acc[staffShift.id].add(locationsPositionId);
              } else {
                acc[staffShift.id] = new Set([locationsPositionId]);
              }
            }
          });
          return acc;
        },
        {},
      );
    },
    shift(state, getters, rootState, rootGetters) {
      return (shiftData): Shift => {
        const allTags = rootGetters['tags/items'];
        const isShiftPresetsEnabled =
          rootState.auth.currentCompany?.canUseShiftPresets || false;
        const {
          locationsPosition,
          staffShifts,
          shiftplan,
          shiftAssignmentGroups,
          tags,
        } = shiftData;
        const tagIds = (tags || []).map((it) => it.id);
        const visibleStaffShifts = (staffShifts || []).filter(
          (staffShift) => staffShift.employment !== null,
        );

        return new Shift(
          {
            ...shiftData,
            isMyShift: visibleStaffShifts.some(
              // as we are doing a local patch of staff shift in shift data, there are case when the employment data is not present hence adding a optional chaining
              (staffShift) =>
                staffShift?.employment?.userId === rootState.auth.currentUserId,
            ),
            staffShifts: visibleStaffShifts.map(
              (staffShift) =>
                new StaffShift({
                  ...staffShift.employment,
                  staffShiftId: staffShift.id,
                  assignmentGroupId: shiftData.shiftAssignmentGroups?.find(
                    (item) => item.id === staffShift.shiftAssignmentGroupId,
                  )?.assignmentGroupId,
                  connectedGroupId: shiftData.connectedGroupId,
                }),
            ),
            tags: allTags.filter((it) => tagIds.includes(it.id)),
            position: {
              ...locationsPosition.position,
              locationId: locationsPosition.location.id,
              locationsPositionId: locationsPosition.id,
            },
            color: locationsPosition.position.color,
            shiftplanId: shiftplan.id,
            shiftAssignmentGroups: shiftAssignmentGroups || [],
          },
          isShiftPresetsEnabled,
        );
      };
    },
    shifts(state, getters, rootState, rootGetters): Shift[] {
      return rootGetters['shifts/getByCacheIdentifier'](
        CalendarCacheKey.SHIFTS_CACHE_KEY,
      )
        .filter(
          (o) => o.shiftplan.id === rootState.calendar.common.shiftplan?.id,
        )
        .map((it) => getters.shift(it))
        .sort((shift1: Shift, shift2: Shift) => {
          const shift1StartsAt = moment(shift1.startsAt);
          const shift2StartsAt = moment(shift2.startsAt);
          if (shift1StartsAt.isAfter(shift2StartsAt, 'minute')) {
            return 1;
          }
          if (shift1StartsAt.isBefore(shift2StartsAt, 'minute')) {
            return -1;
          }
          // shifts without shift presets go to the back
          if (shift1.shiftPreset && !shift2.shiftPreset) {
            return -1;
          }

          if (!shift1.shiftPreset && shift2.shiftPreset) {
            return 1;
          }

          // compare shift presets name if both shifts have shift preset
          if (shift1.shiftPreset && shift2.shiftPreset) {
            if (shift1.shiftPreset.id !== shift2.shiftPreset.id) {
              return shift1.shiftPreset.name.localeCompare(
                shift2.shiftPreset.name,
              );
            }
          }

          return shift1.id - shift2.id;
        });
    },
    // all filters except employments filter
    filteredShifts(state, getters, rootState, rootGetters) {
      if (!rootState.auth.currentCompany) {
        return [];
      }

      const filters: FiltersDictionary =
        rootGetters['calendar/filters/filters'];
      const {
        shiftRotationEnabled: isShiftRotationsEnabled,
        isTagsAllowed: isShiftTagsEnabled,
        canUseShiftPresets: isShiftPresetsEnabled,
      } = rootState.auth.currentCompany;
      const locationsPositionIds =
        rootGetters['calendar/filters/locationsPositionIds'];
      const shiftRotationGroupIds =
        rootGetters['calendar/filters/shiftRotationGroupIds'];
      const { selection: tagIds } = rootState.shiftSchedule.tagsFilter;
      const { selection: shiftPresetIds } =
        rootState.shiftSchedule.shiftPresetsFilter;

      const isLocationsPositionsFilterDisabled =
        !Array.isArray(locationsPositionIds) ||
        locationsPositionIds.length === 0;

      const isShiftPresetsFilterDisabled =
        !isShiftPresetsEnabled ||
        !Array.isArray(shiftPresetIds) ||
        shiftPresetIds.length === 0;

      const isShiftRotationGroupsFilterDisabled =
        !isShiftRotationsEnabled || shiftRotationGroupIds === null;

      const baseFilterFn = filterByBaseFilters(filters);
      const showOnlyMyShiftsFilterFn = filterShowOnlyMyShifts(
        filters.showOnlyMineShifts,
        filters.showShiftsWithoutConflicts,
      );
      const shiftRotationGroupFilterFn = filterByShiftRotationGroups(
        shiftRotationGroupIds,
        isShiftRotationGroupsFilterDisabled,
      );
      const locationsPositionsFilterFn = filterByLocationsPositions(
        locationsPositionIds,
        isLocationsPositionsFilterDisabled,
      );
      const tagsFilterFn = filterByTags(
        tagIds,
        !isShiftTagsEnabled,
        filters.showShiftsWithoutTags,
      );
      const shiftPresetsFilterFn = filterByShiftPresets(
        shiftPresetIds,
        isShiftPresetsFilterDisabled,
      );

      return getters.shifts.filter(
        (shift) =>
          showOnlyMyShiftsFilterFn(shift) &&
          baseFilterFn(shift) &&
          shiftRotationGroupFilterFn(shift) &&
          locationsPositionsFilterFn(shift) &&
          tagsFilterFn(shift) &&
          shiftPresetsFilterFn(shift),
      );
    },
    shiftsByDates(state, getters, rootState, rootGetters) {
      return Object.freeze(
        getByDates(
          rootGetters['calendar/common/dateKeys'],
          getters.filteredShifts,
        ),
      );
    },
    shiftsByPositions(state, getters, rootState, rootGetters) {
      return Object.freeze(
        shiftsByPositions(
          rootGetters['calendar/positions/filteredPositions'],
          getters.shiftsByDates,
        ),
      );
    },
    shiftsByEmployments(state, getters, rootState, rootGetters) {
      return Object.freeze(
        shiftsByEmployments(
          rootGetters['calendar/employments/filteredEmployments'],
          getters.shiftsByDates,
        ),
      );
    },
    openShifts(state, getters, rootState, rootGetters) {
      // if it is "filtered" by shiftRotationGroup, open shifts should only be shown,
      // if they belong to a filtered group
      // -> other shifts would still be shown, if the employment the shift is assigned to
      // has other shifts that belong to a filtered group.
      const shiftRotationGroupFilter = (shift: Shift) => {
        const shiftRotationGroupIds =
          rootGetters['calendar/filters/shiftRotationGroupIds'];
        const isFilterInactive = shiftRotationGroupIds === null;
        const isShiftBelongingToFilteredGroup =
          shift.shiftRotationGroupIds.some((id) =>
            shiftRotationGroupIds?.includes(id),
          );

        return isFilterInactive || isShiftBelongingToFilteredGroup;
      };

      // adding open shifts
      return getByDates(
        rootGetters['calendar/common/dateKeys'],
        getters.filteredShifts
          .filter((it) => it.isOpen)
          .filter(shiftRotationGroupFilter),
      );
    },
    shiftsByPositionsEmployments(state, getters, rootState, rootGetters) {
      return Object.entries(
        getters.shiftsByPositions as Record<number, Record<DateKey, Shift[]>>,
      ).reduce((acc, [positionId, items]) => {
        acc[positionId] = shiftsByEmployments(
          rootGetters['calendar/employments/employmentsByPositions'][
            positionId
          ] || [],
          items,
        );
        return acc;
      }, {});
    },
    shiftById(state, getters) {
      return (id) => getters.filteredShifts.find((it) => it.id === id);
    },
  },
  modules: {
    employeeView: {
      namespaced: true,
      getters: {
        // FIXME: getters here looks like duplicate of getters above, need to check and remove the duplicate ones
        filteredShifts(state, getters, rootState, rootGetters) {
          if (!rootState.auth.currentCompany) {
            return [];
          }

          const filters: FiltersDictionary =
            rootGetters['calendar/filters/filters'];
          const {
            shiftRotationEnabled: isShiftRotationsEnabled,
            isTagsAllowed: isShiftTagsEnabled,
            canUseShiftPresets: isShiftPresetsEnabled,
          } = rootState.auth.currentCompany;
          const locationsPositionIds =
            rootGetters[
              `${CalendarNamespace.CALENDAR}/filters/locationsPositionIds`
            ];
          const shiftRotationGroupIds =
            rootGetters[
              `${CalendarNamespace.CALENDAR}/filters/shiftRotationGroupIds`
            ];
          const { selection: tagIds } = rootState.shiftSchedule.tagsFilter;
          const { selection: shiftPresetIds } =
            rootState.shiftSchedule.shiftPresetsFilter;

          const isLocationsPositionsFilterDisabled =
            !Array.isArray(locationsPositionIds) ||
            locationsPositionIds.length === 0;

          const isShiftPresetsFilterDisabled =
            !isShiftPresetsEnabled ||
            !Array.isArray(shiftPresetIds) ||
            shiftPresetIds.length === 0;

          const isShiftRotationGroupsFilterDisabled =
            !isShiftRotationsEnabled || shiftRotationGroupIds === null;

          const filterByShiftRotationGroupsInEmploymentsView = (
            shift: Shift,
          ) => {
            const isOpenShiftInRotationGroup = () =>
              shift.isOpen &&
              shift.shiftRotationGroupIds.some((id) =>
                shiftRotationGroupIds?.includes(id),
              );
            const isEmploymentInFilteredShiftRotationGroups = () =>
              rootGetters['calendar/employments/filteredEmployments']
                .map(({ id }) => id)
                .some((id) => shift.employmentIds.includes(id));

            return (
              isShiftRotationGroupsFilterDisabled ||
              isOpenShiftInRotationGroup() ||
              isEmploymentInFilteredShiftRotationGroups()
            );
          };

          const showOnlyMyShiftsFilterFn = filterShowOnlyMyShifts(
            filters.showOnlyMineShifts,
            filters.showShiftsWithoutConflicts,
          );
          const baseFilterFn = filterByBaseFilters(filters);
          const locationsPositionsFilterFn = filterByLocationsPositions(
            locationsPositionIds,
            isLocationsPositionsFilterDisabled,
          );
          const tagsFilterFn = filterByTags(
            tagIds,
            !isShiftTagsEnabled,
            filters.showShiftsWithoutTags,
          );
          const shiftPresetsFilterFn = filterByShiftPresets(
            shiftPresetIds,
            isShiftPresetsFilterDisabled,
          );

          return rootGetters['calendar/shifts/shifts'].filter(
            (shift) =>
              showOnlyMyShiftsFilterFn(shift) &&
              baseFilterFn(shift) &&
              filterByShiftRotationGroupsInEmploymentsView(shift) &&
              locationsPositionsFilterFn(shift) &&
              tagsFilterFn(shift) &&
              shiftPresetsFilterFn(shift),
          );
        },
        shiftsByDates(state, getters, rootState, rootGetters) {
          return getByDates(
            rootGetters['calendar/common/dateKeys'],
            getters.filteredShifts,
          );
        },
        shiftsByEmployments(state, getters, rootState, rootGetters) {
          return shiftsByEmployments(
            rootGetters['calendar/employments/filteredEmployments'],
            getters.shiftsByDates,
          );
        },
        shiftById(state, getters) {
          return (id) => getters.filteredShifts.find((it) => it.id === id);
        },
        openShifts(state, getters, rootState, rootGetters) {
          return getByDates(
            rootGetters['calendar/common/dateKeys'],
            getters.filteredShifts.filter((it) => it.isOpen),
          );
        },
      },
    },
    positionsMonthView: {
      namespaced: true,
      getters: {
        shiftsByPositionsEmployments(state, getters, rootState, rootGetters) {
          return Object.entries(
            rootGetters['calendar/shifts/shiftsByPositions'] as Record<
              number,
              Record<string, Shift[]>
            >,
          ).reduce((acc, [positionId, items]) => {
            acc[positionId] = shiftsByEmployments(
              rootGetters[
                'calendar/employments/positionsMonthView/employmentsByPositions'
              ][positionId] || [],
              items,
            );
            return acc;
          }, {});
        },
      },
    },
  },
});
