import { formatCurrency } from 'src/utils/utils';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import popoverContentStyles from 'components/popover/popover-content.css';
import { ShiftAvailableEmployment } from 'src/store/shift-available-employments/Store';
import { EmploymentInfo } from 'src/store/shift-employment-filter/Store';
import HourBalancePopoverContent from 'components/dialog-shift/employees/manager-view/hour-balance/PopoverContent';
import styles from './balance-popover-content.css';

interface Props {
  employmentInfo: EmploymentInfo | ShiftAvailableEmployment;
  currency: string;
  isMoneyShown: boolean;
  isTimeframeMonthly: boolean;
}

@Component
export default class BalancePopoverContent extends TsxComponent<Props> {
  @Prop()
  public employmentInfo: Props['employmentInfo'];

  @Prop()
  public currency: Props['currency'];

  @Prop()
  public isMoneyShown: Props['isMoneyShown'];

  @Prop()
  public isTimeframeMonthly: Props[];

  protected get scheduledPayment() {
    return formatCurrency(
      this.$i18n.i18next.language,
      this.employmentInfo.scheduledMoney || 0,
      this.currency,
    );
  }

  protected get maxPayment() {
    if (!this.employmentInfo.employment.maximumMoneyEnabled) {
      return null;
    }

    const currency = formatCurrency(
      this.$i18n.i18next.language,
      this.employmentInfo.employment.maximumMoney || 0,
      this.currency,
    );

    return `${this.isTildeShown ? '~' : ''}${currency}`;
  }

  private get isTildeShown() {
    return !this.isTimeframeMonthly && this.isEmploymentWorkHourMonthly;
  }

  protected get isEmploymentWorkHourMonthly(): boolean {
    return !!this.employmentInfo.employment.employmentWorkHour?.isMonthly;
  }

  public render() {
    return (
      <div class={styles.balancePopoverContent}>
        <HourBalancePopoverContent employmentInfo={this.employmentInfo} />

        {this.isMoneyShown && (
          <div class={popoverContentStyles.popoverContent}>
            {
              <dl class={popoverContentStyles.popoverContentList}>
                {this.scheduledPayment && [
                  <dt class={popoverContentStyles.popoverContentItemHeader}>
                    {this.$t(
                      'shifts.employees.paymentBalance.labelScheduledPayment',
                    )}
                  </dt>,
                  <dd class={popoverContentStyles.popoverContentItem}>
                    {this.scheduledPayment}
                  </dd>,
                ]}

                {this.maxPayment && [
                  <dt class={popoverContentStyles.popoverContentItemHeader}>
                    {this.$t(
                      'shifts.employees.paymentBalance.labelMaximumPayment',
                    )}
                  </dt>,
                  <dd class={popoverContentStyles.popoverContentItem}>
                    {this.maxPayment}
                  </dd>,
                ]}
              </dl>
            }
          </div>
        )}
      </div>
    );
  }
}
