import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import InputText from 'components/form/input-text/InputText';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import FormDialog from 'components/form/form-dialog/FormDialog';
import { createEventPayload, EventPayload } from 'src/utils/events';
import styles from './details.css';
import { FORM_ID } from '../DialogAssignmentGroup';
import type { FormState } from '../DialogAssignmentGroup';

interface Props {
  formState: FormState;
  isSubmitting?: boolean;
  hasError?: boolean;
  isLoading?: boolean;
  isUpdate: boolean;
}

interface Events {
  onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  onInput: <T extends keyof FormState>(
    payload: EventPayload<{ field: T; value: FormState[T] }>,
  ) => void;
}

@Component
export default class Details extends TsxComponent<Props, Events> {
  @Prop()
  public formState: FormState;

  @Prop()
  public isSubmitting?: boolean;

  @Prop()
  public hasError?: boolean;

  @Prop()
  public isLoading?: boolean;

  @Prop()
  public isUpdate: boolean;

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit(
        'closeClick',
        createEventPayload<void, HTMLElement, UIEvent>(e, undefined),
      );
    }
  }

  public render() {
    return (
      <div class={styles.details}>
        <FormDialog
          id={FORM_ID}
          onSubmit={(e) =>
            this.$emit('submit', createEventPayload<void>(e, undefined))
          }
        >
          <InputText
            label={this.$t('assignmentGroups.modal.labelName')}
            name="name"
            onInput={(e) =>
              this.$emit(
                'input',
                createEventPayload(e, {
                  field: e.target.name,
                  value: e.target.value,
                }),
              )
            }
            required={true}
            type="text"
            value={this.formState.name}
          />
        </FormDialog>
      </div>
    );
  }
}
