import { GQLTagContext } from 'codegen/gql-types';
import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import Spinner from 'components/spinner/Spinner';
import {
  employmentEvaluationsNS,
  GQLEmploymentEvaluation,
} from 'store/employment-evaluations/Store';
import type { FetchEmploymentEvaluationFunction } from 'store/employment-evaluations/Store';
import { evaluationsNS } from 'store/evaluations/Store';
import type { FetchEvaluationFunction } from 'store/evaluations/Store';
import { Action as NormalizedStoreAction } from 'store/normalized-store';
import EmploymentEvaluationAction from 'store/employment-evaluations/Action';
import { paygradeTypesNS } from 'store/paygrade-types/Store';
import type { FetchAllPaygradeTypesFunction } from 'store/paygrade-types/Store';
import { shiftsNS } from 'store/shifts/Store';
import type { FetchShiftFunction, Shift } from 'store/shifts/Store';
import { tagsNS } from 'store/tags/Store';
import type { FetchAllTagsFunction, Tag } from 'store/tags/Store';
import {
  executeStoreActionWithFailureSnackbar,
  StoreActionState,
} from 'utils/store';
import type { GetById } from 'utils/store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import FormContainerCompany from './form/FormContainerCompany';
import FormContainerEmployment from './form/FormContainerEmployment';
import type { Evaluation } from './types';

import styles from './evaluation-container.css';

interface Props {
  isDisabled?: boolean;
  staffShiftId: number;
}

interface Events {
  onRefetchShift: (payload: {
    useBackgroundFetch: boolean;
    withConnected: boolean;
  }) => void;
}

@Component
export default class EvaluationContainer extends TsxComponent<Props, Events> {
  protected isLoading = true;

  @Prop()
  public isDisabled?: Props['staffShiftId'];

  @Prop()
  public staffShiftId: Props['staffShiftId'];

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmploymentId: AuthStoreState['currentEmploymentId'];

  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.Getter
  protected isStakeholder: boolean;

  @evaluationsNS.Action(NormalizedStoreAction.FETCH)
  protected fetchEvaluation: FetchEvaluationFunction;

  @employmentEvaluationsNS.Action(EmploymentEvaluationAction.FETCH_EVALUATION)
  protected fetchEmploymentEvaluation: FetchEmploymentEvaluationFunction;

  @evaluationsNS.Getter('getByStaffShiftId')
  protected getEvaluationByStaffShiftId: GetById<Evaluation>;

  @evaluationsNS.Getter
  protected hasEvaluationPayShowRight: (evaluation: Evaluation) => boolean;

  @evaluationsNS.Getter
  protected hasEvaluationPayManageRight: (evaluation: Evaluation) => boolean;

  @employmentEvaluationsNS.Getter('getByStaffShiftId')
  protected getEmploymentEvaluationByStaffShiftId: GetById<GQLEmploymentEvaluation>;

  @paygradeTypesNS.Action(NormalizedStoreAction.FETCH_ALL)
  protected fetchPaygradeTypesAll: FetchAllPaygradeTypesFunction;

  @shiftsNS.Action(NormalizedStoreAction.FETCH)
  protected fetchShift: FetchShiftFunction;

  @shiftsNS.Getter
  protected canManageShift: (
    locationId: number,
    locationsPositionId: number,
  ) => boolean;

  @shiftsNS.Getter
  protected canViewShift: (
    locationId: number,
    locationsPositionId: number,
  ) => boolean;

  @shiftsNS.Getter('getById')
  protected getShiftById: GetById<Shift>;

  @tagsNS.Action(NormalizedStoreAction.FETCH_ALL)
  protected fetchTagsAll: FetchAllTagsFunction;

  @tagsNS.Getter('items')
  protected tags: Tag[];

  protected get evaluation(): Evaluation | undefined {
    const evaluation = this.getEvaluationByStaffShiftId(this.staffShiftId);

    if (!evaluation) {
      return undefined;
    }

    return {
      ...evaluation,
      note: evaluation.note || '',
      totalPayment: evaluation.totalPayment || 0,
    };
  }

  protected get employmentEvaluation(): Evaluation | undefined {
    const employmentEvaluation = this.getEmploymentEvaluationByStaffShiftId(
      this.staffShiftId,
    );

    if (!this.evaluation || (!employmentEvaluation && this.showManagerView)) {
      return undefined;
    }

    return {
      ...this.evaluation,
      ...employmentEvaluation,
      note: employmentEvaluation?.note || '',
    };
  }

  protected get isEmploymentEvaluationPresent() {
    return !!this.getEmploymentEvaluationByStaffShiftId(this.staffShiftId);
  }

  protected get canManageAdminEvaluation() {
    return this.canManageShift(
      this.evaluation?.location?.id || Number.NaN,
      this.evaluation?.locationsPosition.id || Number.NaN,
    );
  }

  protected get canManageEmploymentEvaluation() {
    return this.isSelfEvaluation;
  }

  protected get showManagerView() {
    if (!this.isStakeholder) {
      return false;
    }

    if (this.isSelfEvaluation) {
      if (this.isManagerAbletoManageOwnShifts && this.canManageEvalutaions) {
        return true;
      }
      return false;
    }

    if (this.canManageEvalutaions) {
      return true;
    }

    return false;
  }

  protected get isManagerAbletoManageOwnShifts() {
    return (
      this.isStakeholder && !!this.currentCompany?.canManagersEvaluateOwnShifts
    );
  }

  protected get canManageEvalutaions() {
    if (this.isSuperAdmin) {
      return true;
    }

    return (
      this.canManageShift(
        this.evaluation?.location?.id || Number.NaN,
        this.evaluation?.locationsPosition.id || Number.NaN,
      ) ||
      this.canViewShift(
        this.evaluation?.location?.id || Number.NaN,
        this.evaluation?.locationsPosition.id || Number.NaN,
      )
    );
  }

  protected get isSelfEvaluation() {
    return this.evaluation?.employment.id === this.currentEmploymentId;
  }

  protected get shift() {
    return this.getShiftById(this.evaluation?.shift?.id || Number.NaN);
  }

  protected get staffShiftsTags() {
    return this.tags.filter(
      (tag) => tag.context === GQLTagContext.STAFF_SHIFT_TAG,
    );
  }

  protected get isSectionPayShown() {
    return (
      !!this.evaluation?.payments.length &&
      (this.hasEvaluationPayShowRight(this.evaluation) ||
        this.hasEvaluationPayManageRight(this.evaluation) ||
        this.isSelfEvaluation)
    );
  }

  protected async populate() {
    this.isLoading = true;

    const result = await executeStoreActionWithFailureSnackbar(
      this,
      { staffShiftId: this.staffShiftId },
      this.fetchEvaluation,
      '',
    );

    if (result.state !== StoreActionState.SUCCESS) {
      this.isLoading = false;
      return;
    }

    await this.fetchEmploymentEvaluation({ staffShiftId: this.staffShiftId });

    if (this.evaluation) {
      await this.fetchShift({ id: this.evaluation.shift?.id || NaN });
      await this.fetchPaygradeTypesAll({});
      await this.fetchTagsAll({
        context: [GQLTagContext.SHIFT_TAG, GQLTagContext.STAFF_SHIFT_TAG],
        locationIds: null,
      });
    }

    this.isLoading = false;
  }

  protected onSubmit() {
    this.populate();
    this.$emit('refetchShift', {
      useBackgroundFetch: true,
      withConnected: false,
    });
  }

  public mounted() {
    this.populate();
  }

  public render() {
    if (this.isLoading) {
      return <Spinner class={styles.evaluationContainerSpinner} />;
    }

    if (!this.evaluation || (!this.showManagerView && !this.isSelfEvaluation)) {
      return null;
    }

    return (
      <div class={styles.evaluationContainer}>
        {this.showManagerView
          ? [
              <FormContainerCompany
                evaluation={this.evaluation}
                isBringShiftsEnabled={!!this.currentCompany?.canUseBringShifts}
                isDisabled={!this.canManageAdminEvaluation}
                onSubmit={this.onSubmit}
                staffShiftsTags={this.staffShiftsTags}
              />,
              this.employmentEvaluation && (
                <FormContainerEmployment
                  evaluation={this.employmentEvaluation}
                  isBringShiftsEnabled={
                    !!this.currentCompany?.canUseBringShifts
                  }
                  isDisabled={true}
                  isManageable={true}
                  isEmploymentEvaluationPresent={
                    this.isEmploymentEvaluationPresent
                  }
                  onSubmit={this.onSubmit}
                  staffShiftsTags={this.staffShiftsTags}
                  isSectionPayShown={this.isSectionPayShown}
                />
              ),
            ]
          : [
              this.shift?.canEvaluate && this.employmentEvaluation && (
                <FormContainerEmployment
                  evaluation={this.employmentEvaluation}
                  isBringShiftsEnabled={
                    !!this.currentCompany?.canUseBringShifts
                  }
                  isDisabled={!this.canManageEmploymentEvaluation}
                  isManageable={false}
                  isEmploymentEvaluationPresent={
                    this.isEmploymentEvaluationPresent
                  }
                  onSubmit={this.onSubmit}
                  staffShiftsTags={this.staffShiftsTags}
                  isSectionPayShown={this.isSectionPayShown}
                />
              ),
              <FormContainerCompany
                evaluation={this.evaluation}
                isBringShiftsEnabled={!!this.currentCompany?.canUseBringShifts}
                isDisabled={true}
                onSubmit={this.onSubmit}
                staffShiftsTags={this.staffShiftsTags}
              />,
            ]}
      </div>
    );
  }
}
