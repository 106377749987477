/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

interface Props {
  insideRef?: HTMLElement | (() => HTMLElement);
}

interface Events {
  onOutsideClick: (
    e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>,
  ) => void;
}

@Component
class OutsideClickHandler extends TSXComponent<Props, Events> {
  protected targetMouseDown: HTMLElement;

  @Prop()
  public insideRef: Props['insideRef'];

  @Emit('outsideClick')
  public onOutsideClick(e: KeyboardEvent | MouseEvent) {}

  protected onWindowMouseDown(e: MouseEvent) {
    this.targetMouseDown = e.target as HTMLElement;
  }

  protected onWindowMouseUp(e: MouseEvent) {
    const target = e.target as HTMLElement;

    if (target !== this.targetMouseDown) {
      return;
    }

    const insideElement =
      typeof this.insideRef === 'function' ? this.insideRef() : this.insideRef;

    if (!insideElement?.contains(target)) {
      this.onOutsideClick(e);
    }
  }

  protected onWindowEscape(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.onOutsideClick(e);
    }
  }

  public mounted() {
    window.addEventListener('mousedown', this.onWindowMouseDown);
    window.addEventListener('mouseup', this.onWindowMouseUp);
    window.addEventListener('keydown', this.onWindowEscape);
  }

  public beforeDestroy() {
    window.removeEventListener('mousedown', this.onWindowMouseDown);
    window.removeEventListener('mouseup', this.onWindowMouseUp);
    window.removeEventListener('keydown', this.onWindowEscape);
  }

  public render() {
    return null;
  }
}

export default OutsideClickHandler;
