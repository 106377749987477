import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateTagMutation,
  GQLCreateTagMutationVariables,
  GQLDeleteTagMutation,
  GQLDeleteTagMutationVariables,
  GQLFetchTagQuery,
  GQLFetchTagQueryVariables,
  GQLUpdateTagMutation,
  GQLUpdateTagMutationVariables,
  GQLTagCreateInput,
  GQLTagUpdateInput,
  GQLTagFragmentFragment,
  GQLTagsQueryVariables,
  GQLTagsQuery,
  GQLTagContext,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateTagGql from './queries/CreateTag.gql';
import DeleteTagGql from './queries/DeleteTag.gql';
import FetchTagGql from './queries/FetchTag.gql';
import TagsGql from './queries/Tags.gql';
import UpdateTagGql from './queries/UpdateTag.gql';

export const tagsNS = namespace('tags');

export type StoreState = ById<Tag>;

export type Tag = GQLTagFragmentFragment;

export type CreateTagFunction = (payload: {
  tag: Omit<GQLTagCreateInput, 'companyId'>;
}) => Promise<StoreActionResult>;

export type DeleteTagFunction = (
  payload: Omit<GQLDeleteTagMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchTagFunction = (
  payload: Omit<GQLFetchTagQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAllTagsFunction = (
  payload: Omit<GQLTagsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type UpdateTagFunction = (payload: {
  id: number;
  tag: Omit<GQLTagUpdateInput, 'companyId'>;
}) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getTagsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByLocationId:
        (state: StoreState) => (locationId: number, context?: GQLTagContext) =>
          // FAQ: implicitly uses sorting by ID which is also what the API returns
          Object.values(state.byId).filter(
            (item) =>
              item.location?.id === locationId &&
              (context ? item.context === context : true),
          ),
    },
  };

  const create: ActionProvider<
    GQLCreateTagMutation,
    GQLCreateTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateTagGql,
      resultKey: 'createTag',
      variables: {
        tag: {
          ...payload.tag,
          companyId: rootState.auth.currentCompanyId,
        },
      },
    };
  };

  const remove: ActionProvider<
    GQLDeleteTagMutation,
    GQLDeleteTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteTagGql,
      resultKey: 'deleteTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetch: ActionProvider<GQLFetchTagQuery, GQLFetchTagQueryVariables> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: FetchTagGql,
      resultKey: 'tags',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetchAll: ActionProvider<GQLTagsQuery, GQLTagsQueryVariables, Tag> = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllTagsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: TagsGql,
      resultKey: 'tags',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const update: ActionProvider<
    GQLUpdateTagMutation,
    GQLUpdateTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: UpdateTagGql,
      resultKey: 'updateTag',
      variables: {
        id: payload.id,
        tag: {
          ...payload.tag,
          companyId: rootState.auth.currentCompanyId,
        },
      },
    };
  };

  return createNormalizedStore<Tag, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.FETCH]: fetch,
      [Action.FETCH_ALL]: fetchAll,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getTagsStore;
