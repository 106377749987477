import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { store as getAbsencesStore } from 'components/calendar-common/absences/Store';
import {
  absencesByEmployments,
  absencesByPositions,
  FILTER_CHECKS,
  getByDates,
  getByDatesUnsorted,
} from 'components/calendar-common/absences/util';
import Absence from 'components/calendar-common/absences/Absence';
import { GQLAbsenceState } from 'codegen/gql-types';
import groupBy from 'lodash/groupBy';

export interface AbsenceGroupDayItem {
  [GQLAbsenceState.NEW]?: Record<string, Absence[]>;
  [GQLAbsenceState.ACCEPTED]?: Record<string, Absence[]>;
}

export const calendarAbsencesNS = namespace('calendar/absences');
export const absencesPositionsMonthViewNS = namespace(
  'calendar/absences/positionsMonthView',
);

export const store = (): Module<{}, RootStoreState> => {
  const baseStore = getAbsencesStore();
  return {
    ...baseStore,
    getters: {
      ...baseStore.getters,
      filteredAbsences(state, getters, rootState, rootGetters) {
        return Object.freeze(
          getters.absences.filter(
            (it) =>
              FILTER_CHECKS.some(({ key, check }) =>
                rootGetters['calendar/filters/filters'][key]
                  ? check(it)
                  : false,
              ) &&
              rootGetters['calendar/employments/filteredEmployments'].find(
                (emp) => emp.id === it.employment.id,
              ),
          ),
        );
      },
      absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
        return Object.freeze(
          getByDatesUnsorted(
            rootGetters['calendar/common/dateKeys'],
            getters.filteredAbsences,
          ),
        );
      },

      absencesByDates(state, getters) {
        return Object.freeze(getByDates(getters.absencesByDatesUnsorted));
      },

      absencesByDatesByStateByReason(state, getters) {
        return Object.entries(
          getters.absencesByDates as Record<string, Record<string, Absence[]>>,
        ).reduce(
          (
            acc: Record<string, AbsenceGroupDayItem>,
            [dateKey, absencesByState],
          ) => {
            if (!acc[dateKey]) {
              acc[dateKey] = {};
            }

            Object.entries(absencesByState).forEach(([stateKey, absences]) => {
              if (!acc[dateKey][stateKey]) {
                acc[dateKey][stateKey] = {};
              }

              acc[dateKey][stateKey] = groupBy(absences, 'reason.id');
            });

            return acc;
          },
          {} as Record<string, AbsenceGroupDayItem>,
        );
      },

      absencesByEmployments(state, getters, rootState, rootGetters) {
        return Object.freeze(
          absencesByEmployments(
            rootGetters['calendar/employments/filteredEmployments'],
            getters.absencesByDatesUnsorted,
          ),
        );
      },

      absencesByPositions(state, getters, rootState, rootGetters) {
        return Object.freeze(
          absencesByPositions(
            rootGetters['calendar/positions/filteredPositions'],
            rootGetters['calendar/employments/employments'],
            getters.absencesByDatesUnsorted,
          ),
        );
      },

      /*
        we need to only have accepted absences
        for every day for summary, independently from current state filters
      */
      filteredAcceptedAbsencesByDates(
        state,
        getters,
        rootState,
        rootGetters,
      ): Record<string, Absence[]> {
        const filteredAbsences = getters.absences.filter(
          (it) =>
            it.state === GQLAbsenceState.ACCEPTED &&
            rootGetters['calendar/employments/filteredEmployments'].find(
              (emp) => emp.id === it.employment.id,
            ),
        );
        return Object.freeze(
          getByDatesUnsorted(
            rootGetters['calendar/common/dateKeys'],
            filteredAbsences,
          ),
        );
      },
    },
    modules: {
      positionsMonthView: {
        namespaced: true,
        getters: {
          filteredAbsences(state, getters, rootState, rootGetters) {
            return Object.freeze(
              rootGetters['calendar/absences/absences'].filter(
                (it) =>
                  FILTER_CHECKS.some(({ key, check }) =>
                    rootGetters['calendar/filters/filters'][key]
                      ? check(it)
                      : false,
                  ) &&
                  rootGetters[
                    'calendar/employments/positionsMonthView/filteredEmployments'
                  ].find((emp) => emp.id === it.employment.id),
              ),
            );
          },

          absencesByDatesUnsorted(state, getters, rootState, rootGetters) {
            return Object.freeze(
              getByDatesUnsorted(
                rootGetters['calendar/common/dateKeys'],
                getters.filteredAbsences,
              ),
            );
          },

          absencesByEmployments(state, getters, rootState, rootGetters) {
            return Object.freeze(
              absencesByEmployments(
                rootGetters[
                  'calendar/employments/positionsMonthView/filteredEmployments'
                ],
                getters.absencesByDatesUnsorted,
              ),
            );
          },
        },
      },
    },
  };
};
