import BalancePopoverContent from 'components/balance-popover-content/BalancePopoverContent';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { EmploymentTooltip } from '../Store';

interface Props {
  tooltipPayload: EmploymentTooltip;
}

@Component
export class TooltipEmploymentContent extends TsxComponent<Props, {}> {
  @Prop()
  protected tooltipPayload: Props['tooltipPayload'];

  public render() {
    return this.tooltipPayload?.employmentInfo ? (
      <BalancePopoverContent
        employmentInfo={this.tooltipPayload?.employmentInfo}
        currency={this.tooltipPayload?.currency}
        isMoneyShown={this.tooltipPayload.isMoneyShown}
        isTimeframeMonthly={this.tooltipPayload.isTimeframeMonthly}
      />
    ) : (
      <div>{this.tooltipPayload?.name}</div>
    );
  }
}

export default TooltipEmploymentContent;
