import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateStaffShiftsTagMutation,
  GQLCreateStaffShiftsTagMutationVariables,
  GQLDeleteStaffShiftsTagMutation,
  GQLDeleteStaffShiftsTagMutationVariables,
  GQLStaffShiftsTagFragmentFragment,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateStaffShiftsTagGql from './queries/CreateStaffShiftsTag.gql';
import DeleteStaffShiftsTagGql from './queries/DeleteStaffShiftsTag.gql';

export const staffShiftsTagsNS = namespace('staffShiftsTags');

export type StoreState = ById<StaffShiftsTag>;

export type StaffShiftsTag = GQLStaffShiftsTagFragmentFragment;

export type CreateStaffShiftsTagFunction = (
  payload: Omit<GQLCreateStaffShiftsTagMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteStaffShiftsTagFunction = (
  payload: Omit<
    GQLDeleteStaffShiftsTagMutationVariables & { id: number },
    'companyId'
  >,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getStaffShiftsTagsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const create: ActionProvider<
    GQLCreateStaffShiftsTagMutation,
    GQLCreateStaffShiftsTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateStaffShiftsTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateStaffShiftsTagGql,
      resultKey: 'createStaffShiftsTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const remove: ActionProvider<
    GQLDeleteStaffShiftsTagMutation,
    GQLDeleteStaffShiftsTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteStaffShiftsTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteStaffShiftsTagGql,
      resultKey: 'deleteStaffShiftsTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  return createNormalizedStore<StaffShiftsTag, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
    },
    graphqlClient,
    logger,
  });
};

export default getStaffShiftsTagsStore;
