import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import BaseDialog, {
  Slot as BaseDialogSlot,
} from 'components/dialog/base-dialog/BaseDialog';
import { Size } from 'components/types';
import styles from './dialog.css';

export enum Slot {
  BUTTONS_LEFT = 'buttonsLeft',
  BUTTONS_RIGHT = 'buttonsRight',
}

export interface Props {
  id?: string;
  isOpen?: boolean;
  title?: string;
  isClosingPrevented: boolean;
  isDialogNotClosable?: boolean;
  isStretchable?: boolean;
}

interface Events {
  onCloseClick: SyntheticEvent<
    HTMLElement | HTMLButtonElement,
    KeyboardEvent | MouseEvent | UIEvent
  >;
}

@Component
export default class Dialog extends TsxComponent<Props, Events> {
  public $refs: {
    container: HTMLDivElement;
  };

  @Prop()
  public title?: string;

  @Prop({ default: () => `dialog-${getRandomString()}` })
  public id: NonNullable<Props['id']>;

  @Prop({ default: false })
  public isOpen: NonNullable<Props['isOpen']>;

  @Prop({ default: false })
  public isClosingPrevented: Props['isClosingPrevented'];

  @Prop({ default: false })
  public isDialogNotClosable: Props['isDialogNotClosable'];

  @Prop({ default: false })
  public isStretchable: NonNullable<Props['isStretchable']>;

  protected get classes(): string[] {
    const classList = [styles.dialog];
    if (this.isStretchable) {
      classList.push(styles.dialogStretchable);
    }

    return classList;
  }

  protected onCloseClick(e: SyntheticEvent) {
    this.$emit('closeClick', e);
  }

  public render() {
    return (
      <BaseDialog
        dialogClasses={this.classes}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        isClosingPrevented={this.isClosingPrevented}
        isDialogNotClosable={this.isDialogNotClosable}
        title={this.title}
        dialogSpacingSize={Size.SMALL}
        showTitleBodySeparator={true}
      >
        <div id={`${this.id}-body`} class={styles.dialogBody}>
          {this.$slots.default}
        </div>
        {(this.$slots[Slot.BUTTONS_LEFT]?.length ||
          this.$slots[Slot.BUTTONS_RIGHT]?.length) && (
          <footer class={styles.dialogFooter} slot={BaseDialogSlot.FOOTER}>
            {this.$slots[Slot.BUTTONS_LEFT]?.length && (
              <div class={styles.dialogFooterStart}>
                {this.$slots[Slot.BUTTONS_LEFT]}
              </div>
            )}

            <div class={styles.dialogFooterEnd}>
              {this.$slots[Slot.BUTTONS_RIGHT]}
            </div>
          </footer>
        )}
      </BaseDialog>
    );
  }
}
