import { store as absences } from 'components/calendar/absences/Store';
import {
  store as agenda,
  StoreState as AgendaStoreState,
} from 'components/calendar/agenda/Store';
import {
  store as common,
  StoreState as CommonStoreState,
} from 'components/calendar/common/Store';
import { store as employments } from 'components/calendar-common/employments/Store';
import {
  store as filters,
  StoreState as FiltersStoreState,
} from 'components/calendar-common/filters/Store';
import {
  store as highlights,
  StoreState as HighlightsStoreState,
} from 'components/calendar-common/highlights/Store';
import {
  store as notes,
  StoreState as NotesStoreState,
} from 'components/calendar-common/notes/Store';
import {
  store as positions,
  ModuleState as PositionsModuleState,
} from 'components/calendar-common/positions/Store';
import { store as shifts } from 'components/calendar-common/shifts/store/Store';
import {
  store as tags,
  StoreState as TagsStoreState,
} from 'components/calendar-common/tags/Store';
import {
  store as pagination,
  StoreState as PaginationStoreState,
} from 'components/calendar-common/common/pagination-mixin/Store';
import {
  store as selectionMode,
  StoreState as SelectionModeStoreState,
} from 'components/calendar-common/selection-mode/Store';
import {
  store as tooltip,
  StoreState as TooltipStoreState,
} from 'components/calendar-common/tooltip/Store';
import { CalendarNamespace } from 'components/calendar-common/Enums';

export interface ModuleState {
  notes: NotesStoreState;
  agenda: AgendaStoreState;
  highlights: HighlightsStoreState;
  filters: FiltersStoreState;
  positions: PositionsModuleState;
  common: CommonStoreState;
  tags: TagsStoreState;
  pagination: PaginationStoreState;
  selectionMode: SelectionModeStoreState;
  tooltip: TooltipStoreState;
}

const getCalendarStore = () => ({
  namespaced: true,
  modules: {
    agenda,
    highlights: highlights(CalendarNamespace.CALENDAR),
    shifts: shifts(),
    absences: absences(),
    filters: filters(CalendarNamespace.CALENDAR),
    positions: positions(CalendarNamespace.CALENDAR),
    employments: employments(CalendarNamespace.CALENDAR),
    selectionMode: selectionMode(CalendarNamespace.CALENDAR),
    common: common(),
    notes: notes(CalendarNamespace.CALENDAR),
    tags: tags(),
    pagination: pagination(),
    tooltip: tooltip(),
  },
});

export default getCalendarStore;
