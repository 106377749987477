import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import SnackbarAction from 'components/snackbar/store/Action';
import { snackbarNS } from 'components/snackbar/store/Store';
import type { ShowSnackbarFunction } from 'components/snackbar/store/Store';
import { SentryTag } from 'services/logger/SentryTransport';
import { locationsPositionsNS } from 'store/locations-positions/Store';
import type { CreateLocationsPositionFunction } from 'store/locations-positions/Store';
import { Action } from 'store/normalized-store';
import { positionsNS } from 'store/positions/Store';
import type { CreatePositionFunction } from 'store/positions/Store';
import { getRandomPastelColor } from 'utils/color';
import { EventPayload } from 'utils/events';
import { getFirstErrorFromResponse, StoreActionState } from 'utils/store';
import { filterFalsy } from 'utils/utils';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { AlertKind } from 'components/alert/Alert';
import { sortBySortOrder } from 'src/utils/sort';
import DialogPosition, { FormState } from './DialogPosition';

export const getInitialFormState = (): FormState => ({
  color: getRandomPastelColor(),
  description: '',
  locationIdsSet: new Set<number>(),
  name: '',
  note: '',
});

@Component
export default class DialogPositionContainer extends TsxComponent<
  {
    isOpen?: boolean;
  },
  {
    onCloseClick: (e: EventPayload<void, HTMLElement, UIEvent>) => void;
  }
> {
  protected formState = getInitialFormState();

  protected isSubmitting = false;

  @authNS.State
  protected currentLocationId: AuthStoreState['currentLocationId'];

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @snackbarNS.Action(SnackbarAction.SHOW)
  protected showSnackbar: ShowSnackbarFunction;

  @locationsPositionsNS.Action(Action.CREATE)
  protected createLocationsPosition: CreateLocationsPositionFunction;

  @positionsNS.Action(Action.CREATE)
  protected createPosition: CreatePositionFunction;

  @Prop()
  public isOpen?: boolean;

  @Watch('isOpen', { immediate: true })
  public async onIsOpenUpdate(isOpen?: boolean) {
    if (!isOpen) {
      this.formState = getInitialFormState();

      if (this.currentLocationId) {
        this.formState.locationIdsSet.add(this.currentLocationId);
      }

      this.isSubmitting = false;
    }
  }

  protected onCloseClick(payload: EventPayload<void, HTMLElement, UIEvent>) {
    this.$emit('closeClick', payload);
  }

  protected onInput({ payload: { field, value } }) {
    this.formState[field] = value;
  }

  protected async onSubmit({
    event,
  }: EventPayload<void, HTMLElement, UIEvent>) {
    event.preventDefault();

    const position = {
      color: this.formState.color,
      description: this.formState.description || null,
      name: this.formState.name,
      note: this.formState.note || null,
      sort: this.formState.sort
        ? Number.parseInt(this.formState.sort, 10)
        : null,
    };

    this.isSubmitting = true;

    const response = await this.createPosition({ position });

    if (
      response.state === StoreActionState.ERROR &&
      typeof response.error !== 'string'
    ) {
      this.isSubmitting = false;

      this.$logInfo({
        tags: [[SentryTag.COMPONENT, DialogPositionContainer.name]],
        message: JSON.stringify(response),
      });

      const error = getFirstErrorFromResponse(response.error);

      const message = error
        ? this.$t(`positions.modal.error.${error.key}`, { ...error.val })
        : this.$t('general.error.unknown');

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });

      return false;
    }

    if (response.state !== StoreActionState.SUCCESS || !response.entityId) {
      this.isSubmitting = false;

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message: this.$t('general.error.unknown'),
        timeout: 5000,
      });

      return false;
    }

    const { entityId: positionId } = response;

    const promises = [...this.formState.locationIdsSet].map((locationId) =>
      this.createLocationsPosition({
        locationsPosition: {
          locationId,
          positionId,
          sort: null,
        },
      }),
    );

    const compositePromise = await Promise.all(promises);

    this.isSubmitting = false;

    const firstNonSuccessfulResponse = compositePromise.find(
      (promise) => promise.state !== StoreActionState.SUCCESS,
    );

    if (firstNonSuccessfulResponse) {
      let message = this.$t('general.error.unknown');

      if (firstNonSuccessfulResponse.state === StoreActionState.ERROR) {
        const error =
          typeof firstNonSuccessfulResponse.error === 'string'
            ? firstNonSuccessfulResponse.error
            : getFirstErrorFromResponse(firstNonSuccessfulResponse.error);

        if (typeof error === 'string') {
          message = error;
        } else if (error !== undefined) {
          message = this.$t(`positions.modal.error.${error.key}`, {
            ...error.val,
          });
        }
      }

      this.showSnackbar({
        kind: AlertKind.ERROR,
        message,
        timeout: 5000,
      });

      return false;
    }

    this.onCloseClick({ event, payload: undefined });

    return this.showSnackbar({
      message: this.$t('positions.modal.created'),
      kind: AlertKind.SUCCESS,
      timeout: 5000,
    });
  }

  public render() {
    return (
      <DialogPosition
        formState={this.formState}
        isOpen={this.isOpen}
        isSubmitting={this.isSubmitting}
        locations={this.currentCompany?.locations
          ?.filter(filterFalsy)
          .sort(sortBySortOrder())}
        onCloseClick={this.onCloseClick}
        onInput={this.onInput}
        onSubmit={this.onSubmit}
      />
    );
  }
}
