/* eslint-disable camelcase */
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RestClient from 'services/rest-client/RestClient';
import RootStoreState from 'src/store/RootStoreState';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult, StoreActionState } from 'src/utils/store';
import { GQLBackgroundJobState } from 'codegen/gql-types';
import { SelectionKind } from 'components/calendar-common/selection-mode/Store';
import Action from './Action';
import Mutation from './Mutation';

export const bulkRequestNS = namespace('bulkRequest');
export const BULK_REQUEST_POLL_TIMEOUT = 1 * 1000; // 1 secs

export interface BulkRequestPayload {
  path: string;
  method: BulkRequestMethod;
  ids: number[];
  data: any;
}

export enum BulkRequestMethod {
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum BulkRequestStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export interface RequestPoll {
  id: string;
  startedAt: Date;
  status: BulkRequestStatus;
  result: StoreActionResult | null;
  timeoutId: number;
  isSuccess: boolean;
  isFailure: boolean;
  type: SelectionKind;
  bulkOperationMethod: BulkRequestMethod;
}

export interface StoreState {
  requests: RequestPoll[];
}

export const BulkOperationMessagesMap = {
  [`${SelectionKind.SHIFT}_${BulkRequestMethod.DELETE}`]: {
    success: 'shiftSchedule.bulkDelete.snackAllDeleted',
    error: 'shiftSchedule.bulkDelete.snackFailed',
  },
  [`${SelectionKind.SHIFT}_${BulkRequestMethod.PATCH}`]: {
    success: 'editMultipleShifts.snackAllUpdated',
    error: 'editMultipleShifts.snackUpdateFailed',
  },
  [`${SelectionKind.STAFF_SHIFT}_${BulkRequestMethod.DELETE}`]: {
    success: 'shiftSchedule.bulkDelete.snackAllUnassigned',
    error: 'shiftSchedule.bulkDelete.snackUnassignFailed',
  },
};

export const getBulkRequestTimeout = (date: Date) => {
  const now = new Date().getTime();
  const elapsedTimeinSeconds = (now - date.getTime()) / 1000;
  if (elapsedTimeinSeconds > 60) {
    return 30 * 1000;
  }
  if (elapsedTimeinSeconds > 10) {
    return 10 * 1000;
  }
  return 1 * 1000;
};

export type CreateBulkRequestFunction = (payload: {
  bulkRequestPayload: BulkRequestPayload[];
  selectionType: SelectionKind;
  bulkOperationMethod: BulkRequestMethod;
}) => Promise<StoreActionResult>;

const getBulkRequestStore = (
  restClient: RestClient,
  logger: ApplicationLogger,
): Module<StoreState, RootStoreState> => {
  const store: Module<StoreState, RootStoreState> = {
    namespaced: true,
    state: {
      requests: [],
    },
    mutations: {
      [Mutation.START_POLLING](state: StoreState, request: RequestPoll) {
        state.requests = [...state.requests, request];
      },
      [Mutation.STOP_POLLING](
        state: StoreState,
        { requestId, result, isFailure, isSuccess },
      ) {
        state.requests = state.requests.map((request) =>
          request.id === requestId
            ? {
                ...request,
                status: BulkRequestStatus.COMPLETED,
                result,
                isFailure,
                isSuccess,
              }
            : request,
        );
      },
      [Mutation.REMOVE_POLLING](state: StoreState, requestId: string) {
        state.requests = state.requests.filter(
          (request) => request.id === requestId,
        );
      },
      [Mutation.UPDATE_TIMEOUT_ID](
        state: StoreState,
        { requestId, timeoutId },
      ) {
        state.requests = state.requests.map((request) =>
          request.id === requestId
            ? {
                ...request,
                timeoutId,
              }
            : request,
        );
      },
    },

    actions: {
      async [Action.CREATE_BULK_REQUEST](
        { rootState, dispatch },
        { bulkRequestPayload: payload, selectionType, bulkOperationMethod },
      ): Promise<StoreActionResult> {
        if (!rootState.auth.currentCompanyId) {
          return {
            state: StoreActionState.ERROR,
          };
        }
        const response = await restClient.post(
          '/api/v1/bulk_requests',
          JSON.stringify({
            company_id: rootState.auth.currentCompanyId,
            user_email: rootState.auth.email,
            authentication_token: rootState.auth.token,
            payload,
          }),
          {
            'content-type': 'application/json',
          },
        );

        if (!response.body?.id) {
          logger.instance.error(response);
          return {
            state: StoreActionState.ERROR,
          };
        }
        dispatch(Action.START_POLLING, {
          requestId: response.body.id,
          type: selectionType,
          bulkOperationMethod,
        });
        return {
          meta: {
            ...response.body,
          },
          entityId: response.body.id,
          state: StoreActionState.SUCCESS,
        };
      },
      async [Action.GET_BULK_REQUEST](
        { rootState },
        requestId: string,
      ): Promise<StoreActionResult> {
        if (!rootState.auth.currentCompanyId) {
          return {
            state: StoreActionState.ERROR,
          };
        }

        const response = await restClient.get(
          `/api/v1/bulk_requests/${requestId}`,
          {
            company_id: `${rootState.auth.currentCompanyId}`,
          },
        );

        if (!response.body?.id) {
          return {
            state: StoreActionState.ERROR,
          };
        }
        return {
          meta: {
            ...response.body,
          },
          entityId: response.body.id,
          state: StoreActionState.SUCCESS,
        };
      },
      async [Action.START_POLLING](
        { commit, dispatch, state },
        { requestId, type, bulkOperationMethod },
      ) {
        const checkBulkRequestStatus = (timeout) => {
          return setTimeout(async () => {
            const result = await dispatch(Action.GET_BULK_REQUEST, requestId);
            const {
              total_entries,
              failed_entries,
              completed_entries,
              state: jobState,
            } = result.meta;
            const isFailure = failed_entries > 0;
            const isSuccess = total_entries === completed_entries;
            const processingRequest = state.requests.find(
              (request) => request.id === requestId,
            );
            if (jobState === GQLBackgroundJobState.FINISHED) {
              clearTimeout(processingRequest?.timeoutId);
              commit(Mutation.STOP_POLLING, {
                requestId,
                result,
                isFailure,
                isSuccess,
              });
            } else {
              commit(Mutation.UPDATE_TIMEOUT_ID, {
                requestId,
                timeoutId: checkBulkRequestStatus(
                  getBulkRequestTimeout(
                    processingRequest?.startedAt || new Date(),
                  ),
                ),
              });
            }
          }, timeout);
        };

        commit(Mutation.START_POLLING, {
          id: requestId,
          type,
          startedAt: new Date(),
          status: BulkRequestStatus.PROCESSING,
          timeoutId: checkBulkRequestStatus(BULK_REQUEST_POLL_TIMEOUT),
          bulkOperationMethod,
        });
      },
      async [Action.REMOVE_POLLING]({ commit }, requestId: string) {
        commit(Mutation.REMOVE_POLLING, {
          requestId,
        });
      },
    },
  };

  return store;
};

export default getBulkRequestStore;
