import Employment, {
  EmploymentConstructorProps,
} from 'components/calendar-common/employments/Employment';

type StaffShiftParams = EmploymentConstructorProps & {
  staffShiftId: number;
  assignmentGroupId: number | undefined;
  connectedGroupId: number | null;
};

class StaffShift extends Employment {
  public staffShiftId: StaffShiftParams['staffShiftId'];

  public assignmentGroupId: StaffShiftParams['assignmentGroupId'];

  public connectedGroupId: StaffShiftParams['connectedGroupId'];

  public constructor(data: StaffShiftParams) {
    super(data);
    this.staffShiftId = data.staffShiftId;
    this.assignmentGroupId = data.assignmentGroupId;
    this.connectedGroupId = data.connectedGroupId;
  }
}

export default StaffShift;
