import root from './root.css';
import colors from './colors.css';
import typography from './typography.css';
import shadowns from './shadows.css';
import spacing from './spacing.css';
import transitions from './transitions.css';
import fonts from './fonts.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  ...root,
  ...colors,
  ...typography,
  ...shadowns,
  ...spacing,
  ...transitions,
  ...fonts,
};
