import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { redirectToNamedParent } from 'src/utils/route';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import {
  AbsenceGroupDayItem,
  calendarAbsencesNS,
} from 'components/calendar/absences/Store';
import { GQLAbsenceState } from 'codegen/gql-types';
import Absence from 'components/calendar-common/absences/Absence';
import Avatar from 'components/avatar/Avatar';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import Button from 'components/form/button/Button';
import { Size } from 'components/types';
import { Slot } from 'components/dialog/Dialog';
import CardWithLink, {
  Slot as CardWithLinkSlot,
} from 'components/card/card-with-link/CardWithLink';
import styles from './calendar-dialog-absence-group.css';
import { Route } from '../routes';

export interface Props {
  dateKey?: string;
  absenceState?: GQLAbsenceState;
  absenceReasonId?: number;
  localizedAbsenceReason: string;
}

@Component
export default class CalendarDialogAbsenceGroup extends TsxComponent<
  Props,
  {}
> {
  @Prop()
  public dateKey: Props['dateKey'];

  @Prop()
  public absenceReasonId: Props['absenceReasonId'];

  @Prop()
  public absenceState: Props['absenceState'];

  @Prop()
  public localizedAbsenceReason: Props['localizedAbsenceReason'];

  @calendarAbsencesNS.Getter('absencesByDatesByStateByReason')
  private absences: Record<string, AbsenceGroupDayItem>;

  private get title() {
    return this.$t('calendar.titleAbsenceGroup', {
      absenceReason: this.localizedAbsenceReason,
    });
  }

  private get currentAbsences(): Absence[] {
    if (
      !this.absences ||
      !this.dateKey ||
      !this.absenceReasonId ||
      !this.absenceState
    ) {
      return [];
    }

    const absencesByDate = this.absences[this.dateKey] || {};
    const absencesByDateByState = absencesByDate[this.absenceState] || {};
    const absencesByDateByStateByReason =
      absencesByDateByState[this.absenceReasonId] || [];

    return absencesByDateByStateByReason;
  }

  private onCloseClick() {
    redirectToNamedParent(this);
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        isOpen
        onCloseClick={this.onCloseClick}
        title={this.title}
      >
        <ul class={styles.calendarDialogAbsenceGroupList}>
          {this.currentAbsences.map((absence) => (
            <li>
              <CardWithLink
                to={{
                  name: Route.ABSENCE,
                  params: { id: absence.id.toString() },
                  query: { goBackOnCancel: 'true' },
                }}
              >
                <Avatar
                  class={styles.calendarDialogAbsenceGroupEmploymentAvatar}
                  firstName={absence.employment.firstName}
                  lastName={absence.employment.lastName}
                  imageUrl={absence.employment.image}
                  size={Size.SMALL}
                  slot={CardWithLinkSlot.ICON}
                />

                <div class={styles.calendarDialogAbsenceGroupEmploymentName}>
                  {absence.employment.name}
                </div>
              </CardWithLink>
            </li>
          ))}
        </ul>

        <Button
          color={ButtonColor.SECONDARY}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.STROKE}
        >
          {this.$t('general.buttonClose')}
        </Button>
      </DialogWithSpinnerAndError>
    );
  }
}
