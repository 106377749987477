import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLShiftAvailableEmploymentFragmentFragment,
  GQLShiftAvailableEmploymentsQueryVariables,
  GQLShiftAvailableEmploymentsQuery,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
  WithCachePayloadParameter,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import ShiftAvailableEmploymentsGql from './queries/ShiftAvailableEmployments.gql';

export const shiftAvailableEmploymentsNS = namespace(
  'shiftAvailableEmployments',
);

export type StoreState = ById<ShiftAvailableEmployment>;

export type ShiftAvailableEmployment =
  GQLShiftAvailableEmploymentFragmentFragment;

export type FetchAllShiftAvailableEmploymentsFunction = (
  payload: WithCachePayloadParameter<
    Omit<GQLShiftAvailableEmploymentsQueryVariables, 'companyId'>
  >,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getShiftAvailableEmploymentsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    getters: {
      getByShiftId: (state: StoreState) => (shiftId: number) =>
        Object.values(state.byId).filter((item) => item.shiftId === shiftId),
    },
  };

  const fetchAll: ActionProvider<
    GQLShiftAvailableEmploymentsQuery,
    GQLShiftAvailableEmploymentsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllShiftAvailableEmploymentsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: ShiftAvailableEmploymentsGql,
      resultKey: 'shiftAvailableEmployments',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createNormalizedStore<
    ShiftAvailableEmployment,
    StoreState,
    RootStoreState
  >({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftAvailableEmploymentsStore;
