import DateItem from 'components/calendar-common/common/DateItem';
import { Component, Prop, Vue } from 'vue-property-decorator';
import styles from './grid-cell.css';

@Component
class GridCell extends Vue {
  @Prop({
    default: () => ({}),
  })
  public date!: DateItem;

  @Prop({ default: false })
  public isHeader!: boolean;

  @Prop({ default: false })
  public isLastRow!: boolean;

  private get dateClasses(): Record<string, boolean> | string[] {
    return this.isHeader
      ? [styles.gridCellHeader]
      : {
          [styles.gridCellOutsideShiftplan]: !this.date.isWithinShiftplan,
          [styles.gridCellToday]: this.date.isToday,
          [styles.gridCellSpecialDate]: this.date.isSpecialDate,
          [styles.gridCellLastRow]:
            this.isLastRow && (this.date.isSpecialDate || this.date.isToday),
        };
  }

  public render() {
    return (
      <div
        class={[styles.gridCell, this.dateClasses]}
        role="button"
        tabIndex={0}
        onClick={(e) => this.$emit('click', e)}
        onFocus={(e) => this.$emit('focus', e)}
        onKeypress={(e) => this.$emit('keypress', e)}
        onMouseover={(e) => this.$emit('mouseover', e)}
        onMousedown={(e) => this.$emit('mousedown', e)}
      >
        {this.$scopedSlots.default && this.$scopedSlots.default({})}
      </div>
    );
  }
}

export default GridCell as any;
