/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Dialog, { Slot } from 'components/dialog/Dialog';
import Spinner from 'components/spinner/Spinner';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';

import styles from './dialog.css';

interface Props {
  error?: boolean | string;
  id?: string;
  isLoading?: boolean;
  isClosingPrevented?: boolean;
  isDialogNotClosable?: boolean;
  isOpen?: boolean;
  title?: string;
  isStretchable?: boolean;
}

interface Events {
  onCloseClick: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>;
}

@Component
export default class DialogWithSpinnerAndError extends TsxComponent<
  Props,
  Events
> {
  @Prop()
  public error: Props['error'];

  @Prop()
  public id: Props['id'];

  @Prop({ default: false })
  public isOpen: NonNullable<Props['isOpen']>;

  @Prop()
  public isLoading: Props['isLoading'];

  @Prop({ default: false })
  public isStretchable: NonNullable<Props['isStretchable']>;

  @Prop({ default: false })
  public isClosingPrevented: NonNullable<Props['isClosingPrevented']>;

  @Prop({ default: false })
  public isDialogNotClosable: Props['isDialogNotClosable'];

  @Prop()
  public title: Props['title'];

  protected onCloseClick() {
    this.$emit('closeClick');
  }

  public render() {
    return (
      <Dialog
        id={this.id}
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        isClosingPrevented={this.isClosingPrevented}
        isDialogNotClosable={this.isDialogNotClosable}
        title={this.title}
        isStretchable={this.isStretchable}
      >
        {this.isLoading && <Spinner class={styles.dialogSpinner} />}

        {this.error && (
          <div class={styles.dialogError}>
            {typeof this.error === 'string'
              ? this.error
              : this.$t('screens.error.title')}
          </div>
        )}

        {!this.isLoading && !this.error && this.$slots.default}

        <template slot={Slot.BUTTONS_LEFT}>
          {this.$slots[Slot.BUTTONS_LEFT]}
        </template>

        <template slot={Slot.BUTTONS_RIGHT}>
          {this.$slots[Slot.BUTTONS_RIGHT]}
        </template>
      </Dialog>
    );
  }
}
