import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLLocationFragmentFragment,
  GQLLocationsQuery,
  GQLLocationsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { StoreActionResult, PayloadParameter } from 'utils/store';
import LocationsGql from './queries/Locations.gql';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from '../normalized-store';

export const locationsNS = namespace('locations');

export type StoreState = ById<Location>;

export type Location = GQLLocationFragmentFragment;

export type FetchAllLocationsFunction = (
  payload: Omit<GQLLocationsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getLocationsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const fetchAll: ActionProvider<
    GQLLocationsQuery,
    GQLLocationsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllLocationsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: LocationsGql,
      resultKey: 'locations',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createNormalizedStore<Location, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getLocationsStore;
