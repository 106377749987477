import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { EventsOn } from 'vue-tsx-support/types/dom';
import { Size } from 'components/types';
import Icon from 'components/icons/Icon';
import Tooltip from 'components/tooltip/Tooltip';
import { getRandomString } from 'src/utils/random';
import { IconName } from 'components/icons/types';
import styles from './input-text.css';

export interface InputTextProps {
  id?: string;
  isStepperShown?: boolean;
  label?: string;
  name?: string;
  type:
    | 'date'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url';
  value?: string | number | readonly string[];
  isValid?: boolean;
  error?: string;
  hintText?: string;
  helperText?: string;
  icon?: IconName;
  info?: string;
  trailingText?: string;
}
@Component({
  inheritAttrs: false,
})
export default class InputText extends TsxComponent<
  Partial<HTMLInputElement> & InputTextProps,
  EventsOn<HTMLInputElement>
> {
  public $refs: {
    inputRef: HTMLInputElement;
  };

  @Prop({ default: () => `inp-${getRandomString()}` })
  protected id: InputTextProps['id'];

  @Prop()
  protected isStepperShown?: InputTextProps['isStepperShown'];

  @Prop()
  public label?: InputTextProps['label'];

  @Prop()
  public name?: InputTextProps['name'];

  @Prop()
  public type: InputTextProps['type'];

  @Prop()
  public value?: InputTextProps['value'];

  @Prop()
  public isValid?: InputTextProps['isValid'];

  @Prop()
  public error?: InputTextProps['error'];

  @Prop()
  public hintText?: InputTextProps['hintText'];

  @Prop()
  public helperText?: InputTextProps['helperText'];

  @Prop()
  public icon?: InputTextProps['icon'];

  @Prop()
  public info?: InputTextProps['info'];

  @Prop()
  public trailingText?: InputTextProps['trailingText'];

  protected get isStepperDownDisabled() {
    if (!this.isStepperShown || this.$attrs.disabled) {
      return true;
    }

    if (this.$attrs.min === undefined || this.value === undefined) {
      return false;
    }

    // FAQ: Number.parseInt for string[] will return integer for first element
    const val =
      typeof this.value === 'number'
        ? this.value
        : Number.parseInt(this.value as string, 10);
    const min = Number.parseInt(this.$attrs.min, 10);

    return val <= min;
  }

  protected get isStepperUpDisabled() {
    if (!this.isStepperShown || this.$attrs.disabled) {
      return true;
    }

    if (this.$attrs.max === undefined || this.value === undefined) {
      return false;
    }

    // FAQ: Number.parseInt for string[] will return integer for first element
    const val =
      typeof this.value === 'number'
        ? this.value
        : Number.parseInt(this.value as string, 10);
    const max = Number.parseInt(this.$attrs.max, 10);

    return val >= max;
  }

  public render() {
    return (
      <div
        class={{
          [styles.inputText]: true,
          [styles.inputTextCalendar]: this.type === 'date',
          [styles.inputTextDisabled]: !!this.$attrs.disabled,
          [styles.inputTextInvalid]: this.isValid === false || this.error,
          [styles.inputTextValid]: this.isValid === true,
        }}
      >
        {this.label && (
          <label class={styles.inputTextLabel} for={this.id}>
            {this.label}
            {this.hintText && (
              <span class={styles.inputTextHintText}>({this.hintText})</span>
            )}
          </label>
        )}
        <div class={styles.inputTextInner}>
          <div class={styles.inputTextInputWrapper}>
            {this.type === 'number' && this.isStepperShown && (
              <div class={styles.inputTextStepper}>
                <button
                  class={styles.inputTextStepperButton}
                  disabled={this.isStepperDownDisabled}
                  type="button"
                  onClick={() => {
                    this.$refs.inputRef.stepDown();
                    this.$refs.inputRef.dispatchEvent(new Event('input'));
                  }}
                >
                  <Icon name={IconName.MINUS} size={Size.XSMALL} />
                </button>
              </div>
            )}

            {this.type === 'date' && (
              // false positive
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label class={styles.inputTextCalendarIcon} for={this.id}>
                <Icon
                  class={styles.inputTextIcon}
                  name={IconName.DAY}
                  size={Size.XSMALL}
                />
              </label>
            )}

            {this.type === 'time' && (
              // false positive
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label class={styles.inputTextCalendarIcon} for={this.id}>
                <Icon name={IconName.CLOCK} size={Size.XSMALL} />
              </label>
            )}
            {this.icon && (
              <Icon
                name={this.icon}
                class={{
                  [styles.inputTextIcon]: true,
                  [styles.inputTextLeftIcon]: true,
                }}
              />
            )}
            <input
              {...{
                attrs: { ...this.$attrs },
                on: { ...this.$listeners },
                style: this.$vnode.data?.style,
              }}
              class={{
                [styles.inputTextInput]: true,
                [styles.inputTextInputWithStepper]: this.isStepperShown,
              }}
              id={this.id}
              name={this.name}
              ref="inputRef"
              type={this.type}
              value={this.value}
            />
            {this.trailingText && (
              <span class={styles.inputTextTrailingText}>
                {this.trailingText}
              </span>
            )}
            {this.info && (
              <Tooltip text={this.info}>
                <Icon name={IconName.INFO} class={styles.inputTextInfoIcon} />
              </Tooltip>
            )}

            {this.isValid === undefined ? null : (
              <Icon
                name={
                  this.isValid ? IconName.CHECK_CIRCLE : IconName.REPORT_PROBLEM
                }
                class={
                  this.isValid
                    ? styles.inputTextValidIcon
                    : styles.inputTextInvalidIcon
                }
              />
            )}
            {this.type === 'number' && this.isStepperShown && (
              <div class={styles.inputTextStepper}>
                <button
                  class={styles.inputTextStepperButton}
                  disabled={this.isStepperUpDisabled}
                  type="button"
                  onClick={() => {
                    this.$refs.inputRef.stepUp();
                    this.$refs.inputRef.dispatchEvent(new Event('input'));
                  }}
                >
                  <Icon name={IconName.PLUS} size={Size.XSMALL} />
                </button>
              </div>
            )}
          </div>
        </div>

        {this.error && <div class={styles.inputTextError}>{this.error}</div>}
        {this.helperText && (
          <span class={styles.inputTextHelperText}>{this.helperText}</span>
        )}
      </div>
    );
  }
}
