import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { DocumentNode } from 'graphql';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import { SentryTag } from 'services/logger/SentryTransport';
import { filterFalsy } from 'src/utils/utils';
import {
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';

export interface ById<T> {
  byId: Record<number | string, T>;
  total: number;
  resultsCache: Record<string, (number | string)[]>;
  abortControllerStore: Record<string, AbortController>;
}

export interface WithId {
  id: number | string;
}

export enum Action {
  CLEAR = 'clear',
  CREATE = 'create',
  DELETE = 'delete',
  FETCH = 'fetch',
  FETCH_ALL = 'fetchAll',
  UPDATE = 'update',
  CLEAR_ITEMS_FROM_STORE = 'clearItemsFromStore',
  LOCAL_PATCH = 'localPatch',
  LOCAL_SET = 'localSet',
}

export enum Mutation {
  CLEAR_ITEMS = 'clearItems',
  REMOVE_ITEM = 'removeItem',
  SET_ITEM = 'setItem',
  SET_ITEMS = 'setItems',
  SET_TOTAL = 'setTotal',
  SET_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER = 'setAbortControllerForCacheIdentifier',
  CLEAR_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER = 'clearAbortControllerForCacheIdentifier',
  LOCAL_PATCH = 'localPatch',
}

export interface ActionDefinition<Query, Variables> {
  query: DocumentNode;
  resultKey: keyof Query;
  variables: Variables;
  useBatching?: boolean;
  failSilently?: boolean;
}

export interface ActionDefinitionWithTransform<Entity, Query, Variables>
  extends ActionDefinition<Query, Variables> {
  transform: (input: any) => Entity;
}

/* eslint-disable indent */
export const isSuccessResult = <T extends {}>(
  result: FetchResult<T>,
  resultKey: string | number | symbol,
): result is FetchResult<T> & { data: T } =>
  !!result.data && resultKey in result.data && !!result.data[resultKey];
/* eslint-enable indent */

export const handleUnexpectedResult = (
  action: Action,
  logger: ApplicationLogger,
): StoreActionResult => {
  logger.instance.error({
    message:
      'Unexpected query/mutation result: data missing or resultKey not found in data',
    tags: [[SentryTag.ACTION, action]],
  });

  return { state: StoreActionState.ERROR };
};

const isActionDefinitionWithTransform = <Entity, Query, Variables>(
  actionDefinition: ActionDefinition<Query, Variables>,
): actionDefinition is ActionDefinitionWithTransform<
  Entity,
  Query,
  Variables
> => 'transform' in actionDefinition;

export type WithCachePayloadParameter<T> = T & {
  cacheIdentifier?: string;
};

export type ClearItemsFromStoreFunction = (payload: { ids: number[] }) => void;

export type ActionProvider<
  Query = any,
  Variables = any,
  Entity = any,
  Context = ActionContext<any, any>,
> = <ActionFunction extends (...args: any) => any>(
  context: Context,
  payload: PayloadParameter<ActionFunction>,
) =>
  | ActionDefinition<Query, Variables>
  | ActionDefinitionWithTransform<Entity, Query, Variables>;

export type Normalize<Entity, Keys extends keyof Entity> = Omit<
  Entity,
  Keys
> & {
  [k in Keys]: number[];
};

export function createNormalizedStore<
  Entity extends WithId,
  StoreState,
  RootStoreState,
  NormalizedEntity = Entity,
>(config: {
  provide: {
    [key in Action]?: ActionProvider;
  };
  graphqlClient: ApolloClient<NormalizedCacheObject>;
  logger: ApplicationLogger;
  store: Module<StoreState, RootStoreState>;
  normalize?: ({ context }, entity: Entity | Entity[]) => NormalizedEntity[];
  denormalize?: (
    { rootState, rootGetters },
    entity: NormalizedEntity[],
  ) => Entity[];
}): Module<StoreState & ById<Entity>, RootStoreState> {
  const { state: otherState, namespaced } = config.store || {};
  const { normalize, denormalize } = config;

  const newState = {
    ...(otherState instanceof Function
      ? otherState()
      : (otherState as StoreState)),
    byId: {},
    total: 0,
    resultsCache: {
      latest: [],
    },
    // Stores abort controllers to handle race conditions
    // only applied for queries with cache identifier currently
    abortControllerStore: {},
  };

  const newActions = {
    [Action.CLEAR]({ commit }) {
      commit(Mutation.CLEAR_ITEMS);
    },
    [Action.CLEAR_ITEMS_FROM_STORE](
      { commit, getters },
      { ids }: PayloadParameter<ClearItemsFromStoreFunction>,
    ) {
      ids.forEach((id) => commit(Mutation.REMOVE_ITEM, id));
    },
  };

  const {
    [Action.CREATE]: createActionProvider,
    [Action.DELETE]: deleteActionProvider,
    [Action.FETCH]: fetchActionProvider,
    [Action.FETCH_ALL]: fetchAllActionProvider,
    [Action.UPDATE]: updateActionProvider,
  } = config.provide;

  const denormalizeData = (items, rootState, rootGetters) => {
    if (!items) {
      return [];
    }
    const itemsArray = Array.isArray(items) ? items : [items];
    if (denormalize) {
      return denormalize({ rootState, rootGetters }, itemsArray);
    }
    return itemsArray;
  };

  if (fetchActionProvider) {
    newActions[Action.FETCH] = async (context, payload) => {
      try {
        const actionDefinition = fetchActionProvider(context, payload);
        const {
          variables: { cacheIdentifier, ...variables },
          query,
          resultKey,
          useBatching,
          failSilently,
        } = actionDefinition;

        let abortController: AbortController | undefined;

        if (cacheIdentifier) {
          const currentRequestController =
            context.state.abortControllerStore[cacheIdentifier];

          // stop current request
          if (currentRequestController) {
            currentRequestController.abort();
            context.commit(
              Mutation.CLEAR_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER,
              {
                cacheIdentifier,
              },
            );
          }

          abortController = new AbortController();
          context.commit(Mutation.SET_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER, {
            cacheIdentifier,
            abortController,
          });
        }

        const result = await config.graphqlClient.query({
          query,
          variables,
          context: {
            useBatching,
            fetchOptions: abortController
              ? {
                  signal: abortController.signal,
                }
              : undefined,
          },
        });

        if (cacheIdentifier) {
          context.commit(Mutation.CLEAR_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER, {
            cacheIdentifier,
          });
        }

        if (!isSuccessResult(result, resultKey) && !failSilently) {
          return handleUnexpectedResult(Action.FETCH, config.logger);
        }

        const isArrayResponse =
          !!result.data?.[resultKey]?.items &&
          Array.isArray(result.data?.[resultKey]?.items);
        if (
          // check for array response
          (isArrayResponse && !result.data[resultKey].items.length) ||
          // check for non-array response
          result.data?.[resultKey] === null
        ) {
          return { state: StoreActionState.NOT_FOUND };
        }

        let item = isArrayResponse
          ? result.data?.[resultKey].items[0]
          : result.data?.[resultKey];

        if (isActionDefinitionWithTransform(actionDefinition)) {
          item = actionDefinition.transform(item);
        }

        if (normalize) {
          [item] = normalize({ context }, item);
        }

        context.commit(Mutation.SET_ITEM, { item, cacheIdentifier });

        return { state: StoreActionState.SUCCESS };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (fetchAllActionProvider) {
    newActions[Action.FETCH_ALL] = async (context, payload) => {
      try {
        const actionDefinition = fetchAllActionProvider(context, payload);
        const {
          variables: { cacheIdentifier, ...variables },
          query,
          resultKey,
          useBatching,
        } = actionDefinition;

        let abortController: AbortController | undefined;

        if (cacheIdentifier) {
          const currentRequestController =
            context.state.abortControllerStore[cacheIdentifier];

          // stop current request
          if (currentRequestController) {
            currentRequestController.abort();
            context.commit(
              Mutation.CLEAR_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER,
              {
                cacheIdentifier,
              },
            );
          }

          abortController = new AbortController();
          context.commit(Mutation.SET_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER, {
            cacheIdentifier,
            abortController,
          });
        }

        const result = await config.graphqlClient.query({
          query,
          variables,
          context: {
            useBatching,
            fetchOptions: abortController
              ? {
                  signal: abortController.signal,
                }
              : undefined,
          },
        });

        if (cacheIdentifier) {
          context.commit(Mutation.CLEAR_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER, {
            cacheIdentifier,
          });
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.FETCH, config.logger);
        }

        if (!Array.isArray(result.data?.[resultKey].items)) {
          return { state: StoreActionState.NOT_FOUND };
        }

        let items = result.data?.[resultKey].items;
        if (isActionDefinitionWithTransform(actionDefinition)) {
          items = items.map((item) => actionDefinition.transform(item));
        }

        if (normalize) {
          items = normalize({ context }, items);
        }

        context.commit(Mutation.SET_ITEMS, { items, cacheIdentifier });
        context.commit(
          Mutation.SET_TOTAL,
          result.data?.[resultKey].pagination?.count || items.length,
        );

        return { state: StoreActionState.SUCCESS };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (updateActionProvider) {
    newActions[Action.UPDATE] = async (context, payload) => {
      try {
        const { variables, query, resultKey, useBatching } =
          updateActionProvider(context, payload);

        const result = await config.graphqlClient.mutate({
          mutation: query,
          variables,
          context: {
            useBatching,
          },
        });

        if (result.errors?.length) {
          return {
            state: StoreActionState.ERROR,
            error: result.errors[0].extensions?.response,
          };
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.UPDATE, config.logger);
        }

        // FIXME find a generic way to handle conflicts. Check feedback on:
        // https://github.com/shyftplan/sppt_web/pull/310#pullrequestreview-849468481
        if (!!result.data && 'conflicts' in result.data[resultKey]) {
          return {
            state: StoreActionState.CONFLICT,
            conflicts: result.data[resultKey].conflicts,
          };
        }

        let item = result.data[resultKey];
        if (normalize) {
          [item] = normalize({ context }, item);
        }

        context.commit(Mutation.SET_ITEM, { item });

        return {
          state: StoreActionState.SUCCESS,
          entityId: result.data[resultKey].id,
        };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (deleteActionProvider) {
    newActions[Action.DELETE] = async (context, payload) => {
      try {
        const { variables, query, resultKey, useBatching } =
          deleteActionProvider(context, payload);

        const result = await config.graphqlClient.mutate({
          mutation: query,
          variables,
          context: {
            useBatching,
          },
        });

        if (result.errors?.length) {
          return {
            state: StoreActionState.ERROR,
            error: result.errors[0].extensions?.response,
          };
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.DELETE, config.logger);
        }

        context.commit(Mutation.REMOVE_ITEM, payload.id);

        return {
          state: result.data[resultKey].success
            ? StoreActionState.SUCCESS
            : StoreActionState.ERROR,
          error: result.data[resultKey].error || undefined,
        };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  if (createActionProvider) {
    newActions[Action.CREATE] = async (context, payload) => {
      try {
        const actionDefinition = createActionProvider(context, payload);
        const {
          variables: { cacheIdentifier, ...variables },
          query,
          resultKey,
          useBatching,
        } = actionDefinition;

        const result = await config.graphqlClient.mutate({
          mutation: query,
          variables,
          context: {
            useBatching,
          },
        });

        if (result.errors?.length) {
          return {
            state: StoreActionState.ERROR,
            error: result.errors[0].extensions?.response,
          };
        }

        if (!isSuccessResult(result, resultKey)) {
          return handleUnexpectedResult(Action.CREATE, config.logger);
        }

        if (!!result.data && 'conflicts' in result.data[resultKey]) {
          return {
            state: StoreActionState.CONFLICT,
            conflicts: result.data[resultKey].conflicts,
          };
        }

        let item = result.data[resultKey];

        if (isActionDefinitionWithTransform(actionDefinition)) {
          item = actionDefinition.transform(item);
        }

        if (normalize) {
          [item] = normalize({ context }, item);
        }

        context.commit(Mutation.SET_ITEM, { item, cacheIdentifier });

        return {
          state: StoreActionState.SUCCESS,
          entityId: result.data[resultKey].id,
        };
      } catch (e) {
        config.logger.instance.error(e);
      }

      return { state: StoreActionState.ERROR };
    };
  }

  newActions[Action.LOCAL_PATCH] = async (context, payload) => {
    const { id, patch } = payload;

    if (context.state.byId[id]) {
      context.commit(Mutation.LOCAL_PATCH, { id, patch });
      return {
        state: StoreActionState.SUCCESS,
      };
    }

    return { state: StoreActionState.NOT_FOUND };
  };

  newActions[Action.LOCAL_SET] = async (context, payload) => {
    if (payload) {
      context.commit(Mutation.SET_ITEMS, { items: payload });
      return {
        state: StoreActionState.SUCCESS,
      };
    }
    return { state: StoreActionState.NOT_FOUND };
  };

  return {
    namespaced,
    state: newState,
    mutations: {
      ...config.store?.mutations,
      [Mutation.CLEAR_ITEMS](state) {
        state.byId = {};
        state.resultsCache = { latest: [] };
        state.total = 0;
      },
      [Mutation.SET_ITEM](
        state,
        { item, cacheIdentifier }: { item: Entity; cacheIdentifier?: string },
      ) {
        state.byId = Object.freeze({
          ...state.byId,
          [item.id]: item,
        });

        if (
          cacheIdentifier &&
          !state.resultsCache[cacheIdentifier]?.includes(item.id)
        ) {
          // make sure that added key is reactive
          Vue.set(state.resultsCache, cacheIdentifier, [
            ...(state.resultsCache[cacheIdentifier] || []),
            item.id,
          ]);
        }
      },
      [Mutation.SET_ITEMS](
        state,
        {
          items,
          cacheIdentifier,
        }: {
          items: Entity[];
          cacheIdentifier?: string;
        },
      ) {
        state.byId = Object.freeze(
          items.reduce(
            (prev, item) => {
              prev[item.id] = item;
              return prev;
            },
            { ...state.byId },
          ),
        );

        state.resultsCache.latest = items.map((item) => item.id);
        if (cacheIdentifier) {
          // make sure that added key is reactive
          Vue.set(
            state.resultsCache,
            cacheIdentifier,
            items.map((item) => item.id),
          );
        }
      },
      [Mutation.REMOVE_ITEM](state, id: number) {
        const tmp = { ...state.byId };
        delete tmp[id];

        state.byId = tmp;
        state.resultsCache = Object.keys(state.resultsCache).reduce(
          (acc: Record<string, (number | string)[]>, key) => ({
            ...acc,
            [key]: state.resultsCache[key].filter((itemId) => itemId !== id),
          }),
          {},
        );
      },
      [Mutation.SET_TOTAL](state, total: number) {
        state.total = total;
      },
      [Mutation.SET_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER](
        state,
        {
          abortController,
          cacheIdentifier,
        }: { abortController: AbortController; cacheIdentifier: string },
      ) {
        state.abortControllerStore[cacheIdentifier] = abortController;
      },
      [Mutation.CLEAR_ABORT_CONTROLLER_FOR_CACHE_IDENTIFIER](
        state,
        { cacheIdentifier }: { cacheIdentifier: string },
      ) {
        delete state.abortControllerStore[cacheIdentifier];
      },
      [Mutation.LOCAL_PATCH](
        state,
        { id, patch }: { id: number | string; patch: Partial<Entity> },
      ) {
        state.byId = Object.freeze({
          ...state.byId,
          [id]: { ...state.byId[id], ...patch },
        });
      },
    },
    getters: {
      ...config.store?.getters,
      denormalizeById: (state, getters) => (id: number | string) => {
        return getters.denormalizedItems[id];
      },
      denormalizedItems: (state, getters, rootState, rootGetters) => {
        return denormalizeData(
          Object.values(state.byId),
          rootState,
          rootGetters,
        ).reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
      },
      getById: (state, getters) => (id: number | string) => {
        if (!id || Number.isNaN(id)) {
          return undefined;
        }
        return getters.denormalizeById(id);
      },
      items: (state, getters) => {
        return Object.values(getters.denormalizedItems);
      },
      ordered: (state, getters) =>
        state.resultsCache.latest.map((id) => {
          return getters.denormalizeById(id);
        }),
      getByCacheIdentifier: (state, getters) => (cacheIdentifier: string) =>
        (state.resultsCache[cacheIdentifier] || [])
          .map((id) => {
            return getters.denormalizeById(id);
          })
          .filter(filterFalsy),
    },
    actions: {
      ...config.store?.actions,
      ...newActions,
    },
    modules: config.store?.modules,
  };
}
