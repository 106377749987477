import type { GQLLocation } from 'codegen/gql-types';
import { Slot } from 'components/dialog/Dialog';
import DialogWithSpinnerAndError from 'components/dialog/DialogWithSpinnerAndError';
import FormDialog from 'components/form/form-dialog/FormDialog';
import Section, { SectionKind } from 'components/form/form-dialog/Section';
import InputCheckbox from 'components/form/input-checkbox/InputCheckbox';
import InputColor from 'components/form/input-color/InputColor';
import InputTextArea from 'components/form/input-text-area/InputTextArea';
import InputText from 'components/form/input-text/InputText';
import { createEventPayload, EventPayload } from 'src/utils/events';
import { getRandomString } from 'src/utils/random';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { Size } from 'components/types';
import styles from './dialog-position.css';

// required to make browser form validation work
const FORM_ID = `dialog-${getRandomString()}`;

export interface FormState {
  color: string;
  description?: string;
  locationIdsSet: Set<number>;
  name: string;
  note?: string;
  sort?: string;
}

@Component
export default class DialogPosition extends TsxComponent<
  {
    formState: FormState;
    isOpen?: boolean;
    isSubmitting: boolean;
    locations?: GQLLocation[];
  },
  {
    onCloseClick: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
    onInput: <T extends keyof FormState>(
      payload: EventPayload<{ field: T; value: FormState[T] }>,
    ) => void;
    onSubmit: (payload: EventPayload<void, HTMLElement, UIEvent>) => void;
  }
> {
  @Prop()
  public formState: FormState;

  @Prop()
  public isOpen?: boolean;

  @Prop()
  public isSubmitting: boolean;

  @Prop()
  public locations?: GQLLocation[];

  protected get isFormStateValid() {
    return (
      !this.isSubmitting &&
      this.formState.name.trim().length > 0 &&
      this.formState.color.length === 7 &&
      this.formState.locationIdsSet.size
    );
  }

  protected onCloseClick(e: SyntheticEvent<HTMLElement, UIEvent>) {
    if (!this.isSubmitting) {
      this.$emit(
        'closeClick',
        createEventPayload<void, HTMLElement, UIEvent>(e, undefined),
      );
    }
  }

  protected onInput(
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
  ) {
    this.$emit(
      'input',
      createEventPayload(e, { field: e.target.name, value: e.target.value }),
    );
  }

  protected onCheckboxGroupChange(e: SyntheticEvent<HTMLInputElement, Event>) {
    const value = Number.parseInt(e.target.value, 10);
    const newSet = new Set(this.formState[e.target.name]);

    if (e.target.checked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }

    this.$emit(
      'input',
      createEventPayload(e, {
        field: e.target.name,
        value: newSet,
      }),
    );
  }

  protected onSubmit(e: SyntheticEvent<HTMLFormElement, UIEvent>) {
    this.$emit('submit', createEventPayload<void>(e, undefined));
  }

  public render() {
    return (
      <DialogWithSpinnerAndError
        isOpen={this.isOpen}
        onCloseClick={this.onCloseClick}
        isClosingPrevented={this.isSubmitting}
        title={this.$t('positions.modal.titleCreate')}
      >
        <FormDialog id={FORM_ID} onSubmit={this.onSubmit}>
          <InputText
            label={this.$t('positions.modal.labelName')}
            name="name"
            onInput={this.onInput}
            required={true}
            type="text"
            value={this.formState.name}
          />

          <InputTextArea
            label={this.$t('positions.modal.labelDescription')}
            name="description"
            onInput={this.onInput}
            type="text"
            value={this.formState.description}
          />

          <Section kind={SectionKind.TWO_COLUMN}>
            <InputText
              label={this.$t('positions.modal.labelSortPosition')}
              name="sort"
              onInput={this.onInput}
              type="number"
              value={this.formState.sort}
            />

            <InputColor
              label={this.$t('positions.modal.labelColor')}
              name="color"
              onInput={this.onInput}
              required={true}
              value={this.formState.color}
            />
          </Section>

          <Section label={this.$t('positions.modal.labelLocations')}>
            <ol class={styles.dialogPositionLocations}>
              {this.locations &&
                this.locations.map((location) => (
                  <li key={location.id}>
                    <InputCheckbox
                      label={location.name}
                      name="locationIdsSet"
                      value={location.id.toString()}
                      checked={this.formState.locationIdsSet.has(location.id)}
                      onChange={this.onCheckboxGroupChange}
                    />
                  </li>
                ))}
            </ol>
          </Section>

          <InputTextArea
            label={this.$t('positions.modal.labelNote')}
            name="note"
            onInput={this.onInput}
            type="text"
            value={this.formState.note}
          />
        </FormDialog>

        <Button
          color={ButtonColor.SECONDARY}
          disabled={this.isSubmitting}
          onClick={this.onCloseClick}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          kind={ButtonKind.GHOST}
        >
          {this.$t('general.buttonCancel')}
        </Button>

        <Button
          disabled={this.isSubmitting || !this.isFormStateValid}
          form={FORM_ID}
          size={Size.SMALL}
          slot={Slot.BUTTONS_RIGHT}
          type="submit"
        >
          {this.$t('positions.modal.buttonCreate')}
        </Button>
      </DialogWithSpinnerAndError>
    );
  }
}
