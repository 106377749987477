export enum IconName {
  ABSENCE = 'absence',
  ABSENCES_STATE_APPROVED = 'absences-state-approved',
  ADD_CIRCLE_OUTLINE = 'add-circle-outline',
  ADD_CIRCLE = 'add-circle',
  ALARM_OFF = 'alarm-off',
  ALARM_ON = 'alarm-on',
  APPLICATION = 'application',
  ARROW_BACK = 'arrow-back',
  ARROW_NEXT = 'arrow-next',
  BELL = 'bell',
  BILLING = 'billing',
  BLOCK = 'block',
  BLOCKED = 'blocked',
  BULK = 'bulk',
  CANCEL = 'cancel',
  CASH_USD = 'cash-usd',
  CHANGE = 'change',
  CHAT = 'chat',
  CHECK_CIRCLE = 'check-circle',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_UP = 'chevron-up',
  CLEAR = 'clear',
  CLOCK = 'clock',
  COMMENT = 'comment',
  COPY = 'copy',
  IMPORT_SHIFT_SCHEDULE = 'copy-shift-schedule',
  DASHBOARD = 'dashboard',
  DAY = 'day',
  DELETE = 'delete',
  DIRECT_SWAP = 'direct-swap',
  DONE = 'done',
  DOTS_HORIZONTAL = 'dots-horizontal',
  DOWNLOAD = 'download',
  EARNED_AMOUNT = 'earned-amount',
  EDIT_EMPLOYEE_ROTATION = 'edit-employee-rotation',
  EDIT_TABLE = 'edit-table',
  EDIT = 'edit',
  EQUAL = 'equal',
  EVALUATION = 'evaluation',
  EVENT = 'event',
  EXPORT_TABLE = 'export-table',
  EXPORT = 'export',
  EYE_OFF = 'eye-off',
  EYE_ON = 'eye-on',
  FACEBOOK = 'facebook',
  FILTER = 'filter',
  FLAG = 'flag',
  FLOPPY_DISK = 'floppy-disk',
  GENERATE = 'generate',
  GOOGLE_DRIVE = 'google-drive',
  GOOGLE_PLUS = 'google-plus',
  HELP = 'help',
  IMPORT = 'import',
  INFO = 'info',
  INSTAGRAM = 'instagram',
  INSURANCE = 'insurance',
  LINK_OFF = 'link-off',
  LINK = 'link',
  LINKEDIN = 'linkedin',
  LOCATION = 'location',
  LOGIN = 'login',
  MAIL = 'mail',
  MAXIMIZED = 'maximized',
  MAXIMUM_MONEY = 'maximum-money',
  MINIMIZED = 'minimized',
  MINUS = 'minus',
  MOBILE = 'mobile',
  MONTH_EMPLOYEES = 'month-employees',
  MONTH_POSITION = 'month-position',
  MONTH = 'month',
  MOON = 'moon',
  NO_CONFICTS_SHIFTS = 'no-conficts-shifts',
  NOTE = 'note',
  OCCUPIED = 'occupied',
  OVERASSIGNED_SHIFTS = 'overassigned-shifts',
  PAPERCLIP = 'paperclip',
  PAUSE = 'pause',
  PAYMENT = 'payment',
  PERSON = 'person',
  PERSONAL = 'personal',
  PHONE_OLD = 'phone-old',
  PIN = 'pin',
  PLAY = 'play',
  PLUS = 'plus',
  POSITION = 'position',
  PRINT = 'print',
  PUNCH = 'punch',
  RANGE = 'range',
  REMAINING_MONEY = 'remaining-money',
  REMOVE_CIRCLE = 'remove-circle',
  REPORT_PROBLEM = 'report-problem',
  REQUEST = 'request',
  RESET = 'reset',
  SEARCH = 'search',
  SEND_MESSAGE = 'send-message',
  SETTINGS = 'settings',
  SHIFT_FULL = 'shift-full',
  SHIFT_HORIZONTAL = 'shift-horizontal',
  SHIFT_OPEN = 'shift-open',
  SHIFT_VERTICAL = 'shift-vertical',
  SHIFTPLAN = 'shiftplan',
  SIDEBAR_COLLAPSE = 'sidebar-collapse',
  SORT = 'sort',
  STAR_CIRCLE = 'star-circle',
  STAR = 'star',
  SURVEY = 'survey',
  SWAP_CIRCLE = 'swap-circle',
  SWAP = 'swap',
  TAG = 'tag',
  TIME = 'time',
  TWITTER = 'twitter',
  UNOCCUPIED = 'unoccupied',
  UPLOAD = 'upload',
  USER_CIRCLE = 'user-circle',
  WEEK_EMPLOYEES = 'week-employees',
  WEEK = 'week',
  WORK = 'work',
  XING = 'xing',
}

export enum FlagName {
  ABKHAZIA = 'abkhazia',
  AFGHANISTAN = 'afghanistan',
  ALAND_ISLANDS = 'aland-islands',
  ALBANIA = 'albania',
  ALGERIA = 'algeria',
  AMERICAN_SAMOA = 'american-samoa',
  ANDORRA = 'andorra',
  ANGOLA = 'angola',
  ANGUILLA = 'anguilla',
  ANTIGUA_AND_BARBUDA = 'antigua-and-barbuda',
  ARGENTINA = 'argentina',
  ARMENIA = 'armenia',
  ARUBA = 'aruba',
  AUSTRALIA = 'australia',
  AUSTRIA = 'austria',
  AZERBAIJAN = 'azerbaijan',
  AZORES_ISLANDS = 'azores-islands',
  BAHAMAS = 'bahamas',
  BAHRAIN = 'bahrain',
  BALEARIC_ISLANDS = 'balearic-islands',
  BANGLADESH = 'bangladesh',
  BARBADOS = 'barbados',
  BASQUE_COUNTRY = 'basque-country',
  BELARUS = 'belarus',
  BELGIUM = 'belgium',
  BELIZE = 'belize',
  BENIN = 'benin',
  BERMUDA = 'bermuda',
  BHUTAN_1 = 'bhutan-1',
  BHUTAN = 'bhutan',
  BOLIVIA = 'bolivia',
  BONAIRE = 'bonaire',
  BOSNIA_AND_HERZEGOVINA = 'bosnia-and-herzegovina',
  BOTSWANA = 'botswana',
  BRAZIL = 'brazil',
  BRITISH_COLUMBIA = 'british-columbia',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'british-indian-ocean-territory',
  BRITISH_VIRGIN_ISLANDS = 'british-virgin-islands',
  BRUNEI = 'brunei',
  BULGARIA = 'bulgaria',
  BURKINA_FASO = 'burkina-faso',
  BURUNDI = 'burundi',
  CAMBODIA = 'cambodia',
  CAMEROON = 'cameroon',
  CANADA = 'canada',
  CANARY_ISLANDS = 'canary-islands',
  CAPE_VERDE = 'cape-verde',
  CAYMAN_ISLANDS = 'cayman-islands',
  CENTRAL_AFRICAN_REPUBLIC = 'central-african-republic',
  CEUTA = 'ceuta',
  CHAD = 'chad',
  CHILE = 'chile',
  CHINA = 'china',
  CHRISTMAS_ISLAND = 'christmas-island',
  COCOS_ISLAND = 'cocos-island',
  COLOMBIA = 'colombia',
  COMOROS = 'comoros',
  COOK_ISLANDS = 'cook-islands',
  CORSICA = 'corsica',
  COSTA_RICA = 'costa-rica',
  CROATIA = 'croatia',
  CUBA = 'cuba',
  CURACAO = 'curacao',
  CYPRUS = 'cyprus',
  CZECH_REPUBLIC = 'czech-republic',
  DEMOCRATIC_REPUBLIC_OF_CONGO = 'democratic-republic-of-congo',
  DENMARK = 'denmark',
  DJIBOUTI = 'djibouti',
  DOMINICA = 'dominica',
  DOMINICAN_REPUBLIC = 'dominican-republic',
  EAST_TIMOR = 'east-timor',
  ECUADOR = 'ecuador',
  EGYPT = 'egypt',
  ENGLAND = 'england',
  EQUATORIAL_GUINEA = 'equatorial-guinea',
  ERITREA = 'eritrea',
  ESTONIA = 'estonia',
  ETHIOPIA = 'ethiopia',
  EUROPEAN_UNION = 'european-union',
  FALKLAND_ISLANDS = 'falkland-islands',
  FAROE_ISLANDS = 'faroe-islands',
  FIJI = 'fiji',
  FINLAND = 'finland',
  FRANCE = 'france',
  FRENCH_POLYNESIA = 'french-polynesia',
  GABON = 'gabon',
  GALAPAGOS_ISLANDS = 'galapagos-islands',
  GAMBIA = 'gambia',
  GEORGIA = 'georgia',
  GERMANY = 'germany',
  GHANA = 'ghana',
  GIBRALTAR = 'gibraltar',
  GREECE = 'greece',
  GREENLAND = 'greenland',
  GRENADA = 'grenada',
  GUAM = 'guam',
  GUATEMALA = 'guatemala',
  GUERNSEY = 'guernsey',
  GUINEA_BISSAU = 'guinea-bissau',
  GUINEA = 'guinea',
  GUYANA = 'guyana',
  HAITI = 'haiti',
  HAWAII = 'hawaii',
  HONDURAS = 'honduras',
  HONG_KONG = 'hong-kong',
  HUNGARY = 'hungary',
  ICELAND = 'iceland',
  INDIA = 'india',
  INDONESIA = 'indonesia',
  IRAN = 'iran',
  IRAQ = 'iraq',
  IRELAND = 'ireland',
  ISLE_OF_MAN = 'isle-of-man',
  ISRAEL = 'israel',
  ITALY = 'italy',
  IVORY_COAST = 'ivory-coast',
  JAMAICA = 'jamaica',
  JAPAN = 'japan',
  JERSEY = 'jersey',
  JORDAN = 'jordan',
  KAZAKHSTAN = 'kazakhstan',
  KENYA = 'kenya',
  KIRIBATI = 'kiribati',
  KOSOVO = 'kosovo',
  KUWAIT = 'kuwait',
  KYRGYZSTAN = 'kyrgyzstan',
  LAOS = 'laos',
  LATVIA = 'latvia',
  LEBANON = 'lebanon',
  LESOTHO = 'lesotho',
  LIBERIA = 'liberia',
  LIBYA = 'libya',
  LIECHTENSTEIN = 'liechtenstein',
  LITHUANIA = 'lithuania',
  LUXEMBOURG = 'luxembourg',
  MACAO = 'macao',
  MADAGASCAR = 'madagascar',
  MADEIRA = 'madeira',
  MALAWI = 'malawi',
  MALAYSIA = 'malaysia',
  MALDIVES = 'maldives',
  MALI = 'mali',
  MALTA = 'malta',
  MARSHALL_ISLAND = 'marshall-island',
  MARTINIQUE = 'martinique',
  MAURITANIA = 'mauritania',
  MAURITIUS = 'mauritius',
  MELILLA = 'melilla',
  MEXICO = 'mexico',
  MICRONESIA = 'micronesia',
  MOLDOVA = 'moldova',
  MONACO = 'monaco',
  MONGOLIA = 'mongolia',
  MONTENEGRO = 'montenegro',
  MONTSERRAT = 'montserrat',
  MOROCCO = 'morocco',
  MOZAMBIQUE = 'mozambique',
  MYANMAR = 'myanmar',
  NAMIBIA = 'namibia',
  NATO = 'nato',
  NAURU = 'nauru',
  NEPAL = 'nepal',
  NETHERLANDS = 'netherlands',
  NEW_ZEALAND = 'new-zealand',
  NICARAGUA = 'nicaragua',
  NIGER = 'niger',
  NIGERIA = 'nigeria',
  NIUE = 'niue',
  NORFOLK_ISLAND = 'norfolk-island',
  NORTH_KOREA = 'north-korea',
  NORTHEN_CYPRUS = 'northen-cyprus',
  NORTHERN_MARIANAS_ISLANDS = 'northern-marianas-islands',
  NORWAY = 'norway',
  OMAN = 'oman',
  ORKNEY_ISLANDS = 'orkney-islands',
  OSSETIA = 'ossetia',
  PAKISTAN = 'pakistan',
  PALAU = 'palau',
  PALESTINE = 'palestine',
  PANAMA = 'panama',
  PAPUA_NEW_GUINEA = 'papua-new-guinea',
  PARAGUAY = 'paraguay',
  PERU = 'peru',
  PHILIPPINES = 'philippines',
  PITCAIRN_ISLANDS = 'pitcairn-islands',
  PORTUGAL = 'portugal',
  PUERTO_RICO = 'puerto-rico',
  QATAR = 'qatar',
  RAPA_NUI = 'rapa-nui',
  REPUBLIC_OF_MACEDONIA = 'republic-of-macedonia',
  REPUBLIC_OF_POLAND = 'republic-of-poland',
  REPUBLIC_OF_THE_CONGO = 'republic-of-the-congo',
  ROMANIA = 'romania',
  RUSSIA = 'russia',
  RWANDA = 'rwanda',
  SABA_ISLAND = 'saba-island',
  SAINT_KITTS_AND_NEVIS = 'saint-kitts-and-nevis',
  SALVADOR = 'salvador',
  SAMOA = 'samoa',
  SAN_MARINO = 'san-marino',
  SAO_TOME_AND_PRINCIPE = 'sao-tome-and-principe',
  SARDINIA = 'sardinia',
  SAUDI_ARABIA = 'saudi-arabia',
  SCOTLAND = 'scotland',
  SENEGAL = 'senegal',
  SERBIA = 'serbia',
  SEYCHELLES = 'seychelles',
  SIERRA_LEONE = 'sierra-leone',
  SINGAPORE = 'singapore',
  SINT_EUSTATIUS = 'sint-eustatius',
  SINT_MAARTEN = 'sint-maarten',
  SLOVAKIA = 'slovakia',
  SLOVENIA = 'slovenia',
  SOLOMON_ISLANDS = 'solomon-islands',
  SOMALIA = 'somalia',
  SOMALILAND = 'somaliland',
  SOUTH_AFRICA = 'south-africa',
  SOUTH_KOREA = 'south-korea',
  SOUTH_SUDAN = 'south-sudan',
  SPAIN = 'spain',
  SRI_LANKA = 'sri-lanka',
  ST_BARTS = 'st-barts',
  ST_LUCIA = 'st-lucia',
  ST_VICENT_AND_GRENADINES = 'st-vicent-and-grenadines',
  SUDAN = 'sudan',
  SURINAME = 'suriname',
  SWAZILAND = 'swaziland',
  SWEDEN = 'sweden',
  SWITZERLAND = 'switzerland',
  SYRIA = 'syria',
  TAIWAN = 'taiwan',
  TAJIKISTAN = 'tajikistan',
  TANZANIA = 'tanzania',
  THAILAND = 'thailand',
  TIBET = 'tibet',
  TOGO = 'togo',
  TOKELAU = 'tokelau',
  TONGA = 'tonga',
  TRANSNISTRIA = 'transnistria',
  TRINIDAD_AND_TOBAGO = 'trinidad-and-tobago',
  TUNISIA = 'tunisia',
  TURKEY = 'turkey',
  TURKMENISTAN = 'turkmenistan',
  TURKS_AND_CAICOS = 'turks-and-caicos',
  TUVALU = 'tuvalu',
  UGANDA = 'uganda',
  UKRAINE = 'ukraine',
  UNITED_ARAB_EMIRATES = 'united-arab-emirates',
  UNITED_KINGDOM = 'united-kingdom',
  UNITED_NATIONS = 'united-nations',
  UNITED_STATES_OF_AMERICA = 'united-states-of-america',
  URUGUAY = 'uruguay',
  UZBEKISTN = 'uzbekistn',
  VANUATU = 'vanuatu',
  VATICAN_CITY = 'vatican-city',
  VENEZUELA = 'venezuela',
  VIETNAM = 'vietnam',
  VIRGIN_ISLANDS = 'virgin-islands',
  WALES = 'wales',
  WESTERN_SAHARA = 'western-sahara',
  YEMEN = 'yemen',
  ZAMBIA = 'zambia',
  ZIMBABWE = 'zimbabwe',
}
