import type { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './conflicts.css';

export interface Props {
  conflict: Extract<
    GQLShiftConflictsFragmentFragment,
    { __typename: 'InsideUnavailableTimeShiftConflict' }
  >;
}

@Component
export default class InsideUnavailableTimeShiftConflict extends TsxComponent<Props> {
  @Prop()
  public conflict: Props['conflict'];

  public render() {
    return (
      <section>
        <h3 class={styles.conflictsItemTitle}>
          {this.$t('conflicts.insideUnavailableTimeShift.title')}
        </h3>
        <p class={styles.conflictsItemText}>
          {this.$t('conflicts.insideUnavailableTimeShift.text')}
        </p>
      </section>
    );
  }
}
