import { TFunction } from 'i18next';
import { RouteConfig, Route as RouteType } from 'vue-router';

export enum Route {
  ROOT = 'employmentsIndex',
  SINGLE_EMPLOYMENT_ROOT = 'singleEmploymentIndex',
  ROTATION_GROUPS = 'employmentRotationGroups',
  CREATE_ASSIGNMENT = 'createAssignment',
  UPDATE_ASSIGNMENT = 'updateAssignment',
}

const routes: RouteConfig[] = [
  {
    path: '/employments',
    name: Route.ROOT,
    meta: {
      title: (t: TFunction) => t('employments.pageTitle'),
    },
    component: () => import('./Employments'),
  },
  {
    path: '/employments/:id',
    name: Route.SINGLE_EMPLOYMENT_ROOT,
    meta: {
      title: (t: TFunction) => t('employments.pageTitle'),
    },
    component: () => import('./single/Single'),
    redirect: { name: Route.ROTATION_GROUPS },
    props: (route: RouteType) => ({
      employmentId: Number.parseInt(route.params.id, 10),
    }),
    children: [
      {
        component: () => import('./rotation-groups/RotationGroups'),
        name: Route.ROTATION_GROUPS,
        path: 'rotation-groups',
        props: (route: RouteType) => ({
          employmentId: Number.parseInt(route.params.id, 10),
        }),
        children: [
          {
            path: 'assignment/create',
            name: Route.CREATE_ASSIGNMENT,
            component: () =>
              import(
                './dialog-rotation-assignment/DialogRotationAssignmentContainer'
              ),
            props: (route: RouteType) => ({
              employmentId: Number.parseInt(route.params.id, 10),
              isUpdate: false,
            }),
          },
          {
            path: 'assignment/:assignmentId(\\d+)',
            name: Route.UPDATE_ASSIGNMENT,
            component: () =>
              import(
                './dialog-rotation-assignment/DialogRotationAssignmentContainer'
              ),
            props: (route: RouteType) => ({
              employmentId: Number.parseInt(route.params.id, 10),
              employmentsRotationGroupId: Number.parseInt(
                route.params.assignmentId,
                10,
              ),
              isUpdate: true,
            }),
          },
        ],
      },
    ],
  },
];

export default routes;
