import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLAbsenceDurationQuery,
  GQLAbsenceDurationQueryVariables,
  GQLAbsencesQuery,
  GQLAbsencesQueryVariables,
  GQLAbsenceState,
  GQLApproveAbsenceMutation,
  GQLApproveAbsenceMutationVariables,
  GQLCalendarAggregation,
  GQLCalendarAggregationsQuery,
  GQLCalendarAggregationsQueryVariables,
  GQLCreateAbsenceMutation,
  GQLCreateAbsenceMutationVariables,
  GQLDeclineAbsenceMutation,
  GQLDeclineAbsenceMutationVariables,
  GQLDeleteAbsenceMutation,
  GQLDeleteAbsenceMutationVariables,
  GQLEmploymentStatus,
  GQLFetchAbsenceQuery,
  GQLFetchAbsenceQueryVariables,
  GQLFetchAllAbsencesQuery,
  GQLFetchAllAbsencesQueryVariables,
  GQLUpdateAbsenceMutation,
  GQLUpdateAbsenceMutationVariables,
} from 'codegen/gql-types';
import TableAction from 'components/table/store/Action';
import getTableStore, {
  StoreState as TableStoreState,
} from 'components/table/store/Store';
import { SortDirection } from 'components/table/types';
import { deepTransformDates } from 'services/graphql-client/DatesTransformLink';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import RestClient from 'services/rest-client/RestClient';
import {
  PayloadParameter,
  StoreActionResult,
  StoreActionState,
} from 'utils/store';
import { endOfYear, startOfYear } from 'date-fns';
import { HasAnyRightFunction } from 'components/auth/store/Store';
import type { CanManageShiftFunction } from 'src/store/shifts/Store';
import {
  ActionProvider,
  createNormalizedStore,
  Action,
  Mutation,
  ById,
  WithCachePayloadParameter,
} from 'src/store/normalized-store';
import AbsencesGql from '../queries/Absences.gql';
import AbsenceDurationGql from '../queries/AbsenceDuration.gql';
import ApproveAbsenceGql from '../queries/ApproveAbsence.gql';
import CreateAbsenceGql from '../queries/CreateAbsence.gql';
import DeclineAbsenceGql from '../queries/DeclineAbsence.gql';
import DeleteAbsenceGql from '../queries/DeleteAbsence.gql';
import FetchAbsenceGql from '../queries/FetchAbsence.gql';
import FetchAllAbsencesGql from '../queries/FetchAllAbsences.gql';
import UpdateAbsenceGql from '../queries/UpdateAbsence.gql';
import CalendarAggregationsGql from '../queries/CalendarAggregations.gql';
import AbsencesAction from './Action';
import AbsencesMutation from './Mutations';
import { TimeframeFilterKind } from '../types';
import type { Absence } from '../types';
import getAbsencesSummaryStore, {
  StoreState as SummaryStoreState,
} from '../summary/store/Store';

export const absencesNS = namespace('absences');
export const absencesTableNS = namespace('absences/table');

export interface CalendarAggregationsState {
  aggregationsItems: GQLCalendarAggregation[];
}

export type StoreState = ById<Absence> & CalendarAggregationsState;

export interface ModuleState extends StoreState {
  summary: SummaryStoreState;
  table: TableStoreState<Absence, Filters>;
}

export enum Sort {
  STARTS_AT = 'starts_at',
}

export interface Filters {
  absenceReasonIds?: number[];
  employmentIds?: number[];
  employmentStatuses: GQLEmploymentStatus[];
  endsAt: Date;
  startsAt: Date;
  paid?: boolean;
  states?: GQLAbsenceState[];
  withAttachment?: boolean;
  locationIds: number[];
  timeframeKind: TimeframeFilterKind;
}

export type CreateAbsenceFunction = (
  payload: WithCachePayloadParameter<
    Omit<GQLCreateAbsenceMutationVariables, 'companyId' | 'absence'> & {
      absence: Omit<GQLCreateAbsenceMutationVariables['absence'], 'companyId'>;
    }
  >,
) => Promise<StoreActionResult>;

export type DeleteAbsenceFunction = (
  payload: GQLDeleteAbsenceMutationVariables,
) => Promise<StoreActionResult>;

export type FetchAbsenceFunction = (
  payload: Omit<GQLFetchAbsenceQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchAllAbsencesFunction = (
  payload: WithCachePayloadParameter<
    Omit<GQLFetchAllAbsencesQueryVariables, 'companyId' | 'locationId'>
  >,
) => Promise<StoreActionResult>;

export type UpdateAbsenceFunction = (
  payload: Omit<GQLUpdateAbsenceMutationVariables, 'companyId' | 'absence'> & {
    absence: Omit<GQLUpdateAbsenceMutationVariables['absence'], 'companyId'>;
  },
) => Promise<StoreActionResult>;

export type CanManageAbsenceFunction = (
  locationId: number,
  locationsPositionIds: number[],
) => boolean;

export type ApproveAbsenceFunction = (
  payload: Omit<GQLApproveAbsenceMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeclineAbsenceFunction = (
  payload: Omit<GQLDeclineAbsenceMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type GetAbsenceDurationFunction = (
  payload: Omit<GQLAbsenceDurationQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type GetCalendarAggregationsFunction = (
  payload: Omit<
    GQLCalendarAggregationsQueryVariables,
    'companyId' | 'locationId'
  >,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getAbsencesStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  restClient: RestClient,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
    state: {
      aggregationsItems: [],
    },
    actions: {
      [AbsencesAction.APPROVE]: async (
        { rootState, dispatch, commit, getters },
        payload: PayloadParameter<ApproveAbsenceFunction>,
      ): ReturnType<ApproveAbsenceFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const result = await graphqlClient.mutate<
            GQLApproveAbsenceMutation,
            GQLApproveAbsenceMutationVariables
          >({
            mutation: ApproveAbsenceGql,
            variables: {
              forceCollision: !!payload.forceCollision,
              forceCollisionAndRemove: !!payload.forceCollisionAndRemove,
              absenceId: payload.absenceId,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          const { approveAbsence } = result.data || {};

          if (approveAbsence && 'conflicts' in approveAbsence) {
            return {
              state: StoreActionState.CONFLICT,
              conflicts: approveAbsence.conflicts,
              canManage: approveAbsence.canManage,
            };
          }

          await dispatch(`table/${TableAction.REFETCH}`);
          const approvedAbsence: Absence = getters.getById(payload.absenceId);

          commit(Mutation.SET_ITEM, {
            item: { ...approvedAbsence, state: GQLAbsenceState.ACCEPTED },
          });

          return { state: StoreActionState.SUCCESS };
        } catch (e) {
          logger.instance.error(e);
        }
        return { state: StoreActionState.ERROR };
      },
      [AbsencesAction.DECLINE]: async (
        { dispatch, rootState, commit, getters },
        payload: PayloadParameter<DeclineAbsenceFunction>,
      ): ReturnType<DeclineAbsenceFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          const result = await graphqlClient.mutate<
            GQLDeclineAbsenceMutation,
            GQLDeclineAbsenceMutationVariables
          >({
            mutation: DeclineAbsenceGql,
            variables: {
              absenceId: payload.absenceId,
              refuseMessage: payload.refuseMessage || null,
              companyId: rootState.auth.currentCompanyId,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          await dispatch(`table/${TableAction.REFETCH}`);
          const declinedAbsence: Absence = getters.getById(payload.absenceId);

          commit(Mutation.SET_ITEM, {
            item: { ...declinedAbsence, state: GQLAbsenceState.REFUSED },
          });

          return {
            state: StoreActionState.SUCCESS,
          };
        } catch (ex) {
          logger.instance.error(ex);
        }

        await dispatch(`table/${TableAction.REFETCH}`);

        return { state: StoreActionState.ERROR };
      },
      [AbsencesAction.GET_CALENDAR_AGGREGATIONS]: async (
        { rootState, commit },
        payload: PayloadParameter<GetCalendarAggregationsFunction>,
      ): ReturnType<GetCalendarAggregationsFunction> => {
        try {
          if (!rootState.auth.currentCompanyId) {
            return { state: StoreActionState.ERROR };
          }

          if (!rootState.auth.currentLocationId) {
            return { state: StoreActionState.ERROR };
          }

          const result = await graphqlClient.query<
            GQLCalendarAggregationsQuery,
            GQLCalendarAggregationsQueryVariables
          >({
            query: CalendarAggregationsGql,
            variables: {
              ...payload,
              companyId: rootState.auth.currentCompanyId,
              locationId: rootState.auth.currentLocationId,
            },
            context: {
              useBatching: true,
            },
          });

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          commit(
            AbsencesMutation.SET_AGGREGATIONS,
            result.data?.calendarAggregations,
          );

          return {
            state: StoreActionState.SUCCESS,
          };
        } catch (ex) {
          logger.instance.error(ex);
        }

        return { state: StoreActionState.ERROR };
      },
      async [AbsencesAction.GET_ABSENCE_DURATION](
        { rootState },
        payload: PayloadParameter<GetAbsenceDurationFunction>,
      ): ReturnType<GetAbsenceDurationFunction> {
        if (!rootState.auth.currentCompanyId) {
          return { state: StoreActionState.ERROR };
        }

        const variables = {
          companyId: rootState.auth.currentCompanyId,
          days: typeof payload.days !== 'number' ? null : payload.days,
          employmentId: payload.employmentId,
          endsAt: payload.endsAt,
          reasonId: payload.reasonId,
          startsAt: payload.startsAt,
        };

        try {
          /* eslint-disable @typescript-eslint/indent */
          const result = await graphqlClient.query<
            GQLAbsenceDurationQuery,
            GQLAbsenceDurationQueryVariables
          >({
            query: AbsenceDurationGql,
            variables,
          });
          /* eslint-enable @typescript-eslint/indent */

          if (result.errors?.length) {
            return {
              state: StoreActionState.ERROR,
              error: result.errors[0].extensions?.response,
            };
          }

          return {
            state: StoreActionState.SUCCESS,
            meta: {
              days: result.data.absenceDuration?.absenceDays,
              hours: result.data.absenceDuration?.absenceHours,
            },
          };
        } catch (ex) {
          logger.instance.error(ex);
        }

        return { state: StoreActionState.ERROR };
      },
      async [AbsencesAction.UPLOAD_ATTACHMENT](
        _,
        payload: { absenceId: number; file: File },
      ) {
        return restClient.postFormData(
          `/api/v1/absences/${payload.absenceId}/attachment`,
          {
            file: payload.file,
          },
        );
      },
    },
    mutations: {
      [AbsencesMutation.SET_AGGREGATIONS](state, items) {
        state.aggregationsItems = items;
      },
    },
    modules: {
      table: getTableStore<
        Absence,
        Sort,
        Filters,
        GQLAbsencesQuery,
        GQLAbsencesQueryVariables,
        {}
      >(graphqlClient, {
        query: AbsencesGql,
        getVariables: (rootState) => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          companyId: rootState.auth.currentCompanyId!,
        }),
        transformResponse: (response) => {
          const {
            absences: {
              items: data,
              pagination: { count },
            },
          } = response.data;

          return {
            data,
            count,
          };
        },
        initialState: {
          perPage: 10,
          sort: {
            direction: SortDirection.DESC,
            key: Sort.STARTS_AT,
          },
          filters: {
            timeframeKind: TimeframeFilterKind.CURRENT_YEAR,
            // FIXME: need to find a way to pass the company time zone here
            // eslint-disable-next-line no-restricted-syntax
            startsAt: startOfYear(new Date()),
            // FIXME: need to find a way to pass the company time zone here
            // eslint-disable-next-line no-restricted-syntax
            endsAt: endOfYear(new Date()),
          },
        },
      }),
      summary: getAbsencesSummaryStore(graphqlClient, logger),
    },
    getters: {
      canManageAbsence: (state, getters, rootState, rootGetters) => {
        return (
          locationId: number,
          locationsPositionIds: number | number[],
        ) => {
          const isSuperAdmin = rootGetters['auth/isSuperAdmin'];
          const isStakeholder = rootGetters['auth/isStakeholder'];
          const hasAnyRight: HasAnyRightFunction =
            rootGetters['auth/hasAnyRight'];
          /* user is "manageable" if it can be scheduled = right to manage shifts */
          const canManageShift: CanManageShiftFunction =
            rootGetters['shifts/canManageShift'];

          return (
            isSuperAdmin ||
            (isStakeholder &&
              (hasAnyRight('absences_manage') ||
                (hasAnyRight('absences_manage_managed_users') &&
                  canManageShift(locationId, locationsPositionIds))))
          );
        };
      },
      aggregationsByDates(state, rootState, getters, rootGetters) {
        return rootGetters['calendarAbsences/common/dateKeys'].reduce(
          (acc, date) => {
            acc[date] = state.aggregationsItems.find(
              (item) => item.date === date,
            ) || {
              demand: 0,
              working: 0,
              absent: 0,
            };
            return acc;
          },
          {},
        );
      },
    },
  };

  const fetch: ActionProvider<
    GQLFetchAbsenceQuery,
    GQLFetchAbsenceQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAbsenceFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: FetchAbsenceGql,
      resultKey: 'absences',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const fetchAll: ActionProvider<
    GQLFetchAllAbsencesQuery,
    GQLFetchAllAbsencesQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllAbsencesFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    if (!rootState.auth.currentLocationId) {
      throw new TypeError('currentLocationId not provided');
    }

    return {
      query: FetchAllAbsencesGql,
      resultKey: 'absences',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
        locationId: rootState.auth.currentLocationId,
      },
      useBatching: true,
    };
  };

  const create: ActionProvider<
    GQLCreateAbsenceMutation,
    GQLCreateAbsenceMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateAbsenceFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = deepTransformDates({
      ...payload,
      absence: {
        ...payload.absence,
        companyId: rootState.auth.currentCompanyId,
      },
    });

    return {
      resultKey: 'createAbsence',
      query: CreateAbsenceGql,
      variables,
      useBatching: true,
    };
  };

  const remove: ActionProvider<
    GQLDeleteAbsenceMutation,
    GQLDeleteAbsenceMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteAbsenceFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = {
      id: payload.id,
    };

    return {
      resultKey: 'deleteAbsence',
      query: DeleteAbsenceGql,
      variables,
      useBatching: true,
    };
  };

  const update: ActionProvider<
    GQLUpdateAbsenceMutation,
    GQLUpdateAbsenceMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateAbsenceFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    const variables = deepTransformDates({
      ...payload,
      absence: {
        ...payload.absence,
        companyId: rootState.auth.currentCompanyId,
      },
    });

    return {
      query: UpdateAbsenceGql,
      resultKey: 'updateAbsence',
      variables,
    };
  };

  return createNormalizedStore<
    Absence,
    CalendarAggregationsState,
    RootStoreState
  >({
    store,
    provide: {
      [Action.FETCH]: fetch,
      [Action.FETCH_ALL]: fetchAll,
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getAbsencesStore;
