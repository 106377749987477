export type MouseTrackerSubscriber = (steps: number) => void;
class MouseMoveTracker {
  private moveCache = 0;

  private previousPosition: number;

  private onMouseMoveBinded: EventListener;

  private stepSize: number;

  private onStep: MouseTrackerSubscriber;

  public constructor(stepSize: number, onStep: MouseTrackerSubscriber) {
    this.stepSize = stepSize;
    this.onStep = onStep;
    this.onMouseMoveBinded = this.onMouseMove.bind(this);
    document.addEventListener('mousemove', this.onMouseMoveBinded);
  }

  private onMouseMove(e: MouseEvent) {
    const { clientY: currentPosition } = e;
    if (this.previousPosition !== undefined) {
      this.moveCache += currentPosition - this.previousPosition;
      const moveSteps: number = Math.floor(this.moveCache / this.stepSize);
      if (Math.abs(moveSteps) > 0) {
        // reset moveCache
        this.moveCache -= moveSteps * this.stepSize;
        // should use constant for time step here
        this.onStep(moveSteps);
      }
    }
    this.previousPosition = currentPosition;
  }

  public destroy() {
    document.removeEventListener('mousemove', this.onMouseMoveBinded);
  }
}

export default MouseMoveTracker;
