import DropdownLegacy from 'components/dropdown-legacy/DropdownLegacy';
import { Component } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import {
  authNS,
  StoreState as AuthStoreState,
} from 'components/auth/store/Store';
import type {
  HasAnyRightFunction,
  LogoutFunction,
} from 'components/auth/store/Store';
import ChatButton from 'components/main-view/chat-button/ChatButton';
import { getUrlWithApiPrefix, getUrlWithCompanyPrefix } from 'utils/url';
import Avatar from 'components/avatar/Avatar';
import { Size } from 'components/types';
import AvatarDefault from 'components/avatar/AvatarDefault';
import { Route } from 'components/shift-schedule/routes';
import { RestError, RestResponse } from 'services/rest-client/RestClient';
import LocationDropdown from '../location-dropdown/LocationDropdown';
import { mainViewNS } from '../store/Store';
import Action from '../store/Action';
import styles from './header.css';

@Component
export default class Header extends TsxComponent<{}> {
  @authNS.Getter
  protected isSuperAdmin: boolean;

  @authNS.State
  protected currentCompany: AuthStoreState['currentCompany'];

  @authNS.State
  protected currentEmployment: AuthStoreState['currentEmployment'];

  @authNS.State
  protected currentUserId: AuthStoreState['currentUserId'];

  @authNS.Getter
  public hasAnyRight: HasAnyRightFunction;

  @authNS.Action
  public logout: LogoutFunction;

  @mainViewNS.Getter
  protected isChatAllowed: boolean;

  @mainViewNS.Action(Action.START_PUNCH_TIME)
  protected startPunchTime: () => Promise<RestError | RestResponse<unknown>>;

  protected get isLocationsDropdownVisible() {
    const routesWithLocationsDropdown: string[] = [
      Route.ROOT,
      Route.SHIFTPLAN,
      Route.PRINT_SHIFTPLAN,
      Route.ROOT_ABSENCES,
    ];
    return this.$route.matched.some(
      (it) => it.name && routesWithLocationsDropdown.includes(it.name),
    );
  }

  protected get isStartPunchTimeVisible() {
    return (
      this.isSuperAdmin ||
      (!!this.currentEmployment?.isStakeholder &&
        this.hasAnyRight('punch_timing_start'))
    );
  }

  protected async onPunchTimeClick() {
    const response = await this.startPunchTime();
    if (response.body.success) {
      this.logout();
      window.location.replace(getUrlWithApiPrefix('/punch_timings'));
    }
  }

  public render() {
    return (
      <header class={styles.header}>
        {this.isLocationsDropdownVisible && (
          <LocationDropdown class={styles.headerDropdown} />
        )}
        <DropdownLegacy
          class={styles.headerDropdown}
          id="header-dropdown"
          items={[
            {
              label: this.$t('header.profile'),
              href: getUrlWithApiPrefix(
                'users',
                this.currentUserId?.toString() || '0',
              ),
            },
            this.isSuperAdmin
              ? {
                  label: this.$t('header.companyProfile'),
                  href: getUrlWithApiPrefix(
                    getUrlWithCompanyPrefix('', this.currentCompany),
                  ),
                }
              : undefined,
            this.isStartPunchTimeVisible
              ? {
                  label: this.$t('header.startPunchTiming'),
                  onClick: this.onPunchTimeClick,
                }
              : undefined,
            'ruler',
            {
              label: this.$t('header.logout'),
              href: getUrlWithApiPrefix('logout'),
            },
          ]}
        >
          <div class={styles.headerDropdownInner}>
            {this.currentEmployment ? (
              <Avatar
                size={Size.SMALL}
                firstName={this.currentEmployment?.firstName}
                lastName={this.currentEmployment?.lastName}
                imageUrl={this.currentEmployment?.pictureData?.pictureSmall}
              />
            ) : (
              <AvatarDefault size={Size.SMALL} />
            )}
            <span class={styles.headerUser}>
              {this.currentEmployment?.firstName}{' '}
              {this.currentEmployment?.lastName}
            </span>
          </div>
        </DropdownLegacy>
        {this.isChatAllowed && <ChatButton />}
      </header>
    );
  }
}
