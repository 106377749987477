import type { GQLSuccessResponse } from 'codegen/gql-types';
import { ShiftPreset } from 'store/shift-presets/Store';
import { GQLResponseExtension } from 'utils/store';

export type RESTING_DAY = 'restingDay';
export const RESTING_DAY: RESTING_DAY = 'restingDay';
export type RotationDayPreset =
  | Pick<ShiftPreset, 'color' | 'id' | 'shortName' | 'startsAt' | 'name'>
  | RESTING_DAY
  | undefined;

export interface RotationGroupInput {
  id?: number;
  name: string;
  days: RotationDayPreset[];
}

export interface RotationGroup {
  id: number;
  name: string;
  employmentsLength: number;
  days: RotationDayPreset[];
}

export type CreateRotationResponse = number | undefined | GQLResponseExtension;

export type UpdateRotationResponse = number | undefined | GQLResponseExtension;

export type SuccessOrResponse = GQLSuccessResponse | GQLResponseExtension;
