import SpIcon from 'components/sp-icon/SpIcon';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from './support-banner.css';

@Component
export default class SupportBanner extends TsxComponent<
  {
    isOpen?: boolean;
  },
  {
    onCloseClick: (e: MouseEvent) => void;
  }
> {
  public $refs: {
    ref: HTMLDivElement;
  };

  @Prop()
  public isOpen?: boolean;

  @Emit('closeClick')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onCloseClick() {}

  @Watch('isOpen')
  protected onIsOpenChange(state: boolean) {
    if (state) {
      window.addEventListener('click', this.onWindowClick);
    } else {
      window.removeEventListener('click', this.onWindowClick);
    }
  }

  protected onWindowClick(e: MouseEvent) {
    const target = e.target as HTMLElement;

    if (!this.$refs.ref.contains(target)) {
      this.onCloseClick();
    }
  }

  public beforeDestroy() {
    // just in case it somehow has not been removed
    window.removeEventListener('click', this.onWindowClick);
  }

  public render() {
    return (
      <transition
        enter-class={styles.transitionSlideEnter}
        enter-to-class={styles.transitionSlideEnterTo}
        leave-class={styles.transitionSlideLeave}
        leave-to-class={styles.transitionSlideLeaveTo}
      >
        {this.isOpen && (
          <div class={styles.supportBanner} ref="ref">
            <span
              class={styles.supportBannerText}
              domPropsInnerHTML={this.$t('supportBanner')}
            />
            <button
              type="button"
              class={styles.supportBannerButton}
              onClick={this.onCloseClick}
            >
              <SpIcon name="clear" class={styles.supportBannerIcon} />
            </button>
          </div>
        )}
      </transition>
    );
  }
}
