import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { GQLShiftConflictsFragmentFragment } from 'codegen/gql-types';
import { createEventPayload, EventPayload } from 'utils/events';
import Prompt, { Slot as PromptSlot } from 'components/prompt/Prompt';
import Button from 'components/form/button/Button';
import { ButtonColor, ButtonKind } from 'components/form/base-button/types';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import DialogManager from 'components/dialog/DialogManager';
import Tooltip from 'components/tooltip/Tooltip';
import Conflicts from '../conflicts/Conflicts';
import styles from './dialog-shift-conflicts.css';

export type DialogManagerShiftsConflict = DialogManager<
  { ignoreAll: boolean },
  {
    isOpen: boolean;
    conflicts: GQLShiftConflictsFragmentFragment[];
    shiftDetailsLabel?: string;
    showIgnoreAll?: boolean;
  }
>;

export interface Props {
  conflicts: GQLShiftConflictsFragmentFragment[];
  isOpen?: boolean;
  isSelf?: boolean;
  isSubmitting?: boolean;
  shiftDetailsLabel?: string;
  showIgnoreAll?: boolean;
}

@Component
export default class DialogShiftConflicts extends TsxComponent<
  Props,
  {
    onCloseClick: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>;
    onConfirm: EventPayload<void, HTMLButtonElement, MouseEvent>;
    onIgnoreAll: EventPayload<void, HTMLButtonElement, MouseEvent>;
  }
> {
  @Prop({ default: () => [] })
  protected conflicts: Props['conflicts'];

  @Prop()
  public isOpen?: Props['isOpen'];

  @Prop()
  public isSelf?: Props['isSelf'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public shiftDetailsLabel?: Props['shiftDetailsLabel'];

  @Prop()
  public showIgnoreAll?: Props['showIgnoreAll'];

  protected get hasIgnorableConflictsOnly() {
    return this.conflicts.every(
      (conflict) => 'ignorable' in conflict && conflict.ignorable,
    );
  }

  private get cancelButtonText() {
    if (this.hasIgnorableConflictsOnly) {
      return this.$t('modal.buttonCancel');
    }
    return this.$t('conflicts.buttonSkip');
  }

  protected onCancelClick(e) {
    this.$emit('closeClick', createEventPayload(e, undefined));
  }

  protected onApproveAndIgnoreClick(e) {
    this.$emit('confirm', createEventPayload(e, undefined));
  }

  protected onIgnoreAll(e) {
    this.$emit('ignoreAll', createEventPayload(e, undefined));
  }

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={this.onCancelClick}
        title={this.$t('conflicts.title')}
      >
        {this.shiftDetailsLabel && <span>{this.shiftDetailsLabel}</span>}

        <Conflicts conflicts={this.conflicts} isSelf={this.isSelf} />

        <template slot={PromptSlot.ACTION_BUTTONS}>
          <div class={styles.dialogShiftConflictsButtons}>
            <Button
              type="button"
              color={ButtonColor.SECONDARY}
              kind={ButtonKind.STROKE}
              onClick={this.onCancelClick}
            >
              {this.cancelButtonText}
            </Button>

            <div class={styles.dialogShiftConflictsButtonsPrimary}>
              {this.hasIgnorableConflictsOnly && (
                <Button
                  type="button"
                  color={ButtonColor.WARNING}
                  kind={ButtonKind.FILL}
                  disabled={this.isSubmitting}
                  onClick={this.onApproveAndIgnoreClick}
                >
                  {this.$t('conflicts.buttonApproveAndIgnore')}
                </Button>
              )}
              {this.hasIgnorableConflictsOnly && this.showIgnoreAll && (
                <Tooltip text={this.$t('conflicts.ignoreAllTooltip')}>
                  <Button
                    data-test-id="dialog-shift-conflicts__button-ignore-all"
                    type="button"
                    color={ButtonColor.WARNING}
                    kind={ButtonKind.FILL}
                    disabled={this.isSubmitting}
                    onClick={this.onIgnoreAll}
                  >
                    {this.$t('conflicts.buttonIgnoreAll')}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </template>
      </Prompt>
    );
  }
}
