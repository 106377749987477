import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import { SyntheticEvent } from 'vue-tsx-support/types/dom';
import Icon from 'components/icons/Icon';
import { hexToRgba } from 'src/utils/color';
import { Size } from 'components/types';
import { IconName } from 'components/icons/types';
import styles from './badge.css';

export enum BadgePreset {
  ERROR = 'error',
  INFO = 'info',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
  PRIMARY = 'primary',
}

export type BadgeSize = Size.XSMALL | Size.SMALL | Size.MEDIUM;
export interface BadgeProps {
  icon?: IconName;
  size?: BadgeSize;
  preset?: BadgePreset;
  hasDot?: boolean;
  isClosable?: boolean;
  customColor?: string;
}

const BADGE_SIZE_MAP: Record<BadgeSize, string> = {
  [Size.MEDIUM]: styles.badgeSizeMedium,
  [Size.SMALL]: styles.badgeSizeSmall,
  [Size.XSMALL]: styles.badgeSizeExtraSmall,
};

const BADGE_KIND_MAP: Record<BadgePreset, string> = {
  [BadgePreset.PRIMARY]: styles.badgePresetPrimary,
  [BadgePreset.INFO]: styles.badgePresetInfo,
  [BadgePreset.WARNING]: styles.badgePresetWarning,
  [BadgePreset.NEUTRAL]: '',
  [BadgePreset.ERROR]: styles.badgePresetError,
  [BadgePreset.SUCCESS]: styles.badgePresetSuccess,
};

export interface BadgeEvents {
  onCloseClick: (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
}

@Component
export default class Badge extends TsxComponent<BadgeProps, BadgeEvents> {
  @Prop()
  public icon: BadgeProps['icon'];

  @Prop({ default: Size.MEDIUM })
  public size: NonNullable<BadgeProps['size']>;

  @Prop({ default: BadgePreset.NEUTRAL })
  public preset: NonNullable<BadgeProps['preset']>;

  @Prop({ default: false })
  public hasDot: NonNullable<BadgeProps['hasDot']>;

  @Prop({ default: false })
  public isClosable: NonNullable<BadgeProps['isClosable']>;

  @Prop()
  public customColor: BadgeProps['customColor'];

  public render() {
    return (
      <div
        style={
          this.customColor
            ? {
                '--text-color': this.customColor,
                '--badge-color': hexToRgba(this.customColor, 0.2),
                '--close-background': hexToRgba(this.customColor, 0.4),
                '--close-active-color': 'white',
              }
            : null
        }
        class={[
          styles.badge,
          BADGE_SIZE_MAP[this.size],
          BADGE_KIND_MAP[this.preset],
        ]}
      >
        {this.hasDot ? <span class={styles.badgeDot}></span> : null}
        {this.icon ? (
          <Icon name={this.icon} class={styles.badgeIcon} size={this.size} />
        ) : null}
        <span class={styles.badgeLabel}>{this.$slots.default}</span>
        {this.isClosable ? (
          // TODO: use a replace it with a proper IconButton as soon we have one
          <button
            aria-label="close"
            type="button"
            class={styles.badgeClose}
            onClick={(event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
              this.$emit('closeClick', event);
            }}
          >
            <Icon name={IconName.CLEAR} size={this.size} />
          </button>
        ) : null}
      </div>
    );
  }
}
