import { GQLAbsenceReason, GQLAbsenceState } from 'codegen/gql-types';
import Employment from 'components/calendar-common/employments/Employment';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import { getDateString } from 'src/utils/date-related';

class Absence {
  public id: number;

  public note: string;

  public employment: Employment;

  public reason: GQLAbsenceReason;

  public state: GQLAbsenceState;

  public date: string;

  public isManageable: boolean;

  public uniqId: string;

  public constructor({
    id,
    canManage,
    employment,
    reason,
    state,
    date,
    notes,
  }) {
    this.id = id;
    this.isManageable = canManage;
    this.employment = employment;
    this.reason = reason;
    this.state = state;
    // always 1 day, so don't care about endsAt
    this.date = getDateString(
      new Date(date),
      VueTimeZoneProvider.getTimeZone(),
    );
    this.note = notes;
    this.uniqId = `${id}-${employment.id}`;
  }
}

export default Absence;
