import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { CalendarNamespace, Mode } from 'components/calendar-common/Enums';
import Mutation from './Mutation';
import Action from './Action';

export const calendarPrintFiltersNS = namespace(
  `${CalendarNamespace.PRINT}/filters`,
);

export interface PrintFilters {
  showNewAbsences: boolean;
  showAcceptedAbsences: boolean;
  showNotes: boolean;
  showPositionNotes: boolean;
}

export type StoreState = {
  selectedLocationsPositionIds: number[];
  absenceReasonsIds: number[];
  filters: PrintFilters;
};

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    selectedLocationsPositionIds: [],
    absenceReasonsIds: [],
    filters: {
      showNewAbsences: false,
      showAcceptedAbsences: false,
      showNotes: false,
      showPositionNotes: false,
    },
  },
  mutations: {
    [Mutation.SET_ABSENCE_REASONS_IDS](state, items: number[]) {
      state.absenceReasonsIds = items;
    },
    [Mutation.SET_LOCATIONS_POSITION_IDS](state, items: number[]) {
      state.selectedLocationsPositionIds = items;
    },
    [Mutation.SET_FILTERS](state, filters: PrintFilters) {
      state.filters = filters;
    },
  },
  actions: {
    [Action.SET_FILTERS]({ commit }, filters: PrintFilters) {
      commit(Mutation.SET_FILTERS, filters);
    },
    [Action.SET_ABSENCE_REASONS_IDS]({ commit }, ids: number[]) {
      commit(Mutation.SET_ABSENCE_REASONS_IDS, ids);
    },
    [Action.SET_LOCATIONS_POSITION_IDS]({ commit }, ids: number[]) {
      commit(Mutation.SET_LOCATIONS_POSITION_IDS, ids);
    },
  },
  getters: {
    filters(state) {
      return state.filters;
    },
    // return null for locationPosition filter for employees
    locationsPositionIds(state, rootState, getters, rootGetters) {
      const mode: Mode = rootGetters['calendar/common/mode'];
      return mode === Mode.EMPLOYEE ? [] : state.selectedLocationsPositionIds;
    },
  },
});
