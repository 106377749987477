import { RouteConfig, Route as RouteType } from 'vue-router';

export enum Route {
  ROOT = 'shiftplanNotification',
}

const routes: RouteConfig[] = [
  {
    name: Route.ROOT,
    component: () => import('./DialogShiftplanNotifications'),
    path: 'notifications-message',
    props: (route: RouteType) => ({
      shiftplanId: Number.parseInt(route.params.shiftplanId, 10),
    }),
  },
];

export default routes;
