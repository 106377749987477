import cookies from 'browser-cookies';
import getAbsencesStore from 'components/absences/store/Store';
import getAuthStore from 'components/auth/store/Store';
import getCalendarStore from 'components/calendar/Store';
import getSectionPayStore from 'components/section-pay/store/Store';
import getRotationGroupsStore from 'components/employments/rotation-groups/store/Store';
import getEmploymentsStore from 'components/employments/store/Store';
import getMainViewStore from 'components/main-view/store/Store';
import getRotationWizardStore from 'components/rotation-wizard/store/Store';
import getShiftScheduleStore from 'components/shift-schedule/store/Store';
import getSnackbarStore from 'components/snackbar/store/Store';
import GraphqlClientFactory, {
  GraphqlClient,
} from 'services/graphql-client/GraphqlClientFactory';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import RestClient from 'services/rest-client/RestClient';
import SecureLocalStorage from 'services/secure-local-storage/SecureLocalStorage';
import VuexSentryPlugin from 'src/store/SentryPlugin';
import getTagsStore from 'store/tags/Store';
import getShiftEvaluationTagsStore from 'store/shift-evaluation-tags/Store';
import Vuex, { Store } from 'vuex';
import VuexPersistence, { PersistOptions } from 'vuex-persist';
import getSectionBreaksStore from 'components/evaluation/section-breaks/store/Store';
import getCalendarAbsencesStore from 'components/calendar-absences/Store';
import getCalendarPrintStore from 'components/calendar-print/store';
import getAvailabiltyAggregationsStore from 'components/availability-aggregations/store/Store';
import RootStoreState from './RootStoreState';
import getAbsenceReasonsStore from './absence-reasons/Store';
import getAssignmentGroupsStore from './assignment-groups/Store';
import getBackgroundJobsStore from './background-jobs/Store';
import getDayNotesStore from './day-notes/Store';
import getEmploymentEvaluationBreaksStore from './employment-evaluation-breaks/Store';
import getEmploymentEvaluationPaymentsStore from './employment-evaluation-payments/Store';
import getEmploymentEvaluationsStore from './employment-evaluations/Store';
import getEvaluationBreaksStore from './evaluation-breaks/Store';
import getEvaluationPaymentsStore from './evaluation-payments/Store';
import getEvaluationsStore from './evaluations/Store';
import getLocationsPositionsStore from './locations-positions/Store';
import getLocationsStore from './locations/Store';
import getPaygradeTypesStore from './paygrade-types/Store';
import getPaymentsCalculationStore from './payments-calculation/Store';
import getPositionsStore from './positions/Store';
import getQualificationsStore from './qualifications/Store';
import getShiftAssignmentGroupsStore from './shift-assignment-groups/Store';
import getShiftAvailableEmploymentsStore from './shift-available-employments/Store';
import getShiftPaygradesStore from './shift-paygrades/Store';
import getShiftplanNotificationsStore from './shiftplan-notifications/Store';
import getShiftplansStore from './shiftplans/Store';
import getShiftPresetStore from './shift-presets/Store';
import getShiftRotationsStore from './shift-rotations/Store';
import getShiftsStore from './shifts/Store';
import getStaffShiftsStore from './staff-shifts/Store';
import getStaffShiftsTagsStore from './staff-shifts-tags/Store';
import getUiSettingsStore from './ui-settings/Store';
import getDragAndDropStore from './drag-and-drop/store';
import getShiftEmploymentsFilterStore from './shift-employment-filter/Store';
import getShiftplanPublishSettingsFilterStore from './shiftplan-publish-settings/Store';
import getSpecialDatesStore from './special-dates/Store';
import getBulkRequestStore from './bulk-requests/Store';

export type ApplicationStore = Store<RootStoreState>;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends {} ? DeepPartial<T[P]> : T[P];
};

type RestoredStoreState = {
  [key: string]:
    | string
    | number
    | boolean
    | RestoredStoreState[]
    | RestoredStoreState;
};
const restoreDates = (state: RestoredStoreState, dateKeys: string[]) =>
  Object.fromEntries(
    Object.entries(state).map(([key, value]) => {
      if (value === null || value === undefined) {
        return [key, value];
      }
      if (Array.isArray(value)) {
        return [key, value];
      }

      if (value instanceof Object) {
        return [key, restoreDates(value, dateKeys)];
      }

      if (typeof value === 'string') {
        if (dateKeys.includes(key)) {
          try {
            const date = new Date(value);
            return [key, date];
          } catch (e) {
            return [key, value];
          }
        }
      }

      return [key, value];
    }),
  );

export const restoreState =
  (logger: ApplicationLogger) => (key: string, storage?: Storage) => {
    try {
      const parsed = JSON.parse(storage?.getItem(key) || '{}');
      const restoredState = restoreDates(parsed, ['startsAt', 'endsAt']);
      const legacy = JSON.parse(atob(cookies.get('sppt_web') || 'e30='));

      if (
        !(
          'currentUserId' in legacy &&
          'currentCompanyId' in legacy &&
          'currentLocationId' in legacy &&
          'email' in legacy &&
          'token' in legacy
        )
      ) {
        // clear existing credentials if none provided through legacy
        return {
          ...restoredState,
          auth: {},
        };
      }

      // overwrite existing credentials with ones from legacy
      return {
        ...restoredState,
        auth: {
          email: legacy.email,
          token: legacy.token,
          currentCompanyId: parseInt(legacy.currentCompanyId, 10),
          currentLocationId: parseInt(legacy.currentLocationId, 10),
          currentUserId: parseInt(legacy.currentUserId, 10),
        },
      };
    } catch (error) {
      logger.instance.error({
        message: 'Restoring store state failed',
        error,
      });
    }

    return {};
  };
// state will be persisted only after mutations matching these regExps will be persisted
const PERSISTED_STORE_MODULES = [
  'auth',
  'rotationWizard',
  'mainView',
  'shiftSchedule/backgroundJobsMap',
];
const PERSISTED_MUTATION_NAMESPACES: RegExp[] = PERSISTED_STORE_MODULES.map(
  (key) => new RegExp(`${key}/\\w+$`),
);

export const filter: NonNullable<PersistOptions<RootStoreState>['filter']> = ({
  type,
}) => {
  const isPersisted = PERSISTED_MUTATION_NAMESPACES.some((namespace) =>
    namespace.test(type),
  );
  return isPersisted;
};

export const getStore = (
  graphqlClientFactory: GraphqlClientFactory,
  restClient: RestClient,
  logger: ApplicationLogger,
) => {
  const vuexLocal = new VuexPersistence<RootStoreState>({
    storage: new SecureLocalStorage(),
    reducer: (state): DeepPartial<RootStoreState> => {
      const {
        auth: { token, currentCompanyId, email },
      } = state;
      return {
        auth: {
          email,
          token,
          currentCompanyId,
        },
        mainView: {
          isChatOpen: state.mainView.isChatOpen,
        },
        shiftSchedule: {
          backgroundJobsMap: {
            byShiftplanId: state.shiftSchedule.backgroundJobsMap.byShiftplanId,
          },
        },
      };
    },
    restoreState: restoreState(logger),
    saveState: (key: string, state: {}, storage?: Storage) =>
      storage?.setItem(key, JSON.stringify(state)),
    filter,
  });

  const gqlClient: GraphqlClient = graphqlClientFactory.getClient();

  return new Vuex.Store<RootStoreState>({
    modules: {
      absenceReasons: getAbsenceReasonsStore(gqlClient, logger),
      assignmentGroups: getAssignmentGroupsStore(gqlClient, logger),
      absences: getAbsencesStore(gqlClient, restClient, logger),
      auth: getAuthStore(graphqlClientFactory, restClient),
      availabilityAggregations: getAvailabiltyAggregationsStore(gqlClient),
      backgroundJobs: getBackgroundJobsStore(gqlClient, logger),
      bulkRequest: getBulkRequestStore(restClient, logger),
      calendar: getCalendarStore(),
      calendarAbsences: getCalendarAbsencesStore(),
      calendarPrint: getCalendarPrintStore(),
      dayNotes: getDayNotesStore(gqlClient, logger),
      dragAndDrop: getDragAndDropStore(),
      employments: getEmploymentsStore(gqlClient, logger),
      employmentEvaluationBreaks: getEmploymentEvaluationBreaksStore(
        gqlClient,
        logger,
      ),
      employmentEvaluationPayments: getEmploymentEvaluationPaymentsStore(
        gqlClient,
        logger,
      ),
      evaluationsForm: {
        namespaced: true,
        modules: {
          sectionBreaks: getSectionBreaksStore(true),
          sectionBreaksEmployment: getSectionBreaksStore(),
          sectionPay: getSectionPayStore(),
          sectionPayEmployment: getSectionPayStore(),
        },
      },
      evaluations: getEvaluationsStore(gqlClient, logger),
      employmentEvaluations: getEmploymentEvaluationsStore(gqlClient, logger),
      locations: getLocationsStore(gqlClient, logger),
      locationsPositions: getLocationsPositionsStore(gqlClient, logger),
      mainView: getMainViewStore(restClient),
      paygradeTypes: getPaygradeTypesStore(gqlClient, logger),
      evaluationBreaks: getEvaluationBreaksStore(gqlClient, logger),
      evaluationPayments: getEvaluationPaymentsStore(gqlClient, logger),
      paymentsCalculation: getPaymentsCalculationStore(gqlClient, logger),
      positions: getPositionsStore(gqlClient, logger),
      rotationGroups: getRotationGroupsStore(gqlClient),
      rotationWizard: getRotationWizardStore(gqlClient, logger),
      shiftAvailableEmployments: getShiftAvailableEmploymentsStore(
        gqlClient,
        logger,
      ),
      shiftPaygrades: getShiftPaygradesStore(gqlClient, logger),
      shiftplans: getShiftplansStore(gqlClient, logger),
      shiftPresets: getShiftPresetStore(gqlClient, logger),
      shifts: getShiftsStore(gqlClient, logger),
      shiftRotations: getShiftRotationsStore(gqlClient, logger),
      shiftSchedule: getShiftScheduleStore(),
      snackbar: getSnackbarStore(),
      staffShiftsTags: getStaffShiftsTagsStore(gqlClient, logger),
      shiftEvaluationTags: getShiftEvaluationTagsStore(gqlClient, logger),
      staffShifts: getStaffShiftsStore(gqlClient, logger),
      tags: getTagsStore(gqlClient, logger),
      uiSettings: getUiSettingsStore(gqlClient, logger),
      qualifications: getQualificationsStore(gqlClient, logger),
      shiftAssignmentGroups: getShiftAssignmentGroupsStore(gqlClient, logger),
      shiftplanNotifications: getShiftplanNotificationsStore(gqlClient, logger),
      employmentInfo: getShiftEmploymentsFilterStore(gqlClient, logger),
      shiftplanPublish: getShiftplanPublishSettingsFilterStore(
        gqlClient,
        logger,
      ),
      specialDates: getSpecialDatesStore(gqlClient, logger),
    },
    plugins: [vuexLocal.plugin, VuexSentryPlugin(logger)],
  });
};

export default getStore;
