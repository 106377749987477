import Employment from 'components/calendar-common/employments/Employment';

// method is applicable only for hourly-weekly accounts
export function getWorkingMinutesLimitForDate(
  date: string,
  employment: Employment,
) {
  const hourlyData = employment.employmentWorkHour;
  const days = [
    'sunMinutes',
    'monMinutes',
    'tueMinutes',
    'wedMinutes',
    'thuMinutes',
    'friMinutes',
    'satMinutes',
  ];

  return hourlyData ? hourlyData[days[new Date(date).getDay()]] || 0 : 0;
}

// method is applicable only for hourly-monthly accounts
export function isWorkingOnThatDayOfTheWeek(
  date: string,
  employment: Employment,
) {
  const hourlyData = employment.employmentWorkHour;
  const days = [
    'isWorkingOnSun',
    'isWorkingOnMon',
    'isWorkingOnTue',
    'isWorkingOnWed',
    'isWorkingOnThu',
    'isWorkingOnFri',
    'isWorkingOnSat',
  ];

  return hourlyData ? hourlyData[days[new Date(date).getDay()]] : true;
}
