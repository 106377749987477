import type { SelectedTimeframe } from 'components/datepicker/types';
import getEmploymentFilterStore, {
  StoreState as EmploymentFilterStoreState,
} from 'components/shift-schedule/filter-box-employment/store/Store';
import getQuickFilterStore, {
  StoreState as QuickFilterStoreState,
} from 'components/shift-schedule/filter-box-quick/store/Store';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import getShiftPresetsFilterStore from 'components/shift-schedule/filter-box-shift-preset/store/Store';
import getShiftRotationGroupsFilterStore, {
  StoreState as ShiftRotationGroupFilterStoreState,
} from 'components/shift-schedule/filter-box-shift-rotation-group/store/Store';
import getTagsFilterStore, {
  StoreState as TagFilterStoreState,
} from 'components/shift-schedule/filter-box-tag/store/Store';
import getAssignmentGroupsFilterStore from 'components/shift-schedule/filter-box-assignment-group/store/Store';
import getLocationsPositionsFilterStore from 'components/shift-schedule/filter-box-locations-position/store/Store';
import { StoreState as FilterStoreState } from 'store/filter-store';
import getAbsenceReasonsFilterStore from 'components/shift-schedule/filter-box-absence-reason/store/Store';
import { PayloadParameter } from 'utils/store';
import type { ShiftplanScopedTimeframes } from 'src/store/ui-settings/Store';
import {
  updateTimeframeOnTimeframeChange,
  getTimeframeFromDates,
} from 'src/utils/timeframe-helpers';
import { endOf, startOf, Unit } from 'src/utils/date-related';
import VueTimeZoneProvider from 'services/vue-time-zone-provider/VueTimeZoneProvider';
import {
  ViewKind,
  SlotDisplayStyle,
  TimeframeKind,
  PrintViewKind,
  PrintTimeframeKind,
} from '../../calendar-common/Enums';
import Action from './Action';
import Mutation from './Mutation';
import { FiltersMap } from '../../calendar-common/filters/Store';
import getRightsStore from './rights/Store';

export const PrintTypeIntervals: Record<PrintViewKind, PrintTimeframeKind> = {
  [PrintViewKind.LIST]: TimeframeKind.DAY,
  [PrintViewKind.POSITIONS_WEEK]: TimeframeKind.WEEK,
  [PrintViewKind.POSITIONS_MONTH]: TimeframeKind.MONTH,
  [PrintViewKind.EMPLOYMENTS_WEEK]: TimeframeKind.WEEK,
  [PrintViewKind.EMPLOYMENTS_MONTH]: TimeframeKind.MONTH,
};

export const shiftScheduleNS = namespace('shiftSchedule');

export const shiftScheduleBackgroundJobsMapNS = namespace(
  'shiftSchedule/backgroundJobsMap',
);

export interface StoreState {
  calendarTimeframes: ShiftplanScopedTimeframes;
  timeframeKind: TimeframeKind;
  viewKind: ViewKind;
  printViewKind: PrintViewKind;
  slotDisplayStyle: SlotDisplayStyle;
  apmTimeframe: SelectedTimeframe;
  printTimeframes: ShiftplanScopedTimeframes;
}

export interface BackgroundJobsMapStoreState {
  byShiftplanId: Record<number, string>;
}

export interface SetTimeframePayload {
  timeframeKind?: TimeframeKind;
  timeframe?: SelectedTimeframe;
}

export interface SetTimeframesPayload {
  timeframeKind: TimeframeKind;
  calendarTimeframes: ShiftplanScopedTimeframes;
}

export interface SetPrintTimeframesPayload {
  printViewKind: PrintViewKind;
  printTimeframes: ShiftplanScopedTimeframes;
}

export interface SetPrintTimeframePayload {
  printViewKind: PrintViewKind;
  timeframe: SelectedTimeframe;
}

export interface ModuleState extends StoreState {
  absenceReasonsFilter: FilterStoreState;
  assignmentGroupsFilter: FilterStoreState;
  employmentFilter: EmploymentFilterStoreState;
  locationsPositionsFilter: FilterStoreState;
  quickFilter: QuickFilterStoreState;
  shiftPresetsFilter: FilterStoreState;
  tagsFilter: TagFilterStoreState;
  shiftRotationGroupsFilter: ShiftRotationGroupFilterStoreState;
  backgroundJobsMap: BackgroundJobsMapStoreState;
}

export type RemoveJobFunction = (shiftplanId: number) => void;

export type SetJobFunction = (payload: {
  jobId: string;
  shiftplanId: number;
}) => void;

const getBackgroundJobsMapStore = (): Module<
  BackgroundJobsMapStoreState,
  RootStoreState
> => ({
  namespaced: true,
  state: {
    byShiftplanId: {},
  },
  mutations: {
    [Mutation.SET_JOB](state, { jobId, shiftplanId }) {
      state.byShiftplanId = {
        ...state.byShiftplanId,
        [shiftplanId]: jobId,
      };
    },
    [Mutation.REMOVE_JOB](state, shiftplanId: number) {
      const tmp = { ...state.byShiftplanId };
      delete tmp[shiftplanId];

      state.byShiftplanId = tmp;
    },
  },
  actions: {
    [Action.SET_JOB]({ commit }, payload: PayloadParameter<SetJobFunction>) {
      commit(Mutation.SET_JOB, {
        jobId: payload.jobId,
        shiftplanId: payload.shiftplanId,
      });
    },
    [Action.REMOVE_JOB](
      { commit },
      payload: PayloadParameter<RemoveJobFunction>,
    ) {
      commit(Mutation.REMOVE_JOB, payload);
    },
  },
});

const getDefaultSavedSettings = () => ({
  day: { startsAt: new Date(), endsAt: new Date() },
  week: { startsAt: new Date(), endsAt: new Date() },
  month: { startsAt: new Date(), endsAt: new Date() },
  free: { startsAt: new Date(), endsAt: new Date() },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const getStore = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    slotDisplayStyle: SlotDisplayStyle.FULLNAMES,
    printViewKind: PrintViewKind.LIST,
    timeframeKind: TimeframeKind.WEEK,
    viewKind: ViewKind.AGENDA,
    calendarTimeframes: getDefaultSavedSettings(),
    apmTimeframe: {
      startsAt: startOf(
        new Date(),
        Unit.MONTH,
        VueTimeZoneProvider.getTimeZone(),
      ),
      endsAt: endOf(new Date(), Unit.MONTH, VueTimeZoneProvider.getTimeZone()),
    },
    printTimeframes: getDefaultSavedSettings(),
  },
  mutations: {
    [Mutation.SET_SLOT_DISPLAY_STYLE](
      state,
      slotDisplayStyle: SlotDisplayStyle,
    ) {
      state.slotDisplayStyle = slotDisplayStyle;
    },
    [Mutation.SET_APM_TIMEFRAME](state, timeframe: SelectedTimeframe) {
      state.apmTimeframe = timeframe;
    },
    [Mutation.SET_CALENDAR_TIMEFRAMES](
      state,
      calendarTimeframes: ShiftplanScopedTimeframes,
    ) {
      state.calendarTimeframes = calendarTimeframes;
    },
    [Mutation.SET_TIMEFRAME_KIND](state, timeframeKind: TimeframeKind) {
      state.timeframeKind = timeframeKind;
    },
    [Mutation.SET_VIEW_KIND](state, viewKind: ViewKind) {
      state.viewKind = viewKind;
    },
    [Mutation.SET_PRINT_TIMEFRAMES](
      state,
      printTimeframes: ShiftplanScopedTimeframes,
    ) {
      state.printTimeframes = printTimeframes;
    },
    [Mutation.SET_PRINT_VIEW_KIND](state, printViewKind: PrintViewKind) {
      state.printViewKind = printViewKind;
    },
  },
  getters: {
    printTimeframeKind(state) {
      return PrintTypeIntervals[state.printViewKind];
    },
    shiftScheduleQuickFilters(state, getters, rootState): FiltersMap[] {
      const { currentCompany } = rootState.auth;

      return [
        FiltersMap.STAFF_REQUEST,
        FiltersMap.LEAVE_REQUEST,
        currentCompany?.shiftSwapEnabled && FiltersMap.REPLACE_REQUEST,
        FiltersMap.UNDERASSIGNED_SHIFTS,
        FiltersMap.FULL_SHIFTS,
        currentCompany?.isOverassignmentAllowed &&
          FiltersMap.OVERASSIGNED_SHIFTS,
        state.viewKind === ViewKind.POSITIONS &&
          (state.timeframeKind === TimeframeKind.DAY ||
            state.timeframeKind === TimeframeKind.WEEK) &&
          FiltersMap.UNSCHEDULED_EMPLOYMENTS,
        FiltersMap.NEW_ABSENCES,
        FiltersMap.ACCEPTED_ABSENCES,
        FiltersMap.SPECIAL_DATES,
        FiltersMap.DAY_NOTES,
        currentCompany?.isTagsAllowed && FiltersMap.SHIFTS_WITHOUT_TAGS,
        currentCompany?.shiftRotationEnabled && FiltersMap.SHIFT_ROTATION,
        FiltersMap.SUMMARY,
      ].filter((filter): filter is FiltersMap => !!filter);
    },
    absencePlanningQuickFilters(state, getters, rootState): FiltersMap[] {
      const { currentCompany } = rootState.auth;

      return [
        FiltersMap.SPECIAL_DATES,
        currentCompany?.shiftRotationEnabled && FiltersMap.SHIFT_ROTATION,
        FiltersMap.SUMMARY,
      ].filter((filter): filter is FiltersMap => !!filter);
    },
    printShiftScheduleQuickFilters(): FiltersMap[] {
      return [
        FiltersMap.NEW_ABSENCES,
        FiltersMap.ACCEPTED_ABSENCES,
        FiltersMap.NOTES,
        FiltersMap.POSITION_NOTES,
      ];
    },
  },
  actions: {
    [Action.SET_SLOT_DISPLAY_STYLE](
      { commit },
      slotDisplayStyle: SlotDisplayStyle,
    ) {
      commit(Mutation.SET_SLOT_DISPLAY_STYLE, slotDisplayStyle);
    },
    [Action.SET_VIEW_KIND]({ commit }, viewKind: ViewKind) {
      commit(Mutation.SET_VIEW_KIND, viewKind);
    },
    [Action.SET_PRINT_VIEW_KIND]({ commit }, printViewKind: PrintViewKind) {
      commit(Mutation.SET_PRINT_VIEW_KIND, printViewKind);
    },
    [Action.SET_APM_TIMEFRAME]({ commit }, payload: SetTimeframePayload) {
      commit(Mutation.SET_APM_TIMEFRAME, payload.timeframe);
    },
    [Action.SET_CALENDAR_TIMEFRAME](
      { commit, state },
      payload: SetTimeframePayload,
    ) {
      const extendedPayload = {
        timeframeKind: state.timeframeKind,
        timeframe: state.calendarTimeframes[state.timeframeKind],
        ...payload,
      };

      const { timeframe, timeframeKind } = extendedPayload;
      let { calendarTimeframes } = state;

      if (
        timeframeKind &&
        state.timeframeKind === timeframeKind &&
        timeframeKind !== TimeframeKind.FREE
      ) {
        calendarTimeframes = updateTimeframeOnTimeframeChange(
          timeframe,
          state.calendarTimeframes,
          timeframeKind,
        );
      } else if (payload.timeframeKind === TimeframeKind.FREE) {
        calendarTimeframes = {
          ...state.calendarTimeframes,
          [timeframeKind]: getTimeframeFromDates(
            timeframe.startsAt,
            timeframe.endsAt,
            timeframeKind,
            VueTimeZoneProvider.getTimeZone(),
          ),
        };
      }

      if (timeframeKind !== state.timeframeKind) {
        commit(Mutation.SET_TIMEFRAME_KIND, timeframeKind);
      }
      commit(Mutation.SET_CALENDAR_TIMEFRAMES, calendarTimeframes);
    },
    [Action.SET_CALENDAR_TIMEFRAMES](
      { commit },
      payload: SetTimeframesPayload,
    ) {
      const { calendarTimeframes, timeframeKind } = payload;

      commit(Mutation.SET_TIMEFRAME_KIND, timeframeKind);
      commit(Mutation.SET_CALENDAR_TIMEFRAMES, calendarTimeframes);
    },
    [Action.SET_PRINT_TIMEFRAME](
      { commit, state },
      payload: SetPrintTimeframePayload,
    ) {
      const { timeframe: printTimeframe, printViewKind } = payload;
      let { printTimeframes } = state;

      if (printViewKind && state.printViewKind === printViewKind) {
        printTimeframes = updateTimeframeOnTimeframeChange(
          printTimeframe,
          state.printTimeframes,
          PrintTypeIntervals[printViewKind],
        );
      }

      if (printViewKind !== state.printViewKind) {
        commit(Mutation.SET_PRINT_VIEW_KIND, printViewKind);
      }

      commit(Mutation.SET_PRINT_TIMEFRAMES, printTimeframes);
    },
    [Action.SET_PRINT_TIMEFRAMES](
      { commit },
      payload: SetPrintTimeframesPayload,
    ) {
      const { printTimeframes, printViewKind } = payload;

      commit(Mutation.SET_PRINT_VIEW_KIND, printViewKind);
      commit(Mutation.SET_PRINT_TIMEFRAMES, printTimeframes);
    },
  },
  modules: {
    rights: getRightsStore(),
    absenceReasonsFilter: getAbsenceReasonsFilterStore(),
    assignmentGroupsFilter: getAssignmentGroupsFilterStore(),
    employmentFilter: getEmploymentFilterStore(),
    locationsPositionsFilter: getLocationsPositionsFilterStore(),
    quickFilter: getQuickFilterStore(),
    shiftPresetsFilter: getShiftPresetsFilterStore(),
    shiftRotationGroupsFilter: getShiftRotationGroupsFilterStore(),
    tagsFilter: getTagsFilterStore(),
    backgroundJobsMap: getBackgroundJobsMapStore(),
  },
});

export default getStore;
