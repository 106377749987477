import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { CalendarNamespace } from '../Enums';
import Actions from './Actions';
import Mutations from './Mutations';

export enum HighlightableEventTypes {
  Employment = 'employment',
  AssignmentGroup = 'assignment group',
  ShiftRotationGroup = 'shift rotation group',
}
export type HighlightableEvent =
  | {
      id: number[];
      type: HighlightableEventTypes;
    }
  | { id: null; type: null };

export type SetHighlightPayload =
  | {
      id: number;
      type: HighlightableEventTypes;
    }[];

export const highlightsNS = namespace(
  `${CalendarNamespace.CALENDAR}/highlights`,
);
export const calendarAbsencesHighlightsNS = namespace(
  `${CalendarNamespace.ABSENCES}/highlights`,
);

export type StoreState = HighlightableEvent;

export const store = (
  calendarNamespace: CalendarNamespace,
): Module<HighlightableEvent, RootStoreState> => {
  const initialState: StoreState = {
    type: null,
    id: null,
  };

  return {
    namespaced: true,
    state: initialState,
    mutations: {
      [Mutations.SET](state, item) {
        if (!item || item.length === 0) {
          state.id = null;
          state.type = null;
          return;
        }

        state.type = item[0].type;
        state.id = item.map((o) => o.id);
      },
    },
    actions: {
      [Actions.SET]({ commit }, item) {
        commit(Mutations.SET, item);
      },
      [Actions.CLEAR]({ commit, rootGetters }) {
        const shiftRotationGroupIds =
          rootGetters[`${calendarNamespace}/filters/shiftRotationGroupIds`] ||
          null;

        if (
          shiftRotationGroupIds !== null &&
          shiftRotationGroupIds.length !== 0
        ) {
          const mutationData = shiftRotationGroupIds.map(
            (shiftRotationGroupId) => ({
              type: HighlightableEventTypes.ShiftRotationGroup,
              id: shiftRotationGroupId,
            }),
          );
          commit(Mutations.SET, mutationData);
        } else {
          commit(Mutations.SET);
        }
      },
    },
  };
};
