import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';

import styles from './sp-icon.css';

@Component
export default class SpIcon extends TsxComponent<{
  label?: string;
  name: string;
}> {
  @Prop()
  public label?: string;

  @Prop()
  public name: string;

  public render() {
    return (
      <span
        aria-hidden={this.label ? undefined : true}
        aria-label={this.label}
        title={this.label}
        class={styles[`sp-${this.name}`]}
      />
    );
  }
}
