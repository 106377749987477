import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { getRandomString } from 'src/utils/random';
import type { SyntheticEvent } from 'vue-tsx-support/types/dom';
import { Key } from 'src/utils/keyboard';
import Badge, { BadgePreset } from 'components/badge/Badge';
import { IconName } from 'components/icons/types';
import { Size } from 'components/types';
import Icon from 'components/icons/Icon';
import SelectPanel, { Option } from 'components/select-panel/SelectPanel';
import OutsideClickHandler from 'components/outside-click-handler/OutsideClickHandler';
import styles from './input-pill-select.css';

export interface Props<T extends string | number> {
  id?: string;
  value: T[];
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  required?: boolean;
  name?: string;
  filteredOptions: Option<T>[];
  selectedOptions: Option<T>[];
  isError?: boolean;
  hintText?: string;
  isMultiselect: boolean;
}

interface ScopedSlots {
  selectedOptions: { selectedValues: string[] };
}

interface Events<T> {
  onChange: EventPayload<
    T[],
    HTMLButtonElement | HTMLInputElement,
    MouseEvent | InputEvent
  >;
  onSearchChange: string;
}

@Component
class InputPillSelectBase<T extends string | number> extends TSXComponent<
  Props<T>,
  Events<T>,
  ScopedSlots
> {
  public $refs: {
    input: HTMLInputElement;
    container: HTMLDivElement;
    list: Vue;
  };

  private stringValue = '';

  private searchString = '';

  private isExpanded = false;

  public isPanelFocused = false;

  public suffixIcon?: IconName;

  @Prop({ default: () => `input-pill-select-${getRandomString()}` })
  protected id: string;

  @Prop()
  public label: Props<T>['label'];

  @Prop()
  public placeholder: Props<T>['placeholder'];

  @Prop()
  public value: Props<T>['value'];

  @Prop()
  public isDisabled: Props<T>['isDisabled'];

  @Prop()
  public name: Props<T>['name'];

  @Prop({ default: false })
  public required: NonNullable<Props<T>['required']>;

  @Prop()
  public filteredOptions: Props<T>['filteredOptions'];

  @Prop()
  public selectedOptions: Props<T>['selectedOptions'];

  @Prop()
  public isError: Props<T>['isError'];

  @Prop()
  public hintText: Props<T>['hintText'];

  @Prop()
  public isMultiselect: Props<T>['isMultiselect'];

  @Watch('value', { immediate: true })
  protected updateStringValue() {
    this.stringValue = this.value.join(',');
  }

  private get isInvalid(): boolean {
    return (
      this.isError ||
      (this.$attrs.required !== undefined &&
        (this.value === undefined || this.value.length === 0))
    );
  }

  private get isValid() {
    return (
      !this.isError &&
      this.$attrs.required !== undefined &&
      this.value.length > 0
    );
  }

  private onInput({ target }: SyntheticEvent<HTMLInputElement, InputEvent>) {
    this.searchString = target.value;
    this.$emit('searchChange', this.searchString);
  }

  private closeAndClearSearchString() {
    this.searchString = '';
    this.$emit('searchChange', '');
    this.close();
  }

  private onChange(
    e: SyntheticEvent<HTMLElement | HTMLInputElement, MouseEvent | InputEvent>,
    payload: T[],
  ) {
    this.$emit('change', createEventPayload(e, payload));

    this.closeAndClearSearchString();
  }

  private onKeyup(e: SyntheticEvent<HTMLInputElement, KeyboardEvent>) {
    if (
      this.isDisabled ||
      e.key === Key.ENTER ||
      e.key === Key.TAB ||
      e.key === Key.SHIFT ||
      e.key === Key.CAPS_LOCK
    ) {
      return;
    }
    if (e.key === Key.ESCAPE) {
      this.close();
      return;
    }
    if (e.key === Key.ARROW_DOWN || e.key === Key.ARROW_UP) {
      this.isPanelFocused = true;
    }
    if (e.target.value === '' && e.key === Key.BACKSPACE) {
      this.onChange(e, this.value.slice(0, -1));
    }

    this.open();
  }

  private onKeydown(e) {
    // Prevent submit while selecting
    if (e.key === Key.ENTER && this.isExpanded) {
      e.preventDefault();
    } else if (e.key === Key.TAB) {
      this.closeAndClearSearchString();
    }
  }

  private onDeletePillClick(
    e: SyntheticEvent<HTMLButtonElement, MouseEvent>,
    valueToDelete: string | number,
  ) {
    e.preventDefault();
    e.stopPropagation();
    this.$refs.input.blur();
    this.close();
    this.onChange(
      e,
      this.value.filter(
        (value: string | number) =>
          value.toString() !== valueToDelete.toString(),
      ),
    );
  }

  private onOutsideClick(
    e: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent>,
  ) {
    e.stopPropagation();
    this.closeAndClearSearchString();
  }

  private open() {
    if (this.isDisabled) {
      return;
    }
    this.$refs?.input?.focus();
    this.isExpanded = true;
  }

  private onSelect(
    e: EventPayload<T, HTMLElement, KeyboardEvent | MouseEvent>,
  ) {
    if (this.isMultiselect && this.value.includes(e.payload)) {
      this.onChange(
        e.event,
        this.value.filter((value: T) => value !== e.payload),
      );
    } else if (this.isMultiselect) {
      this.onChange(e.event, [...this.value, e.payload]);
    } else {
      this.onChange(e.event, [e.payload]);
    }
    this.$refs.input.blur();
    this.close();
  }

  private close() {
    this.isExpanded = false;
    this.isPanelFocused = false;
  }

  public render() {
    return (
      <div>
        {this.label && (
          <label class={styles.inputPillSelectLabel} for={this.id}>
            {this.label}
          </label>
        )}
        <div
          // the required props are there, just not in this element, but the way proposed by w3c
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          class={{
            [styles.inputPillSelect]: true,
            [styles.inputPillSelectDisabled]: this.isDisabled,
          }}
          ref="container"
          aria-expanded={this.isExpanded}
          aria-owns={`${this.id}-listbox`}
          aria-haspopup="listbox"
          id={`${this.id}-combobox`}
          tabIndex={0}
          onFocus={this.open}
        >
          {this.isExpanded && (
            <OutsideClickHandler
              insideRef={() => this.$refs.container}
              onOutsideClick={this.onOutsideClick}
            />
          )}
          <div
            class={{
              [styles.inputPillSelectInner]: true,
              [styles.inputPillSelectInnerSuccess]: this.isValid,
              [styles.inputPillSelectInnerError]: this.isInvalid,
              [styles.inputPillSelectInnerActive]: this.isExpanded,
            }}
          >
            <div
              class={{
                [styles.inputPillSelectValuesAndSearch]: true,
              }}
            >
              {this.isMultiselect &&
                this.selectedOptions.length > 0 &&
                this.selectedOptions.map((option) => (
                  <Badge
                    size={Size.SMALL}
                    class={styles.inputPillSelectBadge}
                    preset={BadgePreset.INFO}
                    isClosable={!this.isDisabled}
                    onCloseClick={(e) =>
                      this.onDeletePillClick(e, option.value)
                    }
                  >
                    {option?.label}
                  </Badge>
                ))}
              {((!this.isMultiselect && this.isExpanded) ||
                this.selectedOptions.length === 0) && (
                <Icon
                  class={styles.inputPillSelectSearchIcon}
                  aria-hidden="true"
                  name={IconName.SEARCH}
                  size={Size.MEDIUM}
                />
              )}

              <input
                id={this.id}
                disabled={this.isDisabled}
                type="text"
                ref="input"
                value={
                  !this.isMultiselect &&
                  this.selectedOptions.length > 0 &&
                  !this.isExpanded
                    ? this.selectedOptions[0].label
                    : this.searchString
                }
                class={styles.inputPillSelectInput}
                onKeydown={this.onKeydown}
                onKeyup={this.onKeyup}
                onFocus={this.open}
                onInput={this.onInput}
                aria-controls={`${this.id}-listbox`}
                placeholder={
                  this.selectedOptions.length === 0 ? this.placeholder : ''
                }
              />

              <input
                type="text"
                name={this.name}
                value={this.stringValue}
                class={styles.inputPillSelectValidationInput}
                tabindex="-1"
                required={this.required}
              />
            </div>
            <div class={styles.inputPillSelectSuffixWrapper}>
              <Icon
                aria-hidden="true"
                class={{
                  [styles.inputPillSelectChevron]: true,
                  [styles.inputPillSelectChevronMenuHidden]: this.isExpanded,
                }}
                name={IconName.CHEVRON_DOWN}
                size={Size.SMALL}
              />
              {(this.isInvalid || this.isValid) && (
                <Icon
                  aria-hidden="true"
                  name={
                    this.isValid
                      ? IconName.CHECK_CIRCLE
                      : IconName.REPORT_PROBLEM
                  }
                  size={Size.SMALL}
                  ref="iconRef"
                  class={{
                    [styles.inputPillSelectSuffixIcon]: true,
                    [styles.inputPillSelectSuffixIconError]: this.isInvalid,
                    [styles.inputPillSelectSuffixIconSuccess]: this.isValid,
                  }}
                />
              )}
            </div>
          </div>
          {!this.isDisabled && this.isExpanded && (
            <SelectPanel
              id={`${this.id}-listbox`}
              value={this.value.map((value) => value.toString())}
              onSelect={(e) => this.onSelect(e)}
              options={this.filteredOptions}
              isPanelFocused={this.isPanelFocused}
              ref="list"
            />
          )}
        </div>
        {this.hintText && (
          <span
            class={{
              [styles.inputPillSelectHintText]: true,
              [styles.inputPillSelectHintTextError]: this.isInvalid,
              [styles.inputPillSelectHintTextSuccess]: this.isValid,
            }}
          >
            {this.hintText}
          </span>
        )}
      </div>
    );
  }
}

export default InputPillSelectBase;
