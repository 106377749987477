enum Mutation {
  SET_VALUE = 'setValue',
  SET_ROTATION = 'setRotation',
  SET_ROTATION_GROUPS = 'setRotationGroups',
  SET_ROTATION_DAY = 'setRotationDay',
  SET_ROTATION_ID = 'setRotationId',
  SET_ROTATION_GROUP_ID = 'setRotationGroupId',
  SET_ROTATION_GROUP_NAME = 'setRotationGroupName',
  RESET = 'reset',
}

export default Mutation;
