import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateShiftEvaluationTagMutation,
  GQLCreateShiftEvaluationTagMutationVariables,
  GQLDeleteShiftEvaluationTagMutation,
  GQLDeleteShiftEvaluationTagMutationVariables,
  GQLShiftEvaluationTagsQuery,
  GQLShiftEvaluationTagsQueryVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateShiftEvaluationTagGql from './queries/CreateShiftEvaluationTag.gql';
import DeleteShiftEvaluationTagGql from './queries/DeleteShiftEvaluationTag.gql';
import ShiftEvaluationTagsGql from './queries/ShiftEvaluationTags.gql';
import { Tag } from '../tags/Store';

export const shiftEvaluationTagsNS = namespace('shiftEvaluationTags');

export type StoreState = ById<Tag>;

export type CreateShiftEvaluationTagFunction = (
  payload: Omit<GQLCreateShiftEvaluationTagMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteShiftEvaluationTagFunction = (
  payload: Omit<GQLDeleteShiftEvaluationTagMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type ShiftEvaluationTagsFunction = (
  payload: Omit<GQLShiftEvaluationTagsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getShiftEvaluationTagsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const create: ActionProvider<
    GQLCreateShiftEvaluationTagMutation,
    GQLCreateShiftEvaluationTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateShiftEvaluationTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateShiftEvaluationTagGql,
      resultKey: 'createShiftEvaluationTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      transform: ({ tag }) => tag,
    };
  };

  const remove: ActionProvider<
    GQLDeleteShiftEvaluationTagMutation,
    GQLDeleteShiftEvaluationTagMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteShiftEvaluationTagFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteShiftEvaluationTagGql,
      resultKey: 'deleteShiftEvaluationTag',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetchAll: ActionProvider<
    GQLShiftEvaluationTagsQuery,
    GQLShiftEvaluationTagsQueryVariables,
    Tag
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<ShiftEvaluationTagsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: ShiftEvaluationTagsGql,
      resultKey: 'shiftEvaluationTags',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  return createNormalizedStore<Tag, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH_ALL]: fetchAll,
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
    },
    graphqlClient,
    logger,
  });
};

export default getShiftEvaluationTagsStore;
