import { ErrorResponse } from 'apollo-link-error';
import RestClient from 'services/rest-client/RestClient';
import { getUrlWithApiPrefix } from 'src/utils/url';

export default async function invalidTokenHandler(
  e: ErrorResponse,
  restClient: RestClient,
) {
  if (
    e.graphQLErrors?.some(
      (error) => error.extensions?.code === 'UNAUTHENTICATED',
    )
  ) {
    const result = await restClient.get('/api/v1/employments/my', {});

    if (!result.isSuccessful && result.status === 401) {
      window.location.replace(getUrlWithApiPrefix('login?invalid_token=true'));
    }
  }
}
