import {
  getHourMinuteDurationString,
  MSECS_IN_HOUR,
  MSECS_IN_MINUTE,
} from 'src/utils/date-related';
import { getEmploymentString } from 'src/utils/utils';
import type { ShiftAvailableEmployment } from 'store/shift-available-employments/Store';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import styles from 'components/popover/popover-content.css';
import { EmploymentInfo } from 'src/store/shift-employment-filter/Store';

@Component
export default class PopoverContent extends TsxComponent<{
  employmentInfo: ShiftAvailableEmployment | EmploymentInfo;
}> {
  @Prop()
  public employmentInfo: ShiftAvailableEmployment | EmploymentInfo;

  protected get worked() {
    return getHourMinuteDurationString(
      (this.employmentInfo.workedMinutes || 0) * MSECS_IN_MINUTE,
    );
  }

  protected get scheduled() {
    return getHourMinuteDurationString(
      (this.employmentInfo.scheduledMinutes || 0) * MSECS_IN_MINUTE,
    );
  }

  protected get contractedAsNumber() {
    return this.employmentInfo.employment.employmentWorkHour?.isMonthly
      ? this.employmentInfo.employment.employmentWorkHour?.monthlyMinutes || 0
      : this.employmentInfo.employment.employmentWorkHour?.weeklyMinutes || 0;
  }

  protected get contracted() {
    return getHourMinuteDurationString(
      this.contractedAsNumber * MSECS_IN_MINUTE,
    );
  }

  protected get hourAccountBalance() {
    return getHourMinuteDurationString(
      (this.employmentInfo.hourAccountHours || 0) * MSECS_IN_HOUR,
      true,
    );
  }

  protected get isBringShiftEnabled() {
    // TODO: Check company setting for canUseBringShifts when API is ready
    return (
      !!this.employmentInfo.bringShiftHoursUsed &&
      !!this.employmentInfo.bringShiftHours
    );
  }

  protected get bringShiftBalance() {
    if (!this.isBringShiftEnabled) {
      return '?';
    }

    return `${getHourMinuteDurationString(
      (this.employmentInfo.bringShiftHoursUsed || 0) * MSECS_IN_HOUR,
    )}/${getHourMinuteDurationString(
      (this.employmentInfo.bringShiftHours || 0) * MSECS_IN_HOUR,
    )}`;
  }

  public render() {
    return (
      <div class={styles.popoverContent}>
        <h3 class={styles.popoverContentHeading}>
          {getEmploymentString(this.employmentInfo.employment, true)}
        </h3>

        <dl class={styles.popoverContentList}>
          <dt class={styles.popoverContentItemHeader}>
            {this.$t('shifts.employees.hourBalance.labelWorking')}
          </dt>
          <dd class={styles.popoverContentItem}>{this.worked}&thinsp;h</dd>

          <dt class={styles.popoverContentItemHeader}>
            {this.$t('shifts.employees.hourBalance.labelPlanned')}
          </dt>
          <dd class={styles.popoverContentItem}>{this.scheduled}&thinsp;h</dd>

          {this.contractedAsNumber > 0 && [
            <dt class={styles.popoverContentItemHeader}>
              {this.$t('shifts.employees.hourBalance.labelContracted')}
            </dt>,
            <dd class={styles.popoverContentItem}>
              {this.contracted}&thinsp;h{' '}
              {this.employmentInfo.employment.employmentWorkHour?.isMonthly
                ? this.$t('shifts.employees.hourBalance.perMonth')
                : this.$t('shifts.employees.hourBalance.perWeek')}
            </dd>,
          ]}

          {this.employmentInfo.employment.hourEnabled && [
            <dt class={styles.popoverContentItemHeader}>
              {this.$t('shifts.employees.hourBalance.labelHourAccount')}
            </dt>,
            <dd class={styles.popoverContentItem}>
              {this.hourAccountBalance}&thinsp;h
            </dd>,
          ]}

          {this.isBringShiftEnabled && [
            <dt class={styles.popoverContentItemHeader}>
              {this.$t('shifts.employees.hourBalance.labelBringShifts')}
            </dt>,
            <dd class={styles.popoverContentItem}>
              {this.bringShiftBalance}&thinsp;h
            </dd>,
          ]}
        </dl>
      </div>
    );
  }
}
