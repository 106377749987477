import { store as absences } from 'components/calendar-print/absences/store/Store';
import { store as employments } from 'components/calendar-print/employments/store/Store';
import {
  store as positions,
  ModuleState as PositionsModuleState,
} from 'components/calendar-common/positions/Store';
import { store as shifts } from 'components/calendar-print/shifts/store/Store';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import {
  store as common,
  StoreState as CommonStoreState,
} from './common/Store';
import {
  store as filters,
  StoreState as FiltersStoreState,
} from './filters/store/Store';

export interface ModuleState {
  positions: PositionsModuleState;
  common: CommonStoreState;
  filters: FiltersStoreState;
}

const getCalendarPrintStore = () => ({
  namespaced: true,
  modules: {
    shifts: shifts(),
    absences: absences(),
    positions: positions(CalendarNamespace.PRINT),
    employments: employments(),
    common: common(),
    filters: filters(),
  },
});

export default getCalendarPrintStore;
