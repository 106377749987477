import { Plugin } from 'vuex';
import RootStoreState from 'src/store/RootStoreState';
import ApplicationLogger from 'services/logger/ApplicationLogger';

const plugin =
  (logger: ApplicationLogger): Plugin<RootStoreState> =>
  (store) => {
    store.subscribeAction({
      error: (action, state, error) => {
        logger.instance.error({
          error,
          tags: [['action', action.type]],
        });
      },
    });
  };

export default plugin;
