import Vue from 'vue';

const OPERATION_CANCELED = 'Operation Canceled';

interface DialogOpenState {
  isOpen: boolean;
}

interface DialogManagerPromptResult<T> {
  result: T;
}

class DialogManager<Result, DialogState extends DialogOpenState> {
  private promptPromise: Promise<DialogManagerPromptResult<Result>>;

  private promiseResolve: (result: DialogManagerPromptResult<Result>) => void;

  private promiseReject: (reason: string) => void;

  public state: Vue & DialogState;

  public constructor(initialState: DialogState) {
    this.state = new Vue({ data: initialState });
  }

  public setStateKey<Key extends keyof DialogState>(
    key: Key,
    value: DialogState[Key],
  ) {
    this.state.$set(this.state, key as string, value);
  }

  public async prompt(): Promise<DialogManagerPromptResult<Result>> {
    this.setStateKey('isOpen', true);
    this.promptPromise = new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
      this.promiseReject = reject;
    });

    return this.promptPromise;
  }

  public onAbort(reason: string = OPERATION_CANCELED) {
    this.setStateKey('isOpen', false);
    this.promiseReject(reason);
  }

  public async onAnswer(result: Result) {
    this.setStateKey('isOpen', false);
    this.promiseResolve({ result });
  }

  public destroy() {
    this.state.$destroy();
  }
}

export default DialogManager;
