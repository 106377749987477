import { Logger, createLogger, transports } from 'winston';
import SentryTransport from './SentryTransport';

class ApplicationLogger {
  public instance: Logger;

  public sentryTransport: SentryTransport;

  public constructor() {
    this.instance = createLogger();
    // in env comma separated line of values will be provided
    // for example: console,sentry
    const logTypesFromEnv = (process.env.LOG_TYPES || '')
      .split(',')
      .map((it) => it.trim())
      .filter((it) => it.length > 0);
    const logTypes = logTypesFromEnv.length ? logTypesFromEnv : ['console'];
    if (logTypes.includes('sentry') && process.env.SENTRY_DSN) {
      this.sentryTransport = new SentryTransport({
        level: 'info',
      });
      this.instance.add(this.sentryTransport);
    }

    if (logTypes.includes('console')) {
      this.instance.add(new transports.Console());
    }
  }
}

export default ApplicationLogger;
