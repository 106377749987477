import {
  StoreState as FilterStoreState,
  createFilterStore,
} from 'store/filter-store';
import { namespace } from 'vuex-class';
import Action from './Action';
import Mutation from './Mutation';

export const employmentFilterNS = namespace('shiftSchedule/employmentFilter');

export type StoreState = FilterStoreState & { search: string };

export interface Filter {
  search: string | null;
}

const getStore = () =>
  createFilterStore({
    namespaced: true,
    state: {
      search: '',
    },
    mutations: {
      [Mutation.SET_SEARCH](state: Filter, search: string) {
        state.search = search;
      },
    },
    actions: {
      [Action.SET_SEARCH]({ commit }, search: string) {
        commit(Mutation.SET_SEARCH, search);
      },
    },
  });

export default getStore;
