import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import RootStoreState from 'src/store/RootStoreState';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import {
  GQLEmploymentsQuery,
  GQLEmploymentsQueryVariables,
} from 'codegen/gql-types';
import EmploymentsGql from '../queries/Employments.gql';

export const SEARCH_FILTER_THROTTLE_TIMEOUT = 250;

export const employmentsNS = namespace('employments');

export type Employment = GQLEmploymentsQuery['employments']['items'][0];

export type StoreState = ById<Employment>;

export type FetchAllEmploymentsFunction = (
  payload?: Omit<GQLEmploymentsQueryVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type FetchEmploymentFunction = (
  payload: Omit<GQLEmploymentsQueryVariables, 'companyId' | 'page' | 'perPage'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEmploymentsStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = { namespaced: true };

  const fetch: ActionProvider<
    GQLEmploymentsQuery,
    GQLEmploymentsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchEmploymentFunction> = {},
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: EmploymentsGql,
      resultKey: 'employments',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
    };
  };

  const fetchAll: ActionProvider<
    GQLEmploymentsQuery,
    GQLEmploymentsQueryVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<FetchAllEmploymentsFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: EmploymentsGql,
      resultKey: 'employments',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  return createNormalizedStore<Employment, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.FETCH]: fetch,
      [Action.FETCH_ALL]: fetchAll,
    },
    graphqlClient,
    logger,
  });
};

export default getEmploymentsStore;
