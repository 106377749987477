import { EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TSXComponent } from 'vue-tsx-support';
import { getRandomString } from 'src/utils/random';
import { filterFalsy } from 'src/utils/utils';
import { Option } from 'components/select-panel/SelectPanel';
import InputPillSelectBase from './InputPillSelectBase';

export interface Props<T extends string | number> {
  id?: string;
  options: Option<T>[];
  value: T[];
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  isValid?: boolean;
  isError?: boolean;
  name?: string;
  hintText?: string;
}

interface Events {
  onChange: (
    e: EventPayload<
      string[],
      HTMLButtonElement | HTMLInputElement,
      MouseEvent | InputEvent
    >,
  ) => void;
}

@Component
export default class InputPillSelect<
  T extends string | number,
> extends TSXComponent<
  Props<T> & Partial<Pick<HTMLInputElement, 'required'>>,
  Events
> {
  public $refs: {
    input: HTMLInputElement;
    container: HTMLDivElement;
    list: Vue;
  };

  private searchString = '';

  @Prop({ default: () => `input-pill-select-${getRandomString()}` })
  protected id: string;

  @Prop()
  public options: Props<T>['options'];

  @Prop()
  public label: Props<T>['label'];

  @Prop()
  public placeholder: Props<T>['placeholder'];

  @Prop({ default: [] })
  public value: NonNullable<Props<T>['value']>;

  @Prop()
  public isDisabled: Props<T>['isDisabled'];

  @Prop()
  public name: Props<T>['name'];

  @Prop()
  public isValid: Props<T>['isValid'];

  @Prop()
  public isError: Props<T>['isError'];

  @Prop()
  public hintText: Props<T>['hintText'];

  private get selectedOptions() {
    if (this.value && this.value.length) {
      return this.value
        .map((selectedValue: T) =>
          this.options.find(
            (option: Option<T>) => option.value === selectedValue,
          ),
        )
        .filter(filterFalsy);
    }
    return [];
  }

  private get filteredOptions() {
    const trimmedLowerCaseSearchString = this.searchString.trim().toLowerCase();

    if (trimmedLowerCaseSearchString.length === 0) {
      return this.options;
    }

    return this.options.filter(({ label }) =>
      label.trim().toLowerCase().includes(trimmedLowerCaseSearchString),
    );
  }

  public render() {
    return (
      <InputPillSelectBase
        isMultiselect={true}
        id={this.id}
        value={this.value}
        isDisabled={this.isDisabled}
        label={this.label}
        hintText={this.hintText}
        isError={this.isError}
        placeholder={this.placeholder}
        name={this.name}
        filteredOptions={this.filteredOptions}
        selectedOptions={this.selectedOptions}
        onSearchChange={(search) => {
          this.searchString = search;
        }}
        onChange={(e) => this.$emit('change', e)}
      />
    );
  }
}
