import Employment, {
  EmploymentConstructorProps,
} from 'components/calendar-common/employments/Employment';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import RootStoreState from 'src/store/RootStoreState';
import { locationPositionFilter } from 'components/calendar-common/employments/utils';
import { CalendarNamespace } from 'components/calendar-common/Enums';
import { sortByLastName } from 'src/utils/sort';

export const calendarPrintEmploymentsNS = namespace(
  `${CalendarNamespace.PRINT}/employments`,
);

export const store = (): Module<{}, RootStoreState> => ({
  namespaced: true,
  getters: {
    employments(state, getters, rootState, rootGetters) {
      const shiftsEmployments: Record<string, number[]> =
        rootGetters[`${CalendarNamespace.PRINT}/shifts/shiftsEmployments`] ||
        {};
      const employments = rootGetters['employments/items']
        .map(
          (employment): EmploymentConstructorProps => ({
            ...employment,
            presentLocationsPositionIds: Array.from(
              shiftsEmployments[employment.id] || [],
            ),
          }),
        )
        .sort(sortByLastName);
      return employments.map((it) => new Employment(it));
    },
    filteredEmployments(state, getters, rootState, rootGetters) {
      if (
        !rootState.auth.currentCompany ||
        !rootState.auth.currentEmploymentId
      ) {
        return [];
      }

      const locationsPositionIds =
        rootGetters['calendarPrint/filters/locationsPositionIds'];

      return getters.employments.filter(
        locationPositionFilter(locationsPositionIds),
      );
    },
    employmentsByPositions(state, getters, rootState, rootGetters) {
      return rootGetters[
        `${CalendarNamespace.PRINT}/positions/filteredPositions`
      ].reduce((acc, it) => {
        acc[it.locationsPositionId] = getters.filteredEmployments.filter(
          (employment) =>
            employment.presentLocationsPositionIds.includes(
              it.locationsPositionId,
            ),
        );
        return acc;
      }, {});
    },
  },
});
