import { LocationsPosition } from 'src/store/locations-positions/Store';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';

export const shiftScheduleRightsNS = namespace('shiftSchedule/rights');

const SHIFT_MANAGE_GLOBAL_RIGHTS = ['payments_manage_all', 'shifts_manage_all'];
const SHIFT_MANAGE_SCOPED_RIGHTS = [
  'payment_manage_right',
  'shift_manage_right',
];

const SHIFT_SHOW_GLOBAL_RIGHTS = ['payments_show_all', 'shifts_show_all'];
const SHIFT_SHOW_SCOPED_RIGHTS = ['payment_show_right', 'shift_show_right'];

const getStore = (): Module<{}, RootStoreState> => ({
  namespaced: true,

  getters: {
    isSuperAdmin(state, getters, rootState, rootGetters): boolean {
      return rootGetters['auth/isSuperAdmin'];
    },
    isStakeholder(state, getters, rootState): boolean {
      return rootState.auth.currentEmployment?.isStakeholder || false;
    },

    locationsPositionIdsForCurrentLocation(
      state,
      getters,
      rootState,
      rootGetters,
    ): number[] {
      const { currentLocationId } = rootState.auth;
      if (!currentLocationId) {
        return [];
      }

      return rootGetters['locationsPositions/getByLocationAndPositionId'](
        currentLocationId,
      ).map((it: LocationsPosition) => it.id);
    },
    hasShowRightsForAllLocations(state, getters, rootState, rootGetters) {
      return (
        getters.isSuperAdmin ||
        (!!getters.isStakeholder &&
          rootGetters['auth/hasAnyRight'](...SHIFT_SHOW_GLOBAL_RIGHTS))
      );
    },
    hasManageRightsForAllLocations(state, getters, rootState, rootGetters) {
      return (
        getters.isSuperAdmin ||
        (getters.isStakeholder &&
          rootGetters['auth/hasAnyRight'](...SHIFT_MANAGE_GLOBAL_RIGHTS))
      );
    },

    hasShowRightsForCurrentLocation(state, getters, rootState, rootGetters) {
      return (
        getters.isSuperAdmin ||
        (!!getters.isStakeholder &&
          (getters.hasShowRightsForAllLocations ||
            rootGetters['auth/hasAnyCurrentLocationRight'](
              ...SHIFT_SHOW_SCOPED_RIGHTS,
            )))
      );
    },
    hasManageRightsForCurrentLocation(state, getters, rootState, rootGetters) {
      return (
        getters.isSuperAdmin ||
        (!!getters.isStakeholder &&
          (getters.hasShowRightsForAllLocations ||
            rootGetters['auth/hasAnyCurrentLocationRight'](
              ...SHIFT_MANAGE_SCOPED_RIGHTS,
            )))
      );
    },
    hasShowRightsForAnyPositionInCurrentLocation(
      state,
      getters,
      rootState,
      rootGetters,
    ) {
      return (
        getters.isSuperAdmin ||
        (!!getters.isStakeholder &&
          rootGetters['auth/hasAnyOfLocationsPositionsRight'](
            getters.locationsPositionIdsForCurrentLocation,
            ...SHIFT_SHOW_SCOPED_RIGHTS,
          ))
      );
    },
    hasManageRightsForAnyPositionInCurrentLocation(
      state,
      getters,
      rootState,
      rootGetters,
    ) {
      return (
        getters.isSuperAdmin ||
        (!!getters.isStakeholder &&
          rootGetters['auth/hasAnyOfLocationsPositionsRight'](
            getters.locationsPositionIdsForCurrentLocation,
            ...SHIFT_MANAGE_SCOPED_RIGHTS,
          ))
      );
    },
    hasAnyShowRightInCurrentLocation(state, getters) {
      return (
        getters.hasShowRightsForCurrentLocation ||
        getters.hasShowRightsForAnyPositionInCurrentLocation
      );
    },
    hasAnyManageRightInCurrentLocation(state, getters) {
      return (
        getters.hasManageRightsForCurrentLocation ||
        getters.hasManageRightsForAnyPositionInCurrentLocation
      );
    },
    hasAnyRightInCurrentLocation(state, getters) {
      return (
        getters.hasAnyManageRightInCurrentLocation ||
        getters.hasAnyShowRightInCurrentLocation
      );
    },
  },
});

export default getStore;
