import 'moment/locale/de';
import moment from 'moment';
import type { GQLLanguage } from 'codegen/gql-types';
import OverlayPortalTarget from 'layouts/OverlayPortalTarget';
import TooltipPortalTarget from 'layouts/TooltipPortalTarget';
import { authNS, StoreState } from 'components/auth/store/Store';
import AuthAction from 'components/auth/store/Action';
import GlobalSnackbarContainer from 'components/snackbar/GlobalSnackbarContainer';
import { Component, Watch } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import InputDropdownPortalTarget from 'layouts/InputDropdownPortalTarget';
import type {
  LogoutFunction,
  RefreshSessionFunction,
} from 'components/auth/store/Store';
import CalendarTooltip from 'components/calendar-common/tooltip/CalendarTooltip';
import {
  tooltipNS,
  StoreState as TooltipStoreState,
} from 'components/calendar-common/tooltip/Store';
import styles from './styles';
import VueTimeZoneProvider from './services/vue-time-zone-provider/VueTimeZoneProvider';
import Loader from './components/loader/Loader';
import { StoreActionState } from './utils/store';
import { getUrlWithApiPrefix } from './utils/url';

const USER_ACTIVITY_THROTTLER_TIME = 10 * 60 * 1000;

@Component
class Root extends TsxComponent<{}> {
  private userActivityThrottlerTimeout: number | undefined;

  @authNS.State
  protected currentEmployment: StoreState['currentEmployment'];

  @authNS.State
  protected currentCompany: StoreState['currentCompany'];

  @authNS.Action(AuthAction.REFRESH_SESSION)
  protected refreshSession: RefreshSessionFunction;

  @authNS.Action(AuthAction.LOGOUT)
  protected logout: LogoutFunction;

  @tooltipNS.State('payload')
  private tooltipPayload: TooltipStoreState['payload'];

  @Watch('currentEmployment.language')
  protected onLanguageChange(language?: GQLLanguage) {
    // this is needed for calendar
    moment.locale(language?.locale || 'en', {
      week: {
        dow: 1,
      },
    });
    this.$i18n.i18next.changeLanguage(language?.locale || 'en');
  }

  @Watch('currentCompany.timeZone')
  protected onTimeZoneChange(timeZone?: string) {
    if (timeZone) {
      VueTimeZoneProvider.setTimeZone(timeZone);
    }
  }

  private get isAuthDataLoaded() {
    return this.currentCompany && this.currentEmployment;
  }

  private async tryRefreshSession() {
    const result = await this.refreshSession();
    if (result.state === StoreActionState.ERROR) {
      this.logout();
      window.location.replace(getUrlWithApiPrefix('/login'));
    }
  }

  private async userActivityThrottler() {
    if (!this.userActivityThrottlerTimeout) {
      this.userActivityThrottlerTimeout = window.setTimeout(async () => {
        await this.tryRefreshSession();
        this.userActivityThrottlerTimeout = undefined;
      }, USER_ACTIVITY_THROTTLER_TIME);
      /* 
        run refresh session immediately on first activity
        to make sure that session is still active when delayed refresh is executed
      */
      await this.tryRefreshSession();
    }
  }

  private activateActivityTracker() {
    window.addEventListener('mousemove', this.userActivityThrottler);
    window.addEventListener('scroll', this.userActivityThrottler);
    window.addEventListener('keydown', this.userActivityThrottler);
    window.addEventListener('resize', this.userActivityThrottler);
  }

  public created() {
    // init moment locale
    moment.locale(this.$i18n.i18next.language || 'en', {
      week: {
        dow: 1,
      },
    });
  }

  public beforeMount() {
    this.activateActivityTracker();
  }

  public beforeDestroy() {
    window.removeEventListener('mousemove', this.userActivityThrottler);
    window.removeEventListener('scroll', this.userActivityThrottler);
    window.removeEventListener('keydown', this.userActivityThrottler);
    window.removeEventListener('resize', this.userActivityThrottler);

    window.clearTimeout(this.userActivityThrottlerTimeout);
  }

  public render() {
    return (
      <div class={styles.root}>
        {/* TODO: Add proper loading screen */}
        {this.isAuthDataLoaded ? <router-view /> : <Loader />}
        {this.tooltipPayload && <CalendarTooltip />}
        <InputDropdownPortalTarget />
        <OverlayPortalTarget />
        <TooltipPortalTarget />
        <GlobalSnackbarContainer />
      </div>
    );
  }
}

export default Root;
