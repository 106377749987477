import { getSecondaryColor } from 'components/calendar-common/helpers/colors/Colors';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { CalendarNamespace } from '../Enums';
import { TOGGLE_POSITION } from './Actions';
import Mutations from './Mutations';

export const positionsNS = namespace('calendar/positions');
export const calendarPrintPositionsNS = namespace(
  `${CalendarNamespace.PRINT}/positions`,
);
export const monthPositionsNS = namespace('calendar/positions/monthPositions');

export interface ModuleState {
  monthPositions: {
    openPositionIds: number[];
  };
}

export const store = (
  parentNamespace: CalendarNamespace.CALENDAR | CalendarNamespace.PRINT,
): Module<{}, RootStoreState> => ({
  namespaced: true,
  getters: {
    availablePositions(state, getters, rootState, rootGetters) {
      return rootGetters['locationsPositions/items'].filter(
        ({ location: { id: locationId } }) =>
          locationId === rootState.auth.currentLocationId,
      );
    },
    positions(state, getters, rootState, rootGetters) {
      return rootGetters['locationsPositions/items']
        .filter(
          ({ location: { id: locationId } }) =>
            locationId ===
            rootState[parentNamespace].common.shiftplan?.locationId,
        )
        .map(({ position, id, sort }) => ({
          ...position,
          sort,
          locationsPositionId: id,
          secondaryColor: getSecondaryColor(position.color),
        }))
        .sort((positionA, positionB) => positionA.sort - positionB.sort);
    },
    locationsPositionIds(state, getters) {
      return getters.positions.map((position) => position.locationsPositionId);
    },
    filteredPositions(state, getters, rootState, rootGetters) {
      const locationsPositionIds =
        rootGetters[`${parentNamespace}/filters/locationsPositionIds`];

      return getters.positions.filter(
        (it) =>
          locationsPositionIds.length === 0 ||
          locationsPositionIds.includes(it.locationsPositionId),
      );
    },
    positionIds(state, getters) {
      return getters.filteredPositions.map(
        (position) => position.locationsPositionId,
      );
    },
  },
  modules: {
    monthPositions: {
      namespaced: true,
      state: {
        openPositionIds: [],
      },
      mutations: {
        [Mutations.OPEN_POSITION](state, positionId) {
          state.openPositionIds = [...state.openPositionIds, positionId];
        },
        [Mutations.CLOSE_POSITION](state, positionId) {
          state.openPositionIds = state.openPositionIds.filter(
            (it) => it !== positionId,
          );
        },
      },
      actions: {
        [TOGGLE_POSITION]({ commit, state }, positionId) {
          if (state.openPositionIds.includes(positionId)) {
            commit(Mutations.CLOSE_POSITION, positionId);
          } else {
            commit(Mutations.OPEN_POSITION, positionId);
          }
        },
      },
    },
  },
});
