import RootStoreState from 'src/store/RootStoreState';
import { EmploymentInfo } from 'src/store/shift-employment-filter/Store';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import Action from './Action';
import Mutation from './Mutation';

export const tooltipNS = namespace('calendar/tooltip');

export interface ShiftTooltip {
  element: HTMLButtonElement;
  text: string;
  composedNote: string | undefined;
  label: string;
  type: TooltipType.SHIFT;
}

export interface EmploymentTooltip {
  element: HTMLDivElement;
  employmentInfo: EmploymentInfo | null;
  currency: string;
  isMoneyShown: boolean;
  name: string;
  isTimeframeMonthly: boolean;
  type: TooltipType.EMPLOYMENT;
}

export enum TooltipType {
  EMPLOYMENT = 'employment',
  SHIFT = 'shift',
}

export type TooltipPayload = ShiftTooltip | EmploymentTooltip;

export interface StoreState {
  payload: TooltipPayload | null;
}

export type SetTooltipPayloadFunction = (tooltip: TooltipPayload) => void;

export type ClearTooltipPayloadFunction = () => void;

export const store = (): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    payload: null,
  },
  mutations: {
    [Mutation.SET_PAYLOAD](state, payload) {
      state.payload = payload;
    },
  },
  actions: {
    [Action.SET_PAYLOAD]({ commit }, payload) {
      commit(Mutation.SET_PAYLOAD, payload);
    },
    [Action.CLEAR_PAYLOAD]({ commit }) {
      commit(Mutation.SET_PAYLOAD, null);
    },
  },
});
