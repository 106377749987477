import { namespace } from 'vuex-class';
import Action from './Action';
import Mutation from './Mutation';
import type { SnackbarContent } from '../types';

export const snackbarNS = namespace('snackbar');

export interface StoreState {
  isVisible: boolean;
  content: SnackbarContent;
}

export type ShowSnackbarFunction = (content: SnackbarContent) => void;

function getSnackbarStore() {
  return {
    namespaced: true,
    state: {
      isVisible: false,
      content: {
        buttonCloseLabel: undefined,
        message: '',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCloseAction: () => {},
        kind: undefined,
      },
    },
    mutations: {
      [Mutation.SET_VISIBILITY](state, isVisible) {
        state.isVisible = isVisible;
      },
      [Mutation.SET_CONTENT](state, content) {
        state.content = content;
      },
    },
    actions: {
      [Action.HIDE]({ commit }) {
        commit(Mutation.SET_VISIBILITY, false);
      },
      [Action.SHOW]({ commit, dispatch }, content: SnackbarContent) {
        const defaultButtonCloseAction = () => dispatch(Action.HIDE);

        commit(Mutation.SET_CONTENT, {
          onCloseAction: defaultButtonCloseAction,
          ...content,
        });
        commit(Mutation.SET_VISIBILITY, true);
      },
    },
  };
}

export default getSnackbarStore;
