import type { Shiftplan } from 'store/shiftplans/Store';
import { endOf, getDateInTimeZone, startOf, Unit } from './date-related';

export const getDefaultShiftplan = (
  shiftplans: Shiftplan[],
  timeZone: string,
) => {
  const today = startOf(new Date(), Unit.DAY, timeZone).valueOf();

  const firstOngoingShiftplan = shiftplans.find(
    (shiftplan) =>
      getDateInTimeZone(new Date(shiftplan.startsAt), timeZone).valueOf() <=
        today &&
      getDateInTimeZone(new Date(shiftplan.endsAt), timeZone).valueOf() >=
        today,
  );

  if (firstOngoingShiftplan) {
    return firstOngoingShiftplan;
  }

  const firstFutureShiftplan = shiftplans.find(
    (shiftplan) =>
      getDateInTimeZone(new Date(shiftplan.startsAt), timeZone).valueOf() >
      today,
  );

  if (firstFutureShiftplan) {
    return firstFutureShiftplan;
  }

  const mostRecentPastShiftplan = [...shiftplans]
    .reverse()
    .find(
      (shiftplan) =>
        getDateInTimeZone(new Date(shiftplan.endsAt), timeZone).valueOf() <
        today,
    );

  if (mostRecentPastShiftplan) {
    return mostRecentPastShiftplan;
  }

  return shiftplans.slice(-1)[0];
};

/*
getShiftplanBasedOnDateRange block starts here

*/

// declared in global scope so that it's accessible when the function is called more than once and can be updated
let index;

const binarySearchForShiftplan = (
  items,
  timeZone,
  startDate,
  endDate,
  checkCompleteOverlap = false,
  start = 0,
  end = items.length - 1,
) => {
  if (start > end) {
    return undefined;
  }
  const mid = Math.floor((start + end) / 2);
  const shiftplanStartsAt = getDateInTimeZone(
    new Date(items[mid].startsAt),
    timeZone,
  ).valueOf();
  const shiftplanEndsAt = getDateInTimeZone(
    new Date(items[mid].endsAt),
    timeZone,
  ).valueOf();
  const isShiftplanStartsDateWithinRange =
    shiftplanStartsAt >= startDate && shiftplanStartsAt <= endDate;
  const isShiftplanEndDatetWithinRange =
    shiftplanEndsAt >= startDate && shiftplanEndsAt <= endDate;

  // Update the index value with mid value when this condition is satisified. This index is then used as a start value so that we don't need to loop through the whole array and directly jump to shiftplan where start date of shiftplan is less than startDate passed.
  if (checkCompleteOverlap) {
    index = startDate < shiftplanStartsAt ? mid : index;
  }

  if (
    (checkCompleteOverlap &&
      isShiftplanStartsDateWithinRange &&
      isShiftplanEndDatetWithinRange) ||
    (!checkCompleteOverlap &&
      (isShiftplanStartsDateWithinRange || isShiftplanEndDatetWithinRange))
  ) {
    return items[mid];
  }

  return startDate < shiftplanStartsAt
    ? binarySearchForShiftplan(
        items,
        timeZone,
        startDate,
        endDate,
        checkCompleteOverlap,
        start,
        mid - 1,
      )
    : binarySearchForShiftplan(
        items,
        timeZone,
        startDate,
        endDate,
        checkCompleteOverlap,
        mid + 1,
        end,
      );
};

export const getShiftplanBasedOnDateRange = (
  startsAt: Date,
  endsAt: Date,
  shiftplans: Shiftplan[],
  timeZone: string,
) => {
  /* Logic to find the shiftplan
   * - First we are trying to find out shiftplan which is active between the date range provided. If found then we return that shiftplan
   * - If shiftplan is not found then we are trying to find out shiftplan which either starts or ends in the given date range.
   * - then we are trying to findout a shiftplan which starts after the provided end date
   * - if no shiftplan is found then we are finding out a shiftplan which ends just before the provided start date
   */

  const startDate = startOf(startsAt, Unit.DAY, timeZone).valueOf();
  const endDate = endOf(endsAt, Unit.DAY, timeZone).valueOf();
  index = shiftplans.length - 1;
  // Within the provided start and the end date
  const shiftplanWithinDateRange = binarySearchForShiftplan(
    shiftplans,
    timeZone,
    startDate,
    endDate,
    true,
  );
  if (shiftplanWithinDateRange) {
    return shiftplanWithinDateRange;
  }

  const firstOngoingShiftplan = binarySearchForShiftplan(
    shiftplans,
    timeZone,
    startDate,
    endDate,
    false,
    index,
  );

  if (firstOngoingShiftplan) {
    return firstOngoingShiftplan;
  }

  if (shiftplans[index]) {
    return shiftplans[index];
  }

  return shiftplans.slice(-1)[0];
};

/*
  - END
*/
