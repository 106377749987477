import RootStoreState from 'src/store/RootStoreState';
import { ActionContext } from 'vuex';
import { namespace } from 'vuex-class';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  GQLCreateEvaluationBreakMutation,
  GQLCreateEvaluationBreakMutationVariables,
  GQLDeleteEvaluationBreakMutation,
  GQLDeleteEvaluationBreakMutationVariables,
  GQLEvaluationBreakFragmentFragment,
  GQLUpdateEvaluationBreakMutation,
  GQLUpdateEvaluationBreakMutationVariables,
} from 'codegen/gql-types';
import ApplicationLogger from 'services/logger/ApplicationLogger';
import {
  Action,
  ActionProvider,
  ById,
  createNormalizedStore,
} from 'store/normalized-store';
import { PayloadParameter, StoreActionResult } from 'utils/store';
import CreateEvaluationBreakGql from './queries/CreateEvaluationBreak.gql';
import DeleteEvaluationBreakGql from './queries/DeleteEvaluationBreak.gql';
import UpdateEvaluationBreakGql from './queries/UpdateEvaluationBreak.gql';

export const evaluationBreaksNS = namespace('evaluationBreaks');

export type StoreState = ById<EvaluationBreak>;

export type EvaluationBreak = GQLEvaluationBreakFragmentFragment;

export type CreateEvaluationBreaksFunction = (
  payload: Omit<GQLCreateEvaluationBreakMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

export type DeleteEvaluationBreaksFunction = (
  payload: Omit<
    GQLDeleteEvaluationBreakMutationVariables & { id: number },
    'companyId'
  >,
) => Promise<StoreActionResult>;

export type UpdateEvaluationBreaksFunction = (
  payload: Omit<GQLUpdateEvaluationBreakMutationVariables, 'companyId'>,
) => Promise<StoreActionResult>;

type StoreActionContext = ActionContext<StoreState, RootStoreState>;

const getEvaluationBreaksStore = (
  graphqlClient: ApolloClient<NormalizedCacheObject>,
  logger: ApplicationLogger,
) => {
  const store = {
    namespaced: true,
  };

  const create: ActionProvider<
    GQLCreateEvaluationBreakMutation,
    GQLCreateEvaluationBreakMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<CreateEvaluationBreaksFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: CreateEvaluationBreakGql,
      resultKey: 'createEvaluationBreak',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const remove: ActionProvider<
    GQLDeleteEvaluationBreakMutation,
    GQLDeleteEvaluationBreakMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<DeleteEvaluationBreaksFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: DeleteEvaluationBreakGql,
      resultKey: 'deleteEvaluationBreak',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  const update: ActionProvider<
    GQLUpdateEvaluationBreakMutation,
    GQLUpdateEvaluationBreakMutationVariables
  > = (
    { rootState }: StoreActionContext,
    payload: PayloadParameter<UpdateEvaluationBreaksFunction>,
  ) => {
    if (!rootState.auth.currentCompanyId) {
      throw new TypeError('currentCompanyId not provided');
    }

    return {
      query: UpdateEvaluationBreakGql,
      resultKey: 'updateEvaluationBreak',
      variables: {
        ...payload,
        companyId: rootState.auth.currentCompanyId,
      },
      useBatching: true,
    };
  };

  return createNormalizedStore<EvaluationBreak, StoreState, RootStoreState>({
    store,
    provide: {
      [Action.CREATE]: create,
      [Action.DELETE]: remove,
      [Action.UPDATE]: update,
    },
    graphqlClient,
    logger,
  });
};

export default getEvaluationBreaksStore;
