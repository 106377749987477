export enum Mutation {
  SET_COUNT = 'setCount',
  SET_LOADING_STATE = 'setLoadingState',
  SET_DATA = 'setData',
  SET_FILTER = 'setFilter',
  SET_PAGE = 'setPage',
  SET_SELECTION = 'setSelection',
  SET_SORT = 'setSort',
  SET_SUBSCRIPTION = 'setSubscription',
  SET_WATCH_QUERY = 'setWatchQuery',
}

export default Mutation;
