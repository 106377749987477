import { createEventPayload, EventPayload } from 'src/utils/events';
import { Component, Prop } from 'vue-property-decorator';
import { Component as TsxComponent } from 'vue-tsx-support';
import Prompt from 'components/prompt/Prompt';
import DialogManager from 'components/dialog/DialogManager';

export type DialogManagerConfirmDelete = DialogManager<
  void,
  {
    isOpen: boolean;
  }
>;

export interface Props {
  isOpen?: boolean;
  isSubmitting?: boolean;
  isConfirmButtonDisabled?: boolean;
  title: string;
  primaryButtonText?: string;
}

interface Events {
  onCancel: EventPayload<void, HTMLElement, UIEvent>;
  onConfirm: EventPayload<void, HTMLElement, UIEvent>;
}

@Component
export default class DialogConfirmDelete extends TsxComponent<Props, Events> {
  @Prop()
  public isOpen?: Props['isOpen'];

  @Prop()
  public isSubmitting?: Props['isSubmitting'];

  @Prop()
  public title: Props['title'];

  @Prop()
  public primaryButtonText: Props['primaryButtonText'];

  @Prop()
  public isConfirmButtonDisabled: Props['isConfirmButtonDisabled'];

  public render() {
    return (
      <Prompt
        isOpen={this.isOpen}
        onCloseClick={(e) =>
          this.$emit('cancel', createEventPayload(e, undefined))
        }
        onConfirmClick={(e) =>
          this.$emit('confirm', createEventPayload(e, undefined))
        }
        title={this.title}
        isDestructive={true}
        isConfirmButtonDisabled={
          this.isSubmitting || this.isConfirmButtonDisabled
        }
        isCloseButtonDisabled={this.isSubmitting}
        primaryButtonText={this.primaryButtonText}
      >
        {this.$slots.default}
      </Prompt>
    );
  }
}
