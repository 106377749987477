// TODO: make it a normalised store
import { getDateKey } from 'components/calendar-common/common/DateItem';
import { DayNote } from 'src/store/day-notes/Store';
import RootStoreState from 'src/store/RootStoreState';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { CalendarNamespace } from '../Enums';
import Action from './Action';
import Mutation from './Mutation';

export const VISIBLE_NOTES_COUNT = 2;

export const notesNS = namespace('calendar/notes');
export const calendarAbsencesNotesNS = namespace('calendarAbsences/notes');

export enum OpenStrategy {
  ALL = 'all',
  INDEPENDENT = 'independent',
}

// this should be moved to some utils if we need it somewhere else
const getClickOutsideTrap =
  (el, cb) =>
  ({ target }) => {
    if (el === target || el.contains(target)) {
      return;
    }
    cb();
  };

export interface StoreState {
  isOpen: boolean;
}
export const store = (
  parentNamespace: CalendarNamespace,
): Module<StoreState, RootStoreState> => ({
  namespaced: true,
  state: {
    isOpen: false,
  },
  mutations: {
    [Mutation.TOGGLE_IS_OPEN](state, isOpen) {
      state.isOpen = isOpen;
    },
  },
  actions: {
    [Action.OPEN_DAY_NOTES]({ commit }) {
      commit(Mutation.TOGGLE_IS_OPEN, true);
    },
    [Action.CLOSE_DAY_NOTES]({ commit }) {
      commit(Mutation.TOGGLE_IS_OPEN, false);
    },
  },
  getters: {
    specialDates(state, getters, rootState, rootGetters) {
      return rootGetters['specialDates/items'].reduce((acc, item) => {
        const dateKey = getDateKey(item.specialDate);
        acc[dateKey] = acc[dateKey] ? [...acc[dateKey], item] : [item];
        return acc;
      }, {});
    },
    isSpecialDate(state, getters, rootState, rootGetters) {
      return (date) => {
        const dateKey = getDateKey(date);
        return (
          rootGetters[`${parentNamespace}/filters/filters`].showSpecialDates &&
          getters.specialDates[dateKey] !== undefined &&
          getters.specialDates[dateKey].length > 0
        );
      };
    },
    hasHiddenNotes(state, getters, rootState, rootGetters) {
      return Object.values<DayNote[]>(rootGetters['dayNotes/byDate']).some(
        (it) => it.length > VISIBLE_NOTES_COUNT,
      );
    },
  },
  modules: {
    dayNotesPopup: {
      namespaced: true,
      state: {
        eventHandler: null,
        element: null,
        isOpen: false,
        date: null,
      },
      mutations: {
        [Mutation.SET_ELEMENT](state, el) {
          state.element = el;
        },
        [Mutation.SET_EVENT_HANDLER](state, handler = null) {
          if (handler === null) {
            document.removeEventListener('click', state.eventHandler);
          } else {
            document.addEventListener('click', handler);
          }
          state.eventHandler = handler;
        },
        [Mutation.SET_DATE](state, date) {
          state.date = date;
        },
        [Mutation.SET_IS_OPEN](state, isOpen) {
          state.isOpen = isOpen;
        },
      },
      getters: {
        notes(state, getters, rootState, rootGetters) {
          return state.date
            ? rootGetters['dayNotes/byDate'][state.date.dateKey] || []
            : [];
        },
        specialDates(state, getters, rootState, rootGetters) {
          return state.date
            ? rootGetters[`${parentNamespace}/notes/specialDates`][
                state.date.dateKey
              ] || []
            : [];
        },
      },
      actions: {
        [Action.OPEN_DAY_NOTE_POPUP](
          { commit, dispatch, state, rootGetters },
          { event, dateItem },
        ) {
          event.preventDefault();
          const { target: el } = event;
          const { dateKey } = dateItem;
          // close previous popup if it was open
          if (state.isOpen) {
            dispatch(Action.CLOSE_DAY_NOTE_POPUP);
          }

          // do not open popup for empty cells outside shyftplan
          if (
            !(
              rootGetters['dayNotes/byDate'][dateKey] || dateItem.isSpecialDate
            ) &&
            !dateItem.isWithinShiftplan
          ) {
            return;
          }
          commit(Mutation.SET_ELEMENT, el);
          commit(Mutation.SET_DATE, dateItem);
          commit(Mutation.SET_IS_OPEN, true);
          commit(
            Mutation.SET_EVENT_HANDLER,
            getClickOutsideTrap(el, () => {
              if (state.date.dateKey === dateKey) {
                dispatch(Action.CLOSE_DAY_NOTE_POPUP);
              }
            }),
          );
        },
        [Action.CLOSE_DAY_NOTE_POPUP]({ commit }) {
          commit(Mutation.SET_ELEMENT, null);
          commit(Mutation.SET_EVENT_HANDLER, null);
          commit(Mutation.SET_DATE, null);
          commit(Mutation.SET_IS_OPEN, false);
        },
      },
    },
  },
});
