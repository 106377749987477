/* eslint-disable import/prefer-default-export */

export enum Key {
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  END = 'End',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  HOME = 'Home',
  SPACE = ' ',
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  SHIFT = 'Shift',
  CAPS_LOCK = 'CapsLock',
}
