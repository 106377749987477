import {
  StoreState as FilterStoreState,
  createFilterStore,
} from 'store/filter-store';
import { namespace } from 'vuex-class';

export const absenceReasonsFilterNS = namespace(
  'shiftSchedule/absenceReasonsFilter',
);

export type StoreState = FilterStoreState;

const getStore = () => createFilterStore({ namespaced: true });

export default getStore;
